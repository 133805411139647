import { Form, FormInstance, message } from "antd";
import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import {
  IManagementRoute,
  INewManagementRouteArg,
} from "src/services/TourManagement/models";
import useLanguage from "src/store/language";
import { IPagination } from "src/models/interfaces/pagination";
import { convertDateTimeIran, convertTimeIran } from "src/helper";
import moment from "moment";
import { useManagementRouteData } from "../../context";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";

interface IContextValue {
  states: {
    managementRouteForm: FormInstance;
    confirmLoading: boolean;
    deleteId: number;
    selectedRote: IManagementRoute | undefined;
    loading: boolean;
    managementRoutes: IManagementRoute[];
    dataLoading: boolean;
    totalCount: number;
    tablePaginationParams: Pick<IPagination, "Offset" | "Limit">;
  };
  func: {
    onFinish: (values: INewManagementRouteArg) => void;
    handleDeleteId: (id: number) => void;
  };
  dispatches: {
    setSelectedRoute: Dispatch<SetStateAction<IManagementRoute | undefined>>;
    setTablePaginationParams: Dispatch<
      SetStateAction<Pick<IPagination, "Offset" | "Limit">>
    >;
  };
  requests: {
    onDelete: () => void;
  };
}

export const ManagementRouteAddEditDataContext = createContext<
  IContextValue | undefined
>(undefined);

const ManagementRouteAddEditDataProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRote, setSelectedRoute] = useState<
    IManagementRoute | undefined
  >(undefined);

  const [managementRoutes, setManagementRoutes] = useState<IManagementRoute[]>(
    []
  );
  const [dataLoading, setDataLoadings] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [tablePaginationParams, setTablePaginationParams] =
    useState<IPagination>({
      Offset: 1,
      Limit: 10,
    });

  const { tourId } = useParams();

  const [managementRouteForm] = Form.useForm();

  const {
    requests: { getHotels, getHotelsByCity },
  } = useManagementRouteData();

  const { words } = useLanguage();

  const onFinish = (values: INewManagementRouteArg) => {
    if (values.arraivalDate)
      values.arraivalDate = convertDateTimeIran(values.arraivalDate);
    if (values.departureDate)
      values.departureDate = convertDateTimeIran(values.departureDate);
    if (values.flightTime)
      values.flightTime = convertTimeIran(values.flightTime);
    if (selectedRote) routeEditREq(values);
    else routeAddReq(values);
  };

  const routeAddReq = async (values: INewManagementRouteArg) => {
    if (!tourId) return;
    setLoading(true);
    try {
      const { AddManagementRoute } = new TourManagementService();
      const result = await AddManagementRoute(tourId, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        managementRouteForm.resetFields();
        getManagementRoutes();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const routeEditREq = async (values: INewManagementRouteArg) => {
    if (!selectedRote) return;
    setLoading(true);
    try {
      const { EditManagementRoute } = new TourManagementService();
      const result = await EditManagementRoute(selectedRote.id, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setSelectedRoute(undefined);
        getManagementRoutes();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteId = (id: number) => {
    setDeleteId(id);
  };

  const onDelete = async () => {
    if (deleteId === 0) return;
    setConfirmLoading(true);
    try {
      const { DeleteRoute } = new TourManagementService();
      const result = await DeleteRoute(deleteId);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        if (deleteId === selectedRote?.id) setSelectedRoute(undefined);
        setDeleteId(0);
        getManagementRoutes();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmLoading(false);
    }
  };

  const getManagementRoutes = useCallback(async () => {
    if (!tourId) return;
    setDataLoadings(true);
    const params = new URLSearchParams(tablePaginationParams);

    try {
      const { AllManagementRoute } = new TourManagementService();
      const res = await AllManagementRoute(Number(tourId), params.toString());
      if (res && res.status === 200 && res.data) {
        setManagementRoutes(res.data.records);
        setTotalCount(res.data.count);
      } else {
        setManagementRoutes([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoadings(false);
    }
  }, [tablePaginationParams, tourId]);

  useEffect(() => {
    getManagementRoutes();
  }, [getManagementRoutes, tourId]);

  useEffect(() => {
    if (selectedRote) {
      managementRouteForm.setFieldsValue({
        cityId: selectedRote.cityId,
        groupNum: selectedRote.groupNum,
        hotelId: selectedRote.hotelId || null,
        flightTime: selectedRote.flightTime
          ? moment(selectedRote.flightTime, "HH:mm")
          : null,
        arraival: dayjs(selectedRote.arraivalDate),
        arraivalDate: dayjs(selectedRote.arraivalDate),
        departure: dayjs(selectedRote.departureDate),
        departureDate: dayjs(selectedRote.departureDate),
        inlineCities: selectedRote.inlineCities,
        detail: selectedRote.detail,
      });
      getHotelsByCity(selectedRote.cityId);
    } else {
      managementRouteForm.resetFields();
    }
  }, [getHotels, getHotelsByCity, managementRouteForm, selectedRote]);

  const contextValues = {
    states: {
      managementRouteForm,
      confirmLoading,
      deleteId,
      selectedRote,
      loading,
      managementRoutes,
      dataLoading,
      totalCount,
      tablePaginationParams,
    },
    func: { onFinish, handleDeleteId },
    dispatches: { setSelectedRoute, setTablePaginationParams },
    requests: { onDelete },
  };

  return (
    <ManagementRouteAddEditDataContext.Provider value={contextValues}>
      {children}
      <DeleteConfirmModal
        showConfirm={deleteId > 0}
        onCancel={() => handleDeleteId(0)}
        onOk={() => onDelete()}
        title={words.managementRoute.deleteRoute}
        okTitle={words.global.delete}
        loading={confirmLoading}
      />
    </ManagementRouteAddEditDataContext.Provider>
  );
};

export default ManagementRouteAddEditDataProvider;

export const useManagementRouteAddEditData = () =>
  useContext(ManagementRouteAddEditDataContext)!;
