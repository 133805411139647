import { Form, FormInstance, message } from "antd";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { createSearchParams, useLocation } from "react-router-dom";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";
import { IPagination } from "src/models/interfaces/pagination";
import { BasicInformationService } from "src/services/BasicInformation/BasicInformation.service";
import { ICountry, INewCountryArg } from "src/services/BasicInformation/models";
import useLanguage from "src/store/language";

interface IContext {
  countries: ICountry[];
  editCountry: ICountry | undefined;
  confirmLoading: boolean;
  createCountryForm: FormInstance;
  tableLoading: boolean;
  deleteId: number;
  addEditLoading: boolean;
  totalCount: number;
  tablePaginationParams: Pick<IPagination, "Offset" | "Limit">;
  onFinish: (value: INewCountryArg) => void;
  setEditCountry: (country: ICountry | undefined) => void;
  onDeleteCountry: () => void;
  handleDeleteId: (id: number) => void;
  setTablePaginationParams: Dispatch<
    SetStateAction<Pick<IPagination, "Offset" | "Limit">>
  >;
}

export const CountryCTX = createContext<IContext | undefined>(undefined);

export const CountryProvider: FC<PropsWithChildren> = ({ children }) => {
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [editCountry, setEditCountry] = useState<ICountry | undefined>(
    undefined
  );
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [addEditLoading, setAddEditLoading] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [tablePaginationParams, setTablePaginationParams] =
    useState<IPagination>({
      Offset: 1,
      Limit: 10,
    });
  const [createCountryForm] = Form.useForm();

  const location = useLocation();
  const { words } = useLanguage();

  const onFinish = (values: INewCountryArg) => {
    setAddEditLoading(true);
    if (editCountry) countryEditREq(values);
    else countryAddReq(values);
  };
  const countryAddReq = async (values: INewCountryArg) => {
    try {
      const { NewCountry } = new BasicInformationService();
      const result = await NewCountry(values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        createCountryForm.resetFields();
        getCountries();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddEditLoading(false);
    }
  };
  const countryEditREq = async (values: INewCountryArg) => {
    if (!editCountry) return;
    try {
      const { EditCountry } = new BasicInformationService();
      const result = await EditCountry(editCountry.id, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setEditCountry(undefined);
        getCountries();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAddEditLoading(false);
    }
  };

  const handleDeleteId = (id: number) => {
    setDeleteId(id);
  };

  const onDeleteCountry = async () => {
    if (deleteId === 0) return;
    setConfirmLoading(true);
    try {
      const { DeleteCountry } = new BasicInformationService();
      const result = await DeleteCountry(deleteId);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        if (editCountry && editCountry.id === deleteId)
          setEditCountry(undefined);
        setDeleteId(0);
        getCountries();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmLoading(false);
    }
  };

  const getCountries = useCallback(async () => {
    setTableLoading(true);
    const query = `${location.search}${
      location.search ? "&" : "?"
    }${createSearchParams(tablePaginationParams).toString()}`;
    try {
      const { Countries } = new BasicInformationService();
      const res = await Countries(query);
      if (res && res.status === 200 && res.data) {
        setCountries(res.data.records);
        setTotalCount(res.data.count);
      } else {
        setCountries([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setTableLoading(false);
    }
  }, [location.search, tablePaginationParams]);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  const contextValue: IContext = {
    countries,
    editCountry,
    confirmLoading,
    createCountryForm,
    tableLoading,
    addEditLoading,
    tablePaginationParams,
    onFinish,
    setEditCountry,
    onDeleteCountry,
    deleteId,
    handleDeleteId,
    setTablePaginationParams,
    totalCount,
  };
  return (
    <CountryCTX.Provider value={contextValue}>
      {children}
      <DeleteConfirmModal
        showConfirm={deleteId > 0}
        onCancel={() => handleDeleteId(0)}
        onOk={() => onDeleteCountry()}
        title={words.basicInfo.deleteConfirmTitle}
        okTitle={words.global.delete}
        loading={confirmLoading}
      />
    </CountryCTX.Provider>
  );
};
export const useCountryContext = () => useContext(CountryCTX)!;
