import { FC } from "react";
import { AssignRoleToUser } from "src/modules/assignRoleToUser";
import { AssignRoleToUserProvider } from "src/modules/assignRoleToUser/context";

export const AssignRoleToUserPage: FC = () => {
  return (
    <AssignRoleToUserProvider>
      <AssignRoleToUser />
    </AssignRoleToUserProvider>
  );
};
