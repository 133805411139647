import { Form, FormInstance, message } from "antd";
import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import {
  IAssignSimcard,
  INewAssignSimcardArg,
  ITableTourist,
} from "src/services/TourManagement/models";
import useLanguage from "src/store/language";
import { useManagementSimcardData } from "../../context";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";

interface IContextValue {
  values: {
    tourist: ITouristProps;
    confirmLoading: boolean;
    deleteId: number;
    selectedAssign: IAssignSimcard | undefined;
    loading: boolean;
  };
  dispatches: {
    setSelectedAssign: Dispatch<SetStateAction<IAssignSimcard | undefined>>;
  };
  func: {
    onFinishAssignSimcard: (values: INewAssignSimcardArg) => void;
    handleDeleteId: (id: number) => void;
  };
  requests: { onDeleteAssign: () => void };
  form: { assignForm: FormInstance };
}

interface ITouristProps {
  data: ITableTourist[];
  loading: boolean;
}

export const AddEditSimcardDataContext = createContext<
  IContextValue | undefined
>(undefined);

const AddEditSimcardDataProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [tourist, setTourist] = useState<ITouristProps>({
    data: [],
    loading: false,
  });
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [selectedAssign, setSelectedAssign] = useState<
    IAssignSimcard | undefined
  >(undefined);

  const [assignForm] = Form.useForm();

  const { words } = useLanguage();
  const { tourId } = useParams();

  const {
    requests: { getAssignSimcards },
  } = useManagementSimcardData();

  const onFinishAssignSimcard = (values: INewAssignSimcardArg) => {
    if (selectedAssign) assignEditREq(values);
    else assignAddREq(values);
  };

  const assignAddREq = async (values: INewAssignSimcardArg) => {
    if (!tourId) return;
    setLoading(true);
    try {
      const { NewAssignSimcard } = new TourManagementService();
      const result = await NewAssignSimcard(+tourId, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        assignForm.resetFields();
        getAssignSimcards();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const assignEditREq = async (values: INewAssignSimcardArg) => {
    if (!selectedAssign) return;
    setLoading(true);
    try {
      const { EditAssignSimcard } = new TourManagementService();
      const result = await EditAssignSimcard(
        selectedAssign.tourTouristId,
        values
      );
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setSelectedAssign(undefined);
        getAssignSimcards();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteId = (id: number) => {
    setDeleteId(id);
  };

  const onDeleteAssign = async () => {
    if (deleteId === 0) return;
    setConfirmLoading(true);
    try {
      const { DeleteAssignSimcard } = new TourManagementService();
      const result = await DeleteAssignSimcard(deleteId);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setDeleteId(0);
        getAssignSimcards();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmLoading(false);
    }
  };

  const getTourists = useCallback(async () => {
    if (!tourId) return;
    setTourist((prev) => ({ ...prev, loading: true }));

    try {
      const { AllTourist } = new TourManagementService();
      const res = await AllTourist(tourId);
      if (res && res.status === 200 && res.data) {
        let arr: ITableTourist[] = [];
        res.data.records.forEach((record) =>
          arr.push({ tourTouristId: record.tourTouristId, ...record.tourist })
        );
        setTourist((prev) => ({ ...prev, data: arr }));
      } else {
        setTourist((prev) => ({ ...prev, data: [] }));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setTourist((prev) => ({ ...prev, loading: false }));
    }
  }, [tourId]);

  useEffect(() => {
    getTourists();
  }, [getTourists]);

  useEffect(() => {
    if (selectedAssign) {
      assignForm.setFieldsValue({
        touristId: selectedAssign.touristId,
        phoneNumber: selectedAssign.phoneNumber,
      });
    } else assignForm.resetFields();
  }, [assignForm, selectedAssign]);

  const contextValues = {
    values: {
      tourist,
      confirmLoading,
      deleteId,
      selectedAssign,
      loading,
      assignForm,
    },
    dispatches: { setSelectedAssign },
    func: { onFinishAssignSimcard, handleDeleteId },
    requests: { onDeleteAssign },
    form: { assignForm },
  };

  return (
    <AddEditSimcardDataContext.Provider value={contextValues}>
      {children}
      <DeleteConfirmModal
        showConfirm={deleteId > 0}
        onCancel={() => handleDeleteId(0)}
        onOk={() => onDeleteAssign()}
        title={words.managementSimcardAssign.deleteConfirmTitle}
        okTitle={words.global.delete}
        loading={confirmLoading}
      />
    </AddEditSimcardDataContext.Provider>
  );
};

export default AddEditSimcardDataProvider;

export const useAddEditSimcardData = () =>
  useContext(AddEditSimcardDataContext)!;
