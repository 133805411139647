import { FC, useState } from "react";
import useLanguage from "src/store/language";
import { useDomesticFlight } from "../context";
import { ColumnsType } from "antd/es/table";
import { Col } from "antd";
import { TableUI } from "src/components/UiKit/table";
import { IDomesticFlight } from "src/services/TourManagement/models";
import { ModalDeleteConfirm } from "./ModalDeleteConfirm";
import { ModalDescriptionDomesticFlight } from "./modalDescription";
import { convertDate, getHour } from "src/helper";

export const DomesticFlightTable: FC = () => {
  const [domesticItem, setDomesticItem] = useState<IDomesticFlight | undefined>(
    undefined
  );
  const { words } = useLanguage();

  const {
    value: {
      totalCount,
      mode,
      domesticFlights,
      dataLoading,
      tablePaginationParams,
      selectedFlight,
    },
    dispatch: { setTablePaginationParams, setSelectedFlight },
    func: { handleDelete },
  } = useDomesticFlight();

  const columns: ColumnsType<IDomesticFlight> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "62px",
      render: (text, record, index) =>
        (tablePaginationParams.Offset - 1) * tablePaginationParams.Limit +
        index +
        1,
    },
    {
      title: words.managementDomesticFlight.flightDateAndTime,
      dataIndex: "flightDateTime",
      key: "flightDateTime",
      align: "center",
      render: (value) => getHour(value) + " _ " + convertDate(value),
    },
    {
      title: words.managementDomesticFlight.startingCity,
      dataIndex: "originCity",
      key: "originCity",
      align: "center",
    },
    {
      title: words.managementDomesticFlight.destinationCity,
      dataIndex: "destinationCity",
      key: "destinationCity",
      align: "center",
    },
    {
      title: words.managementDomesticFlight.flightNO,
      dataIndex: "flightCode",
      key: "flightCode",
      align: "center",
      render: (value) => (value && value !== "" ? value : "-"),
    },
    ...(mode === "view"
      ? []
      : [
          {
            title: words.addTouristInfo.operation,
            dataIndex: "operation",
            key: "operation",
            render: (_: any, record: IDomesticFlight) => (
              <div className="flex justify-center items-center gap-[16px]">
                <div
                  className="edit-action"
                  onClick={() => setSelectedFlight(record)}
                >
                  <span className="material-icons text-[#0C9D61]  text-[16px]">
                    edit_note
                  </span>
                  <span>{words.global.editAction}</span>
                </div>
                <div
                  className="delete-action"
                  onClick={() => handleDelete(record)}
                >
                  <span className="material-icons text-[#ec2d30] text-[14px]">
                    delete_forever
                  </span>
                  <span>{words.global.delete}</span>
                </div>
              </div>
            ),
          },
        ]),
  ];

  return (
    <>
      <Col span={24}>
        {mode === "view" ? (
          <p className="m-0 text-[14px] text-[#233069] pt-[12px] font-bold">
            {words.managementDomesticFlight.tableTitle}
          </p>
        ) : (
          <p className="m-0 text-[14px] text-[#1F1F1F] pt-[12px] font-medium">
            {words.managementDomesticFlight.tableTitle}
          </p>
        )}
      </Col>
      <Col span={24}>
        <TableUI
          tableType="secondary"
          loading={dataLoading}
          columns={columns}
          dataSource={domesticFlights}
          rowClassName={(record) =>
            record.id === selectedFlight?.id ? "selected-row-for-edit" : ""
          }
          pagination={{
            position: ["bottomCenter"],
            total: totalCount,
            pageSize: tablePaginationParams.Limit,
            current: tablePaginationParams.Offset,
            onChange(page, pageSize) {
              setTablePaginationParams((prev) => ({
                ...prev,
                Offset: page,
                Limit: pageSize,
              }));
            },
          }}
        />
      </Col>
      <ModalDescriptionDomesticFlight
        show={domesticItem ? true : false}
        item={domesticItem}
        onClose={() => setDomesticItem(undefined)}
      />
      <ModalDeleteConfirm />
    </>
  );
};
