import { Form } from "antd";
import _ from "lodash";
import { useCarDataContext } from "./context";

export const useFormHasChanged = () => {
  const { editCar, createCarForm } = useCarDataContext();

  const allValues = Form.useWatch([], createCarForm);

  const changed = _.isEqual(_.omit(allValues, ["id"]), _.omit(editCar, ["id"]));
  return editCar ? changed : false;
};
