import { FC } from "react";
import { Col } from "antd";
import { ManagementFilesContainer } from "./style";
import { useManagementFilesData } from "./context";
import ManagementFilesTable from "./components/table";
import AddEditForm from "./components/form/AddEditForm";

const ManagementFiles: FC = () => {
  const {
    states: { mode },
  } = useManagementFilesData();

  return (
    <ManagementFilesContainer justify={"center"} gutter={[0, 24]}>
      <Col xs={24} md={23} className="content">
        {(mode === "add" || mode === "edit") && <AddEditForm />}
        <ManagementFilesTable />
      </Col>
    </ManagementFilesContainer>
  );
};

export default ManagementFiles;
