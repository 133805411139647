import { Flex, Modal } from "antd";
import { FC, useState } from "react";
import { useHotelLetter } from "./context";
import { PreviewHotelHeader } from "./previewStyle";
import Hotel from "@mui/icons-material/HotelTwoTone";
import Group from "@mui/icons-material/GroupTwoTone";
import { Reservation } from "./reservation";
import { TouristList } from "./touristList";

export const PreviewHotelLetter: FC = () => {
  const {
    values: { showPreview },
  } = useHotelLetter();
  const [activePreview, setActivePreview] = useState<
    "reservation" | "touristList"
  >("reservation");
  const activeColor = "#F06614";
  const primaryColor = "#525D90";

  return (
    <Modal
      open={showPreview}
      closable={false}
      width={activePreview === "reservation" ? "878px" : "978px"}
      footer={null}
    >
      <PreviewHotelHeader justify="space-between">
        <Flex vertical>
          <div className="title">پیش نمایش</div>
          <div className="subtitle">نامه رزرو هتل و لیست گردشگران</div>
        </Flex>
        <Flex gap={16}>
          <div
            className={`buttons ${
              activePreview === "reservation" ? "active" : ""
            }`}
            onClick={() => {
              setActivePreview("reservation");
            }}
          >
            <Hotel
              color="warning"
              sx={{
                color:
                  activePreview === "reservation" ? activeColor : primaryColor,
                fontSize: "24px",
              }}
            />
            <span>نامه رزرو هتل </span>
          </div>
          <div
            className={`buttons ${
              activePreview === "touristList" ? "active" : ""
            }`}
            onClick={() => {
              setActivePreview("touristList");
            }}
          >
            <Group
              color="warning"
              sx={{
                color:
                  activePreview === "touristList" ? activeColor : primaryColor,
                fontSize: "24px",
              }}
            />
            <span>لیست گردشگران هتل</span>
          </div>
        </Flex>
      </PreviewHotelHeader>
      {activePreview === "reservation" && <Reservation />}
      {activePreview === "touristList" && <TouristList />}
    </Modal>
  );
};
