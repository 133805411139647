import { FC } from "react";
import { Col } from "antd";
import { SimcardAddEditTabsContainer } from "./style";
import AssignSimcard from "./components/AssignSimcard";

const SimcardAddEditTabs: FC = () => {
  return (
    <SimcardAddEditTabsContainer>
      <Col span={24}>
        <AssignSimcard />
      </Col>
    </SimcardAddEditTabsContainer>
  );
};

export default SimcardAddEditTabs;
