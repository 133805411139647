import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { ITourTableParams } from "src/services/Tour/models";
import { reactQueryEndPoints } from "src/constants";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import { IDashboardInfo } from "src/services/TourManagement/models";

interface IContextValue {
  states: {
    tourTableParams: Pick<ITourTableParams, "Offset" | "Limit">;
    dataLoading: boolean;
    allTour: IDashboardInfo | undefined;
  };
}

export const DashboardDataContext = createContext<IContextValue | undefined>(
  undefined
);

const DashboardDataProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [tourTableParams] = useState<ITourTableParams>({
    Offset: 1,
    Limit: 10,
  });

  const location = useLocation();

  const getAllTours = async () => {
    try {
      const { GetDashboardInfo } = new TourManagementService();
      const result = await GetDashboardInfo();
      if (result && result.status === 200) {
        const { data } = result;
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const { data, isFetching, refetch } = useQuery(
    reactQueryEndPoints.toursList,
    getAllTours,
    { enabled: false }
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourTableParams, location]);
  const contextValues = {
    states: {
      tourTableParams,
      dataLoading: isFetching,
      allTour: data,
    },
  };

  return (
    <DashboardDataContext.Provider value={contextValues}>
      {children}
    </DashboardDataContext.Provider>
  );
};

export default DashboardDataProvider;

export const useDashboardData = () => useContext(DashboardDataContext)!;
