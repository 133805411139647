import { FC, useEffect } from "react";
import { Col, Form, Row } from "antd";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import DatePickerUi from "src/components/UiKit/DatePicker";
import { TabMenuKeys } from "src/modules/addEditTour/models";
import { convertDatePicker } from "src/helper";
import LoadingSpin from "src/components/loadingSpin";
import { useTourViewData } from "src/modules/viewTour/context/TourViewData";
import { useTranslatorInfoRequest } from "./TranslatorInfoRequest";

const TranslatorForm: FC = () => {
  const { words } = useLanguage();

  const [translatorForm] = Form.useForm();

  const { states } = useTourViewData();
  const {
    states: { dataLoading },
  } = useTranslatorInfoRequest();

  useEffect(() => {
    if (states.formData && states.current === TabMenuKeys.translator) {
      translatorForm.setFieldsValue({
        translatorInfo: states.formData.translator?.translatorInfo,
        translatorPhone: states.formData.translator?.translatorPhone,
        translatorFatherName: states.formData.translator?.translatorFatherName,
        translatorNationalCode:
          states.formData.translator?.translatorNationalCode,
        translatorIdNumber: states.formData.translator?.translatorIdNumber,
        translatorBirthDate: convertDatePicker(
          states.formData.translator?.translatorBirthDate
        ),
      });
    }
  }, [translatorForm, states]);

  return dataLoading ? (
    <LoadingSpin />
  ) : (
    <Form form={translatorForm} disabled={true}>
      <Row gutter={[8, 16]} className="team-form-row">
        <Col xs={24} md={12}>
          <Form.Item name={"translatorInfo"}>
            <InputUiKit
              label={words.viewTour.firstName.concat(
                "-" + words.viewTour.lastName
              )}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"translatorPhone"}>
            <InputUiKit label={words.global.contact} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"translatorFatherName"}>
            <InputUiKit label={words.global.fatherName} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"translatorNationalCode"}>
            <InputUiKit label={words.global.nationalCode} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"translatorIdNumber"}>
            <InputUiKit label={words.viewTour.cardNumber} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"translatorBirthDate"}>
            <DatePickerUi label="تاریخ تولد" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default TranslatorForm;
