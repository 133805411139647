import { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  NajaListContainer,
  NajaListTable,
  NajaListWrapper,
  PageFooter,
} from "./style";
import { Col } from "antd";
import { IGetExportNaja } from "src/services/ExportData/models";
import { useParams } from "react-router-dom";
import { ExportDataService } from "src/services/ExportData/ExportData.service";
import _, { uniqueId } from "lodash";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useNajaListTableColumns } from "./tableColumns";
import { downloadExcel } from "react-export-table-to-excel";
import { IObject } from "src/models/interfaces";
import moment from "moment";
import html2pdf from "html2pdf.js";
import { DownloadPdfIcon } from "../DownladPdfIcon";

// import ReactExport from "react-export-excel";

export const NajaList: FC = () => {
  const [data, setData] = useState<IGetExportNaja[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  const { tourId } = useParams();
  const columns = useNajaListTableColumns();

  const fetchData = useCallback(async () => {
    if (!tourId) return;
    try {
      setLoading(true);
      const { GetExportNaja } = new ExportDataService();
      const res = await GetExportNaja(tourId);
      if (res && res.status === 200 && res.data) {
        setData(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [tourId]);

  const componentRef = useRef<HTMLDivElement>(null);

  const print = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: "margin:100px",
    print: async (printIframe: HTMLIFrameElement) => {
      try {
        setDownloadLoading(true);
        const document = printIframe.contentDocument;

        if (document) {
          const html = document.getElementsByTagName("html")[0];
          const htmlRect = html.getBoundingClientRect();
          const pdfWith = 250;
          const pdfCurrentHeight = (pdfWith * htmlRect.height) / htmlRect.width;
          const pageCount = htmlRect.height / pdfWith;
          const roundedPageCount =
            Math.round(pageCount) < pageCount
              ? Math.ceil(pageCount)
              : Math.round(pageCount);
          const pdfHeight = pdfCurrentHeight * roundedPageCount;
          const opt = {
            jsPDF: {
              format: [pdfWith, pdfHeight],
            },
            image: { type: "jpeg", quality: 1 },
            html2canvas: { scale: 3 },
            unit: "px",
          };
          await html2pdf().from(html).set(opt).save("NajaList.pdf");
        }
      } catch (err) {
        console.log(err);
      } finally {
        setDownloadLoading(false);
      }
    },
  });
  const handlePrint = async () => {
    setDownloadLoading(true);
    print();
  };
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const onDownloadExcel = () => {
    const copyColumns = [...columns];
    const copyData = [...data];
    const reverseColumns = _.reverse(copyColumns);
    const header: string[] = reverseColumns.map(
      (column) => column.title as string
    );
    const body: IObject[] = copyData.map((item, index) => {
      const newItem = {
        No: index + 1,
        Name: item.firstName,
        Surname: item.lastName,
        "Date of birth": moment(item.birthDate).format("YYYY-MM-DD"),
        "Place of birth": item.placeOfBirth,
        Nationality: item.country,
        "Pass. No": item.passportNo,
        Job: item.job,
        "Date of expiry": moment(item.expireDate).format("YYYY-MM-DD"),
      };
      return newItem;
    });
    downloadExcel({
      fileName: "report",
      sheet: "report",
      tablePayload: {
        header,
        body,
      },
    });
  };
  return (
    <NajaListContainer>
      <Col span={24} className="content">
        <ReactToPrint content={reactToPrintContent} removeAfterPrint />

        <NajaListWrapper
          ref={componentRef}
          justify={"center"}
          align={"top"}
          className="h-full"
        >
          <div className="wrapper">
            <NajaListTable
              columns={columns}
              dataSource={data}
              loading={loading}
              scroll={{ x: "auto" }}
              pagination={false}
              className="table w-full"
              bordered
              rowKey={() => uniqueId()}
            />
          </div>
        </NajaListWrapper>
        {/* for pdf download */}
        <div className="hidden">
          <NajaListWrapper
            ref={componentRef}
            justify={"center"}
            align={"top"}
            className="h-full"
          >
            <div>
              <img
                src="/assets/images/sarbargHeader.png"
                alt="sarbarg"
                className="mb-[32px]"
                width={"100%"}
              />
            </div>
            <div className="wrapper !shadow-none">
              <NajaListTable
                columns={columns.map((column) => {
                  const newColumn = { ...column };
                  newColumn.className += " !text-[8px]";
                  return newColumn;
                })}
                dataSource={data}
                loading={loading}
                scroll={{ x: "auto" }}
                pagination={false}
                className="table w-full"
                bordered
                rowKey={() => uniqueId()}
              />
            </div>
            <div>
              <img
                src="/assets/images/sarbargFooter.png"
                alt="sarbarg"
                className="mt-[32px]"
                width={"100%"}
              />
            </div>
          </NajaListWrapper>
        </div>
      </Col>
      <Col span={24} className="pb-[32px]">
        <PageFooter className="pageFooter" justify="flex-end">
          <DownloadPdfIcon
            handlePrint={handlePrint}
            loading={downloadLoading}
          />
          <img
            src="/assets/images/excelDownload.svg"
            alt=""
            onClick={onDownloadExcel}
          />
        </PageFooter>
      </Col>
    </NajaListContainer>
  );
};
