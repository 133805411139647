import { Form, FormInstance, message } from "antd";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { createSearchParams, useLocation } from "react-router-dom";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";
import { IPagination } from "src/models/interfaces/pagination";
import { BasicInformationService } from "src/services/BasicInformation/BasicInformation.service";
import { ICity, INewCityArg } from "src/services/BasicInformation/models";
import useLanguage from "src/store/language";

interface IContext {
  cities: ICity[];
  editCity: ICity | undefined;
  createCityForm: FormInstance;
  deleteId: number;
  loadings: ILoadings;
  totalCount: number;
  tablePaginationParams: Pick<IPagination, "Offset" | "Limit">;
  onFinish: (values: INewCityArg) => void;
  setEditCity: (city: ICity | undefined) => void;
  onDeleteCity: () => void;
  handleDeleteId: (id: number) => void;
  setTablePaginationParams: Dispatch<
    SetStateAction<Pick<IPagination, "Offset" | "Limit">>
  >;
}
interface ILoadings {
  table: boolean;
  confirm: boolean;
  addEdit: boolean;
}

export const CityCTX = createContext<IContext | undefined>(undefined);

export const CityProvider: FC<PropsWithChildren> = ({ children }) => {
  const [cities, setCities] = useState<ICity[]>([]);
  const [editCity, setEditCity] = useState<ICity | undefined>(undefined);
  const [loadings, setLoadings] = useState<ILoadings>({
    table: false,
    confirm: false,
    addEdit: false,
  });
  const [totalCount, setTotalCount] = useState<number>(0);
  const [tablePaginationParams, setTablePaginationParams] =
    useState<IPagination>({
      Offset: 1,
      Limit: 10,
    });
  const [deleteId, setDeleteId] = useState<number>(0);
  const [createCityForm] = Form.useForm();

  const location = useLocation();

  const { words } = useLanguage();

  const onFinish = (values: INewCityArg) => {
    setLoadings({ ...loadings, addEdit: true });
    if (editCity) cityEditREq(values);
    else cityAddReq(values);
  };

  const cityAddReq = async (values: INewCityArg) => {
    try {
      const { NewCity } = new BasicInformationService();
      const result = await NewCity(values);
      if (result && result.status === 200) {
        createCityForm.resetFields();
        getCities();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings({ ...loadings, addEdit: false });
    }
  };
  const cityEditREq = async (values: INewCityArg) => {
    if (!editCity) return;
    try {
      const { EditCity } = new BasicInformationService();
      const result = await EditCity(editCity.id, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setEditCity(undefined);
        getCities();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings({ ...loadings, addEdit: false });
    }
  };
  const handleDeleteId = (id: number) => {
    setDeleteId(id);
  };

  const onDeleteCity = async () => {
    if (deleteId === 0) return;
    setLoadings({ ...loadings, confirm: true });
    try {
      const { DeleteCity } = new BasicInformationService();
      const result = await DeleteCity(deleteId);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        if (editCity && editCity.id === deleteId) setEditCity(undefined);
        setDeleteId(0);
        getCities();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings({ ...loadings, confirm: false });
    }
  };

  const getCities = useCallback(async () => {
    setLoadings({ ...loadings, table: true });
    try {
      const query = `${location.search}${
        location.search ? "&" : "?"
      }${createSearchParams(tablePaginationParams).toString()}`;
      const { Cities } = new BasicInformationService();
      const res = await Cities(query);
      if (res && res.status === 200 && res.data) {
        setCities(res.data.records);
        setTotalCount(res.data.count);
      } else {
        setCities([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadings({ ...loadings, table: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePaginationParams.Limit, tablePaginationParams.Offset]);

  useEffect(() => {
    getCities();
  }, [getCities]);

  useEffect(() => {
    if (editCity) {
      createCityForm?.setFieldsValue({
        name: editCity.name,
        province: editCity?.province,
      });
    } else createCityForm?.resetFields();
  }, [createCityForm, editCity]);

  const contextValue: IContext = {
    cities,
    editCity,
    createCityForm,
    loadings,
    onFinish,
    handleDeleteId,
    setEditCity,
    onDeleteCity,
    deleteId,
    setTablePaginationParams,
    totalCount,
    tablePaginationParams,
  };
  return (
    <CityCTX.Provider value={contextValue}>
      {children}
      <DeleteConfirmModal
        showConfirm={deleteId > 0}
        onCancel={() => handleDeleteId(0)}
        onOk={() => onDeleteCity()}
        title={words.basicInfo.deleteConfirmTitle}
        okTitle={words.global.delete}
        loading={loadings.confirm}
      />
    </CityCTX.Provider>
  );
};
export const useCityContext = () => useContext(CityCTX)!;
