import styled from "styled-components";

export const DataEntryHeaderContainer = styled.div`
  width: 100%;
  height: 80px;
  display: inline-flex;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid var(--color-Light-Mode-Border-5, #cacaca);
  background: var(--color-Base-Color-White, #fff);
  margin: 24px 0 32px 0;
  .title {
    color: #233069;
    font-weight: 700;
    font-size: 16px;
    margin-right: 10px;
  }
  .icon {
    transform: rotate(90deg);
    font-size: 24px;
    color: #233069;
  }
`;
