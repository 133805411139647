import { Flex } from "antd";
import { FC } from "react";
import { IManagementRoute } from "src/services/TourManagement/models";
import useLanguage from "src/store/language";
import { useManagementRouteData } from "src/modules/managementRoute/context";
import { ROUTES } from "src/models/enums/routes";
import { Link } from "react-router-dom";
import { useManagementRouteAddEditData } from "../context";
interface ITableActionsProps {
  item: IManagementRoute;
}

const TableActions: FC<ITableActionsProps> = ({ item }) => {
  const { words } = useLanguage();

  const {
    states: { mode },
  } = useManagementRouteData();

  const {
    func: { handleDeleteId },
    dispatches: { setSelectedRoute },
  } = useManagementRouteAddEditData();

  const handleSelectHotel = () => {
    setSelectedRoute(item);
  };

  return (
    <>
      <Flex justify="center" align="center" className="gap-[16px]">
        <Link
          className={`room-place-action ${
            item.roomFlag ? "active" : "disable"
          }`}
          to={`${ROUTES.tourTable}/${item.tourId}${ROUTES.managementTour}${ROUTES.tourManagementRoute}/${item.id}${ROUTES.routeRoom}`}
          state={{ mode: mode, route: item }}
        >
          <span className="material-icons text-[14px]">local_hotel</span>
          <span>{words.global.roomAction}</span>
        </Link>
        <Link
          className={`room-place-action ${
            item.placeFlag ? "active" : "disable"
          }`}
          to={`${ROUTES.tourTable}/${item.tourId}${ROUTES.managementTour}${ROUTES.tourManagementRoute}/${item.id}${ROUTES.routePlace}`}
          state={{ mode: mode, route: item }}
        >
          <span className="material-icons text-[14px]">holiday_village</span>
          <span>{words.global.placeAction}</span>
        </Link>

        {mode === "edit" || mode === "add" ? (
          <>
            <div className="edit-action" onClick={() => handleSelectHotel()}>
              <span className="material-icons text-[14px]">edit_note</span>
              <span>{words.global.editAction}</span>
            </div>
            <div
              className="delete-action"
              onClick={() => handleDeleteId(item.id)}
            >
              <span className="material-icons text-[14px]">delete_forever</span>
              <span>{words.global.delete}</span>
            </div>
          </>
        ) : null}
      </Flex>
    </>
  );
};

export default TableActions;
