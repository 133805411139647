import useLanguage from "src/store/language";
import { IManagementCard } from "../models/interface";
import { ROUTES } from "src/models/enums/routes";

export const useManagement = () => {
  const { words } = useLanguage();
  const cards: IManagementCard = {
    tourInfo: [
      {
        id: 1,
        icon: "basicInfo",
        name: `${words.managementTour.basicInfo}`,
        flag: "BasicInfo",
        endPoint: ROUTES.tourManagementBasicInfo,
      },
      {
        id: 2,
        icon: "hiking",
        name: `${words.managementTour.tourists}`,
        flag: "Tourists",
        endPoint: ROUTES.tourManagementTouristInfo,
      },
      {
        id: 3,
        icon: "timeFrame",
        name: `${words.managementTour.timeFrame}`,
        flag: "TourTime",
        endPoint: ROUTES.tourManagementTime,
      },
      {
        id: 4,
        icon: "tourRoutes",
        name: `${words.managementTour.tourRoutes}`,
        flag: "Routes",
        endPoint: ROUTES.tourManagementRoute,
      },
      {
        id: 5,
        icon: "sdCardAlert",
        name: `${words.managementTour.buySimcard}`,
        flag: "BuySimCard",
        endPoint: ROUTES.tourManagementSimcard,
      },
      {
        id: 6,
        icon: "SIMCard",
        name: `${words.managementTour.SIMCard}`,
        flag: "AssignSimCard",
        endPoint: ROUTES.tourManagementAssignSimcard,
      },
      {
        id: 7,
        icon: "insurance",
        name: `${words.managementTour.insurance}`,
        flag: "Insurance",
        endPoint: ROUTES.tourManagementInsurance,
      },

      {
        id: 8,
        icon: "domesticFlight",
        name: `${words.managementTour.domesticFlight}`,
        flag: "DomesticFlight",
        endPoint: ROUTES.domesticFlight,
      },
      {
        id: 9,
        icon: "documentation",
        name: `${words.managementTour.documentation}`,
        flag: "Attachments",
        endPoint: ROUTES.tourManagementFiles,
      },
    ],
    teemInfo: [
      {
        id: 1,
        icon: "translator",
        name: `${words.managementTour.translator}`,
        flag: "Translator",
        endPoint: ROUTES.tourManagementTranslator,
      },
      {
        id: 2,
        icon: "leader",
        name: `${words.managementTour.leader}`,
        flag: "Leader",
        endPoint: ROUTES.tourManagementLeader,
      },
      {
        id: 3,
        icon: "driver",
        name: `${words.managementTour.driver}`,
        flag: "Driver",
        endPoint: ROUTES.tourManagementDriver,
      },
      {
        id: 4,
        icon: "broker",
        name: `${words.managementTour.companionLeader}`,
        flag: "CompanionLeader",
        endPoint: ROUTES.tourManagementCompanionLeader,
      },
    ],
    notificationInfo: [
      {
        id: 4,
        icon: "notifications",
        name: `${words.managementTour.notifications}`,
        flag: "Notification",
        endPoint: ROUTES.notification,
      },
    ],
  };
  return cards;
};
