import { Flex, Row } from "antd";
import styled from "styled-components";

export const ReservationStyle = styled(Row)`
  margin-top: 32px;
  .header-label {
    color: var(--Light-Secondary-Text, #808080);

    /* Persian/Title/T2-Bold */
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    min-width: 66px;
    text-align: left;
  }
  .header-value {
    color: var(--Gray-Gray-700, #1f1f1f);
    min-width: 75px;
    /* Persian/Subtitle/S2-Medium */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
  }
  .title {
    color: var(--Gray-Gray-700, #1f1f1f);
    margin-bottom: 0;
    /* Persian/Title/T1-Bold */
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
  }
  .description {
    color: var(--Gray-Gray-700, #1f1f1f);
    margin-bottom: 0;

    /* Persian/Subtitle/S1-Regular */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const PageFooter = styled(Flex)`
  margin-top: 32px;
  padding: 32px 24px 16px 24px;
  border-top: 1px solid #dadeec;
  img {
    cursor: pointer;
    user-select: none;
  }
`;
