import { FC } from "react";
import { DatePickerProps } from "antd";
import { ReactDatePickerProps } from "react-datepicker";
import { DatePickerStyled } from "./styles";
import FormControlUiKit from "../FormControl";
interface IProps extends ReactDatePickerProps {
  label?: string;
}

export type DatePickerLocalizedProps = DatePickerProps & IProps;

const AdDatePickerUi: FC<IProps> = ({ label = "", ...props }) => {
  return (
    <FormControlUiKit
      childrenContainerClassName="!justify-center"
      label={label}
      height={"51px"}
    >
      <DatePickerStyled
        showIcon
        isClearable
        dateFormat="yyyy.MM.dd"
        wrapperClassName="AD-react-date-picker"
        showYearDropdown
        showMonthDropdown
        autoComplete="off"
        scrollableYearDropdown
        yearDropdownItemNumber={60}
        dropdownMode="scroll"
        icon={
          <span className="material-icons text-[14px] text-[#CACACA]">
            calendar_month
          </span>
        }
        {...props}
      />
    </FormControlUiKit>
  );
};

export default AdDatePickerUi;
