import { FC } from "react";
import AttachmentsForm from "./AttachForm";
import AttachmentRequestProvider from "./AttachmentRequest";
import AttachTable from "./AttachTable";
import { FilterContentContainer } from "../../styles";
import { AttachmentTabContainer } from "./style";

const Attachments: FC = () => {
  return (
    <AttachmentRequestProvider>
      <FilterContentContainer justify={"center"}>
        <AttachmentTabContainer xs={24} md={20} lg={16}>
          <AttachmentsForm />
          <AttachTable />
        </AttachmentTabContainer>
      </FilterContentContainer>
    </AttachmentRequestProvider>
  );
};

export default Attachments;
