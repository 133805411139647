import { FC } from "react";
import { Col } from "antd";
import { ManagementTimeContainer } from "./style";
import { useManagementTimeData } from "./context";
import ViewTime from "./components/ViewTime";
import AddEditTime from "./components/AddEditTime";

const ManagementTime: FC = () => {
  const {
    states: { mode },
  } = useManagementTimeData();

  return (
    <>
      {/* <PageHeader
        mode={mode}
        title={words.managementTime.managementTime}
        backTitle={words.global.back}
        tourCode={123465}
      /> */}
      <ManagementTimeContainer justify={"center"} gutter={[0, 24]}>
        <Col xs={24} md={mode === "view" ? 18 : 23} className="content">
          {mode === "view" ? <ViewTime /> : <AddEditTime />}
        </Col>
      </ManagementTimeContainer>
    </>
  );
};

export default ManagementTime;
