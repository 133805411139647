import styled from "styled-components";

export const TableContainer = styled.div`
  .rightShadow {
    box-shadow: -5px 0px 5px 0px rgba(90, 95, 118, 0.2);
  }
  .leftShadow {
    box-shadow: 5px 0px 5px 0px rgba(90, 95, 118, 0.2);
  }
`;
