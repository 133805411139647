import { Form, message } from "antd";
import { FormInstance } from "antd/lib";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  IDomesticFlight,
  INewDomesticFlightArgs,
} from "src/services/TourManagement/models";
import { IPagination } from "src/models/interfaces/pagination";
import { useLocation, useParams } from "react-router-dom";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import { ICity } from "src/services/BasicInformation/models";
import { BasicInformationService } from "src/services/BasicInformation/BasicInformation.service";
import useLanguage from "src/store/language";
import { convertDatePicker, convertDateTimeIran } from "src/helper";

interface IManagementDomesticFlight {
  value: {
    loading: boolean;
    mode: string;
    totalCount: number;
    domesticFlights: IDomesticFlight[];
    dataLoading: boolean;
    tablePaginationParams: IPagination;
    tourCode: string;
    selectedFlight: IDomesticFlight | undefined;
    cityProps: ICityProps;
    deleteItem: IDomesticFlight | undefined;
    confirmLoading: boolean;
  };
  dispatch: {
    setTablePaginationParams: Dispatch<SetStateAction<IPagination>>;
    setSelectedFlight: Dispatch<SetStateAction<IDomesticFlight | undefined>>;
  };
  form: { DomesticFlightForm: FormInstance };
  func: {
    onFinishForm: (values: INewDomesticFlightArgs) => void;
    handleDelete: (item?: IDomesticFlight) => void;
  };
  request: { onDeleteDomesticFlight: () => void };
}

interface ICityProps {
  cities: ICity[];
  loading: boolean;
}

export const DomesticFlightContext = createContext<
  IManagementDomesticFlight | undefined
>(undefined);

export const DomesticFlightProvider: FC<PropsWithChildren> = ({ children }) => {
  //-------------------state--------------------------------

  const [loading, setLoading] = useState<boolean>(false);
  const [mode, setMode] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [domesticFlights, setDomesticFlights] = useState<IDomesticFlight[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [tourCode, setTourCode] = useState<string>("");
  const [cityProps, setCityProps] = useState<ICityProps>({
    cities: [],
    loading: false,
  });
  const [selectedFlight, setSelectedFlight] = useState<
    IDomesticFlight | undefined
  >(undefined);
  const [deleteItem, setDeleteItem] = useState<IDomesticFlight | undefined>(
    undefined
  );
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [tablePaginationParams, setTablePaginationParams] =
    useState<IPagination>({
      Offset: 1,
      Limit: 10,
    });

  //-------------------form----------------------------

  const [DomesticFlightForm] = Form.useForm();

  const { words } = useLanguage();
  const { tourId } = useParams();
  const { state } = useLocation();

  //------------------func---------------------

  const onFinishForm = (values: INewDomesticFlightArgs) => {
    values.flightDateTime = convertDateTimeIran(values.flightDateTime);
    if (selectedFlight) domesticFlightEditREq(values);
    else domesticFlightAddReq(values);
  };
  const handleDelete = (item?: IDomesticFlight) => {
    item ? setDeleteItem(item) : setDeleteItem(undefined);
  };
  const onDeleteDomesticFlight = async () => {
    if (!deleteItem) return;
    setConfirmLoading(true);
    try {
      const { DeleteDomesticFlight } = new TourManagementService();
      const result = await DeleteDomesticFlight(deleteItem.id);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setDeleteItem(undefined);
        if (deleteItem.id === selectedFlight?.id) setSelectedFlight(undefined);
        getDomesticFlights();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmLoading(false);
    }
  };
  const domesticFlightAddReq = async (values: INewDomesticFlightArgs) => {
    if (!tourId) return;
    setLoading(true);
    try {
      const { NewDomesticFlight } = new TourManagementService();
      const result = await NewDomesticFlight(Number(tourId), values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        DomesticFlightForm.resetFields();
        getDomesticFlights();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const domesticFlightEditREq = async (values: INewDomesticFlightArgs) => {
    if (!selectedFlight) return;
    setLoading(true);
    try {
      const { EditDomesticFlight } = new TourManagementService();
      const result = await EditDomesticFlight(selectedFlight.id, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setSelectedFlight(undefined);
        getDomesticFlights();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getDomesticFlights = useCallback(async () => {
    if (!tourId) return;
    setDataLoading(true);
    const params = new URLSearchParams(tablePaginationParams);
    try {
      const { AllDomesticFlight } = new TourManagementService();
      const res = await AllDomesticFlight(Number(tourId), params.toString());
      if (res && res.status === 200 && res.data) {
        setDomesticFlights(res.data.records);
        setTotalCount(res.data.count);
      } else {
        setDomesticFlights([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoading(false);
    }
  }, [tablePaginationParams, tourId]);

  const getCities = useCallback(async () => {
    setCityProps((prev) => ({ ...prev, loading: true }));
    try {
      const { Cities } = new BasicInformationService();
      const res = await Cities();
      if (res && res.status === 200 && res.data) {
        setCityProps((prev) => ({ ...prev, cities: res.data.records }));
      } else {
        setCityProps((prev) => ({ ...prev, cities: [] }));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setCityProps((prev) => ({ ...prev, loading: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCities();
  }, [getCities]);

  useEffect(() => {
    if (tourId && (mode === "edit" || mode === "view")) {
      getDomesticFlights();
    }
  }, [getDomesticFlights, mode, tourId]);

  useEffect(() => {
    if (state && state.mode) setMode(state.mode);
    else setMode("edit");
    if (state && state.code) setTourCode(state.code);
    else setTourCode("234566");
  }, [state]);

  useEffect(() => {
    if (selectedFlight)
      DomesticFlightForm.setFieldsValue({
        flightDateTime: convertDatePicker(selectedFlight.flightDateTime),
        originId: selectedFlight.originCityId,
        destinationId: selectedFlight.destinationCityId,
        flightCode: selectedFlight.flightCode,
        description: selectedFlight.description,
      });
    else DomesticFlightForm.resetFields();
  }, [DomesticFlightForm, selectedFlight]);

  const contextValue: IManagementDomesticFlight = {
    value: {
      loading,
      mode,
      totalCount,
      domesticFlights,
      dataLoading,
      tablePaginationParams,
      tourCode,
      selectedFlight,
      cityProps,
      deleteItem,
      confirmLoading,
    },
    dispatch: {
      setTablePaginationParams,
      setSelectedFlight,
    },
    form: { DomesticFlightForm },
    func: {
      onFinishForm,
      handleDelete,
    },
    request: { onDeleteDomesticFlight },
  };
  return (
    <DomesticFlightContext.Provider value={contextValue}>
      {children}
    </DomesticFlightContext.Provider>
  );
};

export const useDomesticFlight = () => useContext(DomesticFlightContext)!;
