import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import { ITourManagement } from "src/services/TourManagement/models";

interface IContextManagementTour {
  value: {
    tourManagement: ITourManagement | undefined;
    dataLoading: boolean;
    mainLink: string;
  };
  dispatch: {};
  form: {};
  func: {};
}

export const ManagementTourContext = createContext<
  IContextManagementTour | undefined
>(undefined);

export const ManagementTourProvider: FC<PropsWithChildren> = ({ children }) => {
  const [tourManagement, setTourManagement] = useState<
    ITourManagement | undefined
  >(undefined);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [mainLink, setMainLink] = useState<string>("");

  const { tourId } = useParams();

  const getTourManagement = useCallback(async () => {
    if (!tourId) return;
    setDataLoading(true);
    try {
      const { GetTourManagement } = new TourManagementService();
      const res = await GetTourManagement(tourId);
      if (res && res.status === 200 && res.data) {
        setTourManagement(res.data);
      } else {
        setTourManagement(undefined);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoading(false);
    }
  }, [tourId]);

  useEffect(() => {
    if (tourId) getTourManagement();
  }, [getTourManagement, tourId]);

  useEffect(() => {
    if (tourId)
      setMainLink(`${ROUTES.tourTable}/${tourId}${ROUTES.managementTour}`);
  }, [tourId]);

  const contextValue: IContextManagementTour = {
    value: { tourManagement, dataLoading, mainLink },
    dispatch: {},
    form: {},
    func: {},
  };
  return (
    <ManagementTourContext.Provider value={contextValue}>
      {children}
    </ManagementTourContext.Provider>
  );
};

export const useManagementTour = () => useContext(ManagementTourContext)!;
