import { Flex } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { useManagementSupervisorData } from "../context";

const ViewSupervisor: FC = () => {
  const { words } = useLanguage();
  const {
    states: { supervisor },
  } = useManagementSupervisorData();

  return (
    <>
      <p className="title">{words.managementSupervisor.viewTitle}</p>
      <section className="view-details">
        <Flex className="w-full">
          <Flex vertical className="w-[50%]">
            <Flex>
              <div className="detail-title">
                {words.managementSupervisor.nameLabel}:
              </div>
              <div>
                {supervisor && supervisor?.name ? supervisor?.name : "-"}
              </div>
            </Flex>
          </Flex>
          <Flex vertical className="w-50%">
            <Flex>
              <div className="detail-title">
                {words.managementSupervisor.phoneLabel}:
              </div>
              <div>
                {supervisor && supervisor?.phone ? supervisor?.phone : "-"}
              </div>
            </Flex>
          </Flex>
        </Flex>
      </section>
    </>
  );
};

export default ViewSupervisor;
