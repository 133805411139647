import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import { IHotel } from "src/services/BasicInformation/models";
import { BasicInformationService } from "src/services/BasicInformation/BasicInformation.service";
import useLanguage from "src/store/language";
import { createSearchParams, useLocation } from "react-router-dom";
import { IPagination } from "src/models/interfaces/pagination";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";
import { App } from "antd";

interface IContextValue {
  hotels: IHotel[];
  dataLoading: boolean;
  deleteId: number;
  confirmLoading: boolean;
  totalCount: number;
  responder: IHotel | undefined;
  tablePaginationParams: Pick<IPagination, "Offset" | "Limit">;
  onDeleteHotel: () => void;
  handleDeleteId: (id: number) => void;
  setTablePaginationParams: Dispatch<
    SetStateAction<Pick<IPagination, "Offset" | "Limit">>
  >;
  setResponder: Dispatch<SetStateAction<IHotel | undefined>>;
}

export const HotelsTableDataContext = createContext<IContextValue | undefined>(
  undefined
);

const HotelsTableDataProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [hotels, setHotels] = useState<IHotel[]>([]);
  const [dataLoading, setDataLoadings] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [responder, setResponder] = useState<IHotel | undefined>(undefined);
  const [tablePaginationParams, setTablePaginationParams] =
    useState<IPagination>({
      Offset: 1,
      Limit: 10,
    });

  const { message } = App.useApp();
  const location = useLocation();
  const { words } = useLanguage();

  const handleDeleteId = (id: number) => {
    setDeleteId(id);
  };

  const onDeleteHotel = async () => {
    if (deleteId === 0) return;
    setConfirmLoading(true);
    try {
      const { DeleteHotel } = new BasicInformationService();
      const result = await DeleteHotel(deleteId);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setDeleteId(0);
        getHotels();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmLoading(false);
    }
  };

  const getHotels = useCallback(async () => {
    setDataLoadings(true);
    try {
      const query = `${location.search}${
        location.search ? "&" : "?"
      }${createSearchParams(tablePaginationParams).toString()}`;
      const { Hotels } = new BasicInformationService();
      const res = await Hotels(query);
      if (res && res.status === 200 && res.data) {
        setHotels(res.data.records);
        setTotalCount(res.data.count);
      } else {
        setHotels([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoadings(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePaginationParams.Limit, tablePaginationParams.Offset]);

  useEffect(() => {
    getHotels();
  }, [getHotels]);

  const contextValues = {
    hotels,
    dataLoading,
    onDeleteHotel,
    handleDeleteId,
    confirmLoading,
    tablePaginationParams,
    setTablePaginationParams,
    totalCount,
    deleteId,
    responder,
    setResponder,
  };

  return (
    <HotelsTableDataContext.Provider value={contextValues}>
      {children}
      <DeleteConfirmModal
        showConfirm={deleteId > 0}
        onCancel={() => handleDeleteId(0)}
        onOk={() => onDeleteHotel()}
        title={words.basicInfo.deleteConfirmTitle}
        okTitle={words.global.delete}
        loading={confirmLoading}
      />
    </HotelsTableDataContext.Provider>
  );
};

export default HotelsTableDataProvider;

export const useHotelsTableData = () => useContext(HotelsTableDataContext)!;
