import { FC, useState } from "react";
import { TableUI } from "src/components/UiKit/table";
import { ColumnsType } from "antd/es/table";
import { IRoom } from "src/services/BasicInformation/models";
import useLanguage from "src/store/language";
import TableActions from "./TableActions";
import { useRoomsData } from "../../context/RoomsData";
import RoomInfoModal from "../modal/RoomInfoModal";

const RoomTable: FC = () => {
  const [description, setDescription] = useState<string | null | undefined>(
    undefined
  );
  const {
    states: {
      rooms,
      dataLoading,
      totalCount,
      tablePaginationParams,
      selectedRoom,
    },
    dispatches: { setTablePaginationParams },
  } = useRoomsData();

  const { words } = useLanguage();

  const tableColumns: ColumnsType<IRoom> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (text, record, index) =>
        (tablePaginationParams.Offset - 1) * tablePaginationParams.Limit +
        index +
        1,
    },
    {
      title: `${words.rooms.nameLabel}`,
      dataIndex: "name",
      key: "name",
    },
    {
      title: `${words.rooms.nameMailLabel}`,
      dataIndex: "nameForMail",
      key: "nameForMail",
    },
    {
      title: `${words.rooms.bedCountLabel}`,
      dataIndex: "bedCount",
      key: "bedCount",
    },
    {
      title: `${words.rooms.descriptionLabel}`,
      dataIndex: "description",
      key: "description",
      render: (value) => (
        <div className="flex justify-center">
          <span
            className={`material-symbols-outlined text-[#4B4B4B] text-[16px] ${
              value && value !== "" ? "cursor-pointer" : "cursor-not-allowed"
            }`}
            onClick={() => setDescription(value)}
          >
            chat
          </span>
        </div>
      ),
    },
    {
      title: `${words.basicInfo.tableActions}`,
      dataIndex: "tools",
      key: "tools",
      render: (_, record) => <TableActions item={record} />,
    },
  ];
  return (
    <>
      <p className="table-title">{words.rooms.tableTitle}</p>
      <TableUI
        columns={tableColumns}
        dataSource={rooms}
        loading={dataLoading}
        scroll={{ x: 500 }}
        tableType="secondary"
        rowClassName={(record, index) =>
          record.id === selectedRoom?.id ? "selected-row-for-edit" : ""
        }
        pagination={{
          position: ["bottomCenter"],
          total: totalCount,
          pageSize: tablePaginationParams.Limit,
          current: tablePaginationParams.Offset,
          onChange(page, pageSize) {
            setTablePaginationParams((prev) => ({
              ...prev,
              Offset: page,
              Limit: pageSize,
            }));
          },
        }}
      />
      <RoomInfoModal
        show={description ? true : false}
        item={description}
        onClose={() => setDescription(undefined)}
      />
    </>
  );
};

export default RoomTable;
