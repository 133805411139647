import { FC } from "react";
import HotelsDataProvider from "./context/HotelsData";
import HotelsMode from "./components";

const Hotels: FC = () => {
  return (
    <HotelsDataProvider>
      <HotelsMode />
    </HotelsDataProvider>
  );
};

export default Hotels;
