import { FC, useEffect } from "react";
import { Button, Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import useLanguage from "src/store/language";
import { TableUI } from "src/components/UiKit/table";
import { ModalContentContainer, ModalHeaderContainer } from "../style";
import { ITourExcelData, ITourExcelModalProps } from "../interface";
import { TourFieldNames } from "../../../models";

const TourExcelModal: FC<ITourExcelModalProps> = ({
  open,
  onBack,
  onSave,
  data = [],
}) => {
  const { words, isRtl } = useLanguage();

  useEffect(() => {}, [data]);

  const columns: ColumnsType<ITourExcelData> = [
    {
      title: `#`,
      dataIndex: "key",
      key: "key",
    },
    {
      title: `${words.viewTour.fromCity}`,
      dataIndex: TourFieldNames.FromCity,
      key: TourFieldNames.FromCity,
    },
    {
      title: `${words.viewTour.betweenCity}`,
      dataIndex: TourFieldNames.BetweenCity,
      key: TourFieldNames.BetweenCity,
    },
    {
      title: `${words.viewTour.toCity}`,
      dataIndex: TourFieldNames.ToCity,
      key: TourFieldNames.ToCity,
    },
    {
      title: `${words.viewTour.flyTime}`,
      dataIndex: TourFieldNames.FlyTime,
      key: TourFieldNames.FlyTime,
    },
    {
      title: `${words.viewTour.persianDate}`,
      dataIndex: TourFieldNames.PersianDate,
      key: TourFieldNames.PersianDate,
    },
    {
      title: `${words.viewTour.date}`,
      dataIndex: TourFieldNames.Date,
      key: TourFieldNames.Date,
    },
    {
      title: `${words.viewTour.places}`,
      dataIndex: TourFieldNames.Places,
      key: TourFieldNames.Places,
    },
    {
      title: `${words.viewTour.hotel}`,
      dataIndex: TourFieldNames.Hotel,
      key: TourFieldNames.Hotel,
    },
  ];

  const ModalHeader = () => {
    return (
      <ModalHeaderContainer>
        {words.addEditTour.tourExcelModalTitle}
      </ModalHeaderContainer>
    );
  };

  return (
    <Modal
      title={<ModalHeader />}
      open={open}
      footer={null}
      onCancel={onBack}
      centered
      width={"90%"}
    >
      <ModalContentContainer>
        <TableUI
          scroll={{ x: 400 }}
          dataSource={data}
          columns={columns}
          tableType="secondary"
        />
        <div className="footer" dir={isRtl ? "rtl" : "ltr"}>
          <Button
            type="text"
            className="save-btn"
            icon={<span className="material-icons">done</span>}
            onClick={onSave}
          >
            {words.global.save}
          </Button>
          <Button
            type="text"
            className="cancel"
            icon={<span className="material-icons">arrow_back</span>}
            dir={isRtl ? "ltr" : "rtl"}
            onClick={onBack}
          >
            {words.global.back}
          </Button>
        </div>
      </ModalContentContainer>
    </Modal>
  );
};

export default TourExcelModal;
