import { Form } from "antd";
import _ from "lodash";
import { useBrokersData } from "../context/BrokersData";

export const useFormHasChanged = () => {
  const {
    states: { selectedBroker, addBrokerForm },
  } = useBrokersData();

  const allValues = Form.useWatch([], addBrokerForm);

  const changed = _.isEqual(
    _.omit(allValues, ["id"]),
    _.omit(selectedBroker, ["id"])
  );
  return selectedBroker ? changed : false;
};
