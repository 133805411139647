import { Input } from "antd";
import styled from "styled-components";

const { TextArea } = Input;

export const TextAreaStyled = styled(TextArea)`
  color: rgb(211 211 211);
  font: inherit;
  letter-spacing: inherit;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  display: block;
  width: 98%;
  padding: 16.5px 14px;
  resize: none !important;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: 0;
  color: #1f1f1f !important;
  font-weight: 400;
  font-size: 12px;

  &:hover {
    border-inline-end-width: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &:focus {
    box-shadow: none !important;
    border: 0 !important;
    background-color: transparent !important;
  }

  .ant-form-item-explain-error {
    text-align: right !important;
  }
  &.ant-input-status-error {
    box-shadow: none !important;
    border: 0 !important;
    outline: none !important;
    background-color: #f5f5f5 !important;
  }
  &.ant-input-status-error:focus {
    border-inline-end-width: 0;
    box-shadow: none !important;
    background-color: #f5f5f5 !important;
  }
`;
