import { FC } from "react";
import { DomesticFlight } from "src/modules/managementDomesticFlight";
import { DomesticFlightProvider } from "src/modules/managementDomesticFlight/context";

export const DomesticFlightPage: FC = () => {
  return (
    <DomesticFlightProvider>
      <DomesticFlight />
    </DomesticFlightProvider>
  );
};
