import { FC, useEffect, useState } from "react";
import { Modal, Button, Row, Form, Input } from "antd";
import useLanguage from "src/store/language";
import { TableUI } from "src/components/UiKit/table";
import { TourFieldNames } from "src/modules/addEditTour/models";
import { IObject } from "src/models/interfaces";
import { ITourInfo } from "src/services/Tour/models";
import { useTourInfoRequest } from "../TourRequest";
import { IAddTouristModalProps } from "../interface";
import { AddTouristModalBodyContainer } from "../style";

const AddTouristModal: FC<IAddTouristModalProps> = ({
  showConfirm,
  setAddModal,
  tour,
}) => {
  const [addLoading, setAddLoading] = useState<boolean>(false);

  const [addTourForm] = Form.useForm();

  const { words } = useLanguage();
  const { requests } = useTourInfoRequest();

  useEffect(() => {
    if (tour) {
      addTourForm.setFieldsValue({
        [TourFieldNames.FromCity]: tour.fromCity,
        [TourFieldNames.ToCity]: tour.toCity,
        [TourFieldNames.BetweenCity]: tour.betweenCity,
        [TourFieldNames.Hotel]: tour.hotel,
        [TourFieldNames.Places]: tour.places,
        [TourFieldNames.PersianDate]: tour.persianDate,
        [TourFieldNames.Date]: tour.date,
        [TourFieldNames.FlyTime]: tour.flyTime,
      });
    }
  }, [addTourForm, tour]);

  const column = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: `${words.viewTour.fromCity}`,
      dataIndex: TourFieldNames.FromCity,
      width: 100,
      render: () => {
        return (
          <Form.Item
            name={TourFieldNames.FromCity}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        );
      },
    },
    {
      title: `${words.viewTour.betweenCity}`,
      dataIndex: TourFieldNames.BetweenCity,
      render: () => {
        return (
          <Form.Item
            name={TourFieldNames.BetweenCity}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        );
      },
    },
    {
      title: `${words.viewTour.toCity}`,
      dataIndex: TourFieldNames.ToCity,
      render: () => {
        return (
          <Form.Item
            name={TourFieldNames.ToCity}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        );
      },
    },
    {
      title: `${words.viewTour.flyTime}`,
      dataIndex: TourFieldNames.FlyTime,
      width: 100,
      render: () => {
        return (
          <Form.Item
            name={TourFieldNames.FlyTime}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        );
      },
    },
    {
      title: `${words.viewTour.persianDate}`,
      dataIndex: TourFieldNames.PersianDate,
      render: () => {
        return (
          <Form.Item
            name={TourFieldNames.PersianDate}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        );
      },
    },
    {
      title: `${words.viewTour.date}`,
      dataIndex: TourFieldNames.Date,
      render: () => {
        return (
          <Form.Item
            name={TourFieldNames.Date}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        );
      },
    },
    {
      title: `${words.viewTour.places}`,
      dataIndex: TourFieldNames.Places,
      render: () => {
        return (
          <Form.Item
            name={TourFieldNames.Places}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        );
      },
    },
    {
      title: `${words.viewTour.hotel}`,
      dataIndex: TourFieldNames.Hotel,
      render: () => {
        return (
          <Form.Item
            name={TourFieldNames.Hotel}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        );
      },
    },
  ];

  const handleCancel = () => {
    setAddModal(false);
    addTourForm.resetFields();
  };

  const onOk = (values: IObject) => {
    setAddLoading(true);

    requests
      .addTourRequest(values as ITourInfo, tour ? tour.id : 0)
      .finally(() => {
        setAddLoading(false);
        if (!tour) addTourForm.resetFields();
      });
  };

  return (
    <Modal
      title={words.addEditTour.addNewRow}
      open={showConfirm}
      onOk={onOk}
      onCancel={handleCancel}
      footer={false}
      width={"90%"}
    >
      <AddTouristModalBodyContainer>
        <Form form={addTourForm} onFinish={onOk}>
          <TableUI
            scroll={{ x: 900 }}
            dataSource={[tour ? tour : { id: "1" }]}
            columns={column}
            pagination={false}
            tableType="secondary"
            bordered={false}
          />

          <Row gutter={[12, 12]}>
            <Button
              htmlType="submit"
              loading={addLoading}
              type="text"
              icon={<span className="material-icons">done</span>}
              className="btn-ok"
            >
              {words.global.save}
            </Button>
            <Button type="text" onClick={handleCancel} className="btn-cancel">
              {words.addEditTour.cancel}
            </Button>
          </Row>
        </Form>
      </AddTouristModalBodyContainer>
    </Modal>
  );
};

export default AddTouristModal;
