export enum TabMenuKeys {
  tourist = "tourist",
  team = "team",
  tour = "tour",
  insurance = "insurance",
  attachment = "attachment",
  manager = "manager",
  leader = "leader",
  translator = "translator",
  driver = "driver",
  car = "car",
}
