import { FC } from "react";
import { Col } from "antd";
import BuyInfo from "./components/BuyInfo";
import { SimcardAddEditTabsContainer } from "./style";

const SimcardAddEdit: FC = () => {
  return (
    <SimcardAddEditTabsContainer>
      <Col span={24}>
        <BuyInfo />
      </Col>
    </SimcardAddEditTabsContainer>
  );
};

export default SimcardAddEdit;
