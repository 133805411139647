import { Col, Row } from "antd";
import styled from "styled-components";

export const ManagementLeaderContainer = styled(Row)`
  padding: 32px 0;
  .header {
    border-bottom: 1px solid var(--shadow, #dadeec);
    display: flex;
    padding: 0 24px 24px 24px;
    align-items: center;
    gap: 10px;
    color: var(--text-3, #5d6881);
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
  }
  .content {
    border-radius: 16px;
    background: var(--0, #fff);
    box-shadow: 2px 6px 16px 0px rgba(0, 0, 0, 0.18);
    padding: 24px 32px;
  }
  .view-details {
    display: flex;
    padding: 24px 16px;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px 24px;
    align-self: stretch;
    flex-wrap: wrap;
    border-radius: 8px;
    background: var(--color-Primary-Primary-100, #fef6f1);
  }
  .title {
    color: var(--color-Light-Mode-Text-Title, #233069);
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
  }

  .footer-box {
    width: 100%;
    display: inline-flex;
    justify-content: end;
    align-items: center;
  }
  .ant-input {
    color: #000000;
  }
  .add-hotel-form-title {
    color: var(--color-Light-Mode-Text-Title, #233069);
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .hotel-table-title {
    color: var(--color-Light-Mode-Text-Primary-text, #1f1f1f);
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .delete-btn {
    color: #f06614;
    border-radius: 8px;
    border: solid 1px #f06614;
    background-color: #ffffff;
    width: 85px;
    font-weight: 500;
    font-size: 12px;
  }
  .delete-btn:hover {
    color: #f06614 !important;
    border: solid 1px #f06614 !important;
    background-color: #ffffff;
  }
`;

export const LeaderImageContainer = styled(Col)`
  .image-box {
    background-color: #fafafa;
    padding: 16px 24px 32px 24px;
    border-radius: 12px;
  }
  .image-box-title {
    font-weight: 500;
    font-size: 16px;
    color: #233069;
  }
  .image-box-description {
    font-weight: 400;
    font-size: 14px;
    color: #233069;
    margin-top: 16px;
  }
  .ant-upload-list-picture-card {
    text-align: left;
  }
  .ant-upload {
    border: dashed 1px #f06614;
    border-radius: 8px;
  }
  .ant-upload:hover {
    border: dashed 1px #f06614 !important;
  }
  .add-image-container {
    width: 100%;
    height: 100%;
    background-color: #fef1ea;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px;
    .text {
      color: #f06614;
      font-weight: 400;
      font-size: 10px;
    }
    .plus {
      color: #f06614;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .edi-img-box {
    width: 100%;
    height: 216px;
    border-radius: 24px;
    position: relative;
  }
  img {
    border-radius: 24px;
  }
  .edit-img-delete {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border-radius: 24px;
  }
  .edi-img-box:hover {
  }
  .edi-img-box:hover .edit-img-delete {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .ant-upload-wrapper .ant-upload-list .ant-upload-select,
  .ant-upload-wrapper .ant-upload-list .ant-upload {
    width: 66px !important;
    height: 66px !important;
  }
  .ant-upload-list-item-container {
    padding: 0;
    margin: 0;
    border-radius: 24px;
  }

  .ant-upload-list-item-container,
  .ant-upload-list-item-done,
  .ant-upload-list-item-thumbnail {
    width: 100% !important;
    height: 216px !important;
    padding: 0;
  }
  .ant-upload-list-item-image {
    border-radius: 24px !important;
    object-fit: unset !important;
  }
`;

export const ViewLeaderContainer = styled(Row)`
  .image-box {
    width: 49%;
    border-radius: 12px;
    background-color: #fef6f1;
    padding: 16px 24px 32px 24px;
  }
  .detail-title {
    width: 160px;
    color: #233069;
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
  }
  .detail-value {
    color: #4b4b4b;
    font-size: 14px;
    font-weight: 400;
  }
  .second {
    margin: 25px 0;
  }
  img {
    border-radius: 24px;
  }
  .images-title {
    color: var(--color-Light-Mode-Text-Title, #233069);
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 12px 0 24px 0;
  }
`;
