import ManagementDriver from "src/modules/managementDriver";
import ManagementDriverDataProvider from "src/modules/managementDriver/context";

const ManagementDriverPage = () => (
  <ManagementDriverDataProvider>
    <ManagementDriver />
  </ManagementDriverDataProvider>
);

export default ManagementDriverPage;
