import { FC } from "react";

const Sign: FC = () => {
  return (
    <div className="mt-10 font-[16px] font-bold px-[16px]">
      <p className="text-right">مدیر عامل شرکت میثاق گشت صدرا</p>
      <p className="text-right">آقای: </p>
    </div>
  );
};

export default Sign;
