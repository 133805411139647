export enum TourFieldNames {
  FromCity = "fromCity",
  BetweenCity = "betweenCity",
  ToCity = "toCity",
  FlyTime = "flyTime",
  PersianDate = "persianDate",
  Date = "date",
  Places = "places",
  Hotel = "hotel",
}
