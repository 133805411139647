import ManagementTime from "src/modules/managementTime";
import ManagementTimeDataProvider from "src/modules/managementTime/context";

const ManagementTimePage = () => (
  <ManagementTimeDataProvider>
    <ManagementTime />
  </ManagementTimeDataProvider>
);

export default ManagementTimePage;
