import { FC } from "react";
import { ColumnsType } from "antd/es/table";
import { convertDate } from "src/helper";
import { TableUI } from "src/components/UiKit/table";
import useLanguage from "src/store/language";
import { ITouristResult } from "src/services/Tour/models";
import { TouristsFieldNames } from "src/modules/addEditTour/models";
import { useTouristInfoRequest } from "./TouristInfoRequest";
import { useTourViewData } from "../../context/TourViewData";

const TouristList: FC = () => {
  const { states } = useTourViewData();
  const {
    states: { dataLoading },
  } = useTouristInfoRequest();

  const { words } = useLanguage();

  const columns: ColumnsType<ITouristResult> = [
    {
      title: `${words.viewTour.roomType}`,
      dataIndex: TouristsFieldNames.RoomType,
      key: TouristsFieldNames.RoomType,
    },
    {
      title: `${words.viewTour.lastName}`,
      dataIndex: TouristsFieldNames.LastName,
      key: TouristsFieldNames.LastName,
    },
    {
      title: `${words.viewTour.firstName}`,
      dataIndex: TouristsFieldNames.FirstName,
      key: TouristsFieldNames.FirstName,
    },
    {
      title: `${words.viewTour.sex}`,
      dataIndex: TouristsFieldNames.Sex,
      key: TouristsFieldNames.Sex,
      render: (value) => (value === 1 ? "M" : "F"),
    },
    {
      title: `${words.viewTour.passportNO}`,
      dataIndex: TouristsFieldNames.PassportNO,
      key: TouristsFieldNames.PassportNO,
    },
    {
      title: `${words.viewTour.birthDate}`,
      dataIndex: TouristsFieldNames.BirthDate,
      key: TouristsFieldNames.BirthDate,
      render: (value) => convertDate(value),
    },
    {
      title: `${words.viewTour.expireDate}`,
      dataIndex: TouristsFieldNames.ExpireDate,
      key: TouristsFieldNames.ExpireDate,
      render: (value) => convertDate(value),
    },
  ];

  return (
    <>
      <TableUI
        loading={dataLoading}
        scroll={{ x: 400 }}
        dataSource={states.formData?.tourist?.tourists || []}
        columns={columns}
        tableType="secondary"
        pagination={{
          position: ["bottomCenter"],
          total: states.formData?.tourist?.tourists?.length,
        }}
      />
    </>
  );
};

export default TouristList;
