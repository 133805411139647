import axios from "axios";
import { BasicInformationUrls } from "./urls";
import {
  IBroker,
  ICar,
  ICity,
  IContact,
  ICountry,
  IHotel,
  INewBrokerArg,
  INewCarArg,
  INewCityArg,
  INewContactArg,
  INewCountryArg,
  INewHotelArg,
  INewPlaceArg,
  INewResponderDetailArg,
  INewRoomArg,
  IPlace,
  IProvince,
  IResponderDetail,
  IResponderDetailById,
  IRoom,
} from "./models";
import { IDataModel } from "../models";

export class BasicInformationService {
  async Countries(search?: string) {
    let endpoint: string = search
      ? BasicInformationUrls.Countries.concat(search)
      : BasicInformationUrls.Countries;
    try {
      const response = await axios.get<IDataModel<ICountry[]>>(endpoint);
      return response;
    } catch (err) {
      return console.log(err);
    }
  }

  async DeleteCountry(id: number) {
    try {
      const response = await axios.delete<void>(
        BasicInformationUrls.BasicInfo.concat(id.toString()).concat(
          BasicInformationUrls.DeleteCountry
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async NewCountry(data: INewCountryArg) {
    try {
      const response = await axios.post<void>(
        BasicInformationUrls.NewCountry,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditCountry(id: number, data: INewCountryArg) {
    try {
      const response = await axios.put<void>(
        BasicInformationUrls.BasicInfo.concat(id.toString()).concat(
          BasicInformationUrls.EditCountry
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async Provinces(id?: number) {
    let endpoint: string = BasicInformationUrls.Provinces;
    if (id) endpoint = endpoint.concat(`?provinceId=${id}`);
    try {
      const response = await axios.get<IProvince[]>(endpoint);
      return response;
    } catch (err) {
      return console.log(err);
    }
  }

  async Cities(search?: string) {
    let endpoint: string = BasicInformationUrls.Cities;
    if (search) endpoint = endpoint.concat(search);
    try {
      const response = await axios.get<IDataModel<ICity[]>>(endpoint);
      return response;
    } catch (err) {
      return console.log(err);
    }
  }

  async DeleteCity(id: number) {
    try {
      const response = await axios.delete<void>(
        BasicInformationUrls.BasicInfo.concat(id.toString()).concat(
          BasicInformationUrls.DeleteCity
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async NewCity(data: INewCityArg) {
    try {
      const response = await axios.post<void>(
        BasicInformationUrls.NewCity,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditCity(id: number, data: INewCityArg) {
    try {
      const response = await axios.put<void>(
        BasicInformationUrls.BasicInfo.concat(id.toString()).concat(
          BasicInformationUrls.EditCity
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async Cars(search?: string) {
    let endpoint: string = BasicInformationUrls.Cars;
    if (search) endpoint = endpoint.concat(search);
    try {
      const response = await axios.get<IDataModel<ICar[]>>(endpoint);
      return response;
    } catch (err) {
      return console.log(err);
    }
  }
  async DeleteCar(id: number) {
    try {
      const response = await axios.delete<void>(
        BasicInformationUrls.BasicInfo.concat(id.toString()).concat(
          BasicInformationUrls.DeleteCar
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async NewCar(data: INewCarArg) {
    try {
      const response = await axios.post<void>(
        BasicInformationUrls.NewCar,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditCar(id: number, data: INewCarArg) {
    try {
      const response = await axios.put<void>(
        BasicInformationUrls.BasicInfo.concat(id.toString()).concat(
          BasicInformationUrls.EditCar
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async NewPlace(data: INewPlaceArg) {
    try {
      const response = await axios.post<void>(
        BasicInformationUrls.NewPlace,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async Places(search?: string) {
    let endpoint: string = BasicInformationUrls.Places;
    if (search) endpoint = endpoint = endpoint.concat(`?${search}`);

    try {
      const response = await axios.get<IDataModel<IPlace[]>>(endpoint);
      return response;
    } catch (err) {
      return console.log(err);
    }
  }

  async DeletePlace(id: number) {
    try {
      const response = await axios.delete<void>(
        BasicInformationUrls.BasicInfo.concat(id.toString()).concat(
          BasicInformationUrls.DeletePlace
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditPlace(id: number, data: INewPlaceArg) {
    try {
      const response = await axios.put<void>(
        BasicInformationUrls.BasicInfo.concat(id.toString()).concat(
          BasicInformationUrls.EditPlace
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async Hotels(search?: string) {
    let endpoint: string = BasicInformationUrls.Hotels;
    if (search) endpoint = endpoint.concat(search);
    try {
      const response = await axios.get<IDataModel<IHotel[]>>(endpoint);
      return response;
    } catch (err) {
      return console.log(err);
    }
  }

  async DeleteHotel(id: number) {
    try {
      const response = await axios.delete<void>(
        BasicInformationUrls.BasicInfo.concat(id.toString()).concat(
          BasicInformationUrls.DeleteHotel
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async NewHotel(data: INewHotelArg) {
    try {
      const response = await axios.post<number>(
        BasicInformationUrls.NewHotel,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditHotel(id: number, data: INewHotelArg) {
    try {
      const response = await axios.put<void>(
        BasicInformationUrls.BasicInfo.concat(id.toString()).concat(
          BasicInformationUrls.EditHotel
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async Contacts(hotelId: number, search?: string) {
    try {
      const response = await axios.get<IDataModel<IContact[]>>(
        BasicInformationUrls.BasicInfo.concat(hotelId.toString()).concat(
          BasicInformationUrls.Contacts.concat(search || "")
        )
      );
      return response;
    } catch (err) {
      return console.log(err);
    }
  }

  async NewContact(hotelId: number, data: INewContactArg) {
    try {
      const response = await axios.post<void>(
        BasicInformationUrls.BasicInfo.concat(hotelId.toString()).concat(
          BasicInformationUrls.NewContact
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteContact(id: number) {
    try {
      const response = await axios.delete<void>(
        BasicInformationUrls.BasicInfo.concat(id.toString()).concat(
          BasicInformationUrls.DeleteContact
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditContact(id: number, data: INewContactArg) {
    try {
      const response = await axios.put<void>(
        BasicInformationUrls.BasicInfo.concat(id.toString()).concat(
          BasicInformationUrls.EditContact
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async NewResponderDetail(data: INewResponderDetailArg) {
    try {
      const response = await axios.post<void>(
        BasicInformationUrls.BasicInfo.concat(
          data.responderId.toString()
        ).concat(BasicInformationUrls.NewResponderDetail),
        { phoneNumber: data.phoneNumber }
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async ResponderDetails(hotelId: number, search?: string) {
    try {
      const response = await axios.get<IDataModel<IResponderDetail[]>>(
        BasicInformationUrls.BasicInfo.concat(hotelId.toString()).concat(
          BasicInformationUrls.ResponderDetail.concat(search || "")
        )
      );
      return response;
    } catch (err) {
      return console.log(err);
    }
  }

  async EditResponderDetail(
    responderDetailId: number,
    data: INewResponderDetailArg
  ) {
    try {
      const response = await axios.put<void>(
        BasicInformationUrls.BasicInfo.concat(
          responderDetailId.toString()
        ).concat(BasicInformationUrls.EditResponderDetail),
        { phoneNumber: data.phoneNumber }
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteResponderDetail(id: number) {
    try {
      const response = await axios.delete<void>(
        BasicInformationUrls.BasicInfo.concat(id.toString()).concat(
          BasicInformationUrls.DeleteResponderDetail
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async ResponderDetailsById(hotelId: number, search?: string) {
    try {
      const response = await axios.get<IDataModel<IResponderDetailById[]>>(
        BasicInformationUrls.BasicInfo.concat(hotelId.toString()).concat(
          BasicInformationUrls.ResponderDetailById.concat(search || "")
        )
      );
      return response;
    } catch (err) {
      return console.log(err);
    }
  }

  async NewRoom(data: INewRoomArg) {
    try {
      const response = await axios.post<void>(
        BasicInformationUrls.NewRoom,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async Rooms(search?: string) {
    let endpoint: string = BasicInformationUrls.Rooms;
    if (search) endpoint = endpoint.concat(`?${search}`);

    try {
      const response = await axios.get<IDataModel<IRoom[]>>(endpoint);
      return response;
    } catch (err) {
      return console.log(err);
    }
  }

  async DeleteRoom(id: number) {
    try {
      const response = await axios.delete<void>(
        BasicInformationUrls.BasicInfo.concat(id.toString()).concat(
          BasicInformationUrls.DeleteRoom
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditRoom(id: number, data: INewRoomArg) {
    try {
      const response = await axios.put<void>(
        BasicInformationUrls.BasicInfo.concat(id.toString()).concat(
          BasicInformationUrls.EditRoom
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async NewBroker(data: INewBrokerArg) {
    try {
      const response = await axios.post<void>(
        BasicInformationUrls.NewBroker,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async Brokers(search?: string) {
    let endpoint: string = BasicInformationUrls.Brokers;
    if (search) endpoint = endpoint.concat(`?${search}`);
    try {
      const response = await axios.get<IDataModel<IBroker[]>>(endpoint);
      return response;
    } catch (err) {
      return console.log(err);
    }
  }

  async DeleteBroker(id: number) {
    try {
      const response = await axios.delete<void>(
        BasicInformationUrls.BasicInfo.concat(id.toString()).concat(
          BasicInformationUrls.DeleteBroker
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditBroker(id: number, data: INewBrokerArg) {
    try {
      const response = await axios.put<void>(
        BasicInformationUrls.BasicInfo.concat(id.toString()).concat(
          BasicInformationUrls.EditBroker
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetHotelsByCity(cityId: number, search?: string) {
    let endpoint: string = BasicInformationUrls.AllHotelsByCity;
    if (search) endpoint = endpoint.concat(`?${search}`);

    try {
      const response = await axios.get<IDataModel<IHotel[]>>(
        BasicInformationUrls.BasicInfo.concat(cityId.toString()).concat(
          endpoint
        )
      );
      return response;
    } catch (err) {
      return console.log(err);
    }
  }
}
