import { FC } from "react";
import ResponderDetailsDataProvider from "./context";
import ResponderDetailsModal from "./ResponderDetailsModal";

const ResponderDetails: FC = () => {
  return (
    <ResponderDetailsDataProvider>
      <ResponderDetailsModal />
    </ResponderDetailsDataProvider>
  );
};

export default ResponderDetails;
