import { FC } from "react";
import { CreateRoleContainer } from "./styles";
import { Button, Col, Form, Row } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import { ConfirmModal } from "src/components/UiKit/ConfirmModal";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import TextAreaUiKit from "src/components/UiKit/textArea";
import { useCreateRoleContext } from "./context";
import { TreeSelectUiKit } from "src/components/UiKit/treeSelect";

export const CreateRole: FC = () => {
  const {
    onFinish,
    onCancelConfirm,
    onConfirm,
    showConfirm,
    treeProps,
    confirmLoading,
    createRoleForm,
  } = useCreateRoleContext();

  const { words } = useLanguage();

  return (
    <>
      <Form onFinish={onFinish} form={createRoleForm}>
        <CreateRoleContainer justify={"center"} gutter={[0, 24]}>
          <Col span={15} className="content">
            <Row gutter={[24, 12]}>
              <Col span={24} className="mb-[40px]">
                <div className="title">
                  {words.systemSettings.createRoleFormTitle}
                </div>
              </Col>
              <Col xs={24} lg={12}>
                <FormItem
                  name={"name"}
                  required
                  rules={[
                    { required: true, message: words.global.fieldRequired },
                  ]}
                >
                  <InputUiKit
                    label={words.systemSettings.rolName}
                    placeholder={words.systemSettings.roleSelectPlaceHolder}
                  />
                </FormItem>
              </Col>
              <Col xs={24} lg={12}>
                <FormItem name={"parentId"}>
                  <TreeSelectUiKit
                    {...treeProps}
                    multiple={false}
                    allowClear
                    label={words.systemSettings.seniorRole}
                    className="selectOption"
                    placeholder={words.systemSettings.seniorRolePlaceHolder}
                  />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  required
                  name={"comment"}
                  rules={[
                    { required: true, message: words.global.fieldRequired },
                  ]}
                >
                  <TextAreaUiKit
                    autoSize={{ minRows: 15, maxRows: 15 }}
                    className="s-textarea py-[16px] px-[12px]"
                    label={words.systemSettings.description}
                    placeholder={words.systemSettings.descriptionPlaceHolder}
                  />
                </FormItem>
              </Col>
              <Col span={24} className="flex gap-[24px] footer">
                <Button
                  type="text"
                  className="bg-[#FAD1B8] text-[#ffffff]"
                  htmlType="submit"
                >
                  {words.systemSettings.createButtonTitle}
                </Button>
              </Col>
            </Row>
          </Col>
        </CreateRoleContainer>
      </Form>
      <ConfirmModal
        open={showConfirm}
        onCancel={onCancelConfirm}
        onOk={onConfirm}
        text={words.systemSettings.createRoleConfirmMessage}
        title={words.systemSettings.createRoleConfirmTitle}
        okText={words.systemSettings.createButtonTitle}
        confirmLoading={confirmLoading}
      />
    </>
  );
};
