import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { IManagementInsurance } from "src/services/TourManagement/models";
interface IContextValue {
  states: {
    mode: string;
    attachmentModeProps: AttachmentModeProps;
  };
  func: {};
  dispatches: {
    setAttachmentModeProps: Dispatch<SetStateAction<AttachmentModeProps>>;
  };
  requests: {};
}

interface AttachmentModeProps {
  mode: "insurance" | "attachment";
  insurance: IManagementInsurance | undefined;
}

export const ManagementInsuranceDataContext = createContext<
  IContextValue | undefined
>(undefined);

const ManagementInsuranceDataProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [mode, setMode] = useState<string>("");
  const [attachmentModeProps, setAttachmentModeProps] =
    useState<AttachmentModeProps>({
      mode: "insurance",
      insurance: undefined,
    });

  const { state } = useLocation();

  useEffect(() => {
    if (state && state.mode) setMode(state.mode);
    else setMode("add");
  }, [state]);

  const contextValues = {
    states: {
      mode,
      attachmentModeProps,
    },
    func: {},
    dispatches: {
      setAttachmentModeProps,
    },
    requests: {},
  };

  return (
    <ManagementInsuranceDataContext.Provider value={contextValues}>
      {children}
    </ManagementInsuranceDataContext.Provider>
  );
};

export default ManagementInsuranceDataProvider;

export const useManagementInsuranceData = () =>
  useContext(ManagementInsuranceDataContext)!;
