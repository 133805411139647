import { Flex } from "antd";
import { FC } from "react";
import { ICar } from "src/services/BasicInformation/models";
import { useCarDataContext } from "../context";

interface ITableActionsProps {
  item: ICar;
}

const TableActions: FC<ITableActionsProps> = ({ item }) => {
  const { handleDeleteId, setEditCar } = useCarDataContext();

  return (
    <>
      <Flex justify="center">
        <span
          className="material-icons text-[14px] text-[#0C9D61] cursor-pointer ml-1"
          onClick={() => setEditCar(item)}
        >
          edit_note
        </span>
        <span
          className="material-icons text-[14px] text-[#F64C4C] cursor-pointer mr-1"
          onClick={() => handleDeleteId(item.id)}
        >
          delete_forever
        </span>
      </Flex>
    </>
  );
};

export default TableActions;
