import { FC } from "react";
import { DataEntryHeaderContainer } from "./style";

interface props {
  title: string;
}

const DataEntryHeader: FC<props> = ({ title }) => {
  return (
    <DataEntryHeaderContainer>
      <span className="material-symbols-outlined icon">width_normal</span>
      <span className="title">{title}</span>
    </DataEntryHeaderContainer>
  );
};

export default DataEntryHeader;
