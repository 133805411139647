import { FC } from "react";
import { TableUI } from "src/components/UiKit/table";
import { ColumnsType } from "antd/es/table";
import { ITableRouteRoom } from "src/services/TourManagement/models";
import useLanguage from "src/store/language";
import TableActions from "./TableActions";
import { useRouteHotelsData } from "../../context";

const RouteHotelsTable: FC = () => {
  const {
    states: {
      routeRooms,
      dataLoading,
      totalCount,
      tablePaginationParams,
      selectedRouteRoom,
      mode,
    },
    dispatches: { setTablePaginationParams },
  } = useRouteHotelsData();

  const { words } = useLanguage();

  const tableColumns: ColumnsType<ITableRouteRoom> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (text, record, index) =>
        (tablePaginationParams.Offset - 1) * tablePaginationParams.Limit +
        index +
        1,
    },
    {
      title: `${words.managementRoute.hotel}`,
      dataIndex: "hotelName",
      key: "hotelName",
    },
    {
      title: `${words.managementRoute.roomType}`,
      dataIndex: "name",
      key: "name",
      render: (value) => (value && value !== "" ? value : "-"),
    },
    {
      title: `${words.managementRoute.roomCount}`,
      dataIndex: "count",
      key: "count",
    },
    ...(mode === "view"
      ? []
      : [
          {
            title: `${words.basicInfo.tableActions}`,
            dataIndex: "tools",
            key: "tools",
            render: (_: any, record: ITableRouteRoom) => (
              <TableActions item={record} />
            ),
          },
        ]),
  ];

  return (
    <>
      <p className="hotel-table-title">
        {words.managementRoute.hotelTableTitle}
      </p>
      <TableUI
        columns={tableColumns}
        dataSource={routeRooms}
        loading={dataLoading}
        scroll={{ x: 500 }}
        tableType="secondary"
        rowClassName={(record) =>
          record.routeRoomId === selectedRouteRoom?.routeRoomId
            ? "selected-row-for-edit"
            : ""
        }
        pagination={{
          position: ["bottomCenter"],
          total: totalCount,
          pageSize: tablePaginationParams.Limit,
          current: tablePaginationParams.Offset,
          onChange(page, pageSize) {
            setTablePaginationParams((prev) => ({
              ...prev,
              Offset: page,
              Limit: pageSize,
            }));
          },
        }}
      />
    </>
  );
};

export default RouteHotelsTable;
