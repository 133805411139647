import { FC } from "react";
import { Col, Spin } from "antd";
import ViewCompanionLeader from "./components/ViewCompanionLeader";
import AddEditCompanionLeader from "./components/AddEditCompanionLeader";
import { ManagementCompanionLeaderContainer } from "./style";
import { useManagementCompanionLeaderData } from "./context";

const ManagementCompanionLeader: FC = () => {
  const {
    states: { mode, dataLoading },
  } = useManagementCompanionLeaderData();

  return (
    <ManagementCompanionLeaderContainer justify={"center"} gutter={[0, 24]}>
      <Col xs={24} md={mode === "view" ? 18 : 16} className="content">
        {dataLoading ? (
          <div className="flex w-full h-full justify-center items-center">
            <Spin />
          </div>
        ) : mode === "view" ? (
          <ViewCompanionLeader />
        ) : (
          <AddEditCompanionLeader />
        )}
      </Col>
    </ManagementCompanionLeaderContainer>
  );
};

export default ManagementCompanionLeader;
