import { FC } from "react";
import { TreeSelect } from "antd";
import { ITreeSelect } from "./interface/ITreeSelect";
import { TreeSelectStyle } from "./style";
import FormControlUiKit from "../FormControl";
import useLanguage from "src/store/language";

export const TreeSelectUiKit: FC<ITreeSelect> = (props) => {
  const { isRtl } = useLanguage();
  return (
    <FormControlUiKit label={props.label} height={"45px"}>
      <TreeSelectStyle dir={isRtl ? "rtl" : "ltr"}>
        <TreeSelect {...props} />
      </TreeSelectStyle>
    </FormControlUiKit>
  );
};
