import { FC } from "react";
import { Tooltip } from "antd";
import { TableUI } from "src/components/UiKit/table";
import { ColumnsType } from "antd/es/table";
import { IHotel } from "src/services/BasicInformation/models";
import useLanguage from "src/store/language";
import { Icons } from "src/components/customIcons";
import { getRowKey } from "src/helper/getRowKey";
import TableActions from "./TableActions";
import ResponderDetails from "./modal";
import { useHotelsTableData } from "../context";

const TableHotel: FC = () => {
  const {
    hotels,
    dataLoading,
    totalCount,
    tablePaginationParams,
    setTablePaginationParams,
    setResponder,
  } = useHotelsTableData();

  const { words } = useLanguage();

  const tableColumns: ColumnsType<IHotel> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (text, record, index) =>
        (tablePaginationParams.Offset - 1) * tablePaginationParams.Limit +
        index +
        1,
    },
    {
      title: "نام هتل",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "نام شهر",
      dataIndex: "cityName",
      key: "cityName",
    },
    {
      title: "تعداد ستاره",
      dataIndex: "star",
      key: "star",
    },
    {
      title: "شماره تماس",
      dataIndex: "contact",
      key: "contact",
      render: (_, record) => (
        <>
          <span
            className="material-icons text-[#4B4B4B] text-[16px] cursor-pointer"
            onClick={() => setResponder(record)}
          >
            contact_phone
          </span>
        </>
      ),
    },
    {
      title: "آدرس",
      dataIndex: "address",
      key: "address",
      render: (value) => (
        <div className="flex justify-center">
          <Tooltip
            placement="bottom"
            color="#4B4B4B"
            title={value && value !== "" ? value : "-"}
          >
            <div>
              <Icons name="address" />
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: `${words.basicInfo.tableActions}`,
      dataIndex: "tools",
      key: "tools",
      render: (_, record) => <TableActions item={record} />,
    },
  ];

  return (
    <>
      <TableUI
        columns={tableColumns}
        dataSource={hotels}
        loading={dataLoading}
        scroll={{ x: 860 }}
        tableType="secondary"
        rowKey={getRowKey}
        pagination={{
          position: ["bottomCenter"],
          total: totalCount,
          pageSize: tablePaginationParams.Limit,
          current: tablePaginationParams.Offset,
          onChange(page, pageSize) {
            setTablePaginationParams((prev) => ({
              ...prev,
              Offset: page,
              Limit: pageSize,
            }));
          },
        }}
      />
      <ResponderDetails />
    </>
  );
};

export default TableHotel;
