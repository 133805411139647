export const useCurrentPath = (path: string) => {
  let currentPath: string = "";

  let pathArray = path.split("/");

  if (path.length > 0) currentPath = "/" + pathArray[1];
  else currentPath = path;

  return currentPath;
};
