import { Form } from "antd";
import _ from "lodash";
import { useCountryContext } from "./context";

export const useFormHasChanged = () => {
  const { editCountry, createCountryForm } = useCountryContext();

  const allValues = Form.useWatch([], createCountryForm);

  const changed = _.isEqual(
    _.omit(allValues, ["id"]),
    _.omit(editCountry, ["id"])
  );
  return editCountry ? changed : false;
};
