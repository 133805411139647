import { FC, useEffect, useState } from "react";
import useAuthStore from "../store/auth";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { definitionRoutes } from "./types/definitionRoutes";
import useRoutesDefinition from "./definition";
import { Skeleton } from "antd";
import { useAxiosConfiguration } from "src/hook/useAxiosConfig";

export const Routers: FC = (): JSX.Element => {
  useAxiosConfiguration();
  const { token } = useAuthStore();
  const { routesDefinition } = useRoutesDefinition();

  const [currentRoutes, setCurrentRoutes] = useState<definitionRoutes>(
    token ? "private" : "public"
  );

  useEffect(() => {
    setCurrentRoutes(token ? "private" : "public");
  }, [token]);

  if (!currentRoutes) return <Skeleton />;

  const routes = createBrowserRouter([...routesDefinition[currentRoutes]]);

  return <RouterProvider router={routes} />;
};
