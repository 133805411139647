import { FC } from "react";
import { Col, Row } from "antd";
import Filter from "./Filter";
import FilterContent from "./FilterContent";
import { useTourViewData } from "../context/TourViewData";
import { PrintTour } from "./printTour";
import { PrintTourist } from "./printTourist";
import PrintTourRequestProvider from "./printTour/context";
import PrintTouristRequestProvider from "./printTourist/context";

const TourViewMode: FC = () => {
  const {
    states: { viewMode },
  } = useTourViewData();
  return (
    <Row align={"middle"} justify={"center"} gutter={[4, 24]}>
      {viewMode === "view" ? (
        <>
          {/* <Col span={24}><Pages /></Col> */}
          <Col span={24}>
            <Filter />
          </Col>
          <Col span={23}>
            <FilterContent />
          </Col>
        </>
      ) : viewMode === "print-tour" ? (
        <Col span={24}>
          <PrintTourRequestProvider>
            <PrintTour />
          </PrintTourRequestProvider>
        </Col>
      ) : (
        <Col span={24}>
          <PrintTouristRequestProvider>
            <PrintTourist />
          </PrintTouristRequestProvider>
        </Col>
      )}
    </Row>
  );
};

export default TourViewMode;
