import { Row } from "antd";
import styled from "styled-components";

export const ManagementTourContainer = styled.div`
  .ant-card-body {
    padding: 0px !important;
    border-radius: 16px !important;
  }
  .card {
    border-radius: 16px;
    padding: 16px 8px;
  }
  .active-border {
    border: 2px solid var(--color-Success-Success-600, #47b881);
  }
  .img {
    display: block;
    width: 24px;
    height: 24px;
  }
  .card-title {
    border-radius: 16px 16px 0px 0px;
    background: var(--color-Gray-Gray-300, #e1e1e1);
    padding: 16px 24px;
    color: var(--color-Light-Mode-Text-Secondary-Text, #4b4b4b);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .card-body {
    border-radius: 0px 0px 16px 16px;
    background: var(--color-Gray-Gray-100, #f5f5f5);
    padding: 24px;
    margin-top: 5px;
  }
`;

export const ManagementTourDetailsContainer = styled(Row)`
  /* margin-top: 24px; */
  padding: 32px 24px;
  .ant-col {
    height: 114px;
    padding: 12px;
    border-radius: 14px;
    background: var(--color-Base-Color-White, #fff);
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
  }
  .box-title {
    color: var(--color-Light-Mode-Text-Secondary-Text, #4b4b4b);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .date {
    color: var(--color-Light-Mode-Text-Third-Text, #8e8e8e);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 5px;
  }
  .arrow {
    width: 32px;
    height: 32px;
    padding: 4px 8px;
    border-radius: 4px;
    background: var(--color-Gray-Gray-100, #f5f5f5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .notification {
    color: var(--color-Light-Mode-Text-Third-Text, #8e8e8e);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .start-end {
    color: var(--color-Light-Mode-Text-Secondary-Text, #4b4b4b);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .not-registered {
    color: var(--color-Icon-Delete, #ec2d30);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .day {
    color: var(--color-Light-Mode-Text-Secondary-Text, #4b4b4b);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .tourist {
    color: var(--color-Light-Mode-Text-Third-Text, #8e8e8e);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .no-amount {
    color: var(--color-Icon-Delete, #ec2d30);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .start-end-date {
    color: var(--color-Light-Mode-Text-Third-Text, #8e8e8e);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
