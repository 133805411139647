import {
  AlignmentType,
  Document,
  Paragraph,
  TextRun,
  BorderStyle,
  Table,
  TableRow,
  TableCell,
  VerticalAlign,
  WidthType,
} from "docx";
import { convertDate } from "src/helper";
import { IObject } from "src/models/interfaces";
import { IFormDataProps } from "src/modules/addEditTour/interface";
import { ITourInfoResult } from "src/services/Tour/models";

export class DocumentCreator {
  public create(data: IFormDataProps | undefined): Document {
    const document = new Document({
      sections: [
        {
          properties: {
            page: {
              margin: {
                top: `${10}mm`,
                right: `${5}mm`,
                bottom: `${10}mm`,
                left: `${5}mm`,
              },
            },
          },
          children: [
            this.createDateNumber(),
            this.createLetter(),
            this.createSpace(),
            this.createDetails(data?.tourInfo),
            this.createSpace(),
            this.createColumns(data?.tourInfo),
            this.createSpace(),
            this.createTable(data?.tour as ITourInfoResult[]),
            this.createSign(),
          ],
        },
      ],
    });

    return document;
  }

  public createDateNumber(): Paragraph {
    return new Paragraph({
      alignment: AlignmentType.LEFT,
      children: [
        new TextRun({
          text: `${convertDate(new Date().toDateString())} :تاریخ `,
        }),
        new TextRun({
          text: `           :شماره نامه `,
          break: 2,
        }),
      ],
    });
  }

  public createLetter(): Paragraph {
    return new Paragraph({
      alignment: AlignmentType.RIGHT,
      children: [
        new TextRun({
          text: "ریاست محترم پلیس مهاجرت و گذرنامه ناجا-حراست ویژه",
          break: 2,
          bold: true,
        }),
        new TextRun({
          text: "باسلام و احترام",
          break: 2,
        }),
        new TextRun({
          text: ".برنامه سفر و فهرست اسامی مسافرین به شرح زیر جهت صدور کد ناجا ارسال می گردد",
          break: 2,
        }),
        new TextRun({
          text: ".گفتنی است صحت مشخصات بر عهده این دفتر/شرکت می باشد",
          bold: true,
          size: 20,
          break: 2,
        }),
      ],
    });
  }

  public createDetails(tourInfo?: IObject): Paragraph {
    return new Paragraph({
      alignment: AlignmentType.CENTER,
      border: {
        top: {
          color: "#CACACA",
          space: 1,
          style: BorderStyle.SINGLE,
          size: 6,
        },
        right: {
          color: "#CACACA",
          space: 1,
          style: BorderStyle.SINGLE,
          size: 6,
        },
        bottom: {
          color: "#CACACA",
          space: 1,
          style: BorderStyle.SINGLE,
          size: 6,
        },
        left: {
          color: "#CACACA",
          space: 1,
          style: BorderStyle.SINGLE,
          size: 6,
        },
      },
      children: [
        new TextRun({
          text: ` روز ${tourInfo?.duration ? tourInfo?.duration : "-"}`.concat(
            " : ".concat("مدت تور")
          ),
          bold: true,
        }),
        new TextRun({
          text: `\t نفر  ${tourInfo?.touristNumber}`.concat(
            " : ".concat("تعداد جهان گردان")
          ),
          bold: true,
        }),
        new TextRun({
          text: `\t${tourInfo?.nation}`.concat(" : ".concat("ملیت جهان گردان")),
          bold: true,
        }),
        new TextRun({
          text: "\t\tمیثاق گشت صدرا".concat(" : ".concat("نام آژانس")),
          bold: true,
        }),
      ],
    });
  }

  public createTableData(tours?: IObject[]): TableRow[] {
    const tableRow: TableRow[] = [];

    tours?.forEach((tour) => {
      const row = new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: tour?.places,
                  }),
                ],
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
            borders: {
              top: {
                color: "#ffffff",
                space: 0,
                style: BorderStyle.SINGLE,
                size: 0,
              },
              bottom: {
                color: "#CACACA",
                space: 1,
                style: BorderStyle.SINGLE,
                size: 6,
              },
              right: {
                color: "#ffffff",
                space: 0,
                style: BorderStyle.SINGLE,
                size: 0,
              },
              left: {
                color: "#ffffff",
                space: 0,
                style: BorderStyle.SINGLE,
                size: 0,
              },
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: tour?.hotel,
                  }),
                ],
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
            borders: {
              top: {
                color: "#ffffff",
                space: 0,
                style: BorderStyle.SINGLE,
                size: 0,
              },
              bottom: {
                color: "#CACACA",
                space: 1,
                style: BorderStyle.SINGLE,
                size: 6,
              },
              right: {
                color: "#ffffff",
                space: 0,
                style: BorderStyle.SINGLE,
                size: 0,
              },
              left: {
                color: "#ffffff",
                space: 0,
                style: BorderStyle.SINGLE,
                size: 0,
              },
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: tour?.toCity,
                  }),
                ],
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
            borders: {
              top: {
                color: "#ffffff",
                space: 0,
                style: BorderStyle.SINGLE,
                size: 0,
              },
              bottom: {
                color: "#CACACA",
                space: 1,
                style: BorderStyle.SINGLE,
                size: 6,
              },
              right: {
                color: "#ffffff",
                space: 0,
                style: BorderStyle.SINGLE,
                size: 0,
              },
              left: {
                color: "#ffffff",
                space: 0,
                style: BorderStyle.SINGLE,
                size: 0,
              },
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: "-",
                  }),
                ],
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
            borders: {
              top: {
                color: "#ffffff",
                space: 0,
                style: BorderStyle.SINGLE,
                size: 0,
              },
              bottom: {
                color: "#CACACA",
                space: 1,
                style: BorderStyle.SINGLE,
                size: 6,
              },
              right: {
                color: "#ffffff",
                space: 0,
                style: BorderStyle.SINGLE,
                size: 0,
              },
              left: {
                color: "#ffffff",
                space: 0,
                style: BorderStyle.SINGLE,
                size: 0,
              },
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: tour?.date,
                  }),
                ],
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
            borders: {
              top: {
                color: "#ffffff",
                space: 0,
                style: BorderStyle.SINGLE,
                size: 0,
              },
              bottom: {
                color: "#CACACA",
                space: 1,
                style: BorderStyle.SINGLE,
                size: 6,
              },
              right: {
                color: "#ffffff",
                space: 0,
                style: BorderStyle.SINGLE,
                size: 0,
              },
              left: {
                color: "#ffffff",
                space: 0,
                style: BorderStyle.SINGLE,
                size: 0,
              },
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    text: tour?.persianDate,
                  }),
                ],
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
            borders: {
              top: {
                color: "#ffffff",
                space: 0,
                style: BorderStyle.SINGLE,
                size: 0,
              },
              bottom: {
                color: "#CACACA",
                space: 1,
                style: BorderStyle.SINGLE,
                size: 6,
              },
              right: {
                color: "#ffffff",
                space: 0,
                style: BorderStyle.SINGLE,
                size: 0,
              },
              left: {
                color: "#ffffff",
                space: 0,
                style: BorderStyle.SINGLE,
                size: 0,
              },
            },
          }),
        ],
      });

      tableRow.push(row);
    });
    return tableRow;
  }

  public createTable(tours?: IObject[]): Table {
    return new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "مکان های دیدنی",
                      size: 24,
                      color: "#000000",
                      bold: true,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              margins: {
                top: 50,
                bottom: 50,
              },
              borders: {
                top: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                bottom: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                right: {
                  color: "#ffffff",
                  space: 0,
                  style: BorderStyle.SINGLE,
                  size: 0,
                },
                left: {
                  color: "#ffffff",
                  space: 0,
                  style: BorderStyle.SINGLE,
                  size: 0,
                },
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "محل اقامت",
                      size: 24,
                      color: "#000000",
                      bold: true,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              margins: {
                top: 50,
                bottom: 50,
              },
              borders: {
                top: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                bottom: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                right: {
                  color: "#ffffff",
                  space: 0,
                  style: BorderStyle.SINGLE,
                  size: 0,
                },
                left: {
                  color: "#ffffff",
                  space: 0,
                  style: BorderStyle.SINGLE,
                  size: 0,
                },
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "شهر",
                      size: 24,
                      color: "#000000",
                      bold: true,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              margins: {
                top: 50,
                bottom: 50,
              },
              borders: {
                top: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                bottom: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                right: {
                  color: "#ffffff",
                  space: 0,
                  style: BorderStyle.SINGLE,
                  size: 0,
                },
                left: {
                  color: "#ffffff",
                  space: 0,
                  style: BorderStyle.SINGLE,
                  size: 0,
                },
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "استان",
                      size: 24,
                      color: "#000000",
                      bold: true,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              margins: {
                top: 50,
                bottom: 50,
              },
              borders: {
                top: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                bottom: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                right: {
                  color: "#ffffff",
                  space: 0,
                  style: BorderStyle.SINGLE,
                  size: 0,
                },
                left: {
                  color: "#ffffff",
                  space: 0,
                  style: BorderStyle.SINGLE,
                  size: 0,
                },
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "تاریخ میلادی",
                      size: 24,
                      color: "#000000",
                      bold: true,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              margins: {
                top: 50,
                bottom: 50,
              },
              borders: {
                top: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                bottom: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                right: {
                  color: "#ffffff",
                  space: 0,
                  style: BorderStyle.SINGLE,
                  size: 0,
                },
                left: {
                  color: "#ffffff",
                  space: 0,
                  style: BorderStyle.SINGLE,
                  size: 0,
                },
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "تاریخ شمسی",
                      size: 24,
                      color: "#000000",
                      bold: true,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              margins: {
                top: 50,
                bottom: 50,
              },
              borders: {
                top: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                bottom: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                right: {
                  color: "#ffffff",
                  space: 0,
                  style: BorderStyle.SINGLE,
                  size: 0,
                },
                left: {
                  color: "#ffffff",
                  space: 0,
                  style: BorderStyle.SINGLE,
                  size: 0,
                },
              },
            }),
          ],
          tableHeader: true,
        }),
        ...this.createTableData(tours),
      ],
      width: { size: 100, type: WidthType.PERCENTAGE },
    });
  }

  public createSign(): Paragraph {
    return new Paragraph({
      alignment: AlignmentType.RIGHT,
      children: [
        new TextRun({
          text: "مدیر عامل شرکت میثاق گشت صدرا",
          bold: true,
          size: 24,
          break: 2,
        }),
        new TextRun({
          text: ":آقای",
          bold: true,
          size: 24,
          break: 2,
        }),
      ],
    });
  }

  public createSpace(): Paragraph {
    return new Paragraph({
      alignment: AlignmentType.RIGHT,
      children: [
        new TextRun({
          text: "",
          bold: true,
          size: 24,
          break: 2,
        }),
      ],
    });
  }

  public createColumns(tourInfo?: IObject): Table {
    return new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "مشخصات راننده و خودرو",
                      size: 24,
                      color: "#000000",
                      bold: true,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              borders: {
                top: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                bottom: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                right: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                left: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "مشخصات راهنما",
                      size: 24,
                      color: "#000000",
                      bold: true,
                    }),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              borders: {
                top: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                bottom: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                right: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                left: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
              },
            }),
          ],
          tableHeader: true,
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun(
                      `${
                        tourInfo?.driverInfo ? tourInfo?.driverInfo : "-"
                      } :نام پدر`
                    ),
                    new TextRun(
                      `\t\t${
                        tourInfo?.driverFatherName
                          ? tourInfo?.driverFatherName
                          : "-"
                      } :نام و نام خانوادگی`
                    ),

                    new TextRun(
                      `\t${
                        tourInfo?.driverIdNumber
                          ? tourInfo?.driverIdNumber
                          : "-"
                      } :ش.ش`
                    ),
                    new TextRun(
                      `\t${
                        tourInfo?.driverNationalCode
                          ? tourInfo?.driverNationalCode
                          : "-"
                      } :کد ملی`
                    ),

                    new TextRun(
                      `\t${
                        tourInfo?.driverBirthDate
                          ? convertDate(tourInfo?.driverBirthDate)
                          : "-"
                      } :ت.ت`
                    ),
                    new TextRun(
                      `\t\t${
                        tourInfo?.carInfo ? tourInfo?.carInfo : "-"
                      } :نوع خودرو`
                    ),

                    new TextRun(
                      `\t\t${
                        tourInfo?.carNumber ? tourInfo?.carNumber : "-"
                      } :شماره خودرو`
                    ),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              borders: {
                top: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                bottom: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                right: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                left: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.RIGHT,
                  children: [
                    new TextRun(
                      `${
                        tourInfo?.leaderInfo ? tourInfo?.leaderInfo : "-"
                      } :نام پدر`
                    ),
                    new TextRun(
                      `\t\t${
                        tourInfo?.leaderFatherName
                          ? tourInfo?.leaderFatherName
                          : "-"
                      } :نام و نام خانوادگی`
                    ),

                    new TextRun(
                      `\t${
                        tourInfo?.leaderIdNumber
                          ? tourInfo?.leaderIdNumber
                          : "-"
                      } :ش.ش`
                    ),
                    new TextRun(
                      `\t${
                        tourInfo?.leaderNationalCode
                          ? tourInfo?.leaderNationalCode
                          : "-"
                      } :کد ملی`
                    ),

                    new TextRun(
                      `\t ${
                        tourInfo?.leaderBirthDate
                          ? convertDate(tourInfo?.leaderBirthDate)
                          : "-"
                      } :ت.ت`
                    ),
                    new TextRun(
                      `\t\t${
                        tourInfo?.leaderPhone ? tourInfo?.leaderPhone : "-"
                      } :شماره تماس`
                    ),

                    new TextRun(
                      `\t\t${
                        tourInfo?.leaderGuidCardNumber
                          ? tourInfo?.leaderGuidCardNumber
                          : "-"
                      } :شماره کارت راهنما`
                    ),
                  ],
                }),
              ],
              verticalAlign: VerticalAlign.CENTER,
              borders: {
                top: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                bottom: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                right: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
                left: {
                  color: "#CACACA",
                  space: 1,
                  style: BorderStyle.SINGLE,
                  size: 6,
                },
              },
            }),
          ],
        }),
      ],
      width: { size: 100, type: WidthType.PERCENTAGE },
    });
  }
}
