import { FC } from "react";
import { ColumnsType } from "antd/es/table";
import { ITourExcelData } from "src/modules/addEditTour/components/tour/interface";
import { TourFieldNames } from "src/modules/addEditTour/models";
import useLanguage from "src/store/language";
import { TableUI } from "src/components/UiKit/table";
import { ITourInfoResult } from "src/services/Tour/models";
import { useTourViewData } from "src/modules/viewTour/context/TourViewData";
import { usePrintTourRequest } from "./context";

const TourTable: FC = () => {
  const { words } = useLanguage();

  const {
    states: { formData },
  } = useTourViewData();

  const {
    states: { dataLoadings },
  } = usePrintTourRequest();

  const columns: ColumnsType<ITourExcelData> = [
    {
      title: `${words.viewTour.fromCity}`,
      dataIndex: TourFieldNames.FromCity,
      key: TourFieldNames.FromCity,
    },
    {
      title: `${words.viewTour.betweenCity}`,
      dataIndex: TourFieldNames.BetweenCity,
      key: TourFieldNames.BetweenCity,
    },
    {
      title: `${words.viewTour.toCity}`,
      dataIndex: TourFieldNames.ToCity,
      key: TourFieldNames.ToCity,
    },
    {
      title: `${words.viewTour.flyTime}`,
      dataIndex: TourFieldNames.FlyTime,
      key: TourFieldNames.FlyTime,
    },
    {
      title: `${words.viewTour.persianDate}`,
      dataIndex: TourFieldNames.PersianDate,
      key: TourFieldNames.PersianDate,
    },
    {
      title: `${words.viewTour.places}`,
      dataIndex: TourFieldNames.Places,
      key: TourFieldNames.Places,
    },
    {
      title: `${words.viewTour.hotel}`,
      dataIndex: TourFieldNames.Hotel,
      key: TourFieldNames.Hotel,
    },
  ];
  return (
    <TableUI
      scroll={{ x: 400 }}
      loading={dataLoadings.tour}
      dataSource={formData?.tour as ITourInfoResult[]}
      columns={columns}
      tableType="secondary"
      pagination={false}
    />
  );
};

export default TourTable;
