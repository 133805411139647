import RoutePlaces from "src/modules/managementRoutePlace";
import RoutePlacesDataProvider from "src/modules/managementRoutePlace/context";

const ManagementRoutePlacePage = () => (
  <RoutePlacesDataProvider>
    <RoutePlaces />
  </RoutePlacesDataProvider>
);

export default ManagementRoutePlacePage;
