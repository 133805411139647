import { AxiosRequestConfig } from "axios";
import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import { useParams } from "react-router-dom";
import downloadZipFile from "src/helper/downloadZipFile";
import { TourService } from "src/services/Tour/Tour.service";
import { useTourViewData } from "../../context/TourViewData";

interface IContextValue {
  states: {
    dataLoading: boolean;
    files: FileList | undefined;
    progress: number;
  };
  dispatches: {
    setFiles: Dispatch<SetStateAction<FileList | undefined>>;
  };
  requests: {
    downloadInsuranceFileRequest: () => void;
  };
}

export const InsuranceRequestContext = createContext<IContextValue | undefined>(
  undefined
);

const InsuranceRequestProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<FileList | undefined>();
  const [progress, setProgress] = useState<number>(0);

  const { dispatches } = useTourViewData();
  const { tourId } = useParams();

  const downloadInsuranceFileRequest = async () => {
    if (!tourId) return;
    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 0)
        );
        setProgress(percentage);
        if (percentage === 100) {
          setTimeout(() => setProgress(0), 400);
        }
      },
    };
    try {
      const { DownloadInsuranceFile } = new TourService();

      const result = await DownloadInsuranceFile(tourId, config);
      if (result) {
        downloadZipFile(result);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProgress(0);
    }
  };

  const getInsuranceInfo = useCallback(async () => {
    if (!tourId) return;
    try {
      setDataLoading(true);
      const { GetInsuranceInfo } = new TourService();

      const result = await GetInsuranceInfo(tourId);
      if (result && result.status === 200) {
        dispatches.setFormData((prev) => ({
          ...prev,
          insurance: {
            ...prev?.insurance,
            insuranceType: result.data.insuranceType,
            insuranceCompany: result.data.insuranceCompany,
          },
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInsuranceFile = useCallback(async () => {
    if (!tourId) return;
    try {
      setProgress(100);
      const { GetInsuranceFile } = new TourService();

      const result = await GetInsuranceFile(tourId);
      if (result && result.status === 200) {
        dispatches.setFormData((prev) => ({
          ...prev,
          insurance: {
            ...prev?.insurance,
            file: result.data,
          },
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProgress(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tourId) {
      getInsuranceInfo();
      getInsuranceFile();
    }
  }, [getInsuranceFile, getInsuranceInfo, tourId]);

  const contextValues = {
    states: {
      dataLoading,
      files,
      progress,
    },
    dispatches: { setFiles },
    requests: { downloadInsuranceFileRequest },
  };

  return (
    <InsuranceRequestContext.Provider value={contextValues}>
      {children}
    </InsuranceRequestContext.Provider>
  );
};

export default InsuranceRequestProvider;

export const useInsuranceRequest = () => useContext(InsuranceRequestContext)!;
