import { Button, Col, Row } from "antd";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { PageFooter } from "./reservationStyle";
import { useHotelLetter } from "./context";
import { ReservationLetter } from "./reservationLetter";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { IGetExportHotelMail } from "src/services/ExportData/models";
import { ExportDataService } from "src/services/ExportData/ExportData.service";
import { createReservationMail } from "./createWord";
import { Packer } from "docx";
import { saveAs } from "file-saver";
import html2pdf from "html2pdf.js";
import { DownloadPdfIcon } from "../DownladPdfIcon";

export const Reservation: FC = () => {
  const {
    dispatches: { setShowPreview },
    values: { selectedRoute, hotelLetterInfo },
  } = useHotelLetter();
  const onClose = () => {
    setShowPreview(false);
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [data, setData] = useState<IGetExportHotelMail>();
  const [docxContent, setDocxContent] = useState<Blob | null>(null);

  const fetchData = useCallback(async () => {
    if (!selectedRoute) return;
    setLoading(true);
    try {
      const { GetExportHotelMail } = new ExportDataService();
      const res = await GetExportHotelMail(selectedRoute.id);
      if (res && res.status === 200) {
        setData(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [selectedRoute]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const componentRef = useRef<HTMLDivElement>(null);

  const print = useReactToPrint({
    content: () => componentRef.current,
    print: async (printIframe: HTMLIFrameElement) => {
      try {
        setDownloadLoading(true);
        const document = printIframe.contentDocument;

        if (document) {
          const html = document.getElementsByTagName("html")[0];
          const htmlRect = html.getBoundingClientRect();
          const pdfWith = 250;
          const pdfCurrentHeight = (pdfWith * htmlRect.height) / htmlRect.width;
          const pageCount = htmlRect.height / pdfWith;
          const roundedPageCount =
            Math.round(pageCount) < pageCount
              ? Math.ceil(pageCount)
              : Math.round(pageCount);
          const pdfHeight = pdfCurrentHeight * roundedPageCount;
          const opt = {
            jsPDF: {
              format: [pdfWith, pdfHeight],
            },
            image: { type: "jpeg", quality: 1 },
            html2canvas: { scale: 3 },
            unit: "px",
          };
          await html2pdf().from(html).set(opt).save("hotelReservation.pdf");
        }
      } catch (err) {
        console.log(err);
      } finally {
        setDownloadLoading(false);
      }
    },
  });
  const handlePrint = async () => {
    setDownloadLoading(true);
    print();
  };
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);

  const generateDocx = useCallback(() => {
    if (!data) return;
    const doc = createReservationMail(data, hotelLetterInfo);

    Packer.toBlob(doc).then((blob: Blob) => {
      setDocxContent(blob);
    });
  }, [data, hotelLetterInfo]);

  const downloadDocx = () => {
    if (docxContent) {
      saveAs(docxContent, "hotelReservation.docx");
    }
  };
  useEffect(() => {
    generateDocx();
  }, [generateDocx]);
  return (
    <Row>
      <Col span={24}>
        <ReservationLetter data={data} loading={loading} />
      </Col>
      {/* for pdf */}
      <Col span={24} className="hidden">
        <ReactToPrint content={reactToPrintContent} removeAfterPrint />

        <ReservationLetter
          data={data}
          loading={loading}
          componentRef={componentRef}
          isPdf
        />
      </Col>
      <Col span={24}>
        <Row>
          <Col span={24} className="pb-[32px]">
            <PageFooter className="pageFooter" justify="space-between">
              <Button type="default" onClick={onClose}>
                بستن
              </Button>

              <div className=" flex justify-center items-center gap[16px]">
                <DownloadPdfIcon
                  handlePrint={handlePrint}
                  loading={downloadLoading}
                />
                <img
                  src="/assets/images/docDownload.svg"
                  alt=""
                  onClick={downloadDocx}
                />
              </div>
            </PageFooter>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
