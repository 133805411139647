import { Input } from "antd";
import styled from "styled-components";

export const InputUiKitContainer = styled(Input)`
  color: #1f1f1f;
  font: inherit;
  letter-spacing: inherit;
  border: 0;
  background-color: #f5f5f5;
  height: 1.4375em;
  margin: 0;
  display: block;
  min-width: 0;
  width: 100%;
  padding: 16.5px 14px;
  font-weight: 500;
  font-size: 12px;

  &:hover,
  &.ant-input-disabled:hover,
  &.ant-input-disabled {
    border-inline-end-width: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &:focus,
  &.ant-input-disabled:focus {
    border-inline-end-width: 0;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active,
  &.ant-input-disabled:-webkit-autofill,
  &.ant-input-disabled:-webkit-autofill:hover,
  &.ant-input-disabled:-webkit-autofill:focus,
  &.ant-input-disabled:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  &::placeholder {
    color: #8e8e8e;
    font-weight: 500;
    font-size: 12px;
  }
  &.ant-input-status-error {
    box-shadow: none !important;
    border: 0 !important;
    outline: none !important;
    background-color: #f5f5f5 !important;
  }
  &.ant-input-status-error:focus {
    border-inline-end-width: 0;
    box-shadow: none !important;
    background-color: #f5f5f5 !important;
  }
`;
