import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Flex, Row } from "antd";

import ReactToPrint, { useReactToPrint } from "react-to-print";
import { PageFooter } from "./reservationStyle";
import { TouristListLetter } from "./touristListLetter";
import { useHotelLetter } from "./context";
import { generateHotelExcel } from "./createHotelExcel";
import { touristListColumns } from "./touristColumns";
import { IGetExportHotelList } from "src/services/ExportData/models";
import { ExportDataService } from "src/services/ExportData/ExportData.service";
import html2pdf from "html2pdf.js";
import { DownloadPdfIcon } from "../DownladPdfIcon";

export const TouristList: FC = () => {
  const {
    dispatches: { setShowPreview },
    values: { selectedRoute },
  } = useHotelLetter();
  const componentRef = useRef<HTMLDivElement>(null);
  const [data, setData] = useState<IGetExportHotelList>();
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const fetchData = useCallback(async () => {
    if (!selectedRoute) return;
    try {
      setLoading(true);
      const { GetExportHotelList } = new ExportDataService();
      const res = await GetExportHotelList(selectedRoute.id);
      if (res && res.status === 200 && res.data) {
        setData(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoute]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const print = useReactToPrint({
    content: () => componentRef.current,
    print: async (printIframe: HTMLIFrameElement) => {
      try {
        setDownloadLoading(true);
        const document = printIframe.contentDocument;

        if (document) {
          const html = document.getElementsByTagName("html")[0];
          const htmlRect = html.getBoundingClientRect();
          const pdfWith = 250;
          const pdfCurrentHeight = (pdfWith * htmlRect.height) / htmlRect.width;
          const pageCount = htmlRect.height / pdfWith;
          const roundedPageCount =
            Math.round(pageCount) < pageCount
              ? Math.ceil(pageCount)
              : Math.round(pageCount);
          const pdfHeight = pdfCurrentHeight * roundedPageCount;
          const opt = {
            jsPDF: {
              format: [pdfWith, pdfHeight],
            },
            image: { type: "jpeg", quality: 1 },
            html2canvas: { scale: 3 },
            unit: "px",
          };
          await html2pdf().from(html).set(opt).save("TouristList.pdf");
        }
      } catch (err) {
        console.log(err);
      } finally {
        setDownloadLoading(false);
      }
    },
  });
  const handlePrint = async () => {
    setDownloadLoading(true);
    print();
  };
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);
  const onClose = () => {
    setShowPreview(false);
  };
  const downloadExcel = () => {
    if (data) {
      generateHotelExcel({
        data,
        columns: touristListColumns,
      });
    }
  };

  return (
    <Row justify={"center"}>
      <ReactToPrint content={reactToPrintContent} removeAfterPrint />
      <Col span={22}>
        <TouristListLetter data={data} loading={loading} />
        {/* for pdf  */}
        <div className="hidden">
          <TouristListLetter
            componentRef={componentRef}
            isPdf
            data={data}
            loading={loading}
          />
        </div>
      </Col>
      <Col span={24} className="pb-[32px]">
        <PageFooter className="pageFooter" justify="space-between">
          <Button onClick={onClose}>بستن</Button>
          <Flex gap={16}>
            <DownloadPdfIcon
              handlePrint={handlePrint}
              loading={downloadLoading}
            />
            <img
              src="/assets/images/excelDownload.svg"
              alt=""
              onClick={downloadExcel}
            />
          </Flex>
        </PageFooter>
      </Col>
    </Row>
  );
};
