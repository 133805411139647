import { FC } from "react";
import { ColumnsType } from "antd/es/table";
import { TableUI } from "src/components/UiKit/table";
import { IContact } from "src/services/BasicInformation/models";
import useLanguage from "src/store/language";
import TableActions from "./TableActions";
import { useAddHotelContactData } from "../context";
import { useAddHotelResponderData } from "./context";

const PersonTable: FC = () => {
  const { words } = useLanguage();

  const {
    values: { responderNames },
  } = useAddHotelContactData();

  const {
    values: { dataLoading, totalCount, tablePaginationParams },
    dispatches: { setTablePaginationParams },
  } = useAddHotelResponderData();

  const tableColumns: ColumnsType<IContact> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (text, record, index) =>
        (tablePaginationParams.Offset - 1) * tablePaginationParams.Limit +
        index +
        1,
    },
    {
      title: "پاسخگو",
      dataIndex: "name",
      key: "name",
    },
    {
      title: `${words.basicInfo.tableActions}`,
      dataIndex: "tools",
      key: "tools",
      render: (_, record) => <TableActions item={record} />,
    },
  ];
  return (
    <>
      <TableUI
        columns={tableColumns}
        dataSource={responderNames}
        loading={dataLoading}
        scroll={{ x: 350 }}
        tableType="secondary"
        pagination={{
          position: ["bottomCenter"],
          total: totalCount,
          pageSize: tablePaginationParams.Limit,
          current: tablePaginationParams.Offset,
          onChange(page, pageSize) {
            setTablePaginationParams((prev) => ({
              ...prev,
              Offset: page,
              Limit: pageSize,
            }));
          },
        }}
      />
    </>
  );
};

export default PersonTable;
