import { FC, useState } from "react";
import { Button, Col } from "antd";
import { ColumnsType } from "antd/es/table";
import useLanguage from "src/store/language";
import { TableUI } from "src/components/UiKit/table";
import { useAttachmentRequest } from "./AttachmentRequest";
import { IAttachFile } from "src/services/Tour/models";
import { useTourAddEditData } from "../../context/TourAddEditData";
import { TabMenuKeys } from "../../models";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import AttachmentModal from "./AttachmentModal";

const AttachTable: FC = () => {
  const [id, setId] = useState<number>(0);
  const [selectedFile, setSelectedFile] = useState<IAttachFile | undefined>();
  const [finishLoading, setFinishLoading] = useState<boolean>(false);

  const { states, dispatches, requests } = useAttachmentRequest();
  const {
    dispatches: { setCurrentFilter },
  } = useTourAddEditData();
  const { words } = useLanguage();

  const location = useLocation();
  const navigate = useNavigate();

  const handleDeleteAttachFile = () => {
    requests.deleteAttachFileRequest(id).finally(() => {
      setId(0);
      setSelectedFile(undefined);
    });
  };

  const handleFinishAddTour = () => {
    setFinishLoading(true);
    requests.changeTourStateRequest().finally(() => setFinishLoading(false));
  };

  const columns: ColumnsType<IAttachFile> = [
    {
      title: `${words.addEditTour.file}`,
      dataIndex: "file",
      key: "file",
      width: 30,
      render: (value, record) => (
        <Button
          type="text"
          className="p-0 hover:p-0 hover:!bg-[#ffffff]"
          onClick={() => setSelectedFile(record)}
        >
          <span className="material-icons">folder</span>
        </Button>
      ),
    },
    {
      title: `${words.addEditTour.description}`,
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: `${words.tableTour.tools}`,
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (value, record) => (
        <Button
          type={"text"}
          className="delete-attach-file-btn"
          icon={<span className="material-icons">delete</span>}
          onClick={() => {
            setId(record.id);
          }}
        ></Button>
      ),
    },
  ];

  return (
    <>
      <DeleteConfirmModal
        showConfirm={id > 0 ? true : false}
        loading={states.loading}
        title={words.addEditTour.deleteAttachFileConfirmModalTitle}
        onOk={handleDeleteAttachFile}
        onCancel={() => setId(0)}
        okTitle={words.addEditTour.delete}
      />
      <AttachmentModal
        open={selectedFile ? true : false}
        onCancel={() => setSelectedFile(undefined)}
        attach={selectedFile}
        onDelete={() => setId(selectedFile?.id || 0)}
      />
      <TableUI
        loading={states.dataLoading}
        scroll={{ x: 400 }}
        dataSource={states.files}
        columns={columns}
        tableType="secondary"
        pagination={{
          position: ["bottomCenter"],
          total: states.totalCount,
          pageSize: states.pagination.Limit,
          current: states.pagination.Offset,
          onChange(page, pageSize) {
            dispatches.setPagination((prev) => ({
              ...prev,
              Offset: page,
              Limit: pageSize,
            }));
          },
        }}
      />
      <Col span={24} className="buttons-last-step">
        {location.pathname.includes(ROUTES.tourEdit) ? (
          <Button
            type="primary"
            className="edit-finish"
            icon={<span className="material-icons">check_circle</span>}
            onClick={() => navigate(ROUTES.tourTable)}
          >
            {words.addEditTour.finisEdit}
          </Button>
        ) : (
          <>
            <Button
              type="text"
              htmlType="submit"
              className="save"
              loading={finishLoading}
              icon={<span className="material-icons">done</span>}
              onClick={handleFinishAddTour}
            >
              {words.addEditTour.finish}
            </Button>
            <Button
              type="text"
              className="cancel"
              icon={<span className="material-icons">arrow_back</span>}
              onClick={() => setCurrentFilter(TabMenuKeys.insurance)}
            >
              {words.global.back}
            </Button>
          </>
        )}
      </Col>
    </>
  );
};

export default AttachTable;
