import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import { IContact } from "src/services/BasicInformation/models";
import { BasicInformationService } from "src/services/BasicInformation/BasicInformation.service";
import { IPagination } from "src/models/interfaces/pagination";
import { useHotelsTableData } from "../../context";

interface IContextValue {
  responderDetails: IContact[];
  dataLoading: boolean;
  tablePaginationParams: Pick<IPagination, "Offset" | "Limit">;
  setTablePaginationParams: Dispatch<
    SetStateAction<Pick<IPagination, "Offset" | "Limit">>
  >;
}

export const ResponderDetailsDataContext = createContext<
  IContextValue | undefined
>(undefined);

const ResponderDetailsDataProvider: FC<{
  children: ReactElement;
}> = ({ children }) => {
  const [allResponders, setAllResponders] = useState<IContact[]>([]);
  const [responderDetails, setResponderDetails] = useState<IContact[]>([]);

  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [tablePaginationParams, setTablePaginationParams] =
    useState<IPagination>({
      Offset: 1,
      Limit: 10,
    });

  const { responder } = useHotelsTableData();

  const getResponders = useCallback(async () => {
    if (!responder) return;
    setDataLoading(true);
    setResponderDetails([]);
    try {
      const { Contacts } = new BasicInformationService();
      const res = await Contacts(responder.id);
      if (res && res.status === 200 && res.data) {
        setAllResponders(res.data.records);
      } else {
        setAllResponders([]);
      }
    } catch (e) {
      console.log(e);
    }
  }, [responder]);

  const getResponderDetails = useCallback(async () => {
    if (allResponders.length === 0) {
      setDataLoading(false);
      return;
    }

    try {
      const { ResponderDetailsById } = new BasicInformationService();
      allResponders?.forEach(async (responder, index) => {
        await ResponderDetailsById(responder.id)
          .then((detail) => {
            if (detail) {
              setResponderDetails((prev) => [
                ...prev,
                {
                  id: responder.id,
                  hotelId: responder.hotelId,
                  name: responder.name,
                  hotelResponderDetails:
                    detail.data.records[0].hotelResponderDetails,
                },
              ]);
            } else {
              setAllResponders((prev) => {
                prev[index].hotelResponderDetails = [];
                return prev;
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } finally {
      setDataLoading(false);
    }
  }, [allResponders]);

  useEffect(() => {
    getResponderDetails();
  }, [getResponderDetails]);

  useEffect(() => {
    getResponders();
  }, [getResponders]);

  const contextValues = {
    responderDetails,
    dataLoading,
    tablePaginationParams,
    setTablePaginationParams,
  };

  return (
    <ResponderDetailsDataContext.Provider value={contextValues}>
      {children}
    </ResponderDetailsDataContext.Provider>
  );
};

export default ResponderDetailsDataProvider;

export const useResponderDetailsData = () =>
  useContext(ResponderDetailsDataContext)!;
