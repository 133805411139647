import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from "react";
import { IBroker, INewBrokerArg } from "src/services/BasicInformation/models";
import { Form, FormInstance, message } from "antd";
import { BasicInformationService } from "src/services/BasicInformation/BasicInformation.service";
import useLanguage from "src/store/language";
import { IPagination } from "src/models/interfaces/pagination";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";

interface IContextValue {
  states: {
    addBrokerForm: FormInstance;
    loading: boolean;
    selectedBroker: IBroker | undefined;
    brokers: IBroker[];
    dataLoading: boolean;
    deleteId: number;
    confirmLoading: boolean;
    totalCount: number;
    tablePaginationParams: Pick<IPagination, "Offset" | "Limit">;
  };
  dispatches: {
    setTablePaginationParams: Dispatch<
      SetStateAction<Pick<IPagination, "Offset" | "Limit">>
    >;
    setSelectedBroker: Dispatch<SetStateAction<IBroker | undefined>>;
  };
  request: {
    onDeleteBroker: (id: number) => void;
  };
  func: {
    onFinish: (values: INewBrokerArg) => void;
    handleDeleteId: (id: number) => void;
  };
}

export const BrokersDataContext = createContext<IContextValue | undefined>(
  undefined
);

const BrokersDataProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const [tablePaginationParams, setTablePaginationParams] =
    useState<IPagination>({
      Offset: 1,
      Limit: 10,
    });
  const [selectedBroker, setSelectedBroker] = useState<IBroker | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [brokers, setBrokers] = useState<IBroker[]>([]);
  const [dataLoading, setDataLoadings] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [addBrokerForm] = Form.useForm();

  const { words } = useLanguage();

  const onFinish = (values: INewBrokerArg) => {
    setLoading(true);
    if (selectedBroker) brokerEditREq(values);
    else brokerAddReq(values);
  };

  const brokerAddReq = async (values: INewBrokerArg) => {
    try {
      const { NewBroker } = new BasicInformationService();
      const result = await NewBroker(values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        addBrokerForm.resetFields();
        getBrokers();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const brokerEditREq = async (values: INewBrokerArg) => {
    if (!selectedBroker) return;
    try {
      const { EditBroker } = new BasicInformationService();
      const result = await EditBroker(selectedBroker.id, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setSelectedBroker(undefined);
        getBrokers();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteId = (id: number) => {
    setDeleteId(id);
  };

  const getBrokers = useCallback(async () => {
    setDataLoadings(true);
    const params = new URLSearchParams(tablePaginationParams);
    try {
      const { Brokers } = new BasicInformationService();
      const res = await Brokers(params.toString());
      if (res && res.status === 200 && res.data) {
        setBrokers(res.data.records);
        setTotalCount(res.data.count);
      } else {
        setBrokers([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoadings(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePaginationParams.Limit, tablePaginationParams.Offset]);

  const onDeleteBroker = async () => {
    if (deleteId === 0) return;
    setConfirmLoading(true);
    try {
      const { DeleteBroker } = new BasicInformationService();
      const result = await DeleteBroker(deleteId);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setDeleteId(0);
        if (deleteId === selectedBroker?.id) setSelectedBroker(undefined);
        getBrokers();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmLoading(false);
    }
  };

  useEffect(() => {
    getBrokers();
  }, [getBrokers]);

  useEffect(() => {
    if (selectedBroker) {
      addBrokerForm.setFieldsValue({
        name: selectedBroker.name,
        city: selectedBroker?.city || null,
        country: selectedBroker?.country || null,
        description: selectedBroker?.description || null,
      });
    } else addBrokerForm.resetFields();
  }, [addBrokerForm, selectedBroker]);

  const contextValues = {
    states: {
      tablePaginationParams,
      selectedBroker,
      addBrokerForm,
      loading,
      brokers,
      dataLoading,
      deleteId,
      confirmLoading,
      totalCount,
    },
    dispatches: {
      setTablePaginationParams,
      setSelectedBroker,
    },
    request: { onDeleteBroker },
    func: { onFinish, handleDeleteId },
  };

  return (
    <BrokersDataContext.Provider value={contextValues}>
      {children}
      <DeleteConfirmModal
        showConfirm={deleteId > 0}
        onCancel={() => handleDeleteId(0)}
        onOk={() => onDeleteBroker()}
        title={words.basicInfo.deleteConfirmTitle}
        okTitle={words.global.delete}
        loading={confirmLoading}
      />
    </BrokersDataContext.Provider>
  );
};

export default BrokersDataProvider;

export const useBrokersData = () => useContext(BrokersDataContext)!;
