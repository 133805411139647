import { ChangeEvent, FC, useEffect, useState } from "react";
import { Col, Form, Input, Row } from "antd";
import * as XLSX from "xlsx";
import _ from "lodash";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { ITouristInfoArg } from "src/services/Tour/models";
import LoadingSpin from "src/components/loadingSpin";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import { IObject } from "src/models/interfaces";
import TouristsEditModal from "./modals/TouristsEitModal";
import { useTouristInfoRequest } from "./TouristInfoRequest";
import TouristExcelModal from "./modals/TouristExcelModal";
import { IExcelData } from "./interface";
import { useTourAddEditData } from "../../context/TourAddEditData";
import FilterContentActions from "../FilterContentActions";
import { TabMenuKeys } from "../../models/enums/tabMenuKeys";
import { FilterContentContainer } from "../../styles";

const TouristInfo: FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [excelData, setExcelData] = useState<IExcelData[]>([]);
  const { words } = useLanguage();
  const [touristForm] = Form.useForm();
  const tourists = Form.useWatch("tourists", touristForm);
  const { states, dispatches } = useTourAddEditData();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    states: { loading, dataLoading },
    requests: { addEditTouristRequest, tourDateRequest },
  } = useTouristInfoRequest();

  useEffect(() => {
    if (states.formData && states.currentFilter === TabMenuKeys.tourist) {
      touristForm.setFieldsValue({
        tourCode: states.formData.tourInfo?.tourCode,
        tourNation: states.formData.tourInfo?.nation,
        tourists: states.formData.tourist?.tourists,
        startDate: states.formData.tourInfo?.startDate
          ? dayjs(states.formData.tourInfo?.startDate)
          : null,
        endDate: states.formData.tourInfo?.endDate
          ? dayjs(states.formData.tourInfo?.endDate)
          : null,
      });
    }
  }, [states.currentFilter, states.formData, touristForm]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file: FileList | null = event.currentTarget.files;
    if (file) {
      const f = file[0];
      const reader = new FileReader();
      reader.onload = (evt: any) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const sheetName = wb.SheetNames[0];
        const ws = wb.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_csv(ws);

        const convertedData: IExcelData[] = convertToJson(data);
        setExcelData(convertedData);
      };
      reader.readAsBinaryString(f);
      setOpenModal(true);
    }
  };

  const convertToJson = (csv: any) => {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");
    headers = headers.filter(function (header: any) {
      return /\S/.test(header);
    });

    for (var i = 1; i < lines.length; i++) {
      var obj: any = {};
      var currentLine = lines[i].split(",");
      currentLine = currentLine.filter(function (current: any) {
        return /\S/.test(current);
      });

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j] as any] =
          headers[j] === "sex" ? Number(currentLine[j]) : currentLine[j];
      }

      obj.key = i;
      result.push(obj);
    }

    return result;
  };

  const handleSaveExcel = () => {
    setOpenModal(false);
    touristForm.setFieldsValue({
      tourists: excelData,
    });
  };

  const handleFinishForm = async (values: IObject) => {
    if (values.startDate) values.startDate = values?.startDate.$d;
    if (values.endDate) values.endDate = values?.endDate.$d;

    if (location.pathname.includes(ROUTES.tourEdit)) {
      //dispatches.setCurrentFilter(TabMenuKeys.team);
      tourDateRequest(values);
    } else {
      if (_.isEqual(states.formData?.tourist, values))
        dispatches.setCurrentFilter(TabMenuKeys.team);
      else addEditTouristRequest(values as ITouristInfoArg);
    }
  };

  return (
    <FilterContentContainer justify={"center"}>
      {dataLoading ? (
        <LoadingSpin />
      ) : (
        <>
          <TouristExcelModal
            open={openModal}
            onBack={() => setOpenModal(false)}
            onSave={handleSaveExcel}
            data={excelData}
          />
          <TouristsEditModal
            open={openEditModal}
            onBack={() => setOpenEditModal(false)}
            onSave={() => setOpenEditModal(false)}
            data={states.formData?.tourist?.tourists || []}
          />
          <Col xs={24} md={20} lg={16} className="form">
            <p className="title">{words.addEditTour.touristInfoTitle}</p>
            <Form form={touristForm} onFinish={handleFinishForm}>
              <Row gutter={[16, 16]} className="form-row">
                <Col xs={24} md={12}>
                  <Form.Item
                    name={"tourCode"}
                    rules={[
                      {
                        required: !location.pathname.includes(ROUTES.tourEdit),
                        message: words.global.fieldRequired,
                      },
                    ]}
                  >
                    <InputUiKit
                      disabled={location.pathname.includes(ROUTES.tourEdit)}
                      label={words.addEditTour.tourCode}
                      placeholder={words.addEditTour.tourCode}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name={"tourNation"}
                    rules={[
                      {
                        required: !location.pathname.includes(ROUTES.tourEdit),
                        message: words.global.fieldRequired,
                      },
                    ]}
                  >
                    <InputUiKit
                      disabled={location.pathname.includes(ROUTES.tourEdit)}
                      label={words.addEditTour.nationalityOfTourists}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name={"startDate"}
                    rules={[
                      {
                        required: true,
                        message: words.global.fieldRequired,
                      },
                    ]}
                  >
                    {/* <AdDatePickerUi label={words.addEditTour.startDate} /> */}
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name={"endDate"}
                    rules={[
                      {
                        required: true,
                        message: words.global.fieldRequired,
                      },
                    ]}
                  >
                    {/* <AdDatePickerUi label={words.addEditTour.endDate} /> */}
                  </Form.Item>
                </Col>

                <Col span={24} className="upload-tourist">
                  <p className="upload-title">
                    {words.addEditTour.touristUploadTitle}
                  </p>
                  <Form.Item
                    name={"tourists"}
                    rules={[
                      { required: true, message: words.global.fieldRequired },
                    ]}
                  >
                    <Input type={"hidden"} />
                  </Form.Item>
                  {location.pathname.includes(ROUTES.tourAdd) ? (
                    <input
                      type="file"
                      accept=".xls,.xlsx"
                      id="excel"
                      className="input-file"
                      onChange={onChange}
                      autoComplete="off"
                    />
                  ) : (
                    <input
                      type="button"
                      accept=".xls,.xlsx"
                      id="excel"
                      className="input-file"
                      autoComplete="off"
                      onClick={() => setOpenEditModal(true)}
                    />
                  )}
                  <label htmlFor="excel">
                    {tourists ? (
                      <img src="/assets/images/excel.svg" alt="excel" />
                    ) : (
                      <>
                        <span className="material-icons">attach_file</span>
                        <span>{words.global.upload}</span>
                      </>
                    )}
                  </label>
                </Col>

                <FilterContentActions
                  loading={loading}
                  onPrevious={() => navigate(ROUTES.tourTable)}
                />
              </Row>
            </Form>
          </Col>
        </>
      )}
    </FilterContentContainer>
  );
};

export default TouristInfo;
