import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { ITourTableParams } from "src/services/Tour/models";
import { TourService } from "src/services/Tour/Tour.service";
import { IFormDataProps } from "../interface";
import { TabMenuKeys } from "../models/enums/tabMenuKeys";
interface IContextValue {
  states: {
    tourTableParams: Pick<ITourTableParams, "Offset" | "Limit">;
    dataLoading: boolean;
    current: string;
    currentFilter: string;
    formData: IFormDataProps | undefined;
  };
  dispatches: {
    setTourTableParams: Dispatch<
      SetStateAction<Pick<ITourTableParams, "Offset" | "Limit">>
    >;
    setCurrent: Dispatch<SetStateAction<string>>;
    setCurrentFilter: Dispatch<SetStateAction<string>>;
    setFormData: Dispatch<SetStateAction<IFormDataProps | undefined>>;
  };
  requests: {};
}

export const TourAddEditDataContext = createContext<IContextValue | undefined>(
  undefined
);

const TourAddEditDataProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [current, setCurrent] = useState<string>(TabMenuKeys.manager);
  const [currentFilter, setCurrentFilter] = useState<string>(
    TabMenuKeys.tourist
  );
  const [formData, setFormData] = useState<IFormDataProps | undefined>();

  const [tourTableParams, setTourTableParams] = useState<ITourTableParams>({
    Offset: 1,
    Limit: 10,
  });
  const { tourId } = useParams();
  const location = useLocation();

  const getTourInfo = useCallback(async () => {
    if (!tourId) return;
    setDataLoading(true);
    try {
      const { TourInfo } = new TourService();

      const result = await TourInfo(tourId);
      if (result && result.status === 200) {
        setFormData((prev) => ({
          ...prev,
          tourInfo: result.data,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname.includes(ROUTES.tourEdit) && tourId) {
      getTourInfo();
    }
  }, [getTourInfo, location.pathname, tourId]);

  const contextValues = {
    states: {
      tourTableParams,
      dataLoading,
      current,
      currentFilter,
      formData,
    },
    dispatches: {
      setTourTableParams,
      setCurrent,
      setCurrentFilter,
      setFormData,
    },
    requests: {},
  };

  return (
    <TourAddEditDataContext.Provider value={contextValues}>
      {children}
    </TourAddEditDataContext.Provider>
  );
};

export default TourAddEditDataProvider;

export const useTourAddEditData = () => useContext(TourAddEditDataContext)!;
