import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from "react";
import {
  ICity,
  INewPlaceArg,
  IPlace,
} from "src/services/BasicInformation/models";
import { Form, FormInstance, message } from "antd";
import { BasicInformationService } from "src/services/BasicInformation/BasicInformation.service";
import useLanguage from "src/store/language";
import { IPagination } from "src/models/interfaces/pagination";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";

interface IContextValue {
  states: {
    cities: ICity[];
    cityLoading: boolean;
    addPlaceForm: FormInstance;
    loading: boolean;
    selectedPlace: IPlace | undefined;
    places: IPlace[];
    dataLoading: boolean;
    deleteId: number;
    confirmLoading: boolean;
    totalCount: number;
    tablePaginationParams: Pick<IPagination, "Offset" | "Limit">;
  };
  dispatches: {
    setTablePaginationParams: Dispatch<
      SetStateAction<Pick<IPagination, "Offset" | "Limit">>
    >;
    setSelectedPlace: Dispatch<SetStateAction<IPlace | undefined>>;
  };
  request: {
    onDeletePlace: (id: number) => void;
  };
  func: {
    onFinish: (values: INewPlaceArg) => void;
    handleDeleteId: (id: number) => void;
  };
}

export const PlacesDataContext = createContext<IContextValue | undefined>(
  undefined
);

const PlacesDataProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const [tablePaginationParams, setTablePaginationParams] =
    useState<IPagination>({
      Offset: 1,
      Limit: 10,
    });
  const [selectedPlace, setSelectedPlace] = useState<IPlace | undefined>(
    undefined
  );
  const [cities, setCities] = useState<ICity[]>([]);
  const [cityLoading, setCityLoadings] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [places, setPlaces] = useState<IPlace[]>([]);
  const [dataLoading, setDataLoadings] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const { words } = useLanguage();

  const onFinish = (values: INewPlaceArg) => {
    setLoading(true);
    if (selectedPlace) placeEditREq(values);
    else placeAddReq(values);
  };

  const placeAddReq = async (values: INewPlaceArg) => {
    try {
      const { NewPlace } = new BasicInformationService();
      const result = await NewPlace(values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        addPlaceForm.resetFields();
        getPlaces();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const placeEditREq = async (values: INewPlaceArg) => {
    if (!selectedPlace) return;
    try {
      const { EditPlace } = new BasicInformationService();
      const result = await EditPlace(selectedPlace.id, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setSelectedPlace(undefined);
        getPlaces();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const [addPlaceForm] = Form.useForm();

  const handleDeleteId = (id: number) => {
    setDeleteId(id);
  };

  const getPlaces = useCallback(async () => {
    setDataLoadings(true);
    const params = new URLSearchParams(tablePaginationParams);
    try {
      const { Places } = new BasicInformationService();
      const res = await Places(params.toString());
      if (res && res.status === 200 && res.data) {
        setPlaces(res.data.records);
        setTotalCount(res.data.count);
      } else {
        setPlaces([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoadings(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePaginationParams.Limit, tablePaginationParams.Offset]);

  const onDeletePlace = async () => {
    if (deleteId === 0) return;
    setConfirmLoading(true);
    try {
      const { DeletePlace } = new BasicInformationService();
      const result = await DeletePlace(deleteId);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setDeleteId(0);
        getPlaces();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmLoading(false);
    }
  };

  const getCities = useCallback(async () => {
    setCityLoadings(true);
    try {
      const { Cities } = new BasicInformationService();
      const res = await Cities();
      if (res && res.status === 200 && res.data) {
        setCities(res.data.records);
      } else {
        setCities([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setCityLoadings(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCities();
  }, [getCities]);

  useEffect(() => {
    getPlaces();
  }, [getPlaces]);

  useEffect(() => {
    if (selectedPlace) {
      addPlaceForm.setFieldsValue({
        name: selectedPlace.name,
        cityId: selectedPlace.cityId,
        address: selectedPlace.address,
      });
    } else addPlaceForm.resetFields();
  }, [addPlaceForm, selectedPlace]);

  const contextValues = {
    states: {
      tablePaginationParams,
      selectedPlace,
      cities,
      cityLoading,
      addPlaceForm,
      loading,
      places,
      dataLoading,
      deleteId,
      confirmLoading,
      totalCount,
    },
    dispatches: {
      setTablePaginationParams,
      setSelectedPlace,
    },
    request: { onDeletePlace },
    func: { onFinish, handleDeleteId },
  };

  return (
    <PlacesDataContext.Provider value={contextValues}>
      {children}
      <DeleteConfirmModal
        showConfirm={deleteId > 0}
        onCancel={() => handleDeleteId(0)}
        onOk={() => onDeletePlace()}
        title={words.basicInfo.deleteConfirmTitle}
        okTitle={words.global.delete}
        loading={confirmLoading}
      />
    </PlacesDataContext.Provider>
  );
};

export default PlacesDataProvider;

export const usePlacesData = () => useContext(PlacesDataContext)!;
