import { ChangeEvent, FC, useEffect } from "react";
import { Button, Col, Form, Row } from "antd";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import { FilterContentContainer } from "../../styles";
import { IObject } from "src/models/interfaces";
import { useTourAddEditData } from "../../context/TourAddEditData";
import FilterContentActions from "../FilterContentActions";
import { TabMenuKeys } from "../../models/enums/tabMenuKeys";
import { useInsuranceRequest } from "./InsuranceRequest";
import LoadingSpin from "src/components/loadingSpin";
import ProgressBar from "src/components/progressBar";

const InsuranceForm: FC = () => {
  const { words } = useLanguage();
  const [insuranceForm] = Form.useForm();

  const { states, dispatches } = useTourAddEditData();
  const {
    states: { files, loading, dataLoading, progress },
    dispatches: { setFiles },
    requests,
  } = useInsuranceRequest()!;

  useEffect(() => {
    if (states.formData?.insurance) {
      insuranceForm.setFieldsValue({
        insuranceType: states.formData?.insurance.insuranceType,
        insuranceCompany: states.formData?.insurance.insuranceCompany,
      });
    }
  }, [insuranceForm, states.formData?.insurance]);

  const handleFinishInsuranceForm = (values: IObject) => {
    const insurance = {
      insuranceType: values.insuranceType,
      insuranceCompany: values.insuranceCompany,
    };
    requests.addEditInsuranceRequest(insurance);
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) setFiles(event.currentTarget.files);
  };

  return (
    <FilterContentContainer justify={"center"}>
      <Col xs={24} md={20} lg={16} className="form">
        <p className="title">{words.addEditTour.insuranceFormTitle}</p>
        <Form form={insuranceForm} onFinish={handleFinishInsuranceForm}>
          <Row gutter={[16, 16]} className="form-row">
            {dataLoading ? (
              <Col span={24}>
                <LoadingSpin />
              </Col>
            ) : (
              <>
                <Col xs={24} md={12}>
                  <Form.Item
                    name={"insuranceType"}
                    rules={[
                      { required: true, message: words.global.fieldRequired },
                    ]}
                  >
                    <InputUiKit label={words.viewTour.carInsurance} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item
                    name={"insuranceCompany"}
                    rules={[
                      { required: true, message: words.global.fieldRequired },
                    ]}
                  >
                    <InputUiKit label={words.viewTour.carInsuranceCompany} />
                  </Form.Item>
                </Col>
              </>
            )}

            <Col span={24} className="upload">
              <p className="upload-title">
                {words.addEditTour.uploadInsuranceTitle}
              </p>
              {progress ? (
                <ProgressBar />
              ) : (
                <>
                  <input
                    type="file"
                    id="excel"
                    className="input-file"
                    onChange={onChange}
                    autoComplete="off"
                  />
                  <label htmlFor="excel">
                    {(states.formData?.insurance &&
                      states.formData.insurance.file) ||
                    (files && files?.length > 0) ? (
                      <img
                        src="/assets/images/file.svg"
                        alt="excel"
                        width={66}
                        height={66}
                      />
                    ) : (
                      <>
                        <span className="material-icons">attach_file</span>
                        <span>{words.global.upload}</span>
                      </>
                    )}
                  </label>

                  {files && files.length > 0 && (
                    <div className="pr-4">
                      <Button type="link" onClick={() => setFiles(undefined)}>
                        {words.addEditTour.deleteInsuranceFileLinkTitle}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </Col>

            <FilterContentActions
              onPrevious={() => dispatches.setCurrentFilter(TabMenuKeys.tour)}
              loading={loading}
            />
          </Row>
        </Form>
      </Col>
    </FilterContentContainer>
  );
};

export default InsuranceForm;
