import { FC } from "react";
import { Spin } from "antd";
import ViewDriver from "./ViewDriver";
import ViewCar from "./ViewCar";
import { useManagementDriverData } from "../../context";

const ViewDriverCar: FC = () => {
  const {
    states: { dataLoading },
  } = useManagementDriverData();

  return (
    <>
      {dataLoading ? (
        <div className="w-full flex justify-center items-center">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <ViewDriver />
          <ViewCar />
        </>
      )}
    </>
  );
};

export default ViewDriverCar;
