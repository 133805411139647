import { Form } from "antd";
import _ from "lodash";
import { useAddEditSimcardData } from "../context";

export const useHasChangedAssignForm = () => {
  const {
    values: { selectedAssign },
    form: { assignForm },
  } = useAddEditSimcardData();

  const allValues = Form.useWatch([], assignForm);

  const changed = _.isEqual(
    _.omit(allValues, ["id", "tourId", "touristName"]),
    _.omit(selectedAssign, ["id", "tourId", "touristName"])
  );
  return selectedAssign ? changed : false;
};
