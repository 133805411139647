import { TableColumnsType } from "antd";
import { Gender } from "src/models/enums/gender";
import { IPeople } from "src/services/ExportData/models";

const flightListColumns: TableColumnsType<IPeople> = [
  {
    key: "#",
    title: "NO",
    width: 60,
    align: "center",
    render: (text, record, index) => index + 1,
  },
  {
    key: "gender",
    dataIndex: "gender",
    title: "Gender",
    width: 105,
    align: "center",
    className: "columns",
    render: (value: Gender) => {
      let showGender = "";
      switch (value) {
        case 1:
          showGender = "Male";
          break;
        case 2:
          showGender = "Female";
          break;
      }
      return showGender;
    },
  },
  {
    key: "firstName",
    dataIndex: "firstName",
    title: "Name",
    width: 128,
    align: "center",
  },
  {
    dataIndex: "lastName",
    key: "lastName",
    title: "Surname",
    width: 112,
    align: "center",
  },
  {
    key: "birthDate",
    dataIndex: "birthDate",
    title: "Date of birth",
    width: 117,
    align: "center",
  },
  {
    key: "passportNo",
    dataIndex: "passportNo",
    title: "Pass. No",
    width: 114,
    align: "center",
  },
  {
    dataIndex: "expireDate",
    key: "expireDate",
    title: "Date of expiry",
    width: 144,
    align: "center",
  },
];

export default flightListColumns;
