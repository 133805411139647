export const getFileExtension = (contentType: string) => {
  let fileFormat = "";
  switch (contentType) {
    case "image/jpeg":
      fileFormat = ".jpg";
      break;
    case "application/zip":
      fileFormat = ".zip";
      break;
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      fileFormat = ".xlsx";
      break;
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      fileFormat = ".docx";
      break;
    case "application/pdf":
      fileFormat = ".pdf";
      break;
    case "image/png":
      fileFormat = ".png";
      break;
    case "application/x-rar-compressed" ||
      "application/rar" ||
      contentType.includes("rar"):
      fileFormat = ".rar";
      break;
  }

  return fileFormat;
};
