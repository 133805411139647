import { Row } from "antd";
import styled from "styled-components";

export const DomesticFlightContainer = styled.div``;

export const AddDomesticFlightContainer = styled(Row)`
  margin-top: 32px;
  padding: 32px 32px 24px 32px;
  border-radius: 20px;
  background: var(--color-Base-Color-White, #fff);

  /* 3 */
  box-shadow: 2px 6px 16px 0px rgba(0, 0, 0, 0.18);
  .ant-btn-default:disabled {
    background-color: #fad1b8;
    color: #fde8dc;
  }
  .ant-btn-default {
    background-color: #f06614;
    color: #fff;
  }
  .hBliQA {
    padding: 0px !important;
  }
  .ant-btn {
    padding: 0px !important;
  }
  .ant-btn-dashed:disabled {
    background-color: inherit;
    color: #fad1b8;
    border-style: solid;
    border-color: #fad1b8;
  }
  .ant-btn-dashed {
    border-color: #f06614;
    background-color: inherit;
    color: #f06614;
    border-style: solid;
  }
`;

export const ViewTableContainer = styled(Row)`
  margin: 32px 24px;
  padding: 32px 32px 24px 32px;
  border-radius: 20px;
  background: var(--color-Base-Color-White, #fff);

  /* 3 */
  box-shadow: 2px 6px 16px 0px rgba(0, 0, 0, 0.18);
  .hBliQA {
    padding: 0px !important;
  }
`;

export const DeleteModalFooterContainer = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;

  .btn-ok {
    width: 130px;
    border: solid 1px #f06614;
    border-radius: 8px;
    color: #f06614;
    font-weight: 500;
    font-size: 16px;
  }
  .btn-ok:hover {
    color: #f06614 !important;
    background-color: transparent !important;
  }
  .btn-no {
    width: 130px;
    border-radius: 8px;
    color: #4b4b4b;
    font-weight: 500;
    font-size: 16px;
  }
  .btn-no:hover {
    color: #4b4b4b !important;
    border: 0 !important;
    outline: none !important;
    box-shadow: none !important;
  }
`;
