import ManagementRoute from "src/modules/managementRoute";
import ManagementRouteDataProvider from "src/modules/managementRoute/context";

const ManagementRoutePage = () => (
  <ManagementRouteDataProvider>
    <ManagementRoute />
  </ManagementRouteDataProvider>
);

export default ManagementRoutePage;
