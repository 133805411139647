import { FC, ReactNode, useEffect, useState } from "react";
import { Button, Form } from "antd";
import type { FormInstance } from "antd";
interface SubmitButtonProps {
  form: FormInstance;
  icon?: ReactNode;
  loading: boolean;
  width?: string;
  canSubmit?: boolean;
  onClick?: () => void;
}

const SubmitButton: FC<React.PropsWithChildren<SubmitButtonProps>> = ({
  form,
  children,
  icon,
  loading,
  width,
  canSubmit,
  onClick,
}) => {
  const [submittable, setSubmittable] = useState<boolean>(false);

  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, values]);

  return (
    <Button
      type="text"
      htmlType="submit"
      disabled={!submittable || canSubmit || loading}
      loading={loading}
      icon={icon}
      onClick={onClick}
      className={`flex items-center justify-center rounded-lg ${
        width ? width : "min-w-[85px]"
      } ${
        submittable
          ? "bg-[#F06614] text-[#ffffff] hover:!bg-[#F06614] hover:!text-[#ffffff]"
          : "bg-[#FAD1B8] !text-[#FDE8DC] hover:!bg-[#FAD1B8] hover:!text-[#FDE8DC]"
      }`}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
