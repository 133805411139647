import { FC, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  message,
  Flex,
  Tooltip,
} from "antd";
import * as XLSX from "xlsx";
import useLanguage from "src/store/language";
import { FormItem } from "src/components/UiKit/FormItem";
import InputUiKit from "src/components/UiKit/Input";
import { SelectUiKit } from "src/components/UiKit/select";
import { Gender } from "src/models/enums/gender";
import SubmitButton from "src/components/UiKit/SubmitButton";
import { useAddTouristInfo } from "../context";
import { INewTouristArg } from "src/services/TourManagement/models";
import AdDatePickerUi from "src/components/UiKit/DatePickerMiladi";
import Upload, {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import TouristExcelModal from "./TouristExcelModal";
import { dummyRequest } from "src/helper/dummyRequest";
import moment from "moment";
const sampleFile = require("../public/tourist-excel-sample.xlsx");
export const AddNewTouristInfo: FC = () => {
  const [touristLoading, setTouristLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [excelData, setExcelData] = useState<INewTouristArg[]>([]);
  const [excelFile, setExcelFile] = useState<UploadFile<any>[]>([]);
  const [fileName, setFileName] = useState<string>("");

  const { words } = useLanguage();

  const {
    value: {
      selectedTourist,
      mode,
      countryProps,
      loading,
      birthDate,
      expireDate,
    },
    dispatch: { setSelectedTourist, setBirthDate, setExpireDate },
    form: { addForm },
    func: { onFinishForm },
    requests: { listTouristAddReq, getTouristByPassport },
  } = useAddTouristInfo();

  const handleCancel = () => {
    if (!selectedTourist) {
      addForm.resetFields();
    } else setSelectedTourist(undefined);
  };
  const formValues = Form.useWatch([], addForm);
  function isValidDate(date: string) {
    let resultFullYear = moment(date, "MM/DD/YYYY").isValid();
    if (resultFullYear) return moment(date, "MM/DD/YYYY").format("YYYY-MM-DD");
    else return "";
  }

  const convertToJson: <T>(result: any[]) => T[] = (result) => {
    const newResult: typeof result = [];
    result.forEach((item) => {
      item["gender"] =
        Number(item["gender"]) === Gender.Male
          ? Number(item["gender"])
          : Number(item["gender"]) === Gender.Female
          ? Gender.Female
          : 0;

      const itemCountry = countryProps.countries.find(
        (country) => country.id === Number(item["countryId"])
      );
      item["countryId"] = itemCountry ? Number(item["countryId"]) : 0;

      item["birthDate"] = isValidDate(item["birthDate"] || "");

      item["expireDate"] = isValidDate(item["expireDate"] || "");
      item.key = item.__rowNum__;
      newResult.push(item);
    });

    return newResult;
  };

  const readFile = (f: RcFile) => {
    const reader = new FileReader();
    reader.onload = (evt: any) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, {
        type: "binary",
        cellDates: true,
        cellText: false,
      });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      const data: INewTouristArg[] = XLSX.utils.sheet_to_json(ws, {
        dateNF: "mm-dd-yyyy",
      });
      const convertedData = convertToJson<INewTouristArg>(data);
      setExcelData(convertedData);
    };
    reader.readAsArrayBuffer(f);

    setOpenModal(true);
  };
  const handleInquiry = () => {
    if (formValues && formValues.passportNO) {
      setTouristLoading(true);
      getTouristByPassport(formValues.passportNO).finally(() =>
        setTouristLoading(false)
      );
    }
  };

  const beforeUpload = (file: RcFile) => {
    const isExcelFile =
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel";
    if (!isExcelFile) {
      message.error("You can only upload EXCEL file!");
    }
    return isExcelFile;
  };
  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    setExcelFile([info.file]);

    if (info.file.status === "uploading") {
      //setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setFileName(info.file.originFileObj?.name || "");
      readFile(info.file.originFileObj!);
    }
  };

  const uploadButton = (
    <div className="ant-upload-excel">
      <span className="material-icons text-[#ffffff] text-[14px]">
        attach_file
      </span>

      <div className="text-[#ffffff]">{words.addTouristInfo.excelBtn}</div>
    </div>
  );

  return (
    <>
      <TouristExcelModal
        open={openModal}
        onBack={() => {
          setExcelFile([]);
          setExcelData([]);
          setFileName("");
          setOpenModal(false);
        }}
        onSave={() =>
          listTouristAddReq(excelData).finally(() => {
            setExcelFile([]);
            setExcelData([]);
            setFileName("");
            setOpenModal(false);
          })
        }
        data={excelData}
        setData={setExcelData}
        loading={loading}
      />
      <Form form={addForm} onFinish={onFinishForm}>
        {mode === "edit" || selectedTourist ? (
          <Col span={24} className="py-[24px]">
            <p className="!m-[0px] text-[16px] text-[#233069] font-bold">
              {words.addTouristInfo.editTitle}
            </p>
          </Col>
        ) : (
          <Col span={24} className="py-[24px]">
            <p className="!m-[0px] text-[16px] text-[#233069] font-bold">
              {words.addTouristInfo.pageTitle}
            </p>
            <p className="!m-[0px] text-[14px] text-[#4B4B4B] font-medium">
              {words.addTouristInfo.secondPageTitle}
            </p>
          </Col>
        )}
        <Col span={24} className="pt-[16px] pb-[32px]">
          <Row gutter={24}>
            <Col xs={24} md={8}>
              <FormItem
                name={"passportNO"}
                label={words.addTouristInfo.passportNO}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <Input.Search
                  placeholder={words.addTouristInfo.passportNOPlaceHolder}
                  enterButton={words.addTouristInfo.inquiry}
                  size="large"
                  onSearch={handleInquiry}
                />
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                name={"firstName"}
                label={words.addTouristInfo.name}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <InputUiKit
                  placeholder={words.addTouristInfo.namePlaceHolder}
                />
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                name={"lastName"}
                label={words.addTouristInfo.lastName}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <InputUiKit
                  placeholder={words.addTouristInfo.lastNamePlaceHolder}
                />
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                name={"gender"}
                label={words.addTouristInfo.gender}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <SelectUiKit
                  placeholder={words.addTouristInfo.choosePlaceHolder}
                >
                  <Select.Option value={Gender.Male}>
                    {words.global.male}
                  </Select.Option>
                  <Select.Option value={Gender.Female}>
                    {words.global.femail}
                  </Select.Option>
                </SelectUiKit>
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                name={"countryId"}
                label={words.addTouristInfo.nationLabel}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <SelectUiKit
                  showSearch
                  placeholder={words.addTouristInfo.choosePlaceHolder}
                >
                  {countryProps.countries.map((country) => (
                    <Select.Option key={country.id} value={country.id}>
                      {country.faName}
                    </Select.Option>
                  ))}
                </SelectUiKit>
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                name={"job"}
                label={words.addTouristInfo.jobLabel}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <InputUiKit placeholder={words.addTouristInfo.jobPlaceholder} />
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                name={"birthPlace"}
                label={words.addTouristInfo.birthPlaceLabel}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <InputUiKit
                  placeholder={words.addTouristInfo.birthPlacePlaceholder}
                />
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                name={"birthDate"}
                label={words.addTouristInfo.birthday}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <AdDatePickerUi
                  placeholderText={words.addTouristInfo.select}
                  selected={birthDate}
                  onChange={(date) => setBirthDate(date)}
                />
              </FormItem>
            </Col>

            <Col xs={24} md={8}>
              <FormItem
                name={"expireDate"}
                label={words.addTouristInfo.expireDate}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <AdDatePickerUi
                  placeholderText={words.addTouristInfo.select}
                  selected={expireDate}
                  onChange={(date) => setExpireDate(date)}
                />
              </FormItem>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Flex justify="space-between" align="center">
            <Flex gap={16}>
              <Upload
                name="excel-file"
                listType="picture-card"
                className=" flex justify-center upload"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                customRequest={dummyRequest}
                fileList={excelFile}
                accept=".xls, .xlsx"
              >
                {fileName ? (
                  <div className="flex flex-col justify-center items-center">
                    <img
                      src={"/assets/images/excelIcon.png"}
                      alt="excel"
                      style={{ width: "50%" }}
                    />
                    <div className="text-[12px]">{fileName}</div>
                  </div>
                ) : (
                  uploadButton
                )}
              </Upload>
              <a
                href={sampleFile}
                download={"tourist-excel-sample.xlsx"}
                className="flex justify-center items-center text-[12px]"
              >
                {words.addTouristInfo.downloadSample}
              </a>
              <Flex className=" h-[40px]" justify="center" align="center">
                <Tooltip title="فرمت تاریخ ها در فایل اکسل باید به صورت MM/DD/YYYY  و میلادی باشد . مثلا 03/15/2024">
                  <span
                    className="material-icons text-[18px] cursor-pointer select-none text-[#1677ff] "
                    // style={{ transform: "rotateY(180deg)" }}
                  >
                    help_outline
                  </span>
                </Tooltip>
              </Flex>
            </Flex>
            <div className="flex items-center">
              <Button
                type="text"
                className="w-[85px] bg-[#F5F5F5] text-[#8E8E8E] ml-2"
                onClick={handleCancel}
              >
                {!selectedTourist
                  ? words.managementTime.clear
                  : words.managementTime.cancel}
              </Button>
              <SubmitButton form={addForm} loading={loading} canSubmit={false}>
                {!selectedTourist
                  ? words.global.add
                  : words.managementTime.editBtn}
              </SubmitButton>
            </div>
          </Flex>
        </Col>
        <Divider className="bg-[#DADEEC]" />
        <Spin spinning={touristLoading} size="large" fullscreen />
      </Form>
    </>
  );
};
