import styled from "styled-components";

export const LoadingSpinContainer = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin-top: 20px;
  border-radius: 8px;
`;
