export enum ROUTES {
  home = "/",
  login = "/login",
  addTour = "/add-tour",
  tourTable = "/tours",
  managementTour = "/managementTour",
  tourView = "/tours/view/",
  tourEdit = "/tours/edit/",
  tourAdd = "/tours/add/",
  tourEditChinese = "/add-tour/edit/",
  settings = "/settings",
  CreateRole = "/settings/create-role",
  AssignActionToRole = "/settings/assign-action-to-role",
  CreateUser = "/settings/create-user",
  AssignRoleToUser = "/settings/assign-role-to-user",
  basicInfo = "/basic-information",
  country = "/basic-information/country",
  city = "/basic-information/city",
  hotel = "/basic-information/hotel",
  car = "/basic-information/car",
  places = "/basic-information/Places-of-interest",
  tourManagementTouristInfo = "/tourist-info",
  viewTouristInfo = "/viewTouristInfo/",
  tourManagementRoute = "/route",
  domesticFlight = "/domestic-flight",
  tourManagementBasicInfo = "/basic-info",
  viewDomesticFlight = "/viewDomesticFlight",
  tourManagementHotel = "/hotel",
  tourManagementTime = "/time",
  tourManagementSimcard = "/buy-simcard",
  tourManagementInsurance = "/insurance",
  tourManagementDriver = "/driver",
  tourManagementTranslator = "/translator",
  tourManagementSupervisor = "/supervisor",
  notification = "/notification",
  viewNotification = "/viewNotification",
  tourManagementFiles = "/files",
  rooms = "/basic-information/rooms",
  broker = "/basic-information/brokers",
  managementTourReport = "/reports",
  tourManagementCompanionLeader = "/companion-leader",
  tourManagementLeader = "/leader",
  tourManagementAssignSimcard = "/assign-simcard",
  routeRoom = "/rooms",
  routePlace = "/places",
  insuranceAttachment = "/attachment",
}
