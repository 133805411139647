import { Button, Modal } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { useManagementNotification } from "../context";

export const DescriptionModal: FC = () => {
  const { words } = useLanguage();

  const {
    value: { isOpenModal },
    func: { onCloseModal },
  } = useManagementNotification();
  return (
    <Modal
      title={
        <div className="pb-[16px] border-0 border-b-[1px] border-solid border-[#FDE8DC]">
          <p className="text-[18px] font-semibold text-[#233069] mb-[2px] mt-[0px]">
            {words.managementNotification.modalTitle}
          </p>
          <span className="text-[12px] text-[#1F1F1F] font-medium pl-[8px] border-0 border-l-[2px] border-solid border-[#FDE8DC]">
            {words.managementTour.pageTitle} 425386
          </span>
          <span className="text-[12px] text-[#8E8E8E] font-normal mr-[8px]">
            تسک آقای عمرانی
          </span>
        </div>
      }
      closeIcon={false}
      open={isOpenModal}
      onCancel={onCloseModal}
      footer={() => (
        <div className="flex justify-center ">
          <Button
            type="default"
            onClick={onCloseModal}
            className="flex gap-[12px] justify-center font-semibold text-[#4B4B4B] text-[16px]"
          >
            <span className="material-icons text-[24px] text-[#4B4B4B]">
              highlight_off
            </span>
            {words.global.close}
          </Button>
        </div>
      )}
    >
      <div className="flex justify-center items-center p-[16px] rounded-[12px] border border-solid border-[#FDE8DC] my-[32px]">
        <div className="p-[16px] flex justify-center items-center bg-[#F5F5F5] rounded-[12px]">
          <p className="text-[12px] text-[#1F1F1F]">
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با
            استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در
            ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز،
            و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای
            زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و
            متخصصان را می طلبد.
          </p>
        </div>
      </div>
    </Modal>
  );
};
