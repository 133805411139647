import { Row } from "antd";
import styled from "styled-components";

export const HotelLetterReservationListContainer = styled(Row)`
  display: flex;
  padding: 32px 32px 24px 32px;
  gap: 24px;
  border-radius: 20px;
  background: var(--color-Base-Color-White, #fff);
  width: 95%;
  /* 3 */
  box-shadow: 2px 6px 16px 0px rgba(0, 0, 0, 0.18);
  .footer {
    border-top: 1px solid #dadeec;
    padding: 32px 24px 16px 0px;
  }
  .ant-table-row-selected {
    .ant-table-cell {
      background: #fde8dc !important;
    }
    .ant-table-cell-row-hover {
      background: #f8b58e !important;
    }
  }
  table {
    thead {
      tr {
        th {
          color: #ffff !important;
          font-family: "Vazir FD";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          background: #525d90 !important;
        }
      }
    }
  }
  table {
    thead {
      tr {
        th::before {
          height: unset !important;
        }
      }
    }
  }

  .textarea {
    font: inherit;
    letter-spacing: inherit;
    border: 0;
    box-sizing: content-box;
    background: #f5f5f5;
    height: 165px;
    margin: 0;
    width: 100%;
    color: #1f1f1f !important;
    font-weight: 400;
    font-size: 12px;

    &:hover {
      border-inline-end-width: 0;
      box-shadow: none !important;
      background-color: #f5f5f5 !important;
    }

    &:focus {
      box-shadow: none !important;
      border: 0 !important;
      background-color: #f5f5f5 !important;
    }

    .ant-form-item-explain-error {
      text-align: right !important;
    }
    &.ant-input-status-error {
      box-shadow: none !important;
      border: 0 !important;
      outline: none !important;
      background-color: #f5f5f5 !important;
    }
    &.ant-input-status-error:focus {
      border-inline-end-width: 0;
      box-shadow: none !important;
      background-color: #f5f5f5 !important;
    }
  }
`;
