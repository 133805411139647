import { Form, FormInstance, TreeSelect, TreeSelectProps, message } from "antd";
import { DefaultOptionType } from "antd/es/select";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UserManagementService } from "src/services/UserManagement/UserManagement.service";
import { INewUserArg, IRole } from "src/services/UserManagement/models";
import useLanguage from "src/store/language";
const { SHOW_PARENT } = TreeSelect;

interface IContext {
  roles: IRole[];
  treeProps: TreeSelectProps;
  showConfirm: boolean;
  confirmLoading: boolean;
  createUserForm: FormInstance | undefined;
  onFinish: (value: INewUserArg) => void;
  onConfirm: () => Promise<void>;
  onCancelConfirm: () => void;
}

const defaultContextValue: IContext = {
  roles: [],
  treeProps: {},
  showConfirm: false,
  confirmLoading: false,
  createUserForm: undefined,
  onFinish: () => {},
  onConfirm: async () => {},
  onCancelConfirm: () => {},
};

export const CreateUserCTX = createContext<IContext>(defaultContextValue);

export const CreateUserProvider: FC<PropsWithChildren> = ({ children }) => {
  const [roles, setRoles] = useState<IRole[]>([]);
  const [newUserData, setNewUserData] = useState<INewUserArg>();
  const [value, setValue] = useState<number[]>([]);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [getRolesLoading, setRolesLoading] = useState<boolean>(false);

  const [createUserForm] = Form.useForm();
  const { words } = useLanguage();

  const onChange = (newValue: number[]) => {
    setValue(newValue);
  };
  const createTree = (role: IRole) => {
    const newRole: DefaultOptionType = {
      label: role.roleName,
      value: role.id,
      key: role.id,
    };
    if (role.childeren.length > 0) {
      newRole.children = role.childeren.map((item) => createTree(item));
    }
    return newRole;
  };
  const data = roles.map((item) => createTree(item))[0]?.children;
  const treeProps: TreeSelectProps = useMemo(
    () => ({
      treeData: data,
      value,
      onChange,
      treeCheckable: false,
      showCheckedStrategy: SHOW_PARENT,
      placeholder: "select",
      style: {
        width: "100%",
      },
      loading: getRolesLoading,
    }),
    [data, getRolesLoading, value]
  );
  const onFinish = (values: INewUserArg) => {
    setNewUserData(values);
    if (values) setShowConfirm(true);
  };
  const onCancelConfirm = () => {
    setShowConfirm(false);
  };
  const onConfirm = async () => {
    if (!newUserData) {
      message.error("data not provided");
      return;
    }
    try {
      setConfirmLoading(true);
      const { newUser } = new UserManagementService();
      const result = await newUser(newUserData);
      if (result && result.status === 200) {
        onCancelConfirm();
        message.success(`${words.systemSettings.createUserSuccessMessage}`);
        setConfirmLoading(false);
        createUserForm.resetFields();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchRoles = async () => {
    setRolesLoading(true);
    getRoles()
      .then((roles) => {
        if (roles && roles.length) {
          setRoles(roles);
        }
      })
      .finally(() => {
        setRolesLoading(false);
      });
  };
  useEffect(() => {
    fetchRoles();
  }, []);

  const contextValue: IContext = {
    roles,
    treeProps,
    showConfirm,
    confirmLoading,
    createUserForm,
    onFinish,
    onConfirm,
    onCancelConfirm,
  };
  return (
    <CreateUserCTX.Provider value={contextValue}>
      {children}
    </CreateUserCTX.Provider>
  );
};
export const useCreateUserContext = () => useContext(CreateUserCTX);
const getRoles = async () => {
  try {
    const { Roles } = new UserManagementService();
    const res = await Roles();
    if (res && res.status === 200 && res.data) {
      return res.data;
    } else {
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};
