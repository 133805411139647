import { FC } from "react";
import { Notification } from "src/modules/managementNotification";
import { ManagementNotificationProvider } from "src/modules/managementNotification/context";

export const NotificationPage: FC = () => {
  return (
    <ManagementNotificationProvider>
      <Notification />
    </ManagementNotificationProvider>
  );
};
