import ManagementTranslator from "src/modules/managementTranslator";
import ManagementTranslatorDataProvider from "src/modules/managementTranslator/context";

const ManagementTranslatorPage = () => (
  <ManagementTranslatorDataProvider>
    <ManagementTranslator />
  </ManagementTranslatorDataProvider>
);

export default ManagementTranslatorPage;
