import { Input } from "antd";
import { styled } from "styled-components";

export const InputStyle = styled(Input)`
  background: #f5f5f5;
  border: 0;
  border-radius: 12px;
  height: 51px;

  &:focus {
    border-inline-end-width: 0;
    box-shadow: none !important;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  &::placeholder {
    color: #8e8e8e;
    font-weight: 500;
    font-size: 12px;
  }
`;
