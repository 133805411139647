export const carPluckAlphabet = [
  { value: "1", label: "الف" },
  { value: "2", label: "ب" },
  { value: "3", label: "پ" },
  { value: "4", label: "ت" },
  { value: "5", label: "ث" },
  { value: "6", label: "ج" },
  { value: "7", label: "چ" },
  { value: "8", label: "ح" },
  { value: "9", label: "خ" },
  { value: "10", label: "د" },
  { value: "11", label: "ذ" },
  { value: "12", label: "ر" },
  { value: "13", label: "ز" },
  { value: "14", label: "ژ" },
  { value: "15", label: "س" },
  { value: "16", label: "ش" },
  { value: "17", label: "ص" },
  { value: "18", label: "ض" },
  { value: "19", label: "ط" },
  { value: "20", label: "ظ" },
  { value: "22", label: "ع" },
  { value: "23", label: "غ" },
  { value: "23", label: "ف" },
  { value: "24", label: "ق" },
  { value: "25", label: "ک" },
  { value: "26", label: "گ" },
  { value: "27", label: "ل" },
  { value: "28", label: "م" },
  { value: "29", label: "ن" },
  { value: "30", label: "و" },
  { value: "31", label: "ه" },
  { value: "32", label: "ی" },
];

export const carPluckAlphabetArray = [
  "الف",
  "ب",
  "پ",
  "ت",
  "ث",
  "ج",
  "چ",
  "ح",
  "خ",
  "د",
  "ذ",
  "ر",
  "ز",
  "ژ",
  "س",
  "ش",
  "ص",
  "ض",
  "ط",
  "ظ",
  "ع",
  "غ",
  "ف",
  "ق",
  "ک",
  "گ",
  "ل",
  "م",
  "ن",
  "و",
  "ه",
  "ی",
];
