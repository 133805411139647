import { Flex, TableColumnsType } from "antd";
import {
  IPlaceOfInterests,
  IRouteDetails,
} from "src/services/ExportData/models";

export const routeProgramColumns: TableColumnsType<IRouteDetails> = [
  {
    key: "#",
    title: "#",
    width: 60,
    align: "center",
    className: "columns",
    render: (text, record, index) => index + 1,
  },
  {
    key: "city",
    dataIndex: "city",
    title: "شهر مبدا",
    width: 107,
    align: "center",
    className: "columns",
  },
  {
    key: "inlineCities",
    dataIndex: "inlineCities",
    title: "شهرهای بین راهی",
    width: 143,
    align: "center",
    className: "columns",
  },
  {
    dataIndex: "flightTime",
    key: "flightTime",
    title: "ساعت پرواز",
    width: 102,
    align: "center",
    className: "columns",
  },
  {
    key: "arrivalDate",
    dataIndex: "arrivalDate",
    title: "تاریخ ورود ",
    width: 121,
    align: "center",
    className: "columns",
  },
  {
    key: "departureDate",
    dataIndex: "departureDate",
    title: "تاریخ خروج",
    width: 121,
    align: "center",
    className: "columns",
  },
  {
    dataIndex: "hotelName",
    key: "hotelName",
    title: "هتل",
    width: 113,
    align: "center",
    className: "columns",
  },
  {
    key: "placeOfInterests",
    dataIndex: "placeOfInterests",
    title: "مکان های دیدنی",
    width: 297,
    align: "center",
    className: "columns ",
    render: (value: IPlaceOfInterests[]) => {
      let text = "";
      value?.forEach((item, index) => {
        text += item.name.concat(index !== value.length - 1 ? "-" : "");
      });

      return <Flex justify="center">{text}</Flex>;
    },
  },
];
