import { FC } from "react";
import { Select } from "antd";
import useLanguage from "src/store/language";
import { ISelect } from "./interface/ISelect";
import { SelectStyle } from "./style/select";
import FormControlUiKit from "../FormControl";

export const SelectUiKit: FC<ISelect> = ({
  placeholder,
  label,
  children,
  ...props
}) => {
  const { isRtl } = useLanguage();

  return (
    <FormControlUiKit label={label} height={"51px"}>
      <SelectStyle dir={isRtl ? "rtl" : "ltr"}>
        <Select
          {...props}
          placeholder={placeholder}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.children as unknown as string).includes(input)
          }
        >
          {children}
        </Select>
      </SelectStyle>
    </FormControlUiKit>
  );
};
