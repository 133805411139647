import { FC, useEffect } from "react";
import { Col, Form, Row } from "antd";
import _ from "lodash";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import { useTourAddEditData } from "src/modules/addEditTour/context/TourAddEditData";
import { IObject } from "src/models/interfaces";
import DatePickerUi from "src/components/UiKit/DatePicker";
import { TabMenuKeys } from "src/modules/addEditTour/models";
import { convertDatePicker } from "src/helper";
import Actions from "../../Actions";
import { useDriverInfoRequest } from "./DriverInfoRequest";
import { IDriverInfoArg } from "src/services/Tour/models";
import LoadingSpin from "src/components/loadingSpin";

const DriverForm: FC = () => {
  const { words } = useLanguage();

  const [driverForm] = Form.useForm();

  const { states, dispatches } = useTourAddEditData();
  const {
    states: { loading, dataLoading },
    requests: { addEditTouristRequest },
  } = useDriverInfoRequest();

  useEffect(() => {
    if (states.formData && states.current === TabMenuKeys.driver) {
      driverForm.setFieldsValue({
        driverInfo: states.formData.driver?.driverInfo,
        driverPhone: states.formData.driver?.driverPhone,
        driverFatherName: states.formData.driver?.driverFatherName,
        driverNationalCode: states.formData.driver?.driverNationalCode,
        driverIdNumber: states.formData.driver?.driverIdNumber,
        driverBirthDate: convertDatePicker(
          states.formData.driver?.driverBirthDate
        ),
      });
    }
  }, [driverForm, states]);

  const handleFinishDriverForm = (values: IObject) => {
    if (values.driverBirthDate)
      values.driverBirthDate = values?.driverBirthDate.$d;
    if (_.isEqual(states.formData?.driver, values))
      dispatches.setCurrent(TabMenuKeys.car);
    else {
      addEditTouristRequest(values as IDriverInfoArg);
    }
  };

  return dataLoading ? (
    <LoadingSpin />
  ) : (
    <Form form={driverForm} onFinish={handleFinishDriverForm}>
      <Row gutter={[8, 16]} className="team-form-row">
        <Col xs={24} md={12}>
          <Form.Item
            name={"driverInfo"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit
              label={words.viewTour.firstName.concat(
                "-" + words.viewTour.lastName
              )}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"driverPhone"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit label={words.global.contact} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"driverFatherName"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit label={words.global.fatherName} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"driverNationalCode"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit label={words.global.nationalCode} numbermode />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"driverBirthDate"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <DatePickerUi label={words.viewTour.birthDate} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"driverIdNumber"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit label={words.viewTour.idNumber} numbermode />
          </Form.Item>
        </Col>
        <Actions
          loading={loading}
          onPrevious={() => dispatches.setCurrent(TabMenuKeys.translator)}
        />
      </Row>
    </Form>
  );
};

export default DriverForm;
