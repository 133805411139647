import { Navigate, RouteObject } from 'react-router-dom'
import { ROUTES } from 'src/models/enums/routes'
import LoginLayout from 'src/templates/loginLayout'
import LoginPage from 'src/pages/public/Login'

export const publicRoutes: RouteObject[] = [
  {
    path: ROUTES.login,
    element: <LoginLayout />,
    children: [
      {
        path: ROUTES.login,
        element: <LoginPage />,
      },
    ],
  },

  { path: '*', element: <Navigate to={ROUTES.login} /> },
]
