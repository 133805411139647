import { Form, FormInstance } from "antd";
import _ from "lodash";
import { useManagementBasicInfoData } from "./context";

export const useEditTourFormChanged = (editTourForm: FormInstance) => {
  const {
    states: { tour },
  } = useManagementBasicInfoData();

  const allValues = Form.useWatch([], editTourForm);

  const changed = _.isEqual(
    _.omit(allValues, ["id", "countryName", "brokerName"]),
    _.omit(tour, ["id", "countryName", "brokerName"])
  );
  return tour ? changed : false;
};
