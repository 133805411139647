import styled from "styled-components";

export const UserStateStyle = styled.div`
  border-radius: 6px;
  border: 1px solid var(--shadow, #dadeec);
  background: var(--strock, #c9ccdc);
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  .defaultItem {
    display: flex;
    padding: 4px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 2px;
    background: var(--shadow, #fafafa);
    font-weight: 400;
    color: var(--sidebar-icon, #a3aed0);
    font-family: DM Sans;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    user-select: none;
  }
  .active {
    border-radius: 2px;
    background: var(--BUY, #01c18f);

    /* input shadow */
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
    color: var(--0, #fff);
    font-family: DM Sans;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .inactive {
    border-radius: 2px;
    background: var(--11, #fd29b5);

    /* input shadow */
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
    color: var(--0, #fff);
    font-family: DM Sans;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
