import { FC } from "react";
import { ManagementTourContainer } from "./style";
import { ManagementTourContent } from "./components/managementTour";
import ManagementTourDetails from "./components/ManagementTourDetails";

export const ManagementTour: FC = () => {
  return (
    <ManagementTourContainer>
      {/* <ManagementTourHeader /> */}
      <ManagementTourDetails />
      <ManagementTourContent />
    </ManagementTourContainer>
  );
};
