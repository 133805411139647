import { FC } from "react";
import { useManagementTourBack } from "src/hook/useManagementTourBack";
import useLanguage from "src/store/language";

interface IProps {
  link?: string;
  disabled: boolean;
  btnTitle: string;
}

const EndRegisterButton: FC<IProps> = ({ link, disabled, btnTitle }) => {
  const { isRtl } = useLanguage();

  const { backHandler } = useManagementTourBack();

  return (
    <div
      className={`bg-[#ffffff] w-[85px] h-[40px] flex items-center justify-center border border-solid rounded-lg ${
        disabled
          ? "cursor-not-allowed text-[#FAD1B8] border-[#FAD1B8]"
          : "cursor-pointer text-[#F06614] border-[#F06614]"
      } ${isRtl ? "mr-[10px]" : "ml-[10px]"}`}
      onClick={!disabled ? backHandler : undefined}
    >
      {btnTitle}
    </div>
  );
};

export default EndRegisterButton;
