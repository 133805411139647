import { FC } from "react";
import { Button, Col, Divider, Form, Row } from "antd";
import FormItem from "antd/es/form/FormItem";
import SubmitButton from "src/components/UiKit/SubmitButton";
import useLanguage from "src/store/language";
import { useNavigate } from "react-router-dom";
import { useManagementSupervisorData } from "../context";
import { useFormHasChanged } from "../useFormHasChanged";
import InputUiKit from "src/components/UiKit/Input";

const AddEditSupervisor: FC = () => {
  const { words } = useLanguage();

  const navigate = useNavigate();
  const {
    states: { loading, mode, supervisorForm },
    func: { onFinish },
  } = useManagementSupervisorData();

  const canSubmit = useFormHasChanged();

  const handleCancel = () => {
    if (mode === "add") {
      supervisorForm.resetFields();
    } else navigate(-1);
  };

  return (
    <>
      <p className="title">
        {mode === "add"
          ? words.managementSupervisor.addFormTitle
          : words.managementSupervisor.editFormTitle}
      </p>
      <Form
        onFinish={onFinish}
        form={supervisorForm}
        layout="vertical"
        preserve={false}
        className="mt-[40px]"
      >
        <Row gutter={[24, 12]}>
          <Col xs={24} md={8}>
            <FormItem
              name={"name"}
              label={words.managementSupervisor.nameLabel}
            >
              <InputUiKit
                placeholder={words.managementSupervisor.namePlaceHolder}
              />
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <FormItem
              name={"phone"}
              label={words.managementSupervisor.phoneLabel}
            >
              <InputUiKit
                placeholder={words.managementSupervisor.phonePlaceHolder}
              />
            </FormItem>
          </Col>

          <Divider className="bg-[#EEEEEE]" />
          <Col span={24} className="flex justify-end">
            <Button
              type="text"
              className="bg-[#F5F5F5] text-[#8E8E8E] ml-2 rounded-lg"
              onClick={handleCancel}
            >
              {mode === "add"
                ? words.managementTime.clear
                : words.managementTime.cancel}
            </Button>
            <SubmitButton
              form={supervisorForm}
              loading={loading}
              canSubmit={canSubmit}
            >
              {mode === "add" ? words.global.add : words.managementTime.editBtn}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddEditSupervisor;
