import { USER_JWT_TOKEN } from "src/constants";
import { ROUTES } from "src/models/enums/routes";
import useAuthStore from "src/store/auth";

export const useLogout = () => {
  const { logout } = useAuthStore();

  const logoutHandler = async () => {
    localStorage.removeItem(USER_JWT_TOKEN);
    window.location.replace(ROUTES.login);
    logout();
  };

  return { logoutHandler };
};
