import { FC, ReactNode } from "react";
import type { InputNumberProps } from "antd";
import { InputNumberUiKitContainer } from "./Container";
import FormControlUiKit from "../FormControl";

interface IProps extends InputNumberProps {
  label?: string;
  icon?: ReactNode;
  allowClear?: boolean;
}

const InputNumberUiKit: FC<IProps> = ({ label, icon, ...props }) => {
  return (
    <FormControlUiKit label={label} height={"51px"} icon={icon}>
      <InputNumberUiKitContainer
        autoComplete="off"
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={(value) =>
          value?.replace(/\$\s?|(,*)/g, "") as unknown as number
        }
        allowClear
        {...props}
      />
    </FormControlUiKit>
  );
};

export default InputNumberUiKit;
