import { FC } from "react";
import { Col } from "antd";
import DataEntryHeader from "src/components/dataEntryHeader";
import useLanguage from "src/store/language";
import BrokersDataProvider from "./context/BrokersData";
import { BrokersContainer } from "./style";
import AddRoom from "./components/form/AddForm";
import RoomTable from "./components/table/Table";

const Brokers: FC = () => {
  const { words } = useLanguage();
  return (
    <BrokersDataProvider>
      <>
        <DataEntryHeader title={words.broker.brokerTitle} />
        <BrokersContainer justify={"center"} gutter={[0, 24]}>
          <Col span={23} className="content">
            <AddRoom />
            <RoomTable />
          </Col>
        </BrokersContainer>
      </>
    </BrokersDataProvider>
  );
};

export default Brokers;
