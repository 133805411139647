import { FC, useEffect, useState } from "react";
import { ProgressBarContainer } from "./style";

const ProgressBar: FC = () => {
  const [completed, setCompleted] = useState<number>(0);
  const containerStyles = {
    height: 20,
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    margin: 50,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: "green",
    borderRadius: "inherit",
    transition: "width 1s ease-in-out",
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };

  useEffect(() => {
    setInterval(() => setCompleted((prev) => (prev > 100 ? 0 : prev + 1)), 500);
  }, []);

  return (
    <ProgressBarContainer dir={"ltr"}>
      <div style={containerStyles}>
        <div style={fillerStyles} dir="rtl">
          <span style={labelStyles}>{`${completed}%`}</span>
        </div>
      </div>
    </ProgressBarContainer>
  );
};

export default ProgressBar;
