import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from "react";
import { useQuery } from "react-query";
import { createSearchParams, useLocation } from "react-router-dom";
import {
  ITourTableCodeParams,
  ITourTableParams,
} from "src/services/Tour/models";
import { reactQueryEndPoints } from "src/constants";
import { Form, message } from "antd";
import useLanguage from "src/store/language";
import { ICountry } from "src/services/BasicInformation/models";
import { BasicInformationService } from "src/services/BasicInformation/BasicInformation.service";
import { IObject } from "src/models/interfaces";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import { ITour, ITourCode } from "src/services/TourManagement/models";
import { FormInstance } from "antd/lib";
import DisableConfirmModal from "../components/DisableConfirm";
interface IContextValue {
  states: {
    tourTableParams: ITourTableParams;
    dataLoading: boolean;
    allTour: ITour[] | undefined;
    totalCount: number | undefined;
    countryLoading: boolean;
    countries: ICountry[];
    countrySearchValue: ISearchValueProps;
    allFilterTours: ITourCode[];
    tourCodeParams: ITourTableCodeParams;
    tourCodeLoading: boolean;
  };
  dispatches: {
    setTourTableParams: Dispatch<SetStateAction<ITourTableParams>>;
    setCountrySearchValue: Dispatch<SetStateAction<ISearchValueProps>>;
    setTourCodeParams: Dispatch<SetStateAction<ITourTableCodeParams>>;
    setDisableConfirm: Dispatch<SetStateAction<boolean>>;
    setDeleteId: Dispatch<SetStateAction<number | undefined>>;
  };
  requests: {
    deleteTourRequest: (id: number | string) => Promise<void>;
  };
  form: { tourTableFilterForm: FormInstance };
}

interface ISearchValueProps extends IObject {
  SearchQuery?: string;
}

export const TourTableDataContext = createContext<IContextValue | undefined>(
  undefined
);

const TourTableDataProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const { state } = useLocation();

  const [tourTableParams, setTourTableParams] = useState<ITourTableParams>({
    Offset: 1,
    Limit: 10,
  });
  const [countryLoading, setCountryLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countrySearchValue, setCountrySearchValue] =
    useState<ISearchValueProps>({});
  const [allFilterTours, setAllFilterTours] = useState<ITourCode[]>([]);
  const [tourCodeParams, setTourCodeParams] = useState<ITourTableCodeParams>(
    {}
  );
  const [tourCodeLoading, setTourCodeLoading] = useState<boolean>(false);
  const [tourTableFilterForm] = Form.useForm();

  const { words } = useLanguage();
  const location = useLocation();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [disableConfirm, setDisableConfirm] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();
  const deleteTourRequest = async () => {
    if (!deleteId) return;
    setDeleteLoading(true);
    try {
      const { DeleteTour } = new TourManagementService();
      const result = await DeleteTour(deleteId);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        refetch();
        getAllFilterTours();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
      setDisableConfirm(false);
    }
  };

  const getAllFilterTours = useCallback(async () => {
    setTourCodeLoading(true);
    const params = new URLSearchParams(tourCodeParams);
    try {
      const { GetToursCode } = new TourManagementService();
      const result = await GetToursCode(params.toString());
      if (result && result.status === 200) {
        setAllFilterTours(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTourCodeLoading(false);
    }
  }, [tourCodeParams]);

  const getAllTours = async () => {
    let querySearch = `${location.search}${
      location.search ? "&" : "?"
    }${createSearchParams(tourTableParams)}`;

    try {
      const { Tours } = new TourManagementService();
      const result = await Tours(querySearch);
      if (result && result.status === 200) {
        const { data } = result;
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const { data, isFetching, refetch } = useQuery(
    reactQueryEndPoints.toursList,
    getAllTours,
    { enabled: false }
  );

  const getCountry = useCallback(async () => {
    setCountryLoading(true);

    const { Countries } = new BasicInformationService();
    try {
      let filterSearchParams =
        countrySearchValue.toString().length < 1 ? "?" : "";
      Object.keys(countrySearchValue).forEach((key) => {
        if (countrySearchValue[key] !== undefined) {
          filterSearchParams = filterSearchParams
            .concat(filterSearchParams === "?" ? "" : "?")
            .concat(`${key}=${countrySearchValue[key]}`);
        }
      });
      const result = await Countries(filterSearchParams);
      if (result && result.status === 200) {
        setCountries(result.data.records);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCountryLoading(false);
    }
  }, [countrySearchValue]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourTableParams, location.search]);

  useEffect(() => {
    getCountry();
  }, [getCountry, countrySearchValue]);

  useEffect(() => {
    getAllFilterTours();
  }, [getAllFilterTours, tourCodeParams]);

  useEffect(() => {
    if (state && state.tourState) {
      tourTableFilterForm.setFieldValue("TourStates", [
        Number(state.tourState),
      ]);
    }
  }, [state, tourTableFilterForm]);

  const contextValues: IContextValue = {
    states: {
      tourTableParams,
      dataLoading: isFetching,
      allTour: data?.records,
      totalCount: data?.count,
      countryLoading,
      countries,
      countrySearchValue,
      allFilterTours,
      tourCodeParams,
      tourCodeLoading,
    },
    dispatches: {
      setTourTableParams,
      setCountrySearchValue,
      setTourCodeParams,
      setDeleteId,
      setDisableConfirm,
    },
    requests: { deleteTourRequest },
    form: { tourTableFilterForm },
  };

  return (
    <TourTableDataContext.Provider value={contextValues}>
      {children}
      <DisableConfirmModal
        showConfirm={disableConfirm}
        loading={deleteLoading}
        onOk={deleteTourRequest}
        onCancel={() => setDisableConfirm(false)}
      />
    </TourTableDataContext.Provider>
  );
};

export default TourTableDataProvider;

export const useTourTableData = () => useContext(TourTableDataContext)!;
