import {
  Document,
  Paragraph,
  TextRun,
  TableRow,
  TableCell,
  Table,
  ITableWidthProperties,
} from "docx";
import moment from "moment-jalaali";
import { IObject } from "src/models/interfaces";
import {
  IGetExportHotelMail,
  IRoomDetail,
} from "src/services/ExportData/models";
import { IHotelLetterInfo } from "./context";

const fontName = "B Nazanin";

export const createReservationMail = (
  data: IGetExportHotelMail,
  hotelLetterInfo: IHotelLetterInfo | undefined
): Document => {
  const { tourCode, hotelName, roomDetail } = data;

  const mailDate = hotelLetterInfo?.date
    ? moment(hotelLetterInfo.date.toISOString()).format("jDD/jMM/jYYYY")
    : moment().format("jDD/jMM/jYYYY");
  const newRoomDetails = roomDetail.map((Room) => {
    const newRoom = { ...Room };
    newRoom.arrivalDate = Room.arrivalDate
      ? moment(Room.arrivalDate).format("jDD/jMM/jYYYY")
      : "";
    newRoom.departureDate = Room.departureDate
      ? moment(Room.departureDate).format("jDD/jMM/jYYYY")
      : "";
    return newRoom;
  });
  const document = new Document({
    sections: [
      {
        properties: {
          page: {
            margin: {
              top: "4cm",
              bottom: "1cm",
              left: "1cm",
              right: "1cm",
            },
          },
        },
        children: [
          createInstitutionHeader("   " + mailDate, "تاریخ: "),
          createInstitutionHeader("   " + tourCode, "شماره نامه: "),
          ...createMailDescription(hotelLetterInfo?.reservation, hotelName),
          nextLine(),
          nextLine(),
          createRoomsTable({ roomDetail: newRoomDetails }),
          nextLine(),
          nextLine(),
          ...createFooterDescription(hotelLetterInfo),
        ],
        footers: {
          default: {
            options: {
              children: [
                new Paragraph({
                  alignment: "left",
                  indent: { left: "3cm" },
                  children: [
                    new TextRun({
                      font: { name: fontName },
                      text: "با تشکر",
                      bold: true,
                      size: "11pt",
                      rightToLeft: true,
                    }),
                  ],
                }),
                new Paragraph({
                  alignment: "left",
                  indent: { left: "3cm" },

                  children: [
                    new TextRun({
                      font: { name: fontName },
                      text: hotelLetterInfo?.signatory,
                      bold: true,
                      size: "11pt",
                      rightToLeft: true,
                    }),
                  ],
                }),
              ],
            },
          },
        },
      },
    ],
  });
  return document;
};

const createInstitutionHeader = (
  institutionName: string,
  dateText: string
): Paragraph => {
  return new Paragraph({
    alignment: "left",
    indent: { left: 4 },
    leftTabStop: 0,
    children: [
      new TextRun({
        font: { name: fontName },
        text: institutionName,
        bold: true,
        rightToLeft: true,
      }),
      new TextRun({
        font: { name: fontName },
        text: `\t${dateText}`,
        bold: true,
        rightToLeft: true,
        color: "#808080",
      }),
    ],
    spacing: { before: 10 },
  });
};

const createMailDescription = (
  reservation?: string,
  hotelName?: string
): Paragraph[] => {
  return [
    new Paragraph({
      alignment: "right",
      children: [
        new TextRun({
          font: { name: fontName },
          text: `رزرواسیون محترم ${reservation} `,
          bold: true,
          size: "11pt",
          rightToLeft: true,
        }),
      ],
    }),
    nextLine(),
    new Paragraph({
      alignment: "right",
      children: [
        new TextRun({
          font: { name: fontName },
          text: `هتل ${hotelName} `,
          bold: true,
          size: "11pt",
          rightToLeft: true,
        }),
      ],
    }),
    nextLine(),
    new Paragraph({
      alignment: "right",
      children: [
        new TextRun({
          font: { name: fontName },
          text: "باسلام",
          size: "10pt",
          rightToLeft: true,
        }),
      ],
    }),
    nextLine(),
    new Paragraph({
      alignment: "right",
      children: [
        new TextRun({
          font: { name: fontName },
          text: "احتراما به استحضار میرساند پیرو مکالمه تلفنی با شما",
          size: "10pt",
          rightToLeft: true,
        }),
      ],
    }),
    nextLine(),
    new Paragraph({
      alignment: "right",
      children: [
        new TextRun({
          font: { name: fontName },
          text: "درخواست رزرو اتاق های زیر را خواستاریم.",
          size: "10pt",
          rightToLeft: true,
        }),
      ],
    }),
    nextLine(),
  ];
};

const nextLine = (): Paragraph =>
  new Paragraph({
    children: [
      new TextRun({
        font: { name: fontName },
        text: "\n",
        bold: true,
        size: "11pt",
        rightToLeft: true,
      }),
    ],
  });

const createRoomsTable = ({
  roomDetail,
}: Pick<IGetExportHotelMail, "roomDetail">) => {
  const columns: {
    dataIndex: keyof IRoomDetail | "key";
    label: string;
    width: ITableWidthProperties;
  }[] = [
    {
      dataIndex: "key",
      label: "#",
      width: { size: "10mm", type: "dxa" },
    },
    {
      dataIndex: "roomName",
      label: "نام اتاق",
      width: { size: "45mm", type: "dxa" },
    },
    {
      dataIndex: "roomCount",
      label: "تعداد",
      width: { size: "30mm", type: "dxa" },
    },
    {
      dataIndex: "arrivalDate",
      label: "تاریخ ورود",
      width: { size: "30mm", type: "dxa" },
    },
    {
      dataIndex: "departureDate",
      label: "تاریخ خروج",
      width: { size: "30mm", type: "dxa" },
    },

    {
      dataIndex: "days",
      label: "مدت",
      width: { size: "35mm", type: "dxa" },
    },
  ];
  return new Table({
    visuallyRightToLeft: true,
    width: { size: "100%", type: "pct" },

    borders: {
      insideHorizontal: { style: "single", color: "#CACACA" },
      insideVertical: { style: "single", color: "#CACACA" },
      bottom: { style: "single", color: "#CACACA" },
      top: { style: "single", color: "#CACACA" },
      right: { style: "single", color: "#CACACA" },
      left: { style: "single", color: "#CACACA" },
    },

    rows: [
      new TableRow({
        tableHeader: true,
        height: { value: "32pt", rule: "exact" },
        children: columns.map(
          (column) =>
            new TableCell({
              verticalAlign: "center",
              width: column.width,
              margins: {
                right: 5,
                bottom: 5,
                left: 5,
                top: 5,
              },
              children: [
                new Paragraph({
                  alignment: "center",

                  children: [
                    new TextRun({
                      font: { name: fontName },
                      text: column.label,
                      bold: true,
                      size: "11pt",
                      rightToLeft: true,
                    }),
                  ],
                }),
              ],
            })
        ),
      }),
      ...roomDetail.map(
        (route: IObject, rowIndex) =>
          new TableRow({
            tableHeader: true,
            height: { value: "32pt", rule: "exact" },
            children: columns.map(
              (column) =>
                new TableCell({
                  verticalAlign: "center",
                  margins: {
                    right: 5,
                    bottom: 5,
                    left: 5,
                    top: 5,
                  },
                  children: [
                    new Paragraph({
                      alignment: "center",
                      children: [
                        new TextRun({
                          font: { name: fontName },
                          text:
                            column.dataIndex === "key"
                              ? (rowIndex + 1).toString()
                              : route[column.dataIndex].toString(),
                          bold: true,
                          size: "11pt",
                          rightToLeft: true,
                        }),
                      ],
                    }),
                  ],
                })
            ),
          })
      ),
    ],
  });
};

const createFooterDescription = (
  hotelLetterInfo: IHotelLetterInfo | undefined
): Paragraph[] => {
  return [
    new Paragraph({
      alignment: "right",
      children: [
        new TextRun({
          font: { name: fontName },
          text: `لطفا اقدامات لازم را مبذول فرمایید. در صورت هرگونه هماهنگی با شماره زیر تماس بگیرید.`,
          size: "10pt",
          rightToLeft: true,
        }),
      ],
    }),
    nextLine(),
    new Paragraph({
      alignment: "right",
      children: [
        new TextRun({
          font: { name: fontName },
          text: `${hotelLetterInfo?.coordinationPerson} ${hotelLetterInfo?.phoneNumber}`,
          size: "10pt",
          rightToLeft: true,
        }),
      ],
    }),
    nextLine(),
    new Paragraph({
      alignment: "right",
      children: [
        new TextRun({
          font: { name: fontName },
          text: "پیشاپیش از بذل توجه و همکاری شما کمال تشکر را داریم.",
          size: "10pt",
          rightToLeft: true,
        }),
      ],
    }),
    nextLine(),
    new Paragraph({
      alignment: "right",
      children: [
        new TextRun({
          font: { name: fontName },
          text: `${hotelLetterInfo?.description}`,
          size: "10pt",
          rightToLeft: true,
        }),
      ],
    }),
    nextLine(),
  ];
};
