import { FC } from "react";
import { Col, Row } from "antd";
import useLanguage from "src/store/language";
import { useCountryContext } from "../context";
import { TableUI } from "src/components/UiKit/table";
import { ColumnsType } from "antd/es/table";
import { ICountry } from "src/services/BasicInformation/models";
import TableActions from "./TableActions";
import { getRowKey } from "src/helper/getRowKey";

const CountryTable: FC = () => {
  const {
    countries,
    tableLoading,
    totalCount,
    tablePaginationParams,
    setTablePaginationParams,
    editCountry,
  } = useCountryContext();

  const { words } = useLanguage();

  const tableColumns: ColumnsType<ICountry> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (text, record, index) =>
        (tablePaginationParams.Offset - 1) * tablePaginationParams.Limit +
        index +
        1,
    },
    {
      title: "نام کشور (فارسی)",
      dataIndex: "faName",
      key: "faName",
    },
    {
      title: "نام کشور (انگلیسی)",
      dataIndex: "enName",
      key: "enName",
    },
    {
      title: "نام اختصاری",
      dataIndex: "shortName",
      key: "shortName",
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: `${words.basicInfo.tableActions}`,
      dataIndex: "tools",
      key: "tools",
      render: (value, record) => <TableActions item={record} />,
    },
  ];

  return (
    <>
      <Row gutter={[24, 12]}>
        <Col span={24} className="mt-[20px]">
          <p className="title-table">{words.basicInfo.countryTableTitle}</p>
        </Col>
        <Col span={24}>
          <TableUI
            columns={tableColumns}
            dataSource={countries}
            loading={tableLoading}
            scroll={{ x: 860 }}
            tableType="secondary"
            rowClassName={(record, index) =>
              record.id === editCountry?.id ? "selected-row-for-edit" : ""
            }
            rowKey={getRowKey}
            pagination={{
              position: ["bottomCenter"],
              total: totalCount,
              pageSize: tablePaginationParams.Limit,
              current: tablePaginationParams.Offset,
              onChange(page, pageSize) {
                setTablePaginationParams((prev) => ({
                  ...prev,
                  Offset: page,
                  Limit: pageSize,
                }));
              },
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default CountryTable;
