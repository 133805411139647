import {
  Dispatch,
  FC,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import type { GetRef, InputRef } from "antd";
import { Button, Form, Input, Table, Modal, Select, App } from "antd";
import { TableUI } from "src/components/UiKit/table";
import { INewTouristArg } from "src/services/TourManagement/models";
import useLanguage from "src/store/language";
import { Gender } from "src/models/enums/gender";
import { ModalContentContainer, ModalHeaderContainer } from "../style";
import { useAddTouristInfo } from "../context";
import dayjs from "dayjs";
import { convertDateTimeIran } from "src/helper";
import AdDatePickerUi from "src/components/UiKit/DatePickerMiladi";
import { ICountry } from "src/services/BasicInformation/models";
import moment from "moment";

export interface ITourExcelModalProps {
  open: boolean;
  onBack: () => void;
  onSave: () => void;
  data: INewTouristArg[];
  setData: Dispatch<SetStateAction<INewTouristArg[]>>;
  loading: boolean;
}

type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

const EditableRow: FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: ReactNode;
  editable: boolean;
  dataIndex: keyof INewTouristArg;
  record: INewTouristArg;
  handleSave: (record: INewTouristArg) => void;
}

const EditableCell: FC<PropsWithChildren<EditableCellProps>> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);

  const inputRef = useRef<InputRef>(null);

  const { words } = useLanguage();

  const form = useContext(EditableContext)!;

  const {
    value: { countryProps },
  } = useAddTouristInfo();

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);

    if (dataIndex === "birthDate" || dataIndex === "expireDate") {
      if (
        record[dataIndex] &&
        record[dataIndex] !== "" &&
        !record[dataIndex].includes("NaN")
      ) {
        form.setFieldsValue({ [dataIndex]: dayjs(record[dataIndex]) });
      } else form.setFieldsValue({ [dataIndex]: null });
    } else if (dataIndex === "gender") {
      if (
        record[dataIndex] &&
        (Number(record[dataIndex]) === Gender.Male ||
          Number(record[dataIndex]) === Gender.Female)
      ) {
        form.setFieldsValue({ [dataIndex]: Number(record[dataIndex]) });
      } else form.setFieldsValue({ [dataIndex]: null });
    } else if (dataIndex === "countryId") {
      if (record[dataIndex] && Number(record[dataIndex]) > 0) {
        const findCountry = countryProps.countries.find(
          (country) => country.id === Number(record[dataIndex])
        );
        if (findCountry)
          form.setFieldsValue({ [dataIndex]: Number(record[dataIndex]) });
        else form.setFieldsValue({ [dataIndex]: null });
      } else form.setFieldsValue({ [dataIndex]: null });
    } else {
      record[dataIndex]
        ? form.setFieldsValue({ [dataIndex]: record[dataIndex] })
        : form.setFieldsValue({ [dataIndex]: null });
    }
  };
  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        {dataIndex.toString() === "expireDate" ||
        dataIndex.toString() === "birthDate" ? (
          <AdDatePickerUi onChange={save} onBlur={save} />
        ) : dataIndex.toString() === "gender" ? (
          <Select onChange={save} className="!w-full">
            <Select.Option value={Gender.Male}>
              {words.global.male}
            </Select.Option>
            <Select.Option value={Gender.Female}>
              {words.global.femail}
            </Select.Option>
          </Select>
        ) : dataIndex.toString() === "countryId" ? (
          <Select onBlur={save} className="!w-full">
            {countryProps.countries.map((country) => (
              <Select.Option key={country.id} value={country.id}>
                {country.faName}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <Input
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            autoComplete="off"
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];
interface DataType extends INewTouristArg {}
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const TouristExcelModal: FC<ITourExcelModalProps> = ({
  open,
  onBack,
  onSave,
  data = [],
  setData,
  loading,
}) => {
  const { words, isRtl } = useLanguage();
  const { notification } = App.useApp();
  const {
    value: { countryProps },
  } = useAddTouristInfo();

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      key: "key",
      title: "#",
      dataIndex: "key",
      editable: false,
      width: 40,
    },
    {
      title: words.addTouristInfo.passportNO,
      dataIndex: "passportNO",
      editable: true,
      width: 150,
      render: (value) => (value ? value : "-"),
    },
    {
      title: words.addTouristInfo.name,
      dataIndex: "firstName",
      editable: true,
      width: 120,
      render: (value) => (value ? value : "-"),
    },
    {
      title: words.addTouristInfo.lastName,
      dataIndex: "lastName",
      editable: true,
      width: 120,
      render: (value) => (value ? value : "-"),
    },
    {
      title: words.addTouristInfo.gender,
      dataIndex: "gender",
      editable: true,
      width: 100,
      render: (value) =>
        value &&
        (Number(value) === Gender.Male || Number(value) === Gender.Female)
          ? Gender.Male === value
            ? words.global.male
            : Gender.Female === value
            ? words.global.femail
            : "-"
          : "-",
    },
    {
      title: words.addTouristInfo.jobLabel,
      dataIndex: "job",
      editable: true,
      width: 140,
      render: (value) => (value ? value : "-"),
    },
    {
      title: words.addTouristInfo.nationLabel,
      dataIndex: "countryId",
      editable: true,
      width: 100,
      render: (value) => {
        if (value && Number(value) > 0) {
          const item: ICountry | undefined = countryProps.countries.find(
            (country) => country.id === value
          );
          if (item) return item.faName;
          else return "-";
        } else return "-";
      },
    },
    {
      title: words.addTouristInfo.birthPlaceLabel,
      dataIndex: "birthPlace",
      editable: true,
      width: 120,
      render: (value) => (value ? value : "-"),
    },
    {
      title: words.addTouristInfo.tableBirthday,
      dataIndex: "birthDate",
      editable: true,
      width: 130,
      render: (value) => {
        if (value && value !== "" && !value.includes("NaN")) {
          return moment(value, "YYYY-MM-DD").format("MM/DD/YYYY");
        } else return "-";
      },
    },
    {
      title: words.addTouristInfo.TableExpireDate,
      dataIndex: "expireDate",
      editable: true,
      width: 150,
      render: (value) => {
        if (value && value !== "" && !value.includes("NaN")) {
          return moment(value, "YYYY-MM-DD").format("MM/DD/YYYY");
        } else return "-";
      },
    },
  ];

  const handleSave = (row: DataType) => {
    const newData = [...data];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];

    if (row.birthDate && row.birthDate !== "")
      row.birthDate = convertDateTimeIran(row.birthDate);

    if (row.expireDate && row.expireDate !== "")
      row.expireDate = convertDateTimeIran(row.expireDate);

    if (
      row.gender &&
      (Number(row.gender) === Gender.Male ||
        Number(row.gender) === Gender.Female)
    )
      row.gender = Number(row.gender);
    else row.gender = 0;
    if (row.countryId && Number(row.countryId) > 0) {
      const findCountry = countryProps.countries.find(
        (country) => country.id === Number(row.countryId)
      );
      if (findCountry) row.countryId = Number(row.countryId);
      else row.countryId = 0;
    } else row.countryId = 0;

    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    setData(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const ModalHeader = () => {
    return (
      <ModalHeaderContainer>
        {words.addTouristInfo.tourExcelModalTitle}
      </ModalHeaderContainer>
    );
  };

  const handleFieldTitle = (field: string) => {
    switch (field) {
      case "passportNO":
        return words.addTouristInfo.passportNO;
      case "firstName":
        return words.addTouristInfo.name;
      case "lastName":
        return words.addTouristInfo.lastName;
      case "gender":
        return words.addTouristInfo.gender;
      case "job":
        return words.addTouristInfo.jobLabel;
      case "countryId":
        return words.addTouristInfo.nationLabel;
      case "birthPlace":
        return words.addTouristInfo.birthPlaceLabel;
      case "birthDate":
        return words.addTouristInfo.tableBirthday;
      case "expireDate":
        return words.addTouristInfo.TableExpireDate;
      default:
        return "";
    }
  };

  const handleCheckValidation = () => {
    const getData = data.filter((item) => !!item["key"]);

    const emptyFields: {
      row: string;
      field: string;
    }[] = [];

    getData.forEach((i) => {
      Object.keys(i).forEach((j) => {
        if (i[j] === 0 || i[j] === "" || !i[j]) {
          emptyFields.push({ row: i["key"]?.toString() || "", field: j });
        }
      });
    });

    if (emptyFields.length > 0) {
      emptyFields.forEach((field) => {
        notification.error({
          message: `${
            words.addTouristInfo.addExcelTouristErrorOne
          } ${handleFieldTitle(field.field)} ${
            words.addTouristInfo.addExcelTouristErrorThree
          } ${field.row} ${words.addTouristInfo.addExcelTouristErrorFive}  `,
        });
      });
    } else onSave();
  };

  return (
    <Modal
      title={<ModalHeader />}
      open={open}
      footer={null}
      onCancel={onBack}
      centered
      width={"90%"}
    >
      <ModalContentContainer>
        <TableUI
          components={components}
          rowClassName={() => "editable-row"}
          dataSource={data}
          columns={columns as ColumnTypes}
          tableType="secondary"
          pagination={false}
        />

        <div className="footer" dir={isRtl ? "rtl" : "ltr"}>
          <Button
            type="text"
            className="save-btn"
            loading={loading}
            icon={<span className="material-icons">done</span>}
            onClick={handleCheckValidation}
          >
            {words.global.save}
          </Button>
          <Button
            type="text"
            className="cancel"
            icon={<span className="material-icons">arrow_back</span>}
            dir={isRtl ? "ltr" : "rtl"}
            onClick={onBack}
          >
            {words.global.back}
          </Button>
        </div>
      </ModalContentContainer>
    </Modal>
  );
};

export default TouristExcelModal;
