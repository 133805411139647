export const convertDate = (date: string | Date) =>
  // moment(date).locale("fa").format("jYYYY-jMM-jDD");
  new Date(date).toLocaleString("fa-IR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

export const convertDateMiladi = (date: string | Date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("/");
};

export const getHour = (date: string | Date) =>
  new Date(date).toLocaleString("fa-IR", {
    hour: "2-digit",
    minute: "2-digit",
  });

export const convertDateTimeIran = (date: string | Date) => {
  const dateTime = new Date(date);

  const dateTimeFormat =
    dateTime.getFullYear().toString() +
    "-" +
    ("0" + (dateTime.getMonth() + 1).toString()).slice(-2) +
    "-" +
    ("0" + dateTime.getDate().toString()).slice(-2) +
    "T" +
    ("0" + dateTime.getHours().toString()).slice(-2) +
    ":" +
    ("0" + dateTime.getMinutes().toString()).slice(-2) +
    ":" +
    ("0" + dateTime.getSeconds().toString()).slice(-2) +
    ".860Z";

  return dateTimeFormat;
};

export const convertTimeIran = (date: string | Date) => {
  const dateTime = new Date(date);

  const timeFormat =
    ("0" + dateTime.getHours().toString()).slice(-2) +
    ":" +
    ("0" + dateTime.getMinutes().toString()).slice(-2);

  return timeFormat;
};
