import { Form } from "antd";
import _ from "lodash";
import { useAddEditSimcardData } from "../context";
import { useManagementSimcardData } from "src/modules/managementSimcard/context";

export const useHasChangedBuyForm = () => {
  const {
    form: { buyForm },
  } = useAddEditSimcardData();

  const {
    states: { buySimcard },
  } = useManagementSimcardData();

  const allValues = Form.useWatch([], buyForm);

  const changed = _.isEqual(
    _.omit(allValues, ["id", "tourId"]),
    _.omit(buySimcard, ["id", "tourId"])
  );

  return buySimcard ? changed : false;
};
