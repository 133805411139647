import { styled } from "styled-components";

export const Container = styled.div<{ $type: "primary" | "secondary" }>`
  width: 100%;
  margin-top: 20px;
  background-color: #ffffff;
  padding: 24px;
  border-radius: 16px;
  ${({ $type }) =>
    $type === "primary"
      ? "background: #fff; border-radius: 16px; padding: 24px;"
      : ""}

  .ant-table-wrapper .ant-table-thead > tr > th {
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
  }
  .ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before,
  .ant-table-wrapper
    .ant-table-thead
    > tr
    > td:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
  }

  thead :is(td, th) {
    background-color: ${({ $type }) =>
      $type === "primary"
        ? "#dbe9ff !important"
        : "rgba(82, 93, 144, 1) !important"};
    color: #1b2559;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
  }

  .ant-table-tbody td {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #5d6881;
    border-bottom: unset;
  }
  /* .ant-table-cell-row-hover,
  .ant-table-row-level-0 {
    background: transparent !important;
  } */
  .sc-bBbNsw {
    width: 80% !important;
  }
  .ant-tabs-tabpane {
    display: flex;
    justify-content: center;
  }
  .ant-form-item {
    border: none;
    padding: 0;
    margin: 0;
  }
  .ant-table-tbody td {
    text-align: center;
  }
  .ant-table-row.selected-row-for-edit {
    background-color: #fde8dc !important;
  }
  .delete-action {
    width: 49px;
    height: 28px;
    border-radius: 4px;
    background-color: #ffebee;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span {
      color: #f06614;
      font-weight: 500;
      font-size: 10px;
    }
  }
  .delete-action:hover {
    background-color: #ffebee !important;
    color: #ec2d30 !important;
  }
  .management-tour-action {
    width: 76px;
    height: 28px;
    border-radius: 4px;
    background-color: #e4f2ff;
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 6px;
    cursor: pointer;
    span {
      color: #3a70e2;
      font-weight: 500;
      font-size: 10px;
    }
  }
  .edit-action {
    width: 57px;
    height: 28px;
    border-radius: 4px;
    background-color: #e5f5ec;
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    span {
      color: #0c9d61;
      font-weight: 500;
      font-size: 10px;
    }
  }

  .download-action {
    width: 55px;
    height: 28px;
    border-radius: 4px;
    background-color: #eee;
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    span {
      color: #4b4b4b;
      font-weight: 500;
      font-size: 10px;
    }
  }
  .file-action {
    width: 62px;
    height: 28px;
    border-radius: 4px;
    background-color: #eee;
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    span {
      color: #4b4b4b;
      font-weight: 500;
      font-size: 10px;
    }
  }
  .room-place-action {
    width: 56px;
    height: 28px;
    border-radius: 4px;
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
  }
  .room-place-action.active {
    background-color: #e4f2ff;
    span {
      color: #3a70e2;
      font-weight: 500;
      font-size: 10px;
    }
  }
  .room-place-action.disable {
    background-color: #eee;
    span {
      color: #8e8e8e;
      font-weight: 500;
      font-size: 10px;
    }
  }
`;
