import { Form, message } from "antd";
import { FormInstance } from "antd/lib";
import dayjs from "dayjs";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { convertDateTimeIran } from "src/helper";
import { Gender } from "src/models/enums/gender";
import { IPagination } from "src/models/interfaces/pagination";
import { BasicInformationService } from "src/services/BasicInformation/BasicInformation.service";
import { ICountry } from "src/services/BasicInformation/models";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import {
  INewTouristArg,
  ITableTourist,
  ITourist,
} from "src/services/TourManagement/models";
import useLanguage from "src/store/language";

interface IAddTouristInfo {
  value: {
    loading: boolean;
    totalCount: number;
    mode: string;
    tourCode: string;
    countryProps: ICountryProps;
    selectedTourist: ITableTourist | undefined;
    tourists: ITableTourist[];
    tablePaginationParams: IPagination;
    dataLoading: boolean;
    deleteItem: ITableTourist | undefined;
    confirmLoading: boolean;
    birthDate: Date | undefined | null;
    expireDate: Date | undefined | null;
  };
  dispatch: {
    setTablePaginationParams: Dispatch<SetStateAction<IPagination>>;
    setSelectedTourist: Dispatch<SetStateAction<ITableTourist | undefined>>;
    setBirthDate: Dispatch<SetStateAction<Date | undefined | null>>;
    setExpireDate: Dispatch<SetStateAction<Date | undefined | null>>;
  };
  form: { addForm: FormInstance };
  func: {
    onFinishForm: (values: INewTouristArg) => void;
    handleDelete: (item?: ITableTourist) => void;
  };
  requests: {
    onDeleteTourist: (item?: ITableTourist) => void;
    listTouristAddReq: (values: INewTouristArg[]) => Promise<void>;
    getTouristByPassport: (passPort: string) => Promise<void>;
  };
}
interface ICountryProps {
  countries: ICountry[];
  loading: boolean;
}
export const AddTouristInfoContext = createContext<IAddTouristInfo | undefined>(
  undefined
);

export const AddTouristInfoProvider: FC<PropsWithChildren> = ({ children }) => {
  // ---------------------sate------------------

  const [loading, setLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [mode, setMode] = useState<string>("");
  const [tourCode, setTourCode] = useState<string>("");
  const [tourists, setTourists] = useState<ITableTourist[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [selectedTourist, setSelectedTourist] = useState<
    ITableTourist | undefined
  >(undefined);
  const [deleteItem, setDeleteItem] = useState<ITableTourist | undefined>(
    undefined
  );
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [countryProps, setCountryProps] = useState<ICountryProps>({
    countries: [],
    loading: false,
  });
  const [tablePaginationParams, setTablePaginationParams] =
    useState<IPagination>({
      Offset: 1,
      Limit: 10,
    });
  const [birthDate, setBirthDate] = useState<Date | undefined | null>(
    undefined
  );
  const [expireDate, setExpireDate] = useState<Date | undefined | null>(
    undefined
  );
  //---------------------form--------------------

  const [addForm] = Form.useForm();

  const { words } = useLanguage();

  const { tourId } = useParams();
  const { state } = useLocation();
  //---------------------func-------------------

  const onFinishForm = (values: INewTouristArg) => {
    if (values.birthDate)
      values.birthDate = convertDateTimeIran(values.birthDate);
    if (values.expireDate)
      values.expireDate = convertDateTimeIran(values.expireDate);
    if (selectedTourist) touristEditREq(values);
    else touristAddReq(values);
  };

  const handleDelete = (item?: ITableTourist) => {
    item ? setDeleteItem(item) : setDeleteItem(undefined);
  };

  const onDeleteTourist = async () => {
    if (!deleteItem) return;
    setConfirmLoading(true);
    try {
      const { DeleteTourist } = new TourManagementService();
      const result = await DeleteTourist(deleteItem.tourTouristId);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setDeleteItem(undefined);
        if (deleteItem.id === selectedTourist?.id)
          setSelectedTourist(undefined);
        getTourists();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmLoading(false);
    }
  };
  const touristAddReq = async (values: INewTouristArg) => {
    if (!tourId) return;
    setLoading(true);
    try {
      const { NewTourist } = new TourManagementService();
      const result = await NewTourist(Number(tourId), values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        addForm.resetFields();
        setExpireDate(undefined);
        setBirthDate(undefined);
        getTourists();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const touristEditREq = async (values: INewTouristArg) => {
    if (!selectedTourist) return;
    setLoading(true);
    try {
      const { EditTourist } = new TourManagementService();
      const result = await EditTourist(selectedTourist.tourTouristId, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setSelectedTourist(undefined);
        setExpireDate(undefined);
        setBirthDate(undefined);

        getTourists();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const listTouristAddReq = async (values: INewTouristArg[]) => {
    if (!tourId) return;
    setLoading(true);
    try {
      const { NewListTourist } = new TourManagementService();
      const result = await NewListTourist(Number(tourId), values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        getTourists();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFindTourist = (tourist?: ITourist) => {
    if (tourist) {
      const newTourist = {
        firstName: tourist.firstName,
        lastName: tourist.lastName,
        passportNO: tourist.passportNO,
        gender:
          tourist?.gender === Gender.Male || tourist?.gender === Gender.Female
            ? tourist?.gender
            : null,
        countryId: tourist?.countryId,
        job: tourist?.job,
        birthPlace: tourist?.birthPlace,
        birthDate: dayjs(tourist?.birthDate),
        expireDate: dayjs(tourist?.expireDate),
      };
      addForm.setFieldsValue(newTourist);
      setBirthDate(tourist.birthDate ? new Date(tourist.birthDate) : null);
      setExpireDate(tourist.expireDate ? new Date(tourist.expireDate) : null);
      const selectedTourist: ITableTourist = {
        ...tourist,
        tourTouristId: tourist.id,
      };
      setSelectedTourist(selectedTourist);
    } else {
      addForm.setFieldsValue({
        firstName: null,
        lastName: null,
        gender: null,
        countryId: null,
        job: null,
        birthPlace: null,
        birthDate: null,
        expireDate: null,
      });
      setBirthDate(null);
      setExpireDate(null);
    }
  };

  const getTouristByPassport = async (passPort: string) => {
    try {
      const { GetTouristByPassport } = new TourManagementService();
      const res = await GetTouristByPassport(passPort);
      if (res && res.status === 200 && res.data) {
        handleFindTourist(res.data);
      } else {
        message.info(words.addTouristInfo.getTouristMessage);
        handleFindTourist();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getTourists = useCallback(async () => {
    if (!tourId) return;
    setDataLoading(true);
    const params = new URLSearchParams(tablePaginationParams);
    try {
      const { AllTourist } = new TourManagementService();
      const res = await AllTourist(Number(tourId), params.toString());
      if (res && res.status === 200 && res.data) {
        let arr: ITableTourist[] = [];
        res.data.records.forEach((record) =>
          arr.push({ tourTouristId: record.tourTouristId, ...record.tourist })
        );
        setTourists(arr);
        setTotalCount(res.data.count);
      } else {
        setTourists([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoading(false);
    }
  }, [tablePaginationParams, tourId]);

  const getCountries = useCallback(async () => {
    setCountryProps((prev) => ({ ...prev, loading: true }));
    try {
      const { Countries } = new BasicInformationService();
      const res = await Countries();
      if (res && res.status === 200 && res.data) {
        setCountryProps((prev) => ({ ...prev, countries: res.data.records }));
      } else {
        setCountryProps((prev) => ({ ...prev, countries: [] }));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setCountryProps((prev) => ({ ...prev, loading: false }));
    }
  }, []);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  useEffect(() => {
    if (tourId) {
      getTourists();
    }
  }, [getTourists, mode, tourId]);

  useEffect(() => {
    if (selectedTourist) {
      addForm.setFieldsValue({
        firstName: selectedTourist.firstName,
        lastName: selectedTourist.lastName,
        passportNO: selectedTourist.passportNO,
        gender: selectedTourist?.gender,
        countryId: selectedTourist?.countryId,
        job: selectedTourist?.job,
        birthPlace: selectedTourist?.birthPlace,
        birthDate: dayjs(selectedTourist?.birthDate),
        expireDate: dayjs(selectedTourist?.expireDate),
      });
      setBirthDate(
        selectedTourist.birthDate ? new Date(selectedTourist.birthDate) : null
      );
      setExpireDate(
        selectedTourist.expireDate ? new Date(selectedTourist.expireDate) : null
      );
    } else {
      addForm.resetFields();
      setBirthDate(null);
      setExpireDate(null);
    }
  }, [addForm, selectedTourist]);

  useEffect(() => {
    if (state && state.mode) setMode(state.mode);
    else setMode("edit");
    if (state && state.code) setTourCode(state.code);
    else setTourCode("234566");
  }, [state]);

  const contextValue: IAddTouristInfo = {
    value: {
      loading,
      totalCount,
      mode,
      tourCode,
      countryProps,
      selectedTourist,
      tourists,
      tablePaginationParams,
      dataLoading,
      deleteItem,
      confirmLoading,
      birthDate,
      expireDate,
    },
    dispatch: {
      setTablePaginationParams,
      setSelectedTourist,
      setBirthDate,
      setExpireDate,
    },
    form: { addForm },
    func: { onFinishForm, handleDelete },
    requests: { onDeleteTourist, listTouristAddReq, getTouristByPassport },
  };
  return (
    <AddTouristInfoContext.Provider value={contextValue}>
      {children}
    </AddTouristInfoContext.Provider>
  );
};

export const useAddTouristInfo = () => useContext(AddTouristInfoContext)!;
