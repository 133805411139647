import { Col, Divider, Flex, Row } from "antd";
import { FC } from "react";
import { convertDate } from "src/helper";
import { useTourViewData } from "src/modules/viewTour/context/TourViewData";

const TeamPrint: FC = () => {
  const {
    states: { formData },
  } = useTourViewData();
  return (
    <Row className="mt-10">
      <Col span={12}>
        <Flex vertical className="border border-solid border-[#CACACA]">
          <p className="font-[16px] font-bold text-[#1F1F1F] text-center">
            مشخصات راهنما
          </p>

          <Divider className="p-0 m-0 bg-[#CACACA]" />

          <Flex justify="space-between" className="p-[16px]">
            <Flex vertical>
              <div>
                <span>نام و نام خانوادگی :</span>
                <span className="px-[3px]">
                  {formData?.tourInfo?.leaderInfo}
                </span>
              </div>
              <div>
                <span>کد ملی :</span>
                <span className="px-[3px]">
                  {formData?.tourInfo?.leaderNationalCode}
                </span>
              </div>
              <div>
                <span>شماره تماس :</span>
                <span className="px-[3px]">
                  {formData?.tourInfo?.leaderPhone}
                </span>
              </div>

              <div>
                <span>شماره کارت راهنما :</span>
                <span className="px-[3px]">
                  {formData?.tourInfo?.leaderGuidCardNumber}
                </span>
              </div>
            </Flex>
            <Flex vertical>
              <div>
                <span>نام پدر :</span>
                <span className="px-[3px]">
                  {formData?.tourInfo?.leaderFatherName}
                </span>
              </div>
              <div>
                <span>ش.ش:</span>
                <span className="px-[3px]">
                  {formData?.tourInfo?.leaderIdNumber}
                </span>
              </div>

              <div>
                <span>ت.ت:</span>
                <span className="px-[3px]">
                  {formData?.tourInfo?.leaderBirthDate
                    ? convertDate(formData?.tourInfo?.leaderBirthDate)
                    : "-"}
                </span>
              </div>
            </Flex>
          </Flex>
        </Flex>
      </Col>

      <Col span={12}>
        <Flex vertical className="border border-solid border-[#CACACA]">
          <p className="font-[16px] font-bold text-[#1F1F1F] text-center align-middle">
            مشخصات راننده و خودرو
          </p>

          <Divider className="p-0 m-0 bg-[#CACACA]" />

          <Flex justify="space-between" className="p-[16px]">
            <Flex vertical>
              <div>
                <span>نام و نام خانوادگی :</span>
                <span className="px-[3px]">
                  {formData?.tourInfo?.driverInfo}
                </span>
              </div>
              <div>
                <span>کد ملی :</span>
                <span className="px-[3px]">
                  {formData?.tourInfo?.driverNationalCode}
                </span>
              </div>
              <div>
                <span>نوع خودرو :</span>
                <span className="px-[3px]">{formData?.tourInfo?.carInfo}</span>
              </div>

              <div>
                <span>شماره خودرو :</span>
                <span className="px-[3px]">
                  {formData?.tourInfo?.carNumber}
                </span>
              </div>
            </Flex>
            <Flex vertical>
              <div>
                <span>نام پدر :</span>
                <span className="px-[3px]">
                  {formData?.tourInfo?.driverFatherName}
                </span>
              </div>
              <div>
                <span>ش.ش:</span>
                <span className="px-[3px]">
                  {formData?.tourInfo?.driverPhone}
                </span>
              </div>

              <div>
                <span>ت.ت:</span>
                <span>
                  {formData?.tourInfo?.driverBirthDate
                    ? convertDate(formData?.tourInfo?.driverBirthDate)
                    : "-"}
                </span>
              </div>
            </Flex>
          </Flex>
        </Flex>
      </Col>
    </Row>
  );
};

export default TeamPrint;
