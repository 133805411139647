export const useCurrentManagementPath = (path: string) => {
  let currentManagementPath: string = "";

  let pathArray = path.split("/");

  if (path.length > 0)
    currentManagementPath = "/" + pathArray[pathArray.length - 1];
  else currentManagementPath = path;

  return currentManagementPath;
};
