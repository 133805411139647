import { styled } from "styled-components";

export const EditProfileContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  .ant-row {
    padding: 0 !important;
  }
  .ant-input {
    color: #000;
  }
  .username {
    color: var(--Gray-03, #6c7172);
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .userRole {
    color: var(--text-2, #f06614);
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .nameHolder {
    color: var(--text, #001377);
    text-align: center;
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 14px;
  }
  .divider1 {
    width: 1px;
    height: 32px;
    color: #a3aed0 !important;
    z-index: 0;
  }
  .changePicture {
    border-radius: 6px;
    background: var(--2, #2c73fc);
    box-shadow: 1px 2px 2px 0px rgba(147, 147, 147, 0.5);
    width: 24px;
    height: 24px;
  }
  .ant-form-item {
    width: 80% !important;
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }
  .footer {
    padding-top: 32px;
    padding-inline: 24px;
    left: 24px;
    width: calc(100% - 48px);
  }
  .avatarBorder {
    border: 3px solid #2c73fc;
  }
  .save {
    background-color: #ffffff;
    color: #f06614;
    border: solid 1px #f06614;
  }
  .save:hover {
    background-color: #ffffff !important;
    color: #f06614 !important;
  }
  .inputs-container {
    border-radius: 20px;
    border: 1px solid var(--color-Stroke-footer-buttons, #fde8dc);
    background: var(--Secondary-Primary-White, #fff);
    padding: 32px 24px;
  }
  .ant-form-item,
  .ant-row {
    width: 100% !important;
  }
`;
