import { Button, Col, Divider, Form, Input, Row, Table } from "antd";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { FlightListContainer, PageFooter, TableWrapper } from "./style";
import { useForm } from "antd/es/form/Form";
import { getRowKey } from "src/helper/getRowKey";
import { useParams } from "react-router-dom";
import { IPeople } from "src/services/ExportData/models";
import { ExportDataService } from "src/services/ExportData/ExportData.service";
import moment from "moment";
import { FormItem } from "src/components/UiKit/FormItem";
import SubmitButton from "src/components/UiKit/SubmitButton";
import _, { uniqueId } from "lodash";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { generateFlightExcel } from "./createExcel";
import columns from "./columns";
import html2pdf from "html2pdf.js";
import { DownloadPdfIcon } from "../DownladPdfIcon";

export const FlightList: FC = () => {
  const [data, setData] = useState<IPeople[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const { tourId } = useParams();
  const [form] = useForm();
  const componentRef = useRef<HTMLDivElement>(null);

  const print = useReactToPrint({
    content: () => componentRef.current,
    print: async (printIframe: HTMLIFrameElement) => {
      try {
        setDownloadLoading(true);

        const document = printIframe.contentDocument;
        if (document) {
          const html = document.getElementsByTagName("html")[0];
          const htmlRect = html.getBoundingClientRect();
          const pdfWith = 300;
          const pdfCurrentHeight = (pdfWith * htmlRect.height) / htmlRect.width;
          const pageCount = htmlRect.height / pdfWith;
          const roundedPageCount =
            Math.round(pageCount) < pageCount
              ? Math.ceil(pageCount)
              : Math.round(pageCount);
          const pdfHeight = pdfCurrentHeight * roundedPageCount;
          const opt = {
            jsPDF: {
              format: [pdfWith, pdfHeight],
            },
            image: { type: "jpeg", quality: 1 },
            html2canvas: { scale: 3 },
            unit: "px",
          };
          await html2pdf().from(html).set(opt).save("FlightList.pdf");
        }
      } catch (err) {
        console.log(err);
      } finally {
        setDownloadLoading(false);
      }
    },
  });
  const handlePrint = async () => {
    setDownloadLoading(true);

    print();
  };
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);
  const resetForm = () => {
    form.resetFields();
    setTitle("");
  };
  const onFinish = (value: { title: string }) => {
    setTitle(value.title);
  };

  const fetchData = useCallback(async () => {
    if (!tourId) return;
    try {
      setLoading(true);
      const { GetExportFlight } = new ExportDataService();
      const res = await GetExportFlight(tourId);
      if (res && res.status === 200 && res.data) {
        setData(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourId]);

  const tableData = data?.map((item) => {
    const newItem = { ...item };
    newItem.birthDate = item.birthDate
      ? moment(item.birthDate).format("YYYY/MM/DD")
      : "";
    newItem.expireDate = item.expireDate
      ? moment(item.expireDate).format("YYYY/MM/DD")
      : "";

    return newItem;
  });

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const reverseColumns = () => {
    const newColumns = [...columns];
    return _.reverse(newColumns);
  };

  return (
    <Row justify={"center"}>
      <ReactToPrint content={reactToPrintContent} removeAfterPrint />

      <Col span={22}>
        <FlightListContainer gutter={[8, 8]}>
          <Col span={24}>
            <div className="pt-[24px] pb-[16px]">
              لطفا تیتر جدول را بنویسید.
            </div>
          </Col>
          <Form form={form} onFinish={onFinish}>
            <Col span={24}>
              <div className="flex flex-col gap-[8px]">
                <div className="px-[12px]">تیتر جدول</div>
                <FormItem name="title" required>
                  <Input
                    className="px-[12px] py-[16px] bg-[#F5F5F5] border-[#F5F5F5]"
                    placeholder="مثلا پرواز شیراز به اصفهان 9 صبح زاگرس ( 6 اردیبهشت 1403 )"
                    autoComplete="off"
                  />
                </FormItem>
              </div>
              <Divider />
            </Col>
            <Col span={24}>
              <div className="flex gap-[16px] justify-end">
                <Button
                  type="default"
                  className="min-w-[110px] text-[#4B4B4B] cursor-pointer"
                  onClick={resetForm}
                >
                  پاک سازی
                </Button>
                <SubmitButton form={form} loading={false}>
                  ثبت
                </SubmitButton>
              </div>
            </Col>
          </Form>
          <Col span={24}>
            <div>لیست گردشگران پرواز</div>
          </Col>
          <TableWrapper span={24}>
            <div className="px-[16px] flex justify-between bg-[#CACACA] h-[55px] items-center rounded-t-[8px]">
              <div className="text-[#000] title">{title}</div>
              <span className="material-symbols-outlined text-[#000000]">
                airplane_ticket
              </span>
            </div>
            <Table
              columns={reverseColumns()}
              dataSource={tableData}
              scroll={{ x: "auto" }}
              loading={loading}
              pagination={false}
              className="pt-[4px] pb-[32px] "
              rowKey={() => uniqueId()}
            />
          </TableWrapper>
          {/* for pdf download */}
          <div className="hidden">
            <TableWrapper span={24} ref={componentRef}>
              <div>
                <img
                  src="/assets/images/sarbargHeader.png"
                  alt="sarbarg"
                  className="mb-[32px]"
                  width={"100%"}
                />
              </div>
              <div className="mx-[24px] px-[16px] flex justify-between bg-[#CACACA] h-[55px] items-center rounded-t-[8px]">
                <div className="text-[#000] title">{title}</div>
                <span className="material-symbols-outlined text-[#000000]">
                  airplane_ticket
                </span>
              </div>
              <Table
                columns={reverseColumns()}
                dataSource={tableData}
                scroll={{ x: "auto" }}
                loading={loading}
                pagination={false}
                className="pt-[4px] pb-[32px] px-[24px] "
                rowKey={getRowKey}
              />
              <div>
                <img
                  src="/assets/images/sarbargFooter.png"
                  alt="sarbarg"
                  className="mt-[32px]"
                  width={"100%"}
                />
              </div>
            </TableWrapper>
          </div>
        </FlightListContainer>
      </Col>
      <Col span={24} className="pb-[32px]">
        <PageFooter className="pageFooter" justify="flex-end">
          <DownloadPdfIcon
            handlePrint={handlePrint}
            loading={downloadLoading}
          />
          <img
            src="/assets/images/excelDownload.svg"
            alt=""
            onClick={() => generateFlightExcel({ data, columns, title })}
          />
        </PageFooter>
      </Col>
    </Row>
  );
};
