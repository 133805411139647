import { FC } from "react";
import { Col, Spin } from "antd";
import { ManagementBasicInfoContainer } from "./style";
import { useManagementBasicInfoData } from "./context";
import ViewBasicInfo from "./components/ViewBasicInfo";
import EditBasicInfo from "./components/EditBasicInfo";

const ManagementRoute: FC = () => {
  const {
    states: { mode, dataLoading },
  } = useManagementBasicInfoData();

  return (
    <>
      {/* <PageHeader
        mode={mode}
        title={words.addTour.basicInfo}
        backTitle={words.global.back}
        tourCode={123465}
      /> */}
      <ManagementBasicInfoContainer justify={"center"} gutter={[0, 24]}>
        <Col xs={24} md={mode === "edit" ? 23 : 16} className="content">
          {dataLoading ? (
            <Spin size="large" className="mt-[30px]" />
          ) : mode === "view" ? (
            <ViewBasicInfo />
          ) : (
            <EditBasicInfo />
          )}
        </Col>
      </ManagementBasicInfoContainer>
    </>
  );
};

export default ManagementRoute;
