import { Row } from "antd";
import styled from "styled-components";

export const FilterContainer = styled.div`
  width: 100%;
  height: 108px;
  border-radius: 16px;
  padding: 16px;
  gap: 24px;
  background-color: #fffaf8;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);

  .ant-menu {
    width: 100%;
    background-color: transparent;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .ant-menu-horizontal > .ant-menu-item-selected::after {
    border-bottom: 1px solid rgba(246, 163, 114, 1) !important;
  }
  .ant-menu-horizontal > .ant-menu-item:hover .ant-menu-title-content {
    color: rgba(246, 163, 114, 1);
  }
  .ant-menu-horizontal > .ant-menu-item:hover:after {
    border-bottom: 1px solid rgba(246, 163, 114, 1) !important;
  }
  .ant-menu-item-selected .ant-menu-title-content {
    color: rgba(246, 163, 114, 1);
  }
  .edit-link {
    color: #f6a372;
    border: 1px solid #f6a372;
    border-radius: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
  }
  .license-btn {
    background-color: #f6a372;
    border-radius: 12px;
    padding: 8px;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
  }
  .license-btn:hover {
    background-color: #f6a372 !important;
    color: #fff !important;
  }
`;

export const FilterContentContainer = styled(Row)`
  .ant-input {
    color: #1f1f1f;
  }
  .form {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 24px 40px 24px 40px;
  }
  .form-row {
    min-height: 487px;
    position: relative;
  }
  .upload {
    width: 100%;
    border: 1px dashed #dadeec;
    position: absolute;
    top: 100px;
    border-radius: 8px;
    padding: 16px 24px;
    img {
      cursor: pointer;
    }
  }
  .upload-title {
    font-weight: 400;
    font-size: 12px;
    color: #001377;
  }
  .buttons {
    width: 100%;
    border-top: 1px solid #dadeec;
    padding-top: 20px;
    position: absolute;
    bottom: 0;
  }
  .buttons-last-step {
    width: 100%;
    border-top: 1px solid #dadeec;
    padding-top: 20px;
    margin-top: 30px;
  }
  .title {
    font-weight: 500;
    font-size: 14px;
    color: #1f1f1f;
  }
  .ant-btn {
    width: 85px;
    height: 40px;
    border-radius: 12px;
    padding: 8px;
    gap: 4px;
    font-weight: 700;
    font-size: 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .next {
    background-color: #fad1b8;
    color: #ffffff;
  }
  .next:hover {
    background-color: #fad1b8 !important;
    color: #ffffff !important;
  }
  .cancel {
    background-color: #f5f5f5;
    margin: 0 10px;
  }
  .buttons-last-step .cancel {
    display: inline-flex;
    flex-direction: row-reverse;
  }
  .material-icons {
    font-size: 12px;
  }
  .input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .input-file + label {
    width: 66px;
    height: 66px;
    color: #f06614;
    background-color: #fff;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    border-radius: 8px;
    text-align: center;
    border: 1px dashed #f06614;
    background-color: #fef1ea;
    cursor: pointer;
  }
  .delete-attach-file-btn {
    color: #f64c4c;
  }
  .delete-attach-file-btn:hover {
    color: #f64c4c;
    padding: 0 !important;
    background-color: transparent !important;
  }
`;
