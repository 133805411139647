import { IObject } from 'src/models/interfaces'

export const convertObj = (params: IObject) => {
  const obj = {}

  Object.keys(params)
    .filter(value => !!params[value])
    .forEach(key => {
      Object.assign(obj, { [key]: typeof params[key] === 'string' ? params[key].trim() : params[key] })
    })

  return obj
}