import styled from "styled-components";
import DatePicker from "react-datepicker";

export const DatePickerStyled = styled(DatePicker)`
  background-color: transparent !important;
  border: none !important;
  margin-top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 12px;

  .ant-picker-input input {
    text-align: center;
    color: #1f1f1f;
    font-weight: 500;
    font-size: 12px;
  }

  .ant-picker-input input::placeholder {
    color: #8e8e8e;
    font-weight: 500;
    font-size: 12px;
  }
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker-ignore-onclickoutside,
  .ad-date-picker {
    width: 100%;
    height: 100%;
    border: none !important;
    outline: 0 !important;
    box-shadow: 0 !important;
    border-radius: 12px !important;
  }
`;
