import { FC, useEffect } from "react";
import { Button, Col, Divider, Form, Row } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import InputUiKit from "src/components/UiKit/Input";
import useLanguage from "src/store/language";
import { useCountryContext } from "../context";
import { useFormHasChanged } from "../useFormHasChanged";
import SubmitButton from "src/components/UiKit/SubmitButton";

const AddCountry: FC = () => {
  const {
    onFinish,
    createCountryForm,
    editCountry,
    setEditCountry,
    addEditLoading,
  } = useCountryContext();

  const { words } = useLanguage();

  const onCancelCountry = () => {
    editCountry ? setEditCountry(undefined) : createCountryForm.resetFields();
  };

  const canSubmit = useFormHasChanged();

  useEffect(() => {
    if (editCountry) {
      createCountryForm?.setFieldsValue({
        faName: editCountry.faName,
        enName: editCountry.enName,
        shortName: editCountry.shortName,
      });
    } else createCountryForm?.resetFields();
  }, [createCountryForm, editCountry]);

  return (
    <Form onFinish={onFinish} form={createCountryForm}>
      <Row gutter={[24, 12]}>
        <Col span={24} className="mb-[40px]">
          <p className="title">لطفا اطلاعات کشور را وارد کنید.</p>
        </Col>
        <Col xs={24} md={8}>
          <FormItem
            name={"faName"}
            label="نام کشور (فارسی)"
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit placeholder="مثلا ایران" />
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem
            name={"enName"}
            label="نام کشور (انگلیسی)"
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit placeholder="e.g. Iran" dir="ltr" />
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem
            name={"shortName"}
            label="نام اختصاری (انگلیسی)"
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit placeholder="e.g. IR" dir="ltr" />
          </FormItem>
        </Col>
        <Col span={24} className="flex justify-end">
          <Button
            type="text"
            className="bg-[#F5F5F5] text-[#8E8E8E] ml-2 w-[85px]"
            onClick={onCancelCountry}
          >
            {editCountry
              ? words.basicInfo.countryCancel
              : words.basicInfo.buttonClear}
          </Button>
          <SubmitButton
            form={createCountryForm}
            loading={addEditLoading}
            canSubmit={canSubmit}
          >
            {editCountry
              ? words.basicInfo.countrySubmit
              : words.basicInfo.buttonAdd}
          </SubmitButton>
        </Col>
        <Divider className="m-0 p-0" />
      </Row>
    </Form>
  );
};

export default AddCountry;
