import styled from "styled-components";
import { Layout } from "antd";

const { Sider } = Layout;

export const SidebarContainer = styled(Sider)`
  transition: all 0.3s;
  z-index: 100;
  left: 0;
  overflow-y: auto;
  background-color: #fff;

  .ant-layout-sider-children {
    overflow-y: auto;
  }
  .sidebar {
    width: 100%;
  }

  .ant-menu-title-content {
    /* Body Text/ Medium/ Bold */
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 175% */
    letter-spacing: -0.32px;
    color: #4b4b4b;
  }
  .ant-menu-submenu-selected {
    background-color: #f6a372;
    .ant-menu-submenu-title,
    .ant-menu-submenu-title span,
    .ant-menu-submenu-title .ant-menu-item-icon {
      color: #fff !important;
    }
  }

  .ant-menu-item-selected {
    background-color: #f6a372;
    .ant-menu-title-content {
      color: #fff;
    }
    .ant-menu-item-icon {
      color: #fff !important;
    }
  }
  .ant-menu-root {
    border: unset !important;
  }
  .notice {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: flex-end;
  }
  .username {
    color: var(--gray-03, #8e8e8e);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
  }
  .avatar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .userRole {
    color: #f06614;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
  }
  .ant-scroll-number {
    margin: 6px 6px;
    box-shadow: unset;
  }
  .ant-avatar {
    border: 3px solid #2c73fc;
  }
  .sidebarAvatar {
    width: 100%;
  }
  .ant-menu-item:not(.ant-menu-item-only-child),
  .ant-menu-submenu {
    margin-bottom: 20px;
  }
  .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline,
  .ant-menu-light > .ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background-color: #ffffff;
    z-index: 10000000 !important;
  }
  .ant-menu-light
    .ant-menu-submenu-selected
    .ant-menu-sub
    .ant-menu-item-active {
    background-color: #fad1b8 !important;
  }
  .ant-menu-light
    .ant-menu-submenu-selected
    .ant-menu-sub
    .ant-menu-item-active
    .ant-menu-title-content {
    color: #fff;
  }
`;

export const AddTourContainer = styled.div`
  width: 100%;
  height: 76px;
  margin-top: 40px;
  border: 1px solid #fde8dc;
  border-radius: 16px;
  padding: 16px;
  .content {
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    span.material-icons {
      background-color: #fde8dc;
      width: 44px;
      height: 44px;
      border-radius: 10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
    .iran-admin-add-tour-title {
      display: inline-flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      margin-right: 20px;
    }
    .title {
      color: #1f1f1f;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      white-space: nowrap;
    }
    .description {
      color: #cacaca;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      white-space: nowrap;
    }
  }
`;
