import { FC, useRef } from "react";
import html2canvas from "html2canvas";
import { Row } from "antd";
import Footer from "./Footer";
import LoadingSpin from "src/components/loadingSpin";
import { usePrintTourRequest } from "./context";
import { Details } from "./Details";

export const PrintTour: FC = () => {
  const {
    states: { dataLoadings },
  } = usePrintTourRequest();

  const printRef = useRef(null);

  const handleDownloadImage = async () => {
    const element = printRef.current;
    if (element) {
      const canvas = await html2canvas(element);

      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = data;
        link.download = "image.jpg";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    }
  };

  return (
    <Row className="bg-[#fff] pt-[40px] rounded-2xl relative h-[1446px]">
      {dataLoadings.tour || dataLoadings.tourInfo ? (
        <LoadingSpin />
      ) : (
        <>
          <Details ref={printRef} />

          <Footer onJpg={handleDownloadImage} />
        </>
      )}
    </Row>
  );
};
