import { App } from "antd";
import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { useTourAddEditData } from "src/modules/addEditTour/context/TourAddEditData";
import { TabMenuKeys } from "src/modules/addEditTour/models";
import { IDriverInfoArg } from "src/services/Tour/models";
import { TourService } from "src/services/Tour/Tour.service";
import useLanguage from "src/store/language";

interface IContextValue {
  states: {
    loading: boolean;
    dataLoading: boolean;
  };
  requests: {
    addEditTouristRequest: (values: IDriverInfoArg) => void;
  };
}

export const DriverInfoRequestContext = createContext<
  IContextValue | undefined
>(undefined);

const DriverInfoRequestProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const { words } = useLanguage();
  const { dispatches } = useTourAddEditData();
  const { tourId } = useParams();
  const location = useLocation();
  const { message } = App.useApp();

  const addEditTouristRequest = async (values: IDriverInfoArg) => {
    if (!tourId) return;
    try {
      setLoading(true);
      const { DriverInfo } = new TourService();

      const result = await DriverInfo(parseInt(tourId), values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        dispatches.setFormData((prev) => ({
          ...prev,
          [TabMenuKeys.driver]: values,
        }));
        dispatches.setCurrent(TabMenuKeys.car);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getDriverInfo = useCallback(async () => {
    if (!tourId) return;
    try {
      setDataLoading(true);
      const { GetDriverInfo } = new TourService();

      const result = await GetDriverInfo(tourId);
      if (result && result.status === 200) {
        dispatches.setFormData((prev) => ({
          ...prev,
          driver: result.data,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname.includes(ROUTES.tourEdit) && tourId) {
      getDriverInfo();
    }
  }, [getDriverInfo, location.pathname, tourId]);

  const contextValues = {
    states: { loading, dataLoading },
    requests: { addEditTouristRequest },
  };

  return (
    <DriverInfoRequestContext.Provider value={contextValues}>
      {children}
    </DriverInfoRequestContext.Provider>
  );
};

export default DriverInfoRequestProvider;

export const useDriverInfoRequest = () => useContext(DriverInfoRequestContext)!;
