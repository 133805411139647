import { FC } from "react";
import { ConfigProvider } from "antd";
import fa_IR from "antd/lib/locale/fa_IR";
import { JalaliLocaleListener } from "antd-jalali";
import { RangePickerStyled } from "./styles";
import FormControlUiKit from "../FormControl";
import useLanguage from "src/store/language";

interface IProps {
  label?: string;
  value?: any;
  onChange?: () => void;
}

const RangePickerUi: FC<IProps> = ({ label = "", value, onChange }) => {
  const { words } = useLanguage();
  return (
    <ConfigProvider locale={fa_IR} direction="rtl">
      <JalaliLocaleListener />
      <FormControlUiKit
        childrenContainerClassName="!justify-center"
        label={label}
        height={"51px"}
      >
        <RangePickerStyled
          value={value}
          onChange={onChange}
          placeholder={[`${words.global.from}`, `${words.global.to}`]}
          dropdownClassName="rtlRangePicker"
          autoComplete="off"
        />
      </FormControlUiKit>
    </ConfigProvider>
  );
};

export default RangePickerUi;
