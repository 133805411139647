import { FC, RefObject } from "react";
import { Col, Flex, Row, Table } from "antd";
import moment from "moment";
import momentJalali from "moment-jalaali";
import { TouristListStyle } from "./touristListStyle";
import { touristListColumns } from "./touristColumns";

import { IGetExportHotelList } from "src/services/ExportData/models";
import { uniqueId } from "lodash";
interface IProps {
  componentRef?: RefObject<HTMLDivElement> | null;
  isPdf?: boolean;
  data: IGetExportHotelList | undefined;
  loading: boolean;
}
export const TouristListLetter: FC<IProps> = ({
  componentRef,
  isPdf,
  data,
  loading,
}) => {
  const tableData = data?.people?.map((item) => {
    const newItem = { ...item };
    newItem.birthDate = item.birthDate
      ? momentJalali(item.birthDate)
          .format("jYYYY/jMM/jDD")
          .concat("\n")
          .concat(moment(item.birthDate).format("YYYY/MM/DD"))
      : "";
    newItem.expireDate = item.expireDate
      ? momentJalali(item.expireDate)
          .format("jYYYY/jMM/jDD")
          .concat("\n")
          .concat(moment(item.expireDate).format("YYYY/MM/DD"))
      : "";

    return newItem;
  });
  return (
    <TouristListStyle
      gutter={[8, 8]}
      ref={componentRef}
      dir="rtl"
      className={isPdf ? "!shadow-none" : ""}
    >
      {isPdf && (
        <Col span={24}>
          <img
            src="/assets/images/sarbargHeader.png"
            alt="sarbarg"
            // className="absolute top-0 left-0 right-0 z-2"
            width={"100%"}
          />
        </Col>
      )}
      <Col span={24}>
        <Row className="px-[24px] py-[32px]" gutter={[8, 8]}>
          <Col span={24}>
            <Row gutter={8}>
              <Col span={8} className="!h-[148px]">
                <Flex
                  justify="space-between"
                  className="headerHolder"
                  align="center"
                >
                  <div>رومینگ لیست میثاق گشت صدرا</div>
                  <div>
                    <span className="material-symbols-outlined ">tour</span>
                  </div>
                </Flex>
                <Flex
                  justify="space-between"
                  align="center"
                  className="h-[103px]"
                >
                  <div className="contentHolder">کد تور </div>
                  <div className="backDataHolder">{data?.tourCode} </div>
                </Flex>
              </Col>
              <Col span={8} className="!h-[148px]">
                <Flex
                  justify="space-between"
                  className="headerHolder"
                  align="center"
                >
                  <div>تور لیدر</div>
                  <div>
                    <span className="material-symbols-outlined ">
                      follow_the_signs
                    </span>
                  </div>
                </Flex>
                <Flex align="center" vertical className="h-[103px]" gap={1}>
                  <div className="flex justify-between w-full h-[51px]">
                    <div className="contentHolder">نام و نام خانوادگی </div>
                    <div className="backDataHolder">{data?.leaderName} </div>
                  </div>
                  <div className="flex justify-between w-full h-[51px]">
                    <div className="contentHolder">شماره تماس </div>
                    <div className="backDataHolder">{data?.leaderPhone} </div>
                  </div>
                </Flex>
              </Col>
              <Col span={8} className="!h-[148px]">
                <Flex
                  justify="space-between"
                  className="headerHolder"
                  align="center"
                >
                  <div>هتل {data?.hotelName}</div>
                  <div>
                    <span className="material-symbols-outlined ">hotel</span>
                  </div>
                </Flex>
                <Flex align="center" vertical className="h-[103px]" gap={1}>
                  <div className="flex justify-between w-full h-[51px]">
                    <div className="contentHolder">ورود </div>
                    <div className="backDataHolder">
                      {data?.arrivaldate
                        ? momentJalali(data?.arrivaldate)
                            .format("jYYYY/jMM/jDD")
                            .concat("\n")
                            .concat(
                              moment(data?.arrivaldate).format("YYYY/MM/DD")
                            )
                        : ""}
                    </div>
                  </div>
                  <div className="flex justify-between w-full h-[51px]">
                    <div className="contentHolder"> خروج </div>
                    <div className="backDataHolder">
                      {data?.departureDate
                        ? momentJalali(data?.departureDate)
                            .format("jYYYY/jMM/jDD")
                            .concat("\n")
                            .concat(
                              moment(data?.departureDate).format("YYYY/MM/DD")
                            )
                        : ""}
                    </div>
                  </div>
                </Flex>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Table
              columns={touristListColumns}
              dataSource={tableData}
              scroll={{ x: "auto" }}
              loading={loading}
              pagination={false}
              className="routesTable"
              rowKey={() => uniqueId()}
            />
          </Col>
        </Row>
      </Col>
      {isPdf && (
        <Col span={24}>
          <img
            src="/assets/images/sarbargFooter.png"
            alt="sarbarg"
            // className="absolute bottom-0 left-0 right-0 z-2"
            width={"100%"}
          />
        </Col>
      )}
    </TouristListStyle>
  );
};
