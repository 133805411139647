import { FC } from "react";
import { ConfigProvider, DatePickerProps } from "antd";
import fa_IR from "antd/lib/locale/fa_IR";
import { JalaliLocaleListener } from "antd-jalali";
import { DatePickerStyled } from "./styles";
import FormControlUiKit from "../FormControl";
interface IProps {
  label?: string;
  value?: any;
}
export type DatePickerLocalizedProps = DatePickerProps & IProps;

const DatePickerUi: FC<DatePickerLocalizedProps> = ({
  label = "",
  value,
  ...props
}) => {
  return (
    <ConfigProvider locale={fa_IR}>
      <JalaliLocaleListener />
      <FormControlUiKit
        childrenContainerClassName="!justify-center"
        label={label}
        height={"51px"}
      >
        <DatePickerStyled value={value} autoComplete="off" {...props} />
      </FormControlUiKit>
    </ConfigProvider>
  );
};

export default DatePickerUi;
