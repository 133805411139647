import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import { TourService } from "src/services/Tour/Tour.service";
import { useTourViewData } from "../../context/TourViewData";
interface IContextValue {
  states: {
    dataLoading: boolean;
  };
}

export const TouristInfoRequestContext = createContext<
  IContextValue | undefined
>(undefined);

const TouristInfoRequestProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const { dispatches } = useTourViewData();
  const { tourId } = useParams();

  const getTouristInfo = useCallback(async () => {
    if (!tourId) return;

    try {
      setDataLoading(true);
      const { GetTouristInfo } = new TourService();

      const result = await GetTouristInfo(tourId);
      if (result && result.status === 200) {
        dispatches.setFormData((prev) => ({
          ...prev,
          tourist: { ...prev?.tourist, tourists: result.data },
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tourId) {
      getTouristInfo();
    }
  }, [getTouristInfo, tourId]);

  const contextValues = {
    states: {
      dataLoading,
    },
  };

  return (
    <TouristInfoRequestContext.Provider value={contextValues}>
      {children}
    </TouristInfoRequestContext.Provider>
  );
};

export default TouristInfoRequestProvider;

export const useTouristInfoRequest = () =>
  useContext(TouristInfoRequestContext)!;
