import { FC } from "react";
import { useLocation } from "react-router-dom";
import { PageTitleKeysType, usePageTitle } from "../hook/usePageTitle";
import { HeaderContainer } from "../styles/header";
import { Col, Flex, Row } from "antd";
import HeaderLogo from "./HeaderLogo";
import useLanguage from "src/store/language";
import { ROUTES } from "src/models/enums/routes";
import useGlobalStore from "src/store/global";
import { useManagementTourBack } from "src/hook/useManagementTourBack";
import { useCurrentManagementPath } from "../hook/useCurrentManagementPath";
import { useManagementsTitle } from "./managementTitle";

const Header: FC = () => {
  let location = useLocation();
  const pageTitle = usePageTitle(location.pathname as PageTitleKeysType);

  const currentManagementPath = useCurrentManagementPath(location.pathname);

  const { backHandler, backHandlerHome } = useManagementTourBack();

  const { isRtl, words } = useLanguage();
  const { userInfo, tourCode } = useGlobalStore();
  const { firstTitle, secondTitle } = useManagementsTitle();

  const TitleOne = () => {
    let text = "";
    firstTitle.forEach((item) => {
      if (location.pathname.includes(item.url)) {
        text = " / ".concat(item.text);
      }
    });
    return text;
  };
  const TitleTwo = () => {
    let text = "";
    secondTitle.forEach((item) => {
      if (location.pathname.includes(item.url)) {
        text = " / ".concat(item.text);
      }
    });
    return text;
  };

  return (
    <HeaderContainer $isrtl={isRtl}>
      <Row className="content" align={"middle"}>
        <Col span={8} className="pr-[24px]">
          {location.pathname === ROUTES.home && (
            <>
              <p className="hello">{`${words.mainLayout.hi} ${
                userInfo?.firstName || ""
              } ${userInfo?.lastName || ""}`}</p>
              <h2 className="title">{pageTitle}</h2>
            </>
          )}
          {location.pathname.includes(ROUTES.tourTable) && (
            <>
              <h2 className="title">{words.sidebarItems.tourTable}</h2>
              <Flex gap={8}>
                {location.pathname.includes(ROUTES.managementTour) ? (
                  <p className="text-[#4B4B4B] text-[16px] font-bold !m-[0px]">
                    {words.global.tour.concat(" ".concat(tourCode))}
                  </p>
                ) : (
                  ""
                )}
                <span className="text-[#4B4B4B] text-[16px] font-bold">
                  {TitleOne()}
                </span>
                <span className="text-[#4B4B4B] text-[16px] font-bold">
                  {TitleTwo()}
                </span>
              </Flex>
            </>
          )}
          {(location.pathname.includes(ROUTES.addTour) ||
            location.pathname.includes(ROUTES.settings)) && (
            <>
              <h2 className="title">{pageTitle}</h2>
            </>
          )}
          {location.pathname.includes(ROUTES.basicInfo) && (
            <>
              <h2 className="title">
                {location.pathname.includes(ROUTES.settings)
                  ? words.sidebarItems.systemSettings
                  : location.pathname.includes(ROUTES.basicInfo)
                  ? words.sidebarItems.basicInfo
                  : words.sidebarItems.tourTable}
              </h2>
              <p className="title-dataEntry">{pageTitle}</p>
            </>
          )}
        </Col>
        <Col
          span={
            location.pathname.includes(ROUTES.managementTour) ||
            location.pathname.includes(ROUTES.basicInfo)
              ? 8
              : 16
          }
        >
          <HeaderLogo
            management={
              location.pathname.includes(ROUTES.managementTour) ||
              location.pathname.includes(ROUTES.basicInfo)
            }
          />
        </Col>
        {location.pathname.includes(ROUTES.managementTour) ||
        location.pathname.includes(ROUTES.basicInfo) ? (
          <Col
            span={location.pathname.includes(ROUTES.basicInfo) ? 8 : 8}
            className={`pl-[24px] flex justify-end`}
          >
            {/* <span
          className="material-symbols-outlined cursor-pointer"
          onClick={logoutHandler}
        >
          logout
        </span> */}

            <div
              className="back-box"
              onClick={
                location.pathname.includes(ROUTES.basicInfo)
                  ? backHandlerHome
                  : backHandler
              }
            >
              <span>
                {currentManagementPath === ROUTES.managementTour
                  ? words.global.backToTable
                  : words.global.back}
              </span>
              <span className="material-icons mr-2">arrow_back</span>
            </div>
          </Col>
        ) : null}
        {/* {!location.pathname.includes(ROUTES.basicInfo) && (
          <Col span={8} className="language-search">
            {location.pathname.includes(ROUTES.tourView) ||
            location.pathname.includes(ROUTES.tourAdd) ||
            location.pathname.includes(ROUTES.tourEdit) ? (
              <div
                className={`inline-flex ${
                  isRtl ? "flex-row" : "flex-row-reverse"
                }`}
              >
                <Link
                  to={ROUTES.tourTable}
                  className="back"
                  dir={isRtl ? "ltr" : "rtl"}
                >
                  <span className="material-icons">
                    {isRtl ? "arrow_back" : "arrow_forward"}
                  </span>
                  <span className="back-title">
                    {words.tableTour.backToTable}
                  </span>
                </Link>
                <ChooseLanguage />
              </div>
            ) : (
              <div
                className={`inline-flex w-full ${
                  isRtl ? "flex-row" : "flex-row-reverse"
                }`}
              >
                <ChooseLanguage />
                {!location.pathname.includes(ROUTES.settings) && <SearchBox />}
              </div>
            )}
          </Col>
        )} */}
      </Row>
    </HeaderContainer>
  );
};

export default Header;
