import { Flex } from "antd";
import { FC } from "react";
import { IAssignSimcard } from "src/services/TourManagement/models";
import { useAddEditSimcardData } from "../add-edit/context";
import useLanguage from "src/store/language";

interface ITableActionsProps {
  item: IAssignSimcard;
}

const TableActions: FC<ITableActionsProps> = ({ item }) => {
  const { words } = useLanguage();
  const {
    func: { handleDeleteId },
    dispatches: { setSelectedAssign },
  } = useAddEditSimcardData();

  const handleSelectAssign = () => {
    setSelectedAssign(item);
  };

  return (
    <>
      <Flex justify="center" className="gap-[16px]">
        <div className="edit-action" onClick={() => handleSelectAssign()}>
          <span className="material-icons ">edit_note</span>
          <span>{words.global.editAction}</span>
        </div>

        <div
          className="delete-action"
          onClick={() => handleDeleteId(item.tourTouristId)}
        >
          <span className="material-icons text-[#ec2d30] text-[16px] cursor-pointer">
            delete_forever
          </span>
          <span>{words.global.delete}</span>
        </div>
      </Flex>
    </>
  );
};

export default TableActions;
