import { FC } from "react";
import { Row } from "antd";
import Filter from "./Filter";
import FilterContent from "./FilterContent";
import HotelsAdd from "./add";
import HotelView from "./view";
import { useHotelsData } from "../context/HotelsData";

const HotelsMode: FC = () => {
  const {
    states: { viewMode },
  } = useHotelsData();
  return (
    <>
      <Filter />
      <Row
        align={"middle"}
        justify={"center"}
        gutter={[4, 24]}
        className="px-[24px] pb-[32px]"
      >
        {viewMode === "menu" ? (
          <FilterContent />
        ) : viewMode === "view" ? (
          <HotelView />
        ) : (
          <HotelsAdd />
        )}
      </Row>
    </>
  );
};

export default HotelsMode;
