import { FC } from "react";
import { Col, Spin } from "antd";
import { Link } from "react-router-dom";
import useLanguage from "src/store/language";
import { Icons } from "src/components/customIcons";
import { ROUTES } from "src/models/enums/routes";
import { TourStates } from "src/models/enums/TourStates";
import { ActiveTourCard } from "./activeTourCard";
import { ActiveToursStyles } from "./styles";
import { useDashboardData } from "../context";

export const ActiveTours: FC = () => {
  const { words } = useLanguage();

  const {
    states: { allTour, dataLoading },
  } = useDashboardData();

  return (
    <Col xs={24} md={12}>
      <ActiveToursStyles>
        <Col span={24} className="px-2.5">
          <Icons name={"circle"} />
          <span className="title">{words.dashboard.activeTours}</span>
        </Col>
        {dataLoading ? (
          <div className="text-center w-full h-full">
            <Spin size="large" />
          </div>
        ) : (
          <>
            {allTour && allTour.startCount > 0 && (
              <>
                <Col span={24} className="ps-[24px] tours-card-col">
                  <div className="flex gap-[16px] overflow-auto pb-[24px]">
                    {allTour && allTour.startCount > 0 ? (
                      allTour.startTours?.map((item, index) => (
                        <ActiveTourCard tour={item} key={index} />
                      ))
                    ) : (
                      <p className="w-full h-[319px] flex justify-center items-center text-[#1b2559] text-2xl font-bold">
                        {words.dashboard.activeToursEmptyMessage}
                      </p>
                    )}
                  </div>
                </Col>

                {allTour ? (
                  <Col
                    span={24}
                    className="flex justify-end items-center text-[#FFFFFF] pb-[24px]"
                  >
                    <Link
                      className="text-[#ffffff] hover:text-[#ffffff]"
                      to={ROUTES.tourTable.concat(
                        "?".concat("TourStates=" + TourStates.Progress)
                      )}
                      state={{ tourState: TourStates.Progress }}
                    >
                      {words.global.seeAll.concat(" (")}
                      {allTour && allTour?.startCount
                        ? allTour?.startCount
                        : 0}{" "}
                      {words.global.tour}
                      {" )"}
                    </Link>
                  </Col>
                ) : null}
              </>
            )}
          </>
        )}
      </ActiveToursStyles>
    </Col>
  );
};
