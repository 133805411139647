import { FC } from "react";
import { Button, Col, Divider, Form, Row } from "antd";
import FormItem from "antd/es/form/FormItem";
import SubmitButton from "src/components/UiKit/SubmitButton";
import useLanguage from "src/store/language";
import DatePickerUi from "src/components/UiKit/DatePicker";
import InputUiKit from "src/components/UiKit/Input";
import InputNumberUiKit from "src/components/UiKit/inputNumber";
import { useAddEditInsuranceData } from "../context";
import { useManagementInsuranceData } from "../../../context";

const InsuranceInfo: FC = () => {
  const { words } = useLanguage();

  const {
    states: { mode },
  } = useManagementInsuranceData();

  const {
    values: { loading, selectedInsurance },
    func: { onFinishInsuranceInfo },
    form: { insuranceInfoForm },
    dispatches: { setSelectedInsurance },
  } = useAddEditInsuranceData();

  const handleCancel = () => {
    if (selectedInsurance) {
      insuranceInfoForm.resetFields();
    }
    setSelectedInsurance(undefined);
  };

  return (
    <>
      <p className="title mt-[24px]">
        {mode === "add"
          ? words.managementInsurance.addInfoFormTitle
          : words.managementInsurance.editInfoFormTitle}
      </p>
      <Form
        onFinish={onFinishInsuranceInfo}
        form={insuranceInfoForm}
        layout="vertical"
        preserve={false}
        className="mt-[40px]"
      >
        <Row gutter={[24, 12]}>
          <Col xs={24} md={8}>
            <FormItem
              name={"type"}
              label={words.managementInsurance.insuranceTypeLabel}
              rules={[{ required: true, message: words.global.fieldRequired }]}
            >
              <InputUiKit
                placeholder={words.managementInsurance.insuranceTypePlaceHolder}
              />
            </FormItem>
          </Col>

          <Col xs={24} md={8}>
            <FormItem
              name={"company"}
              label={words.managementInsurance.insuranceCompanyLabel}
            >
              <InputUiKit
                placeholder={
                  words.managementInsurance.insuranceCompanyPlaceHolder
                }
              />
            </FormItem>
          </Col>

          <Col xs={24} md={8}>
            <FormItem name={"date"} label={words.managementInsurance.dateLabel}>
              <DatePickerUi placeholder={words.managementInsurance.select} />
            </FormItem>
          </Col>

          <Col xs={24} md={8}>
            <FormItem
              name={"amount"}
              label={words.managementInsurance.insurancePriceLabel}
            >
              <InputNumberUiKit
                controls={false}
                placeholder={
                  words.managementInsurance.insurancePricePlaceHolder
                }
              />
            </FormItem>
          </Col>

          <Col span={24} className="flex justify-end">
            <Button
              type="text"
              className="bg-[#F5F5F5] text-[#8E8E8E] ml-2 w-[85px]"
              onClick={handleCancel}
            >
              {!selectedInsurance
                ? words.managementTime.clear
                : words.managementTime.cancel}
            </Button>
            <SubmitButton
              form={insuranceInfoForm}
              loading={loading}
              canSubmit={false}
            >
              {!selectedInsurance
                ? words.global.add
                : words.managementTime.editBtn}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
      <Divider className="bg-[#EEEEEE]" />
    </>
  );
};

export default InsuranceInfo;
