import { FC } from "react";
import { Divider } from "antd";
import { TableUI } from "src/components/UiKit/table";
import { ColumnsType } from "antd/es/table";
import useLanguage from "src/store/language";
import { IInsuranceDocument } from "src/services/TourManagement/models";
import TableActions from "./TableAction";
import { useInsuranceAttachmentData } from "../../context";

const AttachmentTable: FC = () => {
  const {
    states: {
      documents,
      dataLoading,
      totalCount,
      tablePaginationParams,
      selectedDocument,
      mode,
    },
    dispatches: { setTablePaginationParams },
  } = useInsuranceAttachmentData();

  const { words } = useLanguage();

  const tableColumns: ColumnsType<IInsuranceDocument> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      width: 62,
      render: (text, record, index) =>
        (tablePaginationParams.Offset - 1) * tablePaginationParams.Limit +
        index +
        1,
    },
    {
      title: `${words.managementInsurance.fileTableLabel}`,
      dataIndex: "title",
      key: "title",
      width: 144,
    },
    {
      title: `${words.managementInsurance.fileFormat}`,
      dataIndex: "extention",
      key: "extention",
      width: 133,
    },
    ...(mode === "view"
      ? [
          {
            title: `${words.basicInfo.tableActions}`,
            dataIndex: "tools",
            key: "tools",
            width: 100,
            render: (_: any, record: IInsuranceDocument) => (
              <TableActions item={record} />
            ),
          },
        ]
      : []),
    {
      title: `${words.managementFiles.description}`,
      dataIndex: "detail",
      key: "detail",
      // render: (value) =>
      //   value && value !== ""
      //     ? value.length > 100
      //       ? value.slice(0, 100).concat("...")
      //       : value
      //     : "-",
      render: (text, record, index) =>
        mode === "view" ? (
          <span>{record.detail?.substring(0, 90).concat("...")}</span>
        ) : (
          <span>{record.detail?.substring(0, 50).concat("...")}</span>
        ),
    },
    ...(mode === "view"
      ? []
      : [
          {
            title: `${words.basicInfo.tableActions}`,
            dataIndex: "tools",
            key: "tools",
            width: 300,
            render: (_: any, record: IInsuranceDocument) => (
              <TableActions item={record} />
            ),
          },
        ]),
  ];

  return (
    <>
      {mode === "add" || mode === "edit" ? (
        <Divider className="bg-[#E1E1E1]" />
      ) : null}
      <p
        className={`simcard-table-title ${
          mode === "view" ? "!font-bold !text-[#233069] !text-[16px]" : ""
        }`}
      >
        {words.managementInsurance.tableTitle}
      </p>
      <TableUI
        columns={tableColumns}
        dataSource={documents}
        loading={dataLoading}
        scroll={{ x: 500 }}
        tableType="secondary"
        rowClassName={(record, index) =>
          record.attachmentId === selectedDocument?.attachmentId
            ? "selected-row-for-edit"
            : ""
        }
        pagination={{
          position: ["bottomCenter"],
          total: totalCount,
          pageSize: tablePaginationParams.Limit,
          current: tablePaginationParams.Offset,
          onChange(page, pageSize) {
            setTablePaginationParams((prev) => ({
              ...prev,
              Offset: page,
              Limit: pageSize,
            }));
          },
        }}
      />
    </>
  );
};

export default AttachmentTable;
