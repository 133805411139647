import { App } from "antd";
import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { useTourAddEditData } from "src/modules/addEditTour/context/TourAddEditData";
import { TabMenuKeys } from "src/modules/addEditTour/models";
import { ILeaderInfoArg } from "src/services/Tour/models";
import { TourService } from "src/services/Tour/Tour.service";
import useLanguage from "src/store/language";

interface IContextValue {
  states: {
    loading: boolean;
    dataLoading: boolean;
  };
  requests: {
    addEditLeaderRequest: (values: ILeaderInfoArg) => void;
  };
}

export const LeaderInfoRequestContext = createContext<
  IContextValue | undefined
>(undefined);

const LeaderInfoRequestProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const { words } = useLanguage();
  const { dispatches } = useTourAddEditData();
  const { tourId } = useParams();
  const location = useLocation();
  const { message } = App.useApp();

  const addEditLeaderRequest = async (values: ILeaderInfoArg) => {
    if (!tourId) return;
    try {
      setLoading(true);
      const { LeaderInfo } = new TourService();

      const result = await LeaderInfo(parseInt(tourId), values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        dispatches.setFormData((prev) => ({
          ...prev,
          [TabMenuKeys.leader]: values,
        }));
        dispatches.setCurrent(TabMenuKeys.translator);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getLeaderInfo = useCallback(async () => {
    if (!tourId) return;
    try {
      setDataLoading(true);
      const { GetLeaderInfo } = new TourService();

      const result = await GetLeaderInfo(tourId);
      if (result && result.status === 200) {
        dispatches.setFormData((prev) => ({
          ...prev,
          leader: result.data,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname.includes(ROUTES.tourEdit) && tourId) {
      getLeaderInfo();
    }
  }, [getLeaderInfo, location.pathname, tourId]);

  const contextValues = {
    states: { loading, dataLoading },
    requests: { addEditLeaderRequest },
  };

  return (
    <LeaderInfoRequestContext.Provider value={contextValues}>
      {children}
    </LeaderInfoRequestContext.Provider>
  );
};

export default LeaderInfoRequestProvider;

export const useLeaderInfoRequest = () => useContext(LeaderInfoRequestContext)!;
