import { Button, Flex, TableColumnsType } from "antd";
import { useState } from "react";
import { IManagementRoute } from "src/services/TourManagement/models";

const useHotelLetterColumns = () => {
  const [selectedRoute, setSelectedRoute] = useState<IManagementRoute>();

  const columns: TableColumnsType<IManagementRoute> = [
    {
      key: "#",
      title: "#",
      width: 65,
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      key: "cityName",
      dataIndex: "cityName",
      title: "شهر مبدا",
      width: 115,
      align: "center",
      className: "columns",
    },
    {
      key: "inlineCities",
      dataIndex: "inlineCities",
      title: "شهر بین راهی",
      width: 115,
      align: "center",
    },
    {
      dataIndex: "groupNum",
      key: "groupNum",
      title: "گروه",
      width: 115,
      align: "center",
    },
    {
      dataIndex: "flightTime",
      key: "flightTime",
      title: "ساعت پرواز",
      width: 115,
      align: "center",
    },
    {
      key: "arraivalDate",
      dataIndex: "arraivalDate",
      title: "تاریخ ورود",
      width: 115,
      align: "center",
    },
    {
      dataIndex: "departureDate",
      key: "departureDate",
      title: "تاریخ خروج",
      width: 115,
      align: "center",
    },
    {
      key: "hotelName",
      dataIndex: "hotelName",
      title: "هتل",
      width: 115,
      align: "center",
    },
    {
      key: "operation",
      dataIndex: "operation",
      title: "عملیات",
      width: 115,
      align: "center",

      render: (text, record) => {
        return selectedRoute && selectedRoute.id === record.id ? (
          <></>
        ) : (
          <Flex justify="center">
            <Button
              onClick={() => {
                setSelectedRoute(record);
              }}
              type="default"
              className="w-[63px] h-[28px] text-[#4B4B4B] cursor-pointer bg-[#E4F2FF] text-[10px] flex justify-center items-center"
            >
              انتخاب مسیر
            </Button>
          </Flex>
        );
      },
    },
  ];

  return {
    columns,
    selectedRoute,
    setSelectedRoute,
  };
};

export default useHotelLetterColumns;
