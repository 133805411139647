import { Flex, Row, Table } from "antd";
import styled from "styled-components";

export const NajaListContainer = styled(Row)`
  .content {
    min-height: calc(100vh - 176px - 95px - 64px - 102px);
  }
`;
export const NajaListWrapper = styled(Row)`
  .wrapper {
    border-radius: 16px;
    background: var(--color-Base-Color-White, #fff);
    /* min-height: 350px; */
    /* Table */
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
  }
`;
export const NajaListTable = styled(Table)`
  th,
  td {
    border-inline-end: unset !important;
  }

  thead {
    tr {
      th {
        border-bottom: 1px solid #8e8e8e;
        background: var(--color-Base-Color-White, #fff);
        color: var(--color-Light-Mode-Text-Title, #233069);
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
`;
export const PageFooter = styled(Flex)`
  margin-top: 32px;
  padding: 32px 24px 16px 24px;
  border-top: 1px solid #dadeec;
  img {
    cursor: pointer;
    user-select: none;
  }
`;
