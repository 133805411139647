import { FC } from "react";
import { Button, Col, Divider, Form, Row } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import SubmitButton from "src/components/UiKit/SubmitButton";
import { useFormHasChanged } from "../useFormHasChanged";
import { useCarDataContext } from "../context";

const AddCar: FC = () => {
  //const [submittable, setSubmittable] = useState<boolean>(false);

  const { onFinish, createCarForm, editCar, setEditCar, loadings } =
    useCarDataContext();

  const { words } = useLanguage();

  const canSubmit = useFormHasChanged();

  const onCancelProvince = () => {
    editCar ? setEditCar(undefined) : createCarForm.resetFields();
  };

  return (
    <Form onFinish={onFinish} form={createCarForm}>
      <Row gutter={[24, 12]}>
        <Col span={24} className="mb-[40px]">
          <div className="title">{words.basicInfo.carTitle}</div>
        </Col>
        <Col xs={24} md={12}>
          <FormItem
            name={"carName"}
            label="خودرو"
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit placeholder="مثلا ون" />
          </FormItem>
        </Col>

        <Col span={24} className="flex justify-end">
          <Button
            type="text"
            className="bg-[#F5F5F5] text-[#8E8E8E] ml-2"
            onClick={onCancelProvince}
          >
            {editCar
              ? words.basicInfo.countryCancel
              : words.basicInfo.buttonClear}
          </Button>
          <SubmitButton
            form={createCarForm}
            loading={loadings.addEdit}
            canSubmit={canSubmit}
          >
            {editCar
              ? words.basicInfo.countrySubmit
              : words.basicInfo.buttonAdd}
          </SubmitButton>
        </Col>
        <Divider className="m-0 p-0" />
      </Row>
    </Form>
  );
};

export default AddCar;
