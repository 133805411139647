import { ColumnsType } from "antd/es/table";
import { DataNode } from "antd/es/tree";
import {
  FC,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { UseQueryResult, useQuery } from "react-query";
import { reactQueryEndPoints } from "src/constants/rectQueryEndPoints";
import { SystemService } from "src/services/System/System.service";
import { UserManagementService } from "src/services/UserManagement/UserManagement.service";
import { IRole } from "src/services/UserManagement/models";
import {
  IAllAction,
  IAssignRoleToActionArgs,
} from "src/services/System/models";
import useLanguage from "src/store/language";
import { App } from "antd";

//---------------------------Models-------------------------------------
export interface TableData extends IAllAction {
  "#": number;
  tools: ReactNode;
}
interface IContext {
  values: {
    tableColumns: ColumnsType<any>;
    allActionsQuery: UseQueryResult<IAllAction[] | undefined> | undefined;
    dataSource: TableData[];
    showAssign: boolean;
    treeData: DataNode[] | undefined;
    loadingRolesOfAction: boolean;
    currentCheckedKeys: number[];
    assignLoading: boolean;
    getRolesLoading: boolean;
  };
  func: {
    onCancelAssign: () => void;
    onAssign: (selectedKeys: number[]) => Promise<void>;
  };
}
//-------------------------Create Context---------------------------------------
const defaultContextValue: IContext = {
  values: {
    tableColumns: [],
    allActionsQuery: undefined,
    dataSource: [],
    showAssign: false,
    treeData: [],
    loadingRolesOfAction: false,
    currentCheckedKeys: [],
    assignLoading: false,
    getRolesLoading: false,
  },
  func: {
    onCancelAssign: () => {},
    onAssign: async () => {},
  },
};

export const AssignActionToRoleCTX =
  createContext<IContext>(defaultContextValue);

//-------------------------Provider---------------------------------------
export const AssignActionToRoleProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const allActionsQuery = useQuery({
    queryFn: getActions,
    queryKey: reactQueryEndPoints.AllActions,
    enabled: false,
  });
  const { message } = App.useApp();

  const { data, refetch } = allActionsQuery;
  const [dataSource, setDataSource] = useState<TableData[]>([]);
  const [showAssign, setShowAssign] = useState<boolean>(false);
  const [currentAction, setCurrentAction] = useState<IAllAction>();
  const [getRolesLoading, setRolesLoading] = useState<boolean>(false);
  const [currentCheckedKeys, setCurrentCheckedKeys] = useState<number[]>([]);
  const [assignLoading, setAssignLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [loadingRolesOfAction, setLoadingRolesOfAction] =
    useState<boolean>(false);
  const tableColumns = useTableColumns();
  const { words } = useLanguage();
  const onClickTools = async (action: IAllAction) => {
    try {
      setLoadingRolesOfAction(true);
      setShowAssign(true);
      setCurrentAction(action);
      setCurrentCheckedKeys([]);
      const selectedRoles = await getRolesOfAction(action.id);
      if (selectedRoles) {
        setCurrentCheckedKeys(selectedRoles.map((role) => role.id));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingRolesOfAction(false);
    }
  };
  const onCancelAssign = () => {
    setCurrentAction(undefined);
    setShowAssign(false);
  };
  useEffect(() => {
    if (data) {
      setDataSource(createTableData(data, onClickTools));
    }
  }, [data]);
  useEffect(() => {
    refetch();
  }, [refetch]);
  const createTree = (role: IRole) => {
    const newRole: DataNode = {
      title: role.roleName,
      // value: role.id,
      key: role.id,
    };
    if (role.childeren.length > 0) {
      newRole.children = role.childeren.map((item) => createTree(item));
    }
    return newRole;
  };
  const treeData: DataNode[] | undefined = roles.map((item) =>
    createTree(item)
  )[0]?.children;
  const fetchRoles = async () => {
    setRolesLoading(true);
    getRoles()
      .then((roles) => {
        if (roles && roles.length) {
          setRoles(roles);
        }
      })
      .finally(() => {
        setRolesLoading(false);
      });
  };

  const onAssign = async (selectedKeys: number[]) => {
    if (!currentAction) return;
    try {
      setAssignLoading(true);
      const { AssignRoleToAction } = new SystemService();
      const reqBody: IAssignRoleToActionArgs = {
        actionId: currentAction.id,
        rolesId: selectedKeys,
      };
      const result = await AssignRoleToAction(reqBody);
      if (result && result.status === 200) {
        message.success(
          words.systemSettings.assignActionMessage +
            currentAction?.displayName +
            words.systemSettings.assignRoleSuccessfullyMessage
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAssignLoading(false);
    }
  };
  useEffect(() => {
    fetchRoles();
  }, []);
  const contextValue: IContext = {
    values: {
      tableColumns,
      allActionsQuery,
      dataSource,
      showAssign,
      treeData,
      loadingRolesOfAction,
      currentCheckedKeys,
      assignLoading,
      getRolesLoading,
    },
    func: {
      onCancelAssign,
      onAssign,
    },
  };
  return (
    <AssignActionToRoleCTX.Provider value={contextValue}>
      {children}
    </AssignActionToRoleCTX.Provider>
  );
};

export const useAssignActionToRole = () => useContext(AssignActionToRoleCTX);

//---------------------------Functions-------------------------------------
const getActions = async () => {
  try {
    const { AllActions } = new SystemService();
    const result = await AllActions();
    if (result && result.status && result.data) {
      return result.data;
    }
  } catch (err) {
    console.log(err);
  }
};
const createTableData = (
  data: IAllAction[] | undefined,
  onClickTools: (action: IAllAction) => void
) => {
  if (!data) return [];
  const newData = data.map((action, i) => ({
    "#": i + 1,
    ...action,
    tools: <Tools onClick={() => onClickTools(action)} />,
  }));
  return newData;
};

const getRoles = async () => {
  try {
    const { Roles } = new UserManagementService();
    const res = await Roles();
    if (res && res.status === 200 && res.data) {
      return res.data;
    } else {
      return [];
    }
  } catch (e) {
    console.log(e);
  }
};
const getRolesOfAction = async (id: number) => {
  try {
    const { RolesOfAction } = new SystemService();
    const res = await RolesOfAction(id);
    if (res && res.data) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
  }
};
//---------------------------FC-------------------------------------
const Tools: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { words } = useLanguage();
  return (
    <div
      className="flex justify-center items-center gap-[8px] font-[400] text-[#F6A372] cursor-pointer"
      onClick={onClick}
    >
      <span className="material-icons ">mediation</span>
      <span>{words.systemSettings.assignAction}</span>
    </div>
  );
};
interface IRenderTableValue extends HTMLAttributes<HTMLDivElement> {
  text: string | number;
}
const RenderTableValue: FC<IRenderTableValue> = (props) => {
  return (
    <div
      className="text-center text-[14px] text-[#5D6881] font-[400]"
      {...props}
    >
      {props.text}
    </div>
  );
};
//-------------------------Columns---------------------------------------
const useTableColumns = () => {
  const { words } = useLanguage();
  const tableColumns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      className: "!font-bold text-center",
      width: 61,
      render: (text) => <RenderTableValue text={text} />,
    },
    {
      title: `${words.systemSettings.displayName}`,
      dataIndex: "displayName",
      key: "displayName",
      className: "!font-bold ",
      width: 124,
      render: (text) => <RenderTableValue text={text} />,
    },
    {
      title: `${words.systemSettings.name}`,
      dataIndex: "name",
      key: "name",
      className: "!font-bold ",
      width: 126,
      render: (text) => <RenderTableValue text={text} />,
    },
    {
      title: `${words.systemSettings.direction}`,
      dataIndex: "urlPath",
      key: "urlPath",
      className: "!font-bold ",
      width: 583,
      render: (text) => <RenderTableValue text={text} />,
    },
    {
      title: `${words.systemSettings.tools}`,
      dataIndex: "tools",
      key: "tools",
      className: "!font-bold select-none whitespace-nowrap",
      width: 156,
    },
  ];
  return tableColumns;
};
