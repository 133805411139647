import { Form, FormInstance, message } from "antd";
import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import type { UploadFile } from "antd/es/upload/interface";
import { IObject } from "src/models/interfaces";
import { IPagination } from "src/models/interfaces/pagination";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import {
  ITourDocument,
  ITourDocumentArg,
} from "src/services/TourManagement/models";
import useLanguage from "src/store/language";
interface IContextValue {
  states: {
    mode: string;
    documents: ITourDocument[];
    dataLoading: boolean;
    totalCount: number;
    tablePaginationParams: Pick<IPagination, "Offset" | "Limit">;
    selectedDocument: ITourDocument | undefined;
    loading: boolean;
    documentList: UploadFile[];
  };
  func: { onFinishTourDocumentFile: (values: ITourDocumentArg) => void };
  dispatches: {
    setTablePaginationParams: Dispatch<
      SetStateAction<Pick<IPagination, "Offset" | "Limit">>
    >;
    setSelectedDocument: Dispatch<SetStateAction<ITourDocument | undefined>>;
    setDocumentList: Dispatch<SetStateAction<UploadFile[]>>;
  };
  form: { tourFileForm: FormInstance };
  requests: { getTourDocuments: () => void };
}

export const ManagementFilesDataContext = createContext<
  IContextValue | undefined
>(undefined);

const ManagementFilesDataProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [mode, setMode] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [documents, setDocuments] = useState<ITourDocument[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [tablePaginationParams, setTablePaginationParams] =
    useState<IPagination>({
      Offset: 1,
      Limit: 10,
    });
  const [selectedDocument, setSelectedDocument] = useState<
    ITourDocument | undefined
  >(undefined);
  const [documentList, setDocumentList] = useState<UploadFile[]>([]);
  const [tourFileForm] = Form.useForm();

  const { words } = useLanguage();

  const { tourId } = useParams();
  const { state } = useLocation();

  const onFinishTourDocumentFile = (values: IObject) => {
    var formData = new FormData();

    formData.append("Title", values.Title);
    if (values.Detail) formData.append("Detail", values.Detail);
    if (documentList.length > 0)
      formData.append("File", documentList[0].originFileObj as any);

    if (selectedDocument) EditTourDocumentRequest(formData);
    else addTourDocumentFileRequest(formData);
  };

  const addTourDocumentFileRequest = async (values: FormData) => {
    if (!tourId) return;
    setLoading(true);
    try {
      const { NewManagementTourDocument } = new TourManagementService();
      const result = await NewManagementTourDocument(tourId, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setDocumentList([]);
        tourFileForm.resetFields();
        getTourDocuments();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const EditTourDocumentRequest = async (values: FormData) => {
    if (!selectedDocument) return;
    setLoading(true);
    try {
      const { EDitManagementTourDocument } = new TourManagementService();
      const result = await EDitManagementTourDocument(
        selectedDocument.attachmentId,
        values
      );
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setSelectedDocument(undefined);
        getTourDocuments();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getTourDocuments = useCallback(async () => {
    if (!tourId) return;
    setDataLoading(true);
    const params = new URLSearchParams(tablePaginationParams);
    try {
      const { GetTourDocument } = new TourManagementService();
      const res = await GetTourDocument(tourId, params.toString());
      if (res && res.status === 200 && res.data) {
        setDocuments(res.data.records);
        setTotalCount(res.data.count);
      } else {
        setDocuments([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoading(false);
    }
  }, [tablePaginationParams, tourId]);

  useEffect(() => {
    if (tourId && (mode === "edit" || mode === "view")) {
      getTourDocuments();
    }
  }, [getTourDocuments, mode, tourId]);

  useEffect(() => {
    if (state && state.mode) setMode(state.mode);
    else setMode("add");
  }, [state]);

  useEffect(() => {
    if (selectedDocument) {
      tourFileForm.setFieldsValue({
        Title: selectedDocument.title,
        Detail: selectedDocument.detail || null,
      });
    } else {
      setDocumentList([]);
      tourFileForm.resetFields();
    }
  }, [tourFileForm, selectedDocument]);

  const contextValues = {
    states: {
      mode,
      documents,
      dataLoading,
      totalCount,
      tablePaginationParams,
      selectedDocument,
      loading,
      documentList,
    },

    func: { onFinishTourDocumentFile },
    dispatches: {
      setTablePaginationParams,
      setSelectedDocument,
      setDocumentList,
    },
    form: { tourFileForm },
    requests: { getTourDocuments },
  };

  return (
    <ManagementFilesDataContext.Provider value={contextValues}>
      {children}
    </ManagementFilesDataContext.Provider>
  );
};

export default ManagementFilesDataProvider;

export const useManagementFilesData = () =>
  useContext(ManagementFilesDataContext)!;
