import { Form, FormInstance, message } from "antd";
import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import {
  IManagementRoute,
  INewRoutePlaceArg,
  ITableRoutePlace,
} from "src/services/TourManagement/models";
import useLanguage from "src/store/language";
import { IPagination } from "src/models/interfaces/pagination";
import { ICity, IPlace } from "src/services/BasicInformation/models";
import { BasicInformationService } from "src/services/BasicInformation/BasicInformation.service";
import { IArgsKey } from "src/services/models";
import { useLocation } from "react-router-dom";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";

interface IContextValue {
  states: {
    routePlaceForm: FormInstance;
    confirmLoading: boolean;
    deleteId: number;
    selectedRoutePlace: ITableRoutePlace | undefined;
    loading: boolean;
    routePlaces: ITableRoutePlace[];
    dataLoading: boolean;
    totalCount: number;
    tablePaginationParams: Pick<IPagination, "Offset" | "Limit">;
    place: IPlaceProps;
    mode: string;
    city: ICityProps;
  };
  func: {
    onFinish: (values: INewRoutePlaceArg) => void;
    handleDeleteId: (id: number) => void;
  };
  dispatches: {
    setSelectedRoutePlace: Dispatch<
      SetStateAction<ITableRoutePlace | undefined>
    >;
    setTablePaginationParams: Dispatch<
      SetStateAction<Pick<IPagination, "Offset" | "Limit">>
    >;
    setPlacesParams: Dispatch<SetStateAction<IPlaceParams>>;
  };
  requests: {
    onDelete: () => void;
  };
}

interface IPlaceProps {
  data: IPlace[];
  loading: boolean;
}
interface ICityProps {
  data: ICity[];
  loading: boolean;
}
export interface IPlaceParams extends IArgsKey {
  SearchQuery?: string;
}

export const RoutePlacesDataContext = createContext<IContextValue | undefined>(
  undefined
);

const RoutePlacesDataProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRoutePlace, setSelectedRoutePlace] = useState<
    ITableRoutePlace | undefined
  >(undefined);

  const [routePlaces, setRoutePlaces] = useState<ITableRoutePlace[]>([]);
  const [dataLoading, setDataLoadings] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [place, setPlace] = useState<IPlaceProps>({ data: [], loading: false });
  const [route, setRoute] = useState<IManagementRoute | undefined>(undefined);
  const [mode, setMode] = useState<string>("add");
  const [tablePaginationParams, setTablePaginationParams] =
    useState<IPagination>({
      Offset: 1,
      Limit: 10,
    });
  const [city, setCity] = useState<ICityProps>({
    data: [],
    loading: false,
  });
  const [placesParams, setPlacesParams] = useState<IPlaceParams>({});

  const [routePlaceForm] = Form.useForm();
  const { state } = useLocation();
  const { words } = useLanguage();

  const onFinish = (values: INewRoutePlaceArg) => {
    if (selectedRoutePlace) routePlaceEditREq(values);
    else routePlaceAddReq(values);
  };

  const routePlaceAddReq = async (values: INewRoutePlaceArg) => {
    if (!route) return;
    setLoading(true);
    try {
      const { AddManagementRoutePlace } = new TourManagementService();
      const result = await AddManagementRoutePlace(route.id, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setSelectedRoutePlace(undefined);
        getRoutePlaces();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const routePlaceEditREq = async (values: INewRoutePlaceArg) => {
    if (!selectedRoutePlace) return;
    setLoading(true);
    try {
      const { EditManagementRoutePlace } = new TourManagementService();
      const result = await EditManagementRoutePlace(
        selectedRoutePlace.routePlaceOfInterestId,
        values
      );
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setSelectedRoutePlace(undefined);
        getRoutePlaces();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteId = (id: number) => {
    setDeleteId(id);
  };

  const onDelete = async () => {
    if (deleteId === 0) return;
    setConfirmLoading(true);
    try {
      const { DeleteRoutePlace } = new TourManagementService();
      const result = await DeleteRoutePlace(deleteId);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        if (deleteId === selectedRoutePlace?.id)
          setSelectedRoutePlace(undefined);
        setDeleteId(0);
        getRoutePlaces();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmLoading(false);
    }
  };

  const getRoutePlaces = useCallback(async () => {
    if (!route) return;
    setDataLoadings(true);
    const params = new URLSearchParams(tablePaginationParams);

    try {
      const { AllManagementRoutePlace } = new TourManagementService();
      const res = await AllManagementRoutePlace(route.id, params.toString());
      if (res && res.status === 200 && res.data) {
        let arrPlace: ITableRoutePlace[] = [];
        res.data.records.forEach((record) =>
          arrPlace.push({
            routePlaceOfInterestId: record.routePlaceOfInterestId,
            ...record.placeOfInterest,
          })
        );
        setRoutePlaces(arrPlace);
        setTotalCount(res.data.count);
      } else {
        setRoutePlaces([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoadings(false);
    }
  }, [route, tablePaginationParams]);

  const getPlaces = useCallback(async () => {
    setPlace((prev) => ({ ...prev, loading: true }));
    const params = new URLSearchParams({
      ...placesParams,
      Offset: "1",
      limit: "999999999",
    });
    try {
      const { Places } = new BasicInformationService();
      const res = await Places(params.toString());
      if (res && res.status === 200 && res.data) {
        setPlace((prev) => ({ ...prev, data: res.data.records }));
      } else {
        setPlace((prev) => ({ ...prev, data: [] }));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setPlace((prev) => ({ ...prev, loading: false }));
    }
  }, [placesParams]);

  const getCities = useCallback(async () => {
    setCity((prev) => ({ ...prev, loading: true }));
    const params = new URLSearchParams({
      Offset: "1",
      limit: "999999999",
    });
    try {
      const { Cities } = new BasicInformationService();
      const res = await Cities(`?${params.toString()}`);
      if (res && res.status === 200 && res.data) {
        setCity((prev) => ({ ...prev, data: res.data.records }));
      } else {
        setCity((prev) => ({ ...prev, data: [] }));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setCity((prev) => ({ ...prev, loading: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCities();
  }, [getCities]);

  useEffect(() => {
    getPlaces();
  }, [getPlaces, placesParams]);

  useEffect(() => {
    getRoutePlaces();
  }, [getRoutePlaces, route]);

  useEffect(() => {
    if (selectedRoutePlace) {
      routePlaceForm.setFieldsValue({
        placeOfInterestId: selectedRoutePlace.id,
        cityId: selectedRoutePlace.cityName,
      });
    } else routePlaceForm.resetFields();
  }, [routePlaceForm, selectedRoutePlace]);

  useEffect(() => {
    if (state && state.mode) setMode(state.mode);
    else setMode("add");
  }, [state]);

  useEffect(() => {
    if (state && state.route) setRoute(state.route);
    else setRoute(undefined);
  }, [state]);

  const contextValues = {
    states: {
      routePlaceForm,
      confirmLoading,
      deleteId,
      selectedRoutePlace,
      loading,
      routePlaces,
      dataLoading,
      totalCount,
      tablePaginationParams,
      place,
      mode,
      city,
    },
    func: { onFinish, handleDeleteId },
    dispatches: {
      setSelectedRoutePlace,
      setTablePaginationParams,
      setPlacesParams,
    },
    requests: { onDelete },
  };

  return (
    <RoutePlacesDataContext.Provider value={contextValues}>
      {children}
      <DeleteConfirmModal
        showConfirm={deleteId > 0}
        onCancel={() => handleDeleteId(0)}
        onOk={() => onDelete()}
        title={words.managementRoute.deletePlaceTitle}
        okTitle={words.global.delete}
        loading={confirmLoading}
      />
    </RoutePlacesDataContext.Provider>
  );
};

export default RoutePlacesDataProvider;

export const useRoutePlacesData = () => useContext(RoutePlacesDataContext)!;
