import { FC } from "react";
import { useCreateUserContext } from "./context";
import { Button, Col, Form, Row } from "antd";
import { CreateUserContainer } from "./styles";
import { FormItem } from "src/components/UiKit/FormItem";
import { ConfirmModal } from "src/components/UiKit/ConfirmModal";
import InputUiKit from "src/components/UiKit/Input";
import useLanguage from "src/store/language";
import { TreeSelectUiKit } from "src/components/UiKit/treeSelect";
import InputPassword from "src/components/UiKit/inputPassword";

export const CreateUser: FC = () => {
  const {
    onFinish,
    onCancelConfirm,
    onConfirm,
    showConfirm,
    treeProps,
    confirmLoading,
    createUserForm,
  } = useCreateUserContext();

  const { words } = useLanguage();

  return (
    <>
      <Form onFinish={onFinish} form={createUserForm}>
        <CreateUserContainer justify={"center"} gutter={[0, 24]}>
          <Col span={15} className="content">
            <Row gutter={[24, 16]}>
              <Col span={24} className="mb-[40px]">
                <div className="title">
                  {words.systemSettings.createUserFormTitle}
                </div>
              </Col>
              <Col xs={24} lg={12}>
                <FormItem
                  name={"userName"}
                  required
                  rules={[
                    { required: true, message: words.global.fieldRequired },
                  ]}
                >
                  <InputUiKit
                    label={words.systemSettings.userName}
                    placeholder={words.systemSettings.userNamePlaceHolder}
                  />
                </FormItem>
              </Col>
              <Col xs={24} lg={12}>
                <FormItem name={"firstName"}>
                  <InputUiKit
                    label={words.systemSettings.firstName}
                    placeholder={words.systemSettings.firstNamePlaceHolder}
                  />
                </FormItem>
              </Col>
              <Col xs={24} lg={12}>
                <FormItem name={"lastName"}>
                  <InputUiKit
                    label={words.systemSettings.lastName}
                    placeholder={words.systemSettings.lastNamePlaceHolder}
                  />
                </FormItem>
              </Col>
              <Col xs={24} lg={12}>
                <FormItem
                  name={"password"}
                  required
                  rules={[
                    { required: true, message: words.global.fieldRequired },
                  ]}
                >
                  <InputPassword
                    label={words.systemSettings.password}
                    placeholder={words.systemSettings.passwordNamePlaceHolder}
                    className="input-password"
                  />
                </FormItem>
              </Col>
              <Col xs={24} lg={12}>
                <FormItem
                  name={"roles"}
                  required
                  rules={[
                    { required: true, message: words.global.fieldRequired },
                  ]}
                >
                  <TreeSelectUiKit
                    {...treeProps}
                    allowClear
                    className="selectOption"
                    treeCheckable
                    showSearch
                    treeNodeFilterProp="label"
                    label={words.systemSettings.userRole}
                    placeholder={words.systemSettings.roleSelectPlaceHolder}
                  />
                </FormItem>
              </Col>
              <Col span={24} className="flex gap-[24px] footer">
                <Button
                  type="text"
                  className="bg-[#FAD1B8] text-[#ffffff]"
                  htmlType="submit"
                >
                  {words.systemSettings.createButtonTitle}
                </Button>
              </Col>
            </Row>
          </Col>
        </CreateUserContainer>
      </Form>
      <ConfirmModal
        open={showConfirm}
        onCancel={onCancelConfirm}
        onOk={onConfirm}
        text={words.systemSettings.createUserConfirmMessage}
        title={words.systemSettings.createUserConfirmTitle}
        okText={words.systemSettings.createButtonTitle}
        confirmLoading={confirmLoading}
      />
    </>
  );
};
