import { FC } from "react";
import TourViewDataProvider from "./context/TourViewData";
import TourViewMode from "./components";

const TourView: FC = () => {
  return (
    <TourViewDataProvider>
      <TourViewMode />
    </TourViewDataProvider>
  );
};

export default TourView;
