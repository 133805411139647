import { forwardRef } from "react";
import { Col } from "antd";
import Header from "./Header";
import TeamPrint from "./Team";
import TourTable from "./TourTable";

export const Details = forwardRef<HTMLDivElement | null>((props, ref) => {
  return (
    <Col span={24} ref={ref} className="mt-[20px]">
      <Header />
      <TourTable />
      <TeamPrint />
    </Col>
  );
});
