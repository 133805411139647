import styled from "styled-components";
import { sidebarPadding, sidebarWidth } from "../constant/sidebarWidth";

export const LayoutContainer = styled.div<{ path: string }>`
  .ant-menu-horizontal {
    border: unset;
  }
  .contentWrapper {
    background: #fafafa;
  }
  .layout {
    background: #fff;
  }
  .ant-layout-sider-children {
    position: fixed;
    width: ${sidebarWidth}px;
    padding: ${sidebarPadding}px;
  }
  .ant-layout-content {
    overflow-y: auto;
    background-color: #fafafa;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .ant-layout-header {
    /* background-color: var(--color-Gray-Gray-50, #fafafa); */
    height: 102px !important;
    line-height: unset;
  }
  .ant-layout-sider {
    background: #fff;
    height: 100vh;
    overflow-y: auto;
    box-shadow: -4px 0px 5px 0px rgba(0, 140, 132, 0.1);
    z-index: 1000;
  }
  overflow-y: auto;
`;
