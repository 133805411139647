import { FC } from "react";
import CarInfoRequestProvider from "./CarInfoRequest";
import CarTabForm from "./CarTabForm";

const CarTab: FC = () => {
  return (
    <CarInfoRequestProvider>
      <CarTabForm />
    </CarInfoRequestProvider>
  );
};

export default CarTab;
