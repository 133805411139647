import ManagementSupervisor from "src/modules/managementSupervisor";
import ManagementSupervisorDataProvider from "src/modules/managementSupervisor/context";

const ManagementSupervisorPage = () => (
  <ManagementSupervisorDataProvider>
    <ManagementSupervisor />
  </ManagementSupervisorDataProvider>
);

export default ManagementSupervisorPage;
