import { Col, Flex, Row } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { convertDate } from "src/helper";
import { InsuranceInfoContainer } from "./style";
import { useInsuranceAttachmentData } from "../../context";

const InsuranceInfo: FC = () => {
  const { words } = useLanguage();
  const {
    states: { insurance },
  } = useInsuranceAttachmentData();

  return (
    <InsuranceInfoContainer>
      <p className="title">{words.managementInsurance.insuranceTitle}</p>
      <Col span={24} className="view-details">
        <Row className="w-full">
          <Col xs={24} md={12} className="">
            <Flex>
              <div className="detail-title">
                {words.managementInsurance.insuranceTypeLabel}:
              </div>
              <div className="detail-value">
                {insurance && insurance?.type ? insurance?.type : "-"}
              </div>
            </Flex>
            <Flex className="second">
              <div className="detail-title">
                {words.managementInsurance.dateLabel}:
              </div>
              <div className="detail-value">
                {insurance && insurance?.date
                  ? convertDate(insurance?.date)
                  : "-"}
              </div>
            </Flex>
          </Col>

          <Col xs={24} md={12} className="">
            <Flex>
              <div className="detail-title">
                {words.managementInsurance.insuranceCompanyLabel}:
              </div>
              <div className="detail-value">
                {insurance && insurance?.company ? insurance?.company : "-"}
              </div>
            </Flex>
            <Flex className="second">
              <div className="detail-title">
                {words.managementInsurance.insurancePriceLabel}:
              </div>
              <div className="detail-value">
                {insurance && insurance?.amount ? insurance?.amount : "-"}
              </div>
            </Flex>
          </Col>
        </Row>
      </Col>
    </InsuranceInfoContainer>
  );
};

export default InsuranceInfo;
