import { Button, Col, Divider } from "antd";
import { FC } from "react";
import { Icons } from "src/components/customIcons";
import { useTourViewData } from "../../context/TourViewData";

interface IPrintTouristFooterProps {
  onDoc: () => void;
}

const Footer: FC<IPrintTouristFooterProps> = ({ onDoc }) => {
  const {
    dispatches: { setViewMode },
  } = useTourViewData();
  return (
    <Col span={24} className="mt-5 px-[16px]">
      <Divider />
      <div className="flex justify-between mt-10">
        <Button
          type="text"
          icon={<span className="material-icons text-[#fff]">close</span>}
          onClick={() => setViewMode("view")}
          className="flex justify-between bg-[#F6A372] text-[#fff] hover:!bg-[#F6A372] hover:!text-[#fff]"
        >
          <span className="">بستن</span>
        </Button>

        <div>
          <span className="cursor-pointer" onClick={onDoc}>
            <Icons name="doc" />
          </span>
        </div>
      </div>
    </Col>
  );
};

export default Footer;
