import { useEffect } from "react";
import { useQuery } from "react-query";
import { reactQueryEndPoints } from "src/constants";
import { IdentityService } from "src/services/Identity/Identity.service";
import useGlobalStore from "src/store/global";

export const useUserInfo = () => {
  const { setUserInfo } = useGlobalStore();
  const fetchUserInfo = async () => {
    try {
      const { UserInfo } = new IdentityService();
      const res = await UserInfo();
      if (res && res.data) {
        setUserInfo && setUserInfo(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const { refetch: userInfoRefetch } = useQuery({
    queryKey: reactQueryEndPoints.UserInfo,
    queryFn: fetchUserInfo,
    enabled: false,
  });
  useEffect(() => {
    userInfoRefetch();
  }, [userInfoRefetch]);
  return { userInfoRefetch };
};
