import { FC, useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { ModalContentContainer, ModalHeaderContainer } from "./style";
import useLanguage from "src/store/language";
import { IAttachFile } from "src/services/Tour/models";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";
import { useAttachmentRequest } from "./AttachmentRequest";

interface IAttachmentModalProps {
  open: boolean;
  onCancel: () => void;
  attach: IAttachFile | undefined;
  onDelete: () => void;
}

const AttachmentModal: FC<IAttachmentModalProps> = ({
  open,
  onCancel,
  attach,
  onDelete,
}) => {
  const [src, setSrc] = useState<string>("/assets/images/attach.svg");
  const [id, setId] = useState<number>(0);

  const { states, requests } = useAttachmentRequest();

  const { words } = useLanguage();

  const ModalHeader = () => {
    return (
      <ModalHeaderContainer>
        <span className="title">{words.addEditTour.attachmentModalTitle}</span>
      </ModalHeaderContainer>
    );
  };

  const handleDeleteAttachFile = () => {
    requests.deleteAttachFileRequest(id).finally(() => setId(0));
  };

  useEffect(() => {
    if (attach) {
      if (attach.extention.includes(".xls")) {
        setSrc("/assets/images/excel.svg");
      } else if (attach.extention.includes(".doc")) {
        setSrc("/assets/images/doc.svg");
      } else if (attach.extention.includes(".pdf")) {
        setSrc("/assets/images/pdf.svg");
      } else if (
        attach.extention.includes(".zip") ||
        attach.extention.includes(".rar")
      ) {
        setSrc("/assets/images/zip.svg");
      } else {
        setSrc("/assets/images/attach.svg");
      }
    }
  }, [attach]);

  return (
    <>
      <DeleteConfirmModal
        showConfirm={id > 0 ? true : false}
        loading={states.loading}
        title={words.addEditTour.deleteAttachFileConfirmModalTitle}
        onOk={handleDeleteAttachFile}
        onCancel={() => setId(0)}
        okTitle={words.addEditTour.delete}
      />

      <Modal
        title={<ModalHeader />}
        open={open}
        onCancel={onCancel}
        footer={null}
      >
        <ModalContentContainer>
          <div className="flex justify-center align-center bg-[#C9CCDC] py-4">
            <img src={src} alt="attach" />
          </div>
          <div className="footer">
            <Button
              type="text"
              className="delete"
              onClick={onDelete}
              icon={<span className="material-icons">delete_forever</span>}
            >
              {words.addEditTour.delete}
            </Button>
            <Button
              type="text"
              className="download"
              onClick={() => requests.downloadAttachFile(attach?.id || 0)}
              loading={states.progress > 0 ? true : false}
            >
              <img src="/assets/images/download.svg" alt="download" />
            </Button>
          </div>
        </ModalContentContainer>
      </Modal>
    </>
  );
};

export default AttachmentModal;
