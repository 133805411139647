import { TableColumnsType } from "antd";

import _ from "lodash";
import moment from "moment";
import { IGetExportNaja } from "src/services/ExportData/models";

export const useNajaListTableColumns = () => {
  const columns: TableColumnsType<IGetExportNaja> = [
    {
      title: "NO",
      key: "row",
      render: (text, record, index) => index + 1,
      width: 44,
    },
    {
      dataIndex: "firstName",
      title: "Name",
      key: "firstName",
      width: 75,
    },
    {
      dataIndex: "lastName",
      title: "Surname",
      key: "lastName",
      width: 92,
    },
    {
      dataIndex: "birthDate",
      title: "Date of birth",
      key: "birthDate",
      render: (text) => (text ? moment(text).format("YYYY-MM-DD") : ""),
      width: 132,
      className: "!text-[11px]",
    },
    {
      dataIndex: "placeOfBirth",
      title: "Place of birth",
      key: "placeOfBirth",
      width: 117,
    },
    {
      dataIndex: "country",
      title: "Nationality",
      key: "country",
      width: 103,
    },

    {
      dataIndex: "passportNo",
      title: "Pass. No",
      key: "passportNo",
      width: 96,
    },
    {
      dataIndex: "job",
      title: "Job",
      key: "job",
      width: 90,
    },
    {
      dataIndex: "expireDate",
      title: "Date of expiry",
      key: "expireDate",
      render: (text) => (text ? moment(text).format("YYYY-MM-DD") : ""),
      width: 121,
    },
  ];

  for (const col of columns) {
    const columnsClassName = " text-[12px]";
    col.className = col.className
      ? col.className.concat(columnsClassName)
      : columnsClassName;
    col.align = "center";
  }
  return _.reverse(columns);
};
