import { FC } from "react";
import { ColumnsType } from "antd/es/table";
import { TourFieldNames } from "src/modules/addEditTour/models";
import useLanguage from "src/store/language";
import { TableUI } from "src/components/UiKit/table";
import { ITourInfoResult } from "src/services/Tour/models";
import { useTourViewData } from "src/modules/viewTour/context/TourViewData";
import { usePrintTouristRequest } from "./context";

const TouristTable: FC = () => {
  const { words } = useLanguage();

  const {
    states: { formData },
  } = useTourViewData();

  const {
    states: { dataLoadings },
  } = usePrintTouristRequest();

  const columns: ColumnsType<ITourInfoResult> = [
    {
      title: "تاریخ شمسی",
      dataIndex: TourFieldNames.PersianDate,
      key: TourFieldNames.PersianDate,
    },
    {
      title: "تاریخ میلادی",
      dataIndex: TourFieldNames.Date,
      key: TourFieldNames.Date,
    },
    {
      title: "استان",
      dataIndex: "province",
      key: "province",
    },
    {
      title: "شهر",
      dataIndex: TourFieldNames.ToCity,
      key: TourFieldNames.ToCity,
    },
    {
      title: `${words.viewTour.hotel}`,
      dataIndex: TourFieldNames.Hotel,
      key: TourFieldNames.Hotel,
    },
    {
      title: `${words.viewTour.places}`,
      dataIndex: TourFieldNames.Places,
      key: TourFieldNames.Places,
    },
  ];

  return (
    <TableUI
      scroll={{ x: 400 }}
      loading={dataLoadings.tour}
      dataSource={formData?.tour as ITourInfoResult[]}
      columns={columns}
      tableType="secondary"
      pagination={false}
    />
  );
};

export default TouristTable;
