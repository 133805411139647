import { FC } from "react";
import { Tabs } from "antd";
import { TeamInfoContainer } from "./style";
import useLanguage from "src/store/language";
import ManagerTab from "./tabs/manager";
import LeaderTab from "./tabs/leader";
import TranslatorTab from "./tabs/translator";
import DriverTab from "./tabs/driver";
import CarTab from "./tabs/car";
import { FilterContentContainer } from "../../styles";
import { useTourViewData } from "../../context/TourViewData";
import { TabMenuKeys } from "src/modules/addEditTour/models";

const TeamInfo: FC = () => {
  const { words } = useLanguage();
  const { states, dispatches } = useTourViewData()!;

  const items = [
    {
      label: `${words.viewTour.manager}`,
      key: TabMenuKeys.manager,
      children: <ManagerTab />,
    },
    {
      label: `${words.viewTour.leader}`,
      key: TabMenuKeys.leader,
      children: <LeaderTab />,
    },
    {
      label: `${words.viewTour.translator}`,
      key: TabMenuKeys.translator,
      children: <TranslatorTab />,
    },
    {
      label: `${words.viewTour.driver}`,
      key: TabMenuKeys.driver,
      children: <DriverTab />,
    },
    {
      label: `${words.viewTour.car}`,
      key: TabMenuKeys.car,
      children: <CarTab />,
    },
  ];
  const onChange = (key: string) => {
    dispatches.setCurrent(key);
  };

  return (
    <FilterContentContainer justify={"center"}>
      <TeamInfoContainer xs={24} md={20} lg={16} className="form">
        <Tabs
          onChange={onChange}
          type="card"
          centered
          activeKey={states.current}
          items={items}
        />
      </TeamInfoContainer>
    </FilterContentContainer>
  );
};

export default TeamInfo;
