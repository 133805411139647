import { Button, Col, Form, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import { FC } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import InputUiKit from "src/components/UiKit/Input";
import { SelectUiKit } from "src/components/UiKit/select";
import useLanguage from "src/store/language";
import { useManagementNotification } from "../context";

export const AddNotification: FC = () => {
  const { words } = useLanguage();
  const {
    value: { editMode, addSubmit },
    func: { onCancel, onEnd, onFinish, onReset, onSaveEdit },
  } = useManagementNotification();

  return (
    <Form>
      <Row gutter={[24, 0]}>
        <Col span={24} className="pt-[24px] pb-[16px] mb-[24px]">
          {editMode ? (
            <h4 className="text-[16px] text-[#233069]">
              {words.managementNotification.editTitle}
            </h4>
          ) : (
            <h4 className="text-[16px] text-[#233069]">
              {words.managementNotification.addTitle}
            </h4>
          )}
        </Col>
        <Col span={12}>
          <FormItem
            name={"noticeTitle"}
            label={words.managementNotification.inputTitle}
          >
            <InputUiKit placeholder={words.global.insert} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            name={"displayDate"}
            label={words.managementNotification.inputDisplayDate}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <SelectUiKit
              placeholder={words.global.choose}
              options={[
                { value: "test1", label: "test1" },
                { value: "test2", label: "test2" },
              ]}
            />
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem
            name={"description"}
            label={words.managementNotification.description}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <TextArea
              placeholder={words.managementNotification.description}
              rows={5}
              style={{
                resize: "none",
                padding: 16,
                borderRadius: 12,
                background: "#F5F5F5",
              }}
            />
          </FormItem>
        </Col>
        <Col span={24}>
          {editMode ? (
            <div className="flex justify-end items-center gap-[16px] pb-[30px] my-[24px] border-0 border-b-[2px] border-solid border-[#E1E1E1]">
              <Button
                onClick={onCancel}
                type="text"
                className="bg-[#F5F5F5] text-[#8E8E8E] w-[85px] text-[12px]"
              >
                {words.global.cancel}
              </Button>
              <Button onClick={onSaveEdit} className="w-[85px] text-[12px]">
                {words.global.saveEdit}
              </Button>
            </div>
          ) : (
            <div className="flex justify-end items-center gap-[16px] pb-[30px] my-[24px] border-0 border-b-[2px] border-solid border-[#E1E1E1]">
              <Button
                onClick={onReset}
                type="text"
                className="bg-[#F5F5F5] text-[#8E8E8E] w-[85px] text-[12px]"
              >
                {words.addTouristInfo.reset}
              </Button>
              <Button
                onClick={onFinish}
                disabled={!addSubmit}
                className="w-[85px] text-[12px]"
              >
                {words.addTouristInfo.add}
              </Button>
              <Button
                onClick={onEnd}
                type="dashed"
                disabled={!addSubmit}
                className="w-[85px] text-[12px]"
              >
                {words.global.End}
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </Form>
  );
};
