import { FC } from "react";
import HotelsAdd from "./add";
import TableHotel from "./table";
import { useHotelsData } from "../context/HotelsData";
import { HotelsTabMenuKeys } from "../models/enums";

const FilterContent: FC = () => {
  const { states } = useHotelsData()!;

  const handleFilterContent = () => {
    switch (states.currentFilter) {
      case HotelsTabMenuKeys.Add:
        return <HotelsAdd />;
      case HotelsTabMenuKeys.Table:
        return <TableHotel />;
    }
  };
  return <>{handleFilterContent()}</>;
};

export default FilterContent;
