import { FC } from "react";
import HotelsTableDataProvider from "./context";
import Table from "./components/Table";

const HotelsTable: FC = () => {
  return (
    <HotelsTableDataProvider>
      <div className="w-full mt-[48px]">
        <Table />
      </div>
    </HotelsTableDataProvider>
  );
};

export default HotelsTable;
