import { Flex } from "antd";
import { FC } from "react";
import { TourStates } from "src/models/enums/TourStates";
import useLanguage from "src/store/language";

interface ITourStateProps {
  state: number;
}
const TourState: FC<ITourStateProps> = ({ state }) => {
  const { words } = useLanguage();

  const renderTourState = () => {
    switch (state) {
      case TourStates.Waiting:
        return (
          <Flex>
            <img src="/assets/images/tour-waiting.svg" alt="tour-progress" />
            <span className="px-[5px] text-[12px] text-[#233069] font-normal">
              {words.tableTour.waitingToStart}
            </span>
          </Flex>
        );
      case TourStates.Progress:
        return (
          <Flex>
            <img src="/assets/images/tour-progress.svg" alt="tour-progress" />
            <span className="px-[5px] text-[12px] text-[#233069] font-normal">
              {words.tableTour.progress}
            </span>
          </Flex>
        );
      case TourStates.Finished:
        return (
          <Flex>
            <img src="/assets/images/tour-finished.svg" alt="tour-progress" />
            <span className="px-[5px] text-[12px] text-[#233069] font-normal">
              {words.tableTour.finished}
            </span>
          </Flex>
        );
      case TourStates.Inactive:
        return (
          <Flex>
            <img src="/assets/images/tour-cancel.svg" alt="tour-progress" />
            <span className="px-[5px] text-[12px] text-[#233069] font-normal">
              {words.tableTour.inactive}
            </span>
          </Flex>
        );
    }
  };
  return <div>{renderTourState()}</div>;
};

export default TourState;
