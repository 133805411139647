import { FC } from "react";
import { Col } from "antd";
import { ManagementSimcardAssignContainer } from "./style";
import { useManagementSimcardData } from "./context";
import AddEditSimcardDataProvider from "./components/add-edit/context";
import SimcardAddEditTabs from "./components/add-edit";
import ManagementSimcardTable from "./components/table/ManagementSimcardTable";

const ManagementSimcardAssign: FC = () => {
  const {
    states: { mode },
  } = useManagementSimcardData();

  return (
    <>
      {/* <PageHeader
        mode={mode}
        title={words.managementSimcardAssign.assignTitle}
        backTitle={words.global.back}
        tourCode={123465}
      /> */}
      <AddEditSimcardDataProvider>
        <ManagementSimcardAssignContainer justify={"center"} gutter={[0, 24]}>
          <Col xs={24} md={mode === "view" ? 16 : 23} className="content">
            {mode === "add" || mode === "edit" ? <SimcardAddEditTabs /> : null}
            <ManagementSimcardTable />
          </Col>
        </ManagementSimcardAssignContainer>
      </AddEditSimcardDataProvider>
    </>
  );
};

export default ManagementSimcardAssign;
