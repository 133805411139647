export enum TourUrls {
  Tour = "Tour/",
  Tours = "Tour/Tours",
  TouristInfo = "/TouristInfo",
  ManagerInfo = "/ManagerInfo",
  LeaderInfo = "/LeaderInfo",
  TranslatorInfo = "/TranslatorInfo",
  DriverInfo = "/DriverInfo",
  CarInfo = "/CarInfo",
  DeleteTouristInfo = "Tour/DeleteTouristInfo",
  AddTourist = "/AddTourist",
  GetExcelFile = "/ExcellFile",
  GetPdfFile = "/PDFFile",
  GetZipFile = "/ZIPFile",
  TourFileChecker = "/TourFileChecker",
  EditTouristInfo = "/EditTouristInfo",
  DeleteTour = "/DeleteTour",
  GetInsuranceInfo = "/InsuranceInfo",
  GetInsuranceFile = "/InsuranceFile",
  GetAttachFile = "/GetAttachFile",
  DeleteAttachFile = "/DeleteAttachFile",
  PlanTourBatch = "/PlanTourBatch",
  PlanTour = "/PlanTour",
  TourPlanInfo = "/TourPlanInfo",
  DeletePlanTour = "Tour/DeletePlanTour",
  EditPlanTour = "/EditPlanTour",
  DownloadAttachFile = "/DownloadtAttachFile",
  TourInfo = "/TourInfo",
  TourDate = "/TourDate",
  ChangeTourState = "/ChangeTourState",
  InsuranceInfo = "/InsuranceInfo",
  AddTour = "Tour/AddTour",
  EditTour = "/EditTour",
}
