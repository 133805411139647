import { FC } from "react";
import { Col, Row, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import { SelectUiKit } from "src/components/UiKit/select";
import InputNumberUiKit from "src/components/UiKit/inputNumber";
import { useManagementDriverData } from "../../context";
import { carPluckAlphabetArray } from "../../constants/selectOptions";

const CarInfo: FC = () => {
  const { words } = useLanguage();

  const {
    states: { mode },
  } = useManagementDriverData();

  return (
    <>
      <p
        className={`${
          mode === "add"
            ? "driver-add-form-title"
            : "driver-edit-form-title mt-[32px]"
        }`}
      >
        {mode === "add"
          ? words.managementDriver.carFormTitle
          : words.managementDriver.carEditFormTitle}
      </p>

      <Row gutter={[24, 12]}>
        <Col xs={24} md={8}>
          <FormItem
            name={"carName"}
            label={words.managementDriver.carNameLabel}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit
              placeholder={words.managementDriver.carNamePlaceholder}
            />
          </FormItem>
        </Col>

        <Col xs={24} md={8}>
          <FormItem
            label={
              <div className="flex items-center">
                <span>{words.managementDriver.pluckLabel}</span>
              </div>
            }
          >
            <div className="pluck-container">
              <div className="h-[51px] !w-[80px]">
                <FormItem
                  name={["carPluck", "fourthPluck"]}
                  initialValue="ایران 11"
                >
                  <SelectUiKit>
                    {Array.from(
                      Array(100),
                      (e, i) =>
                        i > 9 && (
                          <Select.Option
                            key={i}
                            value={i}
                            style={{ fontSize: "12px" }}
                          >
                            {"ایران " + i}
                          </Select.Option>
                        )
                    )}
                  </SelectUiKit>
                </FormItem>
              </div>
              <div className="h-[51px] !w-[96px] border-right">
                <FormItem
                  name={["carPluck", "thirdPluck"]}
                  rules={[
                    { required: true, message: words.global.fieldRequired },
                  ]}
                >
                  <InputNumberUiKit
                    controls={false}
                    minLength={3}
                    maxLength={3}
                    placeholder={words.managementDriver.thirdPluckLabel}
                  />
                </FormItem>
              </div>
              <div className="h-[51px] !w-[63px] border-right">
                <FormItem name={["carPluck", "secondPluck"]} initialValue="0">
                  <SelectUiKit className="!w-[63px]">
                    {carPluckAlphabetArray.map((alphabet, index) => (
                      <Select.Option
                        key={index}
                        value={index.toString()}
                        style={{ fontSize: "12px" }}
                      >
                        {alphabet}
                      </Select.Option>
                    ))}
                  </SelectUiKit>
                </FormItem>
              </div>
              <div className="h-[51px] !w-[81px] border-right">
                <FormItem
                  name={["carPluck", "firstPluck"]}
                  className="h-[51px] !w-[81px]"
                  rules={[
                    { required: true, message: words.global.fieldRequired },
                  ]}
                >
                  <InputNumberUiKit
                    controls={false}
                    minLength={2}
                    maxLength={2}
                    placeholder={words.managementDriver.firstPluckLabel}
                  />
                </FormItem>
              </div>
            </div>
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

export default CarInfo;
