import { FC } from "react";
import { Col } from "antd";
import AddEditRoutePlaces from "./components/form/AddEditRoutePlaces";
import RoutePlacesTable from "./components/table/RoutePlacesTable";
import { ManagementRoutePlaceContainer } from "./style";
import { useRoutePlacesData } from "./context";

const RoutePlaces: FC = () => {
  const {
    states: { mode },
  } = useRoutePlacesData();

  return (
    <>
      {/* <RoutePlacePageHeader /> */}
      <ManagementRoutePlaceContainer justify={"center"} gutter={[0, 24]}>
        <Col xs={24} md={23} className="content">
          {(mode === "add" || mode === "edit") && <AddEditRoutePlaces />}
          <RoutePlacesTable />
        </Col>
      </ManagementRoutePlaceContainer>
    </>
  );
};

export default RoutePlaces;
