import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import { TourService } from "src/services/Tour/Tour.service";
import { useTourViewData } from "../../context/TourViewData";

interface IContextValue {
  states: {
    dataLoading: boolean;
  };
}

export const TourInfoRequestContext = createContext<IContextValue | undefined>(
  undefined
);

const TourInfoRequestProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const { dispatches } = useTourViewData();
  const { tourId } = useParams();

  const getTourInfo = useCallback(async () => {
    if (!tourId) return;

    try {
      setDataLoading(true);
      const { GetTourInfo } = new TourService();

      const result = await GetTourInfo(tourId);
      if (result && result.status === 200) {
        dispatches.setFormData((prev) => ({
          ...prev,
          tour: result.data,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tourId) {
      getTourInfo();
    }
  }, [getTourInfo, tourId]);

  const contextValues = {
    states: { dataLoading },
  };

  return (
    <TourInfoRequestContext.Provider value={contextValues}>
      {children}
    </TourInfoRequestContext.Provider>
  );
};

export default TourInfoRequestProvider;

export const useTourInfoRequest = () => useContext(TourInfoRequestContext)!;
