import { FC } from "react";
import { Col, Row } from "antd";
import useLanguage from "src/store/language";
import { TableUI } from "src/components/UiKit/table";
import { ColumnsType } from "antd/es/table";
import { ICity } from "src/services/BasicInformation/models";
import TableActions from "./TableActions";
import { useCityContext } from "../context";

const CityTable: FC = () => {
  const {
    cities,
    loadings,
    totalCount,
    tablePaginationParams,
    setTablePaginationParams,
    editCity,
  } = useCityContext();

  const { words } = useLanguage();

  const tableColumns: ColumnsType<ICity> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (text, record, index) =>
        (tablePaginationParams.Offset - 1) * tablePaginationParams.Limit +
        index +
        1,
    },
    {
      title: "استان",
      dataIndex: "province",
      key: "province",
    },
    {
      title: "شهر",
      dataIndex: "name",
      key: "name",
    },
    {
      title: `${words.basicInfo.tableActions}`,
      dataIndex: "tools",
      key: "tools",
      render: (_, record) => <TableActions item={record} />,
    },
  ];

  return (
    <>
      <Row gutter={[24, 12]}>
        <Col span={24} className="mt-[20px]">
          <div className="title-table">{words.basicInfo.cityTableTitle}</div>
        </Col>
        <Col span={24}>
          <TableUI
            columns={tableColumns}
            dataSource={cities}
            loading={loadings.table}
            scroll={{ x: 500 }}
            tableType="secondary"
            rowClassName={(record, index) =>
              record.id === editCity?.id ? "selected-row-for-edit" : ""
            }
            pagination={{
              position: ["bottomCenter"],
              total: totalCount,
              pageSize: tablePaginationParams.Limit,
              current: tablePaginationParams.Offset,
              onChange(page, pageSize) {
                setTablePaginationParams((prev) => ({
                  ...prev,
                  Offset: page,
                  Limit: pageSize,
                }));
              },
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default CityTable;
