import { FC, useEffect } from "react";
import { Button, Col, Divider, Form, Row, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import InputUiKit from "src/components/UiKit/Input";
import SubmitButton from "src/components/UiKit/SubmitButton";
import useLanguage from "src/store/language";
import { SelectUiKit } from "src/components/UiKit/select";
import { SInput } from "src/components/UiKit/SInput";
import { useManagementBasicInfoData } from "../context";
import { useEditTourFormChanged } from "../useEditTourFormChanged";
import { useManagementTourBack } from "src/hook/useManagementTourBack";

const EditBasicInfo: FC = () => {
  const { words } = useLanguage();

  const [editTourForm] = Form.useForm();

  const {
    states: { countries, countryLoading, loading, tour, brokerProps },
    func: { onFinish },
  } = useManagementBasicInfoData();

  const canEdit = useEditTourFormChanged(editTourForm);

  const { backHandler } = useManagementTourBack();

  useEffect(() => {
    if (tour) {
      editTourForm.setFieldsValue({
        tourCode: tour?.tourCode,
        countryId: tour?.countryId,
        touristCount: tour?.touristCount,
        brokerId: tour?.brokerId,
        origin: tour.origin,
        destination: tour.destination,
      });
    }
  }, [editTourForm, tour]);

  return (
    <>
      <Form onFinish={onFinish} form={editTourForm} layout="vertical">
        <Row gutter={[24, 12]}>
          <Col span={24} className="mb-[40px]">
            <p className="title">{words.addTour.editTitle}</p>
          </Col>
          <Col xs={24} md={8}>
            <FormItem
              name={"tourCode"}
              label={words.addTour.tourCodeLabel}
              rules={[{ required: true, message: words.global.fieldRequired }]}
            >
              <InputUiKit
                min={6}
                max={6}
                placeholder={words.addTour.tourCodePlaceHolder}
              />
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <FormItem
              name={"countryId"}
              label={words.addTour.nationLabel}
              rules={[{ required: true, message: words.global.fieldRequired }]}
            >
              <SelectUiKit
                showSearch
                placeholder={words.addTour.nationPlaceHolder}
                loading={countryLoading}
              >
                {countries.map((country) => (
                  <Select.Option key={country.id} value={country.id}>
                    {country.faName}
                  </Select.Option>
                ))}
              </SelectUiKit>
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <FormItem name={"touristCount"} label={words.addTour.touristNumber}>
              <SInput
                numberMode={true}
                placeholder={words.addTour.touristPlaceHolder}
              />
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <FormItem
              name={"origin"}
              label={words.addTour.origin}
              rules={[{ required: true, message: words.global.fieldRequired }]}
            >
              <InputUiKit placeholder={words.addTour.originPlaceholder} />
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <FormItem
              name={"destination"}
              label={words.addTour.destination}
              rules={[{ required: true, message: words.global.fieldRequired }]}
            >
              <InputUiKit placeholder={words.addTour.destinationPlaceholder} />
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <FormItem name={"brokerId"} label={words.addTour.brokerLabel}>
              <SelectUiKit
                allowClear
                showSearch
                placeholder={words.addTour.nationPlaceHolder}
                loading={brokerProps.loading}
              >
                {brokerProps.brokers.map((broker) => (
                  <Select.Option key={broker.id} value={broker.id}>
                    {broker.name}
                  </Select.Option>
                ))}
              </SelectUiKit>
            </FormItem>
          </Col>
          <Divider className="bg-[#EEEEEE]" />
          <Col span={24} className="flex justify-end">
            <Button
              type="text"
              className="bg-[#F5F5F5] text-[#8E8E8E] ml-2 rounded-lg w-[85px]"
              onClick={backHandler}
            >
              {words.addTour.cancel}
            </Button>
            <SubmitButton
              form={editTourForm}
              loading={loading}
              canSubmit={canEdit}
            >
              {words.addTour.editButton}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EditBasicInfo;
