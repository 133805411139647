import { useAddHotelResponderData } from "./context";
import { Form } from "antd";

export const useFormHasChanged = () => {
  const {
    values: { addPersonForm, selectedContact },
  } = useAddHotelResponderData();

  const name = Form.useWatch("name", addPersonForm);

  return selectedContact ? name === selectedContact?.name : false;
};
