import { Flex } from "antd";
import { FC } from "react";
import { usePlacesData } from "src/modules/places/context/PlacesData";
import { IPlace } from "src/services/BasicInformation/models";
import useLanguage from "src/store/language";

interface ITableActionsProps {
  item: IPlace;
}

const TableActions: FC<ITableActionsProps> = ({ item }) => {
  const { words } = useLanguage();

  const {
    func: { handleDeleteId },
    dispatches: { setSelectedPlace },
  } = usePlacesData();

  const handleSelectPlace = () => {
    setSelectedPlace(item);
  };

  return (
    <>
      <Flex justify="center" className="gap-[16px]">
        <div className="edit-action" onClick={() => handleSelectPlace()}>
          <span className="material-icons text-[#0C9D61]  text-[16px]">
            edit_note
          </span>
          <span>{words.global.editAction}</span>
        </div>
        <div className="delete-action" onClick={() => handleDeleteId(item.id)}>
          <span className="material-icons text-[#ec2d30] text-[14px]">
            delete_forever
          </span>
          <span>{words.global.delete}</span>
        </div>
      </Flex>
    </>
  );
};

export default TableActions;
