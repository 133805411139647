import { Form, message } from "antd";
import { FormInstance } from "antd/lib";
import {
  FC,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import { ISupervisorArg } from "src/services/TourManagement/models";
import useLanguage from "src/store/language";

interface IContextValue {
  states: {
    mode: string;
    dataLoading: boolean;
    loading: boolean;
    supervisor: ISupervisorArg | undefined;
    supervisorForm: FormInstance;
  };
  func: { onFinish: (values: ISupervisorArg) => void };
  requests: {};
}

export const ManagementSupervisorDataContext = createContext<
  IContextValue | undefined
>(undefined);

const ManagementSupervisorDataProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [mode, setMode] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [supervisor, setSupervisor] = useState<ISupervisorArg | undefined>(
    undefined
  );

  const [supervisorForm] = Form.useForm();

  const { tourId } = useParams();
  const { state } = useLocation();
  const { words } = useLanguage();

  const navigate = useNavigate();

  const onFinish = (values: ISupervisorArg) => {
    supervisorReq(values);
  };

  const supervisorReq = async (values: ISupervisorArg) => {
    if (!tourId) return;
    setLoading(true);
    try {
      const { Supervisor } = new TourManagementService();
      const result = await Supervisor(tourId, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        navigate(-1);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getSupervisor = useCallback(async () => {
    if (!tourId) return;
    setDataLoading(true);
    try {
      const { GetSupervisor } = new TourManagementService();
      const res = await GetSupervisor(tourId);
      if (res && res.status === 200 && res.data) {
        setSupervisor(res.data);
      } else {
        setSupervisor(undefined);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoading(false);
      setSupervisor(dumpyData);
    }
  }, [tourId]);

  useEffect(() => {
    if (tourId && (mode === "edit" || mode === "view")) getSupervisor();
  }, [getSupervisor, mode, tourId]);

  useEffect(() => {
    if (state && state.mode) setMode(state.mode);
    else setMode("add");
  }, [state]);

  useEffect(() => {
    if (supervisor) {
      supervisorForm.setFieldsValue({
        name: supervisor.name,
        phone: supervisor.phone,
      });
    }
  }, [supervisorForm, supervisor]);

  const contextValues = {
    states: {
      mode,
      dataLoading,
      loading,
      supervisor,
      supervisorForm,
    },
    func: { onFinish },
    requests: {},
  };

  return (
    <ManagementSupervisorDataContext.Provider value={contextValues}>
      {children}
    </ManagementSupervisorDataContext.Provider>
  );
};

export default ManagementSupervisorDataProvider;

export const useManagementSupervisorData = () =>
  useContext(ManagementSupervisorDataContext)!;

const dumpyData: ISupervisorArg = {
  name: "علی احمدی",
  phone: "09112345678",
};
