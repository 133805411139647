import { FC } from "react";
import { Button, Col, Form, Row, Upload, message } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import FormItem from "antd/es/form/FormItem";
import { UploadRequestOption } from "rc-upload/lib/interface";
import SubmitButton from "src/components/UiKit/SubmitButton";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import EndRegisterButton from "src/components/UiKit/EndRegisterButton";
import TextAreaUiKit from "src/components/UiKit/textArea";
import { FilesAddEditFormContainer } from "./style";
import { useManagementFilesData } from "../../context";

const AddEditForm: FC = () => {
  const { words } = useLanguage();
  const {
    states: { mode, documents, selectedDocument, loading, documentList },
    func: { onFinishTourDocumentFile },
    dispatches: { setSelectedDocument, setDocumentList },
    form: { tourFileForm },
  } = useManagementFilesData();

  const handleCancel = () => {
    if (!selectedDocument) {
      tourFileForm.resetFields();
    } else setSelectedDocument(undefined);
    setDocumentList([]);
  };
  const dummyRequest: (options: UploadRequestOption) => void = ({
    file,
    onSuccess,
  }) => {
    setTimeout(() => {
      if (onSuccess) {
        onSuccess("done");
        return file;
      }
    }, 0);
  };

  const beforeUpload = (file: RcFile) => {
    console.log(file);
    const isType =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/msword" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type === "application/pdf" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel";
    if (!isType) {
      message.error(words.managementFiles.imageTypeError);
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(words.managementFiles.imageSizeError);
    }

    return isLt2M && isType;
  };
  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    if (newFileList && newFileList.length > 0)
      tourFileForm.setFieldValue("File", newFileList[0].originFileObj as any);
    else tourFileForm.setFieldValue("File", null);
    setDocumentList(newFileList);
  };

  return (
    <FilesAddEditFormContainer>
      <p className="title mt-[24px]">
        {mode === "add"
          ? words.managementFiles.addFileFormTitle
          : words.managementFiles.editFileFormTitle}
      </p>
      <span className="sub-title">
        {words.managementFiles.addFileFormSubTitle}
      </span>
      <Form
        onFinish={onFinishTourDocumentFile}
        form={tourFileForm}
        layout="vertical"
        preserve={false}
        className="mt-[40px]"
      >
        <Row gutter={[24, 12]}>
          <Col span={24}>
            <Row justify={"space-between"} align={"middle"}>
              <Col xs={24} sm={16} md={8}>
                <FormItem
                  name={"Title"}
                  label={words.managementFiles.fileLabel}
                  rules={[
                    { required: true, message: words.global.fieldRequired },
                  ]}
                >
                  <InputUiKit
                    placeholder={words.managementFiles.filePlaceHolder}
                  />
                </FormItem>
              </Col>
              <Col
                xs={24}
                sm={8}
                md={16}
                className="flex items-center justify-end"
              >
                <Form.Item
                  name="File"
                  rules={[
                    {
                      required: !selectedDocument ? true : false,
                      message: words.global.fieldRequired,
                    },
                  ]}
                >
                  <Upload
                    customRequest={dummyRequest}
                    listType="picture-card"
                    onChange={handleChange}
                    beforeUpload={beforeUpload}
                    accept="image/png, image/jpeg, image/jpg, .doc, .docx, .xls ,.xlsx, .pdf"
                  >
                    {documentList.length < 1 && (
                      <div className="add-image-container">
                        <span className="plus">+</span>
                        <span className="text">
                          {words.managementLeader.addImage}
                        </span>
                      </div>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <FormItem name={"Detail"} label={words.managementFiles.description}>
              <TextAreaUiKit
                rows={12}
                placeholder={words.managementFiles.description}
              ></TextAreaUiKit>
            </FormItem>
          </Col>
          <Col span={24} className="flex justify-end items-center">
            <div className="flex items-center">
              <Button
                type="text"
                className="bg-[#F5F5F5] text-[#8E8E8E] rounded-lg ml-2 w-[85px]"
                onClick={handleCancel}
              >
                {!selectedDocument
                  ? words.managementTime.clear
                  : words.managementTime.cancel}
              </Button>
              <SubmitButton
                form={tourFileForm}
                loading={loading}
                canSubmit={false}
              >
                {!selectedDocument
                  ? words.global.add
                  : words.managementTime.editBtn}
              </SubmitButton>
            </div>
          </Col>
        </Row>
      </Form>
    </FilesAddEditFormContainer>
  );
};

export default AddEditForm;
