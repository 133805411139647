import styled from "styled-components";

export const BrokerInfoModalBodyContainer = styled.div`
  .btn-container {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
  }
  .btn-cancel {
    color: #4b4b4b;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    font-weight: 500;
    font-size: 16px;
    width: 130px;
    height: 50px;
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-around;
  }

  .btn-cancel:hover {
    color: #4b4b4b !important;
    background-color: #f5f5f5 !important;
  }

  .description-container {
    margin-top: 20px;
    border: 1px solid var(--color-Stroke-footer-buttons, #fde8dc);
    border-radius: 12px;
    padding: 16px;
  }
  .description {
    color: var(--color-Light-Mode-Text-Primary-text, #1f1f1f);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background-color: #f5f5f5;
    border-radius: 12px;
    padding: 16px 12px;
  }
  .material-icons {
    font-size: 16px;
    color: #4b4b4b;
  }
`;
