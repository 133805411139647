import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
body * :not(.material-icons , .material-symbols-outlined){
  font-family: vazir, DM Sans !important;
}
.selectOption{
    .ant-select-selector{
        background: #f4f7fe !important; 
  border-color: #f4f7fe !important;
  border-radius: 12px !important;
  min-height: 48px;
  display: flex;
  align-items: center;
    }
   input{
    margin-top: 8px !important;
   }
}

.ant-input-disabled{
  border-color: #F3F4F5 !important;
}
.ant-picker-disabled {
    background: #dadeec !important;
    border-color: #dadeec !important;
  }
.ant-divider-with-text::before{
  border-block-start-color:#2C73FC !important ;
}
.unset-modal-padding{
  .ant-modal-content{
    padding: unset !important;
  }
}

.ant-modal-wrap .ant-modal.confirm-modal .ant-modal-content .ant-modal-footer {
  width: 100%;
  display: inline-flex;
  align-items: center;
}

  /* width */
  ::-webkit-scrollbar {
  width: 5px;
  height:5px;
}

.ant-modal.print-modal .ant-modal-content {
  padding: 20px 0 !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #FEF6F1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:#FAD1B8 ;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:#F06614;
}
.ant-layout, .ant-layout-content {
  height: 100vh !important;
  overflow-y: auto;
  overflow-x: hidden;
}
 
.ant-form-item .ant-form-item-label >label {
  font-weight: 500;
  font-size: 14px;
    color: #233069;
    direction: ltr;
  }
  .tourTableFilterPopUp .ant-popover, .ant-popover-content {
     width: 419px; 
  }
  .tourTableFilterPopUp .ant-popover-content .ant-popover-inner .ant-popover-inner-content .ant-popconfirm-inner-content .ant-popconfirm-buttons {
    width: 100%;
    padding-top: 16px;
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--color-Stroke-footer-buttons, #FDE8DC);
    margin-top: 32px;
  }
  .tourTableFilterPopUp .ant-popover-content .ant-popover-inner .ant-popover-inner-content .ant-popconfirm-inner-content .ant-popconfirm-buttons .ant-btn-primary {
    background-color: #F6A372;
    color: var(--color-Base-Color-White, #FFF);
    text-align: center;
    font-family: "Vazir FD";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 70px;
    height: 35px;
  }
  .tourTableFilterPopUp .ant-popover-content .ant-popover-inner .ant-popover-inner-content .ant-popconfirm-inner-content .ant-popconfirm-buttons .ant-btn-default {
    background-color: transparent;
    color: var(--color-Gray-Gray-600, #4B4B4B);
    text-align: center;
    font-family: "Vazir FD";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 0;
    outline: none;
    box-shadow: none;
  }
  .ant-picker-dropdown {
    z-index: 10000;
  }
  .react-datepicker__header {
    background-color: #ffffff !important;
  }
  .AD-react-date-picker {
    width: 100%;
    height: 51px;
  }
  .AD-react-date-picker .react-datepicker__input-container {
    height: 51px;
    border: 0 !important;
    outline: none !important;
    box-shadow: none !important;
  }
  .AD-react-date-picker .react-datepicker__input-container:focus,
  AD-react-date-picker .react-datepicker__input-container:active,
  AD-react-date-picker .react-datepicker__input-container:enabled {
    border: 0 !important;
    height: 51px;
  }
  .AD-react-date-picker .react-datepicker__input-container input,
  .AD-react-date-picker .react-datepicker__input-container input:focus,
  .AD-react-date-picker .react-datepicker__input-container input:active,
  .AD-react-date-picker .react-datepicker__input-container input:active {
    border: 0 !important;
    outline: none !important;
    box-shadow: none !important;
  }
  .AD-react-date-picker .react-datepicker__input-container input
   {
    text-align: right;
    color: #8e8e8e;
    font-weight: 500;
    font-size: 12px;
  }
  .react-datepicker-wrapper.AD-react-date-picker .react-datepicker__input-container  .react-datepicker__calendar-icon {
    position: absolute;
     left: 0;
     top: 9px;
  } 
  .react-datepicker-wrapper.AD-react-date-picker .react-datepicker__input-container .react-datepicker__close-icon {
    position: absolute;
     left: 7px !important;
     right: unset !important;
     display: none;
  }
  .react-datepicker-wrapper.AD-react-date-picker .react-datepicker__input-container:hover .react-datepicker__close-icon {
     display: block;
  }
  .react-datepicker-wrapper.AD-react-date-picker .react-datepicker__input-container .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #CACACA;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding: 2px;
    font-size: 10px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "×";
}
.rtlRangePicker{
  .ant-picker-panels{
    direction: rtl !important;
  }
}
.react-datepicker-popper{
  z-index: 100 !important;
}
`;
