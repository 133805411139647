import { Form } from "antd";
import { useAddHotelResponderDetailsData } from "./context";

export const useFormHasChanged = () => {
  const {
    values: { addContactForm, selectedDetail },
  } = useAddHotelResponderDetailsData();

  const phoneNumber = Form.useWatch("phoneNumber", addContactForm);

  return selectedDetail ? phoneNumber === selectedDetail?.phoneNumber : false;
};
