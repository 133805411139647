import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import useHotelLetterColumns from "./columns";
import { TableColumnsType } from "antd";
import { IManagementRoute } from "src/services/TourManagement/models";
import { Dayjs } from "dayjs";
import { PreviewHotelLetter } from "./PreviewLetter";

interface IContext {
  values: {
    columns: TableColumnsType<IManagementRoute>;
    selectedRoute: IManagementRoute | undefined;
    showPreview: boolean;
    hotelLetterInfo: IHotelLetterInfo | undefined;
  };
  dispatches: {
    setSelectedRoute: Dispatch<SetStateAction<IManagementRoute | undefined>>;
    setShowPreview: Dispatch<SetStateAction<boolean>>;
    setHotelLetterInfo: Dispatch<SetStateAction<IHotelLetterInfo | undefined>>;
  };
  func: {
    onFinish: (value: any) => void;
  };
}
const defaultContext: IContext = {
  values: {
    columns: [],
    selectedRoute: undefined,
    showPreview: false,
    hotelLetterInfo: undefined,
  },
  dispatches: {
    setSelectedRoute: () => {},
    setShowPreview: () => {},
    setHotelLetterInfo: () => {},
  },
  func: {
    onFinish: () => {},
  },
};

export interface IHotelLetterInfo {
  signatory?: string;
  date?: Dayjs;
  reservation?: string;
  coordinationPerson?: string;
  phoneNumber?: string;
  description?: string;
}
export const HotelLetterCTX = createContext<IContext>(defaultContext);
export const HotelLetterProvider: FC<PropsWithChildren> = ({ children }) => {
  const { columns, selectedRoute, setSelectedRoute } = useHotelLetterColumns();
  const [hotelLetterInfo, setHotelLetterInfo] = useState<IHotelLetterInfo>();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const onFinish = (values: IHotelLetterInfo) => {
    setHotelLetterInfo(values);
    setShowPreview(true);
  };

  const contextValue: IContext = {
    values: {
      columns,
      selectedRoute,
      showPreview,
      hotelLetterInfo,
    },
    dispatches: {
      setSelectedRoute,
      setShowPreview,
      setHotelLetterInfo,
    },
    func: {
      onFinish,
    },
  };

  return (
    <HotelLetterCTX.Provider value={contextValue}>
      {children}
      {showPreview && <PreviewHotelLetter />}
    </HotelLetterCTX.Provider>
  );
};

export const useHotelLetter = () => useContext(HotelLetterCTX);
