import { Form, FormInstance, message } from "antd";
import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { BasicInformationService } from "src/services/BasicInformation/BasicInformation.service";
import { IRoom } from "src/services/BasicInformation/models";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import {
  IManagementRoute,
  INewRouteRoomArg,
  ITableRouteRoom,
} from "src/services/TourManagement/models";
import useLanguage from "src/store/language";
import { IPagination } from "src/models/interfaces/pagination";
import { useLocation } from "react-router-dom";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";

interface IContextValue {
  states: {
    routeRoomForm: FormInstance;
    confirmLoading: boolean;
    deleteId: number;
    selectedRouteRoom: ITableRouteRoom | undefined;
    room: IRoomProps;
    loading: boolean;
    routeRooms: ITableRouteRoom[];
    dataLoading: boolean;
    totalCount: number;
    tablePaginationParams: Pick<IPagination, "Offset" | "Limit">;
    mode: string;
  };
  func: {
    onFinish: (values: INewRouteRoomArg) => void;
    handleDeleteId: (id: number) => void;
  };
  dispatches: {
    setSelectedRouteRoom: Dispatch<SetStateAction<ITableRouteRoom | undefined>>;
    setTablePaginationParams: Dispatch<
      SetStateAction<Pick<IPagination, "Offset" | "Limit">>
    >;
  };
  requests: {
    onDelete: () => void;
  };
}

interface IRoomProps {
  data: IRoom[];
  loading: boolean;
}

export const RouteHotelsDataContext = createContext<IContextValue | undefined>(
  undefined
);

const RouteHotelsDataProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRouteRoom, setSelectedRouteRoom] = useState<
    ITableRouteRoom | undefined
  >(undefined);
  const [mode, setMode] = useState<string>("add");
  const [room, setRoom] = useState<IRoomProps>({
    data: [],
    loading: false,
  });

  const [routeRooms, setRouteRooms] = useState<ITableRouteRoom[]>([]);
  const [dataLoading, setDataLoadings] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [route, setRoute] = useState<IManagementRoute | undefined>(undefined);

  const [tablePaginationParams, setTablePaginationParams] =
    useState<IPagination>({
      Offset: 1,
      Limit: 10,
    });

  const [routeRoomForm] = Form.useForm();

  const { words } = useLanguage();
  const { state } = useLocation();

  const onFinish = (values: INewRouteRoomArg) => {
    if (selectedRouteRoom) routeRoomEditREq(values);
    else routeRoomAddReq(values);
  };

  const routeRoomAddReq = async (values: INewRouteRoomArg) => {
    if (!route) return;
    setLoading(true);
    try {
      const { AddManagementRouteRoom } = new TourManagementService();
      const result = await AddManagementRouteRoom(route.id, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        routeRoomForm.setFieldsValue({
          roomId: null,
          count: null,
        });
        getRouteRooms();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const routeRoomEditREq = async (values: INewRouteRoomArg) => {
    if (!selectedRouteRoom) return;
    setLoading(true);
    try {
      const { EditManagementRouteRoom } = new TourManagementService();
      const result = await EditManagementRouteRoom(
        selectedRouteRoom.routeRoomId,
        values
      );
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setSelectedRouteRoom(undefined);
        getRouteRooms();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteId = (id: number) => {
    setDeleteId(id);
  };

  const onDelete = async () => {
    if (deleteId === 0) return;
    setConfirmLoading(true);
    try {
      const { DeleteRouteRoom } = new TourManagementService();
      const result = await DeleteRouteRoom(deleteId);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        if (deleteId === selectedRouteRoom?.id) setSelectedRouteRoom(undefined);
        setDeleteId(0);
        getRouteRooms();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmLoading(false);
    }
  };

  const getRooms = useCallback(async () => {
    setRoom((prev) => ({ ...prev, loading: true }));
    const params = new URLSearchParams({ Offset: "1", Limit: "999999999" });
    try {
      const { Rooms } = new BasicInformationService();
      const res = await Rooms(params.toString());
      if (res && res.status === 200 && res.data) {
        setRoom((prev) => ({ ...prev, data: res.data.records }));
      } else {
        setRoom((prev) => ({ ...prev, data: [] }));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setRoom((prev) => ({ ...prev, loading: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRouteRooms = useCallback(async () => {
    if (!route) return;
    setDataLoadings(true);
    const params = new URLSearchParams(tablePaginationParams);

    try {
      const { AllManagementRouteRoom } = new TourManagementService();
      const res = await AllManagementRouteRoom(route.id, params.toString());
      if (res && res.status === 200 && res.data) {
        let arr: ITableRouteRoom[] = [];
        res.data.records.forEach((record) =>
          arr.push({
            routeRoomId: record.routeRoomId,
            hotelName: route?.hotelName,
            count: record.count,
            ...record.room,
          })
        );
        setRouteRooms(arr);
        setTotalCount(res.data.count);
      } else {
        setRouteRooms([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoadings(false);
    }
  }, [route, tablePaginationParams]);

  useEffect(() => {
    getRouteRooms();
  }, [getRouteRooms, route]);

  useEffect(() => {
    getRooms();
  }, [getRooms]);

  useEffect(() => {
    if (route) {
      routeRoomForm.setFieldValue("hotelId", route.hotelName);
    }
  }, [routeRoomForm, route]);

  useEffect(() => {
    if (state && state.mode) setMode(state.mode);
    else setMode("add");
  }, [state]);

  useEffect(() => {
    if (state && state.route) setRoute(state.route);
    else setRoute(undefined);
  }, [state]);

  useEffect(() => {
    if (selectedRouteRoom) {
      routeRoomForm.setFieldsValue({
        count: selectedRouteRoom.count,
        roomId: selectedRouteRoom.id,
      });
    } else {
      routeRoomForm.setFieldsValue({
        count: null,
        roomId: null,
      });
    }
  }, [routeRoomForm, selectedRouteRoom]);

  const contextValues = {
    states: {
      routeRoomForm,
      confirmLoading,
      deleteId,
      selectedRouteRoom,
      room,
      loading,
      routeRooms,
      dataLoading,
      totalCount,
      tablePaginationParams,
      mode,
    },
    func: { onFinish, handleDeleteId },
    dispatches: { setSelectedRouteRoom, setTablePaginationParams },
    requests: { onDelete },
  };

  return (
    <RouteHotelsDataContext.Provider value={contextValues}>
      {children}
      <DeleteConfirmModal
        showConfirm={deleteId > 0}
        onCancel={() => handleDeleteId(0)}
        onOk={() => onDelete()}
        title={words.managementRoute.deleteRoomTitle}
        okTitle={words.global.delete}
        loading={confirmLoading}
      />
    </RouteHotelsDataContext.Provider>
  );
};

export default RouteHotelsDataProvider;

export const useRouteHotelsData = () => useContext(RouteHotelsDataContext)!;
