import { Form } from "antd";
import _ from "lodash";
import { useManagementCompanionLeaderData } from "./context";

export const useFormHasChanged = () => {
  const {
    states: { companionLeader, companionLeaderForm },
  } = useManagementCompanionLeaderData();

  const allValues = Form.useWatch([], companionLeaderForm);

  const changed = _.isEqual(
    _.omit(allValues, ["id"]),
    _.omit(companionLeader, ["id"])
  );

  return companionLeader ? changed : false;
};
