import { Flex, Spin } from "antd";
import { FC } from "react";
interface IProps {
  handlePrint: () => void;
  loading: boolean;
}
export const DownloadPdfIcon: FC<IProps> = ({ handlePrint, loading }) => {
  if (loading) {
    return (
      <Flex justify="center" align="center" className="w-[60px] h-[60px] ">
        <Spin />
      </Flex>
    );
  }
  return (
    <img src="/assets/images/pdfDownload.svg" alt="" onClick={handlePrint} />
  );
};
