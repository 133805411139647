import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { Form, message } from "antd";
import type { FormInstance } from "antd";
import {
  INewResponderDetailArg,
  IResponderDetail,
  IResponderDetailById,
} from "src/services/BasicInformation/models";
import { BasicInformationService } from "src/services/BasicInformation/BasicInformation.service";
import useLanguage from "src/store/language";
import { IPagination } from "src/models/interfaces/pagination";
import { createSearchParams, useLocation } from "react-router-dom";
import { useHotelsData } from "src/modules/hotels/context/HotelsData";
import { useAddHotelsData } from "../../../context";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";

interface IContextValue {
  values: {
    responderDetails: IResponderDetail[];
    dataLoading: boolean;
    addContactForm: FormInstance;
    loading: boolean;
    deleteId: number;
    confirmLoading: boolean;
    selectedDetail: IResponderDetail | undefined;
    totalCount: number;
    tablePaginationParams: Pick<IPagination, "Offset" | "Limit">;
  };
  func: {
    onFinishDetail: (values: INewResponderDetailArg) => void;
    handleDeleteId: (id: number) => void;
    handleResponderDetails: (id: number) => void;
  };
  requests: {
    onDeleteDetail: () => void;
  };
  dispatches: {
    setSelectedDetail: Dispatch<SetStateAction<IResponderDetail | undefined>>;
    setTablePaginationParams: Dispatch<
      SetStateAction<Pick<IPagination, "Offset" | "Limit">>
    >;
  };
}

export const AddHotelResponderDetailsDataContext = createContext<
  IContextValue | undefined
>(undefined);

const AddHotelResponderDetailsDataProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [responderDetails, setResponderDetails] = useState<IResponderDetail[]>(
    []
  );
  const [dataLoading, setDataLoadings] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [tablePaginationParams, setTablePaginationParams] =
    useState<IPagination>({
      Offset: 1,
      Limit: 10,
    });
  const [deleteId, setDeleteId] = useState<number>(0);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [selectedDetail, setSelectedDetail] = useState<
    IResponderDetail | undefined
  >(undefined);
  const [selectedResponderId, setSelectedResponderId] = useState<number>(0);
  const [addContactForm] = Form.useForm();
  const location = useLocation();
  const { words } = useLanguage();

  const {
    states: { selectedHotel },
  } = useHotelsData();

  const {
    values: { created, currentTabKey },
  } = useAddHotelsData();

  const onFinishDetail = (values: INewResponderDetailArg) => {
    if (selectedDetail) responderDetailsEditReq(values);
    else responderDetailsAddReq(values);
  };

  const responderDetailsAddReq = async (values: INewResponderDetailArg) => {
    if (!created && !selectedHotel) return;
    setLoading(true);
    try {
      const { NewResponderDetail } = new BasicInformationService();
      const result = await NewResponderDetail(values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        addContactForm.setFieldsValue({
          phoneNumber: null,
        });
        getResponderDetails(values.responderId);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const responderDetailsEditReq = async (values: INewResponderDetailArg) => {
    if (!selectedDetail) return;
    setLoading(true);
    try {
      const { EditResponderDetail } = new BasicInformationService();
      const result = await EditResponderDetail(selectedDetail?.id, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setSelectedDetail(undefined);
        getResponderDetails(selectedResponderId);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteId = (id: number) => {
    setDeleteId(id);
  };

  const handleResponderDetails = (id: number) => {
    if (id > 0) {
      setSelectedResponderId(id);
      getResponderDetails(id);
    } else {
      addContactForm.resetFields();
      setSelectedResponderId(0);
      setSelectedDetail(undefined);
      setResponderDetails([]);
    }
  };

  const onDeleteDetail = async () => {
    if ((!selectedHotel && !created) || deleteId === 0) return;
    setConfirmLoading(true);
    try {
      const { DeleteResponderDetail } = new BasicInformationService();
      const result = await DeleteResponderDetail(deleteId);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        if (selectedDetail && selectedDetail.id === deleteId)
          setSelectedDetail(undefined);
        setDeleteId(0);
        getResponderDetails(selectedResponderId);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmLoading(false);
    }
  };

  const getResponderDetails = async (hotelResponderId: number) => {
    setDataLoadings(true);
    try {
      const { ResponderDetailsById } = new BasicInformationService();
      const query = `${location.search}${
        location.search ? "&" : "?"
      }${createSearchParams(tablePaginationParams).toString()}`;
      const res = await ResponderDetailsById(hotelResponderId, query);
      if (res && res.status === 200 && res.data) {
        const detail: IResponderDetailById[] = res.data.records;
        setResponderDetails(detail[0].hotelResponderDetails);
        setTotalCount(res.data.count);
      } else {
        setResponderDetails([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoadings(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    if (currentTabKey === "contact") {
      setSelectedDetail(undefined);
      addContactForm.resetFields();
    }
  }, [addContactForm, currentTabKey, setSelectedDetail]);

  const contextValues = {
    values: {
      responderDetails,
      dataLoading,
      addContactForm,
      loading,
      confirmLoading,
      selectedDetail,
      deleteId,
      totalCount,
      tablePaginationParams,
    },
    func: { onFinishDetail, handleDeleteId, handleResponderDetails },
    requests: { onDeleteDetail },
    dispatches: {
      setSelectedDetail,
      setTablePaginationParams,
    },
  };

  return (
    <AddHotelResponderDetailsDataContext.Provider value={contextValues}>
      {children}
      <DeleteConfirmModal
        showConfirm={deleteId > 0}
        onCancel={() => handleDeleteId(0)}
        onOk={() => onDeleteDetail()}
        title={words.basicInfo.deleteConfirmTitle}
        okTitle={words.global.delete}
        loading={confirmLoading}
      />
    </AddHotelResponderDetailsDataContext.Provider>
  );
};

export default AddHotelResponderDetailsDataProvider;

export const useAddHotelResponderDetailsData = () =>
  useContext(AddHotelResponderDetailsDataContext)!;
