import { FC, useEffect } from "react";
import { Button, Col, Divider, Form, Row, Select } from "antd";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import { SelectUiKit } from "src/components/UiKit/select";
import { useHotelsData } from "src/modules/hotels/context/HotelsData";
import { HotelsViewMode } from "src/modules/hotels/models/enums";
import { useFormHasChanged } from "./useFormHasChanged";
import SubmitButton from "src/components/UiKit/SubmitButton";
import { useAddHotelResponderDetailsData } from "./context";
import ContactTable from "./ContactTable";
import { useAddHotelContactData } from "../context";

const AddContactForm: FC = () => {
  const { words } = useLanguage();

  const {
    states: { viewMode },
  } = useHotelsData();

  const {
    values: { responderNames },
  } = useAddHotelContactData();

  const {
    values: { addContactForm, loading, selectedDetail },
    func: { onFinishDetail, handleResponderDetails },
    dispatches: { setSelectedDetail },
  } = useAddHotelResponderDetailsData();

  const canSubmit = useFormHasChanged();

  const handleChange = (value: number | undefined) => {
    handleResponderDetails(value ? Number(value) : 0);
  };

  const handleCancel = () => {
    if (selectedDetail) setSelectedDetail(undefined);
    else {
      handleResponderDetails(0);
      addContactForm.resetFields();
    }
  };

  useEffect(() => {
    if (selectedDetail) {
      addContactForm.setFieldsValue({
        phoneNumber: selectedDetail.phoneNumber,
        responderId: selectedDetail.responderName,
      });
    } else
      addContactForm.setFieldsValue({
        phoneNumber: null,
      });
  }, [addContactForm, selectedDetail]);

  return (
    <Form
      form={addContactForm}
      onFinish={onFinishDetail}
      layout="vertical"
      autoComplete="off"
      name="contact-form"
    >
      <div className="h-[400px]">
        <p className="contact-form-title">
          لطفا اطلاعات تماس پاسخگو را وارد کنید.
        </p>
        <Row gutter={[16, 16]} className="mt-8">
          <Col xs={24} md={16}>
            <Form.Item
              name={"responderId"}
              label="پاسخگو"
              rules={[{ required: true, message: words.global.fieldRequired }]}
            >
              <SelectUiKit
                allowClear
                style={{ width: "100%" }}
                placeholder={`${
                  viewMode === HotelsViewMode.Edit
                    ? "انتخاب کنید"
                    : "ابتدا پاسخگو را ثبت کنید."
                }`}
                optionFilterProp="children"
                showSearch
                className="selectOption"
                onChange={handleChange}
              >
                {responderNames.map((contact) => (
                  <Select.Option value={contact.id} key={contact.id}>
                    {contact.name}
                  </Select.Option>
                ))}
              </SelectUiKit>
            </Form.Item>
          </Col>
          <Col xs={24} md={16}>
            <Form.Item
              name={"phoneNumber"}
              label="شماره تماس"
              rules={[{ required: true, message: words.global.fieldRequired }]}
              className="mobile-number-hotel"
            >
              <InputUiKit placeholder="۰۹۱۲۳۴۵۶۷۸۶" />
            </Form.Item>
          </Col>

          <Col span={24} className="w-full flex  justify-end">
            <Button
              type="text"
              className="bg-[#F5F5F5] text-[#8E8E8E] ml-2 w-[85px]"
              onClick={handleCancel}
            >
              لغو
            </Button>
            <SubmitButton
              loading={loading}
              form={addContactForm}
              //submittable={canSubmit}
              //setSubmittable={setSubmittable}
              // width="w-[95px]"
              canSubmit={canSubmit}
            >
              {selectedDetail ? "ذخیره ویرایش" : "افزودن"}
            </SubmitButton>
          </Col>
        </Row>
      </div>
      <Divider className="bg-[#DADEEC]" />
      <p className="contact-form-title">جدول اطلاعات تماس.</p>
      <ContactTable />
    </Form>
  );
};

export default AddContactForm;
