import styled from "styled-components";

export const TreeSelectStyle = styled.div`
  width: 100%;
  background-color: transparent !important;
  .ant-checkbox-inner {
    display: none;
  }
  .ant-select,
  .ant-select-selector,
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector:active,
  .ant-select-selection-search-input {
    border: 0 !important;
    box-shadow: none !important;
    outline: none !important;
    background-color: transparent !important;
  }
  .ant-select-arrow {
    top: 20px;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;
