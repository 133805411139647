import { Layout } from "antd";
import styled from "styled-components";
const { Content } = Layout;

export const LoginLayoutStyled = styled(Content)`
  background: url(/assets/images/loginBg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;

  .language-box {
    margin: 0 0 0 30px;
    padding: 20px 0;
  }
`;

export const LoginLayoutOutletStyled = styled.div`
  width: 100vw;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;
