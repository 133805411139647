import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { IContact } from "src/services/BasicInformation/models";

interface IContextValue {
  values: {
    responderNames: IContact[];
  };
  dispatches: {
    setResponderNames: Dispatch<SetStateAction<IContact[]>>;
  };
}

export const AddHotelContactDataContext = createContext<
  IContextValue | undefined
>(undefined);

const AddHotelContactDataProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [responderNames, setResponderNames] = useState<IContact[]>([]);

  const contextValues = {
    values: {
      responderNames,
    },
    dispatches: {
      setResponderNames,
    },
  };

  return (
    <AddHotelContactDataContext.Provider value={contextValues}>
      {children}
    </AddHotelContactDataContext.Provider>
  );
};

export default AddHotelContactDataProvider;

export const useAddHotelContactData = () =>
  useContext(AddHotelContactDataContext)!;
