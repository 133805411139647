import { Col, Flex, Row, Spin } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { useManagementLeaderData } from "../context";
import { convertDate } from "src/helper";
import { Gender } from "src/models/enums/gender";
import { ViewLeaderContainer } from "../style";

const ViewLeader: FC = () => {
  const { words } = useLanguage();
  const {
    states: { leaderProps, leaderloadings },
  } = useManagementLeaderData();

  return (
    <ViewLeaderContainer>
      <p className="title">{words.managementLeader.viewTitle}</p>
      <Col span={24} className="view-details">
        {leaderloadings.leader ? (
          <div className="flex w-full h-full justify-center items-center">
            <Spin size="large" />
          </div>
        ) : (
          <Row className="w-full">
            <Col xs={24} md={8} className="">
              <Flex>
                <div className="detail-title">
                  {words.managementLeader.nameLabel}:
                </div>
                <div className="detail-value">
                  {leaderProps.leader && leaderProps.leader?.firstName
                    ? leaderProps.leader?.firstName
                    : "-"}
                </div>
              </Flex>
              <Flex className="second">
                <div className="detail-title">
                  {words.managementLeader.fatherNameLabel}:
                </div>
                <div className="detail-value">
                  {leaderProps.leader && leaderProps.leader?.fatherName
                    ? leaderProps.leader?.fatherName
                    : "-"}
                </div>
              </Flex>
              <Flex>
                <div className="detail-title">
                  {words.managementLeader.dateLabel}:
                </div>
                <div className="detail-value">
                  {leaderProps.leader && leaderProps.leader?.birthDate
                    ? convertDate(leaderProps.leader?.birthDate)
                    : "-"}
                </div>
              </Flex>
              <Flex className="second">
                <div className="detail-title">
                  {words.managementLeader.leaderCardLabel}:
                </div>
                <div className="detail-value">
                  {leaderProps.leader && leaderProps.leader?.leaderCardId
                    ? leaderProps.leader?.leaderCardId
                    : "-"}
                </div>
              </Flex>
            </Col>

            <Col xs={24} md={8} className="">
              <Flex>
                <div className="detail-title">
                  {words.managementLeader.familyNameLabel}:
                </div>
                <div className="detail-value">
                  {leaderProps.leader && leaderProps.leader?.lastName
                    ? leaderProps.leader?.lastName
                    : "-"}
                </div>
              </Flex>
              <Flex className="second">
                <div className="detail-title">
                  {words.managementLeader.nationalCodeLabel}:
                </div>
                <div className="detail-value">
                  {leaderProps.leader && leaderProps.leader?.nationalCode
                    ? leaderProps.leader?.nationalCode
                    : "-"}
                </div>
              </Flex>
              <Flex>
                <div className="detail-title">
                  {words.managementLeader.languageLabel}:
                </div>
                <div className="detail-value">
                  {leaderProps.leader && leaderProps.leader?.language
                    ? leaderProps.leader?.language
                    : "-"}
                </div>
              </Flex>
              <Flex className="second">
                <div className="detail-title">
                  {words.managementLeader.expireDateLabel}:
                </div>
                <div className="detail-value">
                  {leaderProps.leader && leaderProps.leader?.expireDate
                    ? convertDate(leaderProps.leader?.expireDate)
                    : "-"}
                </div>
              </Flex>
            </Col>

            <Col xs={24} md={8} className="">
              <Flex>
                <div className="detail-title">
                  {words.managementLeader.genderLabel}:
                </div>
                <div className="detail-value">
                  {leaderProps.leader && leaderProps.leader?.gender
                    ? leaderProps.leader?.gender === Gender.Male
                      ? words.global.male
                      : words.global.femail
                    : "-"}
                </div>
              </Flex>
              <Flex className="second">
                <div className="detail-title">
                  {words.managementLeader.identityLabel}:
                </div>
                <div className="detail-value">
                  {leaderProps.leader && leaderProps.leader?.identityNumber
                    ? leaderProps.leader?.identityNumber
                    : "-"}
                </div>
              </Flex>
              <Flex>
                <div className="detail-title">
                  {words.managementLeader.phoneLabel}:
                </div>
                <div className="detail-value">
                  {leaderProps.leader && leaderProps.leader?.phoneNumber
                    ? leaderProps.leader?.phoneNumber
                    : "-"}
                </div>
              </Flex>
            </Col>
          </Row>
        )}
      </Col>

      <p className="title mt-[32px] mb-[24px]">
        {words.managementLeader.viewImageTitle}
      </p>
      <Col span={24}>
        <Row gutter={[0, 16]} justify={"space-between"}>
          <Flex justify="space-between" align="center" className="w-full">
            <div className="image-box">
              <p className="images-title">
                {words.managementLeader.viewNationalImageTitle}
              </p>
              {leaderloadings.nationalCard ? (
                <div className="flex w-full h-[216px] justify-center items-center">
                  <Spin size="large" />
                </div>
              ) : leaderProps.nationalCard ? (
                <img
                  src={leaderProps.nationalCard}
                  alt="national-card"
                  width="100%"
                  height="216px"
                />
              ) : (
                <div className="w-full h-full flex justify-center items-center">
                  {words.managementLeader.imageNotFound}
                </div>
              )}
            </div>

            <div className="image-box">
              <p className="images-title">
                {words.managementLeader.viewCardImageTitle}
              </p>
              {leaderloadings.nationalCard ? (
                <div className="flex w-full h-[216px] justify-center items-center">
                  <Spin size="large" />
                </div>
              ) : leaderProps.leaderCard ? (
                <img
                  src={leaderProps.leaderCard}
                  alt="national-card"
                  width="100%"
                  height="216px"
                />
              ) : (
                <div className="w-full h-full flex justify-center items-center">
                  {words.managementLeader.imageNotFound}
                </div>
              )}
            </div>
          </Flex>
        </Row>
      </Col>
    </ViewLeaderContainer>
  );
};

export default ViewLeader;
