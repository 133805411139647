import { FC } from "react";
import DriverForm from "./DriverForm";
import DriverInfoRequestProvider from "./DriverInfoRequest";

const DriverTab: FC = () => {
  return (
    <DriverInfoRequestProvider>
      <DriverForm />
    </DriverInfoRequestProvider>
  );
};

export default DriverTab;
