import { Form, message } from "antd";
import { FormInstance } from "antd/lib";
import dayjs from "dayjs";
import {
  FC,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { convertDateTimeIran } from "src/helper";
import { IObject } from "src/models/interfaces";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import { IDriver, IDriverArg } from "src/services/TourManagement/models";
import useLanguage from "src/store/language";

interface IContextValue {
  states: {
    mode: string;
    dataLoading: boolean;
    loading: boolean;
    driver: IDriver | undefined;
    driverForm: FormInstance;
    deleteLoading: boolean;
  };
  func: { onFinish: (values: IDriverArg) => void };
  requests: { onDeleteDriver: () => Promise<void> };
}

export const ManagementDriverDataContext = createContext<
  IContextValue | undefined
>(undefined);

const ManagementDriverDataProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [mode, setMode] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [driver, setDriver] = useState<IDriver | undefined>(undefined);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const [driverForm] = Form.useForm();

  const { tourId } = useParams();
  const { state } = useLocation();
  const { words } = useLanguage();

  const navigate = useNavigate();

  const onFinish = (values: IObject) => {
    if (values.birthDate)
      values.birthDate = convertDateTimeIran(values.birthDate);
    if (values.carPluck) {
      if (
        values.carPluck.firstPluck &&
        values.carPluck.secondPluck &&
        values.carPluck.thirdPluck &&
        values.carPluck.fourthPluck
      ) {
        values.carTag = values.carPluck.firstPluck
          .toString()
          .concat(
            "-" +
              values.carPluck.secondPluck.concat(
                "-" +
                  values.carPluck.thirdPluck
                    .toString()
                    .concat("-" + values.carPluck.fourthPluck)
              )
          );
        delete values.carPluck;
      } else delete values.carPluck;
    }
    driver
      ? driverEditReq(values as IDriverArg)
      : driverAddReq(values as IDriverArg);
  };

  const driverAddReq = async (values: IDriverArg) => {
    if (!tourId) return;
    setLoading(true);
    try {
      const { NewDriver } = new TourManagementService();
      const result = await NewDriver(tourId, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage).then(() => navigate(-1));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const driverEditReq = async (values: IDriverArg) => {
    if (!driver) return;
    setLoading(true);
    try {
      const { UpdateDriver } = new TourManagementService();
      const result = await UpdateDriver(driver.id, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage).then(() => navigate(-1));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onDeleteDriver = async () => {
    if (!driver) return;
    setDeleteLoading(true);
    try {
      const { DeleteDriver } = new TourManagementService();
      const result = await DeleteDriver(driver.id);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setDriver(undefined);
        setMode("add");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
    }
  };

  const getDriver = useCallback(async () => {
    if (!tourId) return;
    setDataLoading(true);
    try {
      const { GetDriver } = new TourManagementService();
      const res = await GetDriver(tourId);
      if (res && res.status === 200 && res.data) {
        setDriver(res.data);
      } else {
        setDriver(undefined);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoading(false);
    }
  }, [tourId]);

  useEffect(() => {
    if (tourId && (mode === "edit" || mode === "view")) getDriver();
  }, [getDriver, mode, tourId]);

  useEffect(() => {
    if (state && state.mode) setMode(state.mode);
    else setMode("add");
  }, [state]);

  useEffect(() => {
    if (driver) {
      driverForm.setFieldsValue({
        fullName: driver.fullName,
        phoneNumber: driver.phoneNumber,
        fatherName: driver.fatherName,
        birthDate: driver.birthDate ? dayjs(driver.birthDate) : null,
        identityNumber: driver.identityNumber,
        nationalCode: driver.nationalCode,
        carName: driver.carName,
        carPluck: {
          firstPluck: driver.carTag ? driver.carTag.split("-")[0] : null,
          secondPluck: driver.carTag ? driver.carTag.split("-")[1] : 1,
          thirdPluck: driver.carTag ? driver.carTag.split("-")[2] : null,
          fourthPluck: driver.carTag ? driver.carTag.split("-")[3] : "62",
        },
      });
    } else driverForm.resetFields();
  }, [driverForm, driver]);

  const contextValues = {
    states: {
      mode,
      dataLoading,
      loading,
      driver,
      driverForm,
      deleteLoading,
    },
    func: { onFinish },
    requests: { onDeleteDriver },
  };

  return (
    <ManagementDriverDataContext.Provider value={contextValues}>
      {children}
    </ManagementDriverDataContext.Provider>
  );
};

export default ManagementDriverDataProvider;

export const useManagementDriverData = () =>
  useContext(ManagementDriverDataContext)!;
