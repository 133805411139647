import { Row } from "antd";
import styled from "styled-components";

export const InsuranceInfoContainer = styled(Row)`
  .detail-title {
    width: 160px;
    color: #233069;
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }
  .detail-value {
    color: #4b4b4b;
    font-size: 14px;
    font-weight: 400;
  }
  .second {
    margin: 25px 0;
  }
`;
