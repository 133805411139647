import { FC } from "react";
import { TableUI } from "src/components/UiKit/table";
import { ColumnsType } from "antd/es/table";
import { ITableRoutePlace } from "src/services/TourManagement/models";
import useLanguage from "src/store/language";
import TableActions from "./TableActions";
import { useRoutePlacesData } from "../../context";

const RoutePlacesTable: FC = () => {
  const {
    states: {
      routePlaces,
      dataLoading,
      totalCount,
      tablePaginationParams,
      selectedRoutePlace,
      mode,
    },
    dispatches: { setTablePaginationParams },
  } = useRoutePlacesData();

  const { words } = useLanguage();

  const tableColumns: ColumnsType<ITableRoutePlace> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (text, record, index) =>
        (tablePaginationParams.Offset - 1) * tablePaginationParams.Limit +
        index +
        1,
    },
    {
      title: `${words.managementRoute.city}`,
      dataIndex: "cityName",
      key: "cityName",
    },
    {
      title: `${words.managementRoute.places}`,
      dataIndex: "name",
      key: "name",
      render: (value) => (value && value !== "" ? value : "-"),
    },
    ...(mode === "view"
      ? []
      : [
          {
            title: `${words.basicInfo.tableActions}`,
            dataIndex: "tools",
            key: "tools",
            render: (_: any, record: ITableRoutePlace) => (
              <TableActions item={record} />
            ),
          },
        ]),
  ];

  return (
    <>
      <p className="hotel-table-title">
        {words.managementRoute.placeTableTitle}
      </p>
      <TableUI
        columns={tableColumns}
        dataSource={routePlaces}
        loading={dataLoading}
        scroll={{ x: 500 }}
        tableType="secondary"
        rowClassName={(record) =>
          record.routePlaceOfInterestId ===
          selectedRoutePlace?.routePlaceOfInterestId
            ? "selected-row-for-edit"
            : ""
        }
        pagination={{
          position: ["bottomCenter"],
          total: totalCount,
          pageSize: tablePaginationParams.Limit,
          current: tablePaginationParams.Offset,
          onChange(page, pageSize) {
            setTablePaginationParams((prev) => ({
              ...prev,
              Offset: page,
              Limit: pageSize,
            }));
          },
        }}
      />
    </>
  );
};

export default RoutePlacesTable;
