import { Flex } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { useManagementTranslatorData } from "../context";
import { convertDate } from "src/helper";
import { Gender } from "src/models/enums/gender";

const ViewTranslator: FC = () => {
  const { words } = useLanguage();
  const {
    states: { translator },
  } = useManagementTranslatorData();

  return (
    <>
      <p className="title">{words.managementTranslator.viewTitle}</p>
      <section className="view-details">
        <Flex className="w-full">
          <Flex vertical className="w-[50%]">
            <Flex>
              <div className="detail-title">
                {words.managementTranslator.nameLabel}:
              </div>
              <div className="text-[14px] text-[#4B4B4B] font-normal">
                {translator && translator?.firstName
                  ? translator?.firstName
                  : "-"}
              </div>
            </Flex>
            <Flex className="second">
              <div className="detail-title">
                {words.managementTranslator.genderLabel}:
              </div>
              <div className="text-[14px] text-[#4B4B4B] font-normal">
                {translator && translator?.gender
                  ? translator?.gender === Gender.Male
                    ? words.global.male
                    : words.global.femail
                  : "-"}
              </div>
            </Flex>
            <Flex>
              <div className="detail-title">
                {words.managementTranslator.nationalCodeLabel}:
              </div>
              <div className="text-[14px] text-[#4B4B4B] font-normal">
                {translator && translator?.nationalCode
                  ? translator?.nationalCode
                  : "-"}
              </div>
            </Flex>
          </Flex>
          <Flex vertical className="w-50%">
            <Flex>
              <div className="detail-title">
                {words.managementTranslator.familyNameLabel}:
              </div>
              <div className="text-[14px] text-[#4B4B4B] font-normal">
                {translator && translator?.lastName
                  ? translator?.lastName
                  : "-"}
              </div>
            </Flex>
            <Flex className="second">
              <div className="detail-title">
                {words.managementTranslator.dateLabel}:
              </div>
              <div className="text-[14px] text-[#4B4B4B] font-normal">
                {translator && translator?.birthDate
                  ? convertDate(translator?.birthDate)
                  : "-"}
              </div>
            </Flex>
            <Flex>
              <div className="detail-title">
                {words.managementTranslator.phoneLabel}:
              </div>
              <div className="text-[14px] text-[#4B4B4B] font-normal">
                {translator && translator?.phoneNumber
                  ? translator?.phoneNumber
                  : "-"}
              </div>
            </Flex>
          </Flex>
        </Flex>
      </section>
    </>
  );
};

export default ViewTranslator;
