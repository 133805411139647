import { ChangeEvent, FC, useState, useEffect } from "react";
import { Form, Col, Row, Input } from "antd";
import * as XLSX from "xlsx";
import useLanguage from "src/store/language";
import TourExcelModal from "./modals/TourExcelModal";
import FilterContentActions from "../FilterContentActions";
import { TabMenuKeys } from "../../models/enums/tabMenuKeys";
import { FilterContentContainer } from "../../styles";
import { useTourAddEditData } from "../../context/TourAddEditData";
import { ITourExcelData } from "./interface";
import { useTourInfoRequest } from "./TourRequest";
import { IPlanTourBatchArgs, ITourInfoResult } from "src/services/Tour/models";
import { useLocation } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import TourEditModal from "./modals/TourEditModal";

const TourInfo: FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [excelData, setExcelData] = useState<ITourExcelData[]>([]);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const {
    states: { loading },
    requests,
  } = useTourInfoRequest();
  const { states, dispatches } = useTourAddEditData()!;

  const { words } = useLanguage();

  const [tourForm] = Form.useForm();
  const tourInfo = Form.useWatch("planTourRequestDTOs", tourForm);
  const location = useLocation();

  useEffect(() => {
    if (states.formData && states.currentFilter === TabMenuKeys.tour) {
      tourForm.setFieldsValue({
        planTourRequestDTOs: states.formData.tour,
      });
    }
  }, [states.currentFilter, states.formData, tourForm]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.files) return;
    const file: FileList | null = event.currentTarget.files;

    if (file) {
      const f = file[0];
      const reader = new FileReader();
      reader.onload = (evt: any) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const sheetName = wb.SheetNames[0];
        const ws = wb.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_csv(ws);

        const convertedData: ITourExcelData[] = convertToJson(data);

        setExcelData(convertedData);
      };
      reader.readAsBinaryString(f);
      setOpenModal(true);
    }
  };

  const convertToJson = (csv: any) => {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");
    headers = headers.filter(function (header: any) {
      return /\S/.test(header);
    });

    for (var i = 1; i < lines.length; i++) {
      var obj: any = {};
      var currentLine = lines[i].split(",");
      currentLine = currentLine.filter(function (current: any) {
        return /\S/.test(current);
      });

      if (currentLine.length > 0) {
        for (var j = 0; j < headers.length; j++) {
          obj[headers[j] as any] = currentLine[j];
        }

        obj.key = i;
        result.push(obj);
      }
    }

    return result;
  };

  const handleSaveExcel = () => {
    setOpenModal(false);
    tourForm.setFieldsValue({
      planTourRequestDTOs: excelData,
    });
  };

  const handleFinishTourForm = (values: IPlanTourBatchArgs) => {
    requests.addEditTourRequest(values);
  };

  return (
    <FilterContentContainer justify={"center"}>
      <TourExcelModal
        open={openModal}
        onBack={() => setOpenModal(false)}
        onSave={handleSaveExcel}
        data={excelData}
      />
      <TourEditModal
        open={openEditModal}
        onBack={() => setOpenEditModal(false)}
        onSave={() => setOpenEditModal(false)}
        data={states.formData?.tour as ITourInfoResult[]}
      />
      <Col xs={24} md={20} lg={16} className="form">
        <p className="title">{words.addEditTour.tourInfoTitle}</p>
        <Form form={tourForm} onFinish={handleFinishTourForm}>
          <Row gutter={[16, 16]} className="form-row">
            <Col span={24} className="upload-tourist">
              <p className="upload-title">
                {words.addEditTour.tourUploadTitle}
              </p>
              <Form.Item
                name={"planTourRequestDTOs"}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <Input type={"hidden"} autoComplete="off" />
              </Form.Item>
              {location.pathname.includes(ROUTES.tourAdd) ? (
                <input
                  type="file"
                  accept=".xls,.xlsx"
                  id="excel"
                  className="input-file"
                  onChange={onChange}
                  autoComplete="off"
                />
              ) : (
                <input
                  type="button"
                  accept=".xls,.xlsx"
                  autoComplete="off"
                  id="excel"
                  className="input-file"
                  onClick={() => setOpenEditModal(true)}
                />
              )}
              <label htmlFor="excel">
                {tourInfo ? (
                  <img src="/assets/images/excel.svg" alt="excel" />
                ) : (
                  <>
                    <span className="material-icons">attach_file</span>
                    <span>{words.global.upload}</span>
                  </>
                )}
              </label>
            </Col>

            <FilterContentActions
              onPrevious={() => dispatches.setCurrentFilter(TabMenuKeys.team)}
              loading={loading}
            />
          </Row>
        </Form>
      </Col>
    </FilterContentContainer>
  );
};

export default TourInfo;
