import { FC } from "react";
import { Tabs } from "antd";
import { TeamInfoContainer } from "./style";
import useLanguage from "src/store/language";
import ManagerTab from "./tabs/manager";
import LeaderTab from "./tabs/leader";
import TranslatorTab from "./tabs/translator";
import DriverTab from "./tabs/driver";
import CarTab from "./tabs/car";
import { FilterContentContainer } from "../../styles";
import { useTourAddEditData } from "../../context/TourAddEditData";
import { TabMenuKeys } from "../../models/enums/tabMenuKeys";
import { useLocation } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";

const TeamInfo: FC = () => {
  const { words } = useLanguage();
  const { states, dispatches } = useTourAddEditData()!;

  const location = useLocation();

  const items = [
    {
      label: `${words.viewTour.manager}`,
      key: TabMenuKeys.manager,
      children: <ManagerTab />,
      disabled:
        states.current === TabMenuKeys.manager ||
        location.pathname.includes(ROUTES.tourEdit)
          ? false
          : true,
    },
    {
      label: `${words.viewTour.leader}`,
      key: TabMenuKeys.leader,
      children: <LeaderTab />,
      disabled:
        states.current === TabMenuKeys.leader ||
        location.pathname.includes(ROUTES.tourEdit)
          ? false
          : true,
    },
    {
      label: `${words.viewTour.translator}`,
      key: TabMenuKeys.translator,
      children: <TranslatorTab />,
      disabled:
        states.current === TabMenuKeys.translator ||
        location.pathname.includes(ROUTES.tourEdit)
          ? false
          : true,
    },
    {
      label: `${words.viewTour.driver}`,
      key: TabMenuKeys.driver,
      children: <DriverTab />,
      disabled:
        states.current === TabMenuKeys.driver ||
        location.pathname.includes(ROUTES.tourEdit)
          ? false
          : true,
    },
    {
      label: `${words.viewTour.car}`,
      key: TabMenuKeys.car,
      children: <CarTab />,
      disabled:
        states.current === TabMenuKeys.car ||
        location.pathname.includes(ROUTES.tourEdit)
          ? false
          : true,
    },
  ];
  const onChange = (key: string) => {
    dispatches.setCurrent(key);
  };

  return (
    <FilterContentContainer justify={"center"}>
      <TeamInfoContainer xs={24} md={20} lg={16} className="form">
        <Tabs
          onChange={onChange}
          type="card"
          centered
          activeKey={states.current}
          items={items}
        />
      </TeamInfoContainer>
    </FilterContentContainer>
  );
};

export default TeamInfo;
