import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { ICity, INewHotelArg } from "src/services/BasicInformation/models";
import { BasicInformationService } from "src/services/BasicInformation/BasicInformation.service";
import useLanguage from "src/store/language";
import { useHotelsData } from "src/modules/hotels/context/HotelsData";
import { HotelsViewMode } from "src/modules/hotels/models/enums";
import { useAddHotelsData } from "../../context";
import { App } from "antd";

interface IContextValue {
  values: {
    cities: ICity[];
    cityLoading: boolean;
    loading: boolean;
  };
  func: {
    onFinish: (values: INewHotelArg) => void;
    handleChangesBack: () => void;
  };
}

export const AddHotelInfoDataContext = createContext<IContextValue | undefined>(
  undefined
);

const AddHotelInfoDataProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [cities, setCities] = useState<ICity[]>([]);
  const [cityLoading, setCityLoadings] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { message } = App.useApp();

  const {
    values: { created },
    dispatches: { setCurrentTabKey, setCreated },
  } = useAddHotelsData();

  const {
    states: { selectedHotel, viewMode, addHotelForm, hotelInfoChanged },
    dispatches: { setSelectedHotel, setHotelInfoChanged },
  } = useHotelsData();

  const { words } = useLanguage();

  const onFinish = (values: INewHotelArg) => {
    if (viewMode === HotelsViewMode.Edit || (created && hotelInfoChanged))
      hotelEditREq(values);
    else if (!selectedHotel) hotelAddReq(values);
    else setCurrentTabKey("contact");
  };

  const hotelAddReq = async (values: INewHotelArg) => {
    setLoading(true);
    try {
      const { NewHotel } = new BasicInformationService();
      const result = await NewHotel(values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        addHotelForm.resetFields();
        setCreated(result.data);
        setSelectedHotel({
          id: result.data,
          cityName: "",
          ...values,
        });
        setCurrentTabKey("contact");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const hotelEditREq = async (values: INewHotelArg) => {
    if (!selectedHotel && !created) return;
    setLoading(true);
    try {
      const { EditHotel } = new BasicInformationService();
      const result = await EditHotel(
        selectedHotel ? selectedHotel.id : created!,
        values
      );
      if (result && result.status === 200) {
        setHotelInfoChanged(false);
        message.success(words.global.successMessage);
        if (created && hotelInfoChanged) setCurrentTabKey("contact");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getCities = useCallback(async (provinceId?: number) => {
    setCityLoadings(true);
    try {
      const { Cities } = new BasicInformationService();
      const res = await Cities();
      if (res && res.status === 200 && res.data) {
        setCities(res.data.records);
      } else {
        setCities([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setCityLoadings(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangesBack = () => {
    if (selectedHotel)
      addHotelForm.setFieldsValue({
        name: selectedHotel?.name,
        cityId: selectedHotel?.cityId,
        address: selectedHotel?.address,
        star: selectedHotel?.star,
      });
  };

  useEffect(() => {
    getCities();
  }, [getCities]);

  useEffect(() => {
    if (selectedHotel && (viewMode === HotelsViewMode.Edit || created)) {
      handleChangesBack();
    } else addHotelForm.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addHotelForm, created, selectedHotel, viewMode]);

  const contextValues = {
    values: {
      cities,
      cityLoading,
      loading,
    },
    func: { onFinish, handleChangesBack },
  };

  return (
    <AddHotelInfoDataContext.Provider value={contextValues}>
      {children}
    </AddHotelInfoDataContext.Provider>
  );
};

export default AddHotelInfoDataProvider;

export const useAddHotelInfoData = () => useContext(AddHotelInfoDataContext)!;
