import { Button, Col, Divider } from "antd";
import { FC } from "react";
import { Icons } from "src/components/customIcons";
import { useTourViewData } from "../../context/TourViewData";

interface IPrintTourFooterProps {
  onJpg: () => void;
}

const Footer: FC<IPrintTourFooterProps> = ({ onJpg }) => {
  const {
    dispatches: { setViewMode },
  } = useTourViewData();
  return (
    <Col span={24} className="w-full absolute bottom-0 right-0">
      <Divider />
      <div className="w-full flex justify-between px-[24px]">
        <Button
          type="text"
          icon={<span className="material-icons text-[#fff]">close</span>}
          onClick={() => setViewMode("view")}
          className="flex justify-between bg-[#F6A372] text-[#fff] hover:!bg-[#F6A372] hover:!text-[#fff]"
        >
          <span className="">بستن</span>
        </Button>

        <div>
          <span className="cursor-pointer" onClick={onJpg}>
            <Icons name="jpg" />
          </span>
        </div>
      </div>
    </Col>
  );
};

export default Footer;
