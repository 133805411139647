import { ROUTES } from "src/models/enums/routes";
import useLanguage from "src/store/language";

export const useManagementsTitle = () => {
  const { words } = useLanguage();

  const titles = {
    firstTitle: [
      {
        url: ROUTES.tourManagementBasicInfo,
        text: words.addTour.basicInfo,
      },
      {
        url: ROUTES.tourManagementRoute,
        text: words.managementRoute.route,
      },
      {
        url: ROUTES.tourManagementTouristInfo,
        text: words.addTouristInfo.headerTitle,
      },
      {
        url: ROUTES.tourManagementSimcard,
        text: words.managementSimcard.managementSimcard,
      },
      {
        url: ROUTES.tourManagementAssignSimcard,
        text: words.managementSimcardAssign.assignTitle,
      },
      {
        url: ROUTES.tourManagementTime,
        text: words.managementTime.managementTime,
      },
      {
        url: ROUTES.domesticFlight,
        text: words.managementDomesticFlight.headerTitle,
      },
      {
        url: ROUTES.tourManagementInsurance,
        text: words.managementInsurance.insuranceTitle,
      },
      {
        url: ROUTES.tourManagementFiles,
        text: words.managementFiles.managementFilesTitle,
      },
      {
        url: ROUTES.tourManagementCompanionLeader,
        text: words.managementCompanionLeader.companionTitle,
      },
      {
        url: ROUTES.tourManagementLeader,
        text: words.managementLeader.leaderTitle,
      },
      {
        url: ROUTES.tourManagementTranslator,
        text: words.managementTranslator.translatorTitle,
      },
      {
        url: ROUTES.tourManagementDriver,
        text: words.managementDriver.driverTitle,
      },
    ],
    secondTitle: [
      {
        url: ROUTES.routePlace,
        text: words.managementRoute.places,
      },
      {
        url: ROUTES.routeRoom,
        text: words.managementRoute.hotelTitle,
      },
      {
        url: ROUTES.insuranceAttachment,
        text: words.managementInsurance.attachmentPageTitle,
      },
    ],
  };
  return titles;
};
