import { TableColumnsType } from "antd";
import { Gender } from "src/models/enums/gender";
import { IPeople } from "src/services/ExportData/models";

export const touristListColumns: TableColumnsType<IPeople> = [
  {
    key: "expireDate",
    dataIndex: "expireDate",
    title: "تاریخ انقضا گذرنامه ",
    width: 107,
    align: "center",
    className: "columns",
  },
  {
    key: "passportNo",
    dataIndex: "passportNo",
    title: "شماره گذرنامه",
    width: 143,
    align: "center",
    className: "columns",
  },
  {
    dataIndex: "gender",
    key: "gender",
    title: "جنسیت",
    width: 102,
    align: "center",
    className: "columns",
    render: (value: Gender) => {
      let showGender = "";
      switch (value) {
        case 1:
          showGender = "Male";
          break;
        case 2:
          showGender = "Female";
          break;
      }
      return showGender;
    },
  },
  {
    key: "birthDate",
    dataIndex: "birthDate",
    title: "تاریخ تولد",
    width: 121,
    align: "center",
    className: "columns",
  },
  {
    key: "lastName",
    dataIndex: "lastName",
    title: "نام خانوادگی",
    width: 121,
    align: "center",
    className: "columns",
  },
  {
    dataIndex: "firstName",
    key: "firstName",
    title: "نام",
    width: 113,
    align: "center",
    className: "columns",
  },
  {
    key: "roomNum",
    title: "نوع اتاق",
    width: 104,
    align: "center",
    className: "columns",
    render: () => "",
  },
  {
    key: "#",
    title: "#",
    width: 60,
    align: "center",
    className: "columns",
    render: (text, record, index) => index + 1,
  },
];
