import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import { Form, message } from "antd";
import type { FormInstance } from "antd";
import { IContact, INewContactArg } from "src/services/BasicInformation/models";
import { BasicInformationService } from "src/services/BasicInformation/BasicInformation.service";
import useLanguage from "src/store/language";
import { IPagination } from "src/models/interfaces/pagination";
import { createSearchParams, useLocation } from "react-router-dom";
import { useHotelsData } from "src/modules/hotels/context/HotelsData";
import { useAddHotelsData } from "../../../context";
import { useAddHotelContactData } from "../context";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";
import { useAddHotelResponderDetailsData } from "../phone/context";

interface IContextValue {
  values: {
    dataLoading: boolean;
    addPersonForm: FormInstance;
    loading: boolean;
    deleteId: number;
    confirmLoading: boolean;
    selectedContact: IContact | undefined;
    totalCount: number;
    tablePaginationParams: Pick<IPagination, "Offset" | "Limit">;
  };
  func: {
    onFinishContact: (values: INewContactArg) => void;
    handleDeleteId: (id: number) => void;
  };
  requests: {
    onDeleteContact: () => void;
  };
  dispatches: {
    setSelectedContact: Dispatch<SetStateAction<IContact | undefined>>;
    setTablePaginationParams: Dispatch<
      SetStateAction<Pick<IPagination, "Offset" | "Limit">>
    >;
  };
}

export const AddHotelResponderDataContext = createContext<
  IContextValue | undefined
>(undefined);

const AddHotelResponderDataProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [dataLoading, setDataLoadings] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [tablePaginationParams, setTablePaginationParams] =
    useState<IPagination>({
      Offset: 1,
      Limit: 10,
    });
  const [deleteId, setDeleteId] = useState<number>(0);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<IContact | undefined>(
    undefined
  );

  const [addPersonForm] = Form.useForm();
  const location = useLocation();
  const { words } = useLanguage();

  const {
    states: { selectedHotel },
  } = useHotelsData();

  const {
    values: { created, currentTabKey },
  } = useAddHotelsData();

  const {
    dispatches: { setResponderNames },
  } = useAddHotelContactData();
  const {
    func: { handleResponderDetails },
  } = useAddHotelResponderDetailsData();
  const onFinishContact = (values: INewContactArg) => {
    if (selectedContact) contactEditReq(values);
    else contactAddReq(values);
  };

  const contactAddReq = async (values: INewContactArg) => {
    if (!created && !selectedHotel) return;
    let hotelId: number = selectedHotel ? selectedHotel.id : created || 0;
    setLoading(true);
    try {
      const { NewContact } = new BasicInformationService();
      const result = await NewContact(hotelId, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        addPersonForm.resetFields();
        handleResponderDetails(0);
        getContacts(hotelId);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const contactEditReq = async (values: INewContactArg) => {
    if (!selectedContact) return;
    setLoading(true);
    try {
      const { EditContact } = new BasicInformationService();
      const result = await EditContact(selectedContact?.id, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setSelectedContact(undefined);
        getContacts(selectedContact.hotelId);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteId = (id: number) => {
    setDeleteId(id);
  };

  const onDeleteContact = async () => {
    if ((!selectedHotel && !created) || deleteId === 0) return;
    let hotelId: number = selectedHotel ? selectedHotel.id : created || 0;
    setConfirmLoading(true);
    try {
      const { DeleteContact } = new BasicInformationService();
      const result = await DeleteContact(deleteId);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        if (selectedContact && selectedContact.id === deleteId)
          setSelectedContact(undefined);
        setDeleteId(0);
        handleResponderDetails(0);
        getContacts(hotelId);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmLoading(false);
    }
  };

  const getContacts = useCallback(async (hotelId: number) => {
    setDataLoadings(true);
    try {
      const { Contacts } = new BasicInformationService();
      const query = `${location.search}${
        location.search ? "&" : "?"
      }${createSearchParams(tablePaginationParams).toString()}`;
      const res = await Contacts(hotelId, query);
      if (res && res.status === 200 && res.data) {
        setResponderNames(res.data.records);
        setTotalCount(res.data.count);
      } else {
        setResponderNames([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoadings(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedHotel) getContacts(selectedHotel.id);
  }, [getContacts, selectedHotel]);

  useEffect(() => {
    if (currentTabKey === "info") {
      setSelectedContact(undefined);
      addPersonForm.resetFields();
    }
  }, [addPersonForm, currentTabKey, selectedContact]);

  const contextValues = {
    values: {
      dataLoading,
      addPersonForm,
      loading,
      confirmLoading,
      selectedContact,
      deleteId,
      totalCount,
      tablePaginationParams,
    },
    func: { onFinishContact, handleDeleteId },
    requests: { onDeleteContact },
    dispatches: {
      setSelectedContact,
      setTablePaginationParams,
    },
  };

  return (
    <AddHotelResponderDataContext.Provider value={contextValues}>
      {children}
      <DeleteConfirmModal
        showConfirm={deleteId > 0}
        onCancel={() => handleDeleteId(0)}
        onOk={() => onDeleteContact()}
        title={words.basicInfo.deleteConfirmTitle}
        okTitle={words.global.delete}
        loading={confirmLoading}
      />
    </AddHotelResponderDataContext.Provider>
  );
};

export default AddHotelResponderDataProvider;

export const useAddHotelResponderData = () =>
  useContext(AddHotelResponderDataContext)!;
