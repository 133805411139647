import { Modal, ModalProps } from "antd";
import { FC } from "react";
import { ConfirmContainer } from "./style";

export interface IProps extends ModalProps {
  text: string;
  confirmIcon?: string;
}
export const ConfirmModal: FC<IProps> = (props) => {
  const { text, confirmIcon } = props;
  return (
    <Modal
      okButtonProps={{
        icon: <span className="material-icons">{confirmIcon}</span>,
        className: "flex justify-center items-center gap-[4px] bg-[#FAD1B8]",
      }}
      cancelButtonProps={{
        className: "flex justify-center items-center gap-[4px] cancel",
      }}
      rootClassName="confirm-modal"
      {...props}
      className="confirm-modal"
    >
      <ConfirmContainer>
        <div className="content">{text}</div>
      </ConfirmContainer>
    </Modal>
  );
};
