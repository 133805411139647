import { FC } from "react";
import { TabMenuKeys } from "src/modules/addEditTour/models";
import Attachments from "./attachment";
import Insurance from "./insurance";
import InsuranceRequestProvider from "./insurance/InsuranceRequest";
import TeamInfo from "./team";
import TourInfo from "./tour/TourInfo";
import TourInfoRequestProvider from "./tour/TourRequest";
import TouristInfoRequestProvider from "./tourist/TouristInfoRequest";
import TouristList from "./tourist/TouristList";
import { useTourViewData } from "../context/TourViewData";

const FilterContent: FC = () => {
  const { states } = useTourViewData()!;

  const handleFilterContent = () => {
    switch (states.currentFilter) {
      case TabMenuKeys.tourist:
        return (
          <TouristInfoRequestProvider>
            <TouristList />
          </TouristInfoRequestProvider>
        );
      case TabMenuKeys.team:
        return <TeamInfo />;
      case TabMenuKeys.tour:
        return (
          <TourInfoRequestProvider>
            <TourInfo />
          </TourInfoRequestProvider>
        );
      case TabMenuKeys.insurance:
        return (
          <InsuranceRequestProvider>
            <Insurance />
          </InsuranceRequestProvider>
        );
      case TabMenuKeys.attachment:
        return <Attachments />;
    }
  };
  return <>{handleFilterContent()}</>;
};

export default FilterContent;
