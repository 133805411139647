import { Flex } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { useManagementInsuranceData } from "src/modules/managementInsurance/context";
import { IInsuranceTableData, useAddEditInsuranceData } from "../context";
import { Link, useParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";

interface ITableActionsProps {
  item: IInsuranceTableData;
}

const TableActions: FC<ITableActionsProps> = ({ item }) => {
  const { words } = useLanguage();

  const { tourId } = useParams();

  const {
    states: { mode },
  } = useManagementInsuranceData();

  const {
    func: { handleDeleteId },
    dispatches: { setSelectedInsurance },
  } = useAddEditInsuranceData();

  const handleSelectHotel = () => {
    setSelectedInsurance(item);
  };

  return (
    <>
      <Flex justify="center" align="center" className="gap-[16px]">
        <Link
          className="file-action"
          to={`${ROUTES.tourTable}/${tourId}${ROUTES.managementTour}${ROUTES.tourManagementInsurance}/${item.id}${ROUTES.insuranceAttachment}`}
          state={{ mode: mode, insurance: item }}
        >
          <span className="material-icons text-[14px]">create_new_folder</span>
          <span>{words.global.fileAction}</span>
        </Link>

        {mode === "edit" || mode === "add" ? (
          <>
            <div className="edit-action" onClick={() => handleSelectHotel()}>
              <span className="material-icons text-[#0C9D61]  text-[16px]">
                edit_note
              </span>
              <span>{words.global.editAction}</span>
            </div>
            <div
              className="delete-action"
              onClick={() => handleDeleteId(item.id)}
            >
              <span className="material-icons text-[14px]">delete_forever</span>
              <span>{words.global.delete}</span>
            </div>
          </>
        ) : null}
      </Flex>
    </>
  );
};

export default TableActions;
