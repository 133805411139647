import { FC } from "react";
import { Col } from "antd";
import AddEditRoute from "./components/routes";
import { ManagementRouteContainer } from "./style";

const ManagementRoute: FC = () => {
  return (
    <>
      {/* <PageHeader
        mode={mode}
        title={words.managementRoute.route}
        backTitle={words.global.back}
        tourCode={123465}
      /> */}
      <ManagementRouteContainer justify={"center"} gutter={[0, 24]}>
        <Col xs={24} md={23} className="content">
          <AddEditRoute />
        </Col>
      </ManagementRouteContainer>
    </>
  );
};

export default ManagementRoute;
