import { FC } from "react";
import { Divider } from "antd";
import { TableUI } from "src/components/UiKit/table";
import { ColumnsType } from "antd/es/table";
import { ITourDocument } from "src/services/TourManagement/models";
import useLanguage from "src/store/language";
import TableActions from "./TableActions";
import { useManagementFilesData } from "src/modules/managementFiles/context";

const ManagementFilesTable: FC = () => {
  const {
    states: {
      documents,
      dataLoading,
      totalCount,
      tablePaginationParams,
      mode,
      selectedDocument,
    },
    dispatches: { setTablePaginationParams },
  } = useManagementFilesData();

  const { words } = useLanguage();

  const tableColumns: ColumnsType<ITourDocument> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      width: 62,
      render: (text, record, index) =>
        (tablePaginationParams.Offset - 1) * tablePaginationParams.Limit +
        index +
        1,
    },
    {
      title: `${words.managementFiles.fileLabel}`,
      dataIndex: "title",
      key: "title",
      width: 144,
    },
    {
      title: `${words.managementFiles.fileFormat}`,
      dataIndex: "extention",
      key: "extention",
      width: 133,
    },

    {
      title: `${words.managementFiles.description}`,
      dataIndex: "detail",
      key: "detail",
      // render: (value) =>
      //   value && value !== ""
      //     ? value.length > 100
      //       ? value.slice(0, 100).concat("...")
      //       : value
      //     : "-",
      render: (text, record, index) =>
        record.detail?.substring(0, 70).concat("..."),
    },
    {
      title: `${words.basicInfo.tableActions}`,
      dataIndex: "tools",
      key: "tools",
      width: 250,
      render: (_: any, record: ITourDocument) => <TableActions item={record} />,
    },
  ];
  return (
    <>
      {mode === "add" || mode === "edit" ? (
        <Divider className="bg-[#E1E1E1]" />
      ) : null}
      <p className="simcard-table-title">{words.managementFiles.tableTitle}</p>
      <TableUI
        columns={tableColumns}
        dataSource={documents}
        loading={dataLoading}
        scroll={{ x: 500 }}
        tableType="secondary"
        rowClassName={(record, index) =>
          record.attachmentId === selectedDocument?.attachmentId
            ? "selected-row-for-edit"
            : ""
        }
        pagination={{
          position: ["bottomCenter"],
          total: totalCount,
          pageSize: tablePaginationParams.Limit,
          current: tablePaginationParams.Offset,
          onChange(page, pageSize) {
            setTablePaginationParams((prev) => ({
              ...prev,
              Offset: page,
              Limit: pageSize,
            }));
          },
        }}
      />
    </>
  );
};

export default ManagementFilesTable;
