import { Flex } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { useManagementDriverData } from "../../context";
import { carPluckAlphabetArray } from "../../constants/selectOptions";

const ViewCar: FC = () => {
  const { words } = useLanguage();
  const {
    states: { driver },
  } = useManagementDriverData();

  return (
    <>
      <p className="title mt-[24px]">{words.managementDriver.viewCarTitle}</p>
      <section className="view-details">
        <Flex className="w-full">
          <Flex vertical className="w-[50%]">
            <Flex className="second">
              <div className="detail-title">
                {words.managementDriver.carNameLabel}:
              </div>
              <div className="text-[14px] text-[#4B4B4B] font-normal">
                {driver && driver?.carName ? driver?.carName : "-"}
              </div>
            </Flex>
          </Flex>
          <Flex vertical className="w-50%">
            <Flex className="second">
              <div className="detail-title">
                {words.managementDriver.pluckLabel}:
              </div>
              <div className="pluck">
                {driver && driver?.carTag ? (
                  <>
                    <span>ایران</span>
                    <span>{driver.carTag.split("-")[3]}</span>
                    <span> _ </span>
                    <span>{driver.carTag.split("-")[2].concat(" ")}</span>
                    <span>
                      {
                        carPluckAlphabetArray[
                          Number(driver.carTag.split("-")[1])
                        ]
                      }
                    </span>
                    <span>{driver.carTag.split("-")[0]}</span>
                  </>
                ) : (
                  "-"
                )}
              </div>
            </Flex>
          </Flex>
        </Flex>
      </section>
    </>
  );
};

export default ViewCar;
