export enum UserManagementUrls {
  Roles = "UserManagement/Roles",
  newUser = "UserManagement/newUser",
  userReport = "UserManagement/userReport",
  RolesOfUser = "/RolesOfUser",
  UserManagement = "UserManagement/",
  AddUserRole = "/AddUserRole",
  ResetUserPassword = "/ResetUserPassword",
  Enable = "/Enable",
  Disable = "/Disable",
}
