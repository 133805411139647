import { Flex, Spin } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { useManagementTimeData } from "../context";
import { convertDate, convertDateMiladi } from "src/helper";
import { TourStates } from "src/models/enums/TourStates";

const ViewTime: FC = () => {
  const { words } = useLanguage();
  const {
    states: { tourTime, dataLoading },
  } = useManagementTimeData();

  const chooseTourState = (stateType?: number) => {
    switch (stateType) {
      case TourStates.Waiting:
        return words.tableTour.waitingToStart;
      case TourStates.Progress:
        return words.tableTour.progress;
      case TourStates.Finished:
        return words.tableTour.finished;
      case TourStates.Inactive:
        return words.tableTour.inactive;
    }
  };

  return (
    <>
      {dataLoading ? (
        <div className="w-full flex justify-center items-center">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Flex justify="space-between" align="center">
            <p className="title">{words.managementTime.viewTitle}</p>
            <p className="text-[14px] text-[#FE9B0E] ">
              {chooseTourState(tourTime?.tourState)}
            </p>
          </Flex>

          <section className="view-details">
            <Flex className="w-full">
              <Flex vertical className="w-[50%]">
                <Flex>
                  <div className="detail-title">
                    {words.managementTime.startTourMiladiLabel}:
                  </div>
                  <div className="text-[14px] font-normal text-[#4B4B4B]">
                    {tourTime && tourTime?.startDate
                      ? convertDateMiladi(tourTime?.startDate)
                      : "-"}
                  </div>
                </Flex>
                <Flex className="second">
                  <div className="detail-title">
                    {words.managementTime.endTourMiladiLabel}:
                  </div>
                  <div className="text-[14px] font-normal text-[#4B4B4B]">
                    {tourTime?.endDate
                      ? convertDateMiladi(tourTime?.endDate)
                      : "-"}
                  </div>
                </Flex>
                <Flex>
                  <div className="detail-title">
                    {words.managementTime.cancelMiladiLabel}:
                  </div>
                  <div className="text-[14px] font-normal text-[#4B4B4B]">
                    {tourTime && tourTime?.cancelDate
                      ? convertDateMiladi(tourTime?.cancelDate)
                      : "-"}
                  </div>
                </Flex>
                <Flex className="second">
                  <div className="detail-title">
                    {words.managementTime.flightLabel}:
                  </div>
                  <div className="text-[14px] font-normal text-[#4B4B4B]">
                    {tourTime && tourTime?.flightDate
                      ? convertDateMiladi(tourTime?.flightDate)
                      : "-"}
                  </div>
                </Flex>
              </Flex>
              <Flex vertical className="w-50%">
                <Flex>
                  <div className="detail-title">
                    {words.managementTime.startTourLabel}:
                  </div>
                  <div className="text-[14px] font-normal text-[#4B4B4B]">
                    {tourTime && tourTime?.startDate
                      ? convertDate(tourTime?.startDate)
                      : "-"}
                  </div>
                </Flex>
                <Flex className="second">
                  <div className="detail-title">
                    {words.managementTime.endTourLabel}:
                  </div>
                  <div className="text-[14px] font-normal text-[#4B4B4B]">
                    {tourTime && tourTime?.endDate
                      ? convertDate(tourTime?.endDate)
                      : "-"}
                  </div>
                </Flex>
                <Flex>
                  <div className="detail-title">
                    {words.managementTime.cancelLabel}:
                  </div>
                  <div className="text-[14px] font-normal text-[#4B4B4B]">
                    {tourTime && tourTime?.cancelDate
                      ? convertDate(tourTime?.cancelDate)
                      : "-"}
                  </div>
                </Flex>
                <Flex className="second">
                  <div className="detail-title">
                    {words.managementTime.tourDurationLabel}:
                  </div>
                  <div className="text-[14px] font-normal text-[#4B4B4B]">
                    {tourTime?.tourDays || 0} روز
                  </div>
                </Flex>
              </Flex>
            </Flex>
          </section>
        </>
      )}
    </>
  );
};

export default ViewTime;
