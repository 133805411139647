import { Form, FormInstance, message } from "antd";
import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import type { UploadFile } from "antd/es/upload/interface";
import { IObject } from "src/models/interfaces";
import { IPagination } from "src/models/interfaces/pagination";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import {
  IInsuranceDocument,
  IManagementInsurance,
  ITourDocumentArg,
} from "src/services/TourManagement/models";
import { AxiosRequestConfig } from "axios";
import downloadZipFile from "src/helper/downloadZipFile";
import useLanguage from "src/store/language";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";

interface IContextValue {
  states: {
    documents: IInsuranceDocument[];
    dataLoading: boolean;
    totalCount: number;
    tablePaginationParams: Pick<IPagination, "Offset" | "Limit">;
    selectedDocument: IInsuranceDocument | undefined;
    loading: boolean;
    documentList: UploadFile[];
    confirmLoading: boolean;
    deleteId: number;
    progress: number;
    mode: string;
    insurance: IManagementInsurance | undefined;
  };
  func: {
    onFinishInsuranceDocumentFile: (values: ITourDocumentArg) => void;
    handleDeleteId: (id: number) => void;
  };
  dispatches: {
    setTablePaginationParams: Dispatch<
      SetStateAction<Pick<IPagination, "Offset" | "Limit">>
    >;
    setSelectedDocument: Dispatch<
      SetStateAction<IInsuranceDocument | undefined>
    >;
    setDocumentList: Dispatch<SetStateAction<UploadFile[]>>;
  };
  form: { insuranceAttachmentForm: FormInstance };
  requests: {
    onDeleteAttachment: () => void;
    addFileRequest: (id: number, formData: FormData) => Promise<boolean>;
    downloadAttachFile: (attachId: number) => void;
  };
}

export const InsuranceAttachmentDataContext = createContext<
  IContextValue | undefined
>(undefined);

const InsuranceAttachmentDataProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [documents, setDocuments] = useState<IInsuranceDocument[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [tablePaginationParams, setTablePaginationParams] =
    useState<IPagination>({
      Offset: 1,
      Limit: 10,
    });
  const [selectedDocument, setSelectedDocument] = useState<
    IInsuranceDocument | undefined
  >(undefined);
  const [documentList, setDocumentList] = useState<UploadFile[]>([]);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [mode, setMode] = useState<string>("");
  const [insurance, setInsurance] = useState<IManagementInsurance | undefined>(
    undefined
  );
  const [insuranceAttachmentForm] = Form.useForm();

  const { words } = useLanguage();

  const { tourId } = useParams();
  const { state } = useLocation();

  const onFinishInsuranceDocumentFile = (values: IObject) => {
    var formData = new FormData();

    formData.append("Title", values.Title);
    if (values.Detail) formData.append("Detail", values.Detail);
    if (documentList.length > 0)
      formData.append("File", documentList[0].originFileObj as any);

    if (selectedDocument) EditInsuranceAttachmentRequest(formData);
    else addInsuranceAttachmentRequest(formData);
  };

  const addInsuranceAttachmentRequest = async (values: FormData) => {
    if (!insurance) return;
    setLoading(true);
    try {
      const { AddManagementInsuranceDocument } = new TourManagementService();
      const result = await AddManagementInsuranceDocument(insurance.id, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setDocumentList([]);
        insuranceAttachmentForm.resetFields();
        getInsuranceAttachment();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const EditInsuranceAttachmentRequest = async (values: FormData) => {
    if (!selectedDocument) return;
    setLoading(true);
    try {
      const { EditManagementInsuranceDocument } = new TourManagementService();
      const result = await EditManagementInsuranceDocument(
        selectedDocument.attachmentId,
        values
      );
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setSelectedDocument(undefined);
        getInsuranceAttachment();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const addFileRequest = async (
    id: number,
    formData: FormData
  ): Promise<boolean> => {
    setLoading(true);
    try {
      const { EditManagementInsuranceDocument } = new TourManagementService();
      const result = await EditManagementInsuranceDocument(id, formData);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        return true;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    return false;
  };

  const handleDeleteId = (id: number) => {
    setDeleteId(id);
  };

  const onDeleteAttachment = async () => {
    if (deleteId === 0) return;
    setConfirmLoading(true);
    try {
      const { DeleteInsuranceDocument } = new TourManagementService();
      const result = await DeleteInsuranceDocument(deleteId);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        if (deleteId === selectedDocument?.attachmentId)
          setSelectedDocument(undefined);
        setDeleteId(0);
        getInsuranceAttachment();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmLoading(false);
    }
  };

  const downloadAttachFile = async (attachId: number) => {
    setProgress(100);
    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 0)
        );
        setProgress(percentage);
        if (percentage === 100) {
          setTimeout(() => setProgress(0), 400);
        }
      },
    };
    const { DownloadInsuranceFile } = new TourManagementService();
    try {
      const result = await DownloadInsuranceFile(attachId, config);
      if (result) {
        downloadZipFile(result);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProgress(0);
    }
  };

  const getInsuranceAttachment = useCallback(async () => {
    if (!insurance) return;
    setDataLoading(true);
    const params = new URLSearchParams(tablePaginationParams);
    try {
      const { GetInsuranceDocument } = new TourManagementService();
      const res = await GetInsuranceDocument(insurance.id, params.toString());
      if (res && res.status === 200 && res.data) {
        setDocuments(res.data.records);
        setTotalCount(res.data.count);
      } else {
        setDocuments([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoading(false);
    }
  }, [insurance, tablePaginationParams]);

  useEffect(() => {
    getInsuranceAttachment();
  }, [getInsuranceAttachment, mode, tourId]);

  useEffect(() => {
    if (selectedDocument) {
      insuranceAttachmentForm.setFieldsValue({
        Title: selectedDocument.title,
        Detail: selectedDocument.detail || null,
      });
    } else {
      setDocumentList([]);
      insuranceAttachmentForm.resetFields();
    }
  }, [insuranceAttachmentForm, selectedDocument]);

  useEffect(() => {
    if (state && state.mode) setMode(state.mode);
    else setMode("add");
  }, [state]);

  useEffect(() => {
    if (state && state.insurance) setInsurance(state.insurance);
    else setInsurance(undefined);
  }, [state]);

  const contextValues = {
    states: {
      documents,
      dataLoading,
      totalCount,
      tablePaginationParams,
      selectedDocument,
      loading,
      documentList,
      confirmLoading,
      deleteId,
      progress,
      mode,
      insurance,
    },

    func: { onFinishInsuranceDocumentFile, handleDeleteId },
    dispatches: {
      setTablePaginationParams,
      setSelectedDocument,
      setDocumentList,
    },
    form: { insuranceAttachmentForm },
    requests: { onDeleteAttachment, addFileRequest, downloadAttachFile },
  };

  return (
    <InsuranceAttachmentDataContext.Provider value={contextValues}>
      {children}
      <DeleteConfirmModal
        showConfirm={deleteId > 0}
        onCancel={() => handleDeleteId(0)}
        onOk={() => onDeleteAttachment()}
        title={words.managementInsurance.deleteConfirmTitle}
        okTitle={words.global.delete}
        loading={confirmLoading}
      />
    </InsuranceAttachmentDataContext.Provider>
  );
};

export default InsuranceAttachmentDataProvider;

export const useInsuranceAttachmentData = () =>
  useContext(InsuranceAttachmentDataContext)!;
