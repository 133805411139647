import { FC, useEffect } from "react";
import { Col, Form, Row } from "antd";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import DatePickerUi from "src/components/UiKit/DatePicker";
import { TabMenuKeys } from "src/modules/addEditTour/models";
import { convertDatePicker } from "src/helper";
import LoadingSpin from "src/components/loadingSpin";
import { useTourViewData } from "src/modules/viewTour/context/TourViewData";
import { useDriverInfoRequest } from "./DriverInfoRequest";

const DriverForm: FC = () => {
  const { words } = useLanguage();

  const [driverForm] = Form.useForm();

  const { states } = useTourViewData();
  const {
    states: { dataLoading },
  } = useDriverInfoRequest();

  useEffect(() => {
    if (states.formData && states.current === TabMenuKeys.driver) {
      driverForm.setFieldsValue({
        driverInfo: states.formData.driver?.driverInfo,
        driverPhone: states.formData.driver?.driverPhone,
        driverFatherName: states.formData.driver?.driverFatherName,
        driverNationalCode: states.formData.driver?.driverNationalCode,
        driverIdNumber: states.formData.driver?.driverIdNumber,
        driverBirthDate: convertDatePicker(
          states.formData.driver?.driverBirthDate
        ),
      });
    }
  }, [driverForm, states]);

  return dataLoading ? (
    <LoadingSpin />
  ) : (
    <Form form={driverForm} disabled={true}>
      <Row gutter={[8, 16]} className="team-form-row">
        <Col xs={24} md={12}>
          <Form.Item name={"driverInfo"}>
            <InputUiKit
              label={words.viewTour.firstName.concat(
                "-" + words.viewTour.lastName
              )}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"driverPhone"}>
            <InputUiKit label={words.global.contact} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"driverFatherName"}>
            <InputUiKit label={words.global.fatherName} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"driverNationalCode"}>
            <InputUiKit label={words.global.nationalCode} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"driverBirthDate"}>
            <DatePickerUi label={words.viewTour.birthDate} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"driverIdNumber"}>
            <InputUiKit label={words.viewTour.idNumber} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default DriverForm;
