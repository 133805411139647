import { FC } from "react";
import { Col, Spin } from "antd";
import { ManagementTranslatorContainer } from "./style";
import { useManagementTranslatorData } from "./context";
import ViewTranslator from "./components/ViewTranslator";
import AddEditTranslator from "./components/AddEditTranslator";

const ManagementTranslator: FC = () => {
  const {
    states: { mode, dataLoading },
  } = useManagementTranslatorData();

  return (
    <ManagementTranslatorContainer justify={"center"} gutter={[0, 24]}>
      <Col xs={24} md={mode === "view" ? 16 : 23} className="content">
        {dataLoading ? (
          <div className="flex w-full h-full justify-center items-center">
            <Spin />
          </div>
        ) : mode === "view" ? (
          <ViewTranslator />
        ) : (
          <AddEditTranslator />
        )}
      </Col>
    </ManagementTranslatorContainer>
  );
};

export default ManagementTranslator;
