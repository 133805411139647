import { ROUTES } from "src/models/enums/routes";
import useLanguage from "src/store/language";

type PageTitleKeys = keyof typeof ROUTES;
export type PageTitleKeysType = (typeof ROUTES)[PageTitleKeys];

export const usePageTitle = (path: PageTitleKeysType) => {
  const { words } = useLanguage();
  const pageTitles = {
    [ROUTES.home]: `${words.dashboard.welcome}`,
    [ROUTES.login]: `${words.login.action}`,
    [ROUTES.addTour]: `${words.addTour.pageTitle}`,
    [ROUTES.tourTable]: `${words.sidebarItems.tourTable}`,
    [ROUTES.tourView]: `${words.viewTour.title}`,
    [ROUTES.tourAdd]: `${words.addEditTour.addTitle}`,
    [ROUTES.tourEdit]: `${words.addEditTour.editTitle}`,
    [ROUTES.tourEditChinese]: `${words.sidebarItems.addTour}`,
    [ROUTES.settings]: `${words.systemSettings.pageTitle}`,
    [ROUTES.CreateRole]: `${words.sidebarItems.createRole}`,
    [ROUTES.AssignActionToRole]: `${words.sidebarItems.assignActionToRole}`,
    [ROUTES.CreateUser]: `${words.sidebarItems.createUser}`,
    [ROUTES.AssignRoleToUser]: `${words.sidebarItems.assignRoleToUser}`,
    [ROUTES.basicInfo]: `${words.sidebarItems.basicInfo}`,
    [ROUTES.country]: `${words.sidebarItems.country}`,
    [ROUTES.city]: `${words.sidebarItems.city}`,
    [ROUTES.hotel]: `${words.sidebarItems.hotel}`,
    [ROUTES.places]: `${words.sidebarItems.places}`,
    [ROUTES.car]: `${words.sidebarItems.car}`,
    [ROUTES.tourManagementHotel]: `${words.sidebarItems.tourTable}`,
    [ROUTES.managementTour]: `${words.managementTour.pageTitle}`,
    [ROUTES.tourManagementTouristInfo]: `${words.managementTour.pageTitle}`,
    [ROUTES.viewTouristInfo]: `${words.managementTour.pageTitle}`,
    [ROUTES.tourManagementRoute]: `${words.managementTour.pageTitle}`,
    [ROUTES.tourManagementTime]: `${words.managementTour.pageTitle}`,
    [ROUTES.tourManagementBasicInfo]: `${words.managementTour.pageTitle}`,
    [ROUTES.domesticFlight]: `${words.managementTour.pageTitle}`,
    [ROUTES.viewDomesticFlight]: `${words.managementTour.pageTitle}`,
    [ROUTES.tourManagementSimcard]: `${words.managementTour.pageTitle}`,
    [ROUTES.tourManagementInsurance]: `${words.managementTour.pageTitle}`,
    [ROUTES.tourManagementDriver]: `${words.managementTour.pageTitle}`,
    [ROUTES.tourManagementTranslator]: `${words.managementTour.pageTitle}`,
    [ROUTES.tourManagementSupervisor]: `${words.managementTour.pageTitle}`,
    [ROUTES.notification]: `${words.managementTour.pageTitle}`,
    [ROUTES.viewNotification]: `${words.managementTour.pageTitle}`,
    [ROUTES.tourManagementFiles]: `${words.managementTour.pageTitle}`,
    [ROUTES.rooms]: `${words.sidebarItems.rooms}`,
    [ROUTES.broker]: `${words.sidebarItems.brokers}`,
    [ROUTES.tourManagementCompanionLeader]: `${words.managementTour.pageTitle}`,
    [ROUTES.tourManagementLeader]: `${words.managementTour.pageTitle}`,
    [ROUTES.tourManagementAssignSimcard]: `${words.managementTour.pageTitle}`,
    [ROUTES.managementTourReport]: `${words.managementTour.pageTitle}`,
    [ROUTES.routeRoom]: `${words.managementTour.pageTitle}`,
    [ROUTES.routePlace]: `${words.managementTour.pageTitle}`,
    [ROUTES.insuranceAttachment]: `${words.managementTour.pageTitle}`,
  };

  return pageTitles[
    Object.keys(pageTitles).find((key) =>
      key.replace(/[^a-zA-Z]+/g, "").includes(path.replace(/[^a-zA-Z]+/g, ""))
    ) as PageTitleKeysType
  ];
};
