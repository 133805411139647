import RouteHotels from "src/modules/managementRouteRoom";
import RouteHotelsDataProvider from "src/modules/managementRouteRoom/context";

const ManagementRouteRoomPage = () => (
  <RouteHotelsDataProvider>
    <RouteHotels />
  </RouteHotelsDataProvider>
);

export default ManagementRouteRoomPage;
