import { FC, useCallback, useEffect, useState } from "react";
import { HotelLetterReservationListContainer } from "./style";
import { Button, Col, Flex, Form, Row, Table } from "antd";
import { useForm } from "antd/es/form/Form";
import { FormItem } from "src/components/UiKit/FormItem";
import InputUiKit from "src/components/UiKit/Input";
import SubmitButton from "src/components/UiKit/SubmitButton";
import TextArea from "antd/es/input/TextArea";
import { getRowKey } from "src/helper/getRowKey";
import { useParams } from "react-router-dom";
import moment from "moment";
import momentJalaly from "moment-jalaali";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import { IManagementRoute } from "src/services/TourManagement/models";
import { IPagination } from "src/models/interfaces/pagination";
import { useHotelLetter } from "./context";
import DatePickerUi from "src/components/UiKit/DatePicker";

export const HotelLetterReservationList: FC = () => {
  const [form] = useForm();
  const {
    values: { columns, selectedRoute },
    dispatches: { setSelectedRoute },
    func: { onFinish },
  } = useHotelLetter();
  const [data, setData] = useState<IManagementRoute[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { tourId } = useParams();
  const [pagination, setPagination] = useState<IPagination>({
    Limit: 10,
    Offset: 1,
  });
  const resetForm = () => {
    form.resetFields();
    setSelectedRoute(undefined);
  };

  const fetchData = useCallback(async () => {
    if (!tourId) return;
    try {
      setLoading(true);
      const { AllManagementRoute } = new TourManagementService();
      const search = new URLSearchParams(pagination);

      const res = await AllManagementRoute(tourId, search.toString());
      if (res && res.status === 200 && res.data) {
        setData(res.data.records);
        setPagination((prev) => ({ ...prev, total: res.data.count }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourId, pagination.Limit, pagination.Offset]);

  const tableData = data?.map((item) => {
    const newItem = { ...item };
    newItem.departureDate = item.departureDate
      ? moment(item.departureDate)
          .format("YYYY/MM/DD")
          .concat(`\n`)
          .concat(momentJalaly(item.departureDate).format("jYYYY/jMM/jDD"))
      : "";
    newItem.arraivalDate = item.arraivalDate
      ? moment(item.arraivalDate)
          .format("YYYY/MM/DD")
          .concat(`\n`)
          .concat(momentJalaly(item.arraivalDate).format("jYYYY/jMM/jDD"))
      : "";

    return newItem;
  });

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Flex justify="center">
      <HotelLetterReservationListContainer>
        <Col span={24} className="pt-[24px] pb-[16px]">
          <div className="text-[#233069] text-[16px]">
            ابتدا مسیر مورد نظر را از جدول زیر انتخاب کنید.
          </div>
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={tableData}
            scroll={{ x: "auto" }}
            loading={loading}
            pagination={{
              total: pagination.total,
              current: pagination.Offset,
              pageSize: pagination.Limit,
              onChange: (page, pageSize) => {
                setPagination((prev) => ({
                  ...prev,
                  Offset: page,
                  Limit: pageSize,
                }));
              },
              position: ["bottomCenter"],
            }}
            className="pt-[4px] pb-[32px] "
            rowKey={getRowKey}
            rowSelection={{
              renderCell: () => null,
              checkStrictly: false,
              hideSelectAll: true,
              selectedRowKeys: selectedRoute ? [selectedRoute.id] : [],
              columnWidth: 0,
            }}
          />
        </Col>
        <Col span={24}>
          <div className="text-[#233069] text-[16px]">
            لطفا اطلاعات زیر را وارد کنید.
          </div>
        </Col>
        <Col span={24}>
          <Form form={form} onFinish={onFinish}>
            <Row gutter={24}>
              <Col span={8}>
                <FormItem name={"signatory"} label="نام امضا کننده">
                  <InputUiKit placeholder="بنویسید" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem name={"date"} label="تاریخ">
                  <DatePickerUi />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem name={"reservation"} label="نام رزرواسیون">
                  <InputUiKit placeholder="بنویسید" />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <FormItem name={"coordinationPerson"} label="شخص جهت هماهنگی">
                  <InputUiKit placeholder="بنویسید" />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem name={"phoneNumber"} label="شماره تماس">
                  <InputUiKit placeholder="بنویسید" />
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormItem name={"description"} label="توضیحات">
                  <TextArea
                    placeholder="توضیحات"
                    className="textarea"
                    rows={5}
                  />
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={24}>
          <div className="flex gap-[16px] justify-end footer">
            <Button
              type="default"
              className="min-w-[110px] text-[#4B4B4B] cursor-pointer"
              onClick={resetForm}
            >
              پاک سازی
            </Button>
            <SubmitButton
              form={form}
              loading={false}
              onClick={form.submit}
              canSubmit={!selectedRoute}
            >
              پیش نمایش
            </SubmitButton>
          </div>
        </Col>
      </HotelLetterReservationListContainer>
    </Flex>
  );
};
