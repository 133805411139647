import { create } from "zustand";
import { USER_JWT_TOKEN } from "src/constants";

interface IStore {
  token?: string;
  userRole?: string[];
  login: (token: string) => void;
  setUserRole: (roles: string[]) => void;
  logout: () => void;
}

const useAuthStore = create<IStore>((set) => ({
  token: localStorage.getItem(USER_JWT_TOKEN) || undefined,
  login: (token) => set(() => ({ token })),
  logout: () => set({ token: undefined }),
  setUserRole: (roles) => set(() => ({ userRole: roles })),
}));

export default useAuthStore;
