import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import { createSearchParams, useLocation, useParams } from "react-router-dom";
import { TourService } from "src/services/Tour/Tour.service";
import { IAttachFile } from "src/services/Tour/models";
import { IPagination } from "src/models/interfaces/pagination";
import { convertObj } from "src/helper";
import { reactQueryEndPoints } from "src/constants";
import { useQuery } from "react-query";
import { AxiosRequestConfig } from "axios";
import downloadZipFile from "src/helper/downloadZipFile";

interface IContextValue {
  states: {
    dataLoading: boolean;
    files: IAttachFile[];
    totalCount: number | undefined;
    pagination: IPagination;
    progress: number;
  };
  dispatches: {
    setPagination: Dispatch<
      SetStateAction<Pick<IPagination, "Offset" | "Limit">>
    >;
  };
  requests: {
    downloadAttachFile: (attachTourId: number) => void;
  };
}

export const AttachmentRequestContext = createContext<
  IContextValue | undefined
>(undefined);

const AttachmentRequestProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [progress, setProgress] = useState<number>(0);
  const [pagination, setPagination] = useState<IPagination>({
    Limit: 10,
    Offset: 1,
  });

  const { tourId } = useParams();
  const location = useLocation();

  const downloadAttachFile = async (attachTourId: number) => {
    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 0)
        );
        setProgress(percentage);
        if (percentage === 100) {
          setTimeout(() => setProgress(0), 400);
        }
      },
    };
    try {
      const { DownloadAttachFile } = new TourService();

      const result = await DownloadAttachFile(attachTourId, config);
      if (result) {
        downloadZipFile(result);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProgress(0);
    }
  };
  const geAttachFile = useCallback(async () => {
    if (!tourId) return;
    let querySearch = `${location.search}${
      location.search ? "&" : "?"
    }${createSearchParams(convertObj(pagination))}`;
    try {
      const { GetAttachFile } = new TourService();

      const result = await GetAttachFile(tourId, querySearch);
      if (result && result.status === 200) {
        const { data } = result;
        return data;
      }
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, isFetching, refetch } = useQuery(
    reactQueryEndPoints.toursList,
    geAttachFile,
    { enabled: false }
  );

  useEffect(() => {
    if (tourId) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, tourId]);

  const contextValues = {
    states: {
      dataLoading: isFetching,
      files: data?.records || [],
      totalCount: data?.count,
      pagination,
      progress,
    },
    dispatches: { setPagination },
    requests: { downloadAttachFile },
  };

  return (
    <AttachmentRequestContext.Provider value={contextValues}>
      {children}
    </AttachmentRequestContext.Provider>
  );
};

export default AttachmentRequestProvider;

export const useAttachmentRequest = () => useContext(AttachmentRequestContext)!;
