import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import { TourService } from "src/services/Tour/Tour.service";
import { useTourViewData } from "../../context/TourViewData";

interface IContextValue {
  states: {
    dataLoadings: {
      tour: boolean;
      tourInfo: boolean;
    };
  };
}

export const PrintTourRequestContext = createContext<IContextValue | undefined>(
  undefined
);

const PrintTourRequestProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [dataLoadings, setDataLoadings] = useState<{
    tour: boolean;
    tourInfo: boolean;
  }>({
    tour: false,
    tourInfo: false,
  });

  const {
    states: { formData, viewMode },
    dispatches,
  } = useTourViewData();
  const { tourId } = useParams();

  const getTourInfo = useCallback(async () => {
    if (!tourId) return;

    try {
      setDataLoadings((dataLoadings) => ({ ...dataLoadings, tourInfo: true }));
      const { TourInfo } = new TourService();

      const result = await TourInfo(tourId);
      if (result && result.status === 200) {
        dispatches.setFormData((prev) => ({
          ...prev,
          tourInfo: result.data,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoadings((dataLoadings) => ({ ...dataLoadings, tourInfo: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTourPlanInfo = useCallback(async () => {
    if (!tourId) return;

    try {
      setDataLoadings((dataLoadings) => ({ ...dataLoadings, tour: true }));
      const { GetTourInfo } = new TourService();

      const result = await GetTourInfo(tourId);
      if (result && result.status === 200) {
        dispatches.setFormData((prev) => ({
          ...prev,
          tour: result.data,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoadings((dataLoadings) => ({ ...dataLoadings, tour: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tourId && viewMode === "print-tour") {
      !formData?.tour && getTourPlanInfo();
      !formData?.tourInfo && getTourInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTourPlanInfo, getTourInfo, tourId, viewMode]);

  const contextValues = {
    states: { dataLoadings },
  };

  return (
    <PrintTourRequestContext.Provider value={contextValues}>
      {children}
    </PrintTourRequestContext.Provider>
  );
};

export default PrintTourRequestProvider;

export const usePrintTourRequest = () => useContext(PrintTourRequestContext)!;
