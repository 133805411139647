import { Form, FormInstance, message } from "antd";
import {
  FC,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { BasicInformationService } from "src/services/BasicInformation/BasicInformation.service";
import { IBroker, ICountry } from "src/services/BasicInformation/models";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import { INewTourArg } from "src/services/TourManagement/models";
import useLanguage from "src/store/language";

export type AttachType = "excel" | "zip" | "pdf";
interface IContextValue {
  states: {
    loading: boolean;
    addTourForm: FormInstance;
    countries: ICountry[];
    countryLoading: boolean;
    brokerProps: IBrokerProps;
  };
  func: {
    onFinish: (value: INewTourArg) => void;
  };
  requests: {};
}

interface IBrokerProps {
  brokers: IBroker[];
  loading: boolean;
}

export const AddTourDataContext = createContext<IContextValue | undefined>(
  undefined
);

const AddTourDataProvider: FC<{ children: ReactElement | ReactElement[] }> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [countryLoading, setCountryLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [brokerProps, setBrokerProps] = useState<IBrokerProps>({
    brokers: [],
    loading: false,
  });

  const [addTourForm] = Form.useForm();

  const { words } = useLanguage();

  const onFinish = (values: INewTourArg) => {
    tourAddReq(values);
  };

  const tourAddReq = async (values: INewTourArg) => {
    setLoading(true);
    try {
      const { AddTour } = new TourManagementService();
      const result = await AddTour(values);
      if (result && result.status === 200) {
        message.success(words.addTour.addSuccessMessage);
        addTourForm.resetFields();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getBrokers = useCallback(async () => {
    setBrokerProps((prev) => ({ ...prev, loading: true }));
    try {
      const { Brokers } = new BasicInformationService();
      const res = await Brokers();
      if (res && res.status === 200 && res.data) {
        setBrokerProps((prev) => ({ ...prev, brokers: res.data.records }));
      } else {
        setBrokerProps((prev) => ({ ...prev, brokers: [] }));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setBrokerProps((prev) => ({ ...prev, loading: false }));
    }
  }, []);

  const getCountries = useCallback(async () => {
    setCountryLoading(true);
    try {
      const { Countries } = new BasicInformationService();
      const res = await Countries();
      if (res && res.status === 200 && res.data) {
        setCountries(res.data.records);
      } else {
        setCountries([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setCountryLoading(false);
    }
  }, []);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  useEffect(() => {
    getBrokers();
  }, [getBrokers]);

  const contextValues = {
    states: {
      loading,
      addTourForm,
      countries,
      countryLoading,
      brokerProps,
    },
    func: { onFinish },
    requests: {},
  };

  return (
    <AddTourDataContext.Provider value={contextValues}>
      {children}
    </AddTourDataContext.Provider>
  );
};

export default AddTourDataProvider;

export const useAddTourData = () => useContext(AddTourDataContext)!;
