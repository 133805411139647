import { Form, FormInstance, message } from "antd";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { createSearchParams, useLocation } from "react-router-dom";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";
import { IPagination } from "src/models/interfaces/pagination";
import { BasicInformationService } from "src/services/BasicInformation/BasicInformation.service";
import { ICar, INewCarArg } from "src/services/BasicInformation/models";
import useLanguage from "src/store/language";

interface IContext {
  cars: ICar[];
  editCar: ICar | undefined;
  createCarForm: FormInstance;
  deleteId: number;
  loadings: ILoadings;
  totalCount: number;
  tablePaginationParams: Pick<IPagination, "Offset" | "Limit">;
  onFinish: (values: INewCarArg) => void;
  setEditCar: (city: ICar | undefined) => void;
  onDeleteCar: () => void;
  handleDeleteId: (id: number) => void;
  setTablePaginationParams: Dispatch<
    SetStateAction<Pick<IPagination, "Offset" | "Limit">>
  >;
}
interface ILoadings {
  table: boolean;
  confirm: boolean;
  addEdit: boolean;
}

export const CarDataCTX = createContext<IContext | undefined>(undefined);

export const CarProvider: FC<PropsWithChildren> = ({ children }) => {
  const [cars, setCars] = useState<ICar[]>([]);
  const [editCar, setEditCar] = useState<ICar | undefined>(undefined);
  const [loadings, setLoadings] = useState<ILoadings>({
    table: false,
    confirm: false,
    addEdit: false,
  });
  const [totalCount, setTotalCount] = useState<number>(0);
  const [tablePaginationParams, setTablePaginationParams] =
    useState<IPagination>({
      Offset: 1,
      Limit: 10,
    });
  const [deleteId, setDeleteId] = useState<number>(0);
  const [createCarForm] = Form.useForm();

  const location = useLocation();

  const { words } = useLanguage();

  const onFinish = (values: INewCarArg) => {
    if (editCar) carEditREq(values);
    else carAddReq(values);
  };

  const carAddReq = async (values: INewCarArg) => {
    setLoadings({ ...loadings, addEdit: true });
    try {
      const { NewCar } = new BasicInformationService();
      const result = await NewCar(values);
      if (result && result.status === 200) {
        createCarForm.resetFields();
        getCars();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings({ ...loadings, addEdit: false });
    }
  };
  const carEditREq = async (values: INewCarArg) => {
    if (!editCar) return;
    setLoadings({ ...loadings, addEdit: true });
    try {
      const { EditCar } = new BasicInformationService();
      const result = await EditCar(editCar.id, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setEditCar(undefined);
        getCars();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings({ ...loadings, addEdit: false });
    }
  };
  const handleDeleteId = (id: number) => {
    setDeleteId(id);
  };

  const onDeleteCar = async () => {
    if (deleteId === 0) return;
    setLoadings({ ...loadings, confirm: true });
    try {
      const { DeleteCar } = new BasicInformationService();
      const result = await DeleteCar(deleteId);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        if (editCar && editCar.id === deleteId) setEditCar(undefined);
        setDeleteId(0);
        getCars();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadings({ ...loadings, confirm: false });
    }
  };

  const getCars = useCallback(async () => {
    setLoadings({ ...loadings, table: true });
    try {
      const query = `${location.search}${
        location.search ? "&" : "?"
      }${createSearchParams(tablePaginationParams).toString()}`;
      const { Cars } = new BasicInformationService();
      const res = await Cars(query);
      if (res && res.status === 200 && res.data) {
        setCars(res.data.records);
        setTotalCount(res.data.count);
      } else {
        setCars([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadings({ ...loadings, table: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCars();
  }, [getCars]);

  useEffect(() => {
    if (editCar) {
      createCarForm?.setFieldsValue({
        carName: editCar.carName,
      });
    } else createCarForm?.resetFields();
  }, [createCarForm, editCar]);

  const contextValue: IContext = {
    cars,
    editCar,
    createCarForm,
    loadings,
    onFinish,
    handleDeleteId,
    setEditCar,
    onDeleteCar,
    deleteId,
    setTablePaginationParams,
    totalCount,
    tablePaginationParams,
  };
  return (
    <CarDataCTX.Provider value={contextValue}>
      {children}
      <DeleteConfirmModal
        showConfirm={deleteId > 0}
        onCancel={() => handleDeleteId(0)}
        onOk={() => onDeleteCar()}
        title={words.basicInfo.deleteConfirmTitle}
        okTitle={words.global.delete}
        loading={loadings.confirm}
      />
    </CarDataCTX.Provider>
  );
};
export const useCarDataContext = () => useContext(CarDataCTX)!;
