import { FC } from "react";
import { Col, Menu, MenuProps, Row } from "antd";
import useLanguage from "src/store/language";
import { FilterContainer } from "../styles";
import { useTourAddEditData } from "../context/TourAddEditData";
import { TabMenuKeys } from "../models/enums/tabMenuKeys";
import { useLocation } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";

const Filter: FC = () => {
  const { words } = useLanguage();

  const { states, dispatches } = useTourAddEditData();

  const location = useLocation();

  const items: MenuProps["items"] = [
    {
      label: `${words.viewTour.touristInfo}`,
      key: TabMenuKeys.tourist,
      disabled:
        states.currentFilter === TabMenuKeys.tourist ||
        location.pathname.includes(ROUTES.tourEdit)
          ? false
          : true,
    },
    {
      label: `${words.viewTour.teamInfo}`,
      key: TabMenuKeys.team,
      disabled:
        states.currentFilter === TabMenuKeys.team ||
        location.pathname.includes(ROUTES.tourEdit)
          ? false
          : true,
    },
    {
      label: `${words.viewTour.tourInfo}`,
      key: TabMenuKeys.tour,
      disabled:
        states.currentFilter === TabMenuKeys.tour ||
        location.pathname.includes(ROUTES.tourEdit)
          ? false
          : true,
    },
    {
      label: `${words.viewTour.carInsurance}`,
      key: TabMenuKeys.insurance,
      disabled:
        states.currentFilter === TabMenuKeys.insurance ||
        location.pathname.includes(ROUTES.tourEdit)
          ? false
          : true,
    },
    {
      label: `${words.viewTour.attachments}`,
      key: TabMenuKeys.attachment,
      disabled:
        states.currentFilter === TabMenuKeys.attachment ||
        location.pathname.includes(ROUTES.tourEdit)
          ? false
          : true,
    },
  ];
  const onClick: MenuProps["onClick"] = (e) => {
    dispatches.setCurrentFilter(e.key);
  };
  return (
    <FilterContainer>
      <Row>
        <Col xs={20}>
          <Menu
            onClick={onClick}
            selectedKeys={[states.currentFilter]}
            mode="horizontal"
            items={items}
          />
        </Col>
        <Col xs={4}></Col>
      </Row>
    </FilterContainer>
  );
};

export default Filter;
