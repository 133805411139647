import { CSSProperties, FC, useEffect, useState } from "react";
import { Menu, MenuProps, Dropdown, Avatar, Popover, Divider } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { useLogout } from "src/hook";
import { USER_DATA } from "src/constants";
import useLanguage from "src/store/language";
import useGlobalStore from "src/store/global";
import { EditProfile } from "src/modules/editProfile";
import { ChangePassword } from "src/modules/changePassword";
import { sidebarWidth } from "../constant/sidebarWidth";
import { useCurrentPath } from "../hook/useCurrentPath";
import { SidebarContainer } from "../styles/sidebar";
import { useSidebarItems } from "../constant/sidebarItems";

const Sidebar: FC = () => {
  let location = useLocation();
  const [userRole, setUserRole] = useState<string[]>([]);
  const [showEditProfile, setShowEditProfile] = useState<boolean>(false);
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  const items = useSidebarItems();
  const currentPath = useCurrentPath(location.pathname);
  const { userInfo } = useGlobalStore();

  useEffect(() => {
    const userRoleInLocal = localStorage.getItem(USER_DATA);
    if (userRoleInLocal) {
      setUserRole(JSON.parse(userRoleInLocal));
    }
  }, []);
  const { logoutHandler } = useLogout();
  const editProfileModalHandler = () => {
    setShowEditProfile(true);
  };
  const changePasswordModalHandler = () => {
    setShowChangePassword(true);
  };
  const { words } = useLanguage();
  const itemStyle: CSSProperties = {
    display: "flex",
    gap: 5,
    paddingInline: 10,
    alignItems: "center",
    justifyContent: "flex-start",
  };
  const itemsDropdown: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div style={itemStyle} onClick={editProfileModalHandler}>
          <span className="material-icons w-[24px] h-[24px] text-[#A3AED0]">
            person
          </span>
          <div>{words.mainLayout.editProfile}</div>
        </div>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "2",
      label: (
        <div style={itemStyle} onClick={changePasswordModalHandler}>
          <span className="material-icons w-[24px] h-[24px] text-[#A3AED0] items-center">
            lock
          </span>
          {words.mainLayout.changePassword}
        </div>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "3",
      label: (
        <div onClick={logoutHandler} style={itemStyle}>
          <span className="material-icons w-[24px] h-[24px] !text-[#A3AED0]">
            logout
          </span>
          {words.mainLayout.logout}
        </div>
      ),
    },
  ];

  return (
    <SidebarContainer width={sidebarWidth}>
      <div className="sidebarAvatar">
        <Dropdown
          menu={{ items: itemsDropdown }}
          trigger={["click"]}
          placement="bottomLeft"
        >
          <div className="avatar cursor-pointer ">
            <div></div>
            <Avatar size={59} icon={<UserOutlined />} />
            <div className="flex justify-between items-center gap-[32px] ">
              <div className="ps-3">
                <div className="username">
                  {userInfo?.firstName} {userInfo?.lastName}
                </div>
                {userRole && userRole.join(" , ").length > 30 ? (
                  <Popover title={userRole.join(" , ")}>
                    {userRole.join(" , ").substring(0, 30).concat("...")}
                  </Popover>
                ) : (
                  <div className="userRole">{userRole?.join(" , ")}</div>
                )}
              </div>
              <span className="material-icons text-[#A3AED0]">
                keyboard_arrow_down
              </span>
            </div>
          </div>
        </Dropdown>
      </div>
      <Divider />
      <div>
        <Menu
          defaultSelectedKeys={["dashboard"]}
          mode="inline"
          items={items}
          className="menu"
          selectedKeys={[currentPath]}
        />
      </div>

      <EditProfile
        isModalOpen={showEditProfile}
        setIsModalOpen={setShowEditProfile}
      />
      <ChangePassword
        isModalOpen={showChangePassword}
        setIsModalOpen={setShowChangePassword}
      />
    </SidebarContainer>
  );
};

export default Sidebar;
