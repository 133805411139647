import { FC } from "react";
import LeaderForm from "./LeaderForm";
import LeaderInfoRequestProvider from "./LeaderInfoRequest";

const LeaderTab: FC = () => {
  return (
    <LeaderInfoRequestProvider>
      <LeaderForm />
    </LeaderInfoRequestProvider>
  );
};

export default LeaderTab;
