import ManagementCompanionLeader from "src/modules/managementCompanionLeader";
import ManagementCompanionLeaderDataProvider from "src/modules/managementCompanionLeader/context";

const ManagementCompanionLeaderPage = () => (
  <ManagementCompanionLeaderDataProvider>
    <ManagementCompanionLeader />
  </ManagementCompanionLeaderDataProvider>
);

export default ManagementCompanionLeaderPage;
