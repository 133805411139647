import { FC } from "react";
import { TableUI } from "src/components/UiKit/table";
import { ColumnsType } from "antd/es/table";
import useLanguage from "src/store/language";
import { convertDate } from "src/helper";
import TableActions from "./TableActions";
import { IInsuranceTableData, useAddEditInsuranceData } from "../context";
import { useManagementInsuranceData } from "src/modules/managementInsurance/context";

const InsuranceTable: FC = () => {
  const {
    values: {
      insuranceInfo,
      dataLoading,
      totalCount,
      tablePaginationParams,
      selectedInsurance,
    },
    dispatches: { setTablePaginationParams },
  } = useAddEditInsuranceData();
  const {
    states: { mode },
  } = useManagementInsuranceData();

  const { words } = useLanguage();

  const tableColumns: ColumnsType<IInsuranceTableData> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      width: 62,
      render: (text, record, index) =>
        (tablePaginationParams.Offset - 1) * tablePaginationParams.Limit +
        index +
        1,
    },
    {
      title: `${words.managementInsurance.insuranceTypeLabel}`,
      dataIndex: "type",
      key: "type",
    },
    {
      title: `${words.managementInsurance.insuranceCompanyLabel}`,
      dataIndex: "company",
      key: "company",
      render: (value) => (value && value !== "" ? value : "-"),
    },
    {
      title: `${words.managementInsurance.dateLabel}`,
      dataIndex: "date",
      key: "date",
      render: (value) => (value && value !== "" ? convertDate(value) : "-"),
    },

    {
      title: `${words.managementInsurance.insurancePriceLabel}`,
      dataIndex: "amount",
      key: "amount",
      render: (value) => (value ? Number(value).toLocaleString() : "-"),
    },
    {
      title: `${words.basicInfo.tableActions}`,
      dataIndex: "tools",
      key: "tools",
      width: 345,
      render: (_: any, record: IInsuranceTableData) => (
        <TableActions item={record} />
      ),
    },
  ];

  return (
    <>
      <p
        className={`text-[14px] text-[#1F1F1F] ${
          mode === "view" ? "!text-[#233069] !font-bold !text-[16px]" : ""
        }`}
      >
        {words.managementInsurance.insuranceTableTitle}
      </p>
      <TableUI
        columns={tableColumns}
        dataSource={insuranceInfo}
        loading={dataLoading}
        scroll={{ x: 500 }}
        tableType="secondary"
        rowClassName={(record) =>
          record.id === selectedInsurance?.id ? "selected-row-for-edit" : ""
        }
        pagination={{
          position: ["bottomCenter"],
          total: totalCount,
          pageSize: tablePaginationParams.Limit,
          current: tablePaginationParams.Offset,
          onChange(page, pageSize) {
            setTablePaginationParams((prev) => ({
              ...prev,
              Offset: page,
              Limit: pageSize,
            }));
          },
        }}
      />
    </>
  );
};

export default InsuranceTable;
