import { Form, FormInstance, message } from "antd";
import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import { INewBuySimcardArg } from "src/services/TourManagement/models";
import useLanguage from "src/store/language";
import { useManagementSimcardData } from "../../context";
import dayjs from "dayjs";
import { convertDateTimeIran } from "src/helper";

interface IContextValue {
  values: {
    loading: boolean;
    deleteLoading: boolean;
  };
  func: {
    onFinishBuySimcard: (values: INewBuySimcardArg) => void;
  };
  request: { onDeleteSimcard: () => Promise<void> };
  form: { buyForm: FormInstance };
}

export const AddEditSimcardDataContext = createContext<
  IContextValue | undefined
>(undefined);

const AddEditSimcardDataProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const [buyForm] = Form.useForm();

  const { words } = useLanguage();
  const { tourId } = useParams();
  const navigate = useNavigate();

  const {
    states: { buySimcard },
    dispatches: { setBuySimcard, setMode },
  } = useManagementSimcardData();

  const onFinishBuySimcard = (values: INewBuySimcardArg) => {
    if (values.payDate) values.payDate = convertDateTimeIran(values.payDate);
    if (values.amount) values.amount = values.amount.toString();
    if (buySimcard) editBuySimcardReq(values);
    else addBuySimcardReq(values);
  };

  const addBuySimcardReq = async (values: INewBuySimcardArg) => {
    if (!tourId) return;
    setLoading(true);
    try {
      const { ManagementBuySimcard } = new TourManagementService();
      const result = await ManagementBuySimcard(+tourId, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage).then(() => navigate(-1));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const editBuySimcardReq = async (values: INewBuySimcardArg) => {
    if (!buySimcard) return;
    setLoading(true);
    try {
      const { EditBuySimcard } = new TourManagementService();
      const result = await EditBuySimcard(buySimcard.id, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage).then(() => navigate(-1));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onDeleteSimcard = async () => {
    if (!buySimcard) return;
    setDeleteLoading(true);
    try {
      const { DeleteBuySimcard } = new TourManagementService();
      const result = await DeleteBuySimcard(buySimcard.id);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setBuySimcard(undefined);
        setMode("add");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    if (buySimcard) {
      buyForm.setFieldsValue({
        count: buySimcard.count,
        amount: buySimcard.amount.toLocaleString(),
        payDate: buySimcard.payDate ? dayjs(buySimcard.payDate) : undefined,
        exporter: buySimcard.exporter,
        exporterPhone: buySimcard.exporterPhone,
      });
    } else buyForm.resetFields();
  }, [buySimcard, buyForm]);

  const contextValues = {
    values: {
      loading,
      deleteLoading,
    },
    func: { onFinishBuySimcard },
    request: { onDeleteSimcard },
    form: { buyForm },
  };

  return (
    <AddEditSimcardDataContext.Provider value={contextValues}>
      {children}
    </AddEditSimcardDataContext.Provider>
  );
};

export default AddEditSimcardDataProvider;

export const useAddEditSimcardData = () =>
  useContext(AddEditSimcardDataContext)!;
