import { FC } from "react";
import { ColumnsType } from "antd/es/table";
import { Col } from "antd";
import { TableUI } from "src/components/UiKit/table";
import useLanguage from "src/store/language";
import { ITableTourist } from "src/services/TourManagement/models";
import { Gender } from "src/models/enums/gender";
import { convertDateMiladi } from "src/helper";
import { useAddTouristInfo } from "../context";

export const TouristTableView: FC = () => {
  const { words } = useLanguage();

  const {
    value: {
      totalCount,
      mode,
      tablePaginationParams,
      tourists,
      dataLoading,
      selectedTourist,
    },
    dispatch: { setTablePaginationParams },
  } = useAddTouristInfo();

  const columns: ColumnsType<ITableTourist> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: 62,
      fixed: "left",
      render: (text, record, index) =>
        (tablePaginationParams.Offset - 1) * tablePaginationParams.Limit +
        index +
        1,
    },
    {
      title: words.addTouristInfo.passportNO,
      dataIndex: "passportNO",
      key: "passportNO",
      align: "center",
      width: 140,
      fixed: "left",
    },
    {
      title: words.addTouristInfo.name,
      dataIndex: "firstName",
      key: "firstName",
      align: "center",
    },
    {
      title: words.addTouristInfo.lastName,
      dataIndex: "lastName",
      key: "lastName",
      align: "center",
    },
    {
      title: words.addTouristInfo.gender,
      dataIndex: "gender",
      key: "gender",
      align: "center",
      width: 76,
      render: (value) =>
        value
          ? Gender.Male === value
            ? words.global.male
            : Gender.Female === value
            ? words.global.femail
            : "-"
          : "-",
    },
    {
      title: words.addTouristInfo.nationLabel,
      dataIndex: "country",
      key: "country",
      align: "center",
    },
    {
      title: words.addTouristInfo.jobLabel,
      dataIndex: "job",
      key: "job",
      align: "center",
    },
    {
      title: words.addTouristInfo.birthPlaceLabel,
      dataIndex: "birthPlace",
      key: "birthPlace",
      align: "center",
    },
    {
      title: words.addTouristInfo.tableBirthday,
      dataIndex: "birthDate",
      key: "birthDate",
      align: "center",
      width: 110,
      render: (value) => (value ? convertDateMiladi(value) : "-"),
    },
    {
      title: words.addTouristInfo.TableExpireDate,
      dataIndex: "expireDate",
      key: "expireDate",
      align: "center",
      width: 110,
      render: (value) => (value ? convertDateMiladi(value) : "-"),
    },

    // {
    //   title: words.addTouristInfo.operation,
    //   dataIndex: "operation",
    //   key: "operation",
    //   width: 130,
    //   render: (_: any, record: ITableTourist) => (
    //     <div className="flex justify-center items-center gap-[16px]">
    //       {/* <div
    //               className="edit-action"
    //               onClick={() => setSelectedTourist(record)}
    //             >
    //               <span className="material-icons ">edit_note</span>
    //               <span>{words.global.editAction}</span>
    //             </div> */}
    //       <span
    //         onClick={() => setSelectedTourist(record)}
    //         className="material-icons text-[#0C9D61] text-[16px] cursor-pointer"
    //       >
    //         edit_note
    //       </span>
    //       {/* <div
    //               className="delete-action"
    //               onClick={() => handleDelete(record)}
    //             >

    //               <span>{words.global.delete}</span>
    //             </div> */}
    //       <span
    //         onClick={() => handleDelete(record)}
    //         className="material-icons text-[#ec2d30] text-[16px] cursor-pointer"
    //       >
    //         delete_forever
    //       </span>
    //     </div>
    //   ),
    // },
  ];
  return (
    <Col span={24}>
      <p className="m-0 text-[14px] text-[#233069] pt-[12px] font-bold">
        {words.addTouristInfo.tableTitle}
      </p>
      <TableUI
        tableType="secondary"
        columns={columns}
        loading={dataLoading}
        dataSource={tourists}
        scroll={{ x: mode === "view" ? 850 : 1100 }}
        rowClassName={(record) =>
          record.id === selectedTourist?.id ? "selected-row-for-edit" : ""
        }
        pagination={{
          position: ["bottomCenter"],
          total: totalCount,
          pageSize: tablePaginationParams.Limit,
          current: tablePaginationParams.Offset,
          onChange(page, pageSize) {
            setTablePaginationParams((prev) => ({
              ...prev,
              Offset: page,
              Limit: pageSize,
            }));
          },
        }}
      />

      {/* <DeleteConfirmModal
        showConfirm={deleteItem ? true : false}
        onCancel={() => handleDelete()}
        onOk={() => onDeleteTourist()}
        title={words.addTouristInfo.deleteTourist}
        okTitle={words.global.delete}
        loading={confirmLoading}
      /> */}
    </Col>
  );
};
