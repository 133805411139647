import { FC, ReactNode } from "react";
import useLanguage from "src/store/language";
import { PageHeaderContainer } from "./style";
interface props {
  title?: string | ReactNode;
  mode: string;
  backTitle?: string;
  backLink?: string;
  tourCode?: string | number;
}

const PageHeader: FC<props> = ({
  title,
  mode,
  backTitle,
  backLink,
  tourCode,
}) => {
  const { words } = useLanguage();

  // const handleBack = () => {
  //   if (backLink && backTitle) navigate(backTitle);
  //   else navigate(-1);
  // };

  const handleIconName = () => {
    switch (mode) {
      case "add":
        return "display_settings";
      case "edit":
        return "edit_note";
      case "view":
        return "remove_red_eye";
      default:
        return "display_settings";
    }
  };

  const PageHeaderTitle = () => {
    const modeName = () => {
      switch (mode) {
        case "add":
          return words.managementRoute.modeAdd;
        case "edit":
          return words.managementRoute.modeEdit;
        case "view":
          return words.managementRoute.modeView;
        default:
          return "";
      }
    };
    return (
      <>
        {mode !== "" && (
          <>
            <span>{words.global.managementTour}</span>
            <span className="mr-[5px]">/</span>
          </>
        )}
        <span className="mr-[5px] text-[#4B4B4B]">
          {modeName()} {title}
        </span>
      </>
    );
  };

  return (
    <PageHeaderContainer>
      <div className="flex items-center">
        {mode !== "" ? (
          <div className="edit-icon">
            <span className="material-icons text-[#273574] text-[18px]">
              {handleIconName()}
            </span>
          </div>
        ) : null}
        <span className="title">{<PageHeaderTitle />}</span>
      </div>

      {/* {backTitle && (
       
        <div className="back-box" onClick={handleBack}>
          <span>{backTitle}</span>
          <span className="material-icons mr-2">arrow_back</span>
        </div>
        
      )} */}
    </PageHeaderContainer>
  );
};

export default PageHeader;
