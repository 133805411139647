import { Form } from "antd";
import _ from "lodash";
import { useManagementDriverData } from "./context";

export const useFormHasChanged = () => {
  const {
    states: { driver, driverForm },
  } = useManagementDriverData();

  const allValues = Form.useWatch([], driverForm);

  const formHasProperty = () => {
    return allValues
      ? Object.values(allValues).every((el) => el === undefined || el === null)
      : false;
  };

  const changed = _.isEqual(allValues, driver);

  return driver ? changed : formHasProperty();
};
