import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

interface IContextValue {
  values: {
    currentTabKey: string;
    created: number | undefined;
  };
  dispatches: {
    setCurrentTabKey: Dispatch<SetStateAction<string>>;
    setCreated: Dispatch<SetStateAction<number | undefined>>;
  };
}

export const AddHotelsDataContext = createContext<IContextValue | undefined>(
  undefined
);

const AddHotelsDataProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [currentTabKey, setCurrentTabKey] = useState<string>("info");
  const [created, setCreated] = useState<number | undefined>(undefined);

  const contextValues = {
    values: { currentTabKey, created },
    dispatches: { setCurrentTabKey, setCreated },
  };

  return (
    <AddHotelsDataContext.Provider value={contextValues}>
      {children}
    </AddHotelsDataContext.Provider>
  );
};

export default AddHotelsDataProvider;

export const useAddHotelsData = () => useContext(AddHotelsDataContext)!;
