import { FC } from "react";
import { Col } from "antd";
import PageHeader from "src/components/pageHeader";
import useLanguage from "src/store/language";
import { ManagementSimcardContainer } from "./style";
import { useManagementSimcardData } from "./context";
import ViewSimcard from "./components/view/ViewSimcard";
import AddEditSimcardDataProvider from "./components/add-edit/context";
import SimcardAddEdit from "./components/add-edit";

const ManagementSimcard: FC = () => {
  const { words } = useLanguage();

  const {
    states: { mode },
  } = useManagementSimcardData();

  return (
    <>
      {/* <PageHeader
        mode={mode}
        title={words.managementSimcard.managementSimcard}
        backTitle={words.global.back}
        tourCode={123465}
      /> */}
      <AddEditSimcardDataProvider>
        <ManagementSimcardContainer justify={"center"} gutter={[0, 24]}>
          <Col xs={24} md={mode === "view" ? 16 : 23} className="content">
            {mode === "view" ? <ViewSimcard /> : <SimcardAddEdit />}
          </Col>
        </ManagementSimcardContainer>
      </AddEditSimcardDataProvider>
    </>
  );
};

export default ManagementSimcard;
