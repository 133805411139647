import { FC } from "react";
import TranslatorForm from "./TranslatorForm";
import TranslatorInfoRequestProvider from "./TranslatorInfoRequest";

const TranslatorTab: FC = () => {
  return (
    <TranslatorInfoRequestProvider>
      <TranslatorForm />
    </TranslatorInfoRequestProvider>
  );
};

export default TranslatorTab;
