import { Flex, Spin } from "antd";
import { FC } from "react";
import { ITourDocument } from "src/services/TourManagement/models";
import useLanguage from "src/store/language";
import { useManagementFilesData } from "../../../context";
import { useManagementFilesTableData } from "../context";

interface ITableActionsProps {
  item: ITourDocument;
}

const TableActions: FC<ITableActionsProps> = ({ item }) => {
  const { words } = useLanguage();

  const {
    states: { progress },
    func: { handleDeleteId },
    requests: { downloadAttachFile },
  } = useManagementFilesTableData();

  const {
    states: { mode },
    dispatches: { setSelectedDocument },
  } = useManagementFilesData();

  const handleSelectAssign = () => {
    setSelectedDocument(item);
  };

  return (
    <>
      <Flex justify="center" className="gap-[16px]">
        <div
          className="download-action"
          onClick={() => downloadAttachFile(item?.attachmentId || 0)}
        >
          <span className="material-icons text-[14px]">sim_card_download</span>
          <span>{words.global.downloadAction}</span>
        </div>
        <Spin spinning={progress > 0 ? true : false} size="large" fullscreen />
        {mode === "add" || mode === "edit" ? (
          <>
            <div className="edit-action" onClick={() => handleSelectAssign()}>
              <span className="material-icons text-[#0C9D61]  text-[16px]">
                edit_note
              </span>
              <span>{words.global.editAction}</span>
            </div>
            <div
              className="delete-action"
              onClick={() => handleDeleteId(item.attachmentId)}
            >
              <span className="material-icons text-[14px]">delete_forever</span>
              <span>{words.global.delete}</span>
            </div>
          </>
        ) : null}
      </Flex>
    </>
  );
};

export default TableActions;
