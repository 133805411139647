import { FC } from "react";
import { TableUI } from "src/components/UiKit/table";
import { ColumnsType } from "antd/es/table";
import { IAssignSimcard } from "src/services/TourManagement/models";
import useLanguage from "src/store/language";
import TableActions from "./TableActions";
import { useManagementSimcardData } from "../../context";
import { Divider } from "antd";
import { useAddEditSimcardData } from "../add-edit/context";

const ManagementSimcardTable: FC = () => {
  const {
    states: { simcards, dataLoading, totalCount, tablePaginationParams, mode },
    dispatches: { setTablePaginationParams },
  } = useManagementSimcardData();

  const {
    values: { selectedAssign },
  } = useAddEditSimcardData();

  const { words } = useLanguage();

  const tableColumns: ColumnsType<IAssignSimcard> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      width: 62,
      render: (text, record, index) =>
        (tablePaginationParams.Offset - 1) * tablePaginationParams.Limit +
        index +
        1,
    },
    {
      title: `${words.managementSimcardAssign.tourist}`,
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: `${words.managementSimcardAssign.simcardNumberLabel}`,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    ...(mode === "view"
      ? []
      : [
          {
            title: `${words.basicInfo.tableActions}`,
            dataIndex: "tools",
            key: "tools",
            render: (_: any, record: IAssignSimcard) => (
              <TableActions item={record} />
            ),
          },
        ]),
  ];
  return (
    <>
      {mode === "add" || mode === "edit" ? (
        <>
          <Divider className="bg-[#E1E1E1]" />
          <p className="simcard-table-title">
            {words.managementSimcardAssign.tableTitle}
          </p>
        </>
      ) : (
        <p className="simcard-table-title !text-[#233069]">
          {words.managementSimcardAssign.viewTableTitle}
        </p>
      )}

      <TableUI
        columns={tableColumns}
        dataSource={simcards}
        loading={dataLoading}
        tableType="secondary"
        rowClassName={(record, index) =>
          record.tourTouristId === selectedAssign?.tourTouristId
            ? "selected-row-for-edit"
            : ""
        }
        pagination={{
          position: ["bottomCenter"],
          total: totalCount,
          pageSize: tablePaginationParams.Limit,
          current: tablePaginationParams.Offset,
          onChange(page, pageSize) {
            setTablePaginationParams((prev) => ({
              ...prev,
              Offset: page,
              Limit: pageSize,
            }));
          },
        }}
      />
    </>
  );
};

export default ManagementSimcardTable;
