import { FC } from "react";
import { Flex } from "antd";
import useLanguage from "src/store/language";
import { useHotelsData } from "../../../context/HotelsData";

const HotelInfo: FC = () => {
  const { words } = useLanguage();

  const {
    states: { selectedHotel },
  } = useHotelsData();

  return (
    <>
      <p className="title">{words.basicInfo.viewHotelPageTitle}</p>
      <div className="place-view-details">
        <Flex align="center" justify="space-between">
          <Flex align="center" className="h-[55px] w-[50%]">
            <span className="place-key">نام هتل:</span>
            <span className="place-value">{selectedHotel?.name}</span>
          </Flex>
          <Flex align="center" className="h-[55px] w-[50%]">
            <span className="place-key">شهر:</span>
            <span className="">{selectedHotel?.cityName}</span>
          </Flex>
        </Flex>
        <Flex align="center" className="h-[55px]">
          <span className="place-key">تعداد ستاره:</span>
          <span className="">
            {selectedHotel?.star.toString().concat(" ستاره")}
          </span>
        </Flex>
        <Flex align="center" className="h-[55px]">
          <span className="place-key">آدرس:</span>
          <span className="">{selectedHotel?.address}</span>
        </Flex>
      </div>
    </>
  );
};

export default HotelInfo;
