import { Row } from "antd";
import styled from "styled-components";

export const SimcardAddEditTabsContainer = styled(Row)`
  width: 100%;
  :where(.css-dev-only-do-not-override-p90m5z).ant-tabs
    .ant-tabs-tab.ant-tabs-tab
    .ant-tabs-tab-btn {
    font-family: vazir, DM Sans !important;
    color: #cacaca;
    font-weight: 700;
    font-size: 14px;
  }

  :where(.css-dev-only-do-not-override-p90m5z).ant-tabs
    .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    font-family: vazir, DM Sans !important;
    color: #f06614;
    font-weight: 700;
    font-size: 14px;
  }
  .ant-tabs-ink-bar {
    border-bottom: 2px solid #f06614;
  }
  .add-place-form-title {
    font-weight: 500;
    font-size: 14px;
    color: #1f1f1f;
  }
  .add-place-form-row {
    margin-top: 30px;
  }
  .footer-box {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: end;
  }
  .confirm-btn {
    background-color: #f6a372;
    color: #fff;
  }
  .confirm-btn:hover {
    background-color: #f6a372 !important;
    color: #fff !important;
  }
  .clean-btn {
    background-color: #f5f5f5;
    color: #8e8e8e;
    margin-right: 10px;
  }
  .clean-btn:hover {
    background-color: #f5f5f5 !important;
    color: #8e8e8e;
    box-shadow: none;
    border: 0;
    outline: none;
  }
  .add-contact-btn {
    color: #f6a372;
    background-color: transparent;
    border: solid 1px #f6a372;
    span {
      color: #f6a372;
    }
  }
  .add-contact-btn-disable {
    color: #fad1b8;
    background-color: transparent;
    border: solid 1px #fad1b8;
    span {
      color: #fad1b8;
    }
  }
  .add-contact-btn:hover {
    color: #f6a372 !important;
    background-color: transparent !important;
    border: solid 1px #f6a372 !important;
  }
  .add-contact-btn-disable:hover {
    color: #fad1b8;
    background-color: transparent;
    border: solid 1px #fad1b8;
  }
  #contact-form_mobile,
  .hotel-mobile-number {
    direction: ltr;
  }
  .add-contact-col {
    border: solid 1px #e1e1e1;
    border-radius: 12px;
    padding: 24px;
  }
  .contact-person-form-title {
    font-weight: 500;
    font-size: 16px;
    color: #233069;
  }
  .contact-form-title {
    font-weight: 500;
    font-size: 14px;
    color: #1f1f1f;
  }
  .contact-form-description {
    font-weight: 400;
    font-size: 14px;
    color: #4b4b4b;
    text-align: justify;
  }
  .delete-btn {
    color: #f06614;
    border-radius: 8px;
    border: solid 1px #f06614;
    background-color: #ffffff;
    width: 85px;
    font-weight: 500;
    font-size: 12px;
  }
  .delete-btn:hover {
    color: #f06614 !important;
    border: solid 1px #f06614 !important;
    background-color: #ffffff;
  }
`;
