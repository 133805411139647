import { FC } from "react";
import useLanguage from "src/store/language";
import { FilterPopUp } from "./Filter/FilterPopup";
import { TableHeaderContainer } from "../styles";

const TableHeader: FC = () => {
  const { words } = useLanguage();
  return (
    <TableHeaderContainer>
      <FilterPopUp>
        <div className="flex gap-[16px] items-end cursor-pointer">
          <span className="material-icons text-[24px] text-[#f06614] mb-[5px]">
            filter_alt
          </span>
          <div className="filter-title">{words.tableTour.filter}</div>
        </div>
      </FilterPopUp>
    </TableHeaderContainer>
  );
};

export default TableHeader;
