import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from "react";
import { INewRoomArg, IRoom } from "src/services/BasicInformation/models";
import { Form, FormInstance, message } from "antd";
import { BasicInformationService } from "src/services/BasicInformation/BasicInformation.service";
import useLanguage from "src/store/language";
import { IPagination } from "src/models/interfaces/pagination";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";

interface IContextValue {
  states: {
    addRoomForm: FormInstance;
    loading: boolean;
    selectedRoom: IRoom | undefined;
    rooms: IRoom[];
    dataLoading: boolean;
    deleteId: number;
    confirmLoading: boolean;
    totalCount: number;
    tablePaginationParams: Pick<IPagination, "Offset" | "Limit">;
  };
  dispatches: {
    setTablePaginationParams: Dispatch<
      SetStateAction<Pick<IPagination, "Offset" | "Limit">>
    >;
    setSelectedRoom: Dispatch<SetStateAction<IRoom | undefined>>;
  };
  request: {
    onDeleteRoom: (id: number) => void;
  };
  func: {
    onFinish: (values: INewRoomArg) => void;
    handleDeleteId: (id: number) => void;
  };
}

export const RoomsDataContext = createContext<IContextValue | undefined>(
  undefined
);

const RoomsDataProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const [tablePaginationParams, setTablePaginationParams] =
    useState<IPagination>({
      Offset: 1,
      Limit: 10,
    });
  const [selectedRoom, setSelectedRoom] = useState<IRoom | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [rooms, setRooms] = useState<IRoom[]>([]);
  const [dataLoading, setDataLoadings] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [addRoomForm] = Form.useForm();

  const { words } = useLanguage();

  const onFinish = (values: INewRoomArg) => {
    setLoading(true);
    if (selectedRoom) roomEditREq(values);
    else roomAddReq(values);
  };

  const roomAddReq = async (values: INewRoomArg) => {
    try {
      const { NewRoom } = new BasicInformationService();
      const result = await NewRoom(values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        addRoomForm.resetFields();
        getRooms();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const roomEditREq = async (values: INewRoomArg) => {
    if (!selectedRoom) return;
    try {
      const { EditRoom } = new BasicInformationService();
      const result = await EditRoom(selectedRoom.id, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setSelectedRoom(undefined);
        getRooms();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteId = (id: number) => {
    setDeleteId(id);
  };

  const getRooms = useCallback(async () => {
    setDataLoadings(true);
    const params = new URLSearchParams(tablePaginationParams);
    try {
      const { Rooms } = new BasicInformationService();
      const res = await Rooms(params.toString());
      if (res && res.status === 200 && res.data) {
        setRooms(res.data.records);
        setTotalCount(res.data.count);
      } else {
        setRooms([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoadings(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePaginationParams.Limit, tablePaginationParams.Offset]);

  const onDeleteRoom = async () => {
    if (deleteId === 0) return;
    setConfirmLoading(true);
    try {
      const { DeleteRoom } = new BasicInformationService();
      const result = await DeleteRoom(deleteId);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setDeleteId(0);
        if (deleteId === selectedRoom?.id) setSelectedRoom(undefined);
        getRooms();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmLoading(false);
    }
  };

  useEffect(() => {
    getRooms();
  }, [getRooms]);

  useEffect(() => {
    if (selectedRoom) {
      addRoomForm.setFieldsValue({
        name: selectedRoom.name,
        nameForMail: selectedRoom.nameForMail,
        bedCount: selectedRoom.bedCount,
        description: selectedRoom?.description || null,
      });
    } else addRoomForm.resetFields();
  }, [addRoomForm, selectedRoom]);

  const contextValues = {
    states: {
      tablePaginationParams,
      selectedRoom,
      addRoomForm,
      loading,
      rooms,
      dataLoading,
      deleteId,
      confirmLoading,
      totalCount,
    },
    dispatches: {
      setTablePaginationParams,
      setSelectedRoom,
    },
    request: { onDeleteRoom },
    func: { onFinish, handleDeleteId },
  };

  return (
    <RoomsDataContext.Provider value={contextValues}>
      {children}
      <DeleteConfirmModal
        showConfirm={deleteId > 0}
        onCancel={() => handleDeleteId(0)}
        onOk={() => onDeleteRoom()}
        title={words.basicInfo.deleteConfirmTitle}
        okTitle={words.global.delete}
        loading={confirmLoading}
      />
    </RoomsDataContext.Provider>
  );
};

export default RoomsDataProvider;

export const useRoomsData = () => useContext(RoomsDataContext)!;
