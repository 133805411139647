import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import { IFormDataProps } from "src/modules/addEditTour/interface";
import { TabMenuKeys } from "src/modules/addEditTour/models";
import { ITourTableParams } from "src/services/Tour/models";
import { TourService } from "src/services/Tour/Tour.service";

interface IContextValue {
  states: {
    current: string;
    currentFilter: string;
    formData: IFormDataProps | undefined;
    viewMode: "view" | "print-tour" | "print-tourist";
  };
  dispatches: {
    setCurrent: Dispatch<SetStateAction<string>>;
    setCurrentFilter: Dispatch<SetStateAction<string>>;
    setFormData: Dispatch<SetStateAction<IFormDataProps | undefined>>;
    setViewMode: Dispatch<
      SetStateAction<"view" | "print-tour" | "print-tourist">
    >;
  };
  requests: {};
}

export const TourViewDataContext = createContext<IContextValue | undefined>(
  undefined
);

const TourViewDataProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const [current, setCurrent] = useState<string>(TabMenuKeys.manager);
  const [currentFilter, setCurrentFilter] = useState<string>(
    TabMenuKeys.tourist
  );
  const [formData, setFormData] = useState<IFormDataProps | undefined>();
  const [tourTableParams, setTourTableParams] = useState<ITourTableParams>({
    Offset: 1,
    Limit: 10,
  });
  const [viewMode, setViewMode] = useState<
    "view" | "print-tour" | "print-tourist"
  >("view");

  const { tourId } = useParams();

  const getTourInfo = useCallback(async () => {
    if (!tourId) return;

    try {
      const { TourInfo } = new TourService();

      const result = await TourInfo(tourId);
      if (result && result.status === 200) {
        setFormData((prev) => ({
          ...prev,
          tourInfo: result.data,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTourInfo();
  }, [getTourInfo]);

  const contextValues = {
    states: {
      tourTableParams,
      current,
      currentFilter,
      formData,
      viewMode,
    },
    dispatches: {
      setTourTableParams,
      setCurrent,
      setCurrentFilter,
      setFormData,
      setViewMode,
    },
    requests: {},
  };

  return (
    <TourViewDataContext.Provider value={contextValues}>
      {children}
    </TourViewDataContext.Provider>
  );
};

export default TourViewDataProvider;

export const useTourViewData = () => useContext(TourViewDataContext)!;
