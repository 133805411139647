import styled from "styled-components";

export const PageHeaderContainer = styled.div`
  width: 100%;
  height: 80px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-bottom: solid 1px #fde8dc;
  margin-bottom: 30px;
  .title {
    color: #233069;
    font-weight: 700;
    font-size: 16px;
    margin-right: 10px;
  }
  .edit-icon {
    width: 52px;
    height: 52px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: #e9eaf1;
  }
  .back-box {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
      color: #f06614;
      font-weight: 700;
      font-size: 18px;
    }
  }
`;
