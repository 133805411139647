import ManagementSimcardAssign from "src/modules/managementSimcardAssign";
import ManagementSimcardAssignDataProvider from "src/modules/managementSimcardAssign/context";

const ManagementSimcardAssignPage = () => (
  <ManagementSimcardAssignDataProvider>
    <ManagementSimcardAssign />
  </ManagementSimcardAssignDataProvider>
);

export default ManagementSimcardAssignPage;
