import { FC, useState } from "react";
import { Button, Col, Divider, Form, Row, Spin } from "antd";
import FormItem from "antd/es/form/FormItem";
import SubmitButton from "src/components/UiKit/SubmitButton";
import useLanguage from "src/store/language";
import DatePickerUi from "src/components/UiKit/DatePicker";
import InputUiKit from "src/components/UiKit/Input";
import InputNumberUiKit from "src/components/UiKit/inputNumber";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";
import { useManagementTourBack } from "src/hook/useManagementTourBack";
import { useAddEditSimcardData } from "../context";
import { useHasChangedBuyForm } from "../hooks/useHasChangedBuyForm";
import { useManagementSimcardData } from "../../../context";

const BuyInfo: FC = () => {
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const { words } = useLanguage();

  const canBuySubmit = useHasChangedBuyForm();
  const { backHandler } = useManagementTourBack();

  const {
    states: { mode, dataLoading },
  } = useManagementSimcardData();

  const {
    values: { loading, deleteLoading },
    func: { onFinishBuySimcard },
    form: { buyForm },
    request: { onDeleteSimcard },
  } = useAddEditSimcardData();

  const handleCancel = () => {
    if (mode === "add") {
      buyForm.resetFields();
    } else backHandler();
  };

  return (
    <>
      <p className="title mt-[24px]">
        {mode === "add"
          ? words.managementSimcard.addInfoFormTitle
          : words.managementSimcard.editInfoFormTitle}
      </p>
      {dataLoading ? (
        <div className="w-full flex justify-center items-center">
          <Spin size="large" />
        </div>
      ) : (
        <Form
          onFinish={onFinishBuySimcard}
          form={buyForm}
          layout="vertical"
          preserve={false}
          className="mt-[40px]"
        >
          <Row gutter={[24, 12]}>
            <Col xs={24} md={8}>
              <FormItem
                name={"count"}
                label={words.managementSimcard.simcardAmountLabel}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <InputNumberUiKit
                  controls={false}
                  type="number"
                  placeholder={words.managementSimcard.simcardAmountPlaceHolder}
                />
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                name={"amount"}
                label={words.managementSimcard.simcardPriceLabel}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <InputNumberUiKit
                  controls={false}
                  placeholder={words.managementSimcard.simcardPricePlaceHolder}
                />
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                name={"payDate"}
                label={words.managementSimcard.simcardDateLabel}
              >
                <DatePickerUi placeholder={words.managementTime.select} />
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                name={"exporter"}
                label={words.managementSimcard.simcardIssuerNameLabel}
              >
                <InputUiKit
                  placeholder={
                    words.managementSimcard.simcardIssuerNamePlaceHolder
                  }
                />
              </FormItem>
            </Col>

            <Col xs={24} md={8}>
              <FormItem
                name={"exporterPhone"}
                label={words.managementSimcard.simcardIssuerMobileLabel}
              >
                <InputUiKit
                  placeholder={
                    words.managementSimcard.simcardIssuerMobilePlaceHolder
                  }
                />
              </FormItem>
            </Col>
            <Divider className="bg-[#EEEEEE]" />
            <Col span={24} className="flex justify-between">
              <div>
                {mode === "edit" ? (
                  <Button
                    type="default"
                    className="delete-btn"
                    onClick={() => setShowDelete(true)}
                  >
                    {words.global.delete}
                  </Button>
                ) : null}
              </div>

              <div className="flex justify-end">
                <Button
                  type="text"
                  className="bg-[#F5F5F5] text-[#8E8E8E] ml-2 w-[85px]"
                  onClick={handleCancel}
                >
                  {mode === "add"
                    ? words.managementTime.clear
                    : words.managementTime.cancel}
                </Button>
                <SubmitButton
                  form={buyForm}
                  loading={loading}
                  canSubmit={canBuySubmit}
                >
                  {mode === "add"
                    ? words.global.add
                    : words.managementTime.editBtn}
                </SubmitButton>
              </div>
            </Col>
          </Row>
        </Form>
      )}

      <DeleteConfirmModal
        showConfirm={showDelete}
        onCancel={() => setShowDelete(false)}
        onOk={() => onDeleteSimcard().finally(() => setShowDelete(false))}
        title={words.managementSimcard.deleteConfirmTitle}
        okTitle={words.global.delete}
        loading={deleteLoading}
      />
    </>
  );
};

export default BuyInfo;
