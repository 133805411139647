import { Flex, Row } from "antd";
import styled from "styled-components";

export const RoutesProgramContainer = styled(Row)`
  border-radius: 20px;
  background: #fff;
  box-shadow: 2px 6px 16px 0px rgba(0, 0, 0, 0.18);
  .headerHolder {
    padding: 0px 12px;
    height: 45px;
    border-radius: 8px 8px 0px 0px;
    background: #8e8e8e;
  }
  .contentHolder {
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    background: #eee;
  }
  .backDataHolder {
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    background: #fafafa;
  }

  table {
    thead {
      tr {
        th {
          color: #1f1f1f;

          /* Fa/Title/T2.14.Bold */
          font-family: "Vazir FD";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          border: 1px solid #f5f5f5 !important;
          background: #e1e1e1 !important;
        }
      }
    }
  }
`;
export const PageFooter = styled(Flex)`
  margin-top: 32px;
  padding: 32px 24px 16px 24px;
  border-top: 1px solid #dadeec;
  img {
    cursor: pointer;
    user-select: none;
  }
`;
