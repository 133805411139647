import React, { FC, ReactNode } from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import ConfigProviders from "src/components/ConfigProviders";
// import { ChooseLanguage } from "src/components/chooseLanguage";
import { LoginLayoutOutletStyled, LoginLayoutStyled } from "./styles";

const LoginLayout: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <ConfigProviders>
      <Layout>
        <LoginLayoutStyled>
          {/* <div className="language-box">
            <ChooseLanguage />
          </div> */}
          <LoginLayoutOutletStyled>
            <Outlet />
          </LoginLayoutOutletStyled>
        </LoginLayoutStyled>
      </Layout>
    </ConfigProviders>
  );
};

export default LoginLayout;
