import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import { App } from "antd";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { TourService } from "src/services/Tour/Tour.service";
import useLanguage from "src/store/language";
import { IAttachFile } from "src/services/Tour/models";
import { uploadFile } from "src/helper/uploadZipFile";
import { IObject } from "src/models/interfaces";
import { IPagination } from "src/models/interfaces/pagination";
import { convertObj } from "src/helper";
import { reactQueryEndPoints } from "src/constants";
import { useQuery } from "react-query";
import { AxiosRequestConfig } from "axios";
import downloadZipFile from "src/helper/downloadZipFile";
import { ROUTES } from "src/models/enums/routes";
import { TourStates } from "src/models/enums/TourStates";

interface IContextValue {
  states: {
    loading: boolean;
    dataLoading: boolean;
    files: IAttachFile[];
    totalCount: number | undefined;
    pagination: IPagination;
    progress: number;
  };
  dispatches: {
    setPagination: Dispatch<
      SetStateAction<Pick<IPagination, "Offset" | "Limit">>
    >;
  };
  requests: {
    addEditAttachRequest: (values: IObject) => Promise<void>;
    deleteAttachFileRequest: (AttachTourId: number) => Promise<void>;
    downloadAttachFile: (attachTourId: number) => void;
    changeTourStateRequest: () => Promise<void>;
  };
}

export const AttachmentRequestContext = createContext<
  IContextValue | undefined
>(undefined);

const AttachmentRequestProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [pagination, setPagination] = useState<IPagination>({
    Limit: 10,
    Offset: 1,
  });
  const { message } = App.useApp();
  const { words } = useLanguage();
  const { tourId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    DownloadAttachFile,
    DeleteAttachFile,
    GetAttachFile,
    ChangeTourState,
  } = new TourService();

  const changeTourStateRequest = async () => {
    if (!tourId) return;

    try {
      const result = await ChangeTourState(tourId, TourStates.Waiting);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        navigate(ROUTES.tourTable);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const downloadAttachFile = async (attachTourId: number) => {
    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 0)
        );
        setProgress(percentage);
        if (percentage === 100) {
          setTimeout(() => setProgress(0), 400);
        }
      },
    };
    try {
      const result = await DownloadAttachFile(attachTourId, config);
      if (result) {
        downloadZipFile(result);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProgress(0);
    }
  };

  const deleteAttachFileRequest = async (AttachTourId: number) => {
    try {
      setLoading(true);
      const result = await DeleteAttachFile(AttachTourId);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        //geAttachFile();
        refetch();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const addEditAttachRequest = async (values: IObject) => {
    if (!tourId) return;
    setLoading(true);
    try {
      const res = await uploadFile({
        callbackUrl: `Tour/${tourId}/AttachFile`,
        newFile: values.file[0],
        method: "PATCH",
        comment: values.comment,
      });
      if (res && res.status === 200) {
        message.success(words.global.successMessage);
        //geAttachFile();
        refetch();
      }
    } catch (err) {
      console.log("upload excel file error", err);
    } finally {
      setLoading(false);
    }
  };

  const geAttachFile = useCallback(async () => {
    if (!tourId) return;
    let querySearch = `${location.search}${
      location.search ? "&" : "?"
    }${createSearchParams(convertObj(pagination))}`;
    try {
      //setDataLoading(true);
      const result = await GetAttachFile(tourId, querySearch);
      if (result && result.status === 200) {
        //setFiles(result.data.records);
        const { data } = result;
        return data;
      }
    } catch (err) {
      console.log(err);
    } finally {
      //setDataLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, isFetching, refetch } = useQuery(
    reactQueryEndPoints.getAttach,
    geAttachFile,
    { enabled: false }
  );

  useEffect(() => {
    if (location.pathname.includes(ROUTES.tourEdit) && tourId) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, location, tourId]);

  const contextValues = {
    states: {
      loading,
      dataLoading: isFetching,
      files: data?.records || [],
      totalCount: data?.count,
      pagination,
      progress,
    },
    dispatches: { setPagination },
    requests: {
      addEditAttachRequest,
      deleteAttachFileRequest,
      downloadAttachFile,
      changeTourStateRequest,
    },
  };

  return (
    <AttachmentRequestContext.Provider value={contextValues}>
      {children}
    </AttachmentRequestContext.Provider>
  );
};

export default AttachmentRequestProvider;

export const useAttachmentRequest = () => useContext(AttachmentRequestContext)!;
