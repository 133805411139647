import { FC } from "react";
import { AssignActionToRole } from "src/modules/assignActionToRole";
import { AssignActionToRoleProvider } from "src/modules/assignActionToRole/context";

export const AssignActionToRolePage: FC = () => {
  return (
    <AssignActionToRoleProvider>
      <AssignActionToRole />
    </AssignActionToRoleProvider>
  );
};
