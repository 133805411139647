import { FC } from "react";
import { ViewNotification } from "src/modules/managementNotification/components/view";
import { ManagementNotificationProvider } from "src/modules/managementNotification/context";

export const ViewNotificationPage: FC = () => {
  return (
    <ManagementNotificationProvider>
      <ViewNotification />
    </ManagementNotificationProvider>
  );
};
