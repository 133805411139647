import styled from "styled-components";

export const SelectStyle = styled.div`
  width: 100%;
  .ant-checkbox-inner {
    display: none;
  }
  .ant-select,
  .ant-select-selector,
  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border: 0 !important;
    box-shadow: none !important;
    outline: none !important;
    background-color: transparent !important;
  }

  .ant-select-arrow {
    /* top: 20px; */
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  .ant-select-selection-placeholder {
    color: #8e8e8e;
    font-weight: 500;
    font-size: 12px;
  }

  .ant-select.ant-select-disabled,
  .ant-select.ant-select-disabled .ant-select-selector {
    border: 0 !important;
    box-shadow: none !important;
    outline: none !important;
    color: var(--color-Light-Mode-Text-Primary-text, #1f1f1f);
  }
`;
