import { FC } from "react";
import { CreateUser } from "src/modules/createUser";
import { CreateUserProvider } from "src/modules/createUser/context";

export const CreateUserPage: FC = () => {
  return (
    <CreateUserProvider>
      <CreateUser />
    </CreateUserProvider>
  );
};
