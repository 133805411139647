import { FC } from "react";
import Attachments from "./attachment";
import TeamInfo from "./team";
import TourInfo from "./tour/TourInfo";
import TouristInfo from "./tourist/TouristInfo";
import { useTourAddEditData } from "../context/TourAddEditData";
import Insurance from "./insurance/InsuranceForm";
import TouristInfoRequestProvider from "./tourist/TouristInfoRequest";
import InsuranceRequestProvider from "./insurance/InsuranceRequest";
import TourInfoRequestProvider from "./tour/TourRequest";
import { TabMenuKeys } from "../models";

const FilterContent: FC = () => {
  const { states } = useTourAddEditData()!;

  const handleFilterContent = () => {
    switch (states.currentFilter) {
      case TabMenuKeys.tourist:
        return (
          <TouristInfoRequestProvider>
            <TouristInfo />
          </TouristInfoRequestProvider>
        );
      case TabMenuKeys.team:
        return <TeamInfo />;
      case TabMenuKeys.tour:
        return (
          <TourInfoRequestProvider>
            <TourInfo />
          </TourInfoRequestProvider>
        );
      case TabMenuKeys.insurance:
        return (
          <InsuranceRequestProvider>
            <Insurance />
          </InsuranceRequestProvider>
        );
      case TabMenuKeys.attachment:
        return <Attachments />;
    }
  };
  return <>{handleFilterContent()}</>;
};

export default FilterContent;
