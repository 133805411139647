import { FC, useState } from "react";
import { Upload, Image, Form, message } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { UploadRequestOption } from "rc-upload/lib/interface";
import useLanguage from "src/store/language";
import { LeaderImageContainer } from "../style";
import { useManagementLeaderData } from "../context";

const LeaderCardImage: FC = () => {
  const [cardImageList, setCardImageList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const {
    states: { leaderForm, leaderProps },
    dispatches: { setLeaderProps },
  } = useManagementLeaderData();
  const { words } = useLanguage();

  const dummyRequest: (options: UploadRequestOption) => void = ({
    file,
    onSuccess,
  }) => {
    setTimeout(() => {
      if (onSuccess) {
        onSuccess("done");
        return file;
      }
    }, 0);
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };
  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error(words.managementLeader.imageTypeError);
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(words.managementLeader.imageSizeError);
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    if (newFileList && newFileList.length > 0)
      leaderForm.setFieldValue(
        "LeaderCard",
        newFileList[0].originFileObj as any
      );
    else leaderForm.setFieldValue("LeaderCard", null);
    setCardImageList(newFileList);
  };

  return (
    <LeaderImageContainer xs={24} md={12}>
      <div className="image-box">
        <span className="image-box-title">
          {words.managementLeader.addEditCardImageTitle}
        </span>
        <span className="pr-[4px] text-[#EC2D30] text-[14px]">*</span>
        <p className="image-box-description">
          {words.managementLeader.addEditImageDescription}
        </p>
        {leaderProps.leaderCard ? (
          <>
            <div className="edi-img-box">
              <img
                src={leaderProps.leaderCard}
                alt="national-card"
                width="100%"
                height="216px"
              />
              <div className="edit-img-delete">
                <span
                  className="material-symbols-outlined cursor-pointer"
                  onClick={() =>
                    setLeaderProps((prev) => ({
                      ...prev,
                      leaderCard: undefined,
                    }))
                  }
                >
                  delete_forever
                </span>
              </div>
            </div>
          </>
        ) : (
          <Form.Item
            name="LeaderCard"
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <Upload
              customRequest={dummyRequest}
              listType="picture-card"
              fileList={cardImageList}
              onChange={handleChange}
              onPreview={handlePreview}
              beforeUpload={beforeUpload}
              accept="image/png, image/jpeg, image/jpg"
            >
              {cardImageList.length < 1 && (
                <div className="add-image-container">
                  <span className="plus">+</span>
                  <span className="text">
                    {words.managementLeader.addImage}
                  </span>
                </div>
              )}
            </Upload>
          </Form.Item>
        )}
        {previewImage && (
          <Image
            wrapperStyle={{ display: "none" }}
            preview={{
              visible: previewOpen,
              onVisibleChange: (visible) => setPreviewOpen(visible),
              afterOpenChange: (visible) => !visible && setPreviewImage(""),
            }}
            src={previewImage}
          />
        )}
      </div>
    </LeaderImageContainer>
  );
};

export default LeaderCardImage;
