import { TabsProps } from "antd";
import { ReportTabKeys } from "../constant";
import MarkAsUnreadTwoTone from "@mui/icons-material/MarkAsUnreadTwoTone";
import LocalPolice from "@mui/icons-material/LocalPoliceTwoTone";
import Hotel from "@mui/icons-material/HotelTwoTone";
import Signpost from "@mui/icons-material/SignpostTwoTone";
import LocalAirport from "@mui/icons-material/LocalAirportTwoTone";
import { ExportToWord } from "../../najaMail";
import { RoutesProgram } from "../../routesProgram";
import { NajaList } from "../../najaList";

import { FlightList } from "../../flightList";
import { HotelLetterReservationList } from "../../hotelLetter";
import { HotelLetterProvider } from "../../hotelLetter/context";
interface IProps {
  activeKey: string;
}
export const useReportHeaderItems = ({ activeKey }: IProps) => {
  const activeColor = "#fff";
  const primaryColor = "#525D90";
  const items: TabsProps["items"] = [
    {
      key: ReportTabKeys.Naja,
      label: "نامه ناجا",
      children: <ExportToWord />,
      icon: (
        <MarkAsUnreadTwoTone
          color="warning"
          sx={{
            color:
              activeKey === ReportTabKeys.Naja ? activeColor : primaryColor,
          }}
        />
      ),
    },
    {
      key: ReportTabKeys.Migration,
      label: "لیست گردشگران ناجا",
      children: <NajaList />,
      icon: (
        <LocalPolice
          color="warning"
          sx={{
            color:
              activeKey === ReportTabKeys.Migration
                ? activeColor
                : primaryColor,
          }}
        />
      ),
    },
    {
      key: ReportTabKeys.Hotel,
      label: "نامه رزرو هتل و لیست گردشگران",
      children: (
        <HotelLetterProvider>
          <HotelLetterReservationList />
        </HotelLetterProvider>
      ),
      icon: (
        <Hotel
          color="warning"
          sx={{
            color:
              activeKey === ReportTabKeys.Hotel ? activeColor : primaryColor,
          }}
        />
      ),
    },
    {
      key: ReportTabKeys.Routes,
      label: "برنامه مسیر تور",
      children: <RoutesProgram />,
      icon: (
        <Signpost
          color="warning"
          sx={{
            color:
              activeKey === ReportTabKeys.Routes ? activeColor : primaryColor,
          }}
        />
      ),
    },
    {
      key: ReportTabKeys.Flights,
      label: "لیست پرواز",
      children: <FlightList />,
      icon: (
        <LocalAirport
          color="warning"
          sx={{
            color:
              activeKey === ReportTabKeys.Flights ? activeColor : primaryColor,
          }}
        />
      ),
    },
  ];
  return items;
};
