import { FC, useEffect } from "react";
import { Col, Form, Row } from "antd";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import DatePickerUi from "src/components/UiKit/DatePicker";
import { TabMenuKeys } from "src/modules/addEditTour/models";
import { convertDatePicker } from "src/helper";
import LoadingSpin from "src/components/loadingSpin";
import { useTourViewData } from "src/modules/viewTour/context/TourViewData";
import { useLeaderInfoRequest } from "./LeaderInfoRequest";

const LeaderForm: FC = () => {
  const { words } = useLanguage();

  const [leaderForm] = Form.useForm();

  const { states } = useTourViewData();
  const {
    states: { dataLoading },
  } = useLeaderInfoRequest();

  useEffect(() => {
    if (states.formData && states.current === TabMenuKeys.leader) {
      leaderForm.setFieldsValue({
        leaderInfo: states.formData.leader?.leaderInfo,
        leaderPhone: states.formData.leader?.leaderPhone,
        leaderFatherName: states.formData.leader?.leaderFatherName,
        leaderNationalCode: states.formData.leader?.leaderNationalCode,
        leaderIdNumber: states.formData.leader?.leaderIdNumber,
        leaderGuidCardNumber: states.formData.leader?.leaderGuidCardNumber,
        leaderBirthDate: convertDatePicker(
          states.formData.leader?.leaderBirthDate
        ),
      });
    }
  }, [leaderForm, states]);

  return dataLoading ? (
    <LoadingSpin />
  ) : (
    <Form form={leaderForm} disabled={true}>
      <Row gutter={[8, 16]} className="team-form-row">
        <Col xs={24} md={12}>
          <Form.Item name={"leaderInfo"}>
            <InputUiKit
              label={words.viewTour.firstName.concat(
                "-" + words.viewTour.lastName
              )}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"leaderPhone"}>
            <InputUiKit label={words.global.contact} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"leaderFatherName"}>
            <InputUiKit label={words.global.fatherName} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"leaderNationalCode"}>
            <InputUiKit label={words.global.nationalCode} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"leaderIdNumber"}>
            <InputUiKit label={words.global.idNumber} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"leaderBirthDate"}>
            <DatePickerUi label="تاریخ تولد" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"leaderGuidCardNumber"}>
            <InputUiKit label={words.viewTour.cardNumber} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default LeaderForm;
