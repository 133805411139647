import { FC, useEffect } from "react";
import { Col, Form, Row } from "antd";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import { useInsuranceRequest } from "./InsuranceRequest";
import ProgressBar from "src/components/progressBar";
import { FilterContentContainer } from "../../styles";
import { useTourViewData } from "../../context/TourViewData";

const InsuranceForm: FC = () => {
  const { words } = useLanguage();
  const [insuranceForm] = Form.useForm();

  const { states } = useTourViewData();
  const {
    states: { progress },
    requests,
  } = useInsuranceRequest();

  useEffect(() => {
    if (states.formData?.insurance) {
      insuranceForm.setFieldsValue({
        insuranceType: states.formData?.insurance.insuranceType,
        insuranceCompany: states.formData?.insurance.insuranceCompany,
      });
    }
  }, [insuranceForm, states.formData?.insurance]);

  return (
    <FilterContentContainer justify={"center"}>
      <Col xs={24} md={20} lg={16} className="form">
        <p className="title"></p>
        <Form form={insuranceForm} disabled={true}>
          <Row gutter={[16, 16]} className="form-row">
            <Col xs={24} md={12}>
              <Form.Item name={"insuranceType"}>
                <InputUiKit label={words.viewTour.carInsurance} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name={"insuranceCompany"}>
                <InputUiKit label={words.addEditTour.nationalityOfTourists} />
              </Form.Item>
            </Col>

            <Col span={24} className="upload">
              <p className="upload-title"></p>

              {progress > 0 ? (
                <ProgressBar />
              ) : (
                <label htmlFor="excel">
                  {states.formData?.insurance &&
                    states.formData.insurance.file && (
                      <img
                        src="/assets/images/excel.svg"
                        alt="excel"
                        width={66}
                        height={66}
                        onClick={() => requests.downloadInsuranceFileRequest()}
                      />
                    )}
                </label>
              )}
            </Col>
          </Row>
        </Form>
      </Col>
    </FilterContentContainer>
  );
};

export default InsuranceForm;
