import { FC, useEffect } from "react";
import { Col, Form, Row } from "antd";
import _ from "lodash";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import { useTourAddEditData } from "src/modules/addEditTour/context/TourAddEditData";
import { IObject } from "src/models/interfaces";
import DatePickerUi from "src/components/UiKit/DatePicker";
import { TabMenuKeys } from "src/modules/addEditTour/models";
import { convertDatePicker } from "src/helper";
import Actions from "../../Actions";
import { useLeaderInfoRequest } from "./LeaderInfoRequest";
import { ILeaderInfoArg } from "src/services/Tour/models";
import LoadingSpin from "src/components/loadingSpin";

const LeaderForm: FC = () => {
  const { words } = useLanguage();

  const [leaderForm] = Form.useForm();

  const { states, dispatches } = useTourAddEditData();
  const {
    states: { loading, dataLoading },
    requests: { addEditLeaderRequest },
  } = useLeaderInfoRequest();

  useEffect(() => {
    if (states.formData && states.current === TabMenuKeys.leader) {
      leaderForm.setFieldsValue({
        leaderInfo: states.formData.leader?.leaderInfo,
        leaderPhone: states.formData.leader?.leaderPhone,
        leaderFatherName: states.formData.leader?.leaderFatherName,
        leaderNationalCode: states.formData.leader?.leaderNationalCode,
        leaderIdNumber: states.formData.leader?.leaderIdNumber,
        leaderGuidCardNumber: states.formData.leader?.leaderGuidCardNumber,
        leaderBirthDate: convertDatePicker(
          states.formData.leader?.leaderBirthDate
        ),
      });
    }
  }, [leaderForm, states]);

  const handleFinishLeaderForm = (values: IObject) => {
    if (values.leaderBirthDate)
      values.leaderBirthDate = values?.leaderBirthDate.$d;
    if (_.isEqual(states.formData?.leader, values))
      dispatches.setCurrent(TabMenuKeys.translator);
    else {
      addEditLeaderRequest(values as ILeaderInfoArg);
    }
  };

  return dataLoading ? (
    <LoadingSpin />
  ) : (
    <Form form={leaderForm} onFinish={handleFinishLeaderForm}>
      <Row gutter={[8, 16]} className="team-form-row">
        <Col xs={24} md={12}>
          <Form.Item
            name={"leaderInfo"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit
              label={words.viewTour.firstName.concat(
                "-" + words.viewTour.lastName
              )}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"leaderPhone"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit label={words.global.contact} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"leaderFatherName"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit label={words.global.fatherName} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"leaderNationalCode"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit label={words.global.nationalCode} numbermode />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"leaderIdNumber"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit label={words.global.idNumber} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"leaderBirthDate"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <DatePickerUi label="تاریخ تولد" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"leaderGuidCardNumber"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit label={words.viewTour.cardNumber} />
          </Form.Item>
        </Col>
        <Actions
          loading={loading}
          onPrevious={() => dispatches.setCurrent(TabMenuKeys.manager)}
        />
      </Row>
    </Form>
  );
};

export default LeaderForm;
