import {
  FC,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { BasicInformationService } from "src/services/BasicInformation/BasicInformation.service";
import { IContact } from "src/services/BasicInformation/models";
import { useHotelsData } from "../../context/HotelsData";

interface IContextValue {
  states: {
    contactsLoading: boolean;
    responderDetails: IContact[];
  };
}

export const HotelsViewDataContext = createContext<IContextValue | undefined>(
  undefined
);

const HotelsViewDataProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [responderDetails, setResponderDetails] = useState<IContact[]>([]);
  const [contactsLoading, setContactsLoading] = useState<boolean>(false);

  const {
    states: { selectedHotel },
  } = useHotelsData();

  const getContacts = useCallback(async () => {
    if (!selectedHotel) return;
    setContactsLoading(true);
    try {
      const { Contacts } = new BasicInformationService();
      const res = await Contacts(selectedHotel.id);
      if (res && res.status === 200 && res.data) {
        setContacts(res.data.records);
      } else {
        setContacts([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  }, [selectedHotel]);

  const getResponderDetails = useCallback(async () => {
    if (contacts.length === 0) {
      setContactsLoading(false);
      return;
    }
    setContactsLoading(true);
    setResponderDetails([]);
    try {
      const { ResponderDetailsById } = new BasicInformationService();
      contacts?.forEach(async (responder, index) => {
        await ResponderDetailsById(responder.id)
          .then((detail) => {
            if (detail) {
              setResponderDetails((prev) => [
                ...prev,
                {
                  id: responder.id,
                  hotelId: responder.hotelId,
                  name: responder.name,
                  hotelResponderDetails:
                    detail.data.records[0].hotelResponderDetails,
                },
              ]);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } finally {
      setContactsLoading(false);
    }
  }, [contacts]);

  useEffect(() => {
    getResponderDetails();
  }, [getResponderDetails]);

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  const contextValues = {
    states: { contactsLoading, responderDetails },
  };

  return (
    <HotelsViewDataContext.Provider value={contextValues}>
      {children}
    </HotelsViewDataContext.Provider>
  );
};

export default HotelsViewDataProvider;

export const useHotelsViewData = () => useContext(HotelsViewDataContext)!;
