import { FC, ReactNode } from "react";
import { ReportTabHeaderStyle } from "./style";
import { Flex, TabsProps } from "antd";
interface IProps {
  items: TabsProps["items"];
  activeKey: string;
  onTabClick: (
    key: string,
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => void;
  icon?: ReactNode;
}
export const ReportTabHeader: FC<IProps> = ({
  items,
  activeKey,
  onTabClick,
}) => {
  return (
    <Flex justify="center" align="center" className="my-[32px]">
      <ReportTabHeaderStyle justify="center" align="center" gap={16}>
        {items?.map((item) => (
          <div
            onClick={(e) => onTabClick(item.key, e)}
            className={`item ${
              activeKey === item.key
                ? "active"
                : "text-[12px] text-[#1F1F1F] font-bold"
            }`}
            key={item.key}
          >
            <span className="flex items-center">{item.icon}</span>
            <span> {item.label}</span>
          </div>
        ))}
      </ReportTabHeaderStyle>
    </Flex>
  );
};
