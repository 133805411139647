import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useParams } from "react-router-dom";
import { useTourViewData } from "src/modules/viewTour/context/TourViewData";
import { TourService } from "src/services/Tour/Tour.service";

interface IContextValue {
  states: {
    dataLoading: boolean;
  };
}

export const DriverInfoRequestContext = createContext<
  IContextValue | undefined
>(undefined);

const DriverInfoRequestProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const { dispatches } = useTourViewData();
  const { tourId } = useParams();

  const getDriverInfo = useCallback(async () => {
    if (!tourId) return;
    try {
      setDataLoading(true);
      const { GetDriverInfo } = new TourService();

      const result = await GetDriverInfo(tourId);
      if (result && result.status === 200) {
        dispatches.setFormData((prev) => ({
          ...prev,
          driver: result.data,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tourId) {
      getDriverInfo();
    }
  }, [getDriverInfo, tourId]);

  const contextValues = {
    states: { dataLoading },
  };

  return (
    <DriverInfoRequestContext.Provider value={contextValues}>
      {children}
    </DriverInfoRequestContext.Provider>
  );
};

export default DriverInfoRequestProvider;

export const useDriverInfoRequest = () => useContext(DriverInfoRequestContext)!;
