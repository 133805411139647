import {
  App,
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Popover,
  Row,
} from "antd";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { EditProfileContainer } from "./editProfile.styles";
import useGlobalStore from "src/store/global";
import { UserOutlined } from "@ant-design/icons";
import { USER_DATA } from "src/constants";
import { FormItem } from "src/components/UiKit/FormItem";
import { IdentityService } from "src/services/Identity/Identity.service";
import { IEditProfile } from "src/services/Identity/models";
import { useUserInfo } from "src/hook/useUserInfo";
import { useMediaQuery } from "src/hook";
import { breakPointsSm } from "src/constants/screen";
import InputUiKit from "src/components/UiKit/Input";
import useLanguage from "src/store/language";

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const EditProfile: FC<IProps> = ({ isModalOpen, setIsModalOpen }) => {
  const isMobileDevice = !useMediaQuery(breakPointsSm);
  const { message } = App.useApp();
  const showMessage = () => {
    message.success(`${words.editProfile.successMessage}`);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const { userInfo } = useGlobalStore();
  const [userRole, setUserRole] = useState<string[]>([]);

  const { userInfoRefetch } = useUserInfo();
  const [loading, setLoading] = useState<boolean>(false);
  const [editProfileForm] = Form.useForm();
  const { words } = useLanguage();

  useEffect(() => {
    const userRoleInLocal = localStorage.getItem(USER_DATA);
    if (userRoleInLocal) {
      setUserRole(JSON.parse(userRoleInLocal));
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      editProfileForm.setFieldsValue({
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
      });
    }
  }, [editProfileForm, userInfo, isModalOpen]);

  const onFinish = async (values: IEditProfile) => {
    try {
      setLoading(true);
      const { UpdateUserInfo } = new IdentityService();
      const result = await UpdateUserInfo(values);
      if (result && result.status === 200) {
        userInfoRefetch();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      showMessage();
    }
  };
  return (
    <Modal
      width={isMobileDevice ? "95%" : "30%"}
      title={words.mainLayout.editProfile}
      open={isModalOpen}
      closable={false}
      onCancel={handleCancel}
      styles={{ body: { height: "550px" } }}
      footer={null}
      afterClose={() => {
        editProfileForm.resetFields();
      }}
      className="relative"
      centered
      getContainer={false}
    >
      <EditProfileContainer>
        <Form className="w-[100%] " form={editProfileForm} onFinish={onFinish}>
          <div className=" flex flex-col items-center gap-[16px]">
            <div className="flex items-end mt-[16px] relative">
              <div className="changePicture flex justify-center items-center absolute z-10">
                <span className="material-icons text-[#fff] text-[16px]">
                  flip_camera_ios
                </span>
              </div>

              <Avatar
                className="avatarBorder"
                size={88}
                icon={<UserOutlined />}
              />
            </div>
            <div className="flex flex-row items-center mb-[20px]">
              <div className="username">
                {userInfo?.firstName} {userInfo?.lastName}
              </div>
              <Divider type="vertical" dashed className="divider1" />
              {userRole && userRole.join(" , ").length > 30 ? (
                <Popover title={userRole.join(" , ")}>
                  <div className="userRole">
                    {userRole.join(" , ").substring(0, 30).concat("...")}
                  </div>
                </Popover>
              ) : (
                <div className="userRole">{userRole?.join(" , ")}</div>
              )}
            </div>
          </div>
          <div>
            <div className="nameHolder">{words.global.insertInfo}</div>
            <Row
              justify={"center"}
              gutter={[8, 0]}
              className="inputs-container"
            >
              <Col span={20}>
                <FormItem
                  name="firstName"
                  label={words.editProfile.firstName}
                  rules={[
                    { required: true, message: words.global.fieldRequired },
                  ]}
                  required={false}
                >
                  <InputUiKit placeholder={words.editProfile.firstName} />
                </FormItem>
              </Col>
              <Col span={20}>
                <FormItem
                  name="lastName"
                  label={words.editProfile.lastName}
                  rules={[
                    { required: true, message: words.global.fieldRequired },
                  ]}
                  required={false}
                >
                  <InputUiKit placeholder={words.editProfile.lastName} />
                </FormItem>
              </Col>
            </Row>
          </div>
          <div className="flex justify-center gap-[24px] footer absolute">
            <Button
              className="w-[30%] save"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              {words.global.save}
            </Button>
            <Button
              className="w-[30%]"
              htmlType="button"
              onClick={() => {
                handleCancel();
                editProfileForm.resetFields();
              }}
            >
              {words.global.close}
            </Button>
          </div>
        </Form>
      </EditProfileContainer>
    </Modal>
  );
};
