import { IDataModel } from "../models";
import {
  IAllTouristResult,
  IAssignSimcard,
  ICompanionLeader,
  IDashboardInfo,
  IDomesticFlight,
  IDriver,
  IDriverArg,
  IInsuranceDocument,
  ILeader,
  IManagementHotel,
  IManagementInsurance,
  IManagementRoute,
  IManagementSimcardBuy,
  IManagementTour,
  IManagementTourist,
  INewAssignSimcardArg,
  INewBuySimcardArg,
  INewCompanionLeaderArg,
  INewDomesticFlightArgs,
  INewHotelArg,
  INewInsuranceArg,
  INewManagementRouteArg,
  INewRoutePlaceArg,
  INewRouteRoomArg,
  INewTourArg,
  INewTourTimeArg,
  INewTouristArg,
  IRoutePlaceResult,
  IRouteRoomResult,
  ISupervisorArg,
  ITour,
  ITourCode,
  ITourDocument,
  ITourManagement,
  ITourTime,
  ITourist,
  ITranslator,
  ITranslatorArg,
} from "./models";
import { TourManagementUrls } from "./urls";
import axios, { AxiosRequestConfig } from "axios";
import { Buffer } from "buffer";

export class TourManagementService {
  async AddManagementHotel(tourId: number | string, data: INewHotelArg) {
    try {
      const response = await axios.post<void>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.NewHotel
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AllManagementHotel(tourId: number | string, search?: string) {
    let endpoint: string = TourManagementUrls.Hotels;
    if (search) endpoint = endpoint.concat(search);
    try {
      const response = await axios.get<IDataModel<IManagementHotel[]>>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          endpoint
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditManagementHotel(hotelId: number, data: INewHotelArg) {
    try {
      const response = await axios.post<void>(
        TourManagementUrls.TourManagement.concat(hotelId.toString()).concat(
          TourManagementUrls.EditHotel
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteHotel(id: number) {
    try {
      const response = await axios.delete<void>(
        TourManagementUrls.TourManagement.concat(id.toString()).concat(
          TourManagementUrls.DeleteHotel
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AddManagementRoute(
    tourId: number | string,
    data: INewManagementRouteArg
  ) {
    try {
      const response = await axios.post<void>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.NewRoute
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AllManagementRoute(tourId: number | string, search?: string) {
    let endpoint: string = TourManagementUrls.Routes;
    if (search) endpoint = endpoint.concat(`?${search}`);
    try {
      const response = await axios.get<IDataModel<IManagementRoute[]>>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          endpoint
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditManagementRoute(hotelId: number, data: INewManagementRouteArg) {
    try {
      const response = await axios.put<void>(
        TourManagementUrls.TourManagement.concat(hotelId.toString()).concat(
          TourManagementUrls.EditRoute
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteRoute(id: number) {
    try {
      const response = await axios.delete<void>(
        TourManagementUrls.TourManagement.concat(id.toString()).concat(
          TourManagementUrls.DeleteRoute
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AddManagementTime(tourId: number | string, data: INewTourTimeArg) {
    try {
      const response = await axios.post<void>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.NewTime
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditManagementTime(timeId: number, data: INewTourTimeArg) {
    try {
      const response = await axios.put<void>(
        TourManagementUrls.TourManagement.concat(timeId.toString()).concat(
          TourManagementUrls.EditTime
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetManagementTime(tourId: number | string) {
    let endpoint: string = TourManagementUrls.GetTime;
    try {
      const response = await axios.get<ITourTime>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          endpoint
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteTime(id: number) {
    try {
      const response = await axios.delete<void>(
        TourManagementUrls.TourManagement.concat(id.toString()).concat(
          TourManagementUrls.DeleteTime
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AllManagementTourist(tourId: number | string, search?: string) {
    let endpoint: string = TourManagementUrls.Tourists;
    if (search) endpoint = endpoint.concat(search);
    try {
      const response = await axios.get<IDataModel<IManagementTourist[]>>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          endpoint
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetManagementBuySimcard(tourId: number | string) {
    let endpoint: string = TourManagementUrls.GetBuySimcard;
    try {
      const response = await axios.get<IManagementSimcardBuy>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          endpoint
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async ManagementBuySimcard(tourId: number | string, data: INewBuySimcardArg) {
    try {
      const response = await axios.post<void>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.BuySimcard
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async EditBuySimcard(buySimcardId: number | string, data: INewBuySimcardArg) {
    try {
      const response = await axios.put<void>(
        TourManagementUrls.TourManagement.concat(
          buySimcardId.toString()
        ).concat(TourManagementUrls.EditBuySimcard),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteBuySimcard(id: number) {
    try {
      const response = await axios.delete<void>(
        TourManagementUrls.TourManagement.concat(id.toString()).concat(
          TourManagementUrls.DeleteBuySimcard
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async NewAssignSimcard(tourId: number, data: INewAssignSimcardArg) {
    try {
      const response = await axios.post<void>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.NewAssignSimcard
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AllAssignSimcards(tourId: number, search?: string) {
    let endpoint: string = TourManagementUrls.AllAssignSimcards;
    if (search) endpoint = endpoint.concat(`?${search}`);
    try {
      const response = await axios.get<IDataModel<IAssignSimcard[]>>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          endpoint
        )
      );
      return response;
    } catch (err) {
      return console.log(err);
    }
  }

  async EditAssignSimcard(assignId: number, data: INewAssignSimcardArg) {
    try {
      const response = await axios.put<void>(
        TourManagementUrls.TourManagement.concat(assignId.toString()).concat(
          TourManagementUrls.EditAssignSimcard
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteAssignSimcard(id: number) {
    try {
      const response = await axios.delete<void>(
        TourManagementUrls.TourManagement.concat(id.toString()).concat(
          TourManagementUrls.DeleteAssignSimcard
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  //-----------------------Start Insurance-----------------

  async GetManagementInsurance(tourId: number | string, queries: string) {
    let endpoint: string = TourManagementUrls.GetInsuranceInfo;
    if (queries) endpoint = endpoint.concat(`?${queries}`);

    try {
      const response = await axios.get<IDataModel<IManagementInsurance[]>>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          endpoint
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AddInsurance(tourId: number, data: INewInsuranceArg) {
    try {
      const response = await axios.post<void>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.AddInsuranceInfo
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async EditInsurance(insuranceId: number | string, data: INewInsuranceArg) {
    try {
      const response = await axios.put<void>(
        TourManagementUrls.TourManagement.concat(insuranceId.toString()).concat(
          TourManagementUrls.EditInsuranceInfo
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteInsurance(insuranceId: number) {
    try {
      const response = await axios.delete<void>(
        TourManagementUrls.TourManagement.concat(insuranceId.toString()).concat(
          TourManagementUrls.DeleteInsuranceInfo
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DownloadInsuranceFile(
    id: number | string,
    config?: AxiosRequestConfig
  ) {
    const newConfig: AxiosRequestConfig<any> = {
      responseType: "arraybuffer",
      headers: {
        accept: "*/*",
      },
      ...config,
    };
    try {
      const response = await axios.get<Buffer>(
        TourManagementUrls.TourManagement.concat(id.toString()).concat(
          TourManagementUrls.DownloadInsuranceFile
        ),
        newConfig
      );
      return { buffer: response.data, type: response.headers["content-type"] };
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetInsuranceDocument(tourId: number | string, queries?: string) {
    let endpoint: string = TourManagementUrls.GetInsuranceDocument;
    if (queries) endpoint = endpoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDataModel<IInsuranceDocument[]>>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          endpoint
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AddManagementInsuranceDocument(
    insuranceDocumentId: number,
    data: FormData
  ) {
    try {
      const response = await axios.post<void>(
        TourManagementUrls.TourManagement.concat(
          insuranceDocumentId.toString()
        ).concat(TourManagementUrls.AddInsuranceDocument),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteInsuranceDocument(id: number | string) {
    try {
      const response = await axios.delete<void>(
        TourManagementUrls.TourManagement.concat(id.toString()).concat(
          TourManagementUrls.DeleteInsuranceDocument
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  //-----------------------End Insurance-----------------

  //------------------Start Tour Attachments---------------------
  async DownloadTourFile(id: number | string, config?: AxiosRequestConfig) {
    const newConfig: AxiosRequestConfig<any> = {
      responseType: "arraybuffer",
      headers: {
        accept: "*/*",
      },
      ...config,
    };
    try {
      const response = await axios.get<Buffer>(
        TourManagementUrls.TourManagement.concat(id.toString()).concat(
          TourManagementUrls.DownloadTourFile
        ),
        newConfig
      );
      return { buffer: response.data, type: response.headers["content-type"] };
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetTourDocument(tourId: number | string, queries: string) {
    let endpoint: string = TourManagementUrls.GetTourDocument;
    if (queries) endpoint = endpoint.concat(`?${queries}`);
    try {
      const response = await axios.get<IDataModel<ITourDocument[]>>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          endpoint
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteTourDocument(id: number | string) {
    try {
      const response = await axios.delete<void>(
        TourManagementUrls.TourManagement.concat(id.toString()).concat(
          TourManagementUrls.DeleteTourDocument
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewManagementTourDocument(tourId: number | string, data: FormData) {
    try {
      const response = await axios.post<void>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.NewTourDocument
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EDitManagementTourDocument(documentId: number, data: FormData) {
    try {
      const response = await axios.put<void>(
        TourManagementUrls.TourManagement.concat(documentId.toString()).concat(
          TourManagementUrls.EditTourDocument
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  //------------------End Tour Attachments---------------------
  async NewManagementInsuranceDocument(
    insuranceDocumentId: number,
    data: FormData
  ) {
    try {
      const response = await axios.post<void>(
        TourManagementUrls.TourManagement.concat(
          insuranceDocumentId.toString()
        ).concat(TourManagementUrls.AddInsuranceDocument),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditManagementInsuranceDocument(
    insuranceDocumentId: number,
    data: FormData
  ) {
    try {
      const response = await axios.put<void>(
        TourManagementUrls.TourManagement.concat(
          insuranceDocumentId.toString()
        ).concat(TourManagementUrls.EditInsuranceDocument),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async Supervisor(tourId: number | string, data: ISupervisorArg) {
    try {
      const response = await axios.put<void>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.Supervisor
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetSupervisor(tourId: number | string) {
    try {
      const response = await axios.get<ISupervisorArg>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.GetSupervisor
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AddTranslator(tourId: number | string, data: ITranslatorArg) {
    try {
      const response = await axios.post<void>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.AddTranslator
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditTranslator(translatorId: number | string, data: ITranslatorArg) {
    try {
      const response = await axios.put<void>(
        TourManagementUrls.TourManagement.concat(
          translatorId.toString()
        ).concat(TourManagementUrls.EditTranslator),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetTranslator(tourId: number | string) {
    try {
      const response = await axios.get<ITranslator>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.GetTranslator
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteTranslator(id: number) {
    try {
      const response = await axios.delete<void>(
        TourManagementUrls.TourManagement.concat(id.toString()).concat(
          TourManagementUrls.DeleteTranslator
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async NewDriver(tourId: number | string, data: IDriverArg) {
    try {
      const response = await axios.post<void>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.AddDriver
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async UpdateDriver(driverId: number | string, data: IDriverArg) {
    try {
      const response = await axios.put<void>(
        TourManagementUrls.TourManagement.concat(driverId.toString()).concat(
          TourManagementUrls.EditDriver
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetDriver(tourId: number | string) {
    try {
      const response = await axios.get<IDriver>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.GetDriver
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteDriver(id: number) {
    try {
      const response = await axios.delete<void>(
        TourManagementUrls.TourManagement.concat(id.toString()).concat(
          TourManagementUrls.DeleteDriver
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async NewDomesticFlight(tourId: number, data: INewDomesticFlightArgs) {
    try {
      const response = await axios.post<void>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.NewDomesticFlight
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AddTour(data: INewTourArg) {
    try {
      const response = await axios.post<void>(TourManagementUrls.AddTour, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditTour(id: number | string, data: INewTourArg) {
    try {
      const response = await axios.put<void>(
        TourManagementUrls.TourManagement.concat(id.toString()).concat(
          TourManagementUrls.EditTour
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AllDomesticFlight(tourId: number, search?: string) {
    let endpoint: string = TourManagementUrls.TourManagement.concat(
      `${tourId}/${TourManagementUrls.AllDomesticFlight}`
    );
    if (search) endpoint = endpoint.concat(`?${search}`);
    try {
      const response = await axios.get<IDataModel<IDomesticFlight[]>>(endpoint);
      return response;
    } catch (err) {
      return console.log(err);
    }
  }

  async EditDomesticFlight(
    domesticFlightId: number,
    data: INewDomesticFlightArgs
  ) {
    try {
      const response = await axios.put<void>(
        TourManagementUrls.TourManagement.concat(
          domesticFlightId.toString()
        ).concat(TourManagementUrls.EditDomesticFlight),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteDomesticFlight(id: number) {
    try {
      const response = await axios.delete<void>(
        TourManagementUrls.TourManagement.concat(id.toString()).concat(
          TourManagementUrls.DeleteDomesticFlight
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetTourById(id: number | string) {
    try {
      const response = await axios.get<IManagementTour>(
        TourManagementUrls.TourManagement.concat(id.toString()).concat(
          TourManagementUrls.GetTourById
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AddCompanionLeader(
    tourId: number | string,
    data: INewCompanionLeaderArg
  ) {
    try {
      const response = await axios.post<void>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.AddCompanionLeader
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditCompanionLeader(
    companionLeaderId: number | string,
    data: INewCompanionLeaderArg
  ) {
    try {
      const response = await axios.put<void>(
        TourManagementUrls.TourManagement.concat(
          companionLeaderId.toString()
        ).concat(TourManagementUrls.EditCompanionLeader),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetCompanionLeader(tourId: number | string) {
    try {
      const response = await axios.get<ICompanionLeader>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.GetCompanionLeader
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteCompanionLeader(id: number) {
    try {
      const response = await axios.delete<void>(
        TourManagementUrls.TourManagement.concat(id.toString()).concat(
          TourManagementUrls.DeleteCompanionLeader
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AddLeader(tourId: number | string, data: FormData) {
    try {
      const response = await axios.post<void>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.AddLeader
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditLeader(leaderId: number | string, data: FormData) {
    try {
      const response = await axios.put<void>(
        TourManagementUrls.TourManagement.concat(leaderId.toString()).concat(
          TourManagementUrls.EditLeader
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetLeader(tourId: number | string) {
    try {
      const response = await axios.get<ILeader>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.GetLeader
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteLeader(id: number) {
    try {
      const response = await axios.delete<void>(
        TourManagementUrls.TourManagement.concat(id.toString()).concat(
          TourManagementUrls.DeleteLeader
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetLeaderCardImage(tourId: number | string) {
    try {
      const config: AxiosRequestConfig<any> = {
        responseType: "arraybuffer",
        headers: {
          accept: "*/*",
        },
      };
      const response = await axios.get<Buffer>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.GetLeaderCardImage
        ),
        config
      );

      if (response) {
        let raw = Buffer.from(response.data).toString("base64");
        return "data:" + response.headers["content-type"] + ";base64," + raw;
      }
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetLeaderNationalCardImage(tourId: number | string) {
    try {
      const config: AxiosRequestConfig<any> = {
        responseType: "arraybuffer",
        headers: {
          accept: "*/*",
        },
      };
      const response = await axios.get<Buffer>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.GetLeaderNationalCardImage
        ),
        config
      );

      if (response) {
        let raw = Buffer.from(response.data).toString("base64");
        return "data:" + response.headers["content-type"] + ";base64," + raw;
      }
    } catch (err) {
      throw console.log(err);
    }
  }

  async Tours(queries?: string) {
    let endpoint: string = TourManagementUrls.Tours;
    if (queries) endpoint = endpoint.concat(`${queries}`);

    try {
      const response = await axios.get<IDataModel<ITour[]>>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async NewTourist(tourId: number, data: INewTouristArg) {
    try {
      const response = await axios.post<void>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.AddTourist
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AllTourist(tourId: number | string, search?: string) {
    let endpoint: string = TourManagementUrls.TourManagement.concat(
      `${tourId}/${TourManagementUrls.GetTourist}`
    );
    if (search) endpoint = endpoint.concat(`?${search}`);
    try {
      const response = await axios.get<IDataModel<IAllTouristResult[]>>(
        endpoint
      );
      return response;
    } catch (err) {
      return console.log(err);
    }
  }

  async GetTouristByPassport(PassportNo: string) {
    let endpoint: string = TourManagementUrls.TourManagement.concat(
      `${PassportNo}${TourManagementUrls.GetTouristByPassport}`
    );
    try {
      const response = await axios.get<ITourist>(endpoint);
      return response;
    } catch (err) {
      return console.log(err);
    }
  }

  async EditTourist(touristId: number, data: INewTouristArg) {
    try {
      const response = await axios.put<void>(
        TourManagementUrls.TourManagement.concat(touristId.toString()).concat(
          TourManagementUrls.EditTourist
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteTourist(id: number) {
    try {
      const response = await axios.delete<void>(
        TourManagementUrls.TourManagement.concat(id.toString()).concat(
          TourManagementUrls.DeleteTourist
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async NewListTourist(tourId: number, data: INewTouristArg[]) {
    try {
      const response = await axios.post<void>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.AddListTourist
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetTourManagement(tourId: number | string) {
    try {
      const response = await axios.get<ITourManagement>(
        TourManagementUrls.TourManagement.concat(tourId.toString()).concat(
          TourManagementUrls.GetTourManagement
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AddManagementRouteRoom(
    routeId: number | string,
    data: INewRouteRoomArg
  ) {
    try {
      const response = await axios.post<void>(
        TourManagementUrls.TourManagement.concat(routeId.toString()).concat(
          TourManagementUrls.NewRouteRoom
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AllManagementRouteRoom(routeId: number | string, search?: string) {
    let endpoint: string = TourManagementUrls.RouteRooms;
    if (search) endpoint = endpoint.concat(`?${search}`);
    try {
      const response = await axios.get<IDataModel<IRouteRoomResult[]>>(
        TourManagementUrls.TourManagement.concat(routeId.toString()).concat(
          endpoint
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditManagementRouteRoom(routeRoomId: number, data: INewRouteRoomArg) {
    try {
      const response = await axios.put<void>(
        TourManagementUrls.TourManagement.concat(routeRoomId.toString()).concat(
          TourManagementUrls.EditRouteRoom
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteRouteRoom(routeRoomId: number) {
    try {
      const response = await axios.delete<void>(
        TourManagementUrls.TourManagement.concat(routeRoomId.toString()).concat(
          TourManagementUrls.DeleteRouteRoom
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AddManagementRoutePlace(
    routeId: number | string,
    data: INewRoutePlaceArg
  ) {
    try {
      const response = await axios.post<void>(
        TourManagementUrls.TourManagement.concat(routeId.toString()).concat(
          TourManagementUrls.NewRoutePlace
        ),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AllManagementRoutePlace(routeId: number | string, search?: string) {
    let endpoint: string = TourManagementUrls.RoutePlaces;
    if (search) endpoint = endpoint.concat(`?${search}`);
    try {
      const response = await axios.get<IDataModel<IRoutePlaceResult[]>>(
        TourManagementUrls.TourManagement.concat(routeId.toString()).concat(
          endpoint
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditManagementRoutePlace(
    routePlaceId: number,
    data: INewRoutePlaceArg
  ) {
    try {
      const response = await axios.put<void>(
        TourManagementUrls.TourManagement.concat(
          routePlaceId.toString()
        ).concat(TourManagementUrls.EditRoutePlace),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteRoutePlace(routePlaceId: number) {
    try {
      const response = await axios.delete<void>(
        TourManagementUrls.TourManagement.concat(
          routePlaceId.toString()
        ).concat(TourManagementUrls.DeleteRoutePlace)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteTour(id: number | string) {
    try {
      const response = await axios.delete<void>(
        TourManagementUrls.TourManagement.concat(id.toString()).concat(
          TourManagementUrls.DeleteTour
        )
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetDashboardInfo() {
    try {
      const response = await axios.get<IDashboardInfo>(
        TourManagementUrls.GetDashboardInfo
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetToursCode(queries?: string) {
    let endpoint: string = TourManagementUrls.GetAllTourCode;
    if (queries) endpoint = endpoint.concat(`?${queries}`);

    try {
      const response = await axios.get<IDataModel<ITourCode[]>>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
}
