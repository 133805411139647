import { FC, useState } from "react";
import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import useLanguage from "src/store/language";
import { TableUI } from "src/components/UiKit/table";
import { IAttachFile } from "src/services/Tour/models";
import AttachmentModal from "./AttachmentModal";
import { useAttachmentRequest } from "./AttachmentRequest";

const AttachTable: FC = () => {
  const [selectedFile, setSelectedFile] = useState<IAttachFile | undefined>();

  const { states } = useAttachmentRequest();

  const {
    states: { dataLoading, totalCount, pagination },
    dispatches,
  } = useAttachmentRequest();

  const { words } = useLanguage();

  const columns: ColumnsType<IAttachFile> = [
    {
      title: `#`,
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: `${words.addEditTour.file}`,
      dataIndex: "file",
      key: "file",
      width: 30,
      render: (value, record) => (
        <Button
          type="text"
          className="p-0 hover:p-0 hover:bg-[#ffffff]"
          onClick={() => setSelectedFile(record)}
        >
          <span className="material-icons">folder</span>
        </Button>
      ),
    },
    {
      title: `${words.addEditTour.description}`,
      dataIndex: "comment",
      key: "comment",
    },
  ];

  return (
    <>
      <AttachmentModal
        open={selectedFile ? true : false}
        onCancel={() => setSelectedFile(undefined)}
        attach={selectedFile}
      />
      <p className="attach-title">{words.viewTour.attachments}</p>
      <TableUI
        loading={dataLoading}
        scroll={{ x: 400 }}
        dataSource={states.files}
        columns={columns}
        tableType="secondary"
        pagination={{
          position: ["bottomCenter"],
          total: totalCount,
          pageSize: pagination.Limit,
          current: pagination.Offset,
          onChange(page, pageSize) {
            dispatches.setPagination((prev) => ({
              ...prev,
              Offset: page,
              Limit: pageSize,
            }));
          },
        }}
      />
    </>
  );
};

export default AttachTable;
