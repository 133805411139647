import { FC, useEffect } from "react";
import { Col, Form, Row } from "antd";
import InputUiKit from "src/components/UiKit/Input";
import useLanguage from "src/store/language";
import { TabMenuKeys } from "src/modules/addEditTour/models";
import LoadingSpin from "src/components/loadingSpin";
import { useTourViewData } from "src/modules/viewTour/context/TourViewData";
import { useCarInfoRequest } from "./CarInfoRequest";

const CarTabForm: FC = () => {
  const { words } = useLanguage();

  const [carForm] = Form.useForm();

  const { states } = useTourViewData();
  const {
    states: { dataLoading },
  } = useCarInfoRequest();

  useEffect(() => {
    if (states.formData && states.current === TabMenuKeys.car) {
      carForm.setFieldsValue({
        carInfo: states.formData.car?.carInfo,
        carNumber: states.formData.car?.carNumber,
        carInsurance: states.formData.car?.carInsurance,
        carInsuranceCompany: states.formData.car?.carInsuranceCompany,
      });
    }
  }, [carForm, states]);

  return dataLoading ? (
    <LoadingSpin />
  ) : (
    <Form form={carForm} disabled={true}>
      <Row gutter={[8, 16]} className="team-form-row">
        <Col xs={24} md={12}>
          <Form.Item name={"carInfo"}>
            <InputUiKit label={words.viewTour.carName} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"carNumber"}>
            <InputUiKit label={words.viewTour.carNumber} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"carInsurance"}>
            <InputUiKit label={words.viewTour.carInsurance} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"carInsuranceCompany"}>
            <InputUiKit label={words.viewTour.carInsuranceCompany} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default CarTabForm;
