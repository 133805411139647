import { FC, useEffect } from "react";
import { Col, Form, Row } from "antd";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import { TabMenuKeys } from "src/modules/addEditTour/models";
import LoadingSpin from "src/components/loadingSpin";
import { useTourViewData } from "src/modules/viewTour/context/TourViewData";
import { useManagerInfoRequest } from "./ManagerInfoRequest";

const ManagerForm: FC = () => {
  const { words } = useLanguage();

  const [managerForm] = Form.useForm();

  const { states } = useTourViewData();
  const {
    states: { dataLoading },
  } = useManagerInfoRequest();

  useEffect(() => {
    if (states.formData && states.current === TabMenuKeys.manager) {
      managerForm.setFieldsValue({
        managerInfo: states.formData.manager?.managerInfo,
        managerPhone: states.formData.manager?.managerPhone,
      });
    }
  }, [managerForm, states]);

  return dataLoading ? (
    <LoadingSpin />
  ) : (
    <Form form={managerForm} disabled={true}>
      <Row gutter={[8, 16]} className="team-form-row">
        <Col xs={24} md={12}>
          <Form.Item name={"managerInfo"}>
            <InputUiKit
              label={words.viewTour.firstName.concat(
                "-" + words.viewTour.lastName
              )}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={"managerPhone"}>
            <InputUiKit label={words.global.contact} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ManagerForm;
