import { TableColumnsType } from "antd";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
import momentJalali from "moment-jalaali";
import { IGetExportRoute } from "src/services/ExportData/models";
interface IProps {
  data: IGetExportRoute;
  columns: TableColumnsType;
}
export const generateRoutesExcel = async ({ data, columns }: IProps) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");

  // ادغام سلول‌ها
  worksheet.mergeCells("C3:J3");
  worksheet.mergeCells("C4:J4");
  worksheet.columns.forEach((column) => {
    column.width = 15;
    column.alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };
    column.font = {
      name: "Tahoma",
      size: 12,
      color: { argb: "000000" },
    };
  });

  const carNameCell = worksheet.getCell("C3");
  carNameCell.value = data.carName;
  carNameCell.font = {
    name: "Tahoma",
    size: 14,
    color: { argb: "000000" },
  };
  carNameCell.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFFFFF" },
  };
  carNameCell.alignment = {
    horizontal: "center",
    vertical: "middle",
    wrapText: true,
  };

  const leaderInfoCell = worksheet.getCell("C4");
  leaderInfoCell.value = ` میثاق گشت (${data.touristCount}) نفر  -  تورلیدر: ${data.leaderName} موبایل: ${data.leaderPhone}`;
  leaderInfoCell.font = {
    name: "Tahoma",
    size: 14,
    color: { argb: "000000" },
  };
  leaderInfoCell.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "8E8E8E" },
  };
  leaderInfoCell.alignment = {
    horizontal: "center",
    vertical: "middle",
    wrapText: true,
  };

  const titleRow = worksheet.getRow(3);
  titleRow.height = 40;

  const header: string[] = columns.map((column) => column.title as string);
  const excelData = data.routeDetails.map((item, index) => {
    const arrivalDate = item.arrivalDate
      ? momentJalali(item.arrivalDate)
          .format("jYYYY/jMM/jDD")
          .concat("\n")
          .concat(moment(item.arrivalDate).format("YYYY/MM/DD"))
      : "";
    const departureDate = item.departureDate
      ? momentJalali(item.departureDate)
          .format("jYYYY/jMM/jDD")
          .concat("\n")
          .concat(moment(item.departureDate).format("YYYY/MM/DD"))
      : "";
    const places = item.placeOfInterests.map((place) => place.name);
    return [
      "",
      "",
      index + 1,
      item.city,
      item.inlineCities,
      item.flightTime,
      arrivalDate,
      departureDate,
      item.hotelName,
      places.join("-"),
    ];
  });
  // داده‌های جدول
  const tableData = [[], ["", "", ...header], ...excelData];

  // افزودن داده‌ها به شیت
  tableData.forEach((row, index) => {
    worksheet.addRow(row);
  });
  const headerRow = worksheet.getRow(6);
  headerRow.eachCell((headCell, colNum) => {
    if (colNum > 2) {
      headCell.font = {
        name: "Tahoma",
        size: 12,
        bold: true,
        color: { argb: "000000" },
      };
      headCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "E1E1E1" },
      };

      headCell.alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
    }
  });

  headerRow.height = 40;

  // ایجاد فایل اکسل
  const buffer = await workbook.xlsx.writeBuffer();

  // ذخیره فایل اکسل
  const blob = new Blob([buffer], { type: "application/octet-stream" });
  saveAs(blob, "RoutesExcel.xlsx");
};
