import { Row } from "antd";
import styled from "styled-components";

export const CancelToursStyles = styled(Row)`
  padding: 24px 0px 0 24px;
  gap: 24px;
  background: linear-gradient(299deg, #f49898 0.39%, #ec2d30 100%);
  margin-top: 32px;
  height: 443px;
  border-radius: 14px;
  .tours-card-col {
    height: auto !important;
  }
  .title {
    color: var(--danger-base-color-white, var(--0, #fff));
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0 10px;
  }
`;

export const CancelTourCardCardStyle = styled.div`
  display: flex;
  width: 178px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 20px;
  background: var(--0, #fff);
  height: 257px;

  /* 2 */
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  .image-container {
    border-radius: 16px;
    background: var(--info-info-100, #f1f8ff);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
  }
  .code {
    color: #273574;
    font-size: 14px;
    font-weight: 700;
  }
  .date {
    color: var(--gray-gray-500, #8e8e8e);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .amount-date span {
    color: #8e8e8e;
    font-size: 10px;
    font-weight: 400;
  }
  .nation {
    color: #4b4b4b;
    font-size: 10px;
    font-weight: 400;
  }
  .footerText {
    color: var(--primary-primary-700, #f06614);
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
  }
`;
