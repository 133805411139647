import { FC } from "react";
import { Button, Modal } from "antd";
import useLanguage from "src/store/language";
import { IDomesticFlight } from "src/services/TourManagement/models";

interface IModalDescriptionDomesticFlightProps {
  show: boolean;
  onClose: () => void;
  item: IDomesticFlight | undefined;
}

export const ModalDescriptionDomesticFlight: FC<
  IModalDescriptionDomesticFlightProps
> = ({ show, onClose, item }) => {
  const { words } = useLanguage();

  return (
    <Modal
      title={
        <div className="pb-[16px] border-0 border-b-[1px] border-solid border-[#FDE8DC]">
          <p className="text-[18px] font-semibold text-[#233069] mb-[2px] mt-[0px]">
            {words.managementDomesticFlight.flightDescription}
          </p>
          <span className="text-[12px] text-[#1F1F1F] font-medium pl-[8px] border-0 border-l-[2px] border-solid border-[#FDE8DC]">
            {item?.flightCode || "-"}
          </span>
          <span className="text-[12px] text-[#8E8E8E] font-normal mr-[8px]">
            {item?.originCity.concat(" - ".concat(item.destinationCity))}
          </span>
        </div>
      }
      closeIcon={false}
      open={show}
      onCancel={onClose}
      footer={() => (
        <div className="flex justify-center ">
          <Button
            type="default"
            onClick={onClose}
            className="flex gap-[12px] justify-center font-semibold text-[#4B4B4B] text-[16px]"
          >
            <span className="material-icons text-[24px] text-[#4B4B4B]">
              highlight_off
            </span>
            {words.global.close}
          </Button>
        </div>
      )}
    >
      <div className="flex justify-center items-center p-[16px] rounded-[12px] border border-solid border-[#FDE8DC] my-[32px]">
        <div className="w-full p-[16px] flex justify-center items-center bg-[#F5F5F5] rounded-[12px]">
          <p className="text-[12px] text-[#1F1F1F]">
            {item?.description || "-"}
          </p>
        </div>
      </div>
    </Modal>
  );
};
