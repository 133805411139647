import { FC } from "react";
import { Col, Row } from "antd";
import { AddNewTouristInfo } from "./components/addNewTouristInfo";
import { TouristTable } from "./components/TouristTable";
import { AddNewTouristInfoContainer, AddTouristInfoContainer } from "./style";
import { useAddTouristInfo } from "./context";
import { TouristTableView } from "./components/touristTableView";

export const AddTouristInfo: FC = () => {
  const {
    value: { mode },
  } = useAddTouristInfo();

  return (
    <AddTouristInfoContainer>
      {/* <PageHeader
        mode={mode}
        title={words.addTouristInfo.headerTitle}
        backTitle={words.global.back}
        tourCode={tourCode}
      /> */}
      <Row justify={"center"}>
        <Col span={22}>
          <AddNewTouristInfoContainer>
            {mode === "add" || mode === "edit" ? <AddNewTouristInfo /> : null}
            {mode === "view" ? <TouristTableView /> : <TouristTable />}
          </AddNewTouristInfoContainer>
        </Col>
      </Row>
    </AddTouristInfoContainer>
  );
};
