import { TableColumnsType } from "antd";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
import { IPeople } from "src/services/ExportData/models";
interface IProps {
  data: IPeople[];
  columns: TableColumnsType<IPeople>;
  title: string;
}
export const generateFlightExcel = async ({ data, columns, title }: IProps) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");

  // ادغام سلول‌ها
  worksheet.mergeCells("C3:I3");
  worksheet.columns.forEach((column) => {
    column.width = 15;
    column.alignment = {
      horizontal: "center",
      vertical: "middle",
      wrapText: true,
    };
    column.font = {
      name: "Tahoma",
      size: 12,
      color: { argb: "000000" },
    };
  });

  const cell = worksheet.getCell("C3");

  cell.value = title;

  // تنظیم استایل برای سلول ادغام شده
  cell.font = {
    name: "Tahoma",
    size: 14,
    bold: true,
    color: { argb: "000000" },
  };

  cell.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "CACACA" },
  };

  cell.alignment = {
    horizontal: "center",
    vertical: "middle",
    wrapText: true,
  };
  const titleRow = worksheet.getRow(3);
  titleRow.height = 40;

  const header: string[] = columns.map((column) => column.title as string);
  const excelData = data.map((item, index) => {
    let showGender = "";
    switch (item.gender) {
      case 1:
        showGender = "Male";
        break;
      case 2:
        showGender = "Female";
        break;
    }
    const birthday = item.birthDate
      ? moment(item.birthDate).format("YYYY-MM-DD")
      : "";
    const expireDate = item.expireDate
      ? moment(item.expireDate).format("YYYY-MM-DD")
      : "";
    return [
      "",
      "",
      index + 1,
      showGender,
      item.firstName,
      item.lastName,
      birthday,
      item.passportNo,
      expireDate,
    ];
  });
  // داده‌های جدول
  const tableData = [[], ["", "", ...header], ...excelData];

  // افزودن داده‌ها به شیت
  tableData.forEach((row, index) => {
    worksheet.addRow(row);
  });
  const headerRow = worksheet.getRow(5);
  headerRow.eachCell((headCell, colNum) => {
    if (colNum > 2) {
      headCell.font = {
        name: "Tahoma",
        size: 12,
        bold: true,
        color: { argb: "000000" },
      };
      headCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "E1E1E1" },
      };

      headCell.alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
    }
  });

  headerRow.height = 40;

  // ایجاد فایل اکسل
  const buffer = await workbook.xlsx.writeBuffer();

  // ذخیره فایل اکسل
  const blob = new Blob([buffer], { type: "application/octet-stream" });
  saveAs(blob, "FlightExcel.xlsx");
};
