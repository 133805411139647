import { Navigate, RouteObject } from "react-router-dom";

import HomePage from "src/pages/private/home";
import { ROUTES } from "../../models/enums/routes";
import { AdminPanelLayout } from "src/templates/mainLayout";
import AddTourPage from "src/pages/private/add-tour";
import TourTablePage from "src/pages/private/table-tour";
import TourViewPage from "src/pages/private/view-tour";
import AddEditTourPage from "src/pages/private/add-edit-tour";
import { CreateRolePage } from "src/pages/private/create-role";
import { AssignActionToRolePage } from "src/pages/private/assign-action-to-role";
import { CreateUserPage } from "src/pages/private/create-user";
import { AssignRoleToUserPage } from "src/pages/private/assign-role-to-user";
import { CountryPage } from "src/pages/private/country";
import { CityPage } from "src/pages/private/city";
import PlacesPage from "src/pages/private/places";
import HotelsPage from "src/pages/private/hotels";
import { CarPage } from "src/pages/private/car";
import { ManagementTourPage } from "src/pages/private/managementTourPage";
import { AddTouristInfoPage } from "src/pages/private/managementAddTouristInfoPage";
import ManagementRoutePage from "src/pages/private/management-route";
import ManagementTimePage from "src/pages/private/management-time";
import ManagementBasicInfoPage from "src/pages/private/management-basic-info";
import { DomesticFlightPage } from "src/pages/private/managementDomesticFlightPage";
import ManagementSimcardPage from "src/pages/private/management-simcard";
import ManagementInsurancePage from "src/pages/private/management-insurance";
import ManagementDriverPage from "src/pages/private/management-driver";
import ManagementTranslatorPage from "src/pages/private/management-translator";
import ManagementSupervisorPage from "src/pages/private/management-supervisor";
import { NotificationPage } from "src/pages/private/managementNotificationPage";
import { ViewNotificationPage } from "src/pages/private/managementViewNotification";
import ManagementFilesPage from "src/pages/private/management-files";
import RoomsPage from "src/pages/private/rooms";
import BrokersPage from "src/pages/private/brokers";
import ManagementCompanionLeaderPage from "src/pages/private/management-companion-leader";
import ManagementLeaderPage from "src/pages/private/management-leader";
import ManagementSimcardAssignPage from "src/pages/private/management-simcard-assign";
import { ManagementTourReportPage } from "src/pages/private/Management-tour-report";
import ManagementRoutePlacePage from "src/pages/private/management-route-place";
import ManagementRouteRoomPage from "src/pages/private/management-route-room";
import ManagementInsuranceAttachmentPage from "src/pages/private/management-Insurance-attachment";

export const privateRoutes: RouteObject[] = [
  {
    path: ROUTES.home,
    element: <AdminPanelLayout />,
    children: [
      {
        path: ROUTES.home,
        element: <HomePage />,
      },
      {
        path: ROUTES.addTour,
        element: <AddTourPage />,
      },
      {
        path: ROUTES.tourTable,
        element: <TourTablePage />,
      },
      {
        path: `${ROUTES.tourView}:tourId`,
        element: <TourViewPage />,
      },
      {
        path: `${ROUTES.tourEdit}:tourId`,
        element: <AddEditTourPage />,
      },
      {
        path: `${ROUTES.tourAdd}:tourId`,
        element: <AddEditTourPage />,
      },
      {
        path: ROUTES.CreateRole,
        element: <CreateRolePage />,
      },
      {
        path: `${ROUTES.tourEditChinese}:addTourId`,
        element: <AddTourPage />,
      },
      {
        path: ROUTES.AssignActionToRole,
        element: <AssignActionToRolePage />,
      },
      {
        path: ROUTES.CreateUser,
        element: <CreateUserPage />,
      },
      {
        path: ROUTES.AssignRoleToUser,
        element: <AssignRoleToUserPage />,
      },
      {
        path: ROUTES.country,
        element: <CountryPage />,
      },
      {
        path: ROUTES.city,
        element: <CityPage />,
      },
      {
        path: ROUTES.places,
        element: <PlacesPage />,
      },
      {
        path: ROUTES.hotel,
        element: <HotelsPage />,
      },
      {
        path: ROUTES.car,
        element: <CarPage />,
      },

      {
        path: `${ROUTES.tourTable}/:tourId${ROUTES.managementTour}`,
        element: <ManagementTourPage />,
      },
      {
        path: `${ROUTES.tourTable}/:tourId${ROUTES.managementTour}${ROUTES.tourManagementTouristInfo}`,
        element: <AddTouristInfoPage />,
      },
      {
        path: `${ROUTES.tourTable}/:tourId${ROUTES.managementTour}${ROUTES.tourManagementRoute}`,
        element: <ManagementRoutePage />,
      },
      {
        path: `${ROUTES.tourTable}/:tourId${ROUTES.managementTour}${ROUTES.tourManagementTime}`,
        element: <ManagementTimePage />,
      },
      {
        path: `${ROUTES.tourTable}/:tourId${ROUTES.managementTour}${ROUTES.tourManagementBasicInfo}`,
        element: <ManagementBasicInfoPage />,
      },
      {
        path: `${ROUTES.tourTable}/:tourId${ROUTES.managementTour}${ROUTES.domesticFlight}`,
        element: <DomesticFlightPage />,
      },
      {
        path: `${ROUTES.tourTable}/:tourId${ROUTES.managementTour}${ROUTES.tourManagementSimcard}`,
        element: <ManagementSimcardPage />,
      },
      {
        path: `${ROUTES.tourTable}/:tourId${ROUTES.managementTour}${ROUTES.tourManagementInsurance}`,
        element: <ManagementInsurancePage />,
      },
      {
        path: `${ROUTES.tourTable}/:tourId${ROUTES.managementTour}${ROUTES.tourManagementDriver}`,
        element: <ManagementDriverPage />,
      },
      {
        path: `${ROUTES.tourTable}/:tourId${ROUTES.managementTour}${ROUTES.tourManagementTranslator}`,
        element: <ManagementTranslatorPage />,
      },
      {
        path: `${ROUTES.tourTable}/:tourId${ROUTES.managementTour}${ROUTES.tourManagementSupervisor}`,
        element: <ManagementSupervisorPage />,
      },
      {
        path: ROUTES.notification,
        element: <NotificationPage />,
      },
      {
        path: ROUTES.viewNotification,
        element: <ViewNotificationPage />,
      },
      {
        path: `${ROUTES.tourTable}/:tourId${ROUTES.managementTour}${ROUTES.tourManagementFiles}`,
        element: <ManagementFilesPage />,
      },
      {
        path: ROUTES.rooms,
        element: <RoomsPage />,
      },
      {
        path: ROUTES.broker,
        element: <BrokersPage />,
      },
      {
        path: `${ROUTES.tourTable}/:tourId${ROUTES.managementTour}${ROUTES.tourManagementCompanionLeader}`,
        element: <ManagementCompanionLeaderPage />,
      },
      {
        path: `${ROUTES.tourTable}/:tourId${ROUTES.managementTour}${ROUTES.tourManagementLeader}`,
        element: <ManagementLeaderPage />,
      },
      {
        path: `${ROUTES.tourTable}/:tourId${ROUTES.managementTour}${ROUTES.tourManagementAssignSimcard}`,
        element: <ManagementSimcardAssignPage />,
      },
      {
        path: `${ROUTES.tourTable}/:tourId${ROUTES.managementTour}${ROUTES.managementTourReport}`,
        element: <ManagementTourReportPage />,
      },
      {
        path: `${ROUTES.tourTable}/:tourId${ROUTES.managementTour}${ROUTES.tourManagementRoute}/:routeId${ROUTES.routePlace}`,
        element: <ManagementRoutePlacePage />,
      },
      {
        path: `${ROUTES.tourTable}/:tourId${ROUTES.managementTour}${ROUTES.tourManagementRoute}/:routeId${ROUTES.routeRoom}`,
        element: <ManagementRouteRoomPage />,
      },
      {
        path: `${ROUTES.tourTable}/:tourId${ROUTES.managementTour}${ROUTES.tourManagementInsurance}/:insuranceId${ROUTES.insuranceAttachment}`,
        element: <ManagementInsuranceAttachmentPage />,
      },
    ],
  },

  { path: "*", element: <Navigate to={ROUTES.home} /> },
];
