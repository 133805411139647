import { App } from "antd";
import {
  FC,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { convertDateTimeIran } from "src/helper";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import { INewTourTimeArg, ITourTime } from "src/services/TourManagement/models";
import useLanguage from "src/store/language";

interface IContextValue {
  states: {
    mode: string;
    dataLoading: boolean;
    loading: boolean;
    tourTime: ITourTime | undefined;
    deleteLoading: boolean;
  };
  func: { onFinish: (values: INewTourTimeArg) => void };
  requests: { onDeleteTime: () => Promise<void> };
}

export const ManagementTimeDataContext = createContext<
  IContextValue | undefined
>(undefined);

const ManagementTimeDataProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [mode, setMode] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tourTime, setTourTime] = useState<ITourTime | undefined>(undefined);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const { tourId } = useParams();
  const { state } = useLocation();
  const { words } = useLanguage();
  const { message } = App.useApp();
  const navigate = useNavigate();

  const onFinish = (values: INewTourTimeArg) => {
    if (values.startDate)
      values.startDate = convertDateTimeIran(values.startDate);
    if (values.endDate) values.endDate = convertDateTimeIran(values.endDate);
    if (values.cancelDate)
      values.cancelDate = convertDateTimeIran(values.cancelDate);
    if (values.flightDate)
      values.flightDate = convertDateTimeIran(values.flightDate);

    tourTime ? timeEditREq(values) : timeAddReq(values);
  };

  const timeAddReq = async (values: INewTourTimeArg) => {
    if (!tourId) return;
    setLoading(true);
    try {
      const { AddManagementTime } = new TourManagementService();
      const result = await AddManagementTime(tourId, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage).then(() => navigate(-1));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const timeEditREq = async (values: INewTourTimeArg) => {
    if (!tourTime) return;
    setLoading(true);
    try {
      const { EditManagementTime } = new TourManagementService();
      const result = await EditManagementTime(tourTime.id, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage).then(() => navigate(-1));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onDeleteTime = async () => {
    if (!tourTime) return;
    setDeleteLoading(true);
    try {
      const { DeleteTime } = new TourManagementService();
      const result = await DeleteTime(tourTime.id);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setTourTime(undefined);
        setMode("add");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
    }
  };

  const getTime = useCallback(async () => {
    if (!tourId) return;
    setDataLoading(true);
    try {
      const { GetManagementTime } = new TourManagementService();
      const res = await GetManagementTime(tourId);
      if (res && res.status === 200 && res.data) {
        setTourTime(res.data);
      } else {
        setTourTime(undefined);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoading(false);
    }
  }, [tourId]);

  useEffect(() => {
    if (tourId && (mode === "edit" || mode === "view")) getTime();
  }, [getTime, mode, tourId]);

  useEffect(() => {
    if (state && state.mode) setMode(state.mode);
    else setMode("add");
  }, [state]);

  const contextValues = {
    states: {
      mode,
      dataLoading,
      loading,
      tourTime,
      deleteLoading,
    },
    func: { onFinish },
    requests: { onDeleteTime },
  };

  return (
    <ManagementTimeDataContext.Provider value={contextValues}>
      {children}
    </ManagementTimeDataContext.Provider>
  );
};

export default ManagementTimeDataProvider;

export const useManagementTimeData = () =>
  useContext(ManagementTimeDataContext)!;
