import { FC } from "react";
import { Col } from "antd";
import { useInsuranceAttachmentData } from "./context";
import InsuranceFileForm from "./components/form/InsuranceFile";
import InsuranceInfo from "./components/insuranceInfo";
import AttachmentTable from "./components/table/FileTable";
import { ManagementInsuranceAttachmentContainer } from "./style";

const InsuranceFile: FC = () => {
  const {
    states: { mode },
  } = useInsuranceAttachmentData();

  return (
    <>
      {/* <InsurancePageHeader /> */}
      <ManagementInsuranceAttachmentContainer
        justify={"center"}
        gutter={[0, 24]}
      >
        <Col xs={24} md={23} className="content">
          {mode === "add" || mode === "edit" ? (
            <InsuranceFileForm />
          ) : (
            <InsuranceInfo />
          )}
          <AttachmentTable />
        </Col>
      </ManagementInsuranceAttachmentContainer>
    </>
  );
};

export default InsuranceFile;
