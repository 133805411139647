import { FC, RefObject } from "react";
import { RoutesProgramContainer } from "./style";
import { Col, Flex, Row, Table } from "antd";
import { routeProgramColumns } from "./columns";
import { IGetExportRoute } from "src/services/ExportData/models";
import moment from "moment";
import momentJalali from "moment-jalaali";
import { uniqueId } from "lodash";
interface IProps {
  componentRef?: RefObject<HTMLDivElement> | null;
  data: IGetExportRoute | undefined;
  loading: boolean;
  isPdf?: boolean;
}
export const RouteProgramData: FC<IProps> = ({
  componentRef,
  data,
  loading,
  isPdf,
}) => {
  const tableData = data?.routeDetails?.map((item) => {
    const newItem = { ...item };
    newItem.arrivalDate = item.arrivalDate
      ? momentJalali(item.arrivalDate)
          .format("jYYYY/jMM/jDD")
          .concat("\n")
          .concat(moment(item.arrivalDate).format("YYYY/MM/DD"))
      : "";
    newItem.departureDate = item.departureDate
      ? momentJalali(item.departureDate)
          .format("jYYYY/jMM/jDD")
          .concat("\n")
          .concat(moment(item.departureDate).format("YYYY/MM/DD"))
      : "";

    return newItem;
  });
  return (
    <RoutesProgramContainer
      gutter={[8, 8]}
      ref={componentRef}
      dir="rtl"
      className={isPdf ? "!shadow-none" : ""}
    >
      {isPdf && (
        <Col span={24}>
          <img
            src="/assets/images/sarbargHeader.png"
            alt="sarbarg"
            // className="absolute top-0 left-0 right-0 z-2"
            width={"100%"}
          />
        </Col>
      )}
      <Col span={24}>
        <Row className="px-[24px] py-[32px]" gutter={[8, 8]}>
          <Col span={24}>
            <Row gutter={8}>
              <Col span={8} className="!h-[148px]">
                <Flex
                  justify="space-between"
                  className="headerHolder"
                  align="center"
                >
                  <div>تور میثاق گشت صدرا</div>
                  <div>
                    <span className="material-symbols-outlined ">tour</span>
                  </div>
                </Flex>
                <Flex
                  justify="space-between"
                  align="center"
                  className="h-[103px]"
                >
                  <div className="contentHolder">تعداد گردشگران</div>
                  <div className="backDataHolder">{data?.touristCount} </div>
                </Flex>
              </Col>
              <Col span={8} className="!h-[148px]">
                <Flex
                  justify="space-between"
                  className="headerHolder"
                  align="center"
                >
                  <div>تور لیدر</div>
                  <div>
                    <span className="material-symbols-outlined ">
                      follow_the_signs
                    </span>
                  </div>
                </Flex>
                <Flex align="center" vertical className="h-[103px]" gap={1}>
                  <div className="flex justify-between w-full h-[51px]">
                    <div className="contentHolder">نام و نام خانوادگی </div>
                    <div className="backDataHolder">{data?.leaderName} </div>
                  </div>
                  <div className="flex justify-between w-full h-[51px]">
                    <div className="contentHolder">شماره تماس </div>
                    <div className="backDataHolder">{data?.leaderPhone} </div>
                  </div>
                </Flex>
              </Col>
              <Col span={8} className="!h-[148px]">
                <Flex
                  justify="space-between"
                  className="headerHolder"
                  align="center"
                >
                  <div>خودرو</div>
                  <div>
                    <span className="material-symbols-outlined ">
                      directions_bus
                    </span>
                  </div>
                </Flex>
                <Flex
                  justify="space-between"
                  align="center"
                  className="h-[103px]"
                >
                  <div className="backDataHolder">{data?.carName} </div>
                </Flex>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Table
              columns={routeProgramColumns}
              dataSource={tableData}
              scroll={{ x: "auto" }}
              loading={loading}
              pagination={false}
              className="routesTable"
              rowKey={() => uniqueId()}
            />
          </Col>
        </Row>
      </Col>
      {isPdf && (
        <Col span={24}>
          <img
            src="/assets/images/sarbargFooter.png"
            alt="sarbarg"
            // className="absolute bottom-0 left-0 right-0 z-2"
            width={"100%"}
          />
        </Col>
      )}
    </RoutesProgramContainer>
  );
};
