import { FC, useState } from "react";
import { Button, Checkbox, Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import useLanguage from "src/store/language";
import { convertDate } from "src/helper";
import { TableUI } from "src/components/UiKit/table";
import {
  ActionsContainer,
  ModalContentContainer,
  ModalHeaderContainer,
} from "../style";
import { ITouristEditModalProps } from "../interface";
import { TouristsFieldNames } from "../../../models";
import { ITouristResult } from "src/services/Tour/models";
import { useTouristInfoRequest } from "../TouristInfoRequest";
import AddTouristModal from "./AddTouristModal";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";

const TouristsEditModal: FC<ITouristEditModalProps> = ({
  open,
  onBack,
  onSave,
  data = [],
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [selectable, setSelectable] = useState<boolean>(false);
  const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false);
  const [addModal, setAddModal] = useState<boolean>(false);
  const [tourist, setTourist] = useState<ITouristResult | undefined>(undefined);

  const { words, isRtl } = useLanguage();

  const { states, requests } = useTouristInfoRequest();

  const handleDeleteTourist = (id: number) => {
    setDeleteConfirm(true);
    setSelectedRowKeys([id]);
  };

  const columns: ColumnsType<ITouristResult> = [
    {
      title: `#`,
      dataIndex: "id",
      key: "id",
    },
    {
      title: `${words.viewTour.roomType}`,
      dataIndex: TouristsFieldNames.RoomType,
      key: TouristsFieldNames.RoomType,
    },
    {
      title: `${words.viewTour.lastName}`,
      dataIndex: TouristsFieldNames.LastName,
      key: TouristsFieldNames.LastName,
    },
    {
      title: `${words.viewTour.firstName}`,
      dataIndex: TouristsFieldNames.FirstName,
      key: TouristsFieldNames.FirstName,
    },
    {
      title: `${words.viewTour.sex}`,
      dataIndex: TouristsFieldNames.Sex,
      key: TouristsFieldNames.Sex,
    },
    {
      title: `${words.viewTour.passportNO}`,
      dataIndex: TouristsFieldNames.PassportNO,
      key: TouristsFieldNames.PassportNO,
    },
    {
      title: `${words.viewTour.birthDate}`,
      dataIndex: TouristsFieldNames.BirthDate,
      key: TouristsFieldNames.BirthDate,
      render: (value) => convertDate(value),
    },
    {
      title: `${words.viewTour.expireDate}`,
      dataIndex: TouristsFieldNames.ExpireDate,
      key: TouristsFieldNames.ExpireDate,
      render: (value) => convertDate(value),
    },
    {
      title: `${words.tableTour.tools}`,
      dataIndex: "actions",
      key: "actions",
      render: (value, record) =>
        selectable ? (
          <Checkbox
            checked={selectedRowKeys.includes(record.id)}
            onChange={(e) => onChangeCheckBox(e, record.id)}
          />
        ) : (
          <ActionsContainer>
            <Button
              type="text"
              className="edit"
              onClick={() => handleEditTourist(record)}
            >
              <span className="material-icons">edit</span>
            </Button>
            <Button
              type="text"
              className="delete"
              onClick={() => handleDeleteTourist(record.id)}
            >
              <span className="material-icons">delete_forever</span>
            </Button>
          </ActionsContainer>
        ),
    },
  ];

  const ModalHeader = () => {
    return (
      <ModalHeaderContainer>
        {words.addEditTour.editModalTitle}
      </ModalHeaderContainer>
    );
  };

  const onChangeCheckBox = (e: CheckboxChangeEvent, id: number) => {
    e.target.checked
      ? setSelectedRowKeys([...selectedRowKeys, id])
      : setSelectedRowKeys(selectedRowKeys.filter((item) => item !== id));
  };

  const handleCancel = () => {
    if (selectable) {
      setSelectable(false);
      setSelectedRowKeys([]);
    } else {
      onBack();
    }
  };

  const handleDeleteRows = () => {
    if (selectable) {
      setDeleteConfirm(true);
    } else {
      setSelectable(true);
    }
  };

  const handleConfirmDeleteRows = () => {
    requests
      .deleteTouristInfoRequest(selectedRowKeys)
      .finally(() => setDeleteConfirm(false));
  };

  const handleEditTourist = (tourist: ITouristResult) => {
    setTourist(tourist);
    setAddModal(true);
  };

  return (
    <>
      <DeleteConfirmModal
        showConfirm={deleteConfirm}
        loading={states.loading}
        title={words.addEditTour.deleteConfirmModalTitle}
        onOk={handleConfirmDeleteRows}
        onCancel={() => setDeleteConfirm(false)}
        okTitle={words.addEditTour.delete}
      />
      <AddTouristModal
        showConfirm={addModal}
        setAddModal={setAddModal}
        tourist={tourist}
      />
      <Modal
        title={<ModalHeader />}
        open={open}
        footer={null}
        onCancel={onBack}
        centered
        width={1000}
      >
        <ModalContentContainer>
          <TableUI
            scroll={{ x: 400 }}
            dataSource={data}
            columns={columns}
            tableType="secondary"
          />
          <div className="footer" dir={isRtl ? "rtl" : "ltr"}>
            <Button
              type="text"
              className="delete-btn"
              icon={<span className="material-icons">delete_forever</span>}
              disabled={
                selectable && selectedRowKeys.length === 0 ? true : false
              }
              onClick={handleDeleteRows}
            >
              {selectable
                ? words.addEditTour.deleteSelectedRows
                : words.addEditTour.deleteRows}
            </Button>
            {!selectable && (
              <>
                <Button
                  type="text"
                  className="add-btn"
                  icon={<span className="material-icons">add</span>}
                  onClick={() => setAddModal(true)}
                >
                  {words.addEditTour.addNewRow}
                </Button>
                <Button
                  type="text"
                  className="save-btn"
                  icon={<span className="material-icons">done</span>}
                  onClick={onSave}
                >
                  {words.global.save}
                </Button>
              </>
            )}
            <Button
              type="text"
              className="cancel"
              dir={isRtl ? "ltr" : "rtl"}
              onClick={handleCancel}
            >
              {words.global.cancel}
            </Button>
          </div>
        </ModalContentContainer>
      </Modal>
    </>
  );
};

export default TouristsEditModal;
