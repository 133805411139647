import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { BasicInformationService } from "src/services/BasicInformation/BasicInformation.service";
import { ICity, IHotel } from "src/services/BasicInformation/models";
import { IManagementRoute } from "src/services/TourManagement/models";

interface IContextValue {
  states: {
    mode: string;
    placeHotelModeProps: IPlaceHotelModeProps;
    hotel: IHotelProps;
    city: ICityProps;
  };
  dispatches: {
    setPlaceHotelModeProps: Dispatch<SetStateAction<IPlaceHotelModeProps>>;
  };
  requests: {
    getHotelsByCity: (cityId: number) => void;
    getHotels: () => void;
  };
}
interface IHotelProps {
  data: IHotel[];
  loading: boolean;
}

interface ICityProps {
  data: ICity[];
  loading: boolean;
}

interface IPlaceHotelModeProps {
  mode: "route" | "hotel" | "place";
  route: IManagementRoute | undefined;
}

export const ManagementRouteDataContext = createContext<
  IContextValue | undefined
>(undefined);

const ManagementRouteDataProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [mode, setMode] = useState<string>("add");
  const [placeHotelModeProps, setPlaceHotelModeProps] =
    useState<IPlaceHotelModeProps>({
      mode: "route",
      route: undefined,
    });
  const [hotel, setHotel] = useState<IHotelProps>({
    data: [],
    loading: false,
  });
  const [city, setCity] = useState<ICityProps>({
    data: [],
    loading: false,
  });

  const { state } = useLocation();

  const getHotelsByCity = useCallback(async (cityId: number) => {
    setHotel((prev) => ({ ...prev, loading: true }));

    try {
      const { GetHotelsByCity } = new BasicInformationService();
      const res = await GetHotelsByCity(cityId);
      if (res && res.status === 200 && res.data) {
        setHotel((prev) => ({ ...prev, data: res.data.records }));
      } else {
        setHotel((prev) => ({ ...prev, data: [] }));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setHotel((prev) => ({ ...prev, loading: false }));
    }
  }, []);

  const getHotels = useCallback(async () => {
    setHotel((prev) => ({ ...prev, loading: true }));

    try {
      const { Hotels } = new BasicInformationService();
      const res = await Hotels();
      if (res && res.status === 200 && res.data) {
        setHotel((prev) => ({ ...prev, data: res.data.records }));
      } else {
        setHotel((prev) => ({ ...prev, data: [] }));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setHotel((prev) => ({ ...prev, loading: false }));
    }
  }, []);

  const getCities = useCallback(async () => {
    setCity((prev) => ({ ...prev, loading: true }));
    try {
      const { Cities } = new BasicInformationService();
      const res = await Cities();
      if (res && res.status === 200 && res.data) {
        setCity((prev) => ({ ...prev, data: res.data.records }));
      } else {
        setCity((prev) => ({ ...prev, data: [] }));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setCity((prev) => ({ ...prev, loading: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCities();
  }, [getCities]);

  useEffect(() => {
    if (state && state.mode) setMode(state.mode);
    else setMode("add");
  }, [state]);

  const contextValues = {
    states: {
      mode,
      placeHotelModeProps,
      hotel,
      city,
    },
    dispatches: { setPlaceHotelModeProps },
    requests: { getHotelsByCity, getHotels },
  };

  return (
    <ManagementRouteDataContext.Provider value={contextValues}>
      {children}
    </ManagementRouteDataContext.Provider>
  );
};

export default ManagementRouteDataProvider;

export const useManagementRouteData = () =>
  useContext(ManagementRouteDataContext)!;
