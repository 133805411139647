import { Flex, Row } from "antd";
import styled from "styled-components";

export const NajaMailStyle = styled(Row)`
  /* .wrapper { */
  border-radius: 6.45px;
  background-color: #fff !important;
  /* box-shadow: 2.163px 4.326px 16.224px 0px rgba(0, 0, 0, 0.1); */

  /* background: url(/assets/images/sarbarg.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center; */
  /* } */
  .content {
    padding: 6.124px 16.124px 30px 16.124px;
  }
  .header-label {
    color: #808080;
    font-family: "Vazir FD";
    font-size: 5.643px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
  }
  .header-value {
    color: #1f1f1f;
    font-family: "Vazir FD";
    font-size: 5.643px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    min-width: 32px;
    text-align: left;
  }
  .mail-title {
    color: #1f1f1f;
    font-family: "Vazir FD";
    font-size: 6.45px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  .mail-description {
    color: #1f1f1f;
    font-family: "Vazir FD";
    font-size: 6.45px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .mail {
    p {
      margin-bottom: unset;
    }
  }
  .tourInfo {
    padding: 6.45px 9.674px;
    border: 1px solid #cacaca;
    color: #1f1f1f;
    font-size: 6.45px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .infoTableHeader {
    display: flex;
    height: 24.186px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4.031px;
    /* align-self: stretch; */
    border: 0.403px solid #cacaca;
    color: #1f1f1f;
    text-align: center;
    font-size: 6.45px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .infoTableBody {
    border: 0.403px solid #cacaca;
    padding: 6.45px;

    & * {
      color: #1f1f1f;
      text-align: right;
      font-family: "Vazir FD";
      font-size: 6.45px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .columns {
    font-size: 6.5px;
  }
  .footer {
    display: flex;
    height: 45.147px;
    padding: 32px 48.372px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 3.225px;
    align-self: stretch;
    color: var(--Gray-Gray-700, #1f1f1f);
    text-align: right;
    font-family: "Vazir FD";
    font-size: 6.45px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
export const PageFooter = styled(Flex)`
  margin-top: 32px;
  padding: 32px 24px 16px 24px;
  border-top: 1px solid #dadeec;
  img {
    cursor: pointer;
    user-select: none;
  }
`;
