import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { IPlanTourBatchArgs, ITourInfo } from "src/services/Tour/models";
import { TourService } from "src/services/Tour/Tour.service";
import useLanguage from "src/store/language";
import { useTourAddEditData } from "../../context/TourAddEditData";
import { TabMenuKeys } from "../../models";
import { App } from "antd";

interface IContextValue {
  states: {
    loading: boolean;
    dataLoading: boolean;
  };
  requests: {
    addEditTourRequest: (values: IPlanTourBatchArgs) => void;
    deleteTourInfoRequest: (value: number[]) => Promise<void>;
    addTourRequest: (value: ITourInfo, planId: number) => Promise<void>;
  };
}

export const TourInfoRequestContext = createContext<IContextValue | undefined>(
  undefined
);

const TourInfoRequestProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const { words } = useLanguage();
  const { dispatches } = useTourAddEditData();
  const { tourId } = useParams();
  const location = useLocation();
  const { message } = App.useApp();

  const { PlanTour, EditPlanTour, DeleteTourInfo, PlanTourBatch, GetTourInfo } =
    new TourService();

  const addTourRequest = async (value: ITourInfo, planId: number) => {
    if (!tourId) return;

    try {
      const result =
        planId === 0
          ? await PlanTour(tourId, value)
          : await EditPlanTour(planId, value);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        getTourInfo();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteTourInfoRequest = async (value: number[]) => {
    try {
      setLoading(true);
      const result = await DeleteTourInfo(value);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        getTourInfo();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const addEditTourRequest = async (values: IPlanTourBatchArgs) => {
    if (!tourId) return;
    setLoading(true);
    try {
      const result = await PlanTourBatch(tourId, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        dispatches.setFormData((prev) => ({
          ...prev,
          [TabMenuKeys.tour]: values,
        }));
        dispatches.setCurrentFilter(TabMenuKeys.insurance);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getTourInfo = useCallback(async () => {
    if (!tourId) return;

    try {
      setDataLoading(true);
      const result = await GetTourInfo(tourId);
      if (result && result.status === 200) {
        dispatches.setFormData((prev) => ({
          ...prev,
          tour: result.data,
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname.includes(ROUTES.tourEdit) && tourId) {
      getTourInfo();
    }
  }, [getTourInfo, location.pathname, tourId]);

  const contextValues = {
    states: {
      loading,
      dataLoading,
    },
    requests: {
      addEditTourRequest,
      deleteTourInfoRequest,
      addTourRequest,
    },
  };

  return (
    <TourInfoRequestContext.Provider value={contextValues}>
      {children}
    </TourInfoRequestContext.Provider>
  );
};

export default TourInfoRequestProvider;

export const useTourInfoRequest = () => useContext(TourInfoRequestContext)!;
