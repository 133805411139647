import { FC } from "react";

const HeaderLogo: FC<{ management: boolean }> = ({ management }) => {
  return (
    <div className={`HeaderLogoWrapper ${management ? "center" : "left"}`}>
      <img src="/assets/images/HeaderLogo.svg" alt="logo" />
    </div>
  );
};
export default HeaderLogo;
