import {
  Document,
  Paragraph,
  TextRun,
  TableRow,
  TableCell,
  Table,
  ITableWidthProperties,
} from "docx";
import moment from "moment-jalaali";
import { IObject } from "src/models/interfaces";
import { carPluckAlphabetArray } from "src/modules/managementDriver/constants/selectOptions";
import { IGetExportMigration } from "src/services/ExportData/models";

const fontName = "B Nazanin";

export const createNajaMail = (data: IGetExportMigration): Document => {
  const { tourCode, countryName, touristCound, tourDays, routeDetails } = data;
  const mailDate = moment().format("jDD/jMM/jYYYY");
  const newRouteDetails = routeDetails.map((route) => {
    const newRoute = { ...route };
    newRoute.arrivalDate = route.arrivalDate
      ? moment(route.arrivalDate).format("jDD/jMM/jYYYY")
      : "";
    newRoute.departureDate = route.departureDate
      ? moment(route.departureDate).format("jDD/jMM/jYYYY")
      : "";
    return newRoute;
  });
  const document = new Document({
    sections: [
      {
        properties: {
          page: {
            margin: {
              top: "4cm",
              bottom: "1cm",
              left: "1cm",
              right: "1cm",
            },
          },
        },
        children: [
          createInstitutionHeader("   " + mailDate, "تاریخ: "),
          createInstitutionHeader("   " + tourCode, "شماره نامه: "),
          ...createMailDescription(),
          nextLine(),
          nextLine(),
          createTourInfo(tourDays, touristCound, countryName),
          nextLine(),
          nextLine(),
          createLeaderAndDriverInfoTable(data),
          nextLine(),
          nextLine(),
          createRouteTable({ routeDetails: newRouteDetails }),
        ],
        footers: {
          default: {
            options: {
              children: [
                new Paragraph({
                  alignment: "left",
                  indent: { left: "3cm" },
                  children: [
                    new TextRun({
                      font: { name: fontName },
                      text: "با تشکر",
                      bold: true,
                      size: "11pt",
                      rightToLeft: true,
                    }),
                  ],
                }),
                new Paragraph({
                  alignment: "left",
                  indent: { left: "3cm" },

                  children: [
                    new TextRun({
                      font: { name: fontName },
                      text: "مدیر عامل",
                      bold: true,
                      size: "11pt",
                      rightToLeft: true,
                    }),
                  ],
                }),
              ],
            },
          },
        },
      },
    ],
  });
  return document;
};

const createInstitutionHeader = (
  institutionName: string,
  dateText: string
): Paragraph => {
  return new Paragraph({
    alignment: "left",
    indent: { left: 4 },
    leftTabStop: 0,
    children: [
      new TextRun({
        font: { name: fontName },
        text: institutionName,
        bold: true,
        rightToLeft: true,
      }),
      new TextRun({
        font: { name: fontName },
        text: `\t${dateText}`,
        bold: true,
        rightToLeft: true,
        color: "#808080",
      }),
    ],
    spacing: { before: 10 },
  });
};

const createTourInfo = (
  tourDays: number,
  touristCound: number,
  countryName: string
): Paragraph => {
  return new Paragraph({
    alignment: "right",
    border: {
      bottom: { style: "single", size: 1, color: "#CACACA", space: 6 },
      left: { style: "single", size: 1, color: "#CACACA", space: 6 },
      right: { style: "single", size: 1, color: "#CACACA", space: 6 },
      top: { style: "single", size: 1, color: "#CACACA", space: 6 },
    },
    children: [
      new TextRun({
        font: { name: fontName },
        text: "مدت تور :",
        bold: true,
        size: "11pt",
        rightToLeft: true,
      }),
      new TextRun({
        font: { name: fontName },
        text: ` ${tourDays} روز`,
        bold: true,
        size: "11pt",
        rightToLeft: true,
      }),
      new TextRun({
        font: { name: fontName },
        text: "\t \xa0\xa0\xa0",
        bold: true,
        size: "11pt",
        rightToLeft: true,
      }),
      new TextRun({
        font: { name: fontName },
        text: "تعداد جهان گردان :",
        bold: true,
        size: "11pt",
        rightToLeft: true,
      }),
      new TextRun({
        font: { name: fontName },
        text: ` ${touristCound} نفر \xa0\xa0\xa0\xa0\xa0\xa0`,
        bold: true,
        size: "11pt",
        rightToLeft: true,
      }),
      new TextRun({
        font: { name: fontName },
        text: "\t",
        bold: true,
        size: "11pt",
        rightToLeft: true,
      }),
      new TextRun({
        font: { name: fontName },
        text: "ملیت جهان گردان : ",
        bold: true,
        size: "11pt",
        rightToLeft: true,
      }),
      new TextRun({
        font: { name: fontName },
        text: countryName,
        bold: true,
        size: "11pt",
        rightToLeft: true,
      }),
      new TextRun({
        font: { name: fontName },
        text: "\t\t",
        bold: true,
        size: "11pt",
        rightToLeft: true,
      }),
      new TextRun({
        font: { name: fontName },
        text: "نام آژانس : ",
        bold: true,
        size: "11pt",
        rightToLeft: true,
      }),
      new TextRun({
        font: { name: fontName },
        text: " میثاق گشت صدرا ",
        bold: true,
        size: "11pt",
        rightToLeft: true,
      }),
      new TextRun({
        font: { name: fontName },
        text: "\t",
        bold: true,
        size: "11pt",
        rightToLeft: true,
      }),
    ],
  });
};

const createMailDescription = (): Paragraph[] => {
  return [
    new Paragraph({
      alignment: "right",
      children: [
        new TextRun({
          font: { name: fontName },
          text: "\nریاست محترم پلیس مهاجرت و گذرنامه ناجا-حراست ویژه",
          bold: true,
          size: "11pt",
          rightToLeft: true,
        }),
      ],
    }),
    new Paragraph({
      alignment: "right",
      children: [
        new TextRun({
          font: { name: fontName },
          text: "باسلام و احترام",
          size: "10pt",
          rightToLeft: true,
        }),
      ],
    }),
    new Paragraph({
      alignment: "right",
      children: [
        new TextRun({
          font: { name: fontName },
          text: "برنامه سفر و فهرست اسامی مسافرین به شرح زیر جهت صدور کد ناجا ارسال می گردد.",
          size: "10pt",
          rightToLeft: true,
        }),
      ],
    }),
    new Paragraph({
      alignment: "right",

      children: [
        new TextRun({
          font: { name: fontName },
          text: "گفتنی است صحت مشخصات بر عهده این",
          size: "10pt",
          rightToLeft: true,
        }),
        new TextRun({
          font: { name: fontName },
          text: " دفتر/شرکت ",
          size: "11pt",
          bold: true,
          rightToLeft: true,
        }),
        new TextRun({
          font: { name: fontName },
          text: " می باشد. ",
          size: "10pt",
          rightToLeft: true,
        }),
      ],
    }),
  ];
};

const nextLine = (): Paragraph =>
  new Paragraph({
    children: [
      new TextRun({
        font: { name: fontName },
        text: "\n",
        bold: true,
        size: "11pt",
        rightToLeft: true,
      }),
    ],
  });

const createLeaderAndDriverInfoTable = ({
  carName,
  carTag,
  driverFullName,
  driverIdentityCode,
  driverNationalCode,
  leaderBirthDate,
  leaderCardNumber,
  leaderFatherName,
  leaderFullName,
  leaderIdentityCode,
  leaderNationalCode,
  leaderPhoneNumber,
  driverPhoneNumber,
}: Pick<
  IGetExportMigration,
  | "carName"
  | "carTag"
  | "driverFullName"
  | "driverIdentityCode"
  | "driverNationalCode"
  | "driverPhoneNumber"
  | "leaderBirthDate"
  | "leaderCardNumber"
  | "leaderFatherName"
  | "leaderFullName"
  | "leaderIdentityCode"
  | "leaderNationalCode"
  | "leaderPhoneNumber"
>) => {
  const newCarTag = (tag: string) => {
    let text = " ایران ";
    text += tag
      .split("-")[3]
      .concat(" - ")
      .concat(tag.split("-")[2])
      .concat(carPluckAlphabetArray[Number(tag.split("-")[1])])
      .concat(tag.split("-")[0]);

    return text;
  };
  return new Table({
    visuallyRightToLeft: true,
    width: { size: "100%", type: "dxa" },

    borders: {
      insideHorizontal: { style: "none" },
      insideVertical: { style: "none" },
      bottom: { style: "single", color: "#CACACA" },
      top: { style: "single", color: "#CACACA" },
      right: { style: "single", color: "#CACACA" },
      left: { style: "single", color: "#CACACA" },
    },
    rows: [
      new TableRow({
        tableHeader: true,

        height: { value: "32pt", rule: "exact" },
        children: [
          new TableCell({
            verticalAlign: "center",
            children: [
              new Paragraph({
                alignment: "center",
                children: [
                  new TextRun({
                    font: { name: fontName },
                    text: "مشخصات راهنما",
                    bold: true,
                    size: "11pt",
                    rightToLeft: true,
                  }),
                ],
              }),
            ],
            columnSpan: 2,
            borders: {
              bottom: { style: "single", color: "#CACACA" },
              right: { style: "single", color: "#CACACA" },
            },
          }),
          new TableCell({
            verticalAlign: "center",
            children: [
              new Paragraph({
                alignment: "center",
                children: [
                  new TextRun({
                    font: { name: fontName },
                    text: "مشخصات راننده و خودرو",
                    bold: true,
                    size: "11pt",
                    rightToLeft: true,
                  }),
                ],
              }),
            ],
            columnSpan: 2,
            borders: {
              bottom: { style: "single", color: "#CACACA" },
              left: { style: "single", color: "#CACACA" },
            },
          }),
        ],
      }),

      new TableRow({
        height: { value: "32pt", rule: "exact" },
        children: [
          new TableCell({
            children: [createColumnInfo("نام و نام خانوادگی", leaderFullName)],
            verticalAlign: "center",
          }),
          new TableCell({
            children: [createColumnInfo("نام پدر", leaderFatherName)],
            borders: {
              right: { style: "single", color: "#CACACA" },
            },
            verticalAlign: "center",
          }),
          new TableCell({
            children: [createColumnInfo("نام و نام خانوادگی", driverFullName)],
            borders: {
              left: { style: "single", color: "#CACACA" },
            },
            verticalAlign: "center",
          }),
          new TableCell({
            children: [createColumnInfo("نام پدر", leaderFatherName)],
            verticalAlign: "center",
          }),
        ],
      }),
      new TableRow({
        height: { value: "32pt", rule: "exact" },

        children: [
          new TableCell({
            verticalAlign: "center",
            children: [createColumnInfo("کد ملی", leaderNationalCode)],
          }),
          new TableCell({
            children: [createColumnInfo("ش.ش", leaderIdentityCode)],
            borders: {
              right: { style: "single", color: "#CACACA" },
            },
            verticalAlign: "center",
          }),
          new TableCell({
            children: [createColumnInfo("کد ملی", driverNationalCode)],
            borders: {
              left: { style: "single", color: "#CACACA" },
            },
            verticalAlign: "center",
          }),
          new TableCell({
            children: [createColumnInfo("ش.ش", driverIdentityCode)],
            verticalAlign: "center",
          }),
        ],
      }),
      new TableRow({
        height: { value: "32pt", rule: "exact" },

        children: [
          new TableCell({
            children: [createColumnInfo("شماره تماس", leaderPhoneNumber)],
            verticalAlign: "center",
          }),
          new TableCell({
            children: [
              createColumnInfo(
                "ت.ت",
                leaderBirthDate
                  ? moment(leaderBirthDate).format("jDD/jMM/jYYYY")
                  : ""
              ),
            ],
            borders: {
              right: { style: "single", color: "#CACACA" },
            },
            verticalAlign: "center",
          }),
          new TableCell({
            children: [createColumnInfo("نوع خودرو", carName)],
            borders: {
              left: { style: "single", color: "#CACACA" },
            },
            verticalAlign: "center",
          }),
          new TableCell({
            children: [createColumnInfo("شماره تماس", driverPhoneNumber)],
            verticalAlign: "center",
          }),
        ],
      }),
      new TableRow({
        height: { value: "32pt", rule: "exact" },

        children: [
          new TableCell({
            children: [createColumnInfo("شماره کارت راهنما", leaderCardNumber)],
            verticalAlign: "center",
          }),
          new TableCell({
            children: [],
            borders: {
              right: { style: "single", color: "#CACACA" },
            },
            verticalAlign: "center",
          }),
          new TableCell({
            children: [createColumnInfo("شماره خودرو", newCarTag(carTag))],
            borders: {
              left: { style: "single", color: "#CACACA" },
            },
            verticalAlign: "center",
          }),
          new TableCell({
            children: [],
            verticalAlign: "center",
          }),
        ],
      }),
    ],
  });
};

const createColumnInfo = (label: string, value: string = ""): Paragraph => {
  return new Paragraph({
    alignment: "right",
    children: [
      new TextRun({
        font: { name: fontName },
        text: ` ${label} : ${value} `,
        rightToLeft: true,
      }),
    ],
  });
};

const createRouteTable = ({
  routeDetails,
}: Pick<IGetExportMigration, "routeDetails">) => {
  const columns: {
    dataIndex: string;
    label: string;
    width: ITableWidthProperties;
  }[] = [
    {
      dataIndex: "key",
      label: "#",
      width: { size: "10mm", type: "dxa" },
    },
    {
      dataIndex: "arrivalDate",
      label: "از تاریخ",
      width: { size: "30mm", type: "dxa" },
    },
    {
      dataIndex: "departureDate",
      label: "تا تاریخ",
      width: { size: "30mm", type: "dxa" },
    },
    {
      dataIndex: "provinceName",
      label: "استان",
      width: { size: "20mm", type: "dxa" },
    },
    {
      dataIndex: "cityName",
      label: "شهر",
      width: { size: "20mm", type: "dxa" },
    },
    {
      dataIndex: "hotelName",
      label: "محل اقامت",
      width: { size: "30mm", type: "dxa" },
    },
    {
      dataIndex: "placeOfInterests",
      label: "مکان های دیدنی",
      width: { size: "50mm", type: "dxa" },
    },
  ];
  return new Table({
    visuallyRightToLeft: true,
    width: { size: "100%", type: "pct" },

    borders: {
      insideHorizontal: { style: "single", color: "#CACACA" },
      insideVertical: { style: "single", color: "#CACACA" },
      bottom: { style: "single", color: "#CACACA" },
      top: { style: "single", color: "#CACACA" },
      right: { style: "single", color: "#CACACA" },
      left: { style: "single", color: "#CACACA" },
    },

    rows: [
      new TableRow({
        tableHeader: true,
        height: { value: "32pt", rule: "exact" },
        children: columns.map(
          (column) =>
            new TableCell({
              verticalAlign: "center",
              width: column.width,

              margins: {
                right: 5,
                bottom: 5,
                left: 5,
                top: 5,
              },
              children: [
                new Paragraph({
                  alignment: "center",

                  children: [
                    new TextRun({
                      font: { name: fontName },
                      text: column.label,
                      bold: true,
                      size: "11pt",
                      rightToLeft: true,
                    }),
                  ],
                }),
              ],
            })
        ),
      }),
      ...routeDetails.map(
        (route: IObject, rowIndex) =>
          new TableRow({
            tableHeader: true,
            height: { value: "32pt", rule: "exact" },
            children: columns.map(
              (column) =>
                new TableCell({
                  verticalAlign: "center",
                  margins: {
                    right: 5,
                    bottom: 5,
                    left: 5,
                    top: 5,
                  },
                  children: [
                    new Paragraph({
                      alignment: "center",
                      children: [
                        new TextRun({
                          font: { name: fontName },
                          text:
                            column.dataIndex === "key"
                              ? (rowIndex + 1).toString()
                              : route[column.dataIndex],
                          bold: true,
                          size: "11pt",
                          rightToLeft: true,
                        }),
                      ],
                    }),
                  ],
                })
            ),
          })
      ),
    ],
  });
};
