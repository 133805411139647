import { FC } from "react";
import { Col } from "antd";
import AddCar from "./components/AddCar";
import CarTable from "./components/CarTable";
import { CarContainer } from "./styles";
import { CarProvider } from "./context";
import DataEntryHeader from "src/components/dataEntryHeader";

export const Car: FC = () => {
  return (
    <CarProvider>
      <DataEntryHeader title="شما میتوانید خودروها را ببنید و آنها را ویرایش کنید و یا خودرو جدیدی اضافه کنید." />
      <CarContainer justify={"center"} gutter={[0, 24]}>
        <Col xs={24} md={18} lg={16} className="content">
          <AddCar />
          <CarTable />
        </Col>
      </CarContainer>
    </CarProvider>
  );
};
