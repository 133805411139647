export enum TourManagementUrls {
  TourManagement = "TourManagement/",
  Hotels = "/AllHotels",
  DeleteHotel = "/DeleteHotel",
  NewHotel = "/AddHotel",
  EditHotel = "/UpdateHotel",
  Routes = "/GetAllRoute",
  DeleteRoute = "/DeleteRoute",
  NewRoute = "/AddRoute",
  EditRoute = "/EditRoute",
  NewTime = "/AddTourTime",
  EditTime = "/EditTourTime",
  GetTime = "/GetTourTime",
  DeleteTime = "/DeleteTourTime",
  Tourists = "/AllTourists",
  BuySimcard = "/AddSimCard",
  NewAssignSimcard = "/AddSimCardTourTourist",
  EditAssignSimcard = "/EditSimCardTourTourist",
  AllAssignSimcards = "/GetAllSimCardTourTourist",
  DeleteAssignSimcard = "/DeleteSimCardTourTourist",
  GetBuySimcard = "/GetSimCard",
  EditBuySimcard = "/EditSimCard",
  DeleteBuySimcard = "/DeleteSimCard",
  AddInsuranceInfo = "/AddInsurance",
  EditInsuranceInfo = "/EditInsurance",
  GetInsuranceInfo = "/GetAllInsurance",
  DeleteInsuranceInfo = "/DeleteInsurance",
  DownloadInsuranceFile = "/DownloadInsuranceAttachment",
  GetInsuranceDocument = "/GetAllInsuranceAttachment",
  DeleteInsuranceDocument = "/DeleteInsuranceAttachment",
  AddInsuranceDocument = "/AddInsuranceAttachment",
  EditInsuranceDocument = "/EditInsuranceAttachment",
  Supervisor = "/Supervisor",
  GetSupervisor = "/GetSupervisor",
  AddTranslator = "/AddTranslator",
  EditTranslator = "/EditTranslator",
  GetTranslator = "/GetTranslator",
  AddDriver = "/AddDriver",
  GetDriver = "/GetDriver",
  EditDriver = "/EditDriver",
  DeleteDriver = "/DeleteDriver",
  TourDocument = "/TourDocument",
  DownloadTourFile = "/DownloadTourAttachment",
  GetTourDocument = "/GetAllTourAttachment",
  DeleteTourDocument = "/DeleteTourAttachment",
  NewTourDocument = "/AddTourAttachment",
  EditTourDocument = "/EditTourAttachment",
  NewDomesticFlight = "/AddDomesticFlight",
  EditDomesticFlight = "/EditDomesticFlight",
  AllDomesticFlight = "GetAllDomesticFlight",
  DeleteDomesticFlight = "/DeleteDomesticFlight",
  AddTour = "TourManagement/AddTour",
  EditTour = "/EditTour",
  GetTourById = "/GetTourById",
  AddCompanionLeader = "/AddCompanionLeader",
  EditCompanionLeader = "/EditCompanionLeader",
  GetCompanionLeader = "/GetCompanionLeader",
  AddLeader = "/AddLeader",
  EditLeader = "/EditLeader",
  GetLeader = "/GetLeader",
  DeleteLeader = "/DeleteLeader",
  GetLeaderCardImage = "/LeaderCardImage",
  GetLeaderNationalCardImage = "/LeaderNationalCardImage",
  DeleteCompanionLeader = "/DeleteCompanionLeader",
  Tours = "TourManagement/GetAllTour",
  AddTourist = "/AddTourTourist",
  EditTourist = "/EditTourTourist",
  GetTourist = "GetAllTourTourist",
  DeleteTourist = "/DeleteTourTourist",
  AddListTourist = "/AddListTourTourist",
  GetTourManagement = "/GetTourManagement",
  RouteRooms = "/GetAllRouteRoom",
  DeleteRouteRoom = "/DeleteRouteRoom",
  NewRouteRoom = "/AddRouteRoom",
  EditRouteRoom = "/EditRouteRoom",
  RoutePlaces = "/GetAllRoutePlaceOfInterest",
  DeleteRoutePlace = "/DeleteRoutePlaceOfInterest",
  NewRoutePlace = "/AddRoutePlaceOfInterest",
  EditRoutePlace = "/EditRoutePlaceOfInterest",
  DeleteTranslator = "/DeleteTranslator",
  DeleteTour = "/DeleteTour",
  GetTouristByPassport = "/GetTouristByPassport",
  GetDashboardInfo = "TourManagement/GetDashboardInfo",
  GetAllTourCode = "TourManagement/GetAllToursCode",
}
