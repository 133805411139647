import { App } from "antd";
import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { IObject } from "src/models/interfaces";
import {
  ITourDateArg,
  ITourist,
  ITouristInfoArg,
} from "src/services/Tour/models";
import { TourService } from "src/services/Tour/Tour.service";
import useLanguage from "src/store/language";
import { useTourAddEditData } from "../../context/TourAddEditData";
import { TabMenuKeys } from "../../models";

interface IContextValue {
  states: {
    loading: boolean;
    dataLoading: boolean;
  };
  requests: {
    addEditTouristRequest: (values: ITouristInfoArg) => void;
    deleteTouristInfoRequest: (value: number[]) => Promise<void>;
    addTouristRequest: (value: ITourist, touristId: number) => Promise<void>;
    tourDateRequest: (values: IObject) => void;
  };
}

export const TouristInfoRequestContext = createContext<
  IContextValue | undefined
>(undefined);

const TouristInfoRequestProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const { words } = useLanguage();
  const { dispatches } = useTourAddEditData();
  const { tourId } = useParams();
  const location = useLocation();
  const { message } = App.useApp();

  const {
    AddTourist,
    EditTouristInfo,
    TourDate,
    DeleteTouristInfo,
    TouristInfo,
  } = new TourService();

  const tourDateRequest = async (values: IObject) => {
    if (!tourId) return;
    setLoading(true);

    const data: ITourDateArg = {
      startDate: values.startDate,
      endDate: values.endDate,
    };
    try {
      const result = await TourDate(tourId, data);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        dispatches.setFormData((prev) => ({
          ...prev,
          tourInfo: {
            ...prev?.tourInfo,
            startDate: values.startDate,
            endDate: values.endDate,
          },
        }));
        dispatches.setCurrentFilter(TabMenuKeys.team);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const addTouristRequest = async (value: ITourist, touristId: number) => {
    if (!tourId) return;

    try {
      const result =
        touristId === 0
          ? await AddTourist(tourId, value)
          : await EditTouristInfo(touristId, value);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        getTouristInfo();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteTouristInfoRequest = async (value: number[]) => {
    try {
      setLoading(true);
      const result = await DeleteTouristInfo(value);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        getTouristInfo();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const addEditTouristRequest = async (values: ITouristInfoArg) => {
    if (!tourId) return;
    setLoading(true);
    try {
      const result = await TouristInfo(tourId, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        dispatches.setFormData((prev) => ({
          ...prev,
          [TabMenuKeys.tourist]: values,
        }));
        dispatches.setCurrentFilter(TabMenuKeys.team);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getTouristInfo = useCallback(async () => {
    if (!tourId) return;

    try {
      setDataLoading(true);
      const { GetTouristInfo } = new TourService();

      const result = await GetTouristInfo(tourId);
      if (result && result.status === 200) {
        dispatches.setFormData((prev) => ({
          ...prev,
          tourist: { ...prev?.tourist, tourists: result.data },
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname.includes(ROUTES.tourEdit) && tourId) {
      getTouristInfo();
    }
  }, [getTouristInfo, location.pathname, tourId]);

  const contextValues = {
    states: {
      loading,
      dataLoading,
    },
    requests: {
      addEditTouristRequest,
      deleteTouristInfoRequest,
      addTouristRequest,
      tourDateRequest,
    },
  };

  return (
    <TouristInfoRequestContext.Provider value={contextValues}>
      {children}
    </TouristInfoRequestContext.Provider>
  );
};

export default TouristInfoRequestProvider;

export const useTouristInfoRequest = () =>
  useContext(TouristInfoRequestContext)!;
