import ManagementLeader from "src/modules/managementLeader";
import ManagementLeaderDataProvider from "src/modules/managementLeader/context";

const ManagementLeaderPage = () => (
  <ManagementLeaderDataProvider>
    <ManagementLeader />
  </ManagementLeaderDataProvider>
);

export default ManagementLeaderPage;
