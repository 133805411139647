import { FC } from "react";
import AddTourForm from "./components/AddTourForm";
import { AddTourContainer } from "./style";
import AddTourDataProvider from "./context";
import { Col } from "antd";

const AddTour: FC = () => {
  return (
    <AddTourDataProvider>
      {/* <PageHeader mode="" title={words.addTour.defineTour} /> */}
      <AddTourContainer justify={"center"} gutter={[0, 24]}>
        <Col span={24} className="content">
          <AddTourForm />
        </Col>
      </AddTourContainer>
    </AddTourDataProvider>
  );
};
export default AddTour;
