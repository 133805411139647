import { FC } from "react";
import { Tabs } from "antd";
import HotelsInfoForm from "./info/AddInfoForm";
import AddHotelContactDataProvider from "./contact/context";
import AddHotelInfoDataProvider from "./info/context";
import { HotelsViewMode } from "src/modules/hotels/models/enums";
import { AddContactWrapper } from "./contact";
import { useAddHotelsData } from "../context";
import { HotelsAddContainer } from "../style";
import { useHotelsData } from "../../../context/HotelsData";

const HotelsAddTabs: FC = () => {
  const {
    values: { currentTabKey },
    dispatches: { setCurrentTabKey },
  } = useAddHotelsData();

  const {
    states: { viewMode },
  } = useHotelsData();

  const onChange = (key: string) => {
    setCurrentTabKey(key);
  };

  return (
    <HotelsAddContainer span={24}>
      <Tabs
        onChange={onChange}
        defaultActiveKey="info"
        activeKey={currentTabKey}
        centered
        items={[
          {
            label: "اطلاعات پایه",
            key: "info",
            children: (
              <AddHotelInfoDataProvider>
                <HotelsInfoForm />
              </AddHotelInfoDataProvider>
            ),
            disabled: viewMode === HotelsViewMode.Edit ? false : true,
          },
          {
            label: "اطلاعات تماس",
            key: "contact",
            children: (
              <AddHotelContactDataProvider>
                <AddContactWrapper />
              </AddHotelContactDataProvider>
            ),
            disabled: viewMode === HotelsViewMode.Edit ? false : true,
          },
        ]}
      />
    </HotelsAddContainer>
  );
};

export default HotelsAddTabs;
