import { FC } from "react";
import { Button, Col, Menu, MenuProps, Row } from "antd";
import useLanguage from "src/store/language";
import { FilterContainer } from "../styles";
import { Link, useParams } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { useTourViewData } from "../context/TourViewData";
import { TabMenuKeys } from "src/modules/addEditTour/models";
import { TourStates } from "src/models/enums/TourStates";

const Filter: FC = () => {
  const { words } = useLanguage();

  const { states, dispatches } = useTourViewData();

  const { tourId } = useParams();

  const items: MenuProps["items"] = [
    {
      label: `${words.viewTour.touristInfo}`,
      key: TabMenuKeys.tourist,
    },
    {
      label: `${words.viewTour.teamInfo}`,
      key: TabMenuKeys.team,
    },
    {
      label: `${words.viewTour.tourInfo}`,
      key: TabMenuKeys.tour,
    },
    {
      label: `${words.viewTour.carInsurance}`,
      key: TabMenuKeys.insurance,
    },
    {
      label: `${words.viewTour.attachments}`,
      key: TabMenuKeys.attachment,
    },
  ];

  const onClick: MenuProps["onClick"] = (e) => {
    dispatches.setCurrentFilter(e.key);
  };
  return (
    <FilterContainer>
      <Row align="middle">
        <Col xs={4}>
          <Button
            type="text"
            onClick={() => dispatches.setViewMode("print-tourist")}
            className="license-btn"
          >
            {words.viewTour.licenseButtonTitle}
          </Button>
        </Col>
        <Col xs={16}>
          <Menu
            onClick={onClick}
            selectedKeys={[states.currentFilter]}
            mode="horizontal"
            items={items}
          />
        </Col>
        {states.formData?.tourInfo?.state !== Number(TourStates.Finished) && (
          <Col xs={4}>
            <Link to={ROUTES.tourEdit + tourId} className="edit-link">
              <span className="material-icons">edit_note</span>
              <span>{words.addEditTour.editTitle}</span>
            </Link>
          </Col>
        )}
      </Row>
    </FilterContainer>
  );
};

export default Filter;
