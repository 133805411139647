import styled from "styled-components";

export const NotificationContainer = styled.div``;

export const Content = styled.div`
  margin-top: 32px;
  padding: 32px 32px 24px 32px;
  border-radius: 20px;
  background: var(--color-Base-Color-White, #fff);

  /* 3 */
  box-shadow: 2px 6px 16px 0px rgba(0, 0, 0, 0.18);
  .ant-btn-default:disabled {
    background-color: #fad1b8;
    color: #fde8dc;
  }
  .ant-btn-default {
    background-color: #f06614;
    color: #fff;
  }
  .hBliQA {
    padding: 0px !important;
  }
  .ant-btn {
    padding: 0px !important;
  }
  .ant-btn-dashed:disabled {
    background-color: inherit;
    color: #fad1b8;
    border-style: solid;
    border-color: #fad1b8;
  }
  .ant-btn-dashed {
    border-color: #f06614;
    background-color: inherit;
    color: #f06614;
    border-style: solid;
  }
`;

export const ViewNotificationContainer = styled.div`
  margin-top: 32px;
  padding: 32px 32px 24px 32px;
  border-radius: 20px;
  background: var(--color-Base-Color-White, #fff);
  .hBliQA {
    padding: 0px;
  }
`;
