import { FC } from "react";
import { Row } from "antd";
import { NewRequests } from "./newRequests";
import { ActiveTours } from "./activeTours";
import DashboardDataProvider from "./context";
import { CancelTours } from "./cancelTours";
import { FinishTours } from "./finishTour";

interface IProps {}

const Home: FC<IProps> = () => {
  return (
    <DashboardDataProvider>
      <Row gutter={[24, 16]} className="px-[16px]" justify="space-around">
        <NewRequests />
        <ActiveTours />
      </Row>
      <Row
        gutter={[24, 16]}
        className="px-[16px] pb-[64px]"
        justify="space-around"
      >
        <CancelTours />
        <FinishTours />
      </Row>
    </DashboardDataProvider>
  );
};
export default Home;
