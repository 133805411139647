import { FC, useEffect } from "react";
import { Col, Form, Row } from "antd";
import _ from "lodash";
import InputUiKit from "src/components/UiKit/Input";
import useLanguage from "src/store/language";
import { useTourAddEditData } from "src/modules/addEditTour/context/TourAddEditData";
import { IObject } from "src/models/interfaces";
import { TabMenuKeys } from "src/modules/addEditTour/models";
import Actions from "../../Actions";
import { useCarInfoRequest } from "./CarInfoRequest";
import { ICarInfoArg } from "src/services/Tour/models";
import LoadingSpin from "src/components/loadingSpin";

const CarTabForm: FC = () => {
  const { words } = useLanguage();

  const [carForm] = Form.useForm();

  const { states, dispatches } = useTourAddEditData();
  const {
    states: { loading, dataLoading },
    requests: { addEditTouristRequest },
  } = useCarInfoRequest();

  useEffect(() => {
    if (states.formData && states.current === TabMenuKeys.car) {
      carForm.setFieldsValue({
        carInfo: states.formData.car?.carInfo,
        carNumber: states.formData.car?.carNumber,
        carInsurance: states.formData.car?.carInsurance,
        carInsuranceCompany: states.formData.car?.carInsuranceCompany,
      });
    }
  }, [carForm, states]);

  const handleFinishCarForm = (values: IObject) => {
    if (_.isEqual(states.formData?.car, values))
      dispatches.setCurrentFilter(TabMenuKeys.tour);
    else {
      addEditTouristRequest(values as ICarInfoArg);
    }
  };

  return dataLoading ? (
    <LoadingSpin />
  ) : (
    <Form form={carForm} onFinish={handleFinishCarForm}>
      <Row gutter={[8, 16]} className="team-form-row">
        <Col xs={24} md={12}>
          <Form.Item
            name={"carInfo"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit label={words.viewTour.carName} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"carNumber"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit label={words.viewTour.carNumber} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"carInsurance"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit label={words.viewTour.carInsurance} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"carInsuranceCompany"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit label={words.viewTour.carInsuranceCompany} />
          </Form.Item>
        </Col>
        <Actions
          loading={loading}
          onPrevious={() => dispatches.setCurrent(TabMenuKeys.driver)}
        />
      </Row>
    </Form>
  );
};

export default CarTabForm;
