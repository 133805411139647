import { Col, Flex, Row } from "antd";
import { FC } from "react";
import { useTourViewData } from "src/modules/viewTour/context/TourViewData";

const TeamPrint: FC = () => {
  const {
    states: { formData },
  } = useTourViewData();

  return (
    <Row gutter={[16, 8]} align="top" className="px-5 mt-5 pb-[40px]">
      <Col span={18}>
        <Flex vertical>
          <div className="w-full h-[40px] px-[24px] flex justify-between item-center content-center bg-[#8E8E8E] rounded-t-lg">
            <p className="text-[#fff]">اطلاعات اعضای تیم</p>
            <p>
              <span className="material-icons text-[#fff]">group</span>
            </p>
          </div>
        </Flex>
        <Row className="mt-1">
          <Col
            span={5}
            className="bg-[#E9EAF1] text-center text-[#1F1F1F] font-[16px] font-bold px-[20px] py-[16px]"
          >
            اعضای تیم
          </Col>

          <Col
            span={5}
            className="bg-[#E9EAF1] text-center text-[#1F1F1F] font-bold px-[20px] py-[16px]"
          >
            سرپرست
          </Col>

          <Col
            span={5}
            className="bg-[#E9EAF1] text-center text-[#1F1F1F] font-bold font-[16px] px-[20px] py-[16px]"
          >
            تور لیدر
          </Col>

          <Col
            span={5}
            className="bg-[#E9EAF1] text-center text-[#1F1F1F] font-bold font-[16px] px-[20px] py-[16px]"
          >
            مترجم
          </Col>

          <Col
            span={4}
            className="bg-[#E9EAF1] text-center text-[#1F1F1F] font-[16px] font-bold px-[20px] py-[16px]"
          >
            راننده
          </Col>
          <Col
            span={5}
            className="bg-[#E9EAF1] text-center text-[#1F1F1F] font-bold font-[16px] px-[20px] py-[16px]"
          >
            نام و نام خانوادگی
          </Col>
          <Col
            span={5}
            className="bg-[#F5F5F5] text-center text-[#1F1F1F] font-[16px] px-[20px] py-[16px]"
          >
            {formData?.tourInfo?.managerInfo}
          </Col>
          <Col
            span={5}
            className="bg-[#F5F5F5] text-center text-[#1F1F1F] px-[20px] py-[16px]"
          >
            {formData?.tourInfo?.leaderInfo}
          </Col>
          <Col
            span={5}
            className="bg-[#F5F5F5] text-center text-[#1F1F1F] px-[20px] py-[16px]"
          >
            {formData?.tourInfo?.translatorInfo}
          </Col>

          <Col
            span={4}
            className="bg-[#F5F5F5] text-center text-[#1F1F1F] px-[20px] py-[16px]"
          >
            {formData?.tourInfo?.driverInfo}
          </Col>

          <Col
            span={5}
            className="bg-[#E9EAF1] text-center text-[#1F1F1F] font-bold font-[16px] px-[20px] py-[16px]"
          >
            شماره تماس
          </Col>

          <Col
            span={5}
            className="bg-[#F5F5F5] text-center text-[#1F1F1F] font-[16px] px-[20px] py-[16px]"
          >
            {formData?.tourInfo?.managerPhone}
          </Col>

          <Col
            span={5}
            className="bg-[#F5F5F5] text-center text-[#1F1F1F] px-[20px] py-[16px]"
          >
            {formData?.tourInfo?.leaderPhone}
          </Col>

          <Col
            span={5}
            className="bg-[#F5F5F5] text-center text-[#1F1F1F] px-[20px] py-[16px]"
          >
            {formData?.tourInfo?.translatorPhone}
          </Col>

          <Col
            span={4}
            className="bg-[#F5F5F5] text-center text-[#1F1F1F] px-[20px] py-[16px]"
          >
            {formData?.tourInfo?.driverPhone}
          </Col>
        </Row>
      </Col>

      <Col span={6}>
        <Flex vertical>
          <div className="w-full h-[40px] px-[24px] inline-flex justify-between item-center bg-[#8E8E8E] rounded-t-lg">
            <span className="text-[#fff]">اطلاعات خودرو</span>
            <span className="material-icons text-[#fff]">directions_bus</span>
          </div>
        </Flex>
        <Row className="mt-1">
          <Col span={24} className="bg-[#F5F5F5] p-[24px] rounded-b-lg">
            <div className="w-full flex justify-between item-center">
              <span className="text-[#1F1F1F] font-[16px] font-bold">
                خودرو
              </span>
              <span className="text-[#1F1F1F] font-[16px] font-normal">
                {formData?.tourInfo?.carInfo}
              </span>
            </div>
            <div className="w-full flex justify-between item-center mt-2">
              <span className="text-[#1F1F1F] font-[16px] font-bold">پلاک</span>
              <span className="text-[#1F1F1F] font-[16px] font-normal">
                {formData?.tourInfo?.carNumber}
              </span>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TeamPrint;
