import { FC, useRef } from "react";
import { Row } from "antd";
import { Packer } from "docx";
import { saveAs } from "file-saver";
import LoadingSpin from "src/components/loadingSpin";
import { Details } from "./Details";
import Footer from "./Footer";
import { usePrintTouristRequest } from "./context";
import { DocumentCreator } from "./cv-generator";
import { useTourViewData } from "../../context/TourViewData";

export const PrintTourist: FC = () => {
  const {
    states: { formData },
  } = useTourViewData();
  const {
    states: { dataLoadings },
  } = usePrintTouristRequest();

  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = () => {
    const documentCreator = new DocumentCreator();
    const doc = documentCreator.create(formData);

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "tourists.docx");
    });
  };

  return (
    <Row className="bg-[#fff] p-[40px] rounded-2xl">
      {dataLoadings.tour || dataLoadings.tourInfo ? (
        <LoadingSpin />
      ) : (
        <>
          <Details ref={componentRef} />
          <Footer onDoc={handlePrint} />
        </>
      )}
    </Row>
  );
};
