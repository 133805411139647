import { FC, useEffect } from "react";
import { Col, Form, Row } from "antd";
import _ from "lodash";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import { useTourAddEditData } from "src/modules/addEditTour/context/TourAddEditData";
import { IObject } from "src/models/interfaces";
import DatePickerUi from "src/components/UiKit/DatePicker";
import { TabMenuKeys } from "src/modules/addEditTour/models";
import { convertDatePicker } from "src/helper";
import Actions from "../../Actions";
import { useTranslatorInfoRequest } from "./TranslatorInfoRequest";
import { ITranslatorInfoArg } from "src/services/Tour/models";
import LoadingSpin from "src/components/loadingSpin";

const TranslatorForm: FC = () => {
  const { words } = useLanguage();

  const [translatorForm] = Form.useForm();

  const { states, dispatches } = useTourAddEditData()!;
  const {
    states: { loading, dataLoading },
    requests: { addEditTranslatorRequest },
  } = useTranslatorInfoRequest();

  useEffect(() => {
    if (states.formData && states.current === TabMenuKeys.translator) {
      translatorForm.setFieldsValue({
        translatorInfo: states.formData.translator?.translatorInfo,
        translatorPhone: states.formData.translator?.translatorPhone,
        translatorFatherName: states.formData.translator?.translatorFatherName,
        translatorNationalCode:
          states.formData.translator?.translatorNationalCode,
        translatorIdNumber: states.formData.translator?.translatorIdNumber,
        translatorBirthDate: convertDatePicker(
          states.formData.translator?.translatorBirthDate
        ),
      });
    }
  }, [translatorForm, states]);

  const handleFinishTranslatorForm = (values: IObject) => {
    if (_.isEqual(states.formData?.translator, values))
      dispatches.setCurrent(TabMenuKeys.driver);
    else {
      addEditTranslatorRequest(values as ITranslatorInfoArg);
    }
  };

  return dataLoading ? (
    <LoadingSpin />
  ) : (
    <Form form={translatorForm} onFinish={handleFinishTranslatorForm}>
      <Row gutter={[8, 16]} className="team-form-row">
        <Col xs={24} md={12}>
          <Form.Item
            name={"translatorInfo"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit
              label={words.viewTour.firstName.concat(
                "-" + words.viewTour.lastName
              )}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"translatorPhone"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit label={words.global.contact} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"translatorFatherName"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit label={words.global.fatherName} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"translatorNationalCode"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit label={words.global.nationalCode} numbermode />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"translatorIdNumber"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit label={words.viewTour.cardNumber} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"translatorBirthDate"}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <DatePickerUi label="تاریخ تولد" />
          </Form.Item>
        </Col>
        <Actions
          loading={loading}
          onPrevious={() => dispatches.setCurrent(TabMenuKeys.leader)}
        />
      </Row>
    </Form>
  );
};

export default TranslatorForm;
