import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import { IManagementSimcardBuy } from "src/services/TourManagement/models";
interface IContextValue {
  states: {
    mode: string;
    buySimcard: IManagementSimcardBuy | undefined;
    dataLoading: boolean;
  };
  dispatches: {
    setBuySimcard: Dispatch<SetStateAction<IManagementSimcardBuy | undefined>>;
    setMode: Dispatch<SetStateAction<string>>;
  };
}

export const ManagementSimcardDataContext = createContext<
  IContextValue | undefined
>(undefined);

const ManagementSimcardDataProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [mode, setMode] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [buySimcard, setBuySimcard] = useState<
    IManagementSimcardBuy | undefined
  >(undefined);

  const { tourId } = useParams();
  const { state } = useLocation();

  const getBuySimcard = useCallback(async () => {
    if (!tourId) return;
    setDataLoading(true);
    try {
      const { GetManagementBuySimcard } = new TourManagementService();
      const res = await GetManagementBuySimcard(tourId);
      if (res && res.status === 200 && res.data) {
        setBuySimcard(res.data);
      } else {
        setBuySimcard(undefined);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoading(false);
    }
  }, [tourId]);

  useEffect(() => {
    if (tourId && (mode === "edit" || mode === "view")) {
      getBuySimcard();
    }
  }, [getBuySimcard, mode, tourId]);

  useEffect(() => {
    if (state && state.mode) setMode(state.mode);
    else setMode("add");
  }, [state]);

  const contextValues = {
    states: {
      mode,
      buySimcard,
      dataLoading,
    },
    dispatches: { setBuySimcard, setMode },
  };

  return (
    <ManagementSimcardDataContext.Provider value={contextValues}>
      {children}
    </ManagementSimcardDataContext.Provider>
  );
};

export default ManagementSimcardDataProvider;

export const useManagementSimcardData = () =>
  useContext(ManagementSimcardDataContext)!;
