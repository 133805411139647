import { FC } from "react";
import { Button, Col, Divider, Flex, Row } from "antd";
import { useHotelsData } from "src/modules/hotels/context/HotelsData";
import { HotelsViewMode } from "src/modules/hotels/models/enums";
import AddPerson from "./person";
import AddPhone from "./phone";
import { useAddHotelsData } from "../../context";
import AddHotelResponderDetailsDataProvider from "./phone/context";

export const AddContactWrapper: FC = () => {
  const {
    states: { viewMode },
    func,
  } = useHotelsData();

  const {
    dispatches: { setCurrentTabKey, setCreated },
  } = useAddHotelsData();

  const { states } = useHotelsData();

  const handleFinish = () => {
    states.addHotelForm.resetFields();
    setCurrentTabKey("info");
    setCreated(undefined);
  };
  const handleBack = () => {
    setCurrentTabKey("info");
  };

  const handleEnd = () => {
    if (states.viewMode === HotelsViewMode.View || !states.hotelInfoChanged)
      func.handleBack();
  };

  return (
    <AddHotelResponderDetailsDataProvider>
      <Row gutter={16} justify={"space-between"}>
        <Col span={24} className="my-[24px] ">
          <p className="title">
            {viewMode === HotelsViewMode.Edit
              ? "شما میتوانید اطلاعات تماس جدیدی ثبت و یا با انتخاب هر شماره تماس ؛ اطلاعات آن را ویرایش کنید."
              : "لطفا اطلاعات تماس هتل را وارد کنید."}{" "}
          </p>
        </Col>
        <Col span={11} className="add-contact-col gutter-row">
          <AddPerson />
        </Col>
        <Col span={11} className="add-contact-col gutter-row">
          <AddPhone />
        </Col>
        <Col span={24} className=" mt-[10px]">
          <Divider className="bg-[#DADEEC]" />
          <Flex justify="flex-end" align="center" gap={16}>
            <Button
              className="text-[#8E8E8E] mr-2 w-[85px]"
              onClick={handleBack}
            >
              بازگشت
            </Button>
            {states.viewMode === HotelsViewMode.Edit ? (
              <Button
                type="primary"
                className="bg-[#F06614] hover:!bg-[#F06614] w-[85px]"
                onClick={handleEnd}
              >
                پایان ویرایش
              </Button>
            ) : (
              <div>
                <Button
                  className="bg-[#F06614] text-[#fff] hover:!text-[#fff] w-[85px]"
                  onClick={handleFinish}
                >
                  پایان ثبت
                </Button>
              </div>
            )}
          </Flex>
        </Col>
      </Row>
    </AddHotelResponderDetailsDataProvider>
  );
};
