import { FC } from "react";
import { Col, Row } from "antd";
import useLanguage from "src/store/language";
import { TableUI } from "src/components/UiKit/table";
import { ColumnsType } from "antd/es/table";
import { ICar } from "src/services/BasicInformation/models";
import TableActions from "./TableActions";
import { useCarDataContext } from "../context";

const CarTable: FC = () => {
  const {
    cars,
    loadings,
    totalCount,
    tablePaginationParams,
    setTablePaginationParams,
  } = useCarDataContext();

  const { words } = useLanguage();

  const tableColumns: ColumnsType<ICar> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (text, record, index) =>
        (tablePaginationParams.Offset - 1) * tablePaginationParams.Limit +
        index +
        1,
    },
    {
      title: "خودرو",
      dataIndex: "carName",
      key: "carName",
    },
    {
      title: `${words.basicInfo.tableActions}`,
      dataIndex: "tools",
      key: "tools",
      render: (_, record) => <TableActions item={record} />,
    },
  ];

  return (
    <>
      <Row gutter={[24, 12]}>
        <Col span={24} className="mt-[20px]">
          <div className="title">{words.basicInfo.carTableTitle}</div>
        </Col>
        <Col span={24}>
          <TableUI
            columns={tableColumns}
            dataSource={cars}
            loading={loadings.table}
            scroll={{ x: 500 }}
            tableType="secondary"
            pagination={{
              position: ["bottomCenter"],
              total: totalCount,
              pageSize: tablePaginationParams.Limit,
              current: tablePaginationParams.Offset,
              onChange(page, pageSize) {
                setTablePaginationParams((prev) => ({
                  ...prev,
                  Offset: page,
                  Limit: pageSize,
                }));
              },
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default CarTable;
