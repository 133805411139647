import { Form, FormInstance } from "antd";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

interface IManagementNotification {
  value: {
    editMode: boolean;
    addSubmit: boolean;
    isOpenModal: boolean;
    totalCount: number;
  };
  dispatch: {
    setEditMode: Dispatch<SetStateAction<boolean>>;
    setAddSubmit: Dispatch<SetStateAction<boolean>>;
    setIsOpenModal: Dispatch<SetStateAction<boolean>>;
    setTotalCount: Dispatch<SetStateAction<number>>;
  };
  form: { notificationForm: FormInstance | undefined };
  func: {
    onFinish: () => void;
    onSaveEdit: () => void;
    onReset: () => void;
    onCancel: () => void;
    onEdit: () => void;
    onEnd: () => void;
    onOpenModal: () => void;
    onCloseModal: () => void;
  };
}

const defaultTcxValue: IManagementNotification = {
  value: {
    editMode: false,
    addSubmit: false,
    isOpenModal: false,
    totalCount: 0,
  },
  dispatch: {
    setEditMode: () => {},
    setAddSubmit: () => {},
    setIsOpenModal: () => {},
    setTotalCount: () => {},
  },
  form: { notificationForm: undefined },
  func: {
    onFinish: () => {},
    onSaveEdit: () => {},
    onReset: () => {},
    onCancel: () => {},
    onEdit: () => {},
    onEnd: () => {},
    onOpenModal: () => {},
    onCloseModal: () => {},
  },
};

export const ManagementNotificationContext =
  createContext<IManagementNotification>(defaultTcxValue);

export const ManagementNotificationProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  //-------------state-------------------

  const [editMode, setEditMode] = useState<boolean>(false);
  const [addSubmit, setAddSubmit] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);

  //--------------form-------------------

  const [notificationForm] = Form.useForm();

  //--------------func-----------------

  const onFinish = () => {};

  const onSaveEdit = () => {};

  const onReset = () => {
    notificationForm.resetFields();
  };

  const onCancel = () => {
    setEditMode(false);
  };

  const onEdit = () => {
    setEditMode(true);
  };

  const onEnd = () => {};

  const onOpenModal = () => {
    setIsOpenModal(true);
  };

  const onCloseModal = () => {
    setIsOpenModal(false);
  };

  const defaultValue: IManagementNotification = {
    value: { editMode, addSubmit, isOpenModal, totalCount },
    dispatch: { setEditMode, setAddSubmit, setIsOpenModal, setTotalCount },
    form: { notificationForm },
    func: {
      onCancel,
      onEdit,
      onSaveEdit,
      onEnd,
      onFinish,
      onReset,
      onOpenModal,
      onCloseModal,
    },
  };

  return (
    <ManagementNotificationContext.Provider value={defaultValue}>
      {children}
    </ManagementNotificationContext.Provider>
  );
};

export const useManagementNotification = () =>
  useContext(ManagementNotificationContext);
