import { UploadRequestOption } from "rc-upload/lib/interface";
import { USER_JWT_TOKEN } from "src/constants";
import axios, { AxiosRequestConfig } from "axios";
import { v4 as uuid } from "uuid";
import { Buffer } from "buffer";
import { RcFile } from "antd/es/upload";
import { message } from "antd";
import { Dispatch, SetStateAction } from "react";

interface IUploadMultipart {
  callbackUrl: string;
  options?: UploadRequestOption<any>;
  setProgress?: Dispatch<SetStateAction<number>>;
  newFile?: Blob;
  method?: "POST" | "PUT" | "PATCH";
  insurance?: { title?: string; description?: string };
  comment?: string;
}
export const uploadFile = async <T>({
  callbackUrl,
  newFile,
  setProgress,
  options,
  method,
  insurance,
  comment,
}: IUploadMultipart) => {
  if (options) {
    var { onSuccess, onError, file, onProgress } = options;
  } else {
    if (newFile) file = newFile;
    else {
      message.error("file not found");
      return null;
    }
  }
  const fileNameArray = (file as RcFile).name.split(".");
  const size = (file as RcFile).size;
  const extention = fileNameArray[fileNameArray.length - 1];
  let count = 0;
  const uid = uuid();
  const blob = new Blob([file]);
  const partSize = 1000000;
  const buf = await blob.arrayBuffer();
  const totalSlice = Math.ceil(size / partSize);
  const slices: { [key: string]: any } = {};
  if (totalSlice > 1) {
    const sliceNum = size / totalSlice;
    let slice = 0;
    for (let i = 0; i < totalSlice + 1; i++) {
      const file = buf.slice(slice, slice + sliceNum);
      slice = slice + sliceNum;
      slices[i] = file;
    }
  } else {
    slices[0] = buf;
  }
  const endpoint = process.env.REACT_APP_API_URL + "Public/UploadFile";
  const slicesKeyArr = Object.keys(slices);
  let allFilesUploaded = true;
  let totalUploadedFiles = 0;

  const config: AxiosRequestConfig = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ` + localStorage.getItem(USER_JWT_TOKEN)!,
    },
    onUploadProgress: (event) => {
      const uploaded = event.loaded;
      const percent = Math.floor((uploaded / size) * 100);
      const currentPercent = Math.floor((uploaded / (event.total || 0)) * 100);
      if (currentPercent === 100) {
        totalUploadedFiles = 100 / totalSlice + totalUploadedFiles;
        setProgress && setProgress(Math.ceil(totalUploadedFiles));
      }
      if (onProgress) onProgress({ percent: percent });
    },
    timeout: 10000000,
  };
  let reqErr: any;
  for (let i = 0; i < slicesKeyArr.length; i++) {
    const fileName = uid.concat(`_${i}`);
    let base64File = Buffer.from(slices[i]).toString("base64");
    if (!base64File) break;
    count += 1;
    try {
      await axios.post(endpoint, { fileName, base64File }, config);
    } catch (err) {
      allFilesUploaded = false;
      reqErr = err;
    }
  }
  if (allFilesUploaded === true) {
    const zipFileEndpoint = process.env.REACT_APP_API_URL + callbackUrl;
    let data;
    if (insurance) {
      data = {
        fileName: uid,
        count,
        extention,
        title: insurance.title,
        description: insurance.description,
      };
    } else if (comment) {
      data = {
        fileName: uid,
        count,
        extention,
        comment,
      };
    } else {
      data = {
        fileName: uid,
        count,
        extention,
      };
    }
    try {
      const res = method
        ? await axios<T>(zipFileEndpoint, {
            method,
            data,
          })
        : await axios.post<T>(zipFileEndpoint, {
            ...data,
          });
      if (res && res.status === 200) {
        setProgress && setProgress(0);
        onSuccess && onSuccess("Ok");
        return res;
      } else {
        return null;
      }
    } catch (err: any) {
      console.log(err);
      onError && onError(err);
      return null;
    }
  } else {
    message.error("upload failed.");
    onError && onError(reqErr);
    return null;
  }
};
