import styled from "styled-components";

export const ConfirmModalBodyContainer = styled.div`
  .ant-row {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #dadeec;
  }
  .btn-cancel {
    color: #8e8e8e;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    margin: 0 10px;
    font-weight: 700;
    font-size: 12px;
    width: 60px;
  }

  .btn-ok {
    background-color: #f64c4c !important;
    color: #ffffff !important;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
  }
  .description {
    margin-top: 20px;
    text-align: center;
    border: 1px solid #dadeec;
    border-radius: 12px;
    font-weight: 700;
    font-size: 16px;
    padding: 48px;
  }
  .material-icons {
    font-size: 12px;
  }
`;
