import { Flex } from "antd";
import { FC } from "react";
import { convertDate } from "src/helper";
import { useTourViewData } from "src/modules/viewTour/context/TourViewData";

const Header: FC = () => {
  const {
    states: { formData },
  } = useTourViewData();
  return (
    <>
      <Flex justify="space-between" align="center" className="px-4">
        <p></p>

        <Flex vertical>
          <Flex>
            <p className="text-left w-[86px]">تاریخ :</p>
            <p className="px-5 text-left">
              {convertDate(new Date().toDateString())}
            </p>
          </Flex>
          <Flex>
            <p className="text-left w-[86px]">شماره نامه:</p>
            <p className="px-5 text-left"></p>
          </Flex>
        </Flex>
      </Flex>
      <div className="px-[16px]">
        <p className="mt-5 text-[#1F1F1F] font-[16px] font-bold text-right">
          ریاست محترم پلیس مهاجرت و گذرنامه ناجا-حراست ویژه
        </p>
        <p className="mt-5 text-[#1F1F1F] font-[16px] font-normal text-right">
          باسلام و احترام
        </p>
        <p className="mt-5 text-[#1F1F1F] font-[16px] font-normal text-right">
          برنامه سفر و فهرست اسامی مسافرین به شرح زیر جهت صدور کد ناجا ارسال می
          .گردد
        </p>
        <p className="mt-5 text-[#1F1F1F] font-[16px] font-bold text-right">
          .گفتنی است صحت مشخصات بر عهده این دفتر/شرکت می باشد
        </p>
      </div>

      <Flex
        justify="space-between"
        className="mt-10 px-[16px] py-[24px] border border-solid border-[#CACACA]"
      >
        <div className="text-[#1F1F1F] font-[16px] font-bold">
          <span>نام آژانس :</span>
          <span>میثاق گشت صدرا</span>
        </div>
        <div className="text-[#1F1F1F] font-[16px] font-bold">
          <span>ملیت جهان گردان :</span>
          <span>
            {formData?.tourInfo?.nation ? formData?.tourInfo?.nation : "-"}
          </span>
        </div>
        <div className="text-[#1F1F1F] font-[16px] font-bold">
          <span>تعداد جهان گردان :</span>
          <span></span>
          {formData?.tourInfo?.touristNumber
            ? formData?.tourInfo?.touristNumber
            : 0}
          نفر
        </div>
        <div className="text-[#1F1F1F] font-[16px] font-bold">
          <span>مدت تور :</span>
          <span>
            {formData?.tourInfo?.duration ? formData?.tourInfo?.duration : "-"}{" "}
            روز
          </span>
        </div>
      </Flex>
    </>
  );
};

export default Header;
