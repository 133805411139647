import { FC } from "react";
import { Flex } from "antd";
import { useNavigate } from "react-router-dom";
import useLanguage from "src/store/language";
import { ROUTES } from "src/models/enums/routes";
import { convertDate } from "src/helper";
import { IDashboardInfoTour } from "src/services/TourManagement/models";
import useGlobalStore from "src/store/global";
import { CancelTourCardCardStyle } from "./styles";

interface IProps {
  tour: IDashboardInfoTour;
}
export const CancelTourCard: FC<IProps> = ({ tour }) => {
  const { words } = useLanguage();

  const navigate = useNavigate();

  const { setTourCode } = useGlobalStore();

  const handleLink = () => {
    setTourCode(tour.tourCode);
    navigate(
      ROUTES.tourTable.concat(
        "/" + tour.tourId.toString() + ROUTES.managementTour
      )
    );
  };

  return (
    <CancelTourCardCardStyle>
      <div className="image-container">
        <img src="/assets/images/cancelTour.svg" alt="activeTour" />
      </div>
      <Flex vertical className="w-full">
        <div className="flex justify-between items-center w-full">
          <div className="nation">{tour.countryName || "-"}</div>
          <div className="code">{tour.tourCode ? tour.tourCode : "-"}</div>
        </div>
        <div className="flex justify-between items-center w-full">
          <div className="amount-date">
            <span>{tour.touristCount}</span>
            <span className="mr-[5px]">نفر</span>
          </div>
          <div className="date">
            {tour && tour.cancelDate ? convertDate(tour.cancelDate) : "-"}
          </div>
        </div>
      </Flex>

      <div className="flex justify-center items-center w-full">
        <span onClick={handleLink} className="footerText">
          {words.dashboard.viewTour}
        </span>
      </div>
    </CancelTourCardCardStyle>
  );
};
