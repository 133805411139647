import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";

export const useManagementTourBack = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  let location = useLocation();

  const backHandler = async () => {
    let pathArray = location.pathname.split("/");
    pathArray.splice(-1);

    if (pathArray.length === 4) navigate(pathArray.join("/"));
    else if (pathArray.length === 6) {
      pathArray.splice(-1);
      let mode: string | undefined = state.mode;
      !mode
        ? navigate(pathArray.join("/"))
        : navigate(pathArray.join("/"), { state: { mode: state.mode } });
    } else navigate(ROUTES.tourTable);
  };

  const backHandlerHome = () => {
    navigate(ROUTES.home);
  };

  return { backHandler, backHandlerHome };
};
