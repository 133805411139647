import { TimePicker } from "antd";
import styled from "styled-components";

export const TimePickerStyled = styled(TimePicker)`
  background-color: transparent !important;
  border: none !important;
  margin-top: 0 !important;
  width: 100% !important;
  height: 100% !important;

  .ant-picker-input input,
  .ant-picker-input input::placeholder {
    color: #8e8e8e;
    font-weight: 500;
    font-size: 12px;
  }
  &:focus {
    border: 0 !important;
    box-shadow: none !important;
    outline: none !important;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: none !important;
    -webkit-border: 0 !important;
    -webkit-outline: none !important;
  }
  input {
    color: #1f1f1f !important;
    font-weight: 500;
    font-size: 12px;
  }
`;
