import { Flex } from "antd";
import { FC } from "react";
import { ITableRouteRoom } from "src/services/TourManagement/models";
import useLanguage from "src/store/language";
import { useRouteHotelsData } from "../../context";

interface ITableActionsProps {
  item: ITableRouteRoom;
}

const TableActions: FC<ITableActionsProps> = ({ item }) => {
  const { words } = useLanguage();

  const {
    func: { handleDeleteId },
    dispatches: { setSelectedRouteRoom },
  } = useRouteHotelsData();

  const handleSelectHotel = () => {
    setSelectedRouteRoom(item);
  };

  return (
    <>
      <Flex justify="center" align="center" className="gap-[16px]">
        <div className="edit-action" onClick={() => handleSelectHotel()}>
          <span className="material-icons text-[#0C9D61]  text-[16px]">
            edit_note
          </span>
          <span>{words.global.editAction}</span>
        </div>
        <div
          className="delete-action"
          onClick={() => handleDeleteId(item.routeRoomId)}
        >
          <span className="material-icons text-[14px]">delete_forever</span>
          <span>{words.global.delete}</span>
        </div>
      </Flex>
    </>
  );
};

export default TableActions;
