import { FC } from "react";
import { Col } from "antd";
import { ManagementDriverContainer } from "./style";
import { useManagementDriverData } from "./context";
import ViewDriverCar from "./components/view";
import AddEditDriver from "./components/add-edit";

const ManagementDriver: FC = () => {
  const {
    states: { mode },
  } = useManagementDriverData();

  return (
    <ManagementDriverContainer justify={"center"} gutter={[0, 24]}>
      <Col xs={24} md={mode === "view" ? 18 : 23} className="content">
        {mode === "view" ? <ViewDriverCar /> : <AddEditDriver />}
      </Col>
    </ManagementDriverContainer>
  );
};

export default ManagementDriver;
