import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { IPagination } from "src/models/interfaces/pagination";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import { IAssignSimcard } from "src/services/TourManagement/models";
interface IContextValue {
  states: {
    mode: string;
    simcards: IAssignSimcard[];
    dataLoading: boolean;
    totalCount: number;
    tablePaginationParams: Pick<IPagination, "Offset" | "Limit">;
  };
  func: {};
  dispatches: {
    setTablePaginationParams: Dispatch<
      SetStateAction<Pick<IPagination, "Offset" | "Limit">>
    >;
  };
  requests: {
    getAssignSimcards: () => void;
  };
}

export const ManagementSimcardAssignDataContext = createContext<
  IContextValue | undefined
>(undefined);

const ManagementSimcardAssignDataProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [mode, setMode] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [simcards, setSimcard] = useState<IAssignSimcard[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [tablePaginationParams, setTablePaginationParams] =
    useState<IPagination>({
      Offset: 1,
      Limit: 10,
    });

  const { tourId } = useParams();
  const { state } = useLocation();

  const getAssignSimcards = useCallback(async () => {
    if (!tourId) return;
    setDataLoading(true);
    const params = new URLSearchParams(tablePaginationParams);
    try {
      const { AllAssignSimcards } = new TourManagementService();
      const res = await AllAssignSimcards(+tourId, params.toString());
      if (res && res.status === 200 && res.data) {
        setSimcard(res.data.records);
        setTotalCount(res.data.count);
      } else {
        setSimcard([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoading(false);
    }
  }, [tablePaginationParams, tourId]);

  useEffect(() => {
    if (tourId && (mode === "edit" || mode === "view")) {
      getAssignSimcards();
    }
  }, [getAssignSimcards, mode, tourId]);

  useEffect(() => {
    if (state && state.mode) setMode(state.mode);
    else setMode("edit");
  }, [state]);

  const contextValues = {
    states: {
      mode,
      simcards,
      dataLoading,
      totalCount,
      tablePaginationParams,
    },
    func: {},
    dispatches: { setTablePaginationParams },
    requests: { getAssignSimcards },
  };

  return (
    <ManagementSimcardAssignDataContext.Provider value={contextValues}>
      {children}
    </ManagementSimcardAssignDataContext.Provider>
  );
};

export default ManagementSimcardAssignDataProvider;

export const useManagementSimcardData = () =>
  useContext(ManagementSimcardAssignDataContext)!;
