import { FC } from "react";
import { Col } from "antd";
import AddEditRouteHotels from "./components/form/AddEditRouteHotels";
import RouteHotelsTable from "./components/table/RouteHotelsTable";
import { useRouteHotelsData } from "./context";
import { ManagementRouteRoomContainer } from "./style";

const RouteHotels: FC = () => {
  const {
    states: { mode },
  } = useRouteHotelsData();

  return (
    <>
      {/* <RouteRoomPageHeader /> */}
      <ManagementRouteRoomContainer justify={"center"} gutter={[0, 24]}>
        <Col xs={24} md={23} className="content">
          {(mode === "add" || mode === "edit") && <AddEditRouteHotels />}
          <RouteHotelsTable />
        </Col>
      </ManagementRouteRoomContainer>
    </>
  );
};

export default RouteHotels;
