import { FC } from "react";
import { Flex } from "antd";
import { TableUI } from "src/components/UiKit/table";
import { ColumnsType } from "antd/es/table";
import { IManagementRoute } from "src/services/TourManagement/models";
import useLanguage from "src/store/language";
import { convertDate, convertDateMiladi } from "src/helper";
import TableActions from "./TableActions";
import { useManagementRouteAddEditData } from "../context";
import { useManagementRouteData } from "src/modules/managementRoute/context";

const ManagementRouteTable: FC = () => {
  const {
    states: {
      managementRoutes,
      dataLoading,
      totalCount,
      tablePaginationParams,
      selectedRote,
    },
    dispatches: { setTablePaginationParams },
  } = useManagementRouteAddEditData();

  const { words } = useLanguage();

  const {
    states: { mode },
  } = useManagementRouteData();

  const tableColumns: ColumnsType<IManagementRoute> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      width: 65,
      fixed: "left",
      render: (text, record, index) =>
        (tablePaginationParams.Offset - 1) * tablePaginationParams.Limit +
        (index + 1),
    },
    {
      title: `${words.viewTour.fromCity}`,
      dataIndex: "cityName",
      key: "cityName",
      width: 100,
      fixed: "left",
      className: "rightShadow",
      render: (value, record) =>
        record.detail ? value.concat(" (" + record.detail.concat(")")) : value,
    },
    {
      title: `${words.managementRoute.intermediateCities}`,
      dataIndex: "inlineCities",
      key: "inlineCities",
      render: (value) => (value && value !== "" ? value : "-"),
    },
    {
      title: `${words.managementRoute.group}`,
      dataIndex: "groupNum",
      key: "groupNum",
      width: 60,
    },
    {
      title: `${words.managementRoute.tableColumnTime}`,
      dataIndex: "flightTime",
      key: "flightTime",
      width: 100,
      render: (value) => (value ? value : "-"),
    },
    {
      title: words.managementRoute.entryDate,
      dataIndex: "arraivalDate",
      key: "arraivalDate",
      width: 100,
      render: (value) =>
        value ? (
          <Flex vertical align="center" justify="space-between">
            <span>{convertDate(value)}</span>
            <span className="mt-[5px]">{convertDateMiladi(value)}</span>
          </Flex>
        ) : (
          "-"
        ),
    },
    {
      title: words.managementRoute.departureDate,
      dataIndex: "departureDate",
      key: "departureDate",
      width: 100,
      render: (value) =>
        value ? (
          <Flex vertical align="center" justify="space-between">
            <span>{convertDate(value)}</span>
            <span className="mt-[5px]">{convertDateMiladi(value)}</span>
          </Flex>
        ) : (
          "-"
        ),
    },
    {
      title: `${words.managementHotel.hotel}`,
      dataIndex: "hotelName",
      key: "hotelName",
    },
    {
      title: `${words.basicInfo.tableActions}`,
      dataIndex: "tools",
      key: "tools",
      width: 270,
      fixed: "right",
      className: "leftShadow",
      render: (_: any, record: IManagementRoute) => (
        <TableActions item={record} />
      ),
    },
  ];

  return (
    <>
      {mode === "add" || mode === "edit" ? (
        <p className="hotel-table-title">{words.managementRoute.tableTitle}</p>
      ) : (
        <p className="hotel-table-title !font-bold !text-[#233069]">
          {words.managementRoute.tableTitle}
        </p>
      )}
      <TableUI
        columns={tableColumns}
        dataSource={managementRoutes}
        loading={dataLoading}
        scroll={{ x: 1325 }}
        tableType="secondary"
        rowClassName={(record) =>
          record.id === selectedRote?.id ? "selected-row-for-edit" : ""
        }
        pagination={{
          position: ["bottomCenter"],
          total: totalCount,
          pageSize: tablePaginationParams.Limit,
          current: tablePaginationParams.Offset,
          onChange(page, pageSize) {
            setTablePaginationParams((prev) => ({
              ...prev,
              Offset: page,
              Limit: pageSize,
            }));
          },
        }}
      />
    </>
  );
};

export default ManagementRouteTable;

const TableColumnTitle = (title: string, subTitle: string) => {
  return (
    <Flex vertical>
      <span>{title}</span>
      <span className="subTitle text-[12px] font-normal">{subTitle}</span>
    </Flex>
  );
};
