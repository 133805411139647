export enum HotelsTabMenuKeys {
  Add = "places-add",
  Table = "places-table",
}

export enum HotelsViewMode {
  Menu = "menu",
  View = "view",
  Edit = "edit",
}
