import { useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { reactQueryEndPoints } from "src/constants";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import useGlobalStore from "src/store/global";

export const useTourCode = () => {
  const { setTourCode, tourCode } = useGlobalStore();
  const { tourId } = useParams();

  const fetchTourCode = async () => {
    if (!tourId || tourCode) return;
    try {
      const { GetTourById } = new TourManagementService();
      const res = await GetTourById(+tourId);
      if (res && res.data) {
        setTourCode && setTourCode(res.data.tourCode);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const { refetch: tourCodeRefetch } = useQuery({
    queryKey: reactQueryEndPoints.TourCode,
    queryFn: fetchTourCode,
    enabled: false,
  });
  useEffect(() => {
    tourCodeRefetch();
  }, [tourCodeRefetch]);
  return { tourCodeRefetch };
};
