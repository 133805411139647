import { FC } from "react";
import { Col, Row } from "antd";
import FormItem from "antd/es/form/FormItem";
import useLanguage from "src/store/language";
import { useManagementDriverData } from "../../context";
import InputUiKit from "src/components/UiKit/Input";
import DatePickerUi from "src/components/UiKit/DatePicker";

const DriverInfo: FC = () => {
  const { words } = useLanguage();

  const {
    states: { mode },
  } = useManagementDriverData();

  return (
    <>
      <p className="title">
        {mode === "add"
          ? words.managementDriver.addFormTitle
          : words.managementDriver.editFormTitle}
      </p>
      {mode === "edit" ? (
        <p
          className={`${
            mode === "edit" ? "driver-edit-form-title" : "driver-add-form-title"
          }`}
        >
          {words.managementDriver.driverEditFormTitle}
        </p>
      ) : null}

      <Row gutter={[24, 12]}>
        <Col xs={24} md={8}>
          <FormItem
            name={"fullName"}
            label={words.managementDriver.nameLabel}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit placeholder={words.managementDriver.namePlaceHolder} />
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem
            name={"fatherName"}
            label={words.managementDriver.fatherNameLabel}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit
              placeholder={words.managementDriver.fatherNamePlaceholder}
            />
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem
            name={"nationalCode"}
            label={words.managementDriver.nationalCodeLabel}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit
              placeholder={words.managementDriver.nationalCodePlaceholder}
              numbermode
            />
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem
            name={"identityNumber"}
            label={words.managementDriver.idNumberLabel}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit
              placeholder={words.managementDriver.idNumberPlaceholder}
              numbermode
            />
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem
            name={"birthDate"}
            label={words.managementDriver.dateLabel}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <DatePickerUi
              placeholder={words.managementDriver.datePlaceholder}
            />
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem
            name={"phoneNumber"}
            label={words.managementDriver.phoneLabel}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit placeholder={words.managementDriver.phonePlaceHolder} />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

export default DriverInfo;
