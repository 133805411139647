import { Card, Col, Row, Spin } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { useManagement } from "../hook/managementHook";
import { useManagementTour } from "../context";
import { Link } from "react-router-dom";

export const ManagementTourContent: FC = () => {
  const { words } = useLanguage();

  const { teemInfo, tourInfo } = useManagement();

  const {
    value: { tourManagement, mainLink, dataLoading },
  } = useManagementTour();

  return (
    <Row className="mt-[10px] pb-[24px] px-[24px]">
      <Col span={24} className="mt-[20px]">
        <Row gutter={[24, 24]}>
          <Col xs={24} md={12}>
            <div className="card-title">
              {words.managementTour.tourCardTitle}
            </div>
            <div className="card-body">
              {dataLoading ? (
                <div className="w-full flex items-center justify-center">
                  <Spin size="large" />
                </div>
              ) : (
                <Row gutter={[16, 24]}>
                  {tourInfo.map((i) => (
                    <Col span={8} key={i.id}>
                      <Card
                        bordered={false}
                        className={`card h-full ${
                          tourManagement?.flags.includes(i.flag) &&
                          "active-border"
                        }`}
                      >
                        <div className="flex justify-start gap-[8px] items-center border-0 border-b-[1px] border-solid border-[#E1E1E1] pb-[12px]">
                          <img
                            src={`/assets/images/managementTourIcon/${i.icon}.svg`}
                            alt=""
                          />
                          <span className="text-[11px] text-[#1F1F1F] font-bold">
                            {i.name}
                          </span>
                        </div>
                        {tourManagement?.flags.includes(i.flag) ? (
                          <div className="flex justify-evenly items-center pt-[12px] ">
                            <Link
                              to={mainLink.concat(i.endPoint)}
                              state={{ mode: "view" }}
                              className="text-[12px] font-normal text-[#FE9B0E] cursor-pointer"
                            >
                              {words.managementTour.view}
                            </Link>
                            <div className="border border-solid border-[#E1E1E1] !w-[1px] !h-[18px]"></div>
                            <Link
                              to={mainLink.concat(i.endPoint)}
                              state={{ mode: "edit" }}
                              className="text-[12px] font-normal text-[#0C9D61] cursor-pointer"
                            >
                              {words.managementTour.edit}
                            </Link>
                          </div>
                        ) : (
                          <div className="flex justify-center items-center pt-[12px] px[8px]">
                            <Link
                              to={mainLink.concat(i.endPoint)}
                              state={{ mode: "add" }}
                              className="text-[12px] font-normal text-[#FE9B0E] cursor-pointer"
                            >
                              {words.managementTour.add}
                            </Link>
                          </div>
                        )}
                      </Card>
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className="card-title">
              {words.managementTour.teamCardTitle}
            </div>
            <div className="card-body">
              {dataLoading ? (
                <div className="w-full flex items-center justify-center">
                  <Spin size="large" />
                </div>
              ) : (
                <Row gutter={[16, 24]}>
                  {teemInfo.map((i) => (
                    <Col span={8} key={i.id}>
                      <Card
                        bordered={false}
                        className={`card ${
                          tourManagement?.flags.includes(i.flag) &&
                          "active-border"
                        }`}
                      >
                        <div className="flex justify-start gap-[16px] items-center border-0 border-b-[1px] border-solid border-[#E1E1E1] pb-[12px]">
                          <img
                            src={`/assets/images/managementTourIcon/${i.icon}.svg`}
                            alt=""
                          />
                          <span className="text-[11px] text-[#1F1F1F] font-bold">
                            {i.name}
                          </span>
                        </div>
                        {tourManagement?.flags.includes(i.flag) ? (
                          <div className="flex justify-evenly items-center pt-[12px] px-[8px] ">
                            <Link
                              to={mainLink.concat(i.endPoint)}
                              state={{ mode: "view" }}
                              className="text-[12px] font-normal text-[#FE9B0E] cursor-pointer"
                            >
                              {words.managementTour.view}
                            </Link>
                            <div className="border border-solid border-[#E1E1E1] !w-[1px] !h-[18px]"></div>
                            <Link
                              to={mainLink.concat(i.endPoint)}
                              state={{ mode: "edit" }}
                              className="text-[12px] font-normal text-[#0C9D61] cursor-pointer"
                            >
                              {words.managementTour.edit}
                            </Link>
                          </div>
                        ) : (
                          <div className="flex justify-center items-center pt-[12px] px[8px]">
                            <Link
                              to={mainLink.concat(i.endPoint)}
                              state={{ mode: "add" }}
                              className="text-[12px] font-normal text-[#FE9B0E] cursor-pointer"
                            >
                              {words.managementTour.add}
                            </Link>
                          </div>
                        )}
                      </Card>
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
