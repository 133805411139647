import { TableColumnsType } from "antd";
import { IRoomDetail } from "src/services/ExportData/models";

export const reservationColumns: TableColumnsType<IRoomDetail> = [
  {
    title: "#",
    key: "#",
    render: (text, record, index) => index + 1,
  },
  {
    title: "نام اتاق",
    dataIndex: "roomName",
    key: "roomName",
  },
  {
    title: "تعداد",
    dataIndex: "roomCount",
    key: "roomCount",
  },
  { title: "تاریخ ورود", dataIndex: "arrivalDate", key: "arrivalDate" },
  { title: "تاریخ خروج ", dataIndex: "departureDate", key: "departureDate" },
  {
    title: "مدت",
    dataIndex: "days",
    key: "days",
  },
];
