import { Tabs } from "antd/lib";
import { FC, useState } from "react";
import { ReportTabHeader } from "./components/TabHeader";
import { useReportHeaderItems } from "./components/TabHeader/hooks/headerItems";

export const ManagementTourReport: FC = () => {
  const [activeKey, setActiveKey] = useState<string>("1");
  const items = useReportHeaderItems({ activeKey });
  return (
    <div>
      <Tabs
        items={items}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
        renderTabBar={({ activeKey: TabActiveKey, onTabClick }) => (
          <ReportTabHeader
            activeKey={TabActiveKey}
            items={items}
            onTabClick={onTabClick}
          />
        )}
      />
    </div>
  );
};
