import { Form } from "antd";
import _ from "lodash";
import { useManagementSupervisorData } from "./context";

export const useFormHasChanged = () => {
  const {
    states: { supervisor, supervisorForm },
  } = useManagementSupervisorData();

  const allValues = Form.useWatch([], supervisorForm);

  const formHasProperty = () => {
    return allValues
      ? Object.values(allValues).every((el) => el === undefined || el === null)
      : false;
  };

  const changed = _.isEqual(allValues, supervisor);

  return supervisor ? changed : formHasProperty();
};
