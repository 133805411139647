import {
  IGetExportFlight,
  IGetExportHotelList,
  IGetExportHotelMail,
  IGetExportMigration,
  IGetExportNaja,
  IGetExportRoute,
} from "./models";
import { ExportDataUrls } from "./urls";
import axios from "axios";

export class ExportDataService {
  async GetExportRoute(tourId: string | number) {
    const endpoint = ExportDataUrls.ExportData.concat(tourId.toString()).concat(
      ExportDataUrls.GetExportRoute
    );
    try {
      const response = await axios.get<IGetExportRoute>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetExportHotelList(RouteId: string | number) {
    const endpoint = ExportDataUrls.ExportData.concat(
      RouteId.toString()
    ).concat(ExportDataUrls.GetExportHotelList);
    try {
      const response = await axios.get<IGetExportHotelList>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetExportNaja(tourId: string | number) {
    const endpoint = ExportDataUrls.ExportData.concat(tourId.toString()).concat(
      ExportDataUrls.GetExportNaja
    );
    try {
      const response = await axios.get<IGetExportNaja[]>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetExportMigration(tourId: string | number) {
    const endpoint = ExportDataUrls.ExportData.concat(tourId.toString()).concat(
      ExportDataUrls.GetExportMigration
    );
    try {
      const response = await axios.get<IGetExportMigration>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetExportHotelMail(RouteId: string | number) {
    const endpoint = ExportDataUrls.ExportData.concat(
      RouteId.toString()
    ).concat(ExportDataUrls.GetExportHotelMail);
    try {
      const response = await axios.get<IGetExportHotelMail>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetExportFlight(RouteId: string | number) {
    const endpoint = ExportDataUrls.ExportData.concat(
      RouteId.toString()
    ).concat(ExportDataUrls.GetExportFlight);
    try {
      const response = await axios.get<IGetExportFlight[]>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
}
