import { FC } from "react";
import FilesTable from "./components/FilesTable";
import ManagementFilesTableDataProvider from "./context";

const ManagementFilesTable: FC = () => {
  return (
    <ManagementFilesTableDataProvider>
      <FilesTable />
    </ManagementFilesTableDataProvider>
  );
};

export default ManagementFilesTable;
