import { FC, useState } from "react";
import { Button, Col, Divider, Form, Row } from "antd";
import FormItem from "antd/es/form/FormItem";
import SubmitButton from "src/components/UiKit/SubmitButton";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";
import { useManagementTourBack } from "src/hook/useManagementTourBack";
import { useFormHasChanged } from "../useFormHasChanged";
import { useManagementCompanionLeaderData } from "../context";

const AddEditCompanionLeader: FC = () => {
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const { words } = useLanguage();

  const {
    states: { loading, mode, companionLeaderForm, deleteLoading },
    func: { onFinish },
    request: { onDeleteCompanionLeader },
  } = useManagementCompanionLeaderData();

  const canSubmit = useFormHasChanged();
  const { backHandler } = useManagementTourBack();

  const handleCancel = () => {
    if (mode === "add") {
      companionLeaderForm.resetFields();
    } else backHandler();
  };

  return (
    <>
      <p className="title">
        {mode === "add"
          ? words.managementCompanionLeader.addFormTitle
          : words.managementCompanionLeader.editFormTitle}
      </p>
      <Form
        onFinish={onFinish}
        form={companionLeaderForm}
        layout="vertical"
        preserve={false}
        className="mt-[40px]"
      >
        <Row gutter={[24, 12]}>
          <Col xs={24} md={12}>
            <FormItem
              name={"fullName"}
              label={words.managementCompanionLeader.nameLabel}
              required
            >
              <InputUiKit
                placeholder={words.managementCompanionLeader.namePlaceHolder}
              />
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem
              name={"phoneNumber"}
              label={words.managementCompanionLeader.phoneLabel}
            >
              <InputUiKit
                placeholder={words.managementCompanionLeader.phonePlaceHolder}
              />
            </FormItem>
          </Col>

          <Divider className="bg-[#EEEEEE]" />
          <Col span={24} className="flex justify-between">
            <div>
              {mode === "edit" ? (
                <Button
                  type="default"
                  className="delete-btn"
                  onClick={() => setShowDelete(true)}
                >
                  {words.global.delete}
                </Button>
              ) : null}
            </div>
            <div className="flex justify-end">
              <Button
                type="text"
                className="bg-[#F5F5F5] text-[#8E8E8E] ml-2 rounded-lg w-[85px]"
                onClick={handleCancel}
              >
                {mode === "add"
                  ? words.managementCompanionLeader.clear
                  : words.managementCompanionLeader.cancel}
              </Button>
              <SubmitButton
                form={companionLeaderForm}
                loading={loading}
                canSubmit={canSubmit}
              >
                <span className="text-[#fff]">
                  {mode === "add"
                    ? words.global.add
                    : words.managementCompanionLeader.editBtn}
                </span>
              </SubmitButton>
            </div>
          </Col>
        </Row>
      </Form>

      <DeleteConfirmModal
        showConfirm={showDelete}
        onCancel={() => setShowDelete(false)}
        onOk={() =>
          onDeleteCompanionLeader().finally(() => setShowDelete(false))
        }
        title={words.managementCompanionLeader.deleteTitle}
        okTitle={words.global.delete}
        loading={deleteLoading}
      />
    </>
  );
};

export default AddEditCompanionLeader;
