import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { Form, FormInstance } from "antd";
import { ITourTableParams } from "src/services/Tour/models";
import { IHotel } from "src/services/BasicInformation/models";
import { HotelsTabMenuKeys, HotelsViewMode } from "../models/enums";

interface IContextValue {
  states: {
    currentFilter: string;
    viewMode: HotelsViewMode;
    selectedHotel: IHotel | undefined;
    hotelInfoChanged: boolean;
    addHotelForm: FormInstance;
  };
  dispatches: {
    setCurrentFilter: Dispatch<SetStateAction<string>>;
    setViewMode: Dispatch<SetStateAction<HotelsViewMode>>;
    setSelectedHotel: Dispatch<SetStateAction<IHotel | undefined>>;
    setHotelInfoChanged: Dispatch<SetStateAction<boolean>>;
  };
  func: {
    handleBack: () => void;
  };
  requests: {};
}

export const HotelsDataContext = createContext<IContextValue | undefined>(
  undefined
);

const HotelsDataProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const [currentFilter, setCurrentFilter] = useState<string>(
    HotelsTabMenuKeys.Add
  );

  const [tourTableParams, setTourTableParams] = useState<ITourTableParams>({
    Offset: 1,
    Limit: 10,
  });
  const [viewMode, setViewMode] = useState<HotelsViewMode>(HotelsViewMode.Menu);

  const [selectedHotel, setSelectedHotel] = useState<IHotel | undefined>(
    undefined
  );
  const [hotelInfoChanged, setHotelInfoChanged] = useState<boolean>(false);

  const [addHotelForm] = Form.useForm();

  const handleBack = () => {
    setSelectedHotel(undefined);
    setViewMode(HotelsViewMode.Menu);
    setCurrentFilter(HotelsTabMenuKeys.Table);
    addHotelForm.resetFields();
  };

  const contextValues = {
    states: {
      tourTableParams,
      currentFilter,
      viewMode,
      selectedHotel,
      hotelInfoChanged,
      addHotelForm,
    },
    dispatches: {
      setTourTableParams,
      setCurrentFilter,
      setViewMode,
      setSelectedHotel,
      setHotelInfoChanged,
    },
    func: { handleBack },
    requests: {},
  };

  return (
    <HotelsDataContext.Provider value={contextValues}>
      {children}
    </HotelsDataContext.Provider>
  );
};

export default HotelsDataProvider;

export const useHotelsData = () => useContext(HotelsDataContext)!;
