import { FC } from "react";
import Table from "antd/es/table";
import { Container } from "./styles";
import { ITable } from "./model";

export const TableUI: FC<ITable> = (props) => {
  const { tableType = "primary" } = props;

  return (
    <Container $type={tableType}>
      <Table {...props} />
    </Container>
  );
};
