import { FC } from "react";
import { Content, NotificationContainer } from "./style";
import { AddNotification } from "./components/addNotification";
import { MiddleHeader } from "./components/middleHeader";
import { Col, Row } from "antd";
import { AddTableNotifications } from "./components/addTable";

export const Notification: FC = () => {
  return (
    <NotificationContainer>
      <MiddleHeader />
      <Row justify={"center"}>
        <Col span={16}>
          <Content>
            <AddNotification />
            <AddTableNotifications />
          </Content>
        </Col>
      </Row>
    </NotificationContainer>
  );
};
