import { Col } from "antd";
import styled from "styled-components";

export const TeamInfoContainer = styled(Col)`
  .team-form-row {
    position: relative;
    margin-top: 20px;
  }
  .ant-tabs-nav-wrap {
    border-bottom: 1px solid #ffffff;
  }
  .ant-tabs-card
    > .ant-tabs-nav
    .ant-tabs-nav-wrap
    .ant-tabs-nav-list
    .ant-tabs-tab {
    border: 1px solid #cacaca;
    background-color: transparent !important;
    margin-left: 10px !important;
    border-radius: 8px;
    padding: 12px 16px;
    font-weight: 700;
    font-size: 14px;
    color: #cacaca;
  }
  .ant-tabs-card
    > .ant-tabs-nav
    .ant-tabs-nav-wrap
    .ant-tabs-nav-list
    .ant-tabs-tab-active {
    border: 1px solid #273574 !important;
  }
  .ant-tabs-card
    > .ant-tabs-nav
    .ant-tabs-nav-wrap
    .ant-tabs-nav-list
    .ant-tabs-tab-active
    .ant-tabs-tab-btn {
    color: #273574 !important;
  }
  .actions {
    width: 100%;
    border-top: 1px solid #dadeec;
    padding-top: 20px;
    margin-top: 30px;
  }
`;
