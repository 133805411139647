import { Flex } from "antd";
import { FC } from "react";
import { IContact } from "src/services/BasicInformation/models";
import useLanguage from "src/store/language";
import { useAddHotelResponderData } from "./context";

interface IContactTableActionsProps {
  item: IContact;
}

const TableActions: FC<IContactTableActionsProps> = ({ item }) => {
  const {
    func: { handleDeleteId },
    dispatches: { setSelectedContact },
  } = useAddHotelResponderData();

  const { words } = useLanguage();

  return (
    <>
      <Flex justify="center" className="gap-[16px]">
        <div className="edit-action" onClick={() => setSelectedContact(item)}>
          <span className="material-icons text-[14px]">edit_note</span>
          <span>{words.global.editAction}</span>
        </div>
        <div className="delete-action" onClick={() => handleDeleteId(item.id)}>
          <span className="material-icons text-[14px]">delete_forever</span>
          <span>{words.global.delete}</span>
        </div>
      </Flex>
    </>
  );
};

export default TableActions;
