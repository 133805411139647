import { Form, message } from "antd";
import { FormInstance } from "antd/lib";
import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { convertDatePicker, convertDateTimeIran } from "src/helper";
import { IObject } from "src/models/interfaces";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import { ILeader, INewLeaderArg } from "src/services/TourManagement/models";
import useLanguage from "src/store/language";

interface IContextValue {
  states: {
    mode: string;
    loading: boolean;
    leaderProps: ILeaderProps;
    leaderForm: FormInstance;
    leaderloadings: ILeaderLoadingsProps;
    deleteLoading: boolean;
  };
  func: { onFinish: (values: INewLeaderArg) => void };
  dispatches: { setLeaderProps: Dispatch<SetStateAction<ILeaderProps>> };
  requests: { onDeleteLeader: () => Promise<void> };
}

interface ILeaderProps {
  leader: ILeader | undefined;
  nationalCard: string | undefined;
  leaderCard: string | undefined;
}

interface ILeaderLoadingsProps {
  leader: boolean;
  nationalCard: boolean;
  leaderCard: boolean;
}

export const ManagementLeaderDataContext = createContext<
  IContextValue | undefined
>(undefined);

const ManagementLeaderDataProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [mode, setMode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [leaderloadings, setLeaderLoadings] = useState<ILeaderLoadingsProps>({
    leader: false,
    nationalCard: false,
    leaderCard: false,
  });
  const [leaderProps, setLeaderProps] = useState<ILeaderProps>({
    leader: undefined,
    nationalCard: undefined,
    leaderCard: undefined,
  });
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const [leaderForm] = Form.useForm();

  const { tourId } = useParams();
  const { state } = useLocation();
  const { words } = useLanguage();

  const navigate = useNavigate();

  const onFinish = (values: IObject) => {
    if (values.BirthDate)
      values.BirthDate = convertDateTimeIran(values.BirthDate);
    if (values.ExpireDate)
      values.ExpireDate = convertDateTimeIran(values.ExpireDate);
    var formData = new FormData();
    for (var key in values) {
      formData.append(key, values[key]);
    }

    if (mode === "add") leaderAddReq(formData);
    else if (mode === "edit") leaderEditReq(formData);
  };

  const leaderAddReq = async (values: FormData) => {
    if (!tourId) return;
    setLoading(true);
    try {
      const { AddLeader } = new TourManagementService();
      const result = await AddLeader(tourId, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage).then(() => navigate(-1));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const leaderEditReq = async (values: FormData) => {
    if (!leaderProps.leader) return;

    setLoading(true);
    try {
      const { EditLeader } = new TourManagementService();
      const result = await EditLeader(leaderProps.leader.id, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage).then(() => navigate(-1));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onDeleteLeader = async () => {
    if (!leaderProps.leader) return;
    setDeleteLoading(true);
    try {
      const { DeleteLeader } = new TourManagementService();
      const result = await DeleteLeader(leaderProps.leader.id);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setMode("add");
        setLeaderProps({
          leader: undefined,
          nationalCard: undefined,
          leaderCard: undefined,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
    }
  };

  const getNationalCard = useCallback(async () => {
    if (!tourId) return;
    setLeaderLoadings((prev) => ({ ...prev, nationalCard: true }));
    try {
      const { GetLeaderNationalCardImage } = new TourManagementService();
      const res = await GetLeaderNationalCardImage(tourId);
      if (res) {
        setLeaderProps((prev) => ({
          ...prev,
          nationalCard: res,
        }));
      } else {
        setLeaderProps((prev) => ({
          ...prev,
          nationalCard: undefined,
        }));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLeaderLoadings((prev) => ({ ...prev, nationalCard: false }));
    }
  }, [tourId]);

  const getLeaderCard = useCallback(async () => {
    if (!tourId) return;
    setLeaderLoadings((prev) => ({ ...prev, leaderCard: true }));
    try {
      const { GetLeaderCardImage } = new TourManagementService();
      const res = await GetLeaderCardImage(tourId);

      if (res) {
        setLeaderProps((prev) => ({
          ...prev,
          leaderCard: res,
        }));
      } else {
        setLeaderProps((prev) => ({ ...prev, leaderCard: undefined }));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLeaderLoadings((prev) => ({ ...prev, leaderCard: false }));
    }
  }, [tourId]);

  const getLeader = useCallback(async () => {
    if (!tourId) return;
    setLeaderLoadings((prev) => ({ ...prev, leader: true }));
    try {
      const { GetLeader } = new TourManagementService();
      const res = await GetLeader(tourId);
      if (res && res.status === 200 && res.data) {
        setLeaderProps((prev) => ({ ...prev, leader: res.data }));
      } else {
        setLeaderProps((prev) => ({ ...prev, leader: undefined }));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLeaderLoadings((prev) => ({ ...prev, leader: false }));
    }
  }, [tourId]);

  useEffect(() => {
    if (tourId && (mode === "edit" || mode === "view")) {
      getLeader();
      getLeaderCard();
      getNationalCard();
    }
  }, [getLeader, getLeaderCard, getNationalCard, mode, tourId]);

  useEffect(() => {
    if (state && state.mode) setMode(state.mode);
    else setMode("add");
  }, [state]);

  useEffect(() => {
    if (leaderProps.leader) {
      leaderForm.setFieldsValue({
        FirstName: leaderProps.leader.firstName,
        PhoneNumber: leaderProps.leader.phoneNumber,
        LastName: leaderProps.leader.lastName,
        FatherName: leaderProps.leader.fatherName,
        Gender: leaderProps.leader.gender,
        NationalCode: leaderProps.leader.nationalCode,
        IdentityNumber: leaderProps.leader.identityNumber,
        Language: leaderProps.leader.language,
        LeaderCardId: leaderProps.leader.leaderCardId,
        BirthDate: leaderProps.leader.birthDate
          ? convertDatePicker(leaderProps.leader.birthDate)
          : null,
        ExpireDate: leaderProps.leader.expireDate
          ? convertDatePicker(leaderProps.leader.expireDate)
          : null,
      });
    } else leaderForm.resetFields();
  }, [leaderForm, leaderProps.leader]);

  const contextValues = {
    states: {
      mode,
      loading,
      leaderProps,
      leaderForm,
      leaderloadings,
      deleteLoading,
    },
    func: { onFinish },
    dispatches: { setLeaderProps },
    requests: { onDeleteLeader },
  };

  return (
    <ManagementLeaderDataContext.Provider value={contextValues}>
      {children}
    </ManagementLeaderDataContext.Provider>
  );
};

export default ManagementLeaderDataProvider;

export const useManagementLeaderData = () =>
  useContext(ManagementLeaderDataContext)!;
