import { FC } from "react";
import { Col } from "antd";
import DataEntryHeader from "src/components/dataEntryHeader";
import PlacesDataProvider from "./context/PlacesData";
import { PlacesContainer } from "./style";
import AddPlace from "./components/AddForm";
import PlaceTable from "./components/Table";

const Places: FC = () => {
  return (
    <PlacesDataProvider>
      <>
        <DataEntryHeader title="شما میتوانید لیست مکان های دیدنی را ببنید و آنها را ویرایش کنید و یا مکان دیدنی جدیدی اضافه کنید." />
        <PlacesContainer justify={"center"} gutter={[0, 24]}>
          <Col xs={24} md={18} lg={16} className="content">
            <AddPlace />
            <PlaceTable />
          </Col>
        </PlacesContainer>
      </>
    </PlacesDataProvider>
  );
};

export default Places;
