import { FC } from "react";
import { Col, Flex, Spin } from "antd";
import useLanguage from "src/store/language";
import { ManagementTourDetailsContainer } from "../style";
import { TourStates } from "src/models/enums/TourStates";
import { useManagementTour } from "../context";
import { convertDate } from "src/helper";
import { ROUTES } from "src/models/enums/routes";
import { Link } from "react-router-dom";

const ManagementTourDetails: FC = () => {
  const { words } = useLanguage();

  const {
    value: { tourManagement, mainLink, dataLoading },
  } = useManagementTour();

  const renderTourState = () => {
    switch (tourManagement?.tourState) {
      case TourStates.Waiting:
        return (
          <Flex>
            <img
              src="/assets/images/tour-waiting.svg"
              alt="tour-progress"
              className="w-[14px]"
            />
            <span className="px-[5px] text-[14px] text-[#FE9B0E] font-bold">
              {words.tableTour.waitingToStart}
            </span>
          </Flex>
        );
      case TourStates.Progress:
        return (
          <Flex>
            <img
              src="/assets/images/tour-progress.svg"
              alt="tour-progress"
              className="w-[14px]"
            />
            <span className="px-[5px] text-[14px] text-[#3B82F6] font-bold">
              {words.tableTour.progress}
            </span>
          </Flex>
        );
      case TourStates.Finished:
        return (
          <Flex>
            <img
              src="/assets/images/tour-finished.svg"
              alt="tour-progress"
              className="w-[14px]"
            />
            <span className="px-[5px] text-[14px] text-[#47B881] font-bold">
              {words.tableTour.finished}
            </span>
          </Flex>
        );
      case TourStates.Inactive:
        return (
          <Flex>
            <img
              src="/assets/images/tour-cancel.svg"
              alt="tour-progress"
              className="w-[14px]"
            />
            <span className="px-[5px] text-[14px] text-[#F64C4C] font-bold">
              {words.tableTour.inactive}
            </span>
          </Flex>
        );
    }
  };

  return (
    <ManagementTourDetailsContainer align={"middle"} justify={"space-between"}>
      <Col xs={24} sm={11} md={6} className="state-col">
        <Flex>
          <div>
            <img
              src="/assets/images/management-state.png"
              alt="state"
              className="w-[24px] h-[24px]"
            />
          </div>
          <Flex vertical className="pr-[16px] w-full">
            <div className="box-title">{words.managementTour.tourState}</div>
            {dataLoading ? (
              <div className="w-full flex items-center justify-center">
                <Spin size="small" className="text-[#4B4B4B]" />
              </div>
            ) : (
              <>
                <div className="py-[6px]">{renderTourState()}</div>
                <Flex align="center" justify="space-between" className="w-full">
                  <Flex align="center">
                    <span className="material-icons text-[#8e8e8e] text-[14px]">
                      edit_calendar
                    </span>
                    <span className="date">
                      {tourManagement && tourManagement.createDate
                        ? convertDate(tourManagement.createDate)
                        : words.managementTour.noRegister}
                    </span>
                  </Flex>
                  <Link
                    to={mainLink.concat(ROUTES.tourManagementTime)}
                    state={{
                      mode: tourManagement?.flags.includes("TourTime")
                        ? "edit"
                        : "add",
                    }}
                    className="arrow"
                  >
                    <span className="material-symbols-outlined text-[16px] text-[#4B4B4B]">
                      keyboard_return
                    </span>
                  </Link>
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </Col>

      <Col xs={24} sm={11} md={6} className="notification-col">
        <Flex>
          <div>
            <img
              src="/assets/images/notifications-active.svg"
              alt="state"
              className="w-[24px] h-[24px]"
            />
          </div>
          <Flex vertical className="pr-[16px] w-full">
            <div className="box-title">{words.managementTour.tourReport}</div>
            <div className="py-[6px] notification">
              {words.managementTour.tourReportDescription}
            </div>
            <Flex align="center" justify="end" className="w-full">
              {dataLoading ? (
                <div className="arrow">
                  <Spin size="small" />
                </div>
              ) : (
                <Link
                  to={mainLink.concat(ROUTES.managementTourReport)}
                  className="arrow"
                >
                  <span className="material-symbols-outlined text-[16px] text-[#4B4B4B]">
                    keyboard_return
                  </span>
                </Link>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Col>

      <Col xs={24} md={11} className="date-col">
        <Flex
          align="center"
          justify="space-between"
          className="px-[12px] h-full"
        >
          <Flex>
            <Flex align="center" justify="space-between">
              <div>
                <img src="/assets/images/flight-land.svg" alt="state" />
              </div>
              <Flex vertical className="px-[12px]">
                <div className="start-end">
                  {words.managementTour.tourStart}
                </div>
                <div>
                  {dataLoading ? (
                    <Spin size="small" />
                  ) : tourManagement && tourManagement?.startDate ? (
                    <span className="start-end-date">
                      {convertDate(tourManagement?.startDate)}
                    </span>
                  ) : (
                    <span className="not-registered">
                      {words.managementTour.noRegister}
                    </span>
                  )}
                </div>
              </Flex>
            </Flex>
          </Flex>
          <Flex vertical align="center" justify="center">
            <div>
              <img src="/assets/images/airport-shuttle.svg" alt="shuttle" />
            </div>
            <Flex align="center" justify="center">
              <Flex align="center">
                {dataLoading ? (
                  <Spin size="small" />
                ) : tourManagement?.tourDays ? (
                  <span className="day">{tourManagement?.tourDays}</span>
                ) : (
                  <span className="no-amount">- - -</span>
                )}

                <span className="day px-[3px]">{words.managementTour.day}</span>
              </Flex>
              <Flex align="center">
                {dataLoading ? (
                  <Spin size="small" />
                ) : tourManagement?.touristCount ? (
                  <span className="tourist px-[3px]">
                    {tourManagement?.touristCount}
                  </span>
                ) : (
                  <span className="no-amount px-[3px]">- - -</span>
                )}
                <span className="tourist">{words.managementTour.tourist}</span>
              </Flex>
            </Flex>
          </Flex>
          <Flex align="center" justify="space-between">
            <Flex vertical className="px-[12px]">
              <div className="start-end">{words.managementTour.tourEnd}</div>
              <div>
                {dataLoading ? (
                  <Spin size="small" />
                ) : tourManagement && tourManagement?.endDate ? (
                  <span className="start-end-date">
                    {convertDate(tourManagement?.endDate)}
                  </span>
                ) : (
                  <span className="not-registered">
                    {words.managementTour.noRegister}
                  </span>
                )}
              </div>
            </Flex>
            <div>
              <img src="/assets/images/flight-takeof.svg" alt="takeof" />
            </div>
          </Flex>
        </Flex>
      </Col>
    </ManagementTourDetailsContainer>
  );
};

export default ManagementTourDetails;
