import { Row } from "antd";
import styled from "styled-components";

export const AssignRoleToUserContainer = styled(Row)`
  .header {
    width: 100%;
    padding: 16px 32px;
    background-color: #fffaf8;
    height: 92px;
    border-radius: 16px;
  }
  .content {
    border-radius: 20px;
    background: var(--0, #fff);

    /* 2 */
    box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
    padding: 24px 32px;
  }
  .title {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
  }
  .footer {
    border-top: 1px solid #dadeec;
    padding-top: 32px;
    padding-bottom: 16px;
  }
  .ant-btn {
    border: 1px solid #e1e1e1;
    border-radius: 12px;
    margin: 0 5px;
  }
  .ant-btn.active {
    border: 1px solid #f6a372;
    background-color: #f6a372;
    display: inline-flex;
    align-items: center;
  }
  .ant-btn.active:hover {
    border: 1px solid #f6a372;
    background-color: #f6a372;
  }

  .ant-btn span {
    font-size: 12px;
    font-weight: 700;
  }
  .ant-btn.active .btn-title {
    color: #ffffff;
    margin-top: 3px;
  }
  .ant-btn.active img {
    padding: 0 5px;
  }
`;
