import { FC, useCallback, useEffect, useRef, useState } from "react";
import { PageFooter } from "./style";
import { Col, Row } from "antd";
import { IGetExportRoute } from "src/services/ExportData/models";

import { useParams } from "react-router-dom";
import { ExportDataService } from "src/services/ExportData/ExportData.service";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { routeProgramColumns } from "./columns";
import { generateRoutesExcel } from "./createRouteExcel";
import { RouteProgramData } from "./RouteProgramData";
import html2pdf from "html2pdf.js";
import { DownloadPdfIcon } from "../DownladPdfIcon";

export const RoutesProgram: FC = () => {
  const [data, setData] = useState<IGetExportRoute>();
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  const { tourId } = useParams();
  const componentRef = useRef<HTMLDivElement>(null);

  const print = useReactToPrint({
    content: () => componentRef.current,
    print: async (printIframe: HTMLIFrameElement) => {
      try {
        setDownloadLoading(true);
        const document = printIframe.contentDocument;

        if (document) {
          const html = document.getElementsByTagName("html")[0];
          const htmlRect = html.getBoundingClientRect();
          const pdfWith = 250;
          const pdfCurrentHeight = (pdfWith * htmlRect.height) / htmlRect.width;
          const pageCount = htmlRect.height / pdfWith;
          const roundedPageCount =
            Math.round(pageCount) < pageCount
              ? Math.ceil(pageCount)
              : Math.round(pageCount);
          const pdfHeight = pdfCurrentHeight * roundedPageCount;
          const opt = {
            jsPDF: {
              format: [pdfWith, pdfHeight],
            },
            image: { type: "jpeg", quality: 1 },
            html2canvas: { scale: 3 },
            unit: "px",
          };
          await html2pdf().from(html).set(opt).save("RoutesProgram.pdf");
        }
      } catch (err) {
        console.log(err);
      } finally {
        setDownloadLoading(false);
      }
    },
  });
  const handlePrint = async () => {
    setDownloadLoading(true);
    print();
  };
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);

  const fetchData = useCallback(async () => {
    if (!tourId) return;
    try {
      setLoading(true);
      const { GetExportRoute } = new ExportDataService();
      const res = await GetExportRoute(tourId);
      if (res && res.status === 200 && res.data) {
        setData(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourId]);

  const downloadExcel = () => {
    if (data) {
      generateRoutesExcel({
        data,
        columns: routeProgramColumns,
      });
    }
  };
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Row justify={"center"}>
      <ReactToPrint content={reactToPrintContent} removeAfterPrint />
      <Col span={22}>
        <RouteProgramData data={data} loading={loading} />
        {/* for pdf  */}
        <div className="hidden">
          <RouteProgramData
            data={data}
            loading={loading}
            componentRef={componentRef}
            isPdf
          />
        </div>
      </Col>
      <Col span={24} className="pb-[32px]">
        <PageFooter className="pageFooter" justify="flex-end">
          <DownloadPdfIcon
            handlePrint={handlePrint}
            loading={downloadLoading}
          />
          <img
            src="/assets/images/excelDownload.svg"
            alt=""
            onClick={downloadExcel}
          />
        </PageFooter>
      </Col>
    </Row>
  );
};
