import styled from "styled-components";

export const ModalContentContainer = styled.div`
  margin-top: 30px;
  .description {
    padding: 48px;
    font-weight: 700;
    font-size: 18px;
    color: #000000;
  }
  .footer {
    width: 100%;
    border-top: 1px solid #dadeec;
    padding-top: 15px;
    margin-top: 20px;
  }
  .ant-btn.save-btn,
  .ant-btn.cancel,
  .ant-btn.delete-btn,
  .ant-btn.add-btn {
    font-weight: 700;
    font-size: 12px;
    border-radius: 12px;
  }
  .save-btn {
    background-color: #f6a372;
    color: #ffffff;
  }
  .save-btn:hover {
    background-color: #f6a372 !important;
    color: #ffffff !important;
  }
  .cancel {
    color: #8e8e8e;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    margin: 0 10px;
  }
  .cancel:hover {
    color: #8e8e8e !important;
    background-color: #ffffff !important;
    border: 1px solid #e1e1e1;
  }
  .material-icons {
    font-size: 12px;
  }

  .ant-table-wrapper .ant-table-cell,
  .ant-table-wrapper .ant-table-thead > tr > th {
    text-align: center;
  }
  .delete-btn {
    background-color: #f64c4c;
    color: #ffffff;
  }
  .delete-btn:hover {
    background-color: #f64c4c !important;
    color: #ffffff !important;
  }
  .add-btn {
    background-color: #f5f5f5;
    color: #8e8e8e;
    margin: 0 10px;
  }
  .add-btn:hover {
    background-color: #f5f5f5;
    color: #8e8e8e;
  }
`;

export const ModalHeaderContainer = styled.div`
  /* text-align: center; */
`;

export const ActionsContainer = styled.div`
  .ant-btn {
    padding: 0;
  }
  .ant-btn:hover {
    padding: 0;
    background-color: transparent !important;
  }
  .delete {
    color: #ff1414;
  }
  .delete:hover {
    color: #ff1414 !important;
  }
  .edit {
    color: #5d6881;
    padding: 0 10px;
  }
  .edit:hover {
    padding: 0 10px;
  }
  .material-icons {
    font-size: 16px;
  }
`;

export const AddTouristModalBodyContainer = styled.div`
  .btn-cancel {
    color: #8e8e8e;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    margin: 0 10px;
  }

  .btn-ok {
    background-color: #f6a372 !important;
    color: #ffffff !important;
  }
  .description {
    margin-top: 20px;
    text-align: center;
    border: 1px solid #dadeec;
    border-radius: 12px;
    font-weight: 700;
    font-size: 16px;
    padding: 48px;
  }
  .material-icons {
    font-size: 12px;
  }
  .add-form {
    min-width: 600px;
    overflow-x: auto !important;
  }
  .ant-flex .title {
    background-color: #525d90;
    color: #ffffff;
  }
`;
