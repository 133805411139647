import { FC } from "react";
import { Col } from "antd";
import PageHeader from "src/components/pageHeader";
import useLanguage from "src/store/language";
import { ManagementSupervisorContainer } from "./style";
import { useManagementSupervisorData } from "./context";
import ViewSupervisor from "./components/ViewSupervisor";
import AddEditSupervisor from "./components/AddEditSupervisor";

const ManagementSupervisor: FC = () => {
  const { words } = useLanguage();

  const {
    states: { mode },
  } = useManagementSupervisorData();

  return (
    <>
      <PageHeader
        mode={mode}
        title={words.managementSupervisor.supervisorTitle}
        backTitle={words.global.back}
        tourCode={123465}
      />
      <ManagementSupervisorContainer justify={"center"} gutter={[0, 24]}>
        <Col xs={24} md={mode === "view" ? 16 : 23} className="content">
          {mode === "view" ? <ViewSupervisor /> : <AddEditSupervisor />}
        </Col>
      </ManagementSupervisorContainer>
    </>
  );
};

export default ManagementSupervisor;
