import { FC } from "react";
import AddHotelResponderDataProvider from "./context";
import AddPersonForm from "./AddPersonForm";

const AddPerson: FC = () => {
  return (
    <AddHotelResponderDataProvider>
      <AddPersonForm />
    </AddHotelResponderDataProvider>
  );
};

export default AddPerson;
