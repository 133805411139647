import { FC, ReactElement, createContext, useContext, useState } from "react";
import { useManagementFilesData } from "../../context";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import useLanguage from "src/store/language";
import downloadZipFile from "src/helper/downloadZipFile";
import { AxiosRequestConfig } from "axios";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";
import { App } from "antd";

interface IContextValue {
  states: {
    confirmLoading: boolean;
    deleteId: number;
    progress: number;
    loading: boolean;
  };
  func: { handleDeleteId: (id: number) => void };
  dispatches: {};
  requests: {
    onDeleteAttachment: () => void;
    downloadAttachFile: (attachId: number) => void;
    addFileRequest: (id: number, formData: FormData) => Promise<boolean>;
  };
}

export const ManagementFilesTableDataContext = createContext<
  IContextValue | undefined
>(undefined);

const ManagementFilesTableDataProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [deleteId, setDeleteId] = useState<number>(0);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    states: { selectedDocument },
    requests: { getTourDocuments },
    dispatches: { setSelectedDocument },
  } = useManagementFilesData();

  const { words } = useLanguage();
  const { message } = App.useApp();

  const handleDeleteId = (id: number) => {
    setDeleteId(id);
  };

  const onDeleteAttachment = async () => {
    if (deleteId === 0) return;
    setConfirmLoading(true);
    try {
      const { DeleteTourDocument } = new TourManagementService();
      const result = await DeleteTourDocument(deleteId);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        if (deleteId === selectedDocument?.attachmentId)
          setSelectedDocument(undefined);
        setDeleteId(0);
        getTourDocuments();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmLoading(false);
    }
  };
  const downloadAttachFile = async (attachId: number) => {
    setProgress(100);
    const config: AxiosRequestConfig = {
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 0)
        );
        setProgress(percentage);
        if (percentage === 100) {
          setTimeout(() => setProgress(0), 400);
        }
      },
    };
    const { DownloadTourFile } = new TourManagementService();
    try {
      const result = await DownloadTourFile(attachId, config);
      if (result) {
        downloadZipFile(result);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProgress(0);
    }
  };
  const addFileRequest = async (
    id: number,
    formData: FormData
  ): Promise<boolean> => {
    setLoading(true);
    try {
      const { EDitManagementTourDocument } = new TourManagementService();
      const result = await EDitManagementTourDocument(id, formData);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        return true;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
    try {
    } catch (err) {
      console.log("upload excel file error", err);
    } finally {
      setLoading(false);
    }
    return false;
  };

  const contextValues = {
    states: { confirmLoading, deleteId, progress, loading },
    func: { handleDeleteId },
    dispatches: {},
    requests: {
      onDeleteAttachment,
      downloadAttachFile,
      addFileRequest,
    },
  };

  return (
    <ManagementFilesTableDataContext.Provider value={contextValues}>
      {children}
      <DeleteConfirmModal
        showConfirm={deleteId > 0}
        onCancel={() => handleDeleteId(0)}
        onOk={() => onDeleteAttachment()}
        title={words.managementFiles.deleteConfirmTitle}
        okTitle={words.global.delete}
        loading={confirmLoading}
      />
    </ManagementFilesTableDataContext.Provider>
  );
};

export default ManagementFilesTableDataProvider;

export const useManagementFilesTableData = () =>
  useContext(ManagementFilesTableDataContext)!;
