import { FC } from "react";
import { Button, Modal } from "antd";
import useLanguage from "src/store/language";
import { useDomesticFlight } from "../context";
import { DeleteModalFooterContainer } from "../style";

export const ModalDeleteConfirm: FC = () => {
  const { words } = useLanguage();

  const {
    value: { deleteItem, confirmLoading },
    func: { handleDelete },
    request: { onDeleteDomesticFlight },
  } = useDomesticFlight();

  const DeleteModalTitle = () => {
    return (
      <div className="pb-[16px] border-0 border-b-[1px] border-solid border-[#FDE8DC]">
        <p className="text-[18px] font-semibold text-[#EC2D30] mb-[2px] mt-[0px]">
          {words.managementDomesticFlight.deletingFlight}
        </p>
        <span className="text-[12px] text-[#1F1F1F] font-medium pl-[8px] border-0 border-l-[2px] border-solid border-[#FDE8DC]">
          {deleteItem?.flightCode || "-"}
        </span>
        <span className="text-[12px] text-[#8E8E8E] font-normal mr-[8px]">
          {deleteItem?.originCity.concat(
            " - ".concat(deleteItem?.destinationCity)
          )}
        </span>
      </div>
    );
  };

  const DeleteModalFooter = () => {
    return (
      <DeleteModalFooterContainer>
        <Button
          type="text"
          loading={confirmLoading}
          onClick={() => onDeleteDomesticFlight()}
          className="btn-ok"
        >
          {words.global.yes}
        </Button>
        <Button
          type="default"
          onClick={() => handleDelete()}
          className="btn-no"
        >
          {words.global.no}
        </Button>
      </DeleteModalFooterContainer>
    );
  };

  return (
    <Modal
      title={<DeleteModalTitle />}
      closeIcon={false}
      open={deleteItem ? true : false}
      closable={false}
      footer={<DeleteModalFooter />}
    >
      <div className="flex justify-center items-center p-[48px] rounded-[12px] border border-solid border-[#FDE8DC] my-[32px]">
        <p className="text-[16px] text-[#233069] font-bold">
          {words.managementDomesticFlight.deleteDescription}
        </p>
      </div>
    </Modal>
  );
};
