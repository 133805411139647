import { FC, ReactNode } from "react";
import { FormControlContainer } from "./styles";
import { Typography } from "antd";
import useLanguage from "src/store/language";

interface IProps {
  label?: string;
  children: ReactNode;
  height?: string;
  icon?: ReactNode;
  className?: string;
  childrenContainerClassName?: string;
}

const FormControlUiKit: FC<IProps> = ({
  label,
  icon,
  children,
  height,
  className,
  childrenContainerClassName,
}) => {
  const { isRtl } = useLanguage();

  return (
    <FormControlContainer className={className} height={height} $isrtl={isRtl}>
      <Typography.Text className="label">{label}</Typography.Text>

      <section className={`childrenContainer ${childrenContainerClassName}`}>
        <span className="icon">{icon}</span>
        {children}
        <fieldset>
          <legend>
            <Typography.Text>{label}</Typography.Text>
          </legend>
        </fieldset>
      </section>
    </FormControlContainer>
  );
};

export default FormControlUiKit;
