import axios, { AxiosRequestConfig } from "axios";
import { TourStates } from "src/models/enums/TourStates";
import { IDataModel } from "../models";
import {
  IAddTourArg,
  IAttachFile,
  ICarInfoArg,
  IDriverInfoArg,
  IFileCheckerResult,
  IInsuranceInfoArg,
  IInsuranceInfoResult,
  ILeaderInfoArg,
  IManagerInfoArg,
  IPlanTourBatchArgs,
  ITourDateArg,
  ITourInfo,
  ITourInfoResult,
  ITourist,
  ITouristInfoArg,
  ITouristResult,
  ITours,
  ITranslatorInfoArg,
} from "./models";
import { TourUrls } from "./urls";
import { Buffer } from "buffer";
export class TourService {
  async Tours(queries: string) {
    let endpoint: string = TourUrls.Tours;
    if (queries) endpoint = endpoint.concat(queries);

    try {
      const response = await axios.get<IDataModel<ITours[]>>(endpoint);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async TouristInfo(id: number | string, data: ITouristInfoArg) {
    try {
      const response = await axios.put<void>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.TouristInfo),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetTouristInfo(id: number | string) {
    try {
      const response = await axios.get<ITouristResult[]>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.TouristInfo)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteTouristInfo(data: number[]) {
    try {
      const response = await axios.delete<void>(TourUrls.DeleteTouristInfo, {
        data,
      });
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async ManagerInfo(id: number, data: IManagerInfoArg) {
    try {
      const response = await axios.put<void>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.ManagerInfo),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetManagerInfo(id: number | string) {
    try {
      const response = await axios.get<IManagerInfoArg>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.ManagerInfo)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async LeaderInfo(id: number, data: ILeaderInfoArg) {
    try {
      const response = await axios.put<void>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.LeaderInfo),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetLeaderInfo(id: number | string) {
    try {
      const response = await axios.get<ILeaderInfoArg>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.LeaderInfo)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async TranslatorInfo(id: number | string, data: ITranslatorInfoArg) {
    try {
      const response = await axios.put<void>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.TranslatorInfo),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetTranslatorInfo(id: number | string) {
    try {
      const response = await axios.get<ITranslatorInfoArg>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.TranslatorInfo)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DriverInfo(id: number, data: IDriverInfoArg) {
    try {
      const response = await axios.put<void>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.DriverInfo),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetDriverInfo(id: number | string) {
    try {
      const response = await axios.get<IDriverInfoArg>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.DriverInfo)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async CarInfo(id: number, data: ICarInfoArg) {
    try {
      const response = await axios.put<void>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.CarInfo),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetCarInfo(id: number | string) {
    try {
      const response = await axios.get<ICarInfoArg>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.CarInfo)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AddTourist(id: number | string, data: ITourist) {
    try {
      const response = await axios.post<void>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.AddTourist),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetExcelFile(id: number | string, config?: AxiosRequestConfig) {
    const newConfig: AxiosRequestConfig<any> = {
      responseType: "arraybuffer",
      headers: {
        accept: "*/*",
      },
      ...config,
    };
    try {
      const response = await axios.get<Buffer>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.GetExcelFile),
        newConfig
      );
      return { buffer: response.data, type: response.headers["content-type"] };
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetPdfFile(id: number | string, config?: AxiosRequestConfig) {
    const newConfig: AxiosRequestConfig<any> = {
      responseType: "arraybuffer",
      headers: {
        accept: "*/*",
      },
      ...config,
    };
    try {
      const response = await axios.get<Buffer>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.GetPdfFile),
        newConfig
      );
      return { buffer: response.data, type: response.headers["content-type"] };
    } catch (err) {
      throw console.log(err);
    }
  }
  async GetZipFile(id: number | string, config?: AxiosRequestConfig) {
    const newConfig: AxiosRequestConfig<any> = {
      responseType: "arraybuffer",
      headers: {
        accept: "*/*",
      },
      ...config,
    };
    try {
      const response = await axios.get<Buffer>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.GetZipFile),
        newConfig
      );
      return { buffer: response.data, type: response.headers["content-type"] };
    } catch (err) {
      throw console.log(err);
    }
  }

  async TourFileChecker(id: number | string) {
    try {
      const response = await axios.get<IFileCheckerResult>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.TourFileChecker)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditTouristInfo(id: number, data: ITourist) {
    try {
      const response = await axios.put<void>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.EditTouristInfo),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteTour(id: number | string) {
    try {
      const response = await axios.delete<void>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.DeleteTour)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetInsuranceInfo(id: number | string) {
    try {
      const response = await axios.get<IInsuranceInfoResult>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.GetInsuranceInfo)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetInsuranceFile(id: number | string) {
    try {
      const response = await axios.get<IInsuranceInfoResult>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.GetInsuranceFile)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DownloadInsuranceFile(
    id: number | string,
    config?: AxiosRequestConfig
  ) {
    const newConfig: AxiosRequestConfig<any> = {
      responseType: "arraybuffer",
      headers: {
        accept: "*/*",
      },
      ...config,
    };
    try {
      const response = await axios.get<Buffer>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.GetInsuranceFile),
        newConfig
      );
      return { buffer: response.data, type: response.headers["content-type"] };
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetAttachFile(id: number | string, queries: string) {
    try {
      const response = await axios.get<IDataModel<IAttachFile[]>>(
        TourUrls.Tour.concat(id.toString())
          .concat(TourUrls.GetAttachFile)
          .concat(queries)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async DeleteAttachFile(id: number | string) {
    try {
      const response = await axios.delete<void>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.DeleteAttachFile)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async PlanTourBatch(id: number | string, data: IPlanTourBatchArgs) {
    try {
      const response = await axios.post<void>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.PlanTourBatch),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async PlanTour(id: number | string, data: ITourInfo) {
    try {
      const response = await axios.post<void>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.PlanTour),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async GetTourInfo(id: number | string) {
    try {
      const response = await axios.get<ITourInfoResult[]>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.TourPlanInfo)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DeleteTourInfo(data: number[]) {
    try {
      const response = await axios.delete<void>(TourUrls.DeletePlanTour, {
        data,
      });
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditPlanTour(id: number, data: ITourInfo) {
    try {
      const response = await axios.put<void>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.EditPlanTour),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async DownloadAttachFile(id: number | string, config?: AxiosRequestConfig) {
    const newConfig: AxiosRequestConfig<any> = {
      responseType: "arraybuffer",
      headers: {
        accept: "*/*",
      },
      ...config,
    };
    try {
      const response = await axios.get<Buffer>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.DownloadAttachFile),
        newConfig
      );
      return { buffer: response.data, type: response.headers["content-type"] };
    } catch (err) {
      throw console.log(err);
    }
  }

  async TourInfo(id: number | string) {
    try {
      const response = await axios.get<ITours>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.TourInfo)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async TourDate(id: number | string, data: ITourDateArg) {
    try {
      const response = await axios.put<void>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.TourDate),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async ChangeTourState(id: number | string, state: TourStates) {
    try {
      const response = await axios.put<void>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.ChangeTourState),
        { state }
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async InsuranceInfo(id: number | string, data: IInsuranceInfoArg) {
    try {
      const response = await axios.put<void>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.InsuranceInfo),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async AddTour(data: IAddTourArg) {
    try {
      const response = await axios.post<void>(TourUrls.AddTour, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }

  async EditTour(id: number, data: IAddTourArg) {
    try {
      const response = await axios.put<void>(
        TourUrls.Tour.concat(id.toString()).concat(TourUrls.EditTour),
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
}
