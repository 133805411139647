import { Form, message } from "antd";
import { FormInstance } from "antd/lib";
import {
  FC,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { convertDatePicker, convertDateTimeIran } from "src/helper";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import {
  ITranslatorArg,
  ITranslator,
} from "src/services/TourManagement/models";
import useLanguage from "src/store/language";

interface IContextValue {
  states: {
    mode: string;
    dataLoading: boolean;
    loading: boolean;
    translator: ITranslator | undefined;
    translatorForm: FormInstance;
    deleteLoading: boolean;
  };
  func: { onFinish: (values: ITranslatorArg) => void };
  requests: { onDeleteTranslator: () => Promise<void> };
}

export const ManagementTranslatorDataContext = createContext<
  IContextValue | undefined
>(undefined);

const ManagementTranslatorDataProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [mode, setMode] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [translator, setTranslator] = useState<ITranslator | undefined>(
    undefined
  );
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const [translatorForm] = Form.useForm();

  const { tourId } = useParams();
  const { state } = useLocation();
  const { words } = useLanguage();

  const navigate = useNavigate();

  const onFinish = (values: ITranslatorArg) => {
    if (values.birthDate)
      values.birthDate = convertDateTimeIran(values.birthDate);

    if (mode === "add") translatorAddReq(values);
    else if (mode === "edit") translatorEditReq(values);
  };

  const translatorAddReq = async (values: ITranslatorArg) => {
    if (!tourId) return;
    setLoading(true);
    try {
      const { AddTranslator } = new TourManagementService();
      const result = await AddTranslator(tourId, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        navigate(-1);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const translatorEditReq = async (values: ITranslatorArg) => {
    if (!translator) return;
    setLoading(true);
    try {
      const { EditTranslator } = new TourManagementService();
      const result = await EditTranslator(translator.id, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        navigate(-1);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onDeleteTranslator = async () => {
    if (!translator) return;
    setDeleteLoading(true);
    try {
      const { DeleteTranslator } = new TourManagementService();
      const result = await DeleteTranslator(translator.id);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setTranslator(undefined);
        setMode("add");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
    }
  };

  const getTranslator = useCallback(async () => {
    if (!tourId) return;
    setDataLoading(true);
    try {
      const { GetTranslator } = new TourManagementService();
      const res = await GetTranslator(tourId);
      if (res && res.status === 200 && res.data) {
        setTranslator(res.data);
      } else {
        setTranslator(undefined);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoading(false);
    }
  }, [tourId]);

  useEffect(() => {
    if (tourId && (mode === "edit" || mode === "view")) getTranslator();
  }, [getTranslator, mode, tourId]);

  useEffect(() => {
    if (state && state.mode) setMode(state.mode);
    else setMode("edit");
  }, [state]);

  useEffect(() => {
    if (translator) {
      translatorForm.setFieldsValue({
        firstName: translator.firstName,
        phoneNumber: translator.phoneNumber,
        lastName: translator.lastName,
        birthDate: translator.birthDate
          ? convertDatePicker(translator.birthDate)
          : null,
        gender: translator.gender,
        nationalCode: translator.nationalCode,
      });
    } else translatorForm.resetFields();
  }, [translatorForm, translator]);

  const contextValues = {
    states: {
      mode,
      dataLoading,
      loading,
      translator,
      translatorForm,
      deleteLoading,
    },
    func: { onFinish },
    requests: { onDeleteTranslator },
  };

  return (
    <ManagementTranslatorDataContext.Provider value={contextValues}>
      {children}
    </ManagementTranslatorDataContext.Provider>
  );
};

export default ManagementTranslatorDataProvider;

export const useManagementTranslatorData = () =>
  useContext(ManagementTranslatorDataContext)!;
