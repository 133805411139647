import { FC } from "react";
import { Button, Col, Divider, Form, Row } from "antd";
import InputUiKit from "src/components/UiKit/Input";
import useLanguage from "src/store/language";
import SubmitButton from "src/components/UiKit/SubmitButton";
import TextAreaUiKit from "src/components/UiKit/textArea";
import { useBrokersData } from "../../context/BrokersData";
import { useFormHasChanged } from "../../hooks/useFormHasChanged";

const AddRoom: FC = () => {
  const { words } = useLanguage();
  const {
    states: { selectedBroker, addBrokerForm, loading },
    func: { onFinish },
    dispatches: { setSelectedBroker },
  } = useBrokersData();

  const handleCancelForm = () => {
    selectedBroker ? setSelectedBroker(undefined) : addBrokerForm.resetFields();
  };

  const canSubmit = useFormHasChanged();

  return (
    <Form
      form={addBrokerForm}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      name="place-form"
    >
      <p className="add-form-title">{words.broker.addFormTitle}</p>
      <Row gutter={[8, 16]} className="add-form-row">
        <Col xs={24} md={8}>
          <Form.Item
            name={"name"}
            label={words.broker.nameLabel}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit placeholder={words.global.insert} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item name={"city"} label={words.broker.cityLabel}>
            <InputUiKit placeholder={words.global.insert} />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item name={"country"} label={words.broker.countryLabel}>
            <InputUiKit placeholder={words.broker.countryPlaceholder} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name={"description"} label={words.broker.descriptionLabel}>
            <TextAreaUiKit placeholder={words.broker.descriptionLabel} />
          </Form.Item>
        </Col>
        <Col span={24} className="footer">
          <div className="footer-box">
            <Button
              type="text"
              className="bg-[#F5F5F5] text-[#8E8E8E] ml-2 round-lg w-[85px]"
              onClick={handleCancelForm}
            >
              {selectedBroker
                ? words.basicInfo.countryCancel
                : words.basicInfo.buttonClear}
            </Button>
            <SubmitButton
              form={addBrokerForm}
              loading={loading}
              canSubmit={canSubmit}
            >
              {selectedBroker
                ? words.basicInfo.countrySubmit
                : words.basicInfo.buttonAdd}
            </SubmitButton>
          </div>
        </Col>
        <Divider className="bg-[#DADEEC]" />
      </Row>
    </Form>
  );
};

export default AddRoom;
