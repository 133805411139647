import { Form } from "antd";
import _ from "lodash";
import { useManagementTranslatorData } from "./context";

export const useFormHasChanged = () => {
  const {
    states: { translator, translatorForm },
  } = useManagementTranslatorData();

  const allValues = Form.useWatch([], translatorForm);

  const formHasProperty = () => {
    return allValues
      ? Object.values(allValues).every((el) => el === undefined || el === null)
      : false;
  };

  const changed = _.isEqual(allValues, translator);

  return translator ? changed : formHasProperty();
};
