import { Col } from "antd";
import styled from "styled-components";

export const AttachmentTabContainer = styled(Col)`
  margin-top: 20px;
  background-color: #ffffff;
  padding: 24px 40px;
  border-radius: 20px;
  .section {
    margin-top: 30px;
    border: 1px dashed #dadeec;
    border-radius: 8px;
    width: 100%;
    padding: 0 24px;
  }
  .form {
    margin-top: 30px;
  }
  .attach-title {
    color: #1f1f1f;
    font-weight: 500;
    font-size: 14px;
  }
  .title {
    margin: 30px 30px 0 30px;
    font-weight: 400;
    font-size: 12px;
    color: #001377;
  }
  .ant-input {
    background-color: #fafafa;
    width: 95%;
  }
`;

export const ModalContentContainer = styled.div`
  margin-top: 30px;

  .description {
    border: 1px solid #cacaca;
    border-radius: 12px;
    padding: 48px;
    font-weight: 700;
    font-size: 18px;
    color: #000000;
  }
  .footer {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #dadeec;
    padding: 32px 0px 16px 0px;
    margin-top: 30px;
  }
  .delete {
    background-color: #f64c4c;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .delete:hover {
    background-color: #f64c4c;
    color: #ffffff;
    font-weight: 700;
    font-size: 14px;
  }
  .cancel {
    color: #8e8e8e;
    background-color: #f5f5f5;
    border-radius: 12px;
    margin-right: 10px;
  }
  .material-icons {
    font-size: 14px;
  }
`;

export const ModalHeaderContainer = styled.div`
  width: 95%;
  .title {
    display: inline-block;
    width: 25%;
    color: #000000;
    font-weight: 700;
    font-size: 20px;
  }
  .code {
    display: inline-block;
    width: 70%;
    text-align: center;
    color: #f06614;
    font-weight: 700;
    font-size: 16px;
  }
`;
