import { Col, Row } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";

export const ViewMiddleHeaderNotification: FC = () => {
  const { words } = useLanguage();

  return (
    <Row>
      <Col
        span={24}
        className="mt-[48px] border-0 border-b-[1px] border-solid border-[#FDE8DC] pb-[24px]"
      >
        <div className="flex justify-between items-center px-[24px] w-full">
          <div className="flex justify-center items-center gap-[16px]">
            <div className="flex justify-center items-center p-[16px] bg-[#E9EAF1] rounded-[12px]">
              <span className="material-icons text-[20px] text-[#273574]">
                visibility
              </span>
            </div>
            <span className="text-[16px] text-[#233069] font-bold">
              {words.managementTour.tourNumber} 425386
            </span>
            <span className="text-[18px] text-[#939AB9] font-black">/</span>
            <span className="text-[16px] text-[#4B4B4B] font-bold">
              {words.managementNotification.viewNotification}
            </span>
          </div>
          <div className="flex items-center gap-[12px] cursor-pointer">
            <span className="text-[#F06614] text-[18px] font-bold">
              {words.global.back}
            </span>
            <span className="material-icons text-[#F06614] text-[24px]">
              west
            </span>
          </div>
        </div>
      </Col>
    </Row>
  );
};
