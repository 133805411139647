import styled from "styled-components";

export const PagesContainer = styled.div`
  width: 100%;
  height: 76px;
  border-radius: 0 20px 20px 0;
  padding: 0;
  gap: 24px;
  background-color: #fffaf8;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
  .ant-row {
    width: 100%;
    padding: 0;
  }
  .ant-col.select {
    padding: 0;
  }
  .ant-col.code-col {
    padding: 16px 0;
    background-color: #fffaf8;
  }
  .code-container {
    width: 95%;
    /* box-shadow: 10px 0 0 0 rgb(0 0 0 / 0.2), -10px 0 0 0 rgb(0 0 0 / 0.2); */
  }

  .ant-select {
    width: 100%;
    height: 76px;
  }
  .ant-select-selector {
    border-radius: 0 20px 20px 0;
  }
  .code {
    background-color: #ffffff;
    padding: 16px;
    border-radius: 12px;
    gap: 18px;
    font-weight: 500;
    font-size: 16px;
    color: #cacaca;
    margin-left: 5px;
  }
`;
