import { FC } from "react";
import { Button, Divider, Modal, type TableColumnsType } from "antd";
import { TableUI } from "src/components/UiKit/table";
import {
  IResponderDetailById,
  IResponderDetail,
} from "src/services/BasicInformation/models";
import { useResponderDetailsData } from "./context";
import { useHotelsTableData } from "../../context";
import { isArray } from "lodash";

const ResponderDetailsModal: FC = () => {
  const { responder, setResponder } = useHotelsTableData();
  const { responderDetails, dataLoading } = useResponderDetailsData();

  const columns: TableColumnsType<IResponderDetailById> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      width: 60,
      render: (text, record, index) => index + 1,
    },
    {
      title: "پاسخگو",
      dataIndex: "name",
      key: "name",
      width: 130,
    },
    {
      title: "شماره تماس",
      dataIndex: "hotelResponderDetails",
      key: "hotelResponderDetails",
      render: (value) => (
        <div>
          {isArray(value)
            ? value.map((responder: IResponderDetail) => (
                <span key={responder.id} className="px-[10px]">
                  {responder.phoneNumber}
                </span>
              ))
            : "-"}
        </div>
      ),
    },
  ];
  const ModalTitle = () => {
    return (
      <div className="w-full flex flex-col">
        <span className="text-[#044440] text-[18px] font-bold">
          اطلاعات تماس
        </span>
        <span className="text-[#8E8E8E] text-[16px] font-normal">
          {responder?.name}
        </span>
      </div>
    );
  };
  return (
    <Modal
      title={<ModalTitle />}
      open={responder ? true : false}
      footer={false}
      closable={false}
      width={"50%"}
    >
      <Divider className="bg-[#CACACA] p-0 m-0" />

      <div className="padding-[24px] mt-5 border border-solid border-[#CACACA] rounded-xl">
        <TableUI
          tableType="secondary"
          loading={dataLoading}
          columns={columns}
          dataSource={responderDetails}
          pagination={false}
        />
      </div>
      <div className="w-full mt-5 flex justify-center">
        <Button
          icon={<span className="material-icons text-[14px] mr-2">cancel</span>}
          onClick={() => setResponder(undefined)}
          className="flex flex-row-reverse items-center justify-between"
        >
          بستن
        </Button>
      </div>
    </Modal>
  );
};

export default ResponderDetailsModal;
