import {
  IAllAction,
  IAssignRoleToActionArgs,
  ICategory,
  ICategoryParams,
  INewCategory,
  INewRoleArg,
  IRolesOfAction,
  ISubCategory,
  ISubCategoryParams,
} from "./models";
import { SystemUrls } from "./urls";
import axios from "axios";

export class SystemService {
  async NewRole(data: INewRoleArg) {
    try {
      const response = await axios.post<void>(SystemUrls.NewRole, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async NewCategory(data: INewCategory) {
    try {
      const response = await axios.post<number>(SystemUrls.NewCategory, data);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AllActions() {
    try {
      const response = await axios.get<IAllAction[]>(SystemUrls.AllActions);
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AllCategory(data: ICategoryParams) {
    const searchParams = new URLSearchParams();
    if (data.Code) searchParams.append("Code", data.Code);
    if (data.Name) searchParams.append("Name", data.Name);
    try {
      const response = await axios.get<ICategory[]>(
        SystemUrls.AllCategory.concat(`?${searchParams.toString()}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AllSubCategory(id: number, data: ISubCategoryParams) {
    const searchParams = new URLSearchParams();
    if (data.Code) searchParams.append("Code", data.Code);
    if (data.Name) searchParams.append("Name", data.Name);
    try {
      const response = await axios.get<ISubCategory[]>(
        SystemUrls.System.concat(id.toString())
          .concat(SystemUrls.AllSubCategory)
          .concat(`?${searchParams.toString()}`)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async RolesOfAction(id: number) {
    try {
      const response = await axios.get<IRolesOfAction[]>(
        SystemUrls.System.concat(id.toString()).concat(SystemUrls.RolesOfAction)
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
  async AssignRoleToAction(data: IAssignRoleToActionArgs) {
    try {
      const response = await axios.post<void>(
        SystemUrls.AssignRoleToAction,
        data
      );
      return response;
    } catch (err) {
      throw console.log(err);
    }
  }
}
