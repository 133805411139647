import { FC } from "react";
import { Button, Col, Divider, Form, Row, Select } from "antd";
import SubmitButton from "src/components/UiKit/SubmitButton";
import { SelectUiKit } from "src/components/UiKit/select";
import useLanguage from "src/store/language";
import InputNumberUiKit from "src/components/UiKit/inputNumber";
import { useRouteHotelsData } from "../../context";
import InputUiKit from "src/components/UiKit/Input";
import EndRegisterButton from "src/components/UiKit/EndRegisterButton";

const AddEditRouteHotels: FC = () => {
  const {
    states: {
      routeRoomForm,
      selectedRouteRoom,
      loading,
      routeRooms,
      room,
      mode,
    },
    func: { onFinish },
    dispatches: { setSelectedRouteRoom },
  } = useRouteHotelsData();

  const { words } = useLanguage();

  const handleCancelForm = () => {
    if (selectedRouteRoom) setSelectedRouteRoom(undefined);
    else {
      routeRoomForm.setFieldsValue({
        roomId: null,
        count: null,
      });
    }
  };

  return (
    <Form
      form={routeRoomForm}
      onFinish={onFinish}
      onFinishFailed={() => alert("error")}
      layout="vertical"
      autoComplete="off"
      name="hotel-form"
    >
      <p className="add-hotel-form-title">
        {words.managementRoute.hotelFormTitle}
      </p>
      <Row gutter={[8, 16]} className="mt-[30px]">
        <Col xs={24} md={8}>
          <Form.Item name={"hotelId"} label={words.managementRoute.hotel}>
            {/* <SelectUiKit
              showSearch
              loading={hotel.loading}
              placeholder={words.managementRoute.select}
              disabled={true}
            >
              {hotel.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </SelectUiKit> */}
            <InputUiKit
              disabled={true}
              placeholder={words.managementRoute.select}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name={"roomId"}
            label={words.managementRoute.roomType}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <SelectUiKit showSearch placeholder={words.managementHotel.select}>
              {room.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </SelectUiKit>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name={"count"}
            label={words.managementRoute.roomCount}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputNumberUiKit
              controls={false}
              placeholder={words.managementRoute.write}
            />
          </Form.Item>
        </Col>

        <Col span={24} className="footer">
          <div className="footer-box">
            <Button
              type="text"
              className="clean-btn bg-[#F5F5F5] text-[#8E8E8E] ml-2 rounded-lg w-[85px]"
              onClick={handleCancelForm}
            >
              {selectedRouteRoom
                ? words.basicInfo.countryCancel
                : words.basicInfo.buttonClear}
            </Button>
            <SubmitButton
              form={routeRoomForm}
              loading={loading}
              canSubmit={false}
            >
              {selectedRouteRoom
                ? words.basicInfo.countrySubmit
                : words.basicInfo.buttonAdd}
            </SubmitButton>
            <EndRegisterButton
              btnTitle={
                selectedRouteRoom ? words.global.endEdit : words.global.End
              }
              disabled={routeRooms.length > 0 ? false : true}
            />
          </div>
        </Col>
        <Divider className="bg-[#DADEEC]" />
      </Row>
    </Form>
  );
};

export default AddEditRouteHotels;
