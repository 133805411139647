import { FC, useEffect } from "react";
import { Button, Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import useLanguage from "src/store/language";
import { convertDate } from "src/helper";
import { TableUI } from "src/components/UiKit/table";
import { TouristsFieldNames } from "src/modules/addEditTour/models";
import { IExcelData, ITouristExcelModalProps } from "../interface";
import { ModalContentContainer, ModalHeaderContainer } from "../style";

const TouristExcelModal: FC<ITouristExcelModalProps> = ({
  open,
  onBack,
  onSave,
  data = [],
}) => {
  const { words, isRtl } = useLanguage();

  useEffect(() => {}, [data]);

  const columns: ColumnsType<IExcelData> = [
    {
      title: `#`,
      dataIndex: "key",
      key: "key",
    },
    {
      title: `${words.viewTour.roomType}`,
      dataIndex: TouristsFieldNames.RoomType,
      key: TouristsFieldNames.RoomType,
    },
    {
      title: `${words.viewTour.lastName}`,
      dataIndex: TouristsFieldNames.LastName,
      key: TouristsFieldNames.LastName,
    },
    {
      title: `${words.viewTour.firstName}`,
      dataIndex: TouristsFieldNames.FirstName,
      key: TouristsFieldNames.FirstName,
    },
    {
      title: `${words.viewTour.sex}`,
      dataIndex: TouristsFieldNames.Sex,
      key: TouristsFieldNames.Sex,
    },
    {
      title: `${words.viewTour.passportNO}`,
      dataIndex: TouristsFieldNames.PassportNO,
      key: TouristsFieldNames.PassportNO,
    },
    {
      title: `${words.viewTour.birthDate}`,
      dataIndex: TouristsFieldNames.BirthDate,
      key: TouristsFieldNames.BirthDate,
      render: (value) => convertDate(value),
    },
    {
      title: `${words.viewTour.expireDate}`,
      dataIndex: TouristsFieldNames.ExpireDate,
      key: TouristsFieldNames.ExpireDate,
      render: (value) => convertDate(value),
    },
  ];

  const ModalHeader = () => {
    return (
      <ModalHeaderContainer>
        {words.addEditTour.excelModalTitle}
      </ModalHeaderContainer>
    );
  };

  return (
    <Modal
      title={<ModalHeader />}
      open={open}
      footer={null}
      onCancel={onBack}
      centered
      width={1000}
    >
      <ModalContentContainer>
        <TableUI
          scroll={{ x: 400 }}
          dataSource={data}
          columns={columns}
          tableType="secondary"
        />
        <div className="footer" dir={isRtl ? "rtl" : "ltr"}>
          <Button
            type="text"
            className="save-btn"
            icon={<span className="material-icons">done</span>}
            onClick={onSave}
          >
            {words.global.save}
          </Button>
          <Button
            type="text"
            className="cancel"
            icon={<span className="material-icons">arrow_back</span>}
            dir={isRtl ? "ltr" : "rtl"}
            onClick={onBack}
          >
            {words.global.back}
          </Button>
        </div>
      </ModalContentContainer>
    </Modal>
  );
};

export default TouristExcelModal;
