import { Flex } from "antd";
import styled from "styled-components";

export const PreviewHotelHeader = styled(Flex)`
  padding: 8px 24px 16px 24px;
  margin-top: 40px;
  border-bottom: 1px solid var(--color-Stroke-footer-buttons, #fde8dc);
  .title {
    color: var(--color-Light-Mode-Text-Title, #233069);

    /* Fa/Headline/H6.18.Bold */
    font-family: "Vazir FD";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .subtitle {
    color: var(--color-Light-Mode-Text-Third-Text, #8e8e8e);

    /* Fa/Subtitle/S1.16.Regular */
    font-family: "Vazir FD";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .buttons {
    display: flex;
    width: 168px;
    padding: 16px 12px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 2px solid #cacaca;
    background: #fff;
    color: #1f1f1f;
    text-align: right;
    /* Fa/Button/B2.12.Bold */
    font-family: "Vazir FD";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    user-select: none;
  }
  .active {
    border-color: #f06614;
    color: #f06614;
  }
`;
