import { InputProps } from "antd";
import { FC, ReactNode } from "react";
import { InputUiKitContainer } from "./styles/Container";
import FormControlUiKit from "../FormControl";
import { omit } from "lodash";
import { Regex } from "src/constants";
interface IProps extends InputProps {
  label?: string;
  icon?: ReactNode;
  numbermode?: boolean;
}

const InputUiKit: FC<IProps> = ({ label, icon, ...props }) => {
  return (
    <FormControlUiKit label={label} height={"51px"} icon={icon}>
      <InputUiKitContainer
        autoComplete="off"
        onBeforeInput={
          props.numbermode
            ? (event: any) => {
                if (
                  Regex.number.test(event.data) ||
                  event.data === "." ||
                  event.data === "-"
                ) {
                  if (
                    (event.data === "." && event.target.value.includes(".")) ||
                    (event.data === "-" && event.target.value.includes("-"))
                  )
                    event.preventDefault();
                } else event.preventDefault();
              }
            : undefined
        }
        {...omit(props, "numbermode")}
      />
    </FormControlUiKit>
  );
};

export default InputUiKit;
