import { FC } from "react";
import HotelsAddTabs from "./components";
import AddHotelsDataProvider from "./context";

const HotelsAdd: FC = () => {
  return (
    <AddHotelsDataProvider>
      <HotelsAddTabs />
    </AddHotelsDataProvider>
  );
};

export default HotelsAdd;
