import { FC } from "react";
import { Button, Col } from "antd";
import useLanguage from "src/store/language";
import { useTourAddEditData } from "../context/TourAddEditData";
import { TabMenuKeys } from "../models";
import { useLocation } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";

interface IFilterActionsProps {
  onPrevious: () => void;
  loading?: boolean;
}
const FilterContentActions: FC<IFilterActionsProps> = ({
  onPrevious,
  loading,
}) => {
  const { words } = useLanguage();

  const { states } = useTourAddEditData()!;

  const location = useLocation();

  return (
    <Col span={24} className="buttons">
      {location.pathname.includes(ROUTES.tourEdit) ? (
        <Button
          type="primary"
          loading={loading}
          htmlType="submit"
          className="edit-finish"
          icon={<span className="material-icons">check_circle</span>}
        >
          {words.addEditTour.finisEdit}
        </Button>
      ) : (
        <>
          <Button
            type="primary"
            loading={loading}
            htmlType="submit"
            className="next"
            icon={<span className="material-icons">arrow_forward</span>}
          >
            {words.addEditTour.next}
          </Button>
          <Button
            type="text"
            className="cancel"
            icon={<span className="material-icons">close</span>}
            onClick={onPrevious}
          >
            {states.currentFilter === TabMenuKeys.tourist
              ? words.global.close
              : words.global.back}
          </Button>
        </>
      )}
    </Col>
  );
};

export default FilterContentActions;
