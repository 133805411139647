import { Form } from "antd";
import _ from "lodash";
import { useCityContext } from "./context";

export const useFormHasChanged = () => {
  const { editCity, createCityForm } = useCityContext();

  const allValues = Form.useWatch([], createCityForm);

  const changed = _.isEqual(
    _.omit(allValues, ["id"]),
    _.omit(editCity, ["id"])
  );
  return editCity ? changed : false;
};
