import { FC } from "react";
import AddManagementRoute from "./form/AddManagementRoute";
import ManagementRouteTable from "./table/ManagementRouteTable";
import ManagementRouteAddEditDataProvider from "./context";
import { useManagementRouteData } from "../../context";

const AddEditRoute: FC = () => {
  const {
    states: { mode },
  } = useManagementRouteData();

  return (
    <ManagementRouteAddEditDataProvider>
      <>
        {(mode === "add" || mode === "edit") && <AddManagementRoute />}
        <ManagementRouteTable />
      </>
    </ManagementRouteAddEditDataProvider>
  );
};

export default AddEditRoute;
