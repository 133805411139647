import { Col } from "antd";
import styled from "styled-components";

export const HotelsViewContainer = styled(Col)`
  background-color: #fff;
  border-radius: 20px;
  padding: 24px !important;

  box-shadow: 2px 6px 16px 0px rgba(0, 0, 0, 0.18);

  .place-view-details {
    margin-top: 5px;
    border: solid 1px #dff7f6;
    border-radius: 8px;
    padding: 24px 16px;
    background-color: #fef6f1;
  }

  .place-key {
    font-weight: 700;
    font-size: 14px;
    color: #044440;
    width: 100px;
  }
  .place-value {
    font-weight: 500;
    font-size: 14px;
    color: #4b4b4b;
  }
  .title {
    font-weight: 700;
    font-size: 16px;
    color: #023936;
  }
`;
