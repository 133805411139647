import { styled } from "styled-components";

export const ChangePasswordContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  .nameHolder {
    color: var(--text, #233069);
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .ant-input {
    color: #000;
  }
  .inputPassword {
    background: #f4f7fe;
    border-color: #f4f7fe;
    border-radius: 12px;
    min-height: 48px;
    input {
      background: transparent;
      width: 100%;
      display: block;
      color: #000;
    }
    svg {
      fill: #001377;
    }
  }
  .ant-form-item {
    width: 100% !important;
    display: flex;
    justify-content: center;
    /* gap: 24px; */
  }
  .footer {
    padding-top: 16px;
    padding-inline: 24px;
    width: calc(100% - 48px);
  }
  .ant-form-item-row {
    width: 318px;
  }
  .save {
    background-color: #ffffff;
    color: #f06614;
    border: solid 1px #f06614;
  }
  .save:hover {
    background-color: #ffffff !important;
    color: #f06614 !important;
  }
  .form-container {
    border-radius: 20px;
    border: 1px solid var(--color-Stroke-footer-buttons, #fde8dc);
    background: var(--Secondary-Primary-White, #fff);
    padding: 24px;
  }
`;
