import ManagementFiles from "src/modules/managementFiles";
import ManagementFilesDataProvider from "src/modules/managementFiles/context";

const ManagementFilesPage = () => (
  <ManagementFilesDataProvider>
    <ManagementFiles />
  </ManagementFilesDataProvider>
);

export default ManagementFilesPage;
