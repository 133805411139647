import { Form, FormInstance, message } from "antd";
import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import {
  IManagementInsurance,
  INewInsuranceArg,
} from "src/services/TourManagement/models";
import useLanguage from "src/store/language";
import { IPagination } from "src/models/interfaces/pagination";
import { convertDatePicker, convertDateTimeIran } from "src/helper";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";

interface IContextValue {
  values: {
    loading: boolean;
    dataLoading: boolean;
    totalCount: number;
    tablePaginationParams: Pick<IPagination, "Offset" | "Limit">;
    insuranceInfo: IInsuranceTableData[];
    confirmLoading: boolean;
    selectedInsurance: IManagementInsurance | undefined;
    deleteId: number;
  };
  dispatches: {
    setSelectedInsurance: Dispatch<
      SetStateAction<IManagementInsurance | undefined>
    >;
    setTablePaginationParams: Dispatch<
      SetStateAction<Pick<IPagination, "Offset" | "Limit">>
    >;
  };
  func: {
    onFinishInsuranceInfo: (values: INewInsuranceArg) => void;
    handleDeleteId: (id: number) => void;
  };
  requests: { onDelete: () => void };
  form: { insuranceInfoForm: FormInstance };
}

export const AddEditInsuranceDataContext = createContext<
  IContextValue | undefined
>(undefined);

export interface IInsuranceTableData extends IManagementInsurance {
  attachments?: boolean;
}

const AddEditInsuranceDataProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [insuranceInfo, setInsuranceInfo] = useState<IInsuranceTableData[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [tablePaginationParams, setTablePaginationParams] =
    useState<IPagination>({
      Offset: 1,
      Limit: 10,
    });
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [selectedInsurance, setSelectedInsurance] = useState<
    IManagementInsurance | undefined
  >(undefined);

  const [insuranceInfoForm] = Form.useForm();

  const { words } = useLanguage();
  const { tourId } = useParams();

  const onFinishInsuranceInfo = (values: INewInsuranceArg) => {
    if (values.amount) values.amount = values.amount.toString();
    if (values.date) values.date = convertDateTimeIran(values.date);

    if (selectedInsurance) editInsuranceReq(values);
    else addInsuranceReq(values);
  };

  const editInsuranceReq = async (values: INewInsuranceArg) => {
    if (!selectedInsurance) return;
    setLoading(true);

    try {
      const { EditInsurance } = new TourManagementService();
      const result = await EditInsurance(selectedInsurance.id, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setSelectedInsurance(undefined);
        getInsuranceInfo();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const addInsuranceReq = async (values: INewInsuranceArg) => {
    if (!tourId) return;
    setLoading(true);

    try {
      const { AddInsurance } = new TourManagementService();
      const result = await AddInsurance(+tourId, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        insuranceInfoForm.resetFields();
        getInsuranceInfo();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteId = (id: number) => {
    setDeleteId(id);
  };

  const onDelete = async () => {
    if (deleteId === 0) return;
    setConfirmLoading(true);
    try {
      const { DeleteInsurance } = new TourManagementService();
      const result = await DeleteInsurance(deleteId);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        if (deleteId === selectedInsurance?.id) setSelectedInsurance(undefined);
        setDeleteId(0);
        getInsuranceInfo();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmLoading(false);
    }
  };

  const getInsuranceInfo = useCallback(async () => {
    if (!tourId) return;
    setDataLoading(true);
    const params = new URLSearchParams(tablePaginationParams);
    try {
      const { GetManagementInsurance } = new TourManagementService();
      const res = await GetManagementInsurance(tourId, params.toString());
      if (res && res.status === 200 && res.data) {
        // setData(res.data.records);
        setTotalCount(res.data.count);
        getInsuranceAttachment(res.data.records);
        return res.data.records;
      } else {
        // setData([]);
        return [];
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tablePaginationParams, tourId]);

  const getInsuranceAttachment = useCallback(
    async (data: IInsuranceTableData[] | undefined) => {
      if (!data || data?.length === 0) return setInsuranceInfo([]);
      setDataLoading(true);
      let newData = [...data];
      const { GetInsuranceDocument } = new TourManagementService();
      newData?.forEach(async (insurance, index) => {
        await GetInsuranceDocument(insurance.id)
          .then((attachment) => {
            if (attachment) {
              // setData((perv) =>
              newData.map((prevInsurance) => {
                if (prevInsurance.id === insurance.id)
                  prevInsurance.attachments =
                    attachment.data.records.length > 0 ? true : false;
                return prevInsurance;
              });
              // );
            }
          })
          .catch((error) => {
            // setData((perv) =>
            newData.map((prevInsurance) => {
              if (prevInsurance.id === insurance.id)
                prevInsurance.attachments = false;
              return prevInsurance;
            });
            // );
          });
      });
      setInsuranceInfo(newData);
      setDataLoading(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  useEffect(() => {
    getInsuranceInfo();
  }, [getInsuranceInfo, tourId]);

  useEffect(() => {
    if (selectedInsurance) {
      insuranceInfoForm.setFieldsValue({
        type: selectedInsurance.type,
        company: selectedInsurance.company || null,
        amount: selectedInsurance.amount ? selectedInsurance.amount : null,
        date: selectedInsurance.date
          ? convertDatePicker(selectedInsurance.date)
          : null,
      });
    } else insuranceInfoForm.resetFields();
  }, [insuranceInfoForm, selectedInsurance]);

  const contextValues = {
    values: {
      loading,
      dataLoading,
      totalCount,
      tablePaginationParams,
      insuranceInfo,
      confirmLoading,
      selectedInsurance,
      deleteId,
    },
    dispatches: { setTablePaginationParams, setSelectedInsurance },
    func: { onFinishInsuranceInfo, handleDeleteId },
    requests: { onDelete },
    form: { insuranceInfoForm },
  };

  return (
    <AddEditInsuranceDataContext.Provider value={contextValues}>
      {children}
      <DeleteConfirmModal
        showConfirm={deleteId > 0}
        onCancel={() => handleDeleteId(0)}
        onOk={() => onDelete()}
        title={words.managementInsurance.deleteInsurance}
        okTitle={words.global.delete}
        loading={confirmLoading}
      />
    </AddEditInsuranceDataContext.Provider>
  );
};

export default AddEditInsuranceDataProvider;

export const useAddEditInsuranceData = () =>
  useContext(AddEditInsuranceDataContext)!;
