import { FC, useState } from "react";
import { IPagination } from "src/models/interfaces/pagination";
import useLanguage from "src/store/language";
import { useManagementNotification } from "../context";
import { ColumnsType } from "antd/es/table";
import { INotificationTable } from "../model";
import { Col, Row } from "antd";
import { TableUI } from "src/components/UiKit/table";
import { DescriptionModal } from "./descriptionModal";

export const AddTableNotifications: FC = () => {
  const { words } = useLanguage();

  const {
    value: { totalCount },
    func: { onEdit, onOpenModal },
  } = useManagementNotification();

  const [tablePaginationParams, setTablePaginationParams] =
    useState<IPagination>({
      Offset: 1,
      Limit: 10,
    });

  const mockData: INotificationTable[] = [
    {
      noticeTitle: "",
      displayDate: "",
      description: "",
    },
  ];
  const columns: ColumnsType<INotificationTable> = [
    {
      title: "#",
      key: "id",
      align: "center",
      width: "62px",
      render: (name, record, index) => index + 1,
    },
    {
      title: words.managementNotification.inputTitle,
      dataIndex: "noticeTitle",
      key: "noticeTitle",
      align: "center",
      width: "100px",
    },
    {
      title: words.managementNotification.DisplayDate,
      dataIndex: "displayDate",
      key: "displayDate",
      align: "center",
      width: "141px",
    },
    {
      title: words.managementNotification.description,
      dataIndex: "description",
      key: "description",
      align: "center",
      width: "100px",
      render: () => (
        <div className="flex justify-center items-center gap-[16px]">
          <span
            onClick={onOpenModal}
            className="material-icons text-[16px] cursor-pointer"
          >
            chat
          </span>
        </div>
      ),
    },
    {
      title: words.managementNotification.operation,
      key: "operation",
      align: "center",
      width: "120px",
      render: () => (
        <div className="flex justify-center items-center gap-[16px]">
          <span
            onClick={onEdit}
            className="material-icons text-[#47B881]  text-[16px] cursor-pointer"
          >
            edit_note
          </span>
          <span className="material-icons text-[#F64C4C] text-[16px] cursor-pointer">
            delete_forever
          </span>
        </div>
      ),
    },
  ];
  return (
    <Row>
      <Col span={24}>
        <h4 className="text-[14px] text-[#1F1F1F] pt-[12px]">
          {words.managementNotification.tableTitle}
        </h4>
      </Col>
      <Col span={24}>
        <TableUI
          tableType="secondary"
          columns={columns}
          dataSource={mockData}
          pagination={{
            position: ["bottomCenter"],
            total: totalCount,
            pageSize: tablePaginationParams.Limit,
            current: tablePaginationParams.Offset,
            onChange(page, pageSize) {
              setTablePaginationParams((prev) => ({
                ...prev,
                Offset: page,
                Limit: pageSize,
              }));
            },
          }}
        />
      </Col>
      <DescriptionModal />
    </Row>
  );
};
