import { FC } from "react";
import { Button, Col, Form, Row, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import SubmitButton from "src/components/UiKit/SubmitButton";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import { SelectUiKit } from "src/components/UiKit/select";

import { useAddEditSimcardData } from "../context";
import { useManagementSimcardData } from "../../../context";
import { useHasChangedAssignForm } from "../hooks/useHasChangedAssignForm";

const AssignSimcard: FC = () => {
  const { words } = useLanguage();
  const canAssignSubmit = useHasChangedAssignForm();
  const {
    states: { mode },
  } = useManagementSimcardData();

  const {
    values: { tourist, loading, selectedAssign },
    dispatches: { setSelectedAssign },
    func: { onFinishAssignSimcard },
    form: { assignForm },
  } = useAddEditSimcardData();

  const handleCancel = () => {
    if (!selectedAssign) {
      assignForm.resetFields();
    } else setSelectedAssign(undefined);
  };

  return (
    <>
      <p className="title mt-[24px]">
        {mode === "add"
          ? words.managementSimcardAssign.addFormTitle
          : words.managementSimcardAssign.editFormTitle}
      </p>
      <Form
        onFinish={onFinishAssignSimcard}
        form={assignForm}
        layout="vertical"
        preserve={false}
        className="mt-[40px]"
      >
        <Row gutter={[24, 12]}>
          <Col xs={24} md={8}>
            <FormItem
              name={"touristId"}
              label={words.managementSimcardAssign.tourist}
              rules={[{ required: true, message: words.global.fieldRequired }]}
            >
              <SelectUiKit
                showSearch
                loading={tourist.loading}
                placeholder={words.managementRoute.select}
              >
                {tourist.data.map((tourist) => (
                  <Select.Option key={tourist.id} value={tourist.id}>
                    {tourist.firstName.concat(" " + tourist.lastName)}
                  </Select.Option>
                ))}
              </SelectUiKit>
            </FormItem>
          </Col>

          <Col xs={24} md={8}>
            <FormItem
              name={"phoneNumber"}
              label={words.managementSimcardAssign.simcardNumberLabel}
              rules={[{ required: true, message: words.global.fieldRequired }]}
            >
              <InputUiKit
                placeholder={
                  words.managementSimcardAssign.simcardIssuerMobilePlaceHolder
                }
              />
            </FormItem>
          </Col>
          <Col span={24} className="flex items-center justify-end">
            <div className="flex items-center">
              <Button
                type="text"
                className="bg-[#F5F5F5] text-[#8E8E8E] rounded-lg ml-2 w-[85px]"
                onClick={handleCancel}
              >
                {selectedAssign
                  ? words.managementTime.cancel
                  : words.managementTime.clear}
              </Button>
              <SubmitButton
                form={assignForm}
                loading={loading}
                canSubmit={canAssignSubmit}
              >
                {!selectedAssign
                  ? words.global.add
                  : words.managementTime.editBtn}
              </SubmitButton>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AssignSimcard;
