import { FC } from "react";
import { type TableColumnsType } from "antd";

import { TableUI } from "src/components/UiKit/table";
import {
  IContact,
  IResponderDetail,
} from "src/services/BasicInformation/models";
import { useHotelsViewData } from "../context";
import { isArray } from "lodash";

const ContactsInfo: FC = () => {
  const {
    states: { contactsLoading, responderDetails },
  } = useHotelsViewData();

  const columns: TableColumnsType<IContact> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (text, record, index) => index + 1,
    },
    {
      title: "پاسخگو",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "شماره تماس",
      dataIndex: "hotelResponderDetails",
      key: "hotelResponderDetails",
      render: (value) => (
        <div>
          {isArray(value)
            ? value.map((responder: IResponderDetail) => (
                <span key={responder.id} className="px-[10px]">
                  {responder.phoneNumber}
                </span>
              ))
            : "-"}
        </div>
      ),
    },
  ];

  return (
    <>
      <p className="title mt-[30px]">اطلاعات تماس</p>
      <TableUI
        tableType="secondary"
        loading={contactsLoading}
        columns={columns}
        dataSource={responderDetails}
        pagination={false}
      />
    </>
  );
};

export default ContactsInfo;
