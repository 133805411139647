import { Flex } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { useManagementDriverData } from "../../context";
import { convertDate } from "src/helper";

const ViewDriver: FC = () => {
  const { words } = useLanguage();
  const {
    states: { driver },
  } = useManagementDriverData();

  return (
    <>
      <p className="title">{words.managementDriver.viewDriverTitle}</p>
      <section className="view-details">
        <Flex className="w-full">
          <Flex vertical className="w-[50%]">
            <Flex>
              <div className="detail-title">
                {words.managementDriver.nameLabel}:
              </div>
              <div className="text-[14px] text-[#4B4B4B] font-normal">
                {driver && driver?.fullName ? driver?.fullName : "-"}
              </div>
            </Flex>
            <Flex className="second">
              <div className="detail-title">
                {words.managementDriver.nationalCodeLabel}:
              </div>
              <div className="text-[14px] text-[#4B4B4B] font-normal">
                {driver && driver?.nationalCode ? driver?.nationalCode : "-"}
              </div>
            </Flex>
            <Flex>
              <div className="detail-title">
                {words.managementDriver.dateLabel}:
              </div>
              <div className="text-[14px] text-[#4B4B4B] font-normal">
                {driver && driver?.birthDate
                  ? convertDate(driver?.birthDate)
                  : "-"}
              </div>
            </Flex>
          </Flex>
          <Flex vertical className="w-50%">
            <Flex>
              <div className="detail-title">
                {words.managementDriver.fatherNameLabel}:
              </div>
              <div className="text-[14px] text-[#4B4B4B] font-normal">
                {driver && driver?.fatherName ? driver?.fatherName : "-"}
              </div>
            </Flex>
            <Flex className="second">
              <div className="detail-title">
                {words.managementDriver.idNumberLabel}:
              </div>
              <div className="text-[14px] text-[#4B4B4B] font-normal">
                {driver && driver?.identityNumber
                  ? driver?.identityNumber
                  : "-"}
              </div>
            </Flex>
            <Flex>
              <div className="detail-title">
                {words.managementDriver.phoneLabel}:
              </div>
              <div className="text-[14px] text-[#4B4B4B] font-normal">
                {driver && driver?.phoneNumber ? driver?.phoneNumber : "-"}
              </div>
            </Flex>
          </Flex>
        </Flex>
      </section>
    </>
  );
};

export default ViewDriver;
