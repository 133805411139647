import { FC, ReactNode } from "react";
import { Modal, Button, Row } from "antd";
import useLanguage from "src/store/language";
import { ConfirmModalBodyContainer } from "./style";

interface IDeleteConfirmModalProps {
  showConfirm: boolean;
  onOk: () => void;
  onCancel: () => void;
  loading?: boolean;
  title: ReactNode;
  okTitle: string;
}

const DeleteConfirmModal: FC<IDeleteConfirmModalProps> = ({
  showConfirm,
  onOk,
  onCancel,
  loading,
  title,
  okTitle,
}) => {
  const { words } = useLanguage();

  return (
    <Modal
      title={<span className="text-[#F64C4C]">{title}</span>}
      open={showConfirm}
      onOk={onOk}
      onCancel={onCancel}
      footer={false}
    >
      <ConfirmModalBodyContainer>
        <p className="description">{words.global.confirmModalDescription}</p>

        <Row gutter={[12, 12]} align={"middle"} justify={"center"}>
          <Button
            loading={loading}
            type="text"
            icon={<span className="material-icons">delete_forever</span>}
            onClick={onOk}
            className="btn-ok"
          >
            {okTitle}
          </Button>
          <Button type="text" onClick={onCancel} className="btn-cancel">
            {words.addEditTour.cancel}
          </Button>
        </Row>
      </ConfirmModalBodyContainer>
    </Modal>
  );
};

export default DeleteConfirmModal;
