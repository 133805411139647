import { FC, useEffect } from "react";
import { Button, Col, Divider, Form, Row } from "antd";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import PersonTable from "./PersonTable";
import { useAddHotelResponderData } from "./context";
import { useFormHasChanged } from "./useFormHasChanged";
import SubmitButton from "src/components/UiKit/SubmitButton";

const AddPersonForm: FC = () => {
  const { words } = useLanguage();

  const {
    values: { addPersonForm, loading, selectedContact },
    func: { onFinishContact },
    dispatches: { setSelectedContact },
  } = useAddHotelResponderData();

  const canSubmit = useFormHasChanged();

  useEffect(() => {
    if (selectedContact) {
      addPersonForm.setFieldsValue({
        name: selectedContact.name,
      });
    } else addPersonForm.resetFields();
  }, [addPersonForm, selectedContact]);

  return (
    <Form
      form={addPersonForm}
      onFinish={onFinishContact}
      layout="vertical"
      autoComplete="off"
      name="contact-form"
    >
      <div className="h-[400px]">
        <p className="contact-form-title">لطفا نام پاسخگو را ثبت کنید.</p>
        <p className="contact-form-description">
          برای افزودن پاسخگو و شماره تماس ایشان به هر هتل ، ابتدا میبایست اشخاص
          پاسخگو را معرفی کرده و در ستون بعدی با انتخاب هر پاسخگو شماره های تماس
          شخص را ثبت نمایید.
        </p>
        <Row gutter={[16, 16]} className="mt-[80px]">
          <Col xs={24} md={16}>
            <Form.Item
              name={"name"}
              label="پاسخگو"
              rules={[{ required: true, message: words.global.fieldRequired }]}
            >
              <InputUiKit placeholder="مثلا آقای عباسی" />
            </Form.Item>
          </Col>

          <Col span={24} className="w-full flex justify-end">
            {selectedContact && (
              <Button
                type="text"
                className="bg-[#F5F5F5] text-[#8E8E8E] ml-2"
                onClick={() => setSelectedContact(undefined)}
              >
                لغو
              </Button>
            )}
            <SubmitButton
              loading={loading}
              form={addPersonForm}
              //submittable={canSubmit}
              //setSubmittable={setSubmittable}
              // width="w-[90px]"
              canSubmit={canSubmit}
            >
              {selectedContact ? "ذخیره ویرایش" : "افزودن"}
            </SubmitButton>
          </Col>
        </Row>
      </div>
      <Divider className="bg-[#DADEEC]" />
      <p className="contact-form-title">جدول اشخاص پاسخگو.</p>
      <PersonTable />
    </Form>
  );
};

export default AddPersonForm;
