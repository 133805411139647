import { Flex, Spin } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { useManagementSimcardData } from "../../context";
import { convertDate } from "src/helper";

const ViewSimcard: FC = () => {
  const { words } = useLanguage();

  const {
    states: { buySimcard, dataLoading },
  } = useManagementSimcardData();

  return (
    <>
      <p className="title !font-bold">{words.managementSimcard.viewTitle}</p>
      <section className="view-details">
        {dataLoading ? (
          <div className="w-full flex items-center justify-center">
            <Spin size="large" />
          </div>
        ) : (
          <Flex className="w-full">
            <Flex vertical className="w-[50%]">
              <Flex>
                <div className="detail-title">
                  {words.managementSimcard.simcardAmountLabel}:
                </div>
                <div className="text-[12px] text-[#4B4B4B] font-normal">
                  {buySimcard?.count} عدد
                </div>
              </Flex>
              <Flex>
                <div className="detail-title second">
                  {words.managementSimcard.simcardDateLabel}:
                </div>
                <div className="second text-[12px] text-[#4B4B4B] font-normal">
                  {buySimcard && buySimcard.payDate
                    ? convertDate(buySimcard?.payDate)
                    : "-"}
                </div>
              </Flex>
              <Flex>
                <div className="detail-title">
                  {words.managementSimcard.simcardIssuerMobileLabel}:
                </div>
                <div className="text-[12px] text-[#4B4B4B] font-normal">
                  {buySimcard && buySimcard?.exporterPhone
                    ? buySimcard?.exporterPhone
                    : "-"}
                </div>
              </Flex>
            </Flex>
            <Flex vertical className="w-50%">
              <Flex>
                <div className="detail-title">
                  {words.managementSimcard.amountViewLabel}:
                </div>
                <div className="text-[12px] text-[#4B4B4B] font-normal">
                  {buySimcard && buySimcard.amount ? (
                    <span>{buySimcard.amount.toLocaleString()} ریال</span>
                  ) : (
                    "-"
                  )}
                </div>
              </Flex>
              <Flex>
                <div className="detail-title second">
                  {words.managementSimcard.simcardIssuerNameLabel}:
                </div>
                <div className="second text-[12px] text-[#4B4B4B] font-normal">
                  {buySimcard && buySimcard.exporter
                    ? buySimcard.exporter
                    : "-"}
                </div>
              </Flex>
            </Flex>
          </Flex>
        )}
      </section>
    </>
  );
};

export default ViewSimcard;
