import ManagementSimcard from "src/modules/managementSimcard";
import ManagementSimcardDataProvider from "src/modules/managementSimcard/context";

const ManagementSimcardPage = () => (
  <ManagementSimcardDataProvider>
    <ManagementSimcard />
  </ManagementSimcardDataProvider>
);

export default ManagementSimcardPage;
