import { Form } from "antd";
import _ from "lodash";
import { useRoomsData } from "../context/RoomsData";

export const useFormHasChanged = () => {
  const {
    states: { selectedRoom, addRoomForm },
  } = useRoomsData();

  const allValues = Form.useWatch([], addRoomForm);

  const changed = _.isEqual(
    _.omit(allValues, ["id"]),
    _.omit(selectedRoom, ["id"])
  );
  return selectedRoom ? changed : false;
};
