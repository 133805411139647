import { App } from "antd";
import {
  FC,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BasicInformationService } from "src/services/BasicInformation/BasicInformation.service";
import { IBroker, ICountry } from "src/services/BasicInformation/models";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import {
  IManagementTour,
  INewTourArg,
} from "src/services/TourManagement/models";
import useLanguage from "src/store/language";

interface IContextValue {
  states: {
    mode: string;
    countries: ICountry[];
    countryLoading: boolean;
    loading: boolean;
    tour: IManagementTour | undefined;
    brokerProps: IBrokerProps;
    dataLoading: boolean;
  };
  func: { onFinish: (values: INewTourArg) => void };
  requests: {};
}

interface IBrokerProps {
  brokers: IBroker[];
  loading: boolean;
}

export const ManagementBasicInfoDataContext = createContext<
  IContextValue | undefined
>(undefined);

const ManagementBasicInfoDataProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [mode, setMode] = useState<string>("");
  const [countryLoading, setCountryLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [tour, setTour] = useState<IManagementTour | undefined>(undefined);
  const [brokerProps, setBrokerProps] = useState<IBrokerProps>({
    brokers: [],
    loading: false,
  });
  const { message } = App.useApp();
  const { tourId } = useParams();
  const { state } = useLocation();
  const { words } = useLanguage();
  const navigate = useNavigate();

  const onFinish = (values: INewTourArg) => {
    tourEditReq(values);
  };

  const tourEditReq = async (values: INewTourArg) => {
    if (!tourId) return;
    setLoading(true);
    try {
      const { EditTour } = new TourManagementService();
      const result = await EditTour(parseInt(tourId), values);
      if (result && result.status === 200) {
        message.success(words.addTour.editSuccessMessage);
        navigate(-1);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getTour = useCallback(async () => {
    if (!tourId) return;
    setDataLoading(true);
    try {
      const { GetTourById } = new TourManagementService();
      const res = await GetTourById(tourId);
      if (res && res.status === 200 && res.data) {
        setTour(res.data);
      } else {
        navigate(-1);
      }
    } catch (e) {
      navigate(-1);
    } finally {
      setDataLoading(false);
    }
  }, [navigate, tourId]);

  const getBrokers = useCallback(async () => {
    setBrokerProps((prev) => ({ ...prev, loading: true }));
    try {
      const { Brokers } = new BasicInformationService();
      const res = await Brokers();
      if (res && res.status === 200 && res.data) {
        setBrokerProps((prev) => ({ ...prev, brokers: res.data.records }));
      } else {
        setBrokerProps((prev) => ({ ...prev, brokers: [] }));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setBrokerProps((prev) => ({ ...prev, loading: false }));
    }
  }, []);

  const getCountries = useCallback(async () => {
    setCountryLoading(true);
    try {
      const { Countries } = new BasicInformationService();
      const res = await Countries();
      if (res && res.status === 200 && res.data) {
        setCountries(res.data.records);
      } else {
        setCountries([]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setCountryLoading(false);
    }
  }, []);

  useEffect(() => {
    if (mode === "edit") getCountries();
  }, [getCountries, mode]);

  useEffect(() => {
    if (state && state.mode) setMode(state.mode);
    else setMode("edit");
  }, [state]);

  useEffect(() => {
    if (tourId) getTour();
  }, [getTour, tourId]);

  useEffect(() => {
    if (mode === "edit") getBrokers();
  }, [getBrokers, mode]);

  const contextValues = {
    states: {
      mode,
      countries,
      countryLoading,
      loading,
      tour,
      brokerProps,
      dataLoading,
    },
    func: { onFinish },
    requests: {},
  };

  return (
    <ManagementBasicInfoDataContext.Provider value={contextValues}>
      {children}
    </ManagementBasicInfoDataContext.Provider>
  );
};

export default ManagementBasicInfoDataProvider;

export const useManagementBasicInfoData = () =>
  useContext(ManagementBasicInfoDataContext)!;
