import { Layout } from "antd";
import styled from "styled-components";

const { Header } = Layout;

export const HeaderContainer = styled(Header)<{ $isrtl: boolean }>`
  height: 100%;
  height: 102px;
  padding: 0;
  box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
  z-index: 100;
  .content {
    width: 100%;
    height: 102px;
    display: inline-flex;
    align-items: center;
    background-color: var(--color-Gray-Gray-50, #fafafa);
  }

  .hello {
    color: #8e8e8e;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.28px;
    margin-bottom: unset;
  }
  .title-dataEntry {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 !important;
  }
  .title {
    color: var(--secondary-grey-900, #273574);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 131.25% */
    letter-spacing: -0.64px;
    margin-bottom: unset;
  }
  .icons {
    color: #a3aed0;
    font-size: 40px;
  }
  .css-dev-only-do-not-override-5diy3c {
  }
  .HeaderLogoWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .HeaderLogoWrapper.center {
    justify-content: center;
  }
  .HeaderLogoWrapper.left {
    justify-content: end;
    padding-left: 24px;
  }
  .language-search {
    display: inline-flex;
    justify-content: ${($isrtl) => ($isrtl ? "start" : "end")};
    direction: ${($isrtl) => ($isrtl ? "ltr" : "rtl")};
    align-items: center;
  }
  .back {
    color: #f6a372;
    display: inline-flex;
    align-items: center;
    span {
      padding: 0 5px;
      font-weight: 700;
      font-size: 18px;
    }
  }
  .back-box {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
      color: #f06614;
      font-weight: 700;
      font-size: 18px;
    }
  }
`;
