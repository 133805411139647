import { FC } from "react";
import { Col, Row } from "antd";
import TourAddEditDataProvider from "./context/TourAddEditData";
import Filter from "./components/Filter";
import FilterContent from "./components/FilterContent";

const AddEditTour: FC = () => {
  return (
    <TourAddEditDataProvider>
      <Row align={"middle"} justify={"center"} gutter={[4, 24]}>
        <Col span={24}>
          <Filter />
        </Col>
        <Col span={23}>
          <FilterContent />
        </Col>
      </Row>
    </TourAddEditDataProvider>
  );
};

export default AddEditTour;
