import { FC, useEffect, useState } from "react";
import { Modal, Button, Row, Form, Select, DatePicker, Input } from "antd";
import useLanguage from "src/store/language";
import { TableUI } from "src/components/UiKit/table";
import { TouristsFieldNames } from "src/modules/addEditTour/models";
import { IObject } from "src/models/interfaces";
import { ITourist } from "src/services/Tour/models";
import { useTouristInfoRequest } from "../TouristInfoRequest";
import { IAddTouristModalProps } from "../interface";
import { AddTouristModalBodyContainer } from "../style";
import { convertDatePicker } from "src/helper";

const AddTouristModal: FC<IAddTouristModalProps> = ({
  showConfirm,
  setAddModal,
  tourist,
}) => {
  const [addLoading, setAddLoading] = useState<boolean>(false);

  const [addTouristForm] = Form.useForm();

  const { words } = useLanguage();
  const { requests } = useTouristInfoRequest();

  useEffect(() => {
    if (tourist) {
      addTouristForm.setFieldsValue({
        id: tourist.birthDate,
        [TouristsFieldNames.RoomType]: tourist.roomType,
        [TouristsFieldNames.LastName]: tourist.lastName,
        [TouristsFieldNames.FirstName]: tourist.firstName,
        [TouristsFieldNames.Sex]: tourist.sex,
        [TouristsFieldNames.PassportNO]: tourist.passportNO,
        [TouristsFieldNames.ExpireDate]: convertDatePicker(tourist.expireDate),
        [TouristsFieldNames.BirthDate]: convertDatePicker(tourist.birthDate),
      });
    }
  }, [addTouristForm, tourist]);

  const column = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: `${words.viewTour.roomType}`,
      dataIndex: TouristsFieldNames.RoomType,
      width: 100,
      render: () => {
        return (
          <Form.Item
            name={TouristsFieldNames.RoomType}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        );
      },
    },
    {
      title: `${words.viewTour.lastName}`,
      dataIndex: TouristsFieldNames.LastName,
      render: () => {
        return (
          <Form.Item
            name={TouristsFieldNames.LastName}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        );
      },
    },
    {
      title: `${words.viewTour.firstName}`,
      dataIndex: TouristsFieldNames.FirstName,
      render: () => {
        return (
          <Form.Item
            name={TouristsFieldNames.FirstName}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        );
      },
    },
    {
      title: `${words.viewTour.sex}`,
      dataIndex: TouristsFieldNames.Sex,
      width: 50,
      render: () => {
        return (
          <Form.Item
            name={TouristsFieldNames.Sex}
            rules={[{ required: true, message: words.global.fieldRequired }]}
            initialValue={1}
          >
            <Select
              options={[
                { label: "M", value: 1 },
                { label: "F", value: 2 },
              ]}
            />
          </Form.Item>
        );
      },
    },
    {
      title: `${words.viewTour.passportNO}`,
      dataIndex: TouristsFieldNames.PassportNO,
      render: () => {
        return (
          <Form.Item
            name={TouristsFieldNames.PassportNO}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        );
      },
    },
    {
      title: `${words.viewTour.expireDate}`,
      dataIndex: TouristsFieldNames.ExpireDate,
      render: () => {
        return (
          <Form.Item
            name={TouristsFieldNames.ExpireDate}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <DatePicker />
          </Form.Item>
        );
      },
    },
    {
      title: `${words.viewTour.birthDate}`,
      dataIndex: TouristsFieldNames.BirthDate,
      render: () => {
        return (
          <Form.Item
            name={TouristsFieldNames.BirthDate}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <DatePicker />
          </Form.Item>
        );
      },
    },
  ];

  const handleCancel = () => {
    setAddModal(false);
    addTouristForm.resetFields();
  };

  const onOk = (values: IObject) => {
    setAddLoading(true);

    requests
      .addTouristRequest(values as ITourist, tourist ? tourist.id : 0)
      .finally(() => {
        setAddLoading(false);
        if (!tourist) addTouristForm.resetFields();
      });
  };

  return (
    <Modal
      title={words.addEditTour.addNewRow}
      open={showConfirm}
      onOk={onOk}
      onCancel={handleCancel}
      footer={false}
      width={"90%"}
    >
      <AddTouristModalBodyContainer>
        <Form form={addTouristForm} onFinish={onOk}>
          <TableUI
            scroll={{ x: 900 }}
            dataSource={[tourist ? tourist : { id: "1" }]}
            columns={column}
            pagination={false}
            tableType="secondary"
            bordered={false}
          />

          <Row gutter={[12, 12]}>
            <Button
              htmlType="submit"
              loading={addLoading}
              type="text"
              icon={<span className="material-icons">done</span>}
              className="btn-ok"
            >
              {words.global.save}
            </Button>
            <Button type="text" onClick={handleCancel} className="btn-cancel">
              {words.addEditTour.cancel}
            </Button>
          </Row>
        </Form>
      </AddTouristModalBodyContainer>
    </Modal>
  );
};

export default AddTouristModal;
