import { FC } from "react";
import { Button, Col, Divider, Form, Row } from "antd";
import InputUiKit from "src/components/UiKit/Input";
import useLanguage from "src/store/language";
import SubmitButton from "src/components/UiKit/SubmitButton";
import InputNumberUiKit from "src/components/UiKit/inputNumber";
import TextAreaUiKit from "src/components/UiKit/textArea";
import { useRoomsData } from "../../context/RoomsData";
import { useFormHasChanged } from "../../hooks/useFormHasChanged";

const AddRoom: FC = () => {
  const { words } = useLanguage();
  const {
    states: { selectedRoom, addRoomForm, loading },
    func: { onFinish },
    dispatches: { setSelectedRoom },
  } = useRoomsData();

  const handleCancelForm = () => {
    selectedRoom ? setSelectedRoom(undefined) : addRoomForm.resetFields();
  };

  const canSubmit = useFormHasChanged();

  return (
    <Form
      form={addRoomForm}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      name="place-form"
    >
      <p className="title">{words.rooms.addFormTitle}</p>
      <Row gutter={[8, 16]} className="add-form-row">
        <Col xs={24} md={8}>
          <Form.Item
            name={"name"}
            label={words.rooms.nameLabel}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit placeholder={words.global.insert} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name={"nameForMail"}
            label={words.rooms.nameMailLabel}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit placeholder={words.global.insert} />
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item
            name={"bedCount"}
            label={words.rooms.bedCountLabel}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputNumberUiKit
              controls={false}
              placeholder={words.rooms.bedCountPlaceholder}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name={"description"} label={words.rooms.descriptionLabel}>
            <TextAreaUiKit placeholder={words.rooms.descriptionLabel} />
          </Form.Item>
        </Col>
        <Col span={24} className="footer">
          <div className="footer-box">
            <Button
              type="text"
              className="bg-[#F5F5F5] text-[#8E8E8E] ml-2 round-lg w-[85px]"
              onClick={handleCancelForm}
            >
              {selectedRoom
                ? words.basicInfo.countryCancel
                : words.basicInfo.buttonClear}
            </Button>
            <SubmitButton
              form={addRoomForm}
              loading={loading}
              canSubmit={canSubmit}
            >
              {selectedRoom
                ? words.basicInfo.countrySubmit
                : words.basicInfo.buttonAdd}
            </SubmitButton>
          </div>
        </Col>
        <Divider className="bg-[#DADEEC]" />
      </Row>
    </Form>
  );
};

export default AddRoom;
