import InsuranceFile from "src/modules/managementInsuranceAttachment";
import InsuranceAttachmentDataProvider from "src/modules/managementInsuranceAttachment/context";

const ManagementInsuranceAttachmentPage = () => (
  <InsuranceAttachmentDataProvider>
    <InsuranceFile />
  </InsuranceAttachmentDataProvider>
);

export default ManagementInsuranceAttachmentPage;
