import { DatePicker } from "antd";
import styled from "styled-components";

const { RangePicker } = DatePicker;

export const RangePickerStyled = styled(RangePicker)`
  background-color: transparent !important;
  border: none !important;
  margin-top: 0 !important;
  height: 100% !important;
  direction: rtl !important;

  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
    direction: rtl !important;
  }
  .ant-picker {
    width: 100%;
  }
  .ant-picker:focus {
    border: 0 !important;
    box-shadow: none !important;
    outline: none !important;
  }
  .ant-picker:-webkit-autofill,
  .ant-picker:-webkit-autofill:hover,
  .ant-picker:-webkit-autofill:focus,
  .ant-picker:-webkit-autofill:active {
    -webkit-box-shadow: none !important;
    -webkit-border: 0 !important;
    -webkit-outline: none !important;
  }
`;
