import React, { useCallback, useEffect, useRef, useState } from "react";
import { Packer } from "docx";
import { saveAs } from "file-saver";
import { createNajaMail } from "./createWord";
import {
  IGetExportMigration,
  IMigrationRouteDetails,
} from "src/services/ExportData/models";
import { NajaMailStyle, PageFooter } from "./styles";
import { Col, Flex, Row, Spin, Table, TableColumnsType } from "antd";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import moment from "moment-jalaali";
import { useParams } from "react-router-dom";
import { ExportDataService } from "src/services/ExportData/ExportData.service";
import { uniqueId } from "lodash";
import { carPluckAlphabetArray } from "src/modules/managementDriver/constants/selectOptions";
import html2pdf from "html2pdf.js";
import { DownloadPdfIcon } from "../DownladPdfIcon";
interface MyReactComponentProps {}

export const ExportToWord: React.FC<MyReactComponentProps> = () => {
  const [docxContent, setDocxContent] = useState<Blob | null>(null);
  const mailDate = moment().format("jYYYY/jMM/jDD");
  const [data, setData] = useState<IGetExportMigration>();
  const [loading, setLoading] = useState<boolean>(false);
  const { tourId } = useParams();
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = useCallback(async () => {
    if (!tourId) return;
    try {
      setLoading(true);
      const { GetExportMigration } = new ExportDataService();
      const res = await GetExportMigration(tourId);
      if (res && res.status === 200 && res.data) {
        setData(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [tourId]);

  const generateDocx = useCallback(() => {
    if (!data) return;
    const doc = createNajaMail(data);

    Packer.toBlob(doc).then((blob: Blob) => {
      setDocxContent(blob);
    });
  }, [data]);

  const downloadDocx = () => {
    if (docxContent) {
      saveAs(docxContent, "NajaMail.docx");
    }
  };
  const componentRef = useRef<HTMLDivElement>(null);

  const print = useReactToPrint({
    content: () => componentRef.current,
    print: async (printIframe: HTMLIFrameElement) => {
      try {
        const document = printIframe.contentDocument;
        if (document) {
          const html = document.getElementsByTagName("html")[0];
          const htmlRect = html.getBoundingClientRect();
          const pdfWith = 200;
          const pdfCurrentHeight = (pdfWith * htmlRect.height) / htmlRect.width;
          const pageCount = htmlRect.height / pdfWith;
          const roundedPageCount =
            Math.round(pageCount) < pageCount
              ? Math.ceil(pageCount)
              : Math.round(pageCount);
          const pdfHeight = pdfCurrentHeight * roundedPageCount;
          const opt = {
            jsPDF: {
              format: [pdfWith, pdfHeight],
            },
            image: { type: "jpeg", quality: 1 },
            html2canvas: { scale: 3 },
            unit: "px",
          };
          await html2pdf().from(html).set(opt).save("NajaMail.pdf");
        }
      } catch (err) {
        console.log(err);
      } finally {
        setDownloadLoading(false);
      }
    },
  });
  const handlePrint = async () => {
    setDownloadLoading(true);

    print();
  };
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);
  const columns: TableColumnsType<IMigrationRouteDetails> = [
    {
      key: "key",
      title: "#",
      width: 24,
      align: "center",
      className: "columns",
      render: (text, record, index) => index + 1,
    },
    {
      dataIndex: "arrivalDate",
      key: "arrivalDate",
      title: "از تاریخ",
      width: 47.5,
      align: "center",
      className: "columns",
    },
    {
      dataIndex: "departureDate",
      key: "departureDate",
      title: "تا تاریخ",
      width: 47.5,
      align: "center",
      className: "columns",
    },
    {
      dataIndex: "provinceName",
      key: "provinceName",
      title: "استان",
      width: 40,
      align: "center",
      className: "columns",
    },
    {
      dataIndex: "cityName",
      key: "cityName",
      title: "شهر",
      width: 51.5,
      align: "center",
      className: "columns",
    },
    {
      dataIndex: "hotelName",
      key: "hotelName",
      title: "محل اقامت",
      width: 62,
      align: "center",
      className: "columns",
    },
    {
      dataIndex: "placeOfInterests",
      key: "placeOfInterests",
      title: "مکان های دیدنی",
      width: 161,
      align: "center",
      className: "columns ",
    },
  ];
  const tableData: IMigrationRouteDetails[] | undefined =
    data?.routeDetails.map((item) => {
      const newItem = { ...item };
      newItem.arrivalDate = item.arrivalDate
        ? moment(item.arrivalDate).format("jYYYY/jMM/jDD")
        : "";
      newItem.departureDate = item.departureDate
        ? moment(item.departureDate).format("jYYYY/jMM/jDD")
        : "";

      return newItem;
    });
  useEffect(() => {
    generateDocx();
  }, [generateDocx]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  if (loading) {
    return <Spin fullscreen />;
  }
  return (
    <Row justify={"center"}>
      <ReactToPrint content={reactToPrintContent} removeAfterPrint />

      <Col span={10}>
        <NajaMailStyle
          ref={componentRef}
          gutter={[0, 12.9]}
          className="relative "
        >
          <Col span={24} className="absolute z-10 left-0 top-3">
            <Flex justify="flex-end" className="px-[16.12px]" gap={10}>
              <span className="header-label">تاریخ :</span>
              <span className="header-value">{mailDate}</span>
            </Flex>
            <Flex justify="flex-end" className="px-[16.12px]" gap={10}>
              <span className="header-label">شماره نامه :</span>
              <span className="header-value">{data?.tourCode}</span>
            </Flex>
          </Col>
          <Col span={24}>
            <img
              src="/assets/images/sarbargHeader.png"
              alt="sarbarg"
              // className="absolute top-0 left-0 right-0 z-2"
              width={"100%"}
            />
          </Col>
          <Col span={24} className="content">
            <Row className="px-[12.9px]" gutter={[0, 24.19]}>
              <Col span={24} className=" mail">
                <Flex vertical gap={4.8}>
                  <h3 className="mail-title">
                    ریاست محترم پلیس مهاجرت و گذرنامه ناجا-حراست ویژه
                  </h3>
                  <div className="mail-description">باسلام و احترام</div>
                  <div className="mail-description">
                    برنامه سفر و فهرست اسامی مسافرین به شرح زیر جهت صدور کد ناجا
                    ارسال می گردد.
                  </div>
                  <div className="mail-description">
                    <span>گفتنی است صحت مشخصات بر عهده این</span>{" "}
                    <span className="mail-title">دفتر/شرکت</span>{" "}
                    <span>می باشد.</span>
                  </div>
                </Flex>
              </Col>
              <Col span={24}>
                <Flex justify="space-between" className="tourInfo">
                  <div>نام آژانس : میثاق گشت صدرا</div>
                  <div>
                    <span>ملیت جهان گردان : </span>
                    <span>{data?.countryName}</span>
                  </div>
                  <div>
                    <span>تعداد جهان گردان :</span>
                    <span>{data?.touristCound} نفر</span>
                  </div>
                  <div>
                    <span>مدت تور : {data?.tourDays} روز</span>
                  </div>
                </Flex>
              </Col>
              <Col span={24} dir="rtl">
                <Row>
                  <Col span={12}>
                    <div className="infoTableHeader">مشخصات راهنما </div>
                  </Col>
                  <Col span={12}>
                    <div className="infoTableHeader">مشخصات راننده و خودرو</div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} className="infoTableBody">
                    <Row gutter={[8, 8]}>
                      <Col span={12}>
                        نام و نام خانوادگی : {data?.leaderFullName}
                      </Col>
                      <Col span={12}>نام پدر : {data?.leaderFatherName}</Col>
                      <Col span={12}>کد ملی : {data?.leaderNationalCode} </Col>
                      <Col span={12}>ش.ش : {data?.leaderIdentityCode} </Col>
                      <Col span={12}>
                        شماره تماس : {data?.leaderPhoneNumber}
                      </Col>
                      <Col span={12}>
                        ت.ت :{" "}
                        {data?.leaderBirthDate
                          ? moment(data?.leaderBirthDate).format(
                              "jYYYY/jMM/jDD"
                            )
                          : ""}
                      </Col>
                      <Col span={24}>
                        شماره کارت راهنما : {data?.leaderCardNumber}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12} className="infoTableBody">
                    <Row gutter={[8, 8]}>
                      <Col span={12}>
                        نام و نام خانوادگی : {data?.driverFullName}
                      </Col>
                      <Col span={12}>نام پدر : {data?.driverFatherName}</Col>
                      <Col span={12}>کد ملی : {data?.driverNationalCode}</Col>
                      <Col span={12}>ش.ش : {data?.driverIdentityCode}</Col>
                      <Col span={12}>نوع خودرو : {data?.carName}</Col>
                      <Col span={12}>
                        شماره تماس : {data?.driverPhoneNumber || ""}
                      </Col>
                      <Col span={24}>
                        شماره خودرو :{" "}
                        {
                          <>
                            <span>ایران</span>
                            <span>{data?.carTag.split("-")[3]}</span>
                            <span>_</span>
                            <span>{data?.carTag.split("-")[2]}</span>
                            <span>
                              {
                                carPluckAlphabetArray[
                                  Number(data?.carTag.split("-")[1])
                                ]
                              }
                            </span>
                            <span>{data?.carTag.split("-")[0]}</span>
                          </>
                        }
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  columns={columns}
                  dataSource={tableData}
                  scroll={{ x: "auto" }}
                  pagination={false}
                  className="routesTable"
                  rowKey={() => uniqueId()}
                />
              </Col>
              <Col span={24}>
                <Flex className="footer">
                  <div>با تشکر</div>
                  <div>مدیر عامل</div>
                </Flex>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <img
              src="/assets/images/sarbargFooter.png"
              alt="sarbarg"
              // className="absolute bottom-0 left-0 right-0 z-2"
              width={"100%"}
            />
          </Col>
        </NajaMailStyle>
      </Col>
      <Col span={24} className="pb-[32px]">
        <PageFooter className="pageFooter" justify="flex-end">
          <DownloadPdfIcon
            handlePrint={handlePrint}
            loading={downloadLoading}
          />
          <img
            src="/assets/images/docDownload.svg"
            alt=""
            onClick={downloadDocx}
          />
        </PageFooter>
      </Col>
    </Row>
  );
};
