import { FC } from "react";
import { ViewNotificationContainer } from "../../style";
import { ViewMiddleHeaderNotification } from "./viewMiddleHeader";
import { TableViewNotification } from "./tableView";
import { Col, Row } from "antd";

export const ViewNotification: FC = () => {
  return (
    <>
      <ViewMiddleHeaderNotification />
      <Row justify={"center"}>
        <Col span={14}>
          <ViewNotificationContainer>
            <TableViewNotification />
          </ViewNotificationContainer>
        </Col>
      </Row>
    </>
  );
};
