import { FC } from "react";
import FormControlUiKit from "../FormControl";
import { TimePickerProps } from "antd/lib";
import { TimePickerStyled } from "./style";

interface IProps {
  label?: string;
  value?: any;
}
export type DatePickerLocalizedProps = TimePickerProps & IProps;

const TimePickerUi: FC<DatePickerLocalizedProps> = ({
  label,
  value,
  ...props
}) => {
  return (
    <FormControlUiKit
      childrenContainerClassName="!justify-center"
      label={label}
      height={"51px"}
    >
      <TimePickerStyled
        format="YYYY/MM/DD"
        value={value}
        autoComplete="off"
        {...props}
      />
    </FormControlUiKit>
  );
};

export default TimePickerUi;
