import { FC } from "react";
import { ManagementTour } from "src/modules/managementTour";
import { ManagementTourProvider } from "src/modules/managementTour/context";

export const ManagementTourPage: FC = () => {
  return (
    <ManagementTourProvider>
      <ManagementTour />
    </ManagementTourProvider>
  );
};
