import { Form } from "antd";
import _ from "lodash";
import { useHotelsData } from "src/modules/hotels/context/HotelsData";
import { HotelsViewMode } from "src/modules/hotels/models/enums";

export const useFormHasChanged = () => {
  const {
    states: { selectedHotel, viewMode, addHotelForm },
    dispatches: { setHotelInfoChanged },
  } = useHotelsData();

  const allValues = Form.useWatch([], addHotelForm);

  const changed = _.isEqual(
    _.omit(allValues, ["id", "cityName"]),
    _.omit(selectedHotel, ["id", "cityName"])
  );
  setHotelInfoChanged(!changed);
  return viewMode === HotelsViewMode.Edit ? changed : false;
};
