import { Col, Flex, Row } from "antd";
import styled from "styled-components";

export const FlightListContainer = styled(Row)`
  display: flex;
  padding: 32px 32px 24px 32px;
  flex-direction: column;
  gap: 24px;
  border-radius: 20px;
  background: var(--color-Base-Color-White, #fff);

  /* 3 */
  box-shadow: 2px 6px 16px 0px rgba(0, 0, 0, 0.18);
  .ant-input-outlined:hover {
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    box-shadow: unset;
  }
  .ant-input-outlined:focus-visible {
    border: 1px solid #f5f5f5;
    background: #f5f5f5;
    box-shadow: unset;
  }
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #4b4b4b !important;
    border: unset !important;
  }
`;
export const TableWrapper = styled(Col)`
  .title {
    direction: rtl !important;
    text-align: center;
    width: 100%;
  }
  table {
    thead {
      tr {
        th {
          color: var(--color-Light-Mode-Text-Primary-text, #1f1f1f);

          /* Fa/Title/T2.14.Bold */
          font-family: "Vazir FD";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          border: 1px solid var(--color-Gray-Gray-100, #f5f5f5) !important;
          background: #e1e1e1 !important;
        }
      }
    }
  }
`;
export const PageFooter = styled(Flex)`
  margin-top: 32px;
  padding: 32px 24px 16px 24px;
  border-top: 1px solid #dadeec;
  img {
    cursor: pointer;
    user-select: none;
  }
`;
