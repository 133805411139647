import { FC, useEffect, useState } from "react";
import { Button, Col, Divider, Form, Row, Select } from "antd";
import SubmitButton from "src/components/UiKit/SubmitButton";
import { SelectUiKit } from "src/components/UiKit/select";
import useLanguage from "src/store/language";
import DatePickerUi from "src/components/UiKit/DatePicker";
import InputUiKit from "src/components/UiKit/Input";
import TimePickerUi from "src/components/UiKit/TimePicker";
import { useManagementRouteAddEditData } from "../context";
import { useManagementRouteData } from "../../../context";

const AddManagementRoute: FC = () => {
  const [startDateAd, setStartDateAd] = useState<Date | undefined | null>(null);
  const [departureDateAd, setDepartureDateAd] = useState<
    Date | undefined | null
  >(null);
  const {
    states: { managementRouteForm, selectedRote, loading },
    func: { onFinish },
    dispatches: { setSelectedRoute },
  } = useManagementRouteAddEditData();

  const {
    states: { hotel, city },
    requests: { getHotelsByCity },
  } = useManagementRouteData();

  const { words } = useLanguage();

  const formValues = Form.useWatch([], managementRouteForm);

  const handleCancelForm = () => {
    if (selectedRote) setSelectedRoute(undefined);
    else {
      managementRouteForm.resetFields();
      setStartDateAd(null);
    }
  };

  const handleChangeCity = (value: string) => {
    if (selectedRote && Number(value) === selectedRote?.cityId)
      managementRouteForm.setFieldValue("hotelId", selectedRote.hotelId);
    else managementRouteForm.setFieldValue("hotelId", null);
    if (value && value !== "") getHotelsByCity(Number(value));
  };

  useEffect(() => {
    if (selectedRote) {
      setStartDateAd(new Date(selectedRote.arraivalDate));
      setDepartureDateAd(new Date(selectedRote.departureDate));
    } else {
      setStartDateAd(null);
      setDepartureDateAd(null);
    }
  }, [managementRouteForm, selectedRote]);

  useEffect(() => {
    if (formValues && formValues.arraivalDate)
      setStartDateAd(new Date(formValues.arraivalDate));
    else setStartDateAd(null);
    if (formValues && formValues.departureDate)
      setDepartureDateAd(new Date(formValues.departureDate));
    else setDepartureDateAd(null);
  }, [formValues, managementRouteForm]);

  return (
    <Form
      form={managementRouteForm}
      onFinish={onFinish}
      onFinishFailed={() => alert("error")}
      layout="vertical"
      autoComplete="off"
      name="hotel-form"
    >
      <p className="add-hotel-form-title !font-bold">
        {words.managementRoute.formTitle}
      </p>
      <Row gutter={[8, 16]} className="mt-[30px]">
        <Col xs={24} md={8}>
          <Form.Item
            name={"cityId"}
            label={words.managementRoute.city}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <SelectUiKit
              showSearch
              loading={city.loading}
              placeholder={words.managementHotel.select}
              onChange={handleChangeCity}
            >
              {city.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </SelectUiKit>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name={"inlineCities"}
            label={words.managementRoute.intermediateCities}
          >
            <InputUiKit
              placeholder={words.managementRoute.intermediateCitiesPlaceHolder}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name={"groupNum"}
            label={words.managementRoute.group}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <SelectUiKit
              showSearch
              loading={city.loading}
              placeholder={words.managementRoute.select}
            >
              {Array.from(
                Array(21),
                (e, i) =>
                  i > 0 && (
                    <Select.Option
                      key={i}
                      value={i}
                      style={{ fontSize: "12px" }}
                    >
                      {i}
                    </Select.Option>
                  )
              )}
            </SelectUiKit>
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item
            name={"flightTime"}
            label={words.managementRoute.labelTime}
          >
            <TimePickerUi
              format="HH:mm"
              showNow={false}
              needConfirm={false}
              placeholder={words.managementRoute.select}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name={"arraivalDate"}
            label={words.managementRoute.labelJalali}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <DatePickerUi placeholder={words.managementRoute.select} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item name={"arraival"} label={words.managementRoute.labelAd}>
            <div className="calculated-date">
              {startDateAd ? (
                <span>
                  {new Date(startDateAd).toLocaleString("en-EN", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </span>
              ) : (
                words.managementRoute.calculated
              )}
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item name={"hotelId"} label={words.managementRoute.hotel}>
            <SelectUiKit
              showSearch
              allowClear
              loading={hotel.loading}
              disabled={formValues && formValues.cityId ? false : true}
              placeholder={words.managementRoute.select}
            >
              {hotel.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </SelectUiKit>
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item
            name={"departureDate"}
            label={words.managementRoute.departureLabelJalali}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <DatePickerUi placeholder={words.managementRoute.select} />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name={"departure"}
            label={words.managementRoute.departureLabelAd}
          >
            <div className="calculated-date">
              {departureDateAd ? (
                <span>
                  {new Date(departureDateAd).toLocaleString("en-EN", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })}
                </span>
              ) : (
                words.managementRoute.calculated
              )}
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} md={24}>
          <Form.Item name={"detail"} label={words.managementRoute.detailLabel}>
            <InputUiKit placeholder={words.managementRoute.detailPlaceholder} />
          </Form.Item>
        </Col>
        <Col span={24} className="footer">
          <div className="footer-box">
            <Button
              type="text"
              className="clean-btn bg-[#F5F5F5] text-[#8E8E8E] ml-2 rounded-lg w-[85px]"
              onClick={handleCancelForm}
            >
              {selectedRote
                ? words.basicInfo.countryCancel
                : words.basicInfo.buttonClear}
            </Button>
            <SubmitButton
              form={managementRouteForm}
              loading={loading}
              canSubmit={false}
            >
              {selectedRote
                ? words.basicInfo.countrySubmit
                : words.basicInfo.buttonAdd}
            </SubmitButton>
          </div>
        </Col>
        <Divider className="bg-[#DADEEC]" />
      </Row>
    </Form>
  );
};

export default AddManagementRoute;
