import { FC } from "react";
import { Col } from "antd";
import AddCity from "./components/AddCity";
import CityTable from "./components/CityTable";
import { CityContainer } from "./styles";
import { CityProvider } from "./context";
import DataEntryHeader from "src/components/dataEntryHeader";
import useLanguage from "src/store/language";

export const City: FC = () => {
  const { words } = useLanguage();

  return (
    <CityProvider>
      <DataEntryHeader title={words.dataEntry.header.city} />
      <CityContainer justify={"center"} gutter={[0, 24]}>
        <Col xs={24} md={18} lg={16} className="content">
          <AddCity />
          <CityTable />
        </Col>
      </CityContainer>
    </CityProvider>
  );
};
