import { Flex } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { useManagementBasicInfoData } from "../context";

const ViewBasicInfo: FC = () => {
  const { words } = useLanguage();
  const {
    states: { tour },
  } = useManagementBasicInfoData();

  return (
    <>
      <p className="title">{words.addTour.viewTitle}</p>
      <section className="view-details">
        <Flex className="w-full">
          <Flex vertical className="w-[50%]">
            <Flex>
              <div className="detail-title">{words.addTour.tourCodeLabel}:</div>
              <div>{tour?.tourCode || "-"}</div>
            </Flex>
            <Flex className="my-[15px]">
              <div className="detail-title">{words.addTour.touristNumber}:</div>
              <div>{tour?.touristCount || 0} نفر</div>
            </Flex>
            <Flex>
              <div className="detail-title">{words.addTour.origin}:</div>
              <div>{tour?.origin || "-"}</div>
            </Flex>
          </Flex>
          <Flex vertical className="w-50%">
            <Flex>
              <div className="detail-title">{words.addTour.nationLabel}:</div>
              <div>{tour?.countryName}</div>
            </Flex>
            <Flex className="my-[15px]">
              <div className="detail-title">{words.addTour.brokerLabel}:</div>
              <div>{tour?.brokerName || "-"}</div>
            </Flex>
            <Flex>
              <div className="detail-title">{words.addTour.destination}:</div>
              <div>{tour?.destination || "-"}</div>
            </Flex>
          </Flex>
        </Flex>
      </section>
    </>
  );
};

export default ViewBasicInfo;
