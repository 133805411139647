import { FC, useState } from "react";
import { Button, Col, Divider, Form, Row, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import SubmitButton from "src/components/UiKit/SubmitButton";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import DatePickerUi from "src/components/UiKit/DatePicker";
import { SelectUiKit } from "src/components/UiKit/select";
import { Gender } from "src/models/enums/gender";
import { useManagementTourBack } from "src/hook/useManagementTourBack";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";
import { useManagementTranslatorData } from "../context";
import { useFormHasChanged } from "../useFormHasChanged";

const AddEditTranslator: FC = () => {
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const { words } = useLanguage();

  const {
    states: { loading, mode, translatorForm, deleteLoading },
    func: { onFinish },
    requests: { onDeleteTranslator },
  } = useManagementTranslatorData();

  const canSubmit = useFormHasChanged();
  const { backHandler } = useManagementTourBack();

  const handleCancel = () => {
    if (mode === "add") {
      translatorForm.resetFields();
    } else backHandler();
  };

  return (
    <>
      <p className="title">
        {mode === "add"
          ? words.managementTranslator.addFormTitle
          : words.managementTranslator.editFormTitle}
      </p>
      <Form
        onFinish={onFinish}
        form={translatorForm}
        layout="vertical"
        preserve={false}
        className="mt-[40px]"
      >
        <Row gutter={[24, 12]}>
          <Col xs={24} md={8}>
            <FormItem
              name={"firstName"}
              label={words.managementTranslator.nameLabel}
              rules={[{ required: true, message: words.global.fieldRequired }]}
            >
              <InputUiKit
                placeholder={words.managementTranslator.namePlaceHolder}
              />
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <FormItem
              name={"lastName"}
              label={words.managementTranslator.familyNameLabel}
              rules={[{ required: true, message: words.global.fieldRequired }]}
            >
              <InputUiKit
                placeholder={words.managementTranslator.familyNamePlaceholder}
              />
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <FormItem
              name={"gender"}
              label={words.managementTranslator.genderLabel}
              rules={[{ required: true, message: words.global.fieldRequired }]}
            >
              <SelectUiKit placeholder={words.managementTranslator.select}>
                <Select.Option value={Gender.Male}>
                  {words.global.male}
                </Select.Option>
                <Select.Option value={Gender.Female}>
                  {words.global.femail}
                </Select.Option>
              </SelectUiKit>
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <FormItem
              name={"birthDate"}
              label={words.managementTranslator.dateLabel}
              rules={[{ required: true, message: words.global.fieldRequired }]}
            >
              <DatePickerUi
                placeholder={words.managementTranslator.datePlaceholder}
              />
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <FormItem
              name={"nationalCode"}
              label={words.managementTranslator.nationalCodeLabel}
              rules={[{ required: true, message: words.global.fieldRequired }]}
            >
              <InputUiKit
                min={11}
                max={11}
                placeholder={words.managementTranslator.nationalCodePlaceholder}
                numbermode
              />
            </FormItem>
          </Col>

          <Col xs={24} md={8}>
            <FormItem
              name={"phoneNumber"}
              label={words.managementTranslator.phoneLabel}
              rules={[{ required: true, message: words.global.fieldRequired }]}
            >
              <InputUiKit
                placeholder={words.managementTranslator.phonePlaceHolder}
              />
            </FormItem>
          </Col>

          <Divider className="bg-[#EEEEEE]" />
          <Col span={24} className="flex justify-between">
            <div>
              {mode === "edit" ? (
                <Button
                  type="default"
                  className="delete-btn"
                  onClick={() => setShowDelete(true)}
                >
                  {words.global.delete}
                </Button>
              ) : null}
            </div>
            <div className="flex justify-end">
              <Button
                type="text"
                className="bg-[#F5F5F5] text-[#8E8E8E] ml-2 rounded-lg w-[85px]"
                onClick={handleCancel}
              >
                {mode === "add"
                  ? words.managementTranslator.clear
                  : words.managementTranslator.cancel}
              </Button>
              <SubmitButton
                form={translatorForm}
                loading={loading}
                canSubmit={canSubmit}
              >
                {mode === "add"
                  ? words.global.add
                  : words.managementTranslator.editBtn}
              </SubmitButton>
            </div>
          </Col>
        </Row>
      </Form>

      <DeleteConfirmModal
        showConfirm={showDelete}
        onCancel={() => setShowDelete(false)}
        onOk={() => onDeleteTranslator().finally(() => setShowDelete(false))}
        title={words.managementTranslator.deleteTitle}
        okTitle={words.global.delete}
        loading={deleteLoading}
      />
    </>
  );
};

export default AddEditTranslator;
