import { FC } from "react";
import { Col, Spin } from "antd";
import { Link } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import { Icons } from "src/components/customIcons";
import useLanguage from "src/store/language";
import { TourStates } from "src/models/enums/TourStates";
import { NewRequestCard } from "./requestCard";
import { NewRequestStyles } from "./styles";
import { useDashboardData } from "../context";

export const NewRequests: FC = () => {
  const { words } = useLanguage();
  const {
    states: { allTour, dataLoading },
  } = useDashboardData();

  return (
    <Col xs={24} md={12}>
      <NewRequestStyles>
        <Col span={24} className="px-2.5">
          <Icons name={"circle"} />
          <span className="title">{words.dashboard.newRequest}</span>
        </Col>
        {dataLoading ? (
          <div className="text-center w-full h-full">
            <Spin size="large" />
          </div>
        ) : (
          <>
            {allTour && allTour?.pendingCount > 0 && (
              <>
                <Col span={24} className="ps-[24px] tours-card-col">
                  <div className="flex gap-[16px] overflow-auto pb-[24px]">
                    {allTour && allTour?.pendingCount > 0 ? (
                      allTour.pendingTours?.map((item, index) => (
                        <NewRequestCard tour={item} key={index} />
                      ))
                    ) : (
                      <p className="w-full h-[237px] flex justify-center items-center text-[#fff] text-2xl font-bold">
                        {words.dashboard.newToursEmptyMessage}
                      </p>
                    )}
                  </div>
                </Col>

                {allTour ? (
                  <Col
                    span={24}
                    className="flex justify-end items-center pe-[24px] text-[#FFFFFF] pb-[24px]"
                  >
                    <Link
                      className="text-[#ffffff] hover:text-[#ffffff]"
                      to={ROUTES.tourTable.concat(
                        "?".concat("TourStates=" + TourStates.Waiting)
                      )}
                      state={{ tourState: TourStates.Waiting }}
                    >
                      {words.global.seeAll.concat(" (")}
                      {allTour && allTour?.pendingCount
                        ? allTour?.pendingCount
                        : 0}{" "}
                      {words.global.tour}
                      {" )"}
                    </Link>
                  </Col>
                ) : null}
              </>
            )}
          </>
        )}
      </NewRequestStyles>
    </Col>
  );
};
