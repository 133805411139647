import { Row } from "antd";
import styled from "styled-components";

export const AssignActionToRoleContainer = styled(Row)`
  .header {
    width: 100%;
    padding: 16px 32px;
    background-color: #FFFAF8;
    height: 92px;
    border-radius: 16px;
    display: inline-flex;
    align-items: center;
  }
  .content {
    border-radius: 16px;
    background: var(--0, #fff);

    /* 2 */
    box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
    padding: 0 24px 24px 24px;
  }
  .title {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
  }
  .footer {
    border-top: 1px solid #dadeec;
    padding-top: 32px;
    padding-bottom: 16px;
  }
`;
