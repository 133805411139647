import { FC } from "react";
import { Col } from "antd";
import InsuranceInfo from "./form/InsuranceInfo";
import { InsuranceAddEditTabsContainer } from "./style";
import AddEditInsuranceDataProvider from "./context";
import InsuranceTable from "./table/InsuranceTable";
import { useManagementInsuranceData } from "../../context";

const InsuranceAddEdit: FC = () => {
  const {
    states: { mode },
  } = useManagementInsuranceData();

  return (
    <AddEditInsuranceDataProvider>
      <>
        {(mode === "add" || mode === "edit") && (
          <InsuranceAddEditTabsContainer>
            <Col span={24}>
              <InsuranceInfo />
            </Col>
          </InsuranceAddEditTabsContainer>
        )}
        <InsuranceTable />
      </>
    </AddEditInsuranceDataProvider>
  );
};

export default InsuranceAddEdit;
