import styled from "styled-components";

export const TableHeaderContainer = styled.div`
  width: 100%;
  height: 80px;
  display: inline-flex;
  justify-content: end;
  align-items: center;
  padding: 24px;
  border-bottom: solid 1px #fde8dc;
  margin-bottom: 30px;

  .filter-title {
    color: var(--color-Primary-Primary-700, #f06614);
    /* Fa/H2/Medium */
    font-family: "Vazir FD";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const TabStyle = styled.div`
  .ant-tabs-tab {
    margin-left: 12px !important;
    color: var(--text-2, #a3aed0);
    font-size: 12px;
    font-weight: 500;
  }

  .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
    color: var(--color-Light-Mode-Disable-Disable-1, #cacaca);
    font-family: "Vazir FD";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--color-Primary-Primary-700, #f06614);
    font-family: "Vazir FD";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .tab-content {
    margin-top: 15px;
    width: 419px;
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active::before {
    color: var(--color-Primary-Primary-700, #f06614);
  }
  .tour-table-filter-tab-des,
  .date-label {
    color: var(--color-Light-Mode-Text-Title, #233069);
    text-align: right;
    font-family: "Vazir FD";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .date-label {
    margin: 0 10px 0 0;
  }
  .country-tab {
    height: 234px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 6px;
    border: 1px solid var(--color-Gray-Gray-100, #f5f5f5);
    padding: 0px 12px 0px 2px;
    margin-top: 32px;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 2px solid #f06614 !important;
    z-index: 2;
  }
`;
