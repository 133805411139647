import ManagementInsurance from "src/modules/managementInsurance";
import ManagementInsuranceDataProvider from "src/modules/managementInsurance/context";

const ManagementInsurancePage = () => (
  <ManagementInsuranceDataProvider>
    <ManagementInsurance />
  </ManagementInsuranceDataProvider>
);

export default ManagementInsurancePage;
