import { FC } from "react";
import { Col, Row } from "antd";
import { useDomesticFlight } from "./context";
import { DomesticFlightTable } from "./components/DomesticFlightTable";
import { AddDomesticFlightContainer, DomesticFlightContainer } from "./style";
import { AddDomesticFlight } from "./components/addDomesticFlight";

export const DomesticFlight: FC = () => {
  const {
    value: { mode },
  } = useDomesticFlight();

  return (
    <DomesticFlightContainer>
      {/* <PageHeader
        mode={mode}
        title={words.managementDomesticFlight.headerTitle}
        backTitle={words.global.back}
        tourCode={tourCode}
      /> */}
      <Row justify={"center"}>
        <Col span={22}>
          <AddDomesticFlightContainer>
            {mode === "edit" || mode === "add" ? <AddDomesticFlight /> : null}
            <DomesticFlightTable />
          </AddDomesticFlightContainer>
        </Col>
      </Row>
    </DomesticFlightContainer>
  );
};
