import { Form, FormInstance } from "antd";
import _ from "lodash";
import { useManagementTimeData } from "./context";

export const useFormHasChanged = (timeTourForm: FormInstance) => {
  const {
    states: { tourTime },
  } = useManagementTimeData();

  const allValues = Form.useWatch([], timeTourForm);

  const formHasProperty = () => {
    return allValues
      ? Object.values(allValues).every((el) => el === undefined || el === null)
      : false;
  };

  const changed = _.isEqual(
    _.omit(allValues, ["id", "tourId"]),
    _.omit(tourTime, ["id", "tourId"])
  );
  return tourTime ? changed : formHasProperty();
};
