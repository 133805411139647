import { FC } from "react";
import HotelInfo from "./components/HotelInfo";
import HotelsViewDataProvider from "./context";
import ContactsInfo from "./components/ContactsInfo";
import { HotelsViewContainer } from "./style";

const HotelView: FC = () => {
  return (
    <HotelsViewDataProvider>
      <HotelsViewContainer span={20}>
        <HotelInfo />
        <ContactsInfo />
      </HotelsViewContainer>
    </HotelsViewDataProvider>
  );
};

export default HotelView;
