import { FC } from "react";
import { Button, Col } from "antd";
import useLanguage from "src/store/language";
import { useLocation } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";

interface IActionsProps {
  onPrevious: () => void;
  loading?: boolean;
  disable?: boolean;
}

const Actions: FC<IActionsProps> = ({ onPrevious, loading, disable }) => {
  const { words, isRtl } = useLanguage();

  const location = useLocation();

  return (
    <Col span={24} className="actions" dir={isRtl ? "rtl" : "rtl"}>
      {location.pathname.includes(ROUTES.tourEdit) ? (
        <Button
          type="text"
          loading={loading}
          htmlType="submit"
          className="edit-finish"
          icon={<span className="material-icons">check_circle</span>}
        >
          {words.addEditTour.finisEdit}
        </Button>
      ) : (
        <>
          <Button
            type="text"
            loading={loading}
            htmlType="submit"
            disabled={disable || loading}
            className="next"
            icon={<span className="material-icons">arrow_forward</span>}
          >
            {words.addEditTour.next}
          </Button>
          <Button
            type="text"
            className="cancel"
            icon={<span className="material-icons">arrow_back</span>}
            dir={isRtl ? "ltr" : "rtl"}
            onClick={onPrevious}
          >
            {words.global.back}
          </Button>
        </>
      )}
    </Col>
  );
};

export default Actions;
