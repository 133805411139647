import { FC } from "react";
import { Button, Col, Divider, Form, Row, Select } from "antd";
import SubmitButton from "src/components/UiKit/SubmitButton";
import { SelectUiKit } from "src/components/UiKit/select";
import useLanguage from "src/store/language";
import { useRoutePlacesData } from "../../context";
import EndRegisterButton from "src/components/UiKit/EndRegisterButton";

const AddEditRoutePlaces: FC = () => {
  const {
    states: {
      routePlaceForm,
      selectedRoutePlace,
      loading,
      routePlaces,
      place,
      city,
      mode,
    },
    func: { onFinish },
    dispatches: { setSelectedRoutePlace, setPlacesParams },
  } = useRoutePlacesData();

  const { words } = useLanguage();

  const handleChangeCity = (value: string) => {
    value && value !== "" && value.length > 2
      ? setPlacesParams({ SearchQuery: value })
      : setPlacesParams({});
  };

  const handleCancelForm = () => {
    setPlacesParams({});
    if (selectedRoutePlace) setSelectedRoutePlace(undefined);
    else {
      routePlaceForm.resetFields();
    }
  };

  return (
    <Form
      form={routePlaceForm}
      onFinish={onFinish}
      onFinishFailed={() => alert("error")}
      layout="vertical"
      autoComplete="off"
      name="hotel-form"
    >
      <p className="add-hotel-form-title">
        {words.managementRoute.placeFormTitle}
      </p>
      <span className="description">
        {words.managementRoute.placeFormDescription}
      </span>
      <Row gutter={[8, 16]} className="mt-[30px]">
        <Col xs={24} md={8}>
          <Form.Item name={"cityId"} label={words.managementRoute.city}>
            <SelectUiKit
              allowClear
              showSearch
              loading={city.loading}
              placeholder={words.managementRoute.select}
              onChange={() =>
                routePlaceForm.setFieldValue("placeOfInterestId", null)
              }
            >
              {city.data.map((item) => (
                <Select.Option key={item.id} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </SelectUiKit>
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name={"placeOfInterestId"}
            label={words.managementRoute.places}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <SelectUiKit
              showSearch
              loading={place.loading}
              placeholder={words.managementHotel.select}
              onSearch={handleChangeCity}
            >
              {place.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </SelectUiKit>
          </Form.Item>
        </Col>

        <Col span={24} className="footer">
          <div className="footer-box">
            <Button
              type="text"
              className="clean-btn bg-[#F5F5F5] text-[#8E8E8E] ml-2 rounded-lg w-[85px]"
              onClick={handleCancelForm}
            >
              {selectedRoutePlace
                ? words.basicInfo.countryCancel
                : words.basicInfo.buttonClear}
            </Button>
            <SubmitButton
              form={routePlaceForm}
              loading={loading}
              canSubmit={false}
            >
              {selectedRoutePlace
                ? words.basicInfo.countrySubmit
                : words.basicInfo.buttonAdd}
            </SubmitButton>
            <EndRegisterButton
              btnTitle={
                selectedRoutePlace ? words.global.endEdit : words.global.End
              }
              disabled={routePlaces.length > 0 ? false : true}
            />
          </div>
        </Col>
        <Divider className="bg-[#DADEEC]" />
      </Row>
    </Form>
  );
};

export default AddEditRoutePlaces;
