import { InputProps } from "antd";
import { FC, useState } from "react";
import { InputStyle } from "./styles";
import { omit } from "lodash";

interface IProps extends InputProps {
  numberMode?: boolean;
}
export const SInput: FC<IProps> = (props) => {
  const [value, setValue] = useState<string>("");
  return (
    <InputStyle
      {...omit(props, "numberMode")}
      onChange={(e: any) => {
        props.onChange && props.onChange(e);
        setValue(e.target.value);
      }}
      autoComplete="off"
      onBeforeInput={
        props.numberMode
          ? (event: any) => {
              if (/[0-9]/.test(event.data) || event.data === ".") {
                if (event.data === "." && value.includes("."))
                  event.preventDefault();
              } else event.preventDefault();
            }
          : undefined
      }
    />
  );
};
