export enum SystemUrls {
  NewCategory = "System/NewCategory",
  AllCategory = "System/AllCategory",
  NewRole = "System/NewRole",
  System = "System/",
  AllSubCategory = "/AllSubCategory",
  AllActions = "/System/AllActions",
  RolesOfAction = "/RolesOfAction",
  AssignRoleToAction = "System/AssignRoleToAction",
}
