import { FC } from "react";
import { ColumnsType } from "antd/es/table";
import { TableUI } from "src/components/UiKit/table";
import useLanguage from "src/store/language";
import { useTourViewData } from "../../context/TourViewData";
import { TourFieldNames } from "src/modules/addEditTour/models";
import { ITourExcelData } from "src/modules/addEditTour/components/tour/interface";
import { ITourInfoResult } from "src/services/Tour/models";
import { useTourInfoRequest } from "./TourRequest";
import { Button } from "antd";

const TourInfo: FC = () => {
  const {
    states,
    dispatches: { setViewMode },
  } = useTourViewData();
  const {
    states: { dataLoading },
  } = useTourInfoRequest();

  const { words } = useLanguage();

  const columns: ColumnsType<ITourExcelData> = [
    {
      title: `${words.viewTour.fromCity}`,
      dataIndex: TourFieldNames.FromCity,
      key: TourFieldNames.FromCity,
    },
    {
      title: `${words.viewTour.betweenCity}`,
      dataIndex: TourFieldNames.BetweenCity,
      key: TourFieldNames.BetweenCity,
    },
    {
      title: `${words.viewTour.toCity}`,
      dataIndex: TourFieldNames.ToCity,
      key: TourFieldNames.ToCity,
    },
    {
      title: `${words.viewTour.flyTime}`,
      dataIndex: TourFieldNames.FlyTime,
      key: TourFieldNames.FlyTime,
    },
    {
      title: `${words.viewTour.persianDate}`,
      dataIndex: TourFieldNames.PersianDate,
      key: TourFieldNames.PersianDate,
    },
    {
      title: `${words.viewTour.date}`,
      dataIndex: TourFieldNames.Date,
      key: TourFieldNames.Date,
    },
    {
      title: `${words.viewTour.places}`,
      dataIndex: TourFieldNames.Places,
      key: TourFieldNames.Places,
    },
    {
      title: `${words.viewTour.hotel}`,
      dataIndex: TourFieldNames.Hotel,
      key: TourFieldNames.Hotel,
    },
  ];

  return (
    <div className="bg-[#fff]">
      <TableUI
        loading={dataLoading}
        scroll={{ x: 400 }}
        dataSource={states.formData?.tour as ITourInfoResult[]}
        columns={columns}
        tableType="secondary"
        pagination={false}
      />
      <div className="text-left pb-4 pl-6">
        <Button
          type="text"
          className="border-[#f6a372] text-[#f6a372] hover:!text-[#f6a372]"
          onClick={() => setViewMode("print-tour")}
        >
          {words.viewTour.tourInfoDownloadTitle}
        </Button>
      </div>
    </div>
  );
};

export default TourInfo;
