import ManagementBasicInfo from "src/modules/ManagementBasicInfo";
import ManagementBasicInfoDataProvider from "src/modules/ManagementBasicInfo/context";

const ManagementBasicInfoPage = () => (
  <ManagementBasicInfoDataProvider>
    <ManagementBasicInfo />
  </ManagementBasicInfoDataProvider>
);

export default ManagementBasicInfoPage;
