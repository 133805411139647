import { FC } from "react";
import { Form, Spin } from "antd";
import DriverInfo from "./DriverInfo";
import FormFooter from "./FormFooter";
import CarInfo from "./CarInfo";
import { useManagementDriverData } from "../../context";

const AddEditDriver: FC = () => {
  const {
    states: { driverForm, dataLoading },
    func: { onFinish },
  } = useManagementDriverData();

  return (
    <>
      {dataLoading ? (
        <div className="w-full flex justify-center items-center">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Form
            onFinish={onFinish}
            onFinishFailed={() => alert("failed")}
            form={driverForm}
            layout="vertical"
            preserve={false}
            className="mt-[40px]"
          >
            <DriverInfo />
            <CarInfo />
            <FormFooter />
          </Form>
        </>
      )}
    </>
  );
};

export default AddEditDriver;
