import { Flex } from "antd";
import { FC } from "react";
import useLanguage from "src/store/language";
import { useManagementCompanionLeaderData } from "../context";

const ViewCompanionLeader: FC = () => {
  const { words } = useLanguage();
  const {
    states: { companionLeader },
  } = useManagementCompanionLeaderData();

  return (
    <>
      <p className="title">{words.managementCompanionLeader.viewTitle}</p>
      <section className="view-details">
        <Flex className="w-full">
          <Flex vertical className="w-[50%]">
            <Flex>
              <div className="detail-title">
                {words.managementCompanionLeader.nameLabel}:
              </div>
              <div className="text-[14px] text-[#4B4B4B] font-normal">
                {companionLeader && companionLeader?.fullName
                  ? companionLeader?.fullName
                  : "-"}
              </div>
            </Flex>
          </Flex>
          <Flex vertical className="w-50%">
            <Flex>
              <div className="detail-title">
                {words.managementCompanionLeader.phoneLabel}:
              </div>
              <div className="text-[14px] text-[#4B4B4B] font-normal">
                {companionLeader && companionLeader?.phoneNumber
                  ? companionLeader?.phoneNumber
                  : "-"}
              </div>
            </Flex>
          </Flex>
        </Flex>
      </section>
    </>
  );
};

export default ViewCompanionLeader;
