import { FC } from "react";
import { Col } from "antd";
import { ManagementInsuranceContainer } from "./style";
import InsuranceAddEdit from "./components/insurance";

const ManagementInsurance: FC = () => {
  return (
    <>
      <ManagementInsuranceContainer justify={"center"} gutter={[0, 24]}>
        <Col xs={24} md={23} className="content">
          <InsuranceAddEdit />
        </Col>
      </ManagementInsuranceContainer>
    </>
  );
};

export default ManagementInsurance;
