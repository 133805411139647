export enum BasicInformationUrls {
  BasicInfo = "BaseInfo/",
  Countries = "BaseInfo/AllCountry",
  DeleteCountry = "/DeleteCountry",
  NewCountry = "BaseInfo/AddCountry",
  EditCountry = "/UpdateCountry",
  Provinces = "BaseInfo/Provinces",
  Cities = "BaseInfo/AllCity",
  DeleteCity = "/DeleteCity",
  NewCity = "BaseInfo/AddCity",
  EditCity = "/UpdateCity",
  Cars = "BaseInfo/AllCar",
  DeleteCar = "/DeleteCar",
  NewCar = "BaseInfo/AddCar",
  EditCar = "/UpdateCar",
  Places = "BaseInfo/AllPlaceOfInterests",
  NewPlace = "BaseInfo/AddPlaceOfInterest",
  DeletePlace = "/DeletePlaceOfInterest",
  EditPlace = "/UpdatePlaceOfInterest",
  Hotels = "BaseInfo/AllHotels",
  DeleteHotel = "/DeleteHotel",
  NewHotel = "BaseInfo/AddHotel",
  EditHotel = "/UpdateHotel",
  Contacts = "/AllHotelResponders",
  DeleteContact = "/DeleteHotelResponder",
  NewContact = "/AddHotelResponder",
  EditContact = "/UpdateHotelResponder",
  ResponderDetail = "/AllHotelResponderDetails",
  NewResponderDetail = "/AddHotelResponderDetail",
  DeleteResponderDetail = "/DeleteHotelResponderDetail",
  EditResponderDetail = "/UpdateHotelResponderDetail",
  ResponderDetailById = "/HotelResponderDetailsById",
  Rooms = "BaseInfo/AllRoom",
  NewRoom = "BaseInfo/AddRoom",
  DeleteRoom = "/DeleteRoom",
  EditRoom = "/UpdateRoom",
  Brokers = "BaseInfo/AllBroker",
  NewBroker = "BaseInfo/AddBroker",
  DeleteBroker = "/DeleteBroker",
  EditBroker = "/UpdateBroker",
  AllHotelsByCity = "/AllHotelsByCity",
}
