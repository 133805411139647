import { FC } from "react";
import { Col } from "antd";
import { ManagementLeaderContainer } from "./style";
import { useManagementLeaderData } from "./context";
import ViewLeader from "./components/ViewLeader";
import AddEditLeader from "./components/AddEditLeader";

const ManagementLeader: FC = () => {
  const {
    states: { mode },
  } = useManagementLeaderData();

  return (
    <ManagementLeaderContainer justify={"center"} gutter={[0, 24]}>
      <Col xs={24} md={23} className="content">
        {mode === "view" ? <ViewLeader /> : <AddEditLeader />}
      </Col>
    </ManagementLeaderContainer>
  );
};

export default ManagementLeader;
