import { Flex } from "antd";
import { FC } from "react";
import { convertDate } from "src/helper";
import { useTourViewData } from "src/modules/viewTour/context/TourViewData";

const Header: FC = () => {
  const {
    states: { formData },
  } = useTourViewData();

  return (
    <Flex vertical>
      <Flex justify="space-between" align="center" className="px-[5px]">
        <img src="/assets/images/HeaderLogo.svg" alt="logo" />

        <Flex vertical>
          <Flex>
            <p className="text-left w-[86px]">تاریخ ثبت:</p>
            <p className="px-5 text-left">
              {formData?.tourInfo?.created
                ? convertDate(formData?.tourInfo?.created)
                : "-"}
            </p>
          </Flex>
          <Flex>
            <p className="text-left w-[86px]">تاریخ شروع تور:</p>
            <p className="px-5 text-left">
              {formData?.tourInfo?.startDate
                ? convertDate(formData?.tourInfo?.startDate)
                : "-"}
            </p>
          </Flex>
          <Flex>
            <p className="text-left w-[86px]">تاریخ پایان تور:</p>
            <p className="px-5 text-left">
              {formData?.tourInfo?.endDate
                ? convertDate(formData?.tourInfo?.endDate)
                : "-"}
            </p>
          </Flex>
        </Flex>
      </Flex>
      <div className="h-[42px] bg-[#F6A372] mt-7 text-left">
        <span className="bg-[#fff] text-[#F06614] inline-block h-[42px] mx-16 inline-flex justify-center items-center text-4xl">
          {formData?.tourInfo?.tourCode}
        </span>
      </div>
    </Flex>
  );
};

export default Header;
