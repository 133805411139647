import { FC } from "react";
import { ColumnsType } from "antd/es/table";
import { TableUI } from "src/components/UiKit/table";
import useLanguage from "src/store/language";
import { convertDate, convertDateMiladi } from "src/helper";
import TableActions from "./TableActions";
import TourState from "./TourState";
import { useTourTableData } from "../context/TourTableData";
import { Flex } from "antd";
import { ITour } from "src/services/TourManagement/models";
import { getRowKey } from "src/helper/getRowKey";
import { TableContainer } from "../styles/table";

const ToursList: FC = () => {
  const { states, dispatches } = useTourTableData()!;

  const { words } = useLanguage();

  const columns: ColumnsType<ITour> = [
    {
      title: "#",
      dataIndex: "#",
      width: 62,
      fixed: "left",
      key: "#",
      render: (text, record, index) =>
        (states.tourTableParams.Offset - 1) * states.tourTableParams.Limit +
        index +
        1,
    },
    {
      title: `${words.tableTour.tourID}`,
      dataIndex: "tourCode",
      key: "tourCode",
      width: 97,
      fixed: "left",
      className: "rightShadow",
      render: (value) => (value ? value : "-"),
    },

    {
      title: `${words.tableTour.proDate}`,
      dataIndex: "created",
      key: "created",
      width: 117,
      render: (value) => (value ? convertDate(value) : "-"),
    },
    {
      title: `${words.tableTour.startDate}`,
      dataIndex: "startDate",
      key: "startDate",
      width: 164,
      render: (value) =>
        value ? (
          <Flex vertical align="center" justify="space-between">
            <span>{convertDate(value)}</span>
            <span className="mt-[5px]">{convertDateMiladi(value)}</span>
          </Flex>
        ) : (
          "-"
        ),
    },
    // {
    //   title: `${words.tableTour.endDate}`,
    //   dataIndex: "endDate",
    //   key: "endDate",
    //   render: (value) =>
    //     value ? (
    //       <Flex vertical align="center" justify="space-between">
    //         <span>{convertDate(value)}</span>
    //         <span className="mt-[5px]">{convertDateMiladi(value)}</span>
    //       </Flex>
    //     ) : (
    //       "-"
    //     ),
    // },
    // {
    //   title: `${words.tableTour.cancelDate}`,
    //   dataIndex: "cancelDate",
    //   key: "cancelDate",
    //   render: (value) =>
    //     value ? (
    //       <Flex vertical align="center" justify="space-between">
    //         <span>{convertDate(value)}</span>
    //         <span className="mt-[5px]">{convertDateMiladi(value)}</span>
    //       </Flex>
    //     ) : (
    //       "-"
    //     ),
    // },
    {
      title: `${words.tableTour.nation}`,
      dataIndex: "countryName",
      key: "countryName",
      width: 117,
    },
    {
      title: `${words.tableTour.tourDuration}`,
      dataIndex: "tourDays",
      key: "tourDays",
      width: 104,
      render: (value) => (value ? Number(value).toLocaleString() : "-"),
    },
    {
      title: `${words.tableTour.notuorists}`,
      dataIndex: "touristCount",
      key: "touristCount",
      width: 125,
      render: (value) => Number(value).toLocaleString(),
    },
    {
      title: `${words.tableTour.state}`,
      dataIndex: "state",
      key: "state",
      width: 141,
      render: (value) => <TourState state={value} />,
    },
    {
      title: `${words.tableTour.tools}`,
      dataIndex: "tools",
      key: "tools",
      fixed: "right",
      width: 177,
      className: "leftShadow",
      render: (value, record) => (
        <TableActions
          id={record.id}
          code={record.tourCode}
          state={record.state}
        />
      ),
    },
  ];

  return (
    <TableContainer>
      <TableUI
        loading={states.dataLoading}
        scroll={{ x: 1400 }}
        dataSource={states.allTour}
        columns={columns}
        tableType="secondary"
        rowKey={getRowKey}
        pagination={{
          position: ["bottomCenter"],
          total: states.totalCount,
          pageSize: states.tourTableParams.Limit,
          current: states.tourTableParams.Offset,
          onChange(page, pageSize) {
            dispatches.setTourTableParams((prev) => ({
              ...prev,
              Offset: page,
              Limit: pageSize,
            }));
          },
        }}
      />
    </TableContainer>
  );
};

export default ToursList;
