import { FC } from "react";
import { Tooltip } from "antd";
import { TableUI } from "src/components/UiKit/table";
import { ColumnsType } from "antd/es/table";
import { IPlace } from "src/services/BasicInformation/models";
import useLanguage from "src/store/language";
import { Icons } from "src/components/customIcons";
import TableActions from "./TableActions";
import { usePlacesData } from "../context/PlacesData";

const PlaceTable: FC = () => {
  const {
    states: {
      places,
      dataLoading,
      totalCount,
      tablePaginationParams,
      selectedPlace,
    },
    dispatches: { setTablePaginationParams },
  } = usePlacesData();

  const { words } = useLanguage();

  const tableColumns: ColumnsType<IPlace> = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      width: 60,
      render: (text, record, index) =>
        (tablePaginationParams.Offset - 1) * tablePaginationParams.Limit +
        index +
        1,
    },
    {
      title: "نام مکان ",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "نام شهر",
      dataIndex: "cityName",
      key: "cityName",
      width: 150,
    },
    {
      title: "آدرس",
      dataIndex: "address",
      key: "address",
      width: 100,
      render: (value) => (
        <div className="flex justify-center">
          <Tooltip
            placement="bottom"
            color="#4B4B4B"
            title={value && value !== "" ? value : "-"}
          >
            <div>
              <Icons name="address" />
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: `${words.basicInfo.tableActions}`,
      dataIndex: "tools",
      key: "tools",
      width: 200,
      render: (_, record) => <TableActions item={record} />,
    },
  ];
  return (
    <>
      <p className="title-table">جدول مکان های دیدنی</p>
      <TableUI
        columns={tableColumns}
        dataSource={places}
        loading={dataLoading}
        scroll={{ x: 500 }}
        tableType="secondary"
        rowClassName={(record, index) =>
          record.id === selectedPlace?.id ? "selected-row-for-edit" : ""
        }
        pagination={{
          position: ["bottomCenter"],
          total: totalCount,
          pageSize: tablePaginationParams.Limit,
          current: tablePaginationParams.Offset,
          onChange(page, pageSize) {
            setTablePaginationParams((prev) => ({
              ...prev,
              Offset: page,
              Limit: pageSize,
            }));
          },
        }}
      />
    </>
  );
};

export default PlaceTable;
