import { FC } from "react";
import { Button, Col, Flex, Form, Row, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import InputUiKit from "src/components/UiKit/Input";
import SubmitButton from "src/components/UiKit/SubmitButton";
import useLanguage from "src/store/language";
import { SelectUiKit } from "src/components/UiKit/select";
import InputNumberUiKit from "src/components/UiKit/inputNumber";
import { useAddTourData } from "../context";

const AddTourForm: FC = () => {
  const {
    states: { addTourForm, loading, countries, countryLoading, brokerProps },
    func: { onFinish },
  } = useAddTourData();

  const { words } = useLanguage();

  return (
    <Form onFinish={onFinish} form={addTourForm} layout="vertical">
      <Row gutter={[24, 12]}>
        <Col span={24} className="mb-[40px]">
          <p className="title !font-bold">{words.addTour.title}</p>
        </Col>
        <Col xs={24} md={8}>
          <FormItem
            name={"tourCode"}
            label={words.addTour.tourCodeLabel}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit
              min={6}
              max={6}
              placeholder={words.addTour.tourCodePlaceHolder}
            />
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem
            name={"countryId"}
            label={words.addTour.nationLabel}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <SelectUiKit
              showSearch
              placeholder={words.addTour.nationPlaceHolder}
              loading={countryLoading}
            >
              {countries.map((country) => (
                <Select.Option key={country.id} value={country.id}>
                  {country.faName}
                </Select.Option>
              ))}
            </SelectUiKit>
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem name={"touristCount"} label={words.addTour.touristNumber}>
            <InputNumberUiKit
              controls={false}
              type="number"
              placeholder={words.addTour.touristPlaceHolder}
            />
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem name={"brokerId"} label={words.addTour.brokerLabel}>
            <SelectUiKit
              allowClear
              showSearch
              placeholder={words.addTour.nationPlaceHolder}
              loading={brokerProps.loading}
            >
              {brokerProps.brokers.map((broker) => (
                <Select.Option key={broker.id} value={broker.id}>
                  {broker.name}
                </Select.Option>
              ))}
            </SelectUiKit>
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem
            name={"origin"}
            label={words.addTour.origin}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit placeholder={words.global.insert} />
          </FormItem>
        </Col>
        <Col xs={24} md={8}>
          <FormItem
            name={"destination"}
            label={words.addTour.destination}
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit placeholder={words.global.insert} />
          </FormItem>
        </Col>
        <Col span={24} className="mt-[24px]">
          <Flex
            justify="end"
            gap={16}
            align="end"
            className="pt-[30px] border-0 border-t-[2px] border-solid border-[#E1E1E1]"
          >
            <Button
              type="text"
              className="bg-[#F5F5F5] text-[#8E8E8E] p-[8px] w-[85px]"
              onClick={() => addTourForm.resetFields()}
            >
              {words.addTour.clear}
            </Button>
            <SubmitButton
              form={addTourForm}
              loading={loading}
              canSubmit={false}
            >
              {words.global.record}
            </SubmitButton>
          </Flex>
        </Col>
      </Row>
    </Form>
  );
};
export default AddTourForm;
