import { FC } from "react";
import { Button, Col, Flex, Form, Row, Select } from "antd";
import InputUiKit from "src/components/UiKit/Input";
import useLanguage from "src/store/language";
import { SelectUiKit } from "src/components/UiKit/select";
import { useHotelsData } from "src/modules/hotels/context/HotelsData";
import { HotelsViewMode } from "src/modules/hotels/models/enums";
import SubmitButton from "src/components/UiKit/SubmitButton";
import { useAddHotelInfoData } from "./context";
import { useFormHasChanged } from "./useFormHasChanged";

const HotelsInfoForm: FC = () => {
  const { words } = useLanguage();

  const {
    states: { selectedHotel, viewMode, hotelInfoChanged, addHotelForm },
  } = useHotelsData();

  const { states, func } = useHotelsData();

  const {
    values: { cities, cityLoading, loading },
    func: { onFinish, handleChangesBack },
  } = useAddHotelInfoData();

  const handleCancelForm = () => {
    if (selectedHotel && viewMode === HotelsViewMode.Edit) {
      handleChangesBack();
    } else {
      addHotelForm.resetFields();
    }
  };
  const canSubmit = useFormHasChanged();

  const handleBack = () => {
    if (states.viewMode === HotelsViewMode.View || !states.hotelInfoChanged)
      func.handleBack();
  };

  return (
    <Form
      form={addHotelForm}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      name="hotel-form"
    >
      <Row gutter={[8, 16]} className="add-place-form-row">
        <Col span={24}>
          <p className="title">
            {viewMode === HotelsViewMode.Edit
              ? "شما میتوانید با انتخاب هر گزینه ؛ اطلاعات آن را ویرایش کنید."
              : "لطفا اطلاعات هتل را وارد کنید."}{" "}
          </p>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name={"name"}
            label="نام هتل"
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit placeholder="مثلا تخت جمشید" />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Form.Item
            name={"cityId"}
            label="نام شهر"
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <SelectUiKit
              showSearch
              loading={cityLoading}
              placeholder="مثلا تهران"
            >
              {cities.map((city) => (
                <Select.Option key={city.id} value={city.id}>
                  {city.name}
                </Select.Option>
              ))}
            </SelectUiKit>
          </Form.Item>
        </Col>

        <Col xs={24} md={8}>
          <Form.Item
            name={"star"}
            label="تعداد ستاره"
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <SelectUiKit showSearch placeholder="مثلا ۵ ستاره">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                <Select.Option value={i} key={i}>
                  {i.toString().concat(" ".concat("ستاره"))}
                </Select.Option>
              ))}
            </SelectUiKit>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name={"address"} label="آدرس">
            <InputUiKit placeholder="مثلا تهران  . تهران پارس" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Flex justify="end" align="center" gap={16}>
            {states.viewMode === HotelsViewMode.Edit && (
              <Button className="btn w-[85px]" onClick={handleBack}>
                بازگشت
              </Button>
            )}
            <Button
              disabled={
                viewMode === HotelsViewMode.Edit ? !hotelInfoChanged : false
              }
              className="btn"
              onClick={handleCancelForm}
            >
              {selectedHotel && viewMode === HotelsViewMode.Edit
                ? "پاک سازی تغییرات"
                : words.basicInfo.buttonClear}
            </Button>
            <SubmitButton
              form={addHotelForm}
              loading={loading}
              canSubmit={canSubmit}
            >
              {selectedHotel && viewMode === HotelsViewMode.Edit
                ? words.basicInfo.countrySubmit
                : "مرحله بعدی"}
            </SubmitButton>
          </Flex>
        </Col>
      </Row>
    </Form>
  );
};

export default HotelsInfoForm;
