import { Flex } from "antd";
import styled from "styled-components";

export const ReportTabHeaderStyle = styled(Flex)`
  border-radius: 12px;
  background: var(--color-Gray-Gray-200, #eee);
  padding: 16px;
  .item {
    display: flex;
    min-width: 168px;
    padding: 16px 12px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    background: var(--color-Base-Color-White, #fff);

    /* Card_Gray */
    box-shadow: 0px 4px 5px 0px rgba(113, 130, 183, 0.1);
    cursor: pointer;
    user-select: none;
  }
  .active {
    background: var(--color-Primary-Primary-700, #f06614);
    color: var(--color-Base-Color-White, #fff);
    font-size: 12px;
    font-weight: bold;
  }
  .css-1hznnw6-MuiSvgIcon-root {
    width: 24px;
    height: 24px;
  }
`;
