import { FC, RefObject, useMemo } from "react";
import { ReservationStyle } from "./reservationStyle";
import { Col, Flex, Table } from "antd";
import { uniqueId } from "lodash";
import { useHotelLetter } from "./context";
import {
  IGetExportHotelMail,
  IRoomDetail,
} from "src/services/ExportData/models";
import moment from "moment-jalaali";
import { reservationColumns } from "./reservationColumns";
interface IProps {
  data: IGetExportHotelMail | undefined;
  loading: boolean;
  componentRef?: RefObject<HTMLDivElement> | null;
  isPdf?: boolean;
}
export const ReservationLetter: FC<IProps> = ({
  componentRef,
  isPdf,
  data,
  loading,
}) => {
  const {
    values: { hotelLetterInfo },
  } = useHotelLetter();
  const tableData = useMemo(() => {
    let newData: IRoomDetail[] = [];
    data?.roomDetail.forEach((room) => {
      newData.push({
        ...room,
        arrivalDate: moment(room.arrivalDate).format("jYYYY/jMM/jDD"),
        departureDate: moment(room.departureDate).format("jYYYY/jMM/jDD"),
      });
    });
    return newData;
  }, [data]);
  return (
    <ReservationStyle gutter={[24, 24]} ref={componentRef}>
      {isPdf && (
        <Col span={24}>
          <img
            src="/assets/images/sarbargHeader.png"
            alt="sarbarg"
            // className="absolute top-0 left-0 right-0 z-2"
            width={"100%"}
          />
        </Col>
      )}
      <Col span={24}>
        <Flex justify="flex-end" className="h-[100px] pe-[40px]" align="center">
          <Flex vertical>
            <Flex gap={24} justify="flex-start">
              <span className="header-label">تاریخ :</span>
              <span className="header-value">
                {moment(hotelLetterInfo?.date?.toString()).format(
                  "jYYYY/jMM/jDD"
                )}
              </span>
            </Flex>
            <Flex gap={24} justify="flex-start">
              <span className="header-label">شماره نامه :</span>
              <span className="header-value">{data?.tourCode}</span>
            </Flex>
          </Flex>
        </Flex>
      </Col>
      <Col span={24} className="!px-[32px]">
        <Flex vertical gap={12}>
          <h2 className="title">
            {hotelLetterInfo?.reservation} رزرواسیون محترم
          </h2>
          <h2 className="title">هتل {data?.hotelName}</h2>
          <p className="description">باسلام</p>
          <p className="description">
            احتراما به استحضار میرساند پیرو مکالمه تلفنی با شما
          </p>
          <p className="description">درخواست رزرو اتاق های زیر را خواستاریم.</p>
        </Flex>
      </Col>
      <Col span={24} className="!px-[32px]">
        <Table
          columns={reservationColumns}
          dataSource={tableData}
          scroll={{ x: "auto" }}
          loading={loading}
          pagination={false}
          className="pt-[4px] pb-[32px] px-[24px] "
          rowKey={() => uniqueId()}
        />
      </Col>
      <Col span={24} className="!px-[32px]">
        <Flex vertical gap={12}>
          <p className="description">
            لطفا اقدامات لازم را مبذول فرمایید. در صورت هرگونه هماهنگی با شماره
            زیر تماس بگیرید.
          </p>
          <p className="description">
            {hotelLetterInfo?.coordinationPerson} {hotelLetterInfo?.phoneNumber}
          </p>
          <p className="description">
            پیشاپیش از بذل توجه و همکاری شما کمال تشکر را داریم.
          </p>
          <p className="description">{hotelLetterInfo?.description}</p>
        </Flex>
      </Col>
      <Col span={24} className={"!px-[32px]"}>
        <Flex
          justify="flex-end"
          className="h-[100px] !pe-[80px]"
          align="center"
        >
          <Flex vertical gap={8}>
            <Flex gap={24} justify="flex-start">
              <span className="title">با سپاس</span>
            </Flex>
            <Flex gap={24} justify="flex-start">
              <span className="title">{hotelLetterInfo?.signatory}</span>
            </Flex>
          </Flex>
        </Flex>
      </Col>
      {isPdf && (
        <Col span={24}>
          <img
            src="/assets/images/sarbargFooter.png"
            alt="sarbarg"
            // className="absolute bottom-0 left-0 right-0 z-2"
            width={"100%"}
          />
        </Col>
      )}
    </ReservationStyle>
  );
};
