import { FC } from "react";
import { Button, Col, Divider, Form, Row, Select } from "antd";
import InputUiKit from "src/components/UiKit/Input";
import useLanguage from "src/store/language";
import { SelectUiKit } from "src/components/UiKit/select";
import { usePlacesData } from "src/modules/places/context/PlacesData";
import SubmitButton from "src/components/UiKit/SubmitButton";
import { useFormHasChanged } from "src/modules/places/hooks/useFormHasChanged";

const AddPlace: FC = () => {
  const { words } = useLanguage();
  const {
    states: { selectedPlace, cities, cityLoading, addPlaceForm, loading },
    func: { onFinish },
    dispatches: { setSelectedPlace },
  } = usePlacesData();

  const handleCancelForm = () => {
    selectedPlace ? setSelectedPlace(undefined) : addPlaceForm.resetFields();
  };

  const canSubmit = useFormHasChanged();

  return (
    <Form
      form={addPlaceForm}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      name="place-form"
    >
      <p className="title">{words.basicInfo.addPlaceFormTitle}</p>
      <Row gutter={[8, 16]} className="add-place-form-row">
        <Col xs={24} md={12}>
          <Form.Item
            name={"name"}
            label="نام مکان"
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit placeholder="مثلا تخت جمشید" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={"cityId"}
            label="شهر"
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <SelectUiKit
              showSearch
              loading={cityLoading}
              placeholder="مثلا تهران"
            >
              {cities.map((city) => (
                <Select.Option key={city.id} value={city.id}>
                  {city.name}
                </Select.Option>
              ))}
            </SelectUiKit>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name={"address"}
            label="آدرس"
            // rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit placeholder="مثلا تهران  . تهران پارس" />
          </Form.Item>
        </Col>
        <Col span={24} className="footer">
          <div className="footer-box">
            <Button
              type="text"
              className="clean-btn bg-[#F5F5F5] text-[#8E8E8E] ml-2 w-[85px]"
              onClick={handleCancelForm}
            >
              {selectedPlace
                ? words.basicInfo.countryCancel
                : words.basicInfo.buttonClear}
            </Button>
            <SubmitButton
              form={addPlaceForm}
              loading={loading}
              canSubmit={canSubmit}
            >
              {selectedPlace
                ? words.basicInfo.countrySubmit
                : words.basicInfo.buttonAdd}
            </SubmitButton>
          </div>
        </Col>
        <Divider className="bg-[#DADEEC]" />
      </Row>
    </Form>
  );
};

export default AddPlace;
