import { ChangeEvent, FC } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import useLanguage from "src/store/language";
import { IObject } from "src/models/interfaces";
import { useAttachmentRequest } from "./AttachmentRequest";

const AttachmentsForm: FC = () => {
  const { states, requests } = useAttachmentRequest();

  const { words } = useLanguage();

  const [attachForm] = Form.useForm();

  const file = Form.useWatch("file", attachForm);

  const handleFinishAttachForm = (values: IObject) => {
    requests
      .addEditAttachRequest(values)
      .finally(() => attachForm.resetFields());
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    attachForm.setFieldsValue({ file });
    if (event.currentTarget.files)
      attachForm.setFieldsValue({
        file: event.currentTarget.files,
      });
  };

  return (
    <>
      <p className="attach-title">{words.addEditTour.attachTitle}</p>
      <Form form={attachForm} onFinish={handleFinishAttachForm}>
        <section className="section">
          <p className="title">{words.addEditTour.attachmentUploadTitle}</p>
          <Row gutter={[8, 16]} className="form">
            <Col xs={24} md={4}>
              <input
                type="file"
                id="excel"
                className="input-file"
                autoComplete="off"
                onChange={onChange}
              />
              <label htmlFor="excel">
                {file ? (
                  <>
                    <img
                      src="/assets/images/file.svg"
                      alt="excel"
                      width={66}
                      height={66}
                    />
                  </>
                ) : (
                  <>
                    <span className="material-icons">attach_file</span>
                    <span>{words.global.add}</span>
                  </>
                )}
              </label>
              <Form.Item
                name="file"
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <Input type={"hidden"} autoComplete="off" />
              </Form.Item>
            </Col>

            <Col xs={24} md={16}>
              <Form.Item
                name="comment"
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <Input.TextArea rows={3} autoComplete="off"></Input.TextArea>
              </Form.Item>
            </Col>

            <Col xs={24} md={4}>
              <Button
                htmlType="submit"
                loading={states.loading}
                className="next"
              >
                {words.global.Confirmation}
              </Button>
            </Col>
          </Row>
        </section>
      </Form>
    </>
  );
};

export default AttachmentsForm;
