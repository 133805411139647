import { FC } from "react";
import { AddTouristInfo } from "src/modules/managementTouristInfo";
import { AddTouristInfoProvider } from "src/modules/managementTouristInfo/context";

export const AddTouristInfoPage: FC = () => {
  return (
    <AddTouristInfoProvider>
      <AddTouristInfo />
    </AddTouristInfoProvider>
  );
};
