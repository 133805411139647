import { FC, PropsWithChildren, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Checkbox, Col, Form, Input, Popconfirm, Row, Spin, Tabs } from "antd";
import { TabsProps } from "antd/lib";
import useLanguage from "src/store/language";
import { IObject } from "src/models/interfaces";
import { convertDateMiladi, convertObj } from "src/helper";
import RangePickerUi from "src/components/UiKit/RangePicker";
import { TourStates } from "src/models/enums/TourStates";
import _ from "lodash";
import { useTourTableData } from "../../context/TourTableData";
import { TabStyle } from "../../styles";

export const FilterPopUp: FC<PropsWithChildren> = ({ children }) => {
  const { words } = useLanguage();

  const {
    dispatches: { setCountrySearchValue },
    form: { tourTableFilterForm },
  } = useTourTableData();

  const navigate = useNavigate();

  const onFinishFilter = (values: IObject) => {
    if (values.registerDate) {
      values.CreateFromDate = convertDateMiladi(values?.registerDate[0].$d);
      values.CreateToDate = convertDateMiladi(values?.registerDate[1].$d);
      delete values.registerDate;
    }
    if (values.startDate) {
      values.StartFromDate = convertDateMiladi(values?.startDate[0].$d);
      values.StartToDate = convertDateMiladi(values?.startDate[1].$d);
      delete values.startDate;
    }
    if (values.endDate) {
      values.EndFromDate = convertDateMiladi(values?.endDate[0].$d);
      values.EndToDate = convertDateMiladi(values?.endDate[1].$d);
      delete values.endDate;
    }
    if (values.cancelDate) {
      values.CancelFromDate = convertDateMiladi(values?.cancelDate[0].$d);
      values.CancelToDate = convertDateMiladi(values?.cancelDate[1].$d);
      delete values.cancelDate;
    }

    navigate({
      search: createSearchParams(convertObj(values)).toString(),
    });
  };

  const cancel = (e: React.MouseEvent<HTMLElement> | undefined) => {
    tourTableFilterForm.resetFields();
    setCountrySearchValue({});
    navigate({
      search: createSearchParams(convertObj({})).toString(),
    });
  };

  const items: TabsProps["items"] = [
    {
      key: "TourCodeTab",
      label: `${words.tableTour.tour}`,
      children: <TourCodeTab />,
    },
    {
      key: "NationTab",
      label: `${words.tableTour.nation}`,
      children: <NationTab />,
    },
    {
      key: "DateTab",
      label: `${words.tableTour.date}`,
      children: <DateTab />,
    },

    {
      key: "StatusTab",
      label: `${words.tableTour.state}`,
      children: <StatusTab />,
    },
  ];

  return (
    <Popconfirm
      placement="bottomLeft"
      title=""
      description={
        <TabStyle>
          <Form
            form={tourTableFilterForm}
            onFinish={onFinishFilter}
            layout="vertical"
          >
            <Tabs centered defaultActiveKey="1" items={items} />
          </Form>
        </TabStyle>
      }
      onConfirm={() => tourTableFilterForm.submit()}
      onCancel={cancel}
      okText={words.tableTour.confirm}
      cancelText={words.tableTour.reset}
      icon={""}
      rootClassName="tourTableFilterPopUp"
      className="tour-table-filter"
    >
      {children}
    </Popconfirm>
  );
};

const TourCodeTab: FC = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const {
    states: { allFilterTours, tourCodeLoading },
    dispatches: { setTourCodeParams },
  } = useTourTableData();
  const { words } = useLanguage();

  var debounce_fun = _.debounce(function (value: string) {
    value = value.trim();
    setSearchValue(value);
    if (value.length > 2)
      setTourCodeParams((prev) => ({
        ...prev,
        SearchQuery: value.toLowerCase(),
      }));
    else if (value.length === 2 && searchValue.length > 2)
      setTourCodeParams({});
    else if (value === "" && searchValue.length > 2) setTourCodeParams({});
  }, 700);

  return (
    <Row className="tab-content">
      <div className="w-full h-full flex items-center justify-center">
        <Input
          allowClear
          placeholder={words.global.search}
          prefix={<span className="material-icons text-[#E9EAF1]">search</span>}
          className="h-[51px] w-[320px] mt-[15px]"
          onChange={(event) => {
            debounce_fun(event.target.value);
          }}
          autoComplete="off"
        />
      </div>

      <Col span={22}>
        <div className="country-tab">
          {tourCodeLoading ? (
            <div className="w-full h-full flex items-center justify-center">
              <Spin size="large" />
            </div>
          ) : (
            <Form.Item name="TourIds">
              <Checkbox.Group className="flex flex-col pt-[16px] gap-[8px] flex-nowrap borderBottom">
                {allFilterTours?.map((tour) => (
                  <Checkbox key={tour.tourId} value={tour.tourId}>
                    {tour.tourCode}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
          )}
        </div>
      </Col>
    </Row>
  );
};
const NationTab: FC = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const {
    states: { countries, countryLoading },
    dispatches: { setCountrySearchValue },
  } = useTourTableData();
  const { words } = useLanguage();

  var debounce_fun = _.debounce(function (value: string) {
    value = value.trim();
    setSearchValue(value);
    if (value.length > 1)
      setCountrySearchValue((prev) => ({
        ...prev,
        SearchQuery: value.toLowerCase(),
      }));
    else if (value.length === 1 && searchValue.length > 1)
      setCountrySearchValue({});
    else if (value === "" && searchValue.length > 1) setCountrySearchValue({});
  }, 700);

  return (
    <Row className="tab-content">
      <Col span={24} className="text-center">
        <Input
          allowClear
          placeholder={words.global.search}
          prefix={<span className="material-icons text-[#E9EAF1]">search</span>}
          className="h-[51px] w-[320px] mt-[15px]"
          onChange={(event) => {
            debounce_fun(event.target.value);
          }}
          autoComplete="off"
        />
      </Col>

      <Col span={22} className="country-tab">
        {countryLoading ? (
          <div className="w-full h-full flex items-center justify-center">
            <Spin size="large" />
          </div>
        ) : (
          <Form.Item name="CountryIds">
            <Checkbox.Group className="flex flex-col pt-[16px] gap-[8px] flex-nowrap borderBottom">
              {countries.map((country) => (
                <Checkbox key={country.id} value={country.id}>
                  {country.faName}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        )}
      </Col>
    </Row>
  );
};
const DateTab: FC = () => {
  const { words } = useLanguage();
  return (
    <Row className="tab-content">
      <Col span={22} className="mt-[15px]">
        <p className="date-label">{words.tableTour.proDate}</p>
        <Form.Item name={"registerDate"}>
          <RangePickerUi />
        </Form.Item>
      </Col>
      <Col span={22} className="mt-[15px]">
        <p className="date-label">{words.tableTour.startFilterDate}</p>
        <Form.Item name={"startDate"}>
          <RangePickerUi />
        </Form.Item>
      </Col>
      <Col span={22} className="mt-[15px]">
        <p className="date-label">{words.tableTour.endDate}</p>
        <Form.Item name={"endDate"}>
          <RangePickerUi />
        </Form.Item>
      </Col>
      <Col span={22} className="mt-[15px]">
        <p className="date-label">{words.tableTour.cancelDate}</p>
        <Form.Item name={"cancelDate"}>
          <RangePickerUi />
        </Form.Item>
      </Col>
    </Row>
  );
};

const StatusTab: FC = () => {
  const { words } = useLanguage();
  return (
    <Row className="tab-content">
      <Col span={24}>
        <Form.Item name="TourStates">
          <Checkbox.Group className="flex flex-col pt-[16px] gap-[8px] flex-nowrap borderBottom">
            <Checkbox value={TourStates.Waiting}>
              {words.tableTour.waitingToStart}
            </Checkbox>
            <Checkbox value={TourStates.Progress}>
              {words.tableTour.progress}
            </Checkbox>
            <Checkbox value={TourStates.Finished}>
              {words.tableTour.finished}
            </Checkbox>
            <Checkbox value={TourStates.Inactive}>
              {words.tableTour.inactive}
            </Checkbox>
          </Checkbox.Group>
        </Form.Item>
      </Col>
    </Row>
  );
};
