import { FC } from "react";
import ManagerForm from "./ManagerForm";
import ManagerInfoRequestProvider from "./ManagerInfoRequest";

const ManagerTab: FC = () => {
  return (
    <ManagerInfoRequestProvider>
      <ManagerForm />
    </ManagerInfoRequestProvider>
  );
};

export default ManagerTab;
