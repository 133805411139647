import { useNavigate } from "react-router-dom";
import { USER_DATA, USER_JWT_TOKEN } from "src/constants";
import { ROUTES } from "src/models/enums/routes";
import useAuthStore from "src/store/auth";

export const useLogin = () => {
  const navigate = useNavigate();
  const { login, setUserRole } = useAuthStore();

  const loginHandler = async (token: string, roles: string[]) => {
    localStorage.setItem(USER_JWT_TOKEN, token);
    localStorage.setItem(USER_DATA, JSON.stringify(roles));
    setUserRole(roles);
    login(token);
    navigate(ROUTES.home);
  };

  return { loginHandler };
};
