import { Row } from "antd";
import { styled } from "styled-components";

export const LoginContainer = styled(Row)`
  .content {
    border-radius: 32px;
    background: var(--background, rgba(101, 101, 101, 0.7));
    backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    padding: 24px;
    margin-top: 4vw;
  }
  /* width: 25vw; */
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input {
    border-radius: 8px;
    border: 2px solid var(--color-Base-Color-White, #fff);
    background: var(--color-Light-Mode-Disable-Disable-1, #cacaca);
    width: 100%;
    display: block;
    height: 56px;
    color: #fff;
    text-align: left;
  }

  .ant-input-status-error:not(.ant-input-disabled) {
    background: var(--color-Light-Mode-Disable-Disable-1, #cacaca);
  }
  :where(
      .css-dev-only-do-not-override-1x5m6t1
    ).ant-input-outlined.ant-input-status-error:not(.ant-input-disabled):focus,
  :where(
      .css-dev-only-do-not-override-1x5m6t1
    ).ant-input-outlined.ant-input-status-error:not(
      .ant-input-disabled
    ):focus-within {
    background: var(--color-Light-Mode-Disable-Disable-1, #cacaca);
  }
  .inputPassword:hover {
    background: var(--color-Light-Mode-Disable-Disable-1, #cacaca) !important;
  }
  .inputPassword {
    display: flex;
    padding: 11px 7.6px;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--color-Base-Color-White, #fff);
    background: var(--color-Light-Mode-Disable-Disable-1, #cacaca);
    input {
      background: transparent;
      width: 100%;
      display: block;
      color: #fff;
      text-align: left;
    }
    input::placeholder {
      color: #000;
    }
    svg {
      fill: #fff;
    }
  }
  .refresh {
    display: flex;
    padding: 10px;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
    border: 1px solid var(--color-Base-Color-White, #fff);
    background: var(--color-Light-Mode-Disable-Disable-1, #cacaca);
    user-select: none;
    cursor: pointer;
  }
  .captcha {
    display: block;
    width: 190px;
    height: 45.6px;
    border-radius: 8px;
    border: 1.267px solid var(--color-Base-Color-White, #fff);
    background: url(<path-to-image>) lightgray 50% / cover no-repeat;
  }

  .button {
    display: flex;
    width: 100%;
    height: 45px;
    padding: 15.2px 5.067px;
    justify-content: center;
    align-items: center;
    gap: 7.6px;
    border-radius: 5.067px;
    background: var(--color-Primary-Primary-700, #f06614);
    color: var(--color-Base-Color-White, #fff);
    text-align: center;
    font-family: "Vazir FD";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .button:hover {
    color: var(--color-Base-Color-White, #fff) !important;
    background: var(--color-Primary-Primary-600, #f06614) !important;
    border-color: var(--color-Light-Mode-Disable-Disable-1, #cacaca) !important;
  }
  .ant-btn-default {
    border: none;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #fff !important;
    -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }

  input::placeholder {
    color: var(--color-Light-Mode-Text-Primary-text, #1f1f1f);
    text-align: right;
    font-family: vazir;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .title-tourism {
    color: var(--color-Primary-Primary-600, #f6a372);
    font-family: "LEMON MILK" !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 3px;
    text-align: right;
    padding-right: 4px;
  }
  .title-company {
    color: var(--color-secondary-secondary-600, #273574);
    font-family: "LEMON MILK" !important;
    font-size: 20.267px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 3.04px;
    text-align: left;
    padding-left: 4px;
  }
  .title {
    color: var(--color-secondary-secondary-600, #273574);
    font-family: "LEMON MILK" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: center;
  }
`;
