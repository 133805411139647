import { Form } from "antd";
import _ from "lodash";
import { usePlacesData } from "../context/PlacesData";

export const useFormHasChanged = () => {
  const {
    states: { selectedPlace, addPlaceForm },
  } = usePlacesData();

  const allValues = Form.useWatch([], addPlaceForm);

  const changed = _.isEqual(
    _.omit(allValues, ["id", "cityName"]),
    _.omit(selectedPlace, ["id", "cityName"])
  );
  return selectedPlace ? changed : false;
};
