import { FC } from "react";
import { Button, Col, Divider, Form, Row } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import useLanguage from "src/store/language";
import { useCityContext } from "../context";
import InputUiKit from "src/components/UiKit/Input";
import { useFormHasChanged } from "../useFormHasChanged";
import SubmitButton from "src/components/UiKit/SubmitButton";

const AddCity: FC = () => {
  const { onFinish, createCityForm, editCity, setEditCity, loadings } =
    useCityContext();

  const { words } = useLanguage();

  const canSubmit = useFormHasChanged();

  const onCancelProvince = () => {
    editCity ? setEditCity(undefined) : createCityForm.resetFields();
  };

  return (
    <Form onFinish={onFinish} form={createCityForm}>
      <Row gutter={[24, 12]}>
        <Col span={24} className="mb-[40px]">
          <p className="title">{words.basicInfo.cityTitle}</p>
          <p className="text-[14px] text-[#4B4B4B] !m-[0px]">
            در صورتی که شهر مد نظر در چین است در فیلد استان از کلمه “ چین “
            استفاده کنید.
          </p>
        </Col>
        <Col xs={24} md={12}>
          <FormItem
            name={"province"}
            label="استان"
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit placeholder="مثلا مازندران" />
          </FormItem>
        </Col>
        <Col xs={24} md={12}>
          <FormItem
            name={"name"}
            label="شهر"
            rules={[{ required: true, message: words.global.fieldRequired }]}
          >
            <InputUiKit placeholder="مثلا ساری" />
          </FormItem>
        </Col>
        <Col span={24} className="flex justify-end">
          <Button
            type="text"
            className="bg-[#F5F5F5] text-[#8E8E8E] ml-2 w-[85px]"
            onClick={onCancelProvince}
          >
            {editCity
              ? words.basicInfo.countryCancel
              : words.basicInfo.buttonClear}
          </Button>
          <SubmitButton
            form={createCityForm}
            loading={loadings.addEdit}
            canSubmit={canSubmit}
          >
            {editCity
              ? words.basicInfo.countrySubmit
              : words.basicInfo.buttonAdd}
          </SubmitButton>
        </Col>
        <Divider className="m-0 p-0" />
      </Row>
    </Form>
  );
};

export default AddCity;
