import { FC } from "react";
import { Button, Col, Form, Row, Select } from "antd";
import useLanguage from "src/store/language";
import { useDomesticFlight } from "../context";
import { FormItem } from "src/components/UiKit/FormItem";
import InputUiKit from "src/components/UiKit/Input";
import { SelectUiKit } from "src/components/UiKit/select";
import DatePickerUi from "src/components/UiKit/DatePicker";
import SubmitButton from "src/components/UiKit/SubmitButton";
import EndRegisterButton from "src/components/UiKit/EndRegisterButton";

export const AddDomesticFlight: FC = () => {
  const { words } = useLanguage();

  const {
    value: { mode, loading, selectedFlight, domesticFlights, cityProps },
    dispatch: { setSelectedFlight },
    form: { DomesticFlightForm },
    func: { onFinishForm },
  } = useDomesticFlight();

  const handleCancel = () => {
    if (!selectedFlight) {
      DomesticFlightForm.resetFields();
    } else setSelectedFlight(undefined);
  };

  return (
    <Form form={DomesticFlightForm} onFinish={onFinishForm} className="w-full">
      {mode === "edit" || selectedFlight ? (
        <Col span={24} className="py-[24px]">
          <p className="!m-[0px] text-[16px] text-[#233069] font-bold">
            {words.managementDomesticFlight.editTitle}
          </p>
        </Col>
      ) : (
        <Col span={24} className="py-[24px]">
          <p className="!m-[0px] text-[16px] text-[#233069] font-bold">
            {words.managementDomesticFlight.addTitle}
          </p>
        </Col>
      )}
      <Col span={24} className="pt-[16px] pb-[32px]">
        <Row gutter={24}>
          <Col span={8}>
            <FormItem
              name={"flightDateTime"}
              label={words.managementDomesticFlight.flightDateAndTime}
              rules={[{ required: true, message: words.global.fieldRequired }]}
            >
              <DatePickerUi
                needConfirm={false}
                showTime={{
                  format: "HH:mm",
                }}
                placeholder={words.managementDomesticFlight.flightDateAndTimePH}
              />
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              name={"originId"}
              label={words.managementDomesticFlight.startingCity}
              rules={[{ required: true, message: words.global.fieldRequired }]}
            >
              <SelectUiKit showSearch placeholder={words.global.choose}>
                {cityProps.cities.map((city) => (
                  <Select.Option key={city.id} value={city.id}>
                    {city.name}
                  </Select.Option>
                ))}
              </SelectUiKit>
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              name={"destinationId"}
              label={words.managementDomesticFlight.destinationCity}
              rules={[{ required: true, message: words.global.fieldRequired }]}
            >
              <SelectUiKit showSearch placeholder={words.global.choose}>
                {cityProps.cities.map((city) => (
                  <Select.Option key={city.id} value={city.id}>
                    {city.name}
                  </Select.Option>
                ))}
              </SelectUiKit>
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              name={"flightCode"}
              label={words.managementDomesticFlight.flightNO}
            >
              <InputUiKit
                placeholder={words.managementDomesticFlight.flightNoPh}
              />
            </FormItem>
          </Col>
          <Col span={16}>
            <FormItem
              name={"description"}
              label={words.managementDomesticFlight.description}
            >
              <InputUiKit
                placeholder={words.managementDomesticFlight.description}
              />
            </FormItem>
          </Col>
        </Row>
      </Col>
      <Col
        span={24}
        className="flex justify-end items-center border-0 border-b-[2px] border-solid border-[#E1E1E1] pb-[24px] mb-[24px]"
      >
        <div className="flex items-center ">
          <Button
            type="text"
            className="w-[85px] bg-[#F5F5F5] text-[#8E8E8E] ml-2"
            onClick={handleCancel}
          >
            {!selectedFlight
              ? words.managementTime.clear
              : words.managementTime.cancel}
          </Button>
          <SubmitButton
            form={DomesticFlightForm}
            loading={loading}
            canSubmit={false}
          >
            {!selectedFlight ? words.global.add : words.managementTime.editBtn}
          </SubmitButton>
        </div>
      </Col>
    </Form>
  );
};
