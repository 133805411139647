import {
  FC,
  ReactElement,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { uploadFile } from "src/helper/uploadZipFile";
import { ROUTES } from "src/models/enums/routes";
import { TourService } from "src/services/Tour/Tour.service";
import useLanguage from "src/store/language";
import { useTourAddEditData } from "../../context/TourAddEditData";
import { TabMenuKeys } from "../../models";
import { App } from "antd";

interface IContextValue {
  states: {
    loading: boolean;
    dataLoading: boolean;
    files: FileList | undefined;
    progress: boolean;
  };
  dispatches: {
    setFiles: Dispatch<SetStateAction<FileList | undefined>>;
  };
  requests: {
    addEditInsuranceRequest: (insurance: {
      insuranceType: string;
      insuranceCompany: string;
    }) => void;
  };
}

export const InsuranceRequestContext = createContext<IContextValue | undefined>(
  undefined
);

const InsuranceRequestProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<FileList | undefined>();
  const [progress, setProgress] = useState<boolean>(false);

  const { words } = useLanguage();
  const { dispatches } = useTourAddEditData();
  const { tourId } = useParams();
  const location = useLocation();
  const { message } = App.useApp();

  const { GetInsuranceInfo, GetInsuranceFile, InsuranceInfo } =
    new TourService();

  const addEditInsuranceRequest = async (insurance: {
    insuranceType: string;
    insuranceCompany: string;
  }) => {
    if (!tourId) return;
    setLoading(true);
    try {
      let res;
      if (files) {
        res = await uploadFile({
          callbackUrl: `Tour/${tourId}/InsuranceInfo`,
          newFile: files[0],
          method: "PUT",
          //insurance,
        });
      } else {
        res = await InsuranceInfo(tourId, insurance);
      }
      if (res && res.status === 200) {
        message.success(words.global.successMessage);
        dispatches.setFormData((prev) => ({
          ...prev,
          [TabMenuKeys.insurance]: { ...insurance },
        }));
        dispatches.setCurrentFilter(TabMenuKeys.attachment);
      }
    } catch (err) {
      console.log("upload excel file error", err);
    } finally {
      setLoading(false);
    }
  };

  const getInsuranceInfo = useCallback(async () => {
    if (!tourId) return;
    try {
      setDataLoading(true);
      const result = await GetInsuranceInfo(tourId);
      if (result && result.status === 200) {
        dispatches.setFormData((prev) => ({
          ...prev,
          insurance: {
            ...prev?.insurance,
            insuranceType: result.data.insuranceType,
            insuranceCompany: result.data.insuranceCompany,
          },
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDataLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInsuranceFile = useCallback(async () => {
    if (!tourId) return;
    try {
      setProgress(true);
      const result = await GetInsuranceFile(tourId);
      if (result && result.status === 200) {
        dispatches.setFormData((prev) => ({
          ...prev,
          insurance: {
            ...prev?.insurance,
            file: result.data,
          },
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setProgress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname.includes(ROUTES.tourEdit) && tourId) {
      getInsuranceInfo();
      getInsuranceFile();
    }
  }, [getInsuranceFile, getInsuranceInfo, location.pathname, tourId]);

  const contextValues = {
    states: {
      loading,
      dataLoading,
      files,
      progress,
    },
    dispatches: { setFiles },
    requests: {
      addEditInsuranceRequest,
    },
  };

  return (
    <InsuranceRequestContext.Provider value={contextValues}>
      {children}
    </InsuranceRequestContext.Provider>
  );
};

export default InsuranceRequestProvider;

export const useInsuranceRequest = () => useContext(InsuranceRequestContext)!;
