import { Form, message } from "antd";
import { FormInstance } from "antd/lib";
import {
  FC,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TourManagementService } from "src/services/TourManagement/TourManagement.service";
import {
  ICompanionLeader,
  INewCompanionLeaderArg,
} from "src/services/TourManagement/models";
import useLanguage from "src/store/language";

interface IContextValue {
  states: {
    mode: string;
    dataLoading: boolean;
    loading: boolean;
    companionLeader: ICompanionLeader | undefined;
    companionLeaderForm: FormInstance;
    deleteLoading: boolean;
  };
  func: { onFinish: (values: INewCompanionLeaderArg) => void };
  request: { onDeleteCompanionLeader: () => Promise<void> };
}

export const ManagementCompanionLeaderDataContext = createContext<
  IContextValue | undefined
>(undefined);

const ManagementCompanionLeaderDataProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [mode, setMode] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [companionLeader, setCompanionLeader] = useState<
    ICompanionLeader | undefined
  >(undefined);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const [companionLeaderForm] = Form.useForm();

  const { tourId } = useParams();
  const { state } = useLocation();
  const { words } = useLanguage();

  const navigate = useNavigate();

  const onFinish = (values: INewCompanionLeaderArg) => {
    if (mode === "add") companionLeaderAddReq(values);
    else if (mode === "edit") companionLeaderEditReq(values);
  };

  const companionLeaderAddReq = async (values: INewCompanionLeaderArg) => {
    if (!tourId) return;
    setLoading(true);
    try {
      const { AddCompanionLeader } = new TourManagementService();
      const result = await AddCompanionLeader(tourId, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        navigate(-1);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const companionLeaderEditReq = async (values: INewCompanionLeaderArg) => {
    if (!companionLeader) return;
    setLoading(true);
    try {
      const { EditCompanionLeader } = new TourManagementService();
      const result = await EditCompanionLeader(companionLeader.id, values);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        navigate(-1);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onDeleteCompanionLeader = async () => {
    if (!companionLeader) return;
    setDeleteLoading(true);
    try {
      const { DeleteCompanionLeader } = new TourManagementService();
      const result = await DeleteCompanionLeader(companionLeader.id);
      if (result && result.status === 200) {
        message.success(words.global.successMessage);
        setMode("add");
        setCompanionLeader(undefined);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
    }
  };

  const getCompanion = useCallback(async () => {
    if (!tourId) return;
    setDataLoading(true);
    try {
      const { GetCompanionLeader } = new TourManagementService();
      const res = await GetCompanionLeader(tourId);
      if (res && res.status === 200 && res.data) {
        setCompanionLeader(res.data);
      } else {
        setCompanionLeader(undefined);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDataLoading(false);
    }
  }, [tourId]);

  useEffect(() => {
    if (tourId && (mode === "edit" || mode === "view")) getCompanion();
  }, [getCompanion, mode, tourId]);

  useEffect(() => {
    if (state && state.mode) setMode(state.mode);
    else setMode("edit");
  }, [state]);

  useEffect(() => {
    if (companionLeader) {
      companionLeaderForm.setFieldsValue({
        fullName: companionLeader?.fullName,
        phoneNumber: companionLeader?.phoneNumber,
      });
    } else companionLeaderForm.resetFields();
  }, [companionLeaderForm, companionLeader]);

  const contextValues = {
    states: {
      mode,
      dataLoading,
      loading,
      companionLeader,
      companionLeaderForm,
      deleteLoading,
    },
    func: { onFinish },
    request: { onDeleteCompanionLeader },
  };

  return (
    <ManagementCompanionLeaderDataContext.Provider value={contextValues}>
      {children}
    </ManagementCompanionLeaderDataContext.Provider>
  );
};

export default ManagementCompanionLeaderDataProvider;

export const useManagementCompanionLeaderData = () =>
  useContext(ManagementCompanionLeaderDataContext)!;
