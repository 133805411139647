import { FC } from "react";
import { Button, Divider, Modal } from "antd";
import useLanguage from "src/store/language";
import { BrokerInfoModalBodyContainer } from "./style";

interface IProps {
  item: string | null | undefined;
  show: boolean;
  onClose: () => void;
}

const BrokerInfoModal: FC<IProps> = ({ show, item, onClose }) => {
  const { words } = useLanguage();

  const Title = () => {
    return (
      <span className="text-[#233069] text-[18px] font-bold">
        {words.broker.modalTitle}
      </span>
    );
  };
  return (
    <Modal title={<Title />} open={show} closable={false} footer={false}>
      <BrokerInfoModalBodyContainer>
        <Divider className="m-0 p-0 bg-[#fde8dc]" />
        <div className="description-container">
          <p className="description">{item}</p>
        </div>

        <div className="btn-container">
          <Button
            type="text"
            onClick={onClose}
            icon={<span className="material-icons">cancel</span>}
            className="btn-cancel"
          >
            {words.global.close}
          </Button>
        </div>
      </BrokerInfoModalBodyContainer>
    </Modal>
  );
};

export default BrokerInfoModal;
