import { InputNumber } from "antd";
import styled from "styled-components";

export const InputNumberUiKitContainer = styled(InputNumber)`
  color: #1f1f1f;
  font: inherit;
  letter-spacing: inherit;
  background-color: #f5f5f5 !important;
  height: 1.4375em;
  margin: 0;
  display: block;
  min-width: 0;
  width: 100%;
  padding: 0 14px;
  box-shadow: none !important;
  border: 0 !important;
  outline: none !important;
  border-radius: 12px;
  &:hover {
    border-inline-end-width: 0;
    box-shadow: none !important;
    background-color: #f5f5f5 !important;
  }
  &:focus {
    border-inline-end-width: 0;
    box-shadow: none !important;
    background-color: #f5f5f5 !important;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-background-color: #f5f5f5 !important;
  }

  .ant-input-number-input::placeholder {
    color: #8e8e8e;
    font-weight: 500;
    font-size: 12px;
  }
  .ant-input-number-input {
    background-color: #f5f5f5;
    border: 0;
    width: 100%;
    height: 1.4375em;
  }

  .ant-input-number-input:hover,
  .ant-input-number-input:focus,
  .ant-input-number-input:active {
    box-shadow: none !important;
    background-color: #f5f5f5 !important;
    border: 0 !important;
    outline: none !important;
  }

  &.ant-input-status-error {
    box-shadow: none !important;
    border: 0 !important;
    outline: none !important;
    background-color: #f5f5f5 !important;
  }
  &.ant-input-status-error:focus {
    border-inline-end-width: 0;
    box-shadow: none !important;
    background-color: #f5f5f5 !important;
  }
`;
